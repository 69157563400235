export const styles = (theme) => ({
  filterSelect: {
    width: '95%',
    margin: '0 auto',
    display: 'table',
    marginBottom: theme.spacing.unit * 2,
  },
  datePicker: {
    width: '100%',
  },
  datePickerRange: {
    width: '50%',
  },
  menuItemSpan: {
    fontSize: 10,
    color: '#949494',
    marginLeft: 20,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  dialogContent: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
  },
  noPadding: {
    padding: '0px !important',
  },
  mapContainer: {
    height: '100%',
    width: '100%',
  },
  markerTrivo: {
    marginLeft: '-15px',
    marginTop: '-24px',
  },
  muiDialog: {
    height: '100%',
    width: '100%',
    paddingRight: '0px !important',
  },
  titleProformas: {
    padding: '15px 24px 10px',
    borderBottom: '1px solid #f3f3f3',
  },
  footerActions: {
    borderTop: '1px solid #f3f3f3',
  },
  tabs: {
    '& button': {
      outline: 'none',
      '&.disabled:not([class*="MuiTab-selected"])': {
        color: 'lightgray',
      },
    },

    '& [class*="MuiTab-selected"]': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
    '&.error': {
      '& [class*="MuiTab-selected"]': {
        color: theme.palette.error.main,
      },
      '& [class*="MuiTabs-indicator"]': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  loaderBackdrop: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: '#ffffff9c',
  },
  iconButton: {
    borderRadius: 4,
    textTransform: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  currentVersion: {
    '& span': {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
  },
  textFieldSelect: {
    marginRight: 24
  },
  inputShrink: {
    backgroundColor: 'white',
  },
  labelForm: {
    transform: 'translate(14px, 6px) scale(1)',
  },
  selectInputForm: {
    '& div div': {
      padding: '7px 24px',
    },
  },
});

export default styles;
