export const styles = (theme) => ({
  root: {},
  paper: {
    width: '100%',
  },
  table: {
    // minWidth: 750,
  },
  tableWrapper: {
    overflow: 'auto',
    height: 336,
    width: '100%',
    overflow: 'auto',
    padding: 20,
    paddingTop: 0,
  },
  title: {
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
    marginBottom: 20,
  },
  notCopy: {
    userSelect: 'none',
  },
});

export default styles;
