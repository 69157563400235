import React, { useEffect } from 'react';
import { withStyles, Paper, Grid } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import Call from '@material-ui/icons/Call';
import WhatsApp from '@material-ui/icons/PhoneAndroid';
import Email from '@material-ui/icons/Email';
import Note from '@material-ui/icons/Note';
import Person from '@material-ui/icons/Person';
import { styles } from './styles';
import TasksTabBar from '../../../pipelineDashboard/tasksTabBar';

const LeadTasks = (props) => {
  const { classes, activityIndicators, brokers, lead } = props;

  const renderTasks = () => {
    return (
      <div className={classes.root2}>
        <TasksTabBar brokers={brokers} lead={lead} />
      </div>
    );
  };

  return (
    <div className={classes.paperRoot}>
      <Grid className={classes.heightDashBoard} container spacing={24}>
        <Grid item md={12} xs={12}>
          <Paper className={`${classes.paper} ${classes.cardAdjusted}`}>
            <h5 className={classes.titleCard}> Estas son tus tareas</h5>
            <hr></hr>
            <div>{renderTasks()}</div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(LeadTasks);
