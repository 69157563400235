export const styles = (theme) => ({
    root: {
      marginTop: theme.spacing.unit * 3,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing.unit * 2,
    },
    tableWrapper: {
      overflowX: 'auto',
      maxHeight: 400,
      height: 'auto',
      width: '100%',
      overflow: 'auto',
      padding: 20,
      paddingTop: 0,
    },
  });

  export default styles;