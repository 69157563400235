export const GET_IDENTIFICATION_TYPES_SUCCESS = 'GET_IDENTIFICATION_TYPES_SUCCESS';

export const GET_IDENTIFICATION_TYPES_SENDING = 'GET_IDENTIFICATION_TYPES_SENDING';

export const GET_IDENTIFICATION_TYPES_FAILED = 'GET_IDENTIFICATION_TYPES_FAILED';

export const FILTER_IDENTIFICATION_TYPES_SUCCESS = 'FILTER_IDENTIFICATION_TYPES_SUCCESS';

export const FILTER_IDENTIFICATION_TYPES_SENDING = 'FILTER_IDENTIFICATION_TYPES_SENDING';

export const FILTER_IDENTIFICATION_TYPES_FAILED = 'FILTER_IDENTIFICATION_TYPES_FAILED';

export const CREATE_IDENTIFICATION_TYPE_SUCCESS = 'CREATE_IDENTIFICATION_TYPE_SUCCESS';

export const CREATE_IDENTIFICATION_TYPE_SENDING = 'CREATE_IDENTIFICATION_TYPE_SENDING';

export const CREATE_IDENTIFICATION_TYPE_FAILED = 'CREATE_IDENTIFICATION_TYPE_FAILED';

export const UPDATE_IDENTIFICATION_TYPE_SUCCESS = 'UPDATE_IDENTIFICATION_TYPE_SUCCESS';

export const UPDATE_IDENTIFICATION_TYPE_SENDING = 'UPDATE_IDENTIFICATION_TYPE_SENDING';

export const UPDATE_IDENTIFICATION_TYPE_FAILED = 'UPDATE_IDENTIFICATION_TYPE_FAILED';
