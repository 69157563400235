/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, Slide } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  filterProjects,
  createProject,
  editProject,
  editProjectAttachments,
  getProject,
  areDisposable,
  cloneProject,
} from '../../actions/projects';
import { addUnits } from '../../actions/units';
import { getParametersByCategory } from '../../actions/parameters';
import { getBuilders } from '../../actions/builders';
import { getCompanies } from '../../actions/companies';
import { getFormattedZones } from '../../actions/zones';
import {
  projectsCols,
  editProjectsCols,
  newProjectsCols,
} from '../../utils/colTypes';
import { projectsOptions } from '../../utils/filterTypes';
import EnhancedTable from '../common/enhancedTable';
import CustomSnackbar from '../common/customSnackbar';
import Attributes from './attributes';
import Attachments from './attachments';
import {
  copyObject,
  SUPER_ADMIN_ROL_ID,
  ADMIN_ID,
  isEmptyObject,
  validTrivoCompany,
  sortObjectByProperty,
  getCSVData,
} from '../../utils/utils';
import { GET_PROJECT_SUCCESS } from '../../actions/types/projects';
import DialogMap from '../common/dialogMap';
import { styles } from './styles';
import CloneDialog from './cloneDialog';
import Proformas from './proformas';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

export class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      attributes: false,
      title: 'Desarrollos',
      attachments: false,
      filterChips: [],
      filterOptions: copyObject(projectsOptions),
      showDialog: false,
      projectToUpdate: null,
      modalMapOpen: false,
      editingProject: null,
      isDisposable: true,
      openDialog: false,
      chosenCity: null,
      cloneParams: null,
      proformas: false,
    };
    this.isLoading = this.isLoading.bind(this);
    this.handleAttributes = this.handleAttributes.bind(this);
    this.handleAttach = this.handleAttach.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleConfirmUpdate = this.handleConfirmUpdate.bind(this);
    this.handleUpdateAttachments = this.handleUpdateAttachments.bind(this);
    this.handleUpdateAnswer = this.handleUpdateAnswer.bind(this);
    this.handleEditAttributes = this.handleEditAttributes.bind(this);
    this.handleEditDescription = this.handleEditDescription.bind(this);
    this.handleCancelUpdate = this.handleCancelUpdate.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.renderDisposableSnackBar = this.renderDisposableSnackBar.bind(this);
    this.renderUnitsSnackBar = this.renderUnitsSnackBar.bind(this);
    this.renderExtras = this.renderExtras.bind(this);
    this.filtersAttributes = this.filtersAttributes.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
    this.setProject = this.setProject.bind(this);
    this.handleOpenMap = this.handleOpenMap.bind(this);
    this.handleCloseMap = this.handleCloseMap.bind(this);
    this.handlesaveGeolocation = this.handlesaveGeolocation.bind(this);
    this.formatColsFromUser = this.formatColsFromUser.bind(this);
    this.formatColsEditProyect = this.formatColsEditProyect.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleCloneDialog = this.handleCloneDialog.bind(this);
    this.onCloneProject = this.onCloneProject.bind(this);
    this.handleProformas = this.handleProformas.bind(this);
    this.handleAddUnits = this.handleAddUnits.bind(this);
  }

  componentWillMount() {
    const company = JSON.parse(localStorage.getItem('company'));

    this.getViewPermission();
    this.props.getParametersByCategory('project');
    this.props.getFormattedZones(
      'cities',
      company ? 'configuration' : null,
      company && company._id ? company._id : null,
    );
    this.props.getBuilders();
    this.props.getCompanies();
    this.props.filterProjects(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.renderFilters();
      if (nextProps.cities && nextProps.cities.length > 0)
        this.setState({ chosenCity: nextProps.cities[0].id });
    }
  }

  setProject(projectId, attachments, attributes, proformas = false) {
    this.props.getProject(projectId).then((result) => {
      if (result.type === GET_PROJECT_SUCCESS) {
        const { project } = result.payload;
        project._id = project.id;
        this.setState({ project, attachments, attributes, proformas });
      }
    });
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_projects) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  };

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_projects) {
        return true;
      }
    }
    return false;
  };

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_projects) {
        return true;
      }
    }
    return false;
  };

  formatColsFromUser = (cols) => {
    const profileId = localStorage.getItem('profileId');
    let newCols = cols;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      newCols = cols.filter(
        (col) => !['companyName', 'companyId', 'clone'].includes(col.id),
      );
    }
    if (!validTrivoCompany()) {
      newCols = cols.filter((col) => !['revenue_percentage'].includes(col.id));
    }
    if (!(this.getEditPermissions() && this.getCreatePermission()))
      newCols = cols.filter((col) => !['proforma'].includes(col.id));
    if (ADMIN_ID !== profileId)
      newCols = cols.filter((col) => !['uploadUnits'].includes(col.id));
    return newCols;
  };

  formatColsEditProyect = (cols) => {
    let newCols = cols;
    if (!validTrivoCompany()) {
      newCols = cols.filter((col) => !['revenue_percentage'].includes(col.id));
    }
    return newCols;
  };

  isLoading() {
    const { isLoadingProjects } = this.props;
    if (isLoadingProjects) {
      return true;
    }
    return false;
  }

  handleAttach(project) {
    this.setProject(project.id, true, false);
  }

  handleAttributes(project) {
    this.setProject(project.id, false, true);
  }

  handleProformas(project) {
    this.setProject(project.id, false, false, true);
  }

  async handleAddUnits(id, e) {
    const units = await getCSVData(e);
    this.props.addUnits(id, units);
  }

  async handlesaveGeolocation(geoData) {
    const { editingProject } = this.state;
    const proyectEdited = {
      location: {
        type: 'Point',
        coordinates: [geoData.long, geoData.lat],
      },
    };

    await this.props.editProject(editingProject.id, proyectEdited);
    this.setState({ modalMapOpen: false, editingProject: null });
    await this.props.filterProjects(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleEditDescription(e) {
    const description = e.target.value;
    const { project } = this.state;
    project.description = description;
    this.setState({ project });
  }

  // eslint-disable-next-line consistent-return
  async handleUpdate(project) {

    if (project.active === false) {
      this.setState({ showDialog: true, projectToUpdate: project });
    } else {
      const editedProject = {};
      const { id } = project;
      let company =
        this.props.projects.find((x) => x.id === id).companyId || null;
      const oldProject = this.props.builders.find(
        (x) => x.id === project.builderId,
      );
      const newCompany = oldProject.company ? oldProject.company.id : null;
      if (company !== newCompany) {
        const isDisposable = await areDisposable(id);
        if (!isDisposable) {
          return this.setState({ isDisposable: false });
        }
        company = newCompany;
      }

      editedProject.company = company;
      editedProject.active = project.active;
      editedProject.builder = project.builderId;
      editedProject.zone = project.zoneId;
      editedProject.name = project.name;
      editedProject.revenue_percentage = project.revenue_percentage;
      editedProject.code = project.code;
      this.props.editProject(id, editedProject).then(() => {
        this.props.filterProjects(
          this.props.page,
          this.props.rowsPerPage,
          this.props.order,
          this.props.orderBy,
          this.props.searchText,
          this.props.filters,
        );
      });
    }
  }

  handleConfirmUpdate() {
    const { projectToUpdate } = this.state;
    this.setState({ projectToUpdate: null, showDialog: false });
    const editedProject = {};
    const { id } = projectToUpdate;
    const builderCompany = this.props.builders.find(
      (x) => x.id === projectToUpdate.builderId,
    ).company;
    editedProject.company = builderCompany ? builderCompany.id : null;
    editedProject.active = projectToUpdate.active;
    editedProject.builder = projectToUpdate.builderId;
    editedProject.zone = projectToUpdate.zoneId;
    editedProject.name = projectToUpdate.name;
    editedProject.revenue_percentage = projectToUpdate.revenue_percentage;
    this.props.editProject(id, editedProject).then(() => {
      this.props.filterProjects(
        this.props.page,
        this.props.rowsPerPage,
        this.props.order,
        this.props.orderBy,
        this.props.searchText,
        this.props.filters,
      );
    });
  }

  handleUpdateAnswer(attributeId, answerId, answer) {
    const { project } = this.state;
    if (!project.attributes) {
      project.attributes = {};
    }
    project.attributes[attributeId] = {
      answerId,
      answer,
    };
    this.setState({ project });
  }

  handleEditAttributes() {
    const { project } = this.state;
    const { parameters } = this.props;
    const { _id } = project;
    project.zone = project.zoneId;
    project.builder = project.builderId;
    delete project.zoneId;
    delete project.builderId;
    const notUpdating = parameters.filter(
      (obj) => obj.is_catalog === true || obj.active === false,
    );
    const { attributes } = project;
    if (notUpdating.length > 0) {
      notUpdating.forEach((value) => {
        if (attributes[value.identifier]) {
          delete attributes[value.identifier];
        }
      });
    }
    const edit = {
      attributes,
      description: project.description,
    };
    this.props.editProject(_id, edit);
  }

  handleAdd(project) {
    const builderCompany = this.props.builders.find(
      (x) => x.id === project.builderId,
    ).company;
    const newProject = project;
    newProject.zone = project.zoneId;
    newProject.builder = project.builderId;
    newProject.company = builderCompany ? builderCompany.id : null;
    delete newProject.zoneId;
    delete newProject.builderId;

    if (!newProject.attributes) {
      newProject.attributes = {};
      const { parameters } = this.props;
      parameters.forEach((element) => {
        if (element.is_catalog === false && element.active === true) {
          newProject.attributes[element.identifier] = {
            answerId: [],
            answer: '',
          };
        }
      });
    }
    delete newProject.cityId;
    if (!isEmptyObject(newProject.attributes)) {
      this.props.createProject(newProject).then(() => {
        this.props.filterProjects(
          this.props.page,
          this.props.rowsPerPage,
          this.props.order,
          this.props.orderBy,
          this.props.searchText,
          this.props.filters,
        );
      });
    } else {
      this.setState({ openDialog: true });
    }
  }

  handleUpdateAttachments(attachments) {
    const { project } = this.state;
    const { _id } = project;
    this.props.editProjectAttachments(_id, attachments).then(() => {
      this.setProject(_id, true, false);
    });
  }

  handleSearch(searchText) {
    let { filters } = this.props;
    if (searchText === null) {
      filters = {};
      this.setState({ filterChips: [] });
    }
    this.props.filterProjects(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      searchText,
      filters,
    );
  }

  handleCloseDialog() {
    this.setState({ openDialog: false, cloneParams: null });

    this.props.filterProjects(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleCloneDialog({ projectId, companyId }) {
    const { companies, builders } = this.props;
    const companyWithBuilders = companies
      .filter((company) => company.id !== companyId)
      .map((company) => {
        const companyBuilders = builders
          .filter(
            (builder) => builder.company && builder.company.id === company.id,
          )
          .map((builder) => ({ id: builder.id, name: builder.name }));
        return {
          id: company.id,
          name: company.name,
          builders: companyBuilders,
        };
      })
      .filter((company) => company.builders.length > 0);

    this.setState({ cloneParams: { companyWithBuilders, projectId } });
  }

  async onCloneProject(data) {
    const success = await this.props.cloneProject(data);
    if (success) this.handleCloseDialog();
  }

  filtersAttributes = (allFilters) => {
    const attributes = [];
    for (let i = 0; i < Object.entries(allFilters).length; i += 1) {
      const filter = Object.entries(allFilters)[i];
      if (
        filter[0] !== 'active' &&
        filter[0] !== 'builder' &&
        filter[0] !== 'company' &&
        filter[0] !== 'zone' &&
        filter[1] !== ''
      ) {
        let query = '';
        if (filter[1].type === 'select') {
          if (filter[1].value.length > 0) {
            query = `attributes.${filter[0]}.answerId`;
            const $in = {
              $in: filter[1].value,
            };
            attributes.push({
              [query]: $in,
            });
          }
        } else {
          query = `attributes.${filter[0]}.answer`;
          attributes.push({
            [query]: filter[1].value,
          });
        }
      } else {
        const defaultFilter = {
          [filter[0]]: filter[1].value,
        };
        attributes.push(defaultFilter);
      }
    }
    const filterToApply = {};
    if (attributes.length > 0) {
      for (let i = 0; i < attributes.length; i += 1) {
        const attribute = attributes[i];
        const attributeObject = Object.entries(attribute);
        const key = attributeObject[0][0];
        const value = attributeObject[0][1];
        if (value !== '') {
          filterToApply[key] = value;
        }
      }
    }
    const preFilters = Object.entries(filterToApply).filter(
      (obj) => obj[1] !== '',
    );
    const filters = {};
    for (let i = 0; i < preFilters.length; i += 1) {
      const preFilter = preFilters[i];
      if (preFilter[1].value) {
        if (Array.isArray(preFilter[1].value)) {
          if (preFilter[1].value.length > 0) {
            filters[preFilter[0]] = preFilter[1].value;
          }
        } else {
          filters[preFilter[0]] = preFilter[1].value;
        }
      } else if (Array.isArray(preFilter[1])) {
        if (preFilter[1].length > 0) {
          filters[preFilter[0]] = preFilter[1];
        }
      } else {
        filters[preFilter[0]] = preFilter[1];
      }
    }
    return filters;
  };

  handleFilter(filters, filterChips) {
    const filtersToApply = this.filtersAttributes(filters);
    this.setState({ filterChips });
    this.props.filterProjects(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      filtersToApply,
    );
  }

  handleOrder(order, orderBy) {
    this.props.filterProjects(
      this.props.page,
      this.props.rowsPerPage,
      order,
      orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleGoBack() {
    // eslint-disable-next-line react/no-unused-state
    this.setState({
      project: null,
      isNew: false,
      cols: projectsCols,
      attributes: false,
      attachments: false,
      proformas: false,
    });
    this.props.filterProjects(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterProjects(
      this.props.page,
      rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleChangePage(page) {
    this.props.filterProjects(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleCancelUpdate() {
    this.setState({ showDialog: false });
    this.props.filterProjects(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleOpenMap(row) {
    this.setState({ modalMapOpen: true, editingProject: row });
  }

  handleCloseMap() {
    this.setState({ modalMapOpen: false });
  }

  renderFilters() {
    const { parameters, builders, zones, companies } = this.props;
    let defaultFilters = [];
    let parameterFilters = [];
    let filterOptions = [];
    const profileId = localStorage.getItem('profileId');
    if (builders.length > 0 && companies.length > 0 && zones.length > 0) {
      let options = copyObject(projectsOptions);
      if (SUPER_ADMIN_ROL_ID !== profileId) {
        options = options.filter((option) => option.id !== 'company');
      }
      defaultFilters = options.map((item) => {
        const filter = item;
        if (filter.id === 'builder') {
          const types = [{ title: 'Todos', value: 'all' }];
          builders.forEach((builder) => {
            const newType = {
              title: builder.name,
              value: builder.id,
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'company') {
          const types = [{ title: 'Todos', value: 'all' }];
          companies.forEach((company) => {
            const newType = {
              title: company.name,
              value: company.id,
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'zone') {
          const types = [{ title: 'Todos', value: 'all' }];
          zones.forEach((zone) => {
            const newType = {
              title: zone.name,
              value: zone.id,
            };
            types.push(newType);
          });
          filter.types = types;
        }
        return filter;
      });
    }

    if (parameters.length > 0) {
      const clearParams = parameters
        .filter((obj) => {
          if (obj.is_catalog === false && obj.active === true) {
            if (obj.options.is_multiple === true && obj.options.content) {
              if (obj.options.content.length > 0) {
                return obj;
              }
            } else if (obj.input_type === 'yes_no') {
              return obj;
            } else if (obj.input_type === 'text_box') {
              return obj;
            } else if (obj.input_type === 'date') {
              return obj;
            } else if (obj.input_type === 'range') {
              return obj;
            }
          }
          return null;
        })
        .filter((obj) => obj !== null);
      parameterFilters = clearParams
        .map((parameter) => {
          let parameterTypes = [];
          if (parameter.input_type === 'yes_no') {
            return {
              id: parameter.identifier,
              label: parameter.name,
              types: [
                { title: 'Todos', value: 'all' },
                { title: 'Si', value: 'Si' },
                { title: 'No', value: 'No' },
              ],
            };
          } else if (parameter.input_type === 'text_box') {
            return {
              id: parameter.identifier,
              label: parameter.name,
              types: [],
              type: 'text',
            };
          } else if (parameter.input_type === 'date') {
            return {
              id: parameter.identifier,
              label: parameter.name,
              types: [],
              type: 'date',
            };
          } else if (parameter.input_type === 'range') {
            return {
              id: parameter.identifier,
              label: parameter.name,
              types: [],
              type: 'numberRange',
            };
          } else if (parameter.options.content.length > 0) {
            parameterTypes = parameter.options.content.map((item) => {
              return {
                title: item.value,
                value: item.answerId,
              };
            });
            const filter = {
              id: parameter.identifier,
              label: parameter.name,
              types: [{ title: 'Todos', value: 'all' }],
            };

            if (parameterTypes.length > 0) {
              filter.types = filter.types.concat(parameterTypes);
            }
            return filter;
          }
          return null;
        })
        .filter((obj) => obj !== null);
    }
    if (defaultFilters.length > 0) {
      filterOptions = defaultFilters.concat(parameterFilters);
      this.setState({ filterOptions });
    }
  }

  renderDialog() {
    const { showDialog } = this.state;
    return (
      <Dialog open={showDialog} onClose={this.handleCancelUpdate}>
        <DialogTitle>Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Al deshabilitar un proyecto automáticamente se deshabilitan todas
            sus unidades. ¿Desea continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancelUpdate} color="primary">
            No
          </Button>
          <Button onClick={this.handleConfirmUpdate} color="primary" autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderExtras() {
    const { isLoadingProjects, parameters, companies } = this.props;
    const { attachments, project, attributes, proformas } = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    if (attachments) {
      return (
        <Attachments
          project={project}
          isLoading={isLoadingProjects}
          onGoBack={this.handleGoBack}
          permissions={hasEditPermission && hasCreatePermission}
          onUpdateAttachments={this.handleUpdateAttachments}
        />
      );
    }
    if (attributes) {
      const attributesToUse = parameters
        .filter((obj) => obj.is_catalog === false && obj.active === true)
        .map((item) => {
          const attr = item;
          if (
            attr &&
            attr.options &&
            attr.options.content &&
            attr.options.content.length > 0
          ) {
            attr.options.content = attr.options.content.filter((x) => x.active);
          }
          return attr;
        });

      return (
        <Attributes
          project={project}
          isLoading={isLoadingProjects}
          onGoBack={this.handleGoBack}
          onUpdateAnswer={this.handleUpdateAnswer}
          attributes={attributesToUse}
          onEditAttributes={this.handleEditAttributes}
          permissions={hasEditPermission && hasCreatePermission}
          onEditDescription={this.handleEditDescription}
        />
      );
    }
    if (proformas) {
      const company = companies.find((item) => item.id === project.company);
      return (
        <Proformas
          project={project}
          onGoBack={this.handleGoBack}
          company={company}
        />
      );
    }

    return <div />;
  }

  renderSnackBar() {
    const { error, message } = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    
    return null;
  }

  renderDisposableSnackBar() {
    const { isDisposable } = this.state;
    if (!isDisposable) {
      this.setState({ isDisposable: true });
      return (
        <CustomSnackbar
          variant="error"
          message="No se puede cambiar la constructora porque el proyecto tiene leads dependientes"
        />
      );
    }
    return null;
  }

  renderUnitsSnackBar() {
    const { unitsError, unitsMessage } = this.props;
    if (unitsMessage) {
      return (
        <CustomSnackbar
          variant={unitsError !== null ? 'error' : 'success'}
          message={unitsMessage}
        />
      );
    }    
    return null;
  }

  render() {
    const {
      projects,
      dataLength,
      builders,
      zones,
      cities,
      companies,
    } = this.props;
    const {
      title,
      project,
      filterOptions,
      filterChips,
      modalMapOpen,
      editingProject,
      openDialog,
      chosenCity,
      cloneParams,
    } = this.state;
    let [long, lat] = [];
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    if (editingProject && editingProject.location) {
      const { coordinates } = editingProject.location;
      if (coordinates && coordinates.length > 0) {
        [long, lat] = coordinates;
      }
    }
    const processProject = projects.map((item) => {
      const proj = item;
      if (proj.company) {
        proj.companyName = proj.company.name;
        proj.companyId = proj.company.id;
        delete proj.company;
      }
      return proj;
    });
    const data = {
      rows: processProject,
      cols: this.formatColsFromUser(projectsCols),
      autoFillers: {
        builder: builders,
        company: companies,
        zone: zones.filter((item) => item.city === chosenCity),
        city: cities,
      },
      editCols: this.formatColsEditProyect(editProjectsCols),
      newCols: this.formatColsEditProyect(newProjectsCols),
    };

    const handlers = {
      onAttributes: this.handleAttributes,
      onAttach: this.handleAttach,
      onAdd: this.handleAdd,
      onSearch: this.handleSearch,
      onOrder: this.handleOrder,
      onFilter: this.handleFilter,
      onUpdate: this.handleUpdate,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      openMapModal: this.handleOpenMap,
      onChangeCity: (value) => this.setState({ chosenCity: value }),
      onCloneProject: this.handleCloneDialog,
      onProformas: this.handleProformas,
      onAddUnits: this.handleAddUnits,
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      isEditableInline: true,
      hasEditPermission,
      hasCreatePermission,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order,
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength,
      },
      filters: {
        is: true,
        options: filterOptions,
        chips: filterChips,
        by: this.props.filters,
      },
    };
    return this.isLoading() === true ? (
      <CircularProgress
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
      />
    ) : (
      <div>
        {this.renderSnackBar()}
        {this.renderDisposableSnackBar()}
        {this.renderUnitsSnackBar()}
        {this.renderDialog()}
        {editingProject && (
          <DialogMap
            lat={lat}
            long={long}
            movePiker
            successAction={this.handlesaveGeolocation}
            closeDialog={this.handleCloseMap}
            isOpen={modalMapOpen}
          />
        )}
        {project !== null ? (
          this.renderExtras()
        ) : (
          <EnhancedTable
            title={title}
            data={data}
            handlers={handlers}
            config={config}
          />
        )}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="lg"
          fullWidth
          open={openDialog}
          onClose={() => this.handleCloseDialog()}
          TransitionComponent={Transition}
        >
          <DialogTitle>
            No puede crear un proyecto sin al menos un atributo de proyecto
            activo
          </DialogTitle>
          <DialogActions>
            <Button
              style={{ color: 'red' }}
              onClick={() => this.handleCloseDialog()}
            >
              Cerrar
            </Button>
            <Button href="/parametros" color="primary">
              Configurar atributos de desarrollos
            </Button>
          </DialogActions>
        </Dialog>
        {cloneParams && (
          <CloneDialog
            onCloneProject={this.onCloneProject}
            closeDialog={() => this.setState({ cloneParams: null })}
            projectId={cloneParams.projectId}
            companyWithBuilders={cloneParams.companyWithBuilders}
          />
        )}
      </div>
    );
  }
}

Projects.propTypes = {
  isLoadingProjects: PropTypes.bool.isRequired,
  filterProjects: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  builders: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  parameters: PropTypes.array.isRequired,
  dataLength: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  getProject: PropTypes.func.isRequired,
  editProject: PropTypes.func.isRequired,
  editProjectAttachments: PropTypes.func.isRequired,
  getFormattedZones: PropTypes.func.isRequired,
  getBuilders: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  createProject: PropTypes.func.isRequired,
  cloneProject: PropTypes.func.isRequired,
  getParametersByCategory: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  addUnits: PropTypes.func.isRequired,
  unitsMessage: PropTypes.string,
  unitsError: PropTypes.object,
};

const getZones = (array) => {
  const response = [];
  array.map((city) =>
    city.zones.map((zone) =>
      zone.sectors.map(({ id, name }) =>
        response.push({ id, name, city: city.id, active: true }),
      ),
    ),
  );
  return sortObjectByProperty(response, 'name');
};

const mapStateToProps = (state) => {
  const { projects, zones, builders, parameters, companies, units } = state;
  return {
    isLoadingProjects: projects.sending,
    isLoadingZones: zones.sending,
    isLoadingBuilders: builders.sending,
    isLoadingCompanies: companies.sending,
    isLoadingParameters: parameters.sending,
    projects: projects.projects,
    parameters: parameters.parameters,
    zones: getZones(zones.formattedZones),
    cities: zones.formattedZones.map(({ id, name }) => ({
      id,
      name,
      active: true,
    })),
    builders: builders.builders,
    companies: companies.companies,
    dataLength: projects.dataLength,
    message: projects.message,
    error: projects.error,
    unitsMessage: units.message,
    unitsError: units.error,
    page: projects.page,
    rowsPerPage: projects.rowsPerPage,
    order: projects.order,
    orderBy: projects.orderBy,
    searchText: projects.searchText,
    filters: projects.filters,
  };
};
const mapDispatchToProps = {
  filterProjects,
  createProject,
  editProject,
  editProjectAttachments,
  cloneProject,
  getParametersByCategory,
  getBuilders,
  getFormattedZones,
  getProject,
  getCompanies,
  addUnits
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Projects));
