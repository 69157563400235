/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage, SUPER_ADMIN_ROL_ID} from '../utils/utils';
import * as commisionActions from './types/commissions';
import store from '../store';
import CONSTANTS from '../utils/constants';

const company = JSON.parse(localStorage.getItem('company'));
const profileId = localStorage.getItem('profileId');

export const filterCommissions = (page, rowsPerPage, order, orderBy, searchText, filters) => async (dispatch) => {
  if (
    (searchText !== '' && store.getState().commissions.searchText !== searchText) || 
    (Object.keys(filters).length && filters !== store.getState().commissions.filters)
  ) {
    page = 0;
  }
  if (order !== store.getState().commissions.order || orderBy !== store.getState().commissions.orderBy) {
    // eslint-disable-next-line no-param-reassign
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
    filters = {
      type: filters.type
    };
  }
  dispatch({
    type: commisionActions.FILTER_COMMISSIONS_SENDING,
    payload: {
      commissions: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
      filters
    }
  });
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && order !== null && orderBy !== null) {
      filter = {
        filters,
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order
        }
      };
    }
    if (searchText !== '') {
      if (filter.filters.type === 'collect') {
        filter.filters.builder = [searchText];
      } else {
        filter.filters.beneficiary = searchText;
      }
    }
    /* debugger */
    if (company && company._id) {
      filter.company = company._id;
    }
    if (SUPER_ADMIN_ROL_ID === profileId) {
      filter.company = CONSTANTS.TRIVO_COMPANY_ID;
    }
    const request = axiosMaker('POST', 'revenues/filter', filter, true);
    const response = await axios(request);
    console.log('response: ', response);
    return dispatch({
      type: commisionActions.FILTER_COMMISSIONS_SUCCESS,
      payload: {
        commissions: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: commisionActions.FILTER_COMMISSIONS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const patchCommission = (id, commissions) => async (dispatch) => {
  dispatch({
    type: commisionActions.UPDATE_COMMISSION_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `revenues/${id}`, commissions, true));
    return dispatch({
      type: commisionActions.UPDATE_COMMISSION_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: commisionActions.UPDATE_COMMISSION_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
