import * as smartActions from '../actions/types/smart';
import { typesCountDefault } from '../utils/utils';

const initialState = {
  form: [],
  answers: [],
  graphs: [],
  unitsCount: 0,
  projectsCount: 0,
  priceAvg: 0,
  typesCount: typesCountDefault,
  availability: {
    availableUnits: 0,
    availablePojects: 0,
    priceAvg: 0,
  },
  supplyGraphs: [],
  supplyResume: {},
  supplyFilters: null,
  message: '',
  sending: false,
  error: null,
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {
    form,
    message,
    error,
    graphs,
    unitsCount,
    projectsCount,
    priceAvg,
    typesCount,
    availability,
    answers,
    supplyGraphs,
    supplyFilters,
    supplyResume,
  } = action.payload;
  switch (action.type) {
    case smartActions.GET_FORM_SENDING:
      return { ...state, form: [], sending: true };
    case smartActions.GET_FORM_SUCCESS:
      return { ...state, sending: false, form, error: null };
    case smartActions.GET_FORM_FAILED:
      return { ...state, sending: false, form: [], error, message };
    case smartActions.GET_ANSWERS_SENDING:
      return { ...state, answers: [], sending: true };
    case smartActions.GET_ANSWERS_SUCCESS:
      return { ...state, sending: false, answers, error: null };
    case smartActions.GET_ANSWERS_FAILED:
      return { ...state, sending: false, answers: [], error, message };
    case smartActions.CREATE_FORM_SENDING:
      return { ...state, sending: true };
    case smartActions.CREATE_FORM_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        answers: [],
        form: [],
        message: 'Información almacenada correctamente',
      };
    case smartActions.CREATE_FORM_FAILED:
      return { ...state, sending: false, error, message };
    case smartActions.GET_GRAPHS_SENDING:
      return { ...state, sending: true };
    case smartActions.GET_GRAPHS_SUCCESS:
      return {
        ...state,
        sending: false,
        graphs,
        unitsCount,
        projectsCount,
        priceAvg,
        typesCount,
        availability,
        error: null,
        message: 'Gráficos obtenidos satisfactoriamente',
      };
    case smartActions.GET_GRAPHS_FAILED:
      return { ...state, sending: false, graphs: [], error, message };
    case smartActions.GET_SUPPLY_GRAPHS_SENDING:
      return { ...state, sending: true };
    case smartActions.GET_SUPPLY_GRAPHS_SUCCESS:
      return {
        ...state,
        sending: false,
        supplyResume,
        supplyGraphs,
        supplyFilters,
        error: null,
        message: 'Gráficos obtenidos satisfactoriamente',
      };
    case smartActions.GET_SUPPLY_GRAPHS_FAILED:
      return {
        ...state,
        sending: false,
        supplyGraphs: [],
        supplyResume: {},
        supplyFilters: null,
        error,
        message,
      };
    default:
      return state;
  }
};
