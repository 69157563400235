import {lighten} from '@material-ui/core/styles/colorManipulator';

export const styles = theme => ({
  paper: {
    width: '95%',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 3
  },
  table: {
    width: '100%'
  },
  tableWrapper: {
    overflowX: 'scroll'
  },
  root: {
    paddingRight: theme.spacing.unit
  },
  sortableRow: {
    cursor: 'move'
  },
  row: {

  },
  highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 100%'
  },
  search: {
    marginRight: theme.spacing.unit * 2,
    width: theme.spacing.unit * 80
  }
});

export default styles;
