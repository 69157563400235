import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {SUPER_ADMIN_ROL_ID} from '../../../../utils/utils';

export class OriginDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      name: '',
      company: ''
    };
    this.renderButton = this.renderButton.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
  }

  componentWillMount() {
    const {isNew, origin} = this.props;
    const profileId = localStorage.getItem('profileId');  
    const {company} = origin;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      const companyStored = JSON.parse(localStorage.getItem('company'));  
      const companySaved = companyStored ? companyStored._id : '';  
      this.setState({company: companySaved});
    }
    if (isNew === false) {
      this.setState({
        origin,
        name: origin.name,
        active: origin.active,
        company: (company && company._id) ? company._id : ''
      });
    }
  }

  onAddButtonClick() {
    const {name, company} = this.state;
    this.props.handleCreateOrigin({name, company}).then(() => {
      this.handleGoBack();
    });
  }

  onEditButtonClick() {
    const {origin, active, name, company} = this.state;
    const originEdited = {
      id: origin.id,
      active,
      name,
      company
    };
    this.props.handleUpdateOrigin(originEdited);
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  handleChangeCompany(e) {
    const company = e.target.value;
    this.setState({company});
  }

  isInvalid() {
    const {name, company} = this.state;
    console.log('name, company:', name, company)
    
    if (name !== '' && company !== '') {
      return false;
    }
    return true;
  }

  renderButton() {
    const {isLoading, isNew, classes} = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} este origen?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} origen`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderRowCompany(classes) {
    const profileId = localStorage.getItem('profileId');  
    return (
      SUPER_ADMIN_ROL_ID === profileId &&
      <TableRow>
        <TableCell className={classes.tableRowTitle}><b>Empresa</b></TableCell>
        <TableCell>
          <Select
            value={this.state.company}
            fullWidth
            onChange={this.handleChangeCompany}
          >
            {this.renderCompanies()}
          </Select>
        </TableCell>
      </TableRow>
    );
  }  

  renderCompanies() {
    const {companies} = this.props;
    return companies.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
      );
    });
  }  
    
  render() {
    const {classes, isLoading, isNew} = this.props;
    return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de ' : 'Nuevo '} origen
          </Typography>
        </Toolbar>
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              {
                !isNew &&
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Disponible</b></TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.active}
                          onChange={e => this.setState({active: e.target.checked})}
                          color="primary"
                        />
                      }
                      label={this.state.active === true ? 'Disponible' : 'No disponible'}
                    />
                  </TableCell>
                </TableRow>
              }
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Nombre</b></TableCell>
                <TableCell>
                  <TextField 
                    autoFocus
                    value={this.state.name}
                    onChange={e => this.setState({name: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              {this.renderRowCompany(classes)}  
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>
          {this.renderButton()}
        </div>
      </Paper>
    );
  }
}

OriginDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  origin: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleCreateOrigin: PropTypes.func.isRequired,
  handleUpdateOrigin: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired
};
  
export default (withStyles(styles)(OriginDetails));
