export const styles = (theme) => ({
  borderedCards: {
    padding: '30px 30px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderLeft: '1px solid',
    borderBottom: '1px solid',
    borderLeftColor: '#eeeeee',
    borderBottomColor: '#eeeeee',
    height: '100%',
  },
  cardAdjusted: {
    padding: 0,
    height: '100%',
  },
  root: {
    flexGrow: 1,
    margin: 20,
  },
  root2: {
    flexGrow: 1,
    marginTop: '-1rem',
  },
  titleCard: {
    padding: '20px 20px 0px 20px',
  },
  itemList: {
    '& span': {
      fontSize: '1.1rem !important',
      lineHeight: '1.8',
      fontWeight: 500,
    },
  },
  itemValue: {
    color: 'white',
    width: '50px',
    height: '50px',
    padding: '10px',
    borderRadius: '14px',
    background: theme.palette.primary.main,
  },
  mainIitemList: {
    paddingRight: '60px',
  },
  flexCard: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexCardTitle: {
    flex: 1,
    '& h5': {
      marginBottom: '0px',
    },
    '& h6': {
      fontWeight: '700',
    },
  },
});

export default styles;
