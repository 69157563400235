/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { axiosMaker, errorMessage } from '../utils/utils';
import * as plansActions from './types/plans';
import store from '../store';

export const getPlans = () => async (dispatch) => {
  dispatch({
    type: plansActions.GET_PLANS_SENDING,
    payload: {
      plans: [],
    },
  });
  try {
    const response = await axios(axiosMaker('GET', 'plans', null, true));
    return dispatch({
      type: plansActions.GET_PLANS_SUCCESS,
      payload: {
        plans: response.data,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: plansActions.GET_PLANS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getGraphs = () => async (dispatch) => {
  dispatch({
    type: plansActions.GET_GRAPHS_SENDING,
    payload: {
      graphs: [],
    },
  });
  try {
    const response = await axios(axiosMaker('GET', 'plans/graphs', null, true));
    return dispatch({
      type: plansActions.GET_GRAPHS_SUCCESS,
      payload: {
        graphs: response.data,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: plansActions.GET_GRAPHS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const filterPlans = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().plans.searchText !== searchText) {
    page = 0;
  }
  if (
    order !== store.getState().plans.order ||
    orderBy !== store.getState().plans.orderBy
  ) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: plansActions.GET_FILTER_PLANS_SENDING,
    payload: {
      plans: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
    },
  });
  try {
    let filter = {};
    if (
      page !== null &&
      rowsPerPage !== null &&
      order !== null &&
      orderBy !== null &&
      searchText !== null
    ) {
      filter = {
        filters: {
          name: searchText,
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order,
        },
      };
    }
    const response = await axios(axiosMaker('POST', 'plans/filter', filter, true));
    return dispatch({
      type: plansActions.GET_FILTER_PLANS_SUCCESS,
      payload: {
        plans: response.data.results,
        dataLength: response.data.total,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: plansActions.GET_FILTER_PLANS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updatePlan = (id, plan) => async (dispatch) => {
  dispatch({
    type: plansActions.UPDATE_PLAN_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `plans/${id}`, plan, true));
    return dispatch({
      type: plansActions.UPDATE_PLAN_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: plansActions.UPDATE_PLAN_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
