import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import {
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import AddIcon from '@material-ui/icons/Add';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FbDialogCredentials = (props) => {
  const [open, setOpen] = React.useState(false);

  const [facebookUserToken, setFacebookUserToken] = useState('');
  const [appId, setappId] = useState('');
  const [pageId, setpageId] = useState('');
  const [name, setName] = useState('');
  const [origin, setOrigin] = useState('');
  const { classes, addFbCredentials, origins } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {}, []);

  const validCredential = () => {
    return appId !== '' && pageId !== '' && facebookUserToken !== '' && name !== '' && origin !== '';
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitForm = () => {
    if (validCredential()) {
      addFbCredentials({ facebookUserToken, appId, pageId, name, origin });
      setFacebookUserToken('');
      setappId('');
      setpageId('');
      setName('');
      setOrigin('');
      handleClose();
    }
  };

  return (
    <div>
      <Tooltip title="Añadir Integración" style={{ display: 'inline-block' }}>
        <IconButton color="primary" onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <TextField
            className={classes.textFields}
            label="Nombre de la integración"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            className={classes.textFields}
            label="Identificador de la aplicación"
            fullWidth
            value={appId}
            onChange={(e) => setappId(e.target.value)}
          />
          <TextField
            className={classes.textFields}
            label="Identificador de la página"
            fullWidth
            value={pageId}
            onChange={(e) => setpageId(e.target.value)}
          />
          <TextField
            className={classes.textFields}
            label="Token de usuario"
            fullWidth
            value={facebookUserToken}
            onChange={(e) => setFacebookUserToken(e.target.value)}
          />
          <FormControl className={classes.textFields}>
            <InputLabel htmlFor="origin-label">
              Origen
            </InputLabel>
            <Select
              input={<Input name="origin" id="origin-label" />}
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
              fullWidth
            >
              {origins.map((item, index) => (
                <MenuItem value={item.id} key={`origin_${index}`}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmitForm}
            disabled={!validCredential()}
            color="primary"
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FbDialogCredentials.propTypes = {
  classes: PropTypes.object.isRequired,
  addFbCredentials: PropTypes.func.isRequired,
  origins: PropTypes.array.isRequired,
};

export default withStyles(styles)(FbDialogCredentials);
