import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export class CustomTextBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answer} = currentAnswer;
      if (answer !== '') {
        this.setState({answer});
      }
    }
  }

  handleChange(e) {
    const {identifier} = this.props;
    const answer = e.target.value;
    this.setState({answer});
    this.props.updateAnswer(identifier, [], answer);
  }

  render() {
    const {permissions} = this.props;
    return (
      <TextField
        fullWidth
        label={this.props.placeholder}
        value={this.state.answer}
        multiline
        rowsMax={5}
        onChange={this.handleChange}
        margin="normal"
        variant="outlined"
        disabled={permissions === false}
      />
    );
  }
}
CustomTextBox.propTypes = {
  updateAnswer: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  permissions: PropTypes.bool,
  currentAnswer: PropTypes.object
};

export default CustomTextBox;
