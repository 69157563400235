import React, { Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { green500, red500 } from 'material-ui/styles/colors';
import PropTypes from 'prop-types';
import { getProfileIsAdmin, eventListCols, dateFormat, logOutFunction } from '../../../../utils';
import { styles } from './styles';

const getEventListCols = () => {
  const isAdmin = getProfileIsAdmin();
  const firsts = eventListCols.slice(0, 1);
  const ends = eventListCols.slice(1);

  const brokerCol = {
    id: 'broker',
    right: false,
    disablePadding: false,
    label: 'Asesor',
    type: 'text',
  };

  return isAdmin ? [...firsts, brokerCol, ...ends] : eventListCols;
};

const AppointmentListTable = ({ events = [], classes, handlerOnEdit, renderActionEvents }) => {
  const renderBool2 = (exist) => {
    return exist ? <CheckIcon style={{ color: green500 }} /> : '-';
  };

  console.log("events",events);
  const renderBoolX = (exist) => {
    return exist ? <CloseIcon style={{ color: red500 }} /> : '-';
  };

  const renderEdit = (eventId) => {
    if (!eventId) {
      return <div style={{ textAlign: 'center' }}>-</div>;
    }

    return (
      <Tooltip title="Editar">
        <EditIcon
          style={{ cursor: 'pointer', color: 'grey' }}
          onClick={() => handlerOnEdit(eventId)}
        />
      </Tooltip>
    );
  };

  const renderNumber = (num) => <div style={{ textAlign: 'center' }}>{Number(num) ? num : 0}</div>;

  const renderColContent = (col, _, event) => {
    switch (col.type) {
      case 'number':
        return renderNumber(event[col.id]);
      case 'dropdown':
        return renderActionEvents(event);
      case 'boolx':
        return renderBoolX(event[col.id]);
      case 'edit':
        return renderEdit(event[col.id]);
      case 'bool2':
        return renderBool2(event[col.id]);
      default:
        return <div style={{ minWidth: 150 }}>{event[col.id]}</div>;
    }
  };

  const parseDate = (date) =>
    `${moment(date).format(`${dateFormat} HH:mm`)} (${moment(date).fromNow()})`;

  const renderTableCol = () =>
    events.map((event, index) => {
      const isCanceled = event.status === 'CANCELED';
      const date = parseDate(event.date);

      event['date'] = date;
      event['isCanceled'] = isCanceled;
      event['rescheduled'] = event.rescheduled ? event.rescheduled : 0;
      event['canEdit'] = isCanceled ? null : event.id;

      return (
        <TableRow key={index}>
          {getEventListCols().map((col, i) => (
            <TableCell style={{ padding: '0px 20px' }} key={i}>
              {renderColContent(col, index, event)}
            </TableCell>
          ))}
        </TableRow>
      );
    });

  const renderTableRow = () => (
    <TableRow>
      {getEventListCols().map((col, index) => (
        <TableCell style={{ padding: '0px 20px' }} key={index}>
          {col.label}
        </TableCell>
      ))}
    </TableRow>
  );

  const renderTable = () => (
    <div style={{ width: 'auto', overflowX: 'scroll' }}>
      <Table>
        <TableHead>{renderTableRow()}</TableHead>
        <TableBody>{renderTableCol()}</TableBody>
      </Table>
    </div>
  );

  return (
    <Paper elevation={0} className={classes.paperRoot}>
      <Toolbar>
        <Typography variant="h6">{events[0].lead} (Citas)</Typography>
      </Toolbar>
      {renderTable()}
    </Paper>
  );
};

AppointmentListTable.propTypes = {
  events: PropTypes.array.isRequired,
  renderActionEvents: PropTypes.func.isRequired,
  handlerOnEdit: PropTypes.func.isRequired,
  handlerCancelEvent: PropTypes.func.isRequired,
};

export default withStyles(styles)(AppointmentListTable);
