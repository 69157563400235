import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {createCountry, updateCountry} from '../../../../actions/countries';


const CountryDetails = (props) => {
  const [countryDetail, setCountry] = useState({
    active: true,
    name: ''
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const {isNew, country} = props;
    if (!isNew) {
      setCountry(country);
    }
  }, []);

  const onAddButtonClick = async () => {
    const {name} = countryDetail;
    await dispatch(createCountry({name}));
    props.handleGoBack();
  };

  const onEditButtonClick = async () => {
    const {country} = props;
    const {name, active} = countryDetail;
    await dispatch(updateCountry(country.id, {name, active}));
    props.handleGoBack();
  };

  const handleChangeCountry = (e) => {
    const name = e.target.value;
    setCountry((prevState) => {
      return {...prevState, name};
    });
  };

  const isInvalid = () => {
    const {name} = countryDetail;
    if (name !== '') {
      return false;
    }
    return true;
  };

  const renderButton = () => {
    const {isLoading, isNew, classes} = props;
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} este país?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} país`}
          variant="contained"
          isDisabled={isInvalid()}
          confirmAction={isNew ? onAddButtonClick : onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  };


  const {classes, isLoading, isNew, handleGoBack} = props;
  return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
    <Paper elevation={2} className={classes.paperRoot}>
      <Toolbar>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">
          {!isNew ? 'Detalle de ' : 'Nuevo '} país
        </Typography>
      </Toolbar>
      <div style={{width: 'auto', overflowX: 'scroll'}}>
        <Table>
          <TableBody>
            {
              !isNew &&
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Disponible</b></TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={countryDetail.active}
                        onChange={e => setCountry((prevState) => {
                          return {...prevState, active: e.target.checked};
                        })}
                        color="primary"
                      />
                    }
                    label={countryDetail.active === true ? 'Disponible' : 'No disponible'}
                  />
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell className={classes.tableRowTitle}><b>Nombre</b></TableCell>
              <TableCell>
                <TextField 
                  autoFocus
                  value={countryDetail.name}
                  onChange={handleChangeCountry}
                  fullWidth
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={classes.submitContainer}>
        {renderButton()}
      </div>
    </Paper>
  );
};

CountryDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  country: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired
};

export default (withStyles(styles)(CountryDetails));
