export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';

export const GET_RULES_SENDING = 'GET_RULES_SENDING';

export const GET_RULES_FAILED = 'GET_RULES_FAILED';

export const FILTER_RULES_SUCCESS = 'FILTER_RULES_SUCCESS';

export const FILTER_RULES_SENDING = 'FILTER_RULES_SENDING';

export const FILTER_RULES_FAILED = 'FILTER_RULE_FAILED';

export const CREATE_RULE_SUCCESS = 'CREATE_RULE_SUCCESS';

export const CREATE_RULE_SENDING = 'CREATE_RULE_SENDING';

export const CREATE_RULE_FAILED = 'CREATE_RULE_FAILED';

export const UPDATE_RULE_SUCCESS = 'UPDATE_RULE_SUCCESS';

export const UPDATE_RULE_SENDING = 'UPDATE_RULE_SENDING';

export const UPDATE_RULE_FAILED = 'UPDATE_RULE_FAILED';
