export const styles = theme => ({
  root: {
    display: 'flex'
  },
  br: {
    width: '100%',
    height: 25
  },
  appBarSpacer: theme.mixins.toolbar,
  row: {

  },
  delete: {
    alignSelf: 'flex-end',
    display: 'inline-block',
    float: 'right',
    verticalAlign: 'top'
  },
  block: {
    
  },
  unblock: {
    color: 'red'
  },
  rightIcon: {
    marginLeft: 10
  },
  content: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    height: '100%',
    backgroundColor: '#F5F5F6',
    width: '100%'
  },
  paperRoot: {
    width: '95%',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 3
  },
  submitContainer: {
    height: '100%',
    display: 'table',
    margin: '0 auto',
    verticalAlign: 'middle'
  },
  tableRowTitle: {
    width: 100
  },
  textInput: {
    marginBottom: theme.spacing.unit * 2
  },
  fontAwesomeIcon: {
    textAlign: 'center',
    margin: '0 auto',
    display: 'table'
  },
  spacer: {
    flex: '1 1 100%'
  },
  smallBar: {
    minHeight: 30,
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0
  },
  preferenceCell: {
    width: 200,
    padding: 10,
    display: 'inline-block',
    marginBottom: 40
  },
  preferenceTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  preferenceSubtitle: {
    textAlign: 'center'
  },
  preferenceContainer: {
    maxWidth: '100%',
    textAlign: 'center'
  },
  matchContainer: {
    width: 300,
    margin: 10,
    display: 'inline-block',
    verticalAlign: 'top',
    padding: 10,
    borderColor: '#f1f1f1',
    borderWidth: 0.2,
    borderStyle: 'solid'
  },
  matchButton : {
    padding: '10px'
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    '&:focus': {
      textDecoration: 'none'
    }, 
    '&:hover': {
      textDecoration: 'none'
    }, 
    '&:visited': {
      textDecoration: 'none'
    }, 
    '&:link': {
      textDecoration: 'none'
    }, 
    '&:active': {
      textDecoration: 'none'
    }
  },
  match: {
    paddingTop: 6,
    paddingRight: 8,
    paddingBottom: 6,
    paddingLeft: 8,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  matchImage: {
    width: 280,
    height: 200
  },
  fontSize: {
    fontSize: 12
  },
  projectTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  cursor: {
    cursor: 'move',
    display: 'inline-block'
  },
  pointer: {
    cursor: 'pointer'
  },
  confirmationDialogTitle: {
    fontSize: 24,
    padding: 25,
    paddingBottom: 0
  }
});

export default styles;
