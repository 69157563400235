import * as parametersActions from '../actions/types/parameters';

const initialState = {
  sending: false,
  message: '',
  parameters: [],
  allParameters: [],
  dataLength: 0,
  dataLengthAllParameters: 0,
  error: null,
  page: 0,
  rowsPerPage: 20,
  category: 'personal',
  searchText: '',
  order: 'asc',
  orderBy: 'name',
  filters: {}
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, parameters, allParameters, dataLengthAllParameters, message, dataLength, category, rowsPerPage, page, order, orderBy, searchText, filters} = action.payload;
  switch (action.type) {
  case parametersActions.GET_PARAMETERS_SENDING:
    return {...state, sending: true, dataLength, category};
  case parametersActions.GET_PARAMETERS_SUCCESS:
    return {...state, sending: false, message: 'Parámetros obtenidos satisfactoriamente', parameters, error: null, dataLength};
  case parametersActions.GET_PARAMETERS_FAILED:
    return {...state, sending: false, message, error};
  case parametersActions.GET_ALL_PARAMETERS_SENDING:
    return {...state, sending: true, dataLengthAllParameters};
  case parametersActions.GET_ALL_PARAMETERS_SUCCESS:
    return {...state, sending: false, message: 'Parámetros obtenidos satisfactoriamente', allParameters, error: null, dataLengthAllParameters};
  case parametersActions.GET_ALL_PARAMETERS_FAILED:
    return {...state, sending: false, message, error};
  case parametersActions.FILTER_PARAMETERS_SENDING:
    return {...state, sending: true, dataLength, category, rowsPerPage, page, order, orderBy, searchText, filters};
  case parametersActions.FILTER_PARAMETERS_SUCCESS:
    return {...state, sending: false, message: 'Parámetros obtenidos satisfactoriamente', parameters, error: null, dataLength};
  case parametersActions.FILTER_PARAMETERS_FAILED:
    return {...state, sending: false, message, error};
  case parametersActions.SORT_PARAMETERS_SENDING:
    return {...state, sending: true};
  case parametersActions.SORT_PARAMETERS_SUCCESS:
    return {...state, sending: false, message: 'Parámetros ordenados satisfactoriamente', error: null};
  case parametersActions.SORT_PARAMETERS_FAILED:
    return {...state, sending: false, message, error};
  case parametersActions.ADD_PARAMETER_SENDING:
    return {...state, sending: true};
  case parametersActions.ADD_PARAMETER_SUCCESS:
    return {...state, sending: false, message: 'Parámetro creado satisfactoriamente', error: null};
  case parametersActions.ADD_PARAMETER_FAILED:
    return {...state, sending: false, message, error};
  case parametersActions.EDIT_PARAMETER_SENDING:
    return {...state, sending: true};
  case parametersActions.EDIT_PARAMETER_SUCCESS:
    return {...state, sending: false, message: 'Parámetros modificado satisfactoriamente', error: null};
  case parametersActions.EDIT_PARAMETER_FAILED:
    return {...state, sending: false, message, error};
  default:
    return state;
  }
};
