import * as cityActions from '../actions/types/cities';

const initialState = {
  cities: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {cities, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case cityActions.FILTER_CITIES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case cityActions.FILTER_CITIES_SUCCESS:
    return {...state, sending: false, cities, error: null, message: 'Provincias obtenidos satisfactoriamente', dataLength};
  case cityActions.FILTER_CITIES_FAILED:
    return {...state, sending: false, cities: [], error, message, dataLength};
  case cityActions.GET_CITIES_SENDING:
    return {...state, sending: true};
  case cityActions.GET_CITIES_SUCCESS:
    return {...state, sending: false, cities, error: null, message: 'Ciudades obtenidas satisfactoriamente', dataLength};
  case cityActions.GET_CITIES_FAILED:
    return {...state, sending: false, cities: [], error, message, dataLength};
  case cityActions.CREATE_CITY_SENDING:
    return {...state, sending: true};
  case cityActions.CREATE_CITY_SUCCESS:
    return {...state, sending: false, error: null, message: 'Ciudad creada satisfactoriamente'};
  case cityActions.CREATE_CITY_FAILED:
    return {...state, sending: false, error, message};
  case cityActions.UPDATE_CITY_SENDING:
    return {...state, sending: true};
  case cityActions.UPDATE_CITY_SUCCESS:
    return {...state, sending: false, error: null, message: 'Ciudad actualizada satisfactoriamente'};
  case cityActions.UPDATE_CITY_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
