export const styles = (theme) => ({
  customDialog: {
    height: '100%',
    width: '100%',
    paddingRight: '0px !important',
    '& div:nth-child(2)': {
      '& div:nth-child(1)': {
        borderRadius: 24,
      },
    },
  },
  dialogContent: {
    padding: '24px 4%',
    '& *': {
      maxWidth: '100%'
    },
    '& img': {
      objectFit: 'cover',
      height: 'auto !important'
    }
  },
});

export default styles;
