import * as proformasAction from '../actions/types/proformas';

const initialState = {
  companyConfig: [],
  message: '',
  sending: false,
  error: null,
  validProforma: false,
  currentProjectValidProforma: null,
  units: [],
  defaultConfigurations: [],
  proformas: [],
  validUnits: [],
  projects: [],
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {
    companyConfig,
    error,
    message,
    currentProjectValidProforma,
    validProforma,
    units,
    defaultConfigurations,
    proformas,
    validUnits,
    projects,
  } = action.payload;
  switch (action.type) {
    case proformasAction.GET_PROFORMA_COMPANY_CONFIG_SENDING:
      return { ...state, sending: true };
    case proformasAction.GET_PROFORMA_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        sending: false,
        companyConfig,
        error: null,
        message:
          'Configuracion de Proformas por Empresa obtenidas satisfactoriamente',
      };
    case proformasAction.GET_PROFORMA_COMPANY_CONFIG_FAILED:
      return { ...state, sending: false, companyConfig: [], error, message };
    case proformasAction.UPDATE_PROFORMA_COMPANY_CONFIG_SENDING:
      return { ...state, sending: true };
    case proformasAction.UPDATE_PROFORMA_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        message:
          'Configuracion de Proformas por Empresa actualizadaS satisfactoriamente',
      };
    case proformasAction.UPDATE_PROFORMA_COMPANY_CONFIG_FAILED:
      return { ...state, sending: false, error, message };
    case proformasAction.VALID_PROFORMA_COMPANY_CONFIG_SENDING:
      return {
        ...state,
        sending: true,
        currentProjectValidProforma: null,
        validProforma: false,
        validUnits: [],
      };
    case proformasAction.VALID_PROFORMA_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        message: '',
        currentProjectValidProforma,
        validProforma,
        validUnits,
      };
    case proformasAction.VALID_PROFORMA_COMPANY_CONFIG_FAILED:
      return {
        ...state,
        sending: false,
        error,
        message: 'Pendiente configuración de proyecto',
        currentProjectValidProforma,
        validProforma: false,
      };
    case proformasAction.GET_UNITS_PROFORMA_COMPANY_CONFIG_SENDING:
      return {
        ...state,
        sending: true,
        currentProjectValidProforma: null,
        validProforma: false,
      };
    case proformasAction.GET_UNITS_PROFORMA_COMPANY_CONFIG_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        message: '',
        units,
        defaultConfigurations,
      };
    case proformasAction.GET_UNITS_PROFORMA_COMPANY_CONFIG_FAILED:
      return {
        ...state,
        sending: false,
        error,
        message: 'Pendiente configuración de proyecto',
        currentProjectValidProforma,
        validProforma: false,
      };
    case proformasAction.CREATE_PROFORMA_SENDING:
      return { ...state, sending: true };
    case proformasAction.CREATE_PROFORMA_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        message: 'Proforma creada satisfactoriamente',
      };
    case proformasAction.CREATE_PROFORMA_FAILED:
      return { ...state, sending: false, error, message };
    case proformasAction.GET_PROFORMAS_SENDING:
      return { ...state, sending: true, projects: [] };
    case proformasAction.GET_PROFORMAS_SUCCESS:
      return {
        ...state,
        sending: false,
        proformas,
        error: null,
        message: 'Proformas obtenidas satisfactoriamente',
      };
    case proformasAction.GET_PROFORMAS_FAILED:
      return {
        ...state,
        sending: false,
        projects: [],
        error,
        message,
      };
    case proformasAction.GET_PROJECTS_BY_LEAD_SENDING:
      return { ...state, sending: true, proformas: [] };
    case proformasAction.GET_PROJECTS_BY_LEAD_SUCCESS:
      return {
        ...state,
        sending: false,
        projects,
        error: null,
        message: 'Proformas obtenidas satisfactoriamente',
      };
    case proformasAction.GET_PROJECTS_BY_LEAD_FAILED:
      return {
        ...state,
        sending: false,
        projects: [],
        error,
        message,
      };
    default:
      return state;
  }
};
