export const GET_PROJECTS_SENDING = 'GET_PROJECTS_SENDING';

export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';

export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED';


export const GET_PROJECT_SENDING = 'GET_PROJECT_SENDING';

export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';

export const GET_PROJECT_FAILED = 'GET_PROJECT_FAILED';


export const GET_PROJECT_IMAGES_SENDING = 'GET_PROJECT_IMAGES_SENDING';

export const GET_PROJECT_IMAGES_SUCCESS = 'GET_PROJECT_IMAGES_SUCCESS';

export const GET_PROJECT_IMAGES_FAILED = 'GET_PROJECT_IMAGES_FAILED';


export const FILTER_PROJECTS_SENDING = 'FILTER_PROJECTS_SENDING';

export const FILTER_PROJECTS_SUCCESS = 'FILTER_PROJECTS_SUCCESS';

export const FILTER_PROJECTS_FAILED = 'FILTER_PROJECTS_FAILED';


export const ADD_PROJECT_SENDING = 'ADD_PROJECT_SENDING';

export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';

export const ADD_PROJECT_FAILED = 'ADD_PROJECT_FAILED';


export const EDIT_PROJECT_SENDING = 'EDIT_PROJECT_SENDING';

export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';

export const EDIT_PROJECT_FAILED = 'EDIT_PROJECT_FAILED';


export const EDIT_PROJECT_ATTACHMENTS_SENDING = 'EDIT_PROJECT_ATTACHMENTS_SENDING';

export const EDIT_PROJECT_ATTACHMENTS_SUCCESS = 'EDIT_PROJECT_ATTACHMENTS_SUCCESS';

export const EDIT_PROJECT_ATTACHMENTS_FAILED = 'EDIT_PROJECT_ATTACHMENTS_FAILED';


export const CLONE_PROJECT_SENDING = 'CLONE_PROJECT_SENDING';

export const CLONE_PROJECT_FAILED = 'CLONE_PROJECT_FAILED';

export const GET_PROJECT_UNITS_SENDING = 'GET_PROJECT_UNITS_SENDING';

export const GET_PROJECT_UNITS_SUCCESS = 'GET_PROJECT_UNITS_SUCCESS';

export const GET_PROJECT_UNITS_FAILED = 'GET_PROJECT_UNITS_FAILED';
