import React, {useState, useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector, useDispatch} from 'react-redux';
import {filterPlans, getGraphs} from '../../../actions/plans';
import EnhancedTable from '../../common/enhancedTable';
import {plansCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import PlanDetails from './detail';

const profile = JSON.parse(localStorage.getItem('profile'));

const getViewPermission = () => {
  if (profile.permissions.view_plans) {
    return true;
  }
  window.location.href = '/';
  return false;
};

const Plans = (props) => {
  const title = 'Planes';
  const {page, rowsPerPage, order, orderBy, plans, searchText, sending, dataLength, error, message,graphs} = useSelector(state => state.plans);
  const [plan, setPlan] = useState(null);
  const [isNew, setIsNew] = useState(false);


  const dispatch = useDispatch();

  useEffect(() => {
    getViewPermission();
    dispatch(filterPlans(page, rowsPerPage, order, orderBy, searchText));
    dispatch(getGraphs());
  }, []);

  const onSearch = (searchedText) => {
    dispatch(filterPlans(page, rowsPerPage, order, orderBy, searchedText));
  };

  const onAdd = () => {
    setPlan({});
    setIsNew(true);
  };

  const onDetail = (id) => {
    const planLocal = plans.filter(obj => obj.id === id)[0];
    setPlan(planLocal);
    setIsNew(false);
  };

  const handleChangeRowsPerPage = (rowsPerPageLocal) => {
    dispatch(filterPlans(page, rowsPerPageLocal, order, orderBy, searchText));
  };

  const handleChangePage = (pageLocal) => {
    dispatch(filterPlans(pageLocal, rowsPerPage, order, orderBy, searchText));
  };

  const onOrder = (orderLocal, orderByLocal) => {
    dispatch(filterPlans(page, rowsPerPage, orderLocal, orderByLocal, searchText));
  };

  const getEditPermissions = () => {
    if (profile) {
      if (profile.permissions.update_plans) {
        return true;
      }
    }
    return false;
  };

  const getCreatePermission = () => {
    if (profile) {
      if (profile.permissions.create_plans) {
        return true;
      }
    }
    return false;
  };

  const handleGoBack = () => {
    dispatch(filterPlans(page, rowsPerPage, order, orderBy, searchText));
    setPlan(null);
    setIsNew(false);
  };

  const renderSnackBar = () => {
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  };

  const isLoading = sending;
  const hasEditPermission = getEditPermissions();
  const hasCreatePermission = false;
  const data = {
    rows: plans,
    cols: plansCols
  };
  const handlers = {
    onDetail,
    onAdd,
    onSearch,
    onOrder,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage
  };
  const config = {
    isDragable: false,
    isSelectable: false,
    hasEditPermission,
    hasCreatePermission,
    isFiterable: false,
    searchText: searchText || '',
    order: {
      by: orderBy,
      direction: order
    },
    pagination: {
      current: page,
      rowsPerPage,
      totalItems: dataLength
    },
    filters: {
      is: false
    }
  };
  return (
    isLoading === true ?
      <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} />
      :
      <div>
        {renderSnackBar()}
        {  
          !plan ?
            <EnhancedTable
              title={title}
              data={data}
              handlers={handlers}
              config={config}
            />
            :
            <PlanDetails
              plan={plan}
              graphs={graphs}
              handleGoBack={handleGoBack}
              isLoading={isLoading}
              isNew={isNew}
              
            />
        }
      </div>
  );
};
  
export default Plans;
