import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {
  filterEmailTemplates,
  createEmailTemplate,
  updateEmailTemplate
} from '../../../actions/emailTemplates';
import EnhancedTable from '../../common/enhancedTable';
import {emailTemplatesCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import EmailTemplateDetail from './detail';
import {getCompanies} from '../../../actions/companies';

export class EmailTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailTemplate: null,
      isNew: false,
      title: 'Plantillas de Correo'
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleUpdateEmailTemplate = this.handleUpdateEmailTemplate.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.getCompanies();
    this.props.filterEmailTemplates(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  onAdd() {
    this.setState({isNew: true, emailTemplate: {}});
  }

  onDetail(id) {
    const emailTemplate = this.props.emailTemplates.filter(obj => obj.id === id)[0];
    this.setState({emailTemplate, isNew: false});
  }

  onOrder(order, orderBy) {
    this.props.filterEmailTemplates(
      this.props.page,
      this.props.rowsPerPage,
      order,
      orderBy,
      this.props.searchText
    );
  }

  onSearch(searchText) {
    this.props.filterEmailTemplates(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      searchText
    );
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_email_templates) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  };

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_email_templates) {
        return true;
      }
    }
    return false;
  };

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_email_templates) {
        return true;
      }
    }
    return false;
  };

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterEmailTemplates(
      this.props.page,
      rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  handleChangePage(page) {
    this.props.filterEmailTemplates(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  handleGoBack() {
    this.props.filterEmailTemplates(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
    this.setState({emailTemplate: null, isNew: false});
  }

  handleUpdateEmailTemplate(emailTemplateEdited) {
    const {emailTemplate} = this.state;
    this.setState({emailTemplate: emailTemplateEdited});
    const edited = {};
    if (emailTemplate.active !== emailTemplateEdited.active) {
      edited.active = emailTemplateEdited.active;
    }
    if (emailTemplate.name !== emailTemplateEdited.name) {
      edited.name = emailTemplateEdited.name;
    }
    if (emailTemplate.template !== emailTemplateEdited.template) {
      edited.template = emailTemplateEdited.template;
    }
    if (emailTemplate.subject !== emailTemplateEdited.subject) {
      edited.subject = emailTemplateEdited.subject;
    }
    if (emailTemplate.isDefault !== emailTemplateEdited.isDefault) {
      edited.isDefault = emailTemplateEdited.isDefault;
    }
    if (emailTemplate.company !== emailTemplateEdited.company) {
      edited.company = emailTemplateEdited.company;
    }
    this.props.updateEmailTemplate(emailTemplateEdited.id, edited);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, emailTemplates, dataLength, companies} = this.props;
    const {title, emailTemplate, isNew} = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const data = {
      rows: emailTemplates,
      cols: emailTemplatesCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      hasEditPermission,
      hasCreatePermission,
      isSelectable: false,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };

    if (isLoading === true) {
      return (
        <CircularProgress
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto'
          }}
        />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {emailTemplate === null ? (
          <EnhancedTable
            title={title}
            data={data}
            handlers={handlers}
            config={config}
          />
        ) : (
          <EmailTemplateDetail
            emailTemplate={emailTemplate}
            handleGoBack={this.handleGoBack}
            handleCreateEmailTemplate={this.props.createEmailTemplate}
            handleUpdateEmailTemplate={this.handleUpdateEmailTemplate}
            isLoading={isLoading}
            isNew={isNew}
            companies={companies}
          />
        )}
      </div>
    );
  }
}

EmailTemplates.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  filterEmailTemplates: PropTypes.func.isRequired,
  createEmailTemplate: PropTypes.func.isRequired,
  updateEmailTemplate: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  error: PropTypes.object,
  getCompanies: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
  const {emailTemplates, companies} = state;
  return {
    isLoading: emailTemplates.sending,
    emailTemplates: emailTemplates.emailTemplates,
    dataLength: emailTemplates.dataLength,
    error: emailTemplates.error,
    message: emailTemplates.message,
    searchText: emailTemplates.searchText,
    order: emailTemplates.order,
    orderBy: emailTemplates.orderBy,
    page: emailTemplates.page,
    rowsPerPage: emailTemplates.rowsPerPage,
    filters: emailTemplates.filters,
    companies: companies.companies
  };
};

const mapDispatchToProps = {
  filterEmailTemplates,
  createEmailTemplate,
  updateEmailTemplate,
  getCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
