import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Paper,
  Toolbar
} from '@material-ui/core';
import {styles} from './styles';
import {filterLeadStates, createLeadState, updateLeadState, swapStatuses} from '../../../actions/leadStates';
import {getProfile} from '../../../actions/profiles';
import EnhancedTable from '../../common/enhancedTable';
import {leadStatesCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import LeadStateDetail from './detail';
import {SUPER_ADMIN_ROL_ID, sortObjectByProperty} from '../../../utils/utils';


export class LeadStates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {           
      leadState: null,
      isNew: false,
      title: 'Estados de Lead',
      expanded: null
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleUpdateLeadState = this.handleUpdateLeadState.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    const profileId = localStorage.getItem('profileId');
    if (profileId) {
      this.props.getProfile(profileId).then(() => {
        this.props.filterLeadStates(
          this.props.page,
          this.props.rowsPerPage,
          this.props.order,
          this.props.orderBy,
          this.props.searchText
        );
      });
    } else {
      window.location.href = '/login';
    }
  }

  onAdd() {
    this.setState({isNew: true, leadState: {}});
  }

  onDetail(id) {
    const leadState = this.props.leadStates.filter(obj => obj.id === id)[0];
    this.setState({leadState, isNew: false});
  }

  onOrder(order, orderBy) {
    this.props.filterLeadStates(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText);
  }

  async onDrag(dragValues) {
    const {oldIndex, newIndex} = dragValues; 
    const {leadStates} = this.props; 
    this.props.swapStatuses(leadStates[oldIndex].id, leadStates[newIndex].id).then(() => {
      this.props.filterLeadStates(
        this.props.page,
        this.props.rowsPerPage,
        this.props.order,
        this.props.orderBy,
        this.props.searchText
      );
    });
  }

  onSearch(searchText) {
    this.props.filterLeadStates(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_lead_statuses) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_lead_statuses) {
        return true;
      }
    }
    return false;
  }

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_lead_statuses) {
        return true;
      }
    }
    return false;
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterLeadStates(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleChangePage(page) {
    this.props.filterLeadStates(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleGoBack() {
    this.setState({leadState: null, isNew: false});
    this.props.filterLeadStates(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleUpdateLeadState(leadStateEdited) {
    const {leadState} = this.state;
    this.setState({leadState: leadStateEdited});
    const edited = {};
    if (leadState.active !== leadStateEdited.active) {
      edited.active = leadStateEdited.active;
    }
    if (leadState.name !== leadStateEdited.name) {
      edited.name = leadStateEdited.name;
    }
    if (leadState.icon !== leadStateEdited.icon) {
      edited.icon = leadStateEdited.icon;
    }
    this.props.updateLeadState(leadStateEdited.id, edited);
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, isLoadingProfile, leadStates, dataLength, profile} = this.props;
    const {title, leadState, isNew} = this.state;

    const profileId = localStorage.getItem('profileId');
    const isSuperAdmin = SUPER_ADMIN_ROL_ID === profileId;

    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const data = {
      rows: leadStates,
      cols: leadStatesCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onDrag: this.onDrag,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: !!isSuperAdmin,
      hasEditPermission,
      hasCreatePermission,
      dragIndexOnly: true,
      isSelectable: false,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };
    if (isLoading === true || isLoadingProfile === true) {
      return (
        <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} />
      );
    }
    if (!isSuperAdmin) {
      const {expanded} = this.state;
      const {classes} = this.props;

      return (
        <Paper elevation={2} className={classes.paperRoot}>
          <div className={classes.root}>
            <Toolbar>
              <Typography variant="h6">
                Estados de lead
              </Typography>
            </Toolbar>
            {
              leadStates.map((leadStatesItem) => {
                const stagesOrderedByCompany = sortObjectByProperty(leadStatesItem.stages, 'company');
                const stagesOrderedByCompanyAndOrder = sortObjectByProperty(stagesOrderedByCompany, 'order');
                return (
                  <ExpansionPanel key={leadStatesItem.id} expanded={expanded === leadStatesItem.id} onChange={this.handleChange(leadStatesItem.id)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>{leadStatesItem.name}</Typography>
                      <Typography className={classes.secondaryHeading}>Etapas</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <List >
                        {
                          stagesOrderedByCompanyAndOrder.map((stageItem, index) => {
                            return (
                              <ListItem key={index} >
                                <ListItemText primary={stageItem.name} />
                              </ListItem>);
                          })
                        }
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })
            }
          </div>
        </Paper>
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {
          leadState === null ?
            <EnhancedTable 
              title={title}
              data={data} 
              handlers={handlers}
              config={config}
            />  
            :
            <LeadStateDetail 
              leadState={leadState}
              handleGoBack={this.handleGoBack}
              isLoading={isLoading}
              isNew={isNew}
              profile={profile}
            />
        }
      </div>
    );
  }
}

LeadStates.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  leadStates: PropTypes.array.isRequired,
  isLoadingProfile: PropTypes.bool.isRequired,
  profile: PropTypes.object,
  filterLeadStates: PropTypes.func.isRequired,
  createLeadState: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  updateLeadState: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  swapStatuses: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {leadStates, profiles} = state;
  return { 
    isLoading: leadStates.sending, 
    leadStates: leadStates.leadStates,
    isLoadingProfile: profiles.sending, 
    profile: profiles.profile,
    dataLength: leadStates.dataLength, 
    error: leadStates.error,
    message: leadStates.message,
    searchText: leadStates.searchText,
    order: leadStates.order,
    orderBy: leadStates.orderBy,
    page: leadStates.page,
    rowsPerPage: leadStates.rowsPerPage,
    filters: leadStates.filters
  };
};
  
const mapDispatchToProps = {
  filterLeadStates,
  createLeadState,
  updateLeadState, 
  getProfile,
  swapStatuses
};
  
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LeadStates));
