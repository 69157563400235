import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import EnhancedTable from '../../common/enhancedTable';
import {wizardCols, parametersCols, paramsOrderCols} from '../../../utils/colTypes';
import {parameterOptions} from '../../../utils/filterTypes';
import ParametersDetail from './detail';
import {SUPER_ADMIN_ROL_ID} from '../../../utils/utils';

export class ParametersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatColsFromUser = (cols) => {
    const profileId = localStorage.getItem('profileId');
    let newCols = cols;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      newCols = cols.filter(col => col.id !== 'enabled');
    }
    return newCols;
  }
    
  render() {
    const {category, parameters, isLoading, parameter, isNew, allParameters} = this.props;
    const hasEditPermission = true;
    let hasCreatePermission = true;
    const profileId = localStorage.getItem('profileId');
    if (SUPER_ADMIN_ROL_ID !== profileId || category === 'orders') { 
      hasCreatePermission = false;
    } 

    let cols = [];

    if (category === 'wizard') cols = wizardCols;
    else if (category === 'orders') cols = paramsOrderCols;
    else cols = this.formatColsFromUser(parametersCols);
    const data = {rows: parameters, cols};
    const handlers = {
      onDetail: this.props.onEditRowClick,
      onAdd: this.props.handleAddNew,
      onSearch: this.props.handleSearch,
      onFilter: this.props.saveFilters,
      onOrder: this.props.orderData,
      onChangePage: this.props.handleChangePage,
      onChangeRowsPerPage: this.props.handleChangeRowsPerPage,
      onDrag: this.props.isSorted
    };
    const config = {
      isDragable: category === 'wizard' || category === 'orders',
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: this.props.dataLength
      },
      filters: {
        is: true,
        options: parameterOptions,
        chips: this.props.filtersToShow,
        by: this.props.filters
      }
    };
    return this.props.isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <div>
        {
          parameter === null ?
            <div>
              {
                parameters &&   
                <EnhancedTable 
                  title="Perfilamiento" 
                  data={data} 
                  handlers={handlers}
                  config={config}
                />
              }
            </div>
            :
            <ParametersDetail 
              parameter={parameter}
              handleGoBack={this.props.handleGoBack}
              allParameters={allParameters}
              isNew={isNew}
              onChangeCategory={this.props.handleChangeCategory}
              propertyTypes={this.props.propertyTypes}
              category={category}
              createParameter={this.props.createParameter}
              editParameter={this.props.editParameter}
              isLoading={isLoading}
            />

        }
      </div>
    );
  }
}
ParametersList.propTypes = {
  parameter: PropTypes.object,
  parameters: PropTypes.array.isRequired,
  allParameters: PropTypes.array.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  dataLength: PropTypes.number.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleChangeCategory: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  createParameter: PropTypes.func.isRequired,
  editParameter: PropTypes.func.isRequired,
  isSorted: PropTypes.func.isRequired,
  orderData: PropTypes.func.isRequired,
  saveFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  filtersToShow: PropTypes.array.isRequired,
  onEditRowClick: PropTypes.func.isRequired,
  handleAddNew: PropTypes.func.isRequired
};

export default ParametersList;
