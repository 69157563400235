import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import AppointmentModal from '../../../common/appointmentModal';
import { getEventsStatistics } from '../../../../actions/calendar';

const broker = localStorage.getItem('user');

export const Revisita = ({ lead, classes, toggleAppoinmentModal, isOpenAppointmentModal }) => {
  const dispatch = useDispatch();

  const { statistics = {}, sending } = useSelector((state) => state.eventsStatistics);

  const fetchEventsStatistics = () =>
    dispatch(getEventsStatistics(lead._id, { leads: [lead._id], brokers: [broker] }));

  const handlerCloseModal = () => toggleAppoinmentModal(false);
  const handlerOpenModal = () => toggleAppoinmentModal(true);

  useEffect(() => {
    fetchEventsStatistics();
  }, [lead]);

  if (sending) {
    return null;
  }

  if (!statistics[lead._id] || (statistics[lead._id] && !statistics[lead._id].length)) {
    return null;
  }

  const { activeEventsWithHasVisitActive = 0 } = statistics[lead._id][0] || {};

  if (activeEventsWithHasVisitActive > 0) {
    return (
      <>
        {isOpenAppointmentModal && (
          <AppointmentModal
            isEventFlag
            isRevisit
            open={isOpenAppointmentModal}
            close={handlerCloseModal}
            lead={lead._id}
          />
        )}
        <Button
          type="button"
          variant="contained"
          className={classes.submit}
          color="primary"
          onClick={handlerOpenModal}
        >
          Revisita
        </Button>
      </>
    );
  }

  return null;
};
