export const FILTER_COMMISSIONS_SUCCESS = 'FILTER_COMMISSIONS_SUCCESS';

export const FILTER_COMMISSIONS_SENDING = 'FILTER_COMMISSIONS_SENDING';

export const FILTER_COMMISSIONS_FAILED = 'FILTER_COMMISSIONS_FAILED';

export const UPDATE_COMMISSION_SUCCESS = 'UPDATE_COMMISSION_SUCCESS';

export const UPDATE_COMMISSION_SENDING = 'UPDATE_COMMISSION_SENDING';

export const UPDATE_COMMISSION_FAILED = 'UPDATE_COMMISSION_FAILED';
