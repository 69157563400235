export const GET_WHATSAPP_TEMPLATES_SUCCESS = 'GET_WHATSAPP_TEMPLATES_SUCCESS';

export const GET_WHATSAPP_TEMPLATES_SENDING = 'GET_WHATSAPP_TEMPLATES_SENDING';

export const GET_WHATSAPP_TEMPLATES_FAILED = 'GET_WHATSAPP_TEMPLATES_FAILED';

export const FILTER_WHATSAPP_TEMPLATES_SUCCESS = 'FILTER_WHATSAPP_TEMPLATES_SUCCESS';

export const FILTER_WHATSAPP_TEMPLATES_SENDING = 'FILTER_WHATSAPP_TEMPLATES_SENDING';

export const FILTER_WHATSAPP_TEMPLATES_FAILED = 'FILTER_WHATSAPP_TEMPLATES_FAILED';

export const CREATE_WHATSAPP_TEMPLATE_SUCCESS = 'CREATE_WHATSAPP_TEMPLATE_SUCCESS';

export const CREATE_WHATSAPP_TEMPLATE_SENDING = 'CREATE_WHATSAPP_TEMPLATE_SENDING';

export const CREATE_WHATSAPP_TEMPLATE_FAILED = 'CREATE_WHATSAPP_TEMPLATE_FAILED';

export const UPDATE_WHATSAPP_TEMPLATE_SUCCESS = 'UPDATE_WHATSAPP_TEMPLATE_SUCCESS';

export const UPDATE_WHATSAPP_TEMPLATE_SENDING = 'UPDATE_WHATSAPP_TEMPLATE_SENDING';

export const UPDATE_WHATSAPP_TEMPLATE_FAILED = 'UPDATE_WHATSAPP_TEMPLATE_FAILED';
