import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import EnhancedTableCell from './enhancedTableCell';
import { styles } from './styles';
import { isNumber, dateFormat, SUPER_ADMIN_ROL_ID, ASESOR_ID } from '../../../../utils/utils';
import moment from 'moment';

export class EnhancedTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderCells() {
    const {
      row,
      rowIndex,
      cols,
      hasAutoFillers,
      autoFillers,
      isAddingNew,
      hasEditPermission,
      allowAddToLead,
      renderMockup,
    } = this.props;
    return cols.map((column, index) => {
      let cellData = row[column.id];
      if (column.id === 'complete_name' && row.repeated) {
        // eslint-disable-next-line no-param-reassign
        column.type = 'text';
        if (row.repeated > 1) {
          // eslint-disable-next-line no-param-reassign
          column.type = 'link';
          cellData += ` (${row.repeated})`;
        }
      }
      let suggestions = [];
      if (hasAutoFillers) {
        const { autoCompleteWith } = column;
        if (autoCompleteWith) {
          if (autoFillers[autoCompleteWith]) {
            if (autoFillers[autoCompleteWith].length > 0) {
              let activeAutoFillers = autoFillers[autoCompleteWith];
              if (isAddingNew && rowIndex === 0) {
                activeAutoFillers = autoFillers[autoCompleteWith].filter(
                  (obj) => obj.active === true || obj.status === true,
                );
              }
              suggestions = activeAutoFillers.map((obj) => {
                return {
                  value: obj.id,
                  label: obj.name,
                };
              });
              if (typeof row[column.id] === 'object' && row[column.id] !== null) {
                if (column.isBroker) {
                  const brokersArr = [];
                  for (let i = 0; i < row[column.id].length; i += 1) {
                    const broker = row[column.id][i];
                    const brokerToPush = suggestions
                      .filter((obj) => obj.value === broker.userId)
                      .shift();
                    if (brokerToPush) {
                      brokersArr.push(brokerToPush.label);
                    }
                  }
                  cellData = brokersArr.join(', ');
                } else {
                  cellData = suggestions.filter((obj) => obj.value === row[column.id].id).shift();
                }
              } else {
                cellData = suggestions.filter((obj) => obj.value === row[column.id]).shift();
              }
              if (column.type !== 'editableText' && cellData) {
                if (cellData.label) {
                  cellData = cellData.label;
                }
              }
            }
          }
        } else if (column.defaultId) {
          const { preferences } = row;
          const preference = preferences.filter((obj) => column.defaultId === obj.attributeId)[0];
          if (preference) {
            if (preference.answerValues) {
              cellData = preference.answerValues.join(', ');
            } else {
              cellData = ' - ';
            }
          } else {
            cellData = ' - ';
          }
        }
      }
      if ((column.type === 'edit' || column.type === 'duplicate') && !hasEditPermission) {
        return null;
      }
      if (column.id === 'addToLead') {
        cellData = row;
      }
      return (
        <EnhancedTableCell
          type={column.type}
          key={`${column.id}-${index}`}
          row={row}
          allowAddToLead={allowAddToLead}
          onAddToLead={this.props.onAddToLead}
          cellData={cellData}
          onDetail={() => this.props.onDetail(row, rowIndex)}
          onEvent={() => this.props.onEvent(row)}
          onDuplicate={() => this.props.onDuplicate(row, rowIndex)}
          onAttributes={() => this.props.onAttributes(row)}
          onAttach={() => this.props.onAttach(row)}
          onSave={() => this.props.onSave(row)}
          onUpdate={() => this.props.onUpdate(row)}
          onViewHistory={() => this.props.onViewHistory(row)}
          onGetAllLeadsOfUser={() =>  this.props.onGetAllLeadsOfUser(row.userId, row.complete_name, row.userDocument, row.phone) }
          suggestions={suggestions}
          handleChangeCell={(text) => this.props.onChangeCell(text, column)}
          openMapModal={() => this.props.openMapModal(row)}
          onAddUser={() => this.props.onAddUser(row.id)}
          onGenerateCredentials={() => this.props.onGenerateCredentials(row.id)}
          renderMockup={() => this.props.renderMockup(row, rowIndex)}
          isNoClearable={column.isNoClearable}
          onCloneProject={() =>
            this.props.onCloneProject({
              projectId: row.id,
              companyId: row.companyId,
            })
          }
          onProformas={() => this.props.onProformas(row)}
          onProformaAdmin={() => this.props.onProformaAdmin(row)}
          onAddUnits={this.props.onAddUnits}
        />
      );
    });
  }

  validRenderCheck(row) {
    let renderCheckGLobal = true; // check para validar qeu el asesor senior no pueda hacer acciones no permitidas en este caso reasignar un lead que no es suyo
    const profileId = localStorage.getItem('profileId');
    const userIdStorage = localStorage.getItem('user');
    if (
      (window.location.pathname == '/' || window.location.pathname == '/leads') &&
      profileId == ASESOR_ID
    ) {
      if (row.brokerId !== userIdStorage) {
        renderCheckGLobal = false;
      }
    }
    return renderCheckGLobal;
  }

  render() {
    const { isSelectable, isSortable, classes, isSelected, row } = this.props;
    let renderEdit = true;
    const profileId = localStorage.getItem('profileId');
    const userIdStorage = localStorage.getItem('user');
    if (
      (window.location.pathname == '/' || window.location.pathname == '/leads') &&
      profileId == ASESOR_ID &&
      row.brokerId !== userIdStorage
    ) {
      renderEdit = false;
    }
    let classRowLastContact = '';
    if (
      (window.location.pathname == '/' || window.location.pathname == '/leads') &&
      row.date_updated &&
      row.date_updated != ''
    ) {
    
      const start = moment();
      const end = moment(row.date_updated).set('hours', '00');
      const calculatedDeadLine = row.next_deadline ?  moment(row.next_deadline) : moment().subtract(1,'day');
      const difDays = start.diff(end, 'days');
      const difDaysDeadLine = start.diff(calculatedDeadLine, 'days');
      //if() si tengo deadline futuro no haga nada
      // si tengo un deadline pasado o no tengo deadline el calculo es Hoy menos fecha de actualización
      if (difDaysDeadLine > 0 &&  difDays >=15 ) {
        switch (true) {
          case difDays >= 15 && difDays < 30:
            classRowLastContact = classes.traffic_light_15;
            break;
          case difDays >= 30 && difDays <= 45:
            classRowLastContact = classes.traffic_light_30;
            break;
          case difDays > 45: 
            console.log('difDays:', difDays)
            classRowLastContact = classes.traffic_light_45;
            break;

          default:
            break;
        }
      }
    }

    return (
      <TableRow
        hover
        onClick={() =>
          this.props.onClickRow && this.validRenderCheck(row)
            ? this.props.onClickRow(row.id)
            : () => {}
        }
        role={isSelectable ? 'checkbox' : 'none'}
        selected={isSelected}
        className={isSortable ? classes.sortableRow : classRowLastContact}
      >
        {isSelectable && (
          <TableCell padding="checkbox">
            {renderEdit && <Checkbox checked={isSelected} />}
          </TableCell>
        )}
        {this.renderCells()}
      </TableRow>
    );
  }
}
EnhancedTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  hasAutoFillers: PropTypes.bool,
  isSortable: PropTypes.bool,
  allowAddToLead: PropTypes.bool,
  isAddingNew: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  autoFillers: PropTypes.object,
  isSelected: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  isSelectable: PropTypes.bool,
  rowIndex: PropTypes.number,
  onClickRow: PropTypes.func,
  onDetail: PropTypes.func,
  onEvent: PropTypes.func,
  onGetAllLeadsOfUser: PropTypes.func,
  onDuplicate: PropTypes.func,
  onChangeCell: PropTypes.func,
  onAttributes: PropTypes.func,
  onAttach: PropTypes.func,
  onSave: PropTypes.func,
  onUpdate: PropTypes.func,
  onViewHistory: PropTypes.func,
  onAddToLead: PropTypes.func,
  openMapModal: PropTypes.func,
  onAddUser: PropTypes.func,
  onCloneProject: PropTypes.func,
  onGenerateCredentials: PropTypes.func,
  renderMockup: PropTypes.func,
  onProformas: PropTypes.func,
  onProformaAdmin: PropTypes.func,
  onAddUnits: PropTypes.func,
};

export default withStyles(styles)(EnhancedTableRow);
