const drawerWidth = 240;

export const styles = (theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: 24,
    textTransform: 'none',
  },
  banner: { width: '100%' },
  centredContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardWrapper: {
    position: 'relative',
    bottom: 40,
    width: '80%',
    borderRadius: 18,
  },
  cardContent: {
    padding: 24,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  cardContentR: {
    padding: 24,
    paddingLeft: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  cardButton: {
    borderRadius: 24,
    color: 'white',
    textTransform: 'none',
    fontWeight: 100,
  },
  verticalDivider: {
    height: '100%',
    width: 1,
    backgroundColor: 'lightgray',
  },
  cardText: {
    margin: '12px 0',
    color: '#000000ab',
  },
  gridTitle: {
    '&>div': {
      width: '80%',
      margin: 'auto',
      maxWidth: 500,
      borderRadius: 24,
      cursor: 'pointer',
      '&:hover': {
        boxShadow: theme.shadows[12]
      }
    },
  },
});

export default styles;
