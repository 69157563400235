import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Divider,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import NewsModal from './../common/newsModal';
import banner from '../../assets/trivo_smart_banner.png';
import { userHasTrivoSmart } from '../../actions/users';
import { getNewsToShow } from '../../actions/news';

const TrivoSmart = (props) => {
  const { classes } = props;

  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [newsItemData, setNewsItemData] = useState({});

  const { newsToShow } = useSelector((state) => state.news);

  useEffect(() => {
    (async () => {
      const company = JSON.parse(localStorage.getItem('company'));
      const hasTrivoSmartPermission = await userHasTrivoSmart();
      const userWithTrivoSmart = company && company.hasTrivoSmart && hasTrivoSmartPermission;
      if (!userWithTrivoSmart) props.history.push('/');
      else dispatch(getNewsToShow());
    })();
  }, []);

  const getNews = () => {
    return newsToShow.map((newsItem) => {
      return {
        title: newsItem.title,
        mainImage: newsItem.mainImage,
        id: newsItem.id,
      };
    });
  };

  const handleOpenNewItem = (data) => {
    setOpenDialog(true);
    setNewsItemData(data);
  };

  const renderListNews = () => {
    return (
      <div className={classes.root}>
        <GridList cellHeight={250} spacing={48}>
          {newsToShow.map((newsItem) => (
            <GridListTile
              onClick={() => handleOpenNewItem({ title: newsItem.title, id: newsItem.id })}
              news={getNews()}
              key={newsItem.mainImage}
              cols={newsItem.featured ? 2 : 1}
              rows={newsItem.featured ? 2 : 1}
              className={classes.gridTitle}
            >
              <img src={newsItem.mainImage} alt={newsItem.title} />
              <GridListTileBar
                title={newsItem.title}
                titlePosition="top"
                actionIcon={
                  <IconButton className={classes.icon}>{/* <StarBorderIcon /> */}</IconButton>
                }
                actionPosition="left"
                className={classes.titleBar}
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    );
  };

  const renderNews = () => {
    return (
      <Grid container>
        <Grid item md={12} xs={12}>
          <img src={banner} alt="banner" className={classes.banner} />
        </Grid>
        <Grid item md={2} xs={1} />
        <Grid item md={8} xs={10}>
          <div className={classes.centredContainer}>
            <Paper elevation={16} className={classes.cardWrapper}>
              <Grid container>
                <Grid item sm={5} xs={12} className={classes.cardContent}>
                  <Typography variant="h5" color="primary">
                    Oferta
                  </Typography>
                  <Typography variant="body2" className={classes.cardText}>
                    Información de oferta de proyectos inmobiliarios para uso simple e inmediato
                  </Typography>
                  <div className={classes.centredContainer} style={{}}>
                    <Link href="/trivo-smart-oferta">
                      <Button color="primary" variant="contained" className={classes.cardButton}>
                        Ver Oferta
                      </Button>
                    </Link>
                  </div>
                </Grid>
                <Grid item sm={2} className={classes.centredContainer} style={{ padding: 10 }}>
                  <Divider orientation="vertical" className={classes.verticalDivider} />
                </Grid>
                <Grid item sm={5} xs={12} className={classes.cardContentR}>
                  <Typography variant="h5" color="secondary">
                    Demanda
                  </Typography>
                  <Typography variant="body2" className={classes.cardText}>
                    Información de demanda en el mercado de proyectos inmobiliarios
                  </Typography>
                  <div className={classes.centredContainer} style={{}}>
                    <Link href="/trivo-smart-demanda">
                      <Button color="secondary" variant="contained" className={classes.cardButton}>
                        Ver Demanda
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
          {renderListNews()}
          {/* {news.length > 0 && <NewsSlider news={getNews()} handleOpenNewItem={handleOpenNewItem} />} */}
        </Grid>
        <br />
      </Grid>
    );
  };
  return (
    <div>
      {renderNews()}
      {openDialog && (
        <NewsModal
          open={openDialog}
          setOpen={setOpenDialog}
          title={newsItemData.title}
          newItemId={newsItemData.id}
        />
      )}
    </div>
  );
};

TrivoSmart.propTypes = {
  window: PropTypes.func,
  history: PropTypes.any,
};

export default withStyles(styles)(TrivoSmart);
