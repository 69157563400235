import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';

const DropDown = ({ options = [] }) => {
  const safeOptions = Array.isArray(options) ? options : [];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDropDown = () => setAnchorEl(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {safeOptions.map(({ onClick, label, id, val, disabled }, key) => {
          return (
            <MenuItem
              key={key}
              onClick={() => {
                onClick && onClick(id, val);
                closeDropDown();
              }}
              disabled={disabled}
            >
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

DropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
      val: PropTypes.any.isRequired,
      id: PropTypes.any.isRequired,
    }),
  ).isRequired,
};

export default DropDown;
