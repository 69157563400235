export const styles = () => ({
  hide: {
    display: 'none'
  },
  button: {
    marginTop: 20,
    marginBottom: 20
  },
  icon: {
    marginRight: 10
  }
});

export default styles;
