import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector, useDispatch } from 'react-redux';
import { InlineDatePicker } from 'material-ui-pickers';
import { styles } from './styles';
import { getEventsReport } from '../../../actions/calendar';
import { ADMIN, ADMIN_ID, ASESOR_ID, ASESOR_JR_ID, dateFormat } from '../../../utils';
import moment from 'moment-timezone';

const profileId = localStorage.getItem('profileId');

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

const RankingModal = (props) => {
  const { classes } = props;
  const [open, setOpen] = useState(false);

  const { report } = useSelector((state) => state.calendar);
  const [dateFrom, setDateFrom] = useState(startOfMonth);
  const [dateTo, setDateTo] = useState(endOfMonth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(getEventsReport(dateFrom, dateTo));
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFilter = () => {
    dispatch(
      getEventsReport(moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD')),
    );
  };

  const renderButton = () => {
    if(profileId=== ADMIN_ID)
    return "Ranking";
    else{
      return  "Resumen"
    }
  }

  const renderTitle = () => {
    if(profileId=== ADMIN_ID)
    return "Ranking asesores";
    else{
      return  "Resumen de citas"
    }
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {renderButton()}
      </Button>
      <Dialog
        onClose={handleClose}
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {renderTitle()}
        </DialogTitle>
        <DialogContent>
          <div className={classes.datePickerRangeContainer}>
            <InlineDatePicker
              value={dateFrom}
              clearable
              emptyLabel=""
              invalidLabel=""
              invalidDateMessage=""
              autoOk
              onChange={setDateFrom}
              label={'Desde'}
              format={dateFormat}
              className={classes.datePickerRange}
            />
            <InlineDatePicker
              value={dateTo}
              clearable
              emptyLabel=""
              invalidLabel=""
              invalidDateMessage=""
              autoOk
              onChange={setDateTo}
              label={'Hasta'}
              format={dateFormat}
              className={classes.datePickerRange}
            />
            <div className={classes.buttonContainer}>
              <Button variant="contained" onClick={handleFilter} color="primary">
                Filtrar
              </Button>
            </div>
          </div>
          {report.length ? (
            <div>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Asesor</CustomTableCell>
                    <CustomTableCell align="right">Citas únicas</CustomTableCell>
                    <CustomTableCell align="right">Citas agendadas</CustomTableCell>
                    <CustomTableCell align="right">Revisitas</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.map((row, key) => (
                    <TableRow className={classes.row} key={`item-${key}`}>
                      <CustomTableCell component="th" scope="row">
                        {row.name}
                      </CustomTableCell>
                      <CustomTableCell align="right">{row.uniqueDates}</CustomTableCell>
                      <CustomTableCell align="right">{row.datesBroker}</CustomTableCell>
                      <CustomTableCell align="right">{row.revisitDates}</CustomTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div>No existe ranking</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="bordered" onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(RankingModal);
