import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';
import PropTypes from 'prop-types';

const Editor = (props) => {
  const { content, setContent } = props;
  const editor = useRef(null);

  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: true,
    uploader: {
      insertImageAsBase64URI: true,
    },
    enableDragAndDropFileToEditor: true,
    removeButtons: ['source', 'about', 'video', 'file', 'print', 'image'],
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      onBlur={content => setContent(content.target.innerHTML)}
    />
  );
};

Editor.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func.isRequired,
};

export default Editor;
