import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {filterObservations} from '../../actions/observations';
import {getUsers} from '../../actions/users';
import EnhancedTable from '../common/enhancedTable';
import {observationCols} from '../../utils/colTypes';
import {observationsOptions} from '../../utils/filterTypes';
import {copyObject} from '../../utils/utils';
import CustomSnackbar from '../common/customSnackbar';

export class Observations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {           
      title: 'Observaciones',
      filterChips: [],
      filterOptions: copyObject(observationsOptions)
    };
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
  }

  async componentWillMount() {
    this.getViewPermission();
    await this.props.getUsers();
    this.renderFilters();
    await this.props.filterObservations(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters
    );
  }

  componentWillReceiveProps() {
    this.renderFilters();
  }

  onSearch(searchText) {
    this.props.filterObservations(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText, this.props.filters);
  }

  onOrder(order, orderBy) {
    this.props.filterObservations(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText, this.props.filters);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_observations) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  handleChangePage(page) {
    this.props.filterObservations(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters);
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterObservations(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters);
  }

  handleFilter(filters, filterChips) {
    this.setState({filterChips});
    this.props.filterObservations(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, '', filters);
  }

  renderFilters() {
    const {users} = this.props;
    let defFilters = copyObject(observationsOptions);
    if (users.length > 0) {
      defFilters = defFilters.map((item) => {
        const filter = item;
        if (filter.id === 'broker') {
          const types = [{title: 'Todos', value: 'all'}];
          if (users) {
            const asesors = users.filter((obj) => {
              if (obj.profile) {
                if (obj.profile._id) {
                  if (obj.is_asesor === true) {
                    return true;
                  }
                }
              }
              return false;
            });
            asesors.forEach((leadStage) => {
              const newType = {
                title: leadStage.name,
                value: leadStage.id
              };
              types.push(newType);
            });
            filter.types = types;
          }
        } else if (filter.type === 'date') {
          filter.types = [{title: 'Todos', value: ''}];
        }
        return filter;
      });
    }
    this.setState({filterOptions: defFilters});
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, observations, dataLength, isLoadingUsers} = this.props;
    const {title, filterOptions, filterChips} = this.state;
    const data = {
      rows: observations,
      cols: observationCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onFilter: this.handleFilter,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      isFiterable: false,
      hasEditPermission: false,
      hasCreatePermission: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: true,
        options: filterOptions,
        chips: filterChips,
        by: this.props.filters
      }
    };

    if (isLoading === true || isLoadingUsers === true) {
      return (
        <div style={{zIndex: 100, position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)'}}>
          <CircularProgress
            style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}}
          />
        </div>
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        <EnhancedTable 
          title={title}
          data={data} 
          handlers={handlers}
          config={config}
        />  
      </div>
    );
  }
}

Observations.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingUsers: PropTypes.bool.isRequired,
  observations: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  filterObservations: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {observations, users} = state;
  return { 
    isLoading: observations.sending, 
    isLoadingUsers: users.sending,
    users: users.users,
    observations: observations.observations,
    dataLength: observations.dataLength, 
    error: observations.error,
    message: observations.message,
    searchText: observations.searchText,
    order: observations.order,
    orderBy: observations.orderBy,
    page: observations.page,
    rowsPerPage: observations.rowsPerPage,
    filters: observations.filters
  };
};
  
const mapDispatchToProps = {
  filterObservations,
  getUsers
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Observations);
