import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  TextField,
  Typography,
  FormControl,
  Switch,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { styles } from './styles';
import { parseNumber } from '../../../utils/utils';
import { InlineDatePicker } from 'material-ui-pickers';

const UnitForm = (props) => {
  const {
    formValues,
    classes,
    handleInputChange,
    currentVersion,
    changeUnitManualState,
  } = props;

  const {
    name,
    rooms,
    coveredArea,
    coveredAreaPrice,
    gardenOrTerraceArea,
    gardenOrTerraceAreaPrice,
    parkingSpaces,
    parkingSpacesPrice,
    warehouseValue,
    totalArea,
    observations,
    totalPrice,
    warehouses,
    deadline,
    showUnitData,
    discountValue,
  } = formValues;

  const currentPrice =
    parseNumber(coveredAreaPrice) +
    parseNumber(gardenOrTerraceAreaPrice) +
    parseNumber(parkingSpacesPrice) +
    parseNumber(warehouseValue) -
    parseNumber(discountValue);

  const totalPriceError =
    parseNumber(currentPrice).toFixed(2) !== parseNumber(totalPrice).toFixed(2);

  const onInputChange = ({ target: { name, value } }) => {
    const getPercentage = (itemValue) =>
      currentPrice > 0 && parseNumber(itemValue) > 0
        ? ((parseNumber(itemValue) * 100) / currentPrice).toFixed(2)
        : 0;

    const getNewValue = (itemName) => {
      const tempPrice =
        currentPrice - parseNumber(formValues[name]) + parseNumber(value);
      const percentage = parseNumber(
        getPercentage(parseNumber(formValues[itemName])),
      );
      return ((tempPrice * percentage) / 100).toFixed(2);
    };

    changeUnitManualState({
      [name]: value,
      content: [],
      entryValue: getNewValue('entryValue'),
      reserveValue: getNewValue('reserveValue'),
    });
  };

  return (
    <Paper className={classes.root}>
      <Typography
        variant="h5"
        color="primary"
        className={classes.headerProformas}
      >
        Datos de Inmueble
      </Typography>
      <Grid container spacing={24}>
        <Grid item className={classes.gridItem} xs={12} md={6}>
          <Grid container spacing={24}>
            <Grid item className={classes.gridItem} xs={12}>
              <List>
                <ListItem>
                  <Switch
                    checked={!!showUnitData}
                    disabled={!!currentVersion}
                    onChange={(e) =>
                      handleInputChange({
                        target: {
                          name: 'showUnitData',
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                  <ListItemText
                    className={classes.listText}
                    primary={`${
                      !!showUnitData ? 'Mostrar' : 'No mostrar'
                    } datos del inmueble`}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item className={classes.gridItem} xs={6}>
              <List>
                <ListItem>
                  <ListItemText
                    className={classes.listText}
                    primary="Unidad"
                    secondary={name}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item className={classes.gridItem} xs={6}>
              <List>
                <ListItem>
                  <ListItemText
                    className={classes.listText}
                    primary="Dormitorios"
                    secondary={rooms}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item className={classes.gridItem} sm={6} xs={12}>
              <FormControl
                style={{ width: '100%', paddingTop: 16, paddingBottom: 16 }}
              >
                <InlineDatePicker
                  disabled={!!currentVersion}
                  label="Fecha de Entrega"
                  variant="outlined"
                  format="YYYY-MM-DD"
                  value={deadline}
                  onChange={(selected) =>
                    handleInputChange({
                      target: {
                        name: 'deadline',
                        value: selected,
                      },
                    })
                  }
                  className={classes.pickerContainer}
                  InputProps={{ className: classes.inputForm }}
                />
              </FormControl>
            </Grid>
            <Grid item className={classes.gridItem} xs={12}>
              <TextField
                disabled={!!currentVersion}
                label="Observaciones"
                name="observations"
                value={observations || ''}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                className={classes.textField}
                InputProps={{ className: classes.inputForm }}
                InputLabelProps={{ className: classes.labelForm }}
                multiline
                rows={10}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.gridItem} xs={12} md={6}>
          <Grid container>
            <Grid item className={classes.gridItem} xs={12}>
              <List
                subheader={
                  <ListSubheader className={classes.listHeader} component="div">
                    Área Cubierta
                  </ListSubheader>
                }
              >
                <Grid container spacing={8}>
                  <Grid item className={classes.gridItem} sm={6} xs={12}>
                    <TextField
                      type="number"
                      label="Área"
                      name="coveredArea"
                      value={coveredArea || ''}
                      onChange={handleInputChange}
                      margin="normal"
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ className: classes.inputForm }}
                      InputLabelProps={{
                        className: classes.labelForm,
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">m2</InputAdornment>
                        ),
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} sm={6} xs={12}>
                    <TextField
                      disabled={!!currentVersion}
                      type="number"
                      label="Precio Total"
                      name="coveredAreaPrice"
                      value={coveredAreaPrice || ''}
                      onChange={onInputChange}
                      margin="normal"
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ className: classes.inputForm }}
                      InputLabelProps={{
                        className: classes.labelForm,
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </List>
            </Grid>
            <Grid item className={classes.gridItem} xs={12}>
              <List
                subheader={
                  <ListSubheader className={classes.listHeader} component="div">
                    Jardín o Terraza
                  </ListSubheader>
                }
              >
                <Grid container spacing={8}>
                  <Grid item className={classes.gridItem} sm={6} xs={12}>
                    <TextField
                      type="number"
                      label="Área"
                      placeholder="0"
                      name="gardenOrTerraceArea"
                      value={gardenOrTerraceArea || ''}
                      onChange={handleInputChange}
                      margin="normal"
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ className: classes.inputForm }}
                      InputLabelProps={{
                        className: classes.labelForm,
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">m2</InputAdornment>
                        ),
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} sm={6} xs={12}>
                    <TextField
                      disabled={!!currentVersion}
                      type="number"
                      placeholder="0"
                      label="Precio Total"
                      name="gardenOrTerraceAreaPrice"
                      value={gardenOrTerraceAreaPrice || ''}
                      onChange={onInputChange}
                      margin="normal"
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ className: classes.inputForm }}
                      InputLabelProps={{
                        className: classes.labelForm,
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </List>
            </Grid>
            <Grid item className={classes.gridItem} xs={12}>
              <List
                subheader={
                  <ListSubheader className={classes.listHeader} component="div">
                    {'Bodega(s)'}
                  </ListSubheader>
                }
              >
                <Grid container spacing={8}>
                  <Grid item className={classes.gridItem} sm={6} xs={12}>
                    <TextField
                      disabled={!!currentVersion}
                      type="number"
                      label="Cantidad"
                      placeholder="0"
                      name="warehouses"
                      value={
                        warehouses || warehouses === ''
                          ? warehouses
                          : parseNumber(warehouseValue) > 0
                          ? 1
                          : 0
                      }
                      onChange={handleInputChange}
                      margin="normal"
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ className: classes.inputForm }}
                      InputLabelProps={{
                        className: classes.labelForm,
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            unidad(es)
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} sm={6} xs={12}>
                    <TextField
                      disabled={!!currentVersion}
                      type="number"
                      label="Precio total"
                      name="warehouseValue"
                      placeholder="0"
                      value={warehouseValue || ''}
                      onChange={onInputChange}
                      margin="normal"
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ className: classes.inputForm }}
                      InputLabelProps={{
                        className: classes.labelForm,
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </List>
            </Grid>
            <Grid item className={classes.gridItem} xs={12}>
              <List
                subheader={
                  <ListSubheader className={classes.listHeader} component="div">
                    {'Parqueo(s)'}
                  </ListSubheader>
                }
              >
                <Grid container spacing={8}>
                  <Grid item className={classes.gridItem} sm={6} xs={12}>
                    <TextField
                      disabled={!!currentVersion}
                      type="number"
                      label="Cantidad"
                      name="parkingSpaces"
                      placeholder="0"
                      value={parseNumber(parkingSpaces) || ''}
                      onChange={handleInputChange}
                      margin="normal"
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ className: classes.inputForm }}
                      InputLabelProps={{
                        className: classes.labelForm,
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} sm={6} xs={12}>
                    <TextField
                      disabled={!!currentVersion}
                      type="number"
                      placeholder="0"
                      label="Precio total"
                      name="parkingSpacesPrice"
                      value={parkingSpacesPrice || ''}
                      onChange={onInputChange}
                      margin="normal"
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{ className: classes.inputForm }}
                      InputLabelProps={{
                        className: classes.labelForm,
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </List>
            </Grid>
            <Grid item className={classes.gridItem} sm={6} xs={12}>
              <List>
                <ListItem>
                  <ListItemText
                    className={classes.listText}
                    primary="Área Total"
                    secondary={`${Number(totalArea).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} m2`}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item className={classes.gridItem} sm={6} xs={12}>
              <List>
                <ListItem>
                  <ListItemText
                    className={`${classes.listText} ${
                      totalPriceError && 'warning'
                    }`}
                    primary="Precio Total"
                    secondary={`$ ${Number(
                      currentPrice.toFixed(2),
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  />
                </ListItem>
                <ListItem style={{ paddingTop: 0 }}>
                  <h6 className={classes.warning}>
                    {totalPriceError
                      ? `El precio total es diferente al del stock ( $ ${Number(
                          totalPrice,
                        ).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} )`
                      : ''}
                  </h6>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

UnitForm.propTypes = {
  classes: PropTypes.object.isRequired,
  lead: PropTypes.object,
  formValues: PropTypes.object,
  handleInputChange: PropTypes.func,
};

export default withStyles(styles)(UnitForm);
