/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-cond-assign */

import CONSTANTS from './constants';
import * as XLSX from 'xlsx';
import slug from 'slug';

/* eslint-disable no-nested-ternary */
export const apiHost = process.env.REACT_APP_API_HOST || 'http://localhost:5000/';
export const apiProformas = process.env.REACT_APP_API_PROFORMAS || 'http://localhost:5001/';
export const apiServerless = process.env.REACT_APP_API_SERVERLESS || 'http://localhost:5002/local/';
// export const apiHost = 'https://trivo-back-development.herokuapp.com/';
// export const apiHost = 'https://api.trivo.com.ec/';

/* export const apiHost = 'http://localhost:5000/'; */

export const API_KEY =
  process.env.REACT_APP_API_KEY ||
  'dsuLtZY6gsmEyxzxUGGFyvqSN9w6SnsZqFuxDxzPNeheALThQHCVfcLUx5fp9vfR';
// export const API_KEY = '6rmc6baaL2aL9VGcGV8LPg6ESpxfWvst28SY9AzrPh2PsT6MGQPZ396uwZJ3pKVL';

export const ENVIRONMENT = process.env.REACT_APP_ENV || 'development';

export const PROJECTS_URL =
  process.env.REACT_APP_PROJECTS_URL || 'http://trivo-proyectos.netlify.com/';

export const LEAD_STATE_NUEVO = process.env.REACT_APP_STATE_NUEVO || '5cbdf596ba0b4000172a4857';

export const LEAD_STATE_SEGUIMIENTO =
  process.env.REACT_APP_STATE_SEGUIMIENTO || '5cbdf569ba0b4000172a4851';

export const LEAD_STATE_GANADO = process.env.REACT_APP_STATE_GANADO || '5cbdf578ba0b4000172a4853';

export const LEAD_STATE_PERDIDO = process.env.REACT_APP_STATE_PERDIDO || '5cbdf57fba0b4000172a4854';

export const LEAD_STAGE_ANTICIPO_RECIBIDO =
  process.env.REACT_APP_STAGE_ANTICIPO_RECIBIDO || '5cbdf6ebba0b4000172a4868';

export const ADMIN = process.env.REACT_APP_PERMISSION_ADMIN || 'Admin';

export const SUPER_ADMIN = process.env.REACT_APP_PERMISSION_ADMIN || 'SuperAdmin';

export const SUPER_ADMIN_ROL_ID =
  process.env.REACT_APP_SUPER_ADMIN_ROL || '5e79341a7064063d800f8a01';

export const ADVICE = process.env.REACT_APP_ADVICE || 'Consejo';

export const OBSERVATION = process.env.REACT_APP_OBSERVATION || 'Observación';

export const UNIT_TYPE = process.env.REACT_APP_UNIT_TYPE || 'tipo_1550803898024';

export const ZONE = process.env.REACT_APP_ZONE || 'zone_1551401186572';

export const BUDGET = process.env.REACT_APP_BUDGET || 'budget_1550605031998';

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '5ce2fc9c7142902b502081a9';

export const REFERRER_ID = process.env.REACT_APP_REFERRER_ID || '5ce2fca27142902b502081aa';

export const NEW_LEAD = {
  user: { name: '', lastname: '', phone: '', email: '', document: '' },
};

export const ME_DA_IGUAL = 'Me da igual';

export const DEFAULT_DOMAIN = process.env.REACT_APP_DEFAULT_DOMAIN || 'notificaciones@trivo.com.ec';

export const ASESOR_ID = process.env.REACT_APP_ASESOR_ID || '5cc327c976008939e447fc74';

export const ADMIN_ID = process.env.REACT_APP_ADMIN_ID || '5cc327ae76008939e447fc73';

export const ASESOR_JR_ID = process.env.REACT_APP_ASESOR_JR_ID || '5cf99a477e083313a00cff5b';

export const MANDATO_ID = process.env.REACT_APP_MANDATO_ID || '5dc58c014ad261194fd201a8';

export const BUILDER_ID = process.env.REACT_APP_BUILDER_ID || '5d5322627693c4481cc27d50';

export const COMERCIAL_MANAGER = process.env.REACT_APP_COMERCIAL_MANAGER_ID || '63f54058121d520c4bba90cb';


export const STRATEGIC_PARTNER_ID =
  process.env.REACT_APP_STRATEGIC_PARTNER_ID || '5e6a62d6e680ba704f436ae4';

export const IDENTIFICATION_CARD_ID =
  process.env.REACT_APP_IDENTIFICATION_CARD_ID || '5e7937ee142fff7b1494b771';

export const IDENTIFICATION_RUC_ID =
  process.env.REACT_APP_IDENTIFICATION_RUC_ID || '5e728ea60431434f78567fdc';

export const PARTNERS = [
  { id: '5dc1973dfbf2aa44643d84f8', name: 'MLS' },
  { id: '5e173b95d01c455834af6bb6', name: 'Plusvalía' },
];

export const FERIA_ONLINE_QUITO_ORIGIN =
  process.env.REACT_APP_FERIA_ONLINE_QUITO_ORIGIN || '5ee05810225b527927d9bd62';

export const FREE_PLAN = process.env.REACT_APP_FREE_PLAN || '5f3d51fad4ee282080b440b1';
export const TRIVO_PLAN = process.env.REACT_APP_TRIVO_PLAN || '5f3d51ebd4ee282080b440b0';

export const UPGRADE_PLAN_LINK = process.env.REACT_APP_UPGRADE_PLAN_LINK;

export const PLANS_TO_UPGRADE = process.env.REACT_APP_PLANS_TO_UPGRADE || FREE_PLAN;

export const PROJECT_PLANS = process.env.REACT_APP_PROJECT_PLANS || '5f3d51ebd4ee282080b440b0';

export const maxLimit = 999999;

export const origins = [
  'Agencia',
  'App - Referidos',
  'Directo Telf/Whatsapp',
  'Facebook',
  'Feria',
  'Feria BIESS',
  'Feria NY',
  'Feria Plusvalía',
  'Instagram',
  'Lead propio Asesor',
  'Mi trivo',
  'MLS',
  'Plusvalia',
  'Properati',
  'Sala de ventas',
  'Showroom',
  'Web - Explora',
  'Web - Wizard',
];

export const dashboardStatus = [
  'Leads',
  'Contactados',
  'Info. inicial enviada',
  'Cita confirmada',
  'Cita realizada',
  'Visita confirmada',
  'Visita realizada',
  'Gestión pre-cierre',
  'Ganado',
];

export const observationTypes = [
  {
    value: 'nota',
    name: 'Nota',
  },
  {
    value: 'llamada',
    name: 'Llamada',
  },
  {
    value: 'whatsapp',
    name: 'Whatsapp',
  },
  {
    value: 'email',
    name: 'Email',
  },
  {
    value: 'personal',
    name: 'Personal',
  },
];

export const colors = [
  '#1B7F79',
  '#00766c',
  '#009688',
  '#26a69a',
  '#00bcd4',
  '#77C0E3',
  '#31A9C2',
  '#028ABE',
  '#00CCC0',
  '#72F2EB',
  '#64d8cb',
  '#00ffff',
  '#747F7F',
  '#4799B7',
  '#008ba3',
  '#22556E',
  '#153641',
  '#075E72',
  '#55ae95',
  '#9be3de',
  '#6f9a8d',
  '#1f6650',
  '#c6cbef',
  '#3a9679',
  '#729d39',
  '#36622b',
  '#587850',
  '#4bbb8b',
];

export const getUrlExtension = (url) => {
  return url.split(/#|\?/)[0].split('.').pop().trim();
};

export const sortObjectByProperty = (array, property, type) => {
  return array.sort((a, b) => {
    const textA = a[property];
    const textB = b[property];
    if (type === 'desc') return textA < textB ? 1 : textA > textB ? -1 : 0;

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const isEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isPhone = (phone) => {
  const re = /^\d*$/;
  return re.test(String(phone));
};

export const isInternationalPhone = (phone) => {
  const re = /^\+[0-9]{1}[0-9]{9,14}$/;
  // console.log(re.test(String(phone)), 'validPhone');
  return re.test(String(phone));
};

export const isInArray = (values, array) => {
  for (let i = 0; i < values.length; i += 1) {
    if (array.indexOf(values[i]) > -1) {
      return true;
    }
  }
  return false;
};

export const isNumber = (number) => {
  if (number) {
    if (number === '') {
      return true;
    }

    const re = /^[0-9.]+$/;
    const test = re.test(String(number));
    return test;
  }

  return true;
};

export const currentDate = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${dd}`;
};

export const dateFormat = 'YYYY-MM-DD';

export const dateFormatExtended = 'YYYY-MM-DD HH:mm:ss';

export const dateRegion = 'America/Merida';

export const copyObject = (obj) => JSON.parse(JSON.stringify(obj));

export const moveArray = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - (arr.length + 1);
    while ((k -= 1)) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const axiosMaker = (method, url, data = null, token, api) => {
  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'api-key': API_KEY,
  };
  if (token) {
    headers['x-access-token'] = localStorage.token;
  }

  let currentApi = apiHost;

  if (api === 'proformas') currentApi = apiProformas;
  else if (api === 'serverless') currentApi = apiServerless;

  const options = {
    async: true,
    crossDomain: true,
    method,
    url: currentApi + url,
    headers,
  };
  if (data !== null) {
    options.data = data;
  }
  return options;
};

export const depthOf = (object) => {
  let level = 1;
  let key;
  for (key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (!object.hasOwnProperty(key)) continue;
    if (typeof object[key] === 'object') {
      const depth = depthOf(object[key]) + 1;
      level = Math.max(depth, level);
    }
  }
  return level;
};

export const axiosMakerCSV = (method, url, data = null, token) => {
  const headers = {
    'Cache-Control': 'no-cache',
    'api-key': API_KEY,
  };
  if (token) {
    headers['x-access-token'] = localStorage.token;
  }
  const options = {
    async: true,
    crossDomain: true,
    method,
    url: apiHost + url,
    headers,
  };
  if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && data !== null) {
    options.data = data;
  }
  return options;
};

export const axiosFormData = (method, url, formData, token) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache',
    'api-key': API_KEY,
  };
  if (token) {
    headers['x-access-token'] = localStorage.token;
  }
  const options = {
    async: true,
    crossDomain: true,
    method,
    url: apiHost + url,
    headers,
    data: formData,
  };
  return options;
};

export const errorMessage = (error) => {
  // eslint-disable-next-line no-console
  console.log('error: ', error);
  if (error) {
    const { status, data } = error;
    switch (status) {
      case 400:
        if (data.errors) {
          if (data.errors.length > 0) {
            return data.errors.map((obj) => obj.message).join(', ');
          }
        } else if (data.message) {
          return data.message;
        }
        return 'Ha ocurrido un error';
      case 401:
        return 'No has iniciado sesión';
      case 403:
        return 'No tienes permiso para acceder a este recurso';
      case 404:
        return 'No se ha encontrado el recurso';
      case 405:
        return 'No existe el recurso';
      case 500:
        return 'Ha ocurrido un error en el servidor';
      case 501:
        return 'Ha ocurrido un error en el servidor';
      case 502:
        return 'Ha ocurrido un error en el servidor';
      case 503:
        return 'Ha ocurrido un error en el servidor';
      case 504:
        return 'Ha ocurrido un error con el tiempo de espera del servidor';
      default:
        return 'Ha ocurrido un error';
    }
  }
  return 'Ha ocurrido un error';
};

export const findIndexFromObjectsArray = (array, attr, value) => {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};

export const sortOptions = [
  {
    name: 'Precio de menor a mayor',
    value: 'priceValue_asc',
  },
  {
    name: 'Precio de mayor a menor',
    value: 'priceValue_desc',
  },
  {
    name: 'Fecha de entrega de menor a mayor',
    value: 'finalDateValue_asc',
  },
  {
    name: 'Fecha de entrega de mayor a menor',
    value: 'finalDateValue_desc',
  },
  {
    name: 'Área de menor a mayor',
    value: 'areaValue_asc',
  },
  {
    name: 'Área de mayor a menor',
    value: 'areaValue_desc',
  },
  {
    name: 'Número de dormitorios de menor a mayor',
    value: 'roomsValue_asc',
  },
  {
    name: 'Número de dormitorios de mayor a menor',
    value: 'roomsValue_desc',
  },
];

export const flattenObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (!obj[k]) {
      acc[pre + k] = null;
      return acc;
    }
    if (typeof obj[k] === 'object') {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else acc[pre + k] = obj[k];
    return acc;
  }, {});
};

export const flattenArray = (array) => array.map((obj) => flattenObject(obj));

export const getIdentifier = (str) => {
  return (
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.toLowerCase())
      .join('-')
  );
};

export const haveSameContents = (a, b) => {
  for (const v of new Set([...a, ...b])) {
    if (a.filter((e) => e === v).length !== b.filter((e) => e === v).length) return false;
  }
  return true;
};

export const compareMultipleSelect = (documents, idsArray) => {
  const hasNewBuilders = !haveSameContents(
    idsArray,
    documents.filter((doc) => doc.company).map((doc) => doc.id),
  );

  const buildersIds = documents.filter((doc) => doc.company).map((doc) => doc.id);
  const updated = hasNewBuilders ? idsArray.filter((id) => !buildersIds.includes(id)) : [];

  const setNulls = documents
    .filter((doc) => doc.company)
    .filter((doc) => !idsArray.includes(doc.id))
    .map((doc) => doc.id);

  return { updated, setNulls };
};

export const isEmptyObject = (obj) => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
};

export const validTrivoCompany = () => {
  const company = JSON.parse(localStorage.getItem('company'));
  if (company && company._id && company._id === CONSTANTS.TRIVO_COMPANY_ID) {
    return true;
  }
  return false;
};

export const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);

export const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

export const union = (a, b) => [...a, ...not(b, a)];

export const typesCountDefault = [
  {
    title: 'Casa',
    rooms: [
      '2 habitaciones:  0',
      '3 habitaciones:  0',
      '4 habitaciones:  0',
      '+4 habitaciones:  0',
    ],
  },
  {
    title: 'Departamento',
    rooms: [
      'Suite:  0',
      'Loft:  0',
      '2 habitaciones:  0',
      '3 habitaciones:  0',
      '4 habitaciones:  0',
      '+4 habitaciones:  0',
    ],
  },
  {
    title: 'Local Comercial:  0',
  },
  {
    title: 'Oficina:  0',
  },
  {
    title: 'Terreno:  0',
  },
];

export const parseNumber = (value) => (isNaN(Number(value)) ? 0 : Number(value));

export const randomString = (length) => Math.random().toString(36).substr(2, length);

export const downloadPdf = (fileURL, fileName) => {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement('a');
    save.href = fileURL;
    save.target = '_blank';
    var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search('Chrome') < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, '_blank');
    _window.document.close();
    _window.document.execCommand('SaveAs', true, fileName || fileURL);
    _window.close();
  }
};

export const countriesArray = [
  'Afganistán',
  'Albania',
  'Alemania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua y Barbuda',
  'Antillas Holandesas',
  'Arabia Saudita',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaiyán',
  'Bahamas',
  'Bahrein',
  'Bangladesh',
  'Barbados',
  'Bélgica',
  'Belice',
  'Benín',
  'Bermudas',
  'Bielorrusia',
  'Bolivia',
  'Bosnia y Herzegovina',
  'Botsuana',
  'Brasil',
  'Brunéi',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Bután',
  'Cabo Verde',
  'Camboya',
  'Camerún',
  'Canadá',
  'Chad',
  'Chile',
  'China',
  'Chipre',
  'Colombia',
  'Comores',
  'Congo (Brazzaville)',
  'Congo (Kinshasa)',
  'Cook, Islas',
  'Corea del Norte',
  'Corea del Sur',
  'Costa de Marfil',
  'Costa Rica',
  'Croacia',
  'Cuba',
  'Dinamarca',
  'Djibouti, Yibuti',
  'Ecuador',
  'Egipto',
  'El Salvador',
  'Emiratos Árabes Unidos',
  'Eritrea',
  'Eslovaquia',
  'Eslovenia',
  'España',
  'Estados Unidos',
  'Estonia',
  'Etiopía',
  'Feroe, Islas',
  'Filipinas',
  'Finlandia',
  'Fiyi',
  'Francia',
  'Gabón',
  'Gambia',
  'Georgia',
  'Ghana',
  'Gibraltar',
  'Granada',
  'Grecia',
  'Groenlandia',
  'Guadalupe',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Ecuatorial',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungría',
  'India',
  'Indonesia',
  'Irak',
  'Irán',
  'Irlanda',
  'Isla Pitcairn',
  'Islandia',
  'Islas Salomón',
  'Islas Turcas y Caicos',
  'Islas Virgenes Británicas',
  'Israel',
  'Italia',
  'Jamaica',
  'Japón',
  'Jersey',
  'Jordania',
  'Kazajstán',
  'Kenia',
  'Kirguistán',
  'Kiribati',
  'Kuwait',
  'Laos',
  'Lesotho',
  'Letonia',
  'Líbano',
  'Liberia',
  'Libia',
  'Liechtenstein',
  'Lituania',
  'Luxemburgo',
  'Macedonia',
  'Madagascar',
  'Malasia',
  'Malawi',
  'Maldivas',
  'Malí',
  'Malta',
  'Man, Isla de',
  'Marruecos',
  'Martinica',
  'Mauricio',
  'Mauritania',
  'México',
  'Moldavia',
  'Mónaco',
  'Mongolia',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Nicaragua',
  'Níger',
  'Nigeria',
  'Norfolk Island',
  'Noruega',
  'Nueva Caledonia',
  'Nueva Zelanda',
  'Omán',
  'Países Bajos, Holanda',
  'Pakistán',
  'Panamá',
  'Papúa-Nueva Guinea',
  'Paraguay',
  'Perú',
  'Polinesia Francesa',
  'Polonia',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reino Unido',
  'República Checa',
  'República Dominicana',
  'Reunión',
  'Ruanda',
  'Rumanía',
  'Rusia',
  'Sáhara Occidental',
  'Samoa',
  'San Cristobal y Nevis',
  'San Marino',
  'San Pedro y Miquelón',
  'San Tomé y Príncipe',
  'San Vincente y Granadinas',
  'Santa Elena',
  'Santa Lucía',
  'Senegal',
  'Serbia y Montenegro',
  'Seychelles',
  'Sierra Leona',
  'Singapur',
  'Siria',
  'Somalia',
  'Sri Lanka',
  'Sudáfrica',
  'Sudán',
  'Suecia',
  'Suiza',
  'Surinam',
  'Swazilandia',
  'Tadjikistan',
  'Tailandia',
  'Taiwan',
  'Tanzania',
  'Timor Oriental',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad y Tobago',
  'Túnez',
  'Turkmenistan',
  'Turquía',
  'Tuvalu',
  'Ucrania',
  'Uganda',
  'Uruguay',
  'Uzbekistán',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Wallis y Futuna',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

const processCSV = (dataString) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

  const list = [];
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    if (headers && row.length == headers.length) {
      const obj = {};
      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] == '"') d = d.substring(1, d.length - 1);
          if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
        }
        if (headers[j]) {
          obj[headers[j]] = d;
        }
      }

      if (Object.values(obj).filter((x) => x).length > 0) {
        list.push(obj);
      }
    }
  }

  return list;
};

export const getCSVData = (e) =>
  new Promise((resolve) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      resolve(processCSV(data));
    };
    reader.readAsBinaryString(file);
  });

export const getExcelData = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = (evt) => {
      const data = new Uint8Array(evt.target.result);
      const workbook = XLSX.read(data, {
        type: 'array',
        cellDates: true,
        cellNF: true,
        cellText: true,
      });

      let sheets = [];

      workbook.SheetNames.forEach(function (sheetName) {
        const sheet = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], {
          defval: '',
          date_format: 'yyyy/mm/dd',
        });
        sheets.push(sheet);
      });

      resolve(sheets);
    };
  });

export const toCamelCase = (str) => {
  let s =
    str &&
    slug(str)
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join('');
  return s.slice(0, 1).toLowerCase() + s.slice(1);
};

export const downladTableAsCsv = async (headRows, rows, fileTitle) => {
  const exportCSVFile = async (headers, items) => {
    /* if (headers) {
      items.unshift(headers);
    } */

    const fileName = fileTitle + '.xlsx' || 'export.xlsx';
    const workSheet = XLSX.utils.json_to_sheet(items);
    var wscols = [
      { width: 16 }, // Nuevo
      { width: 30 }, // Constructora
      { width: 25 }, // Proyecto
      { width: 25 }, // Proyecto
      { width: 25 }, // Proyecto
      { width: 25 }, // Proyecto
      { width: 25 }, // Proyecto
      { width: 25 }, // Proyecto
      { width: 25 }, // Proyecto
      { width: 25 }, // Proyecto
      { width: 20 }, // Telefono O
      { width: 20 }, //NC1
      { width: 22 }, //CC1
      { width: 25 }, //TC1
      { width: 25 }, //EC1
      { width: 25 }, //NC2
      { width: 22 }, //CC2
      { width: 22 }, //TC2
      { width: 25 }, //EC2
      { width: 20 }, //NC3
      { width: 25 }, //CC3
      { width: 25 }, //TC3
      { width: 25 }, //EC3
    ];
    workSheet['!cols'] = wscols;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, workSheet, fileName);

    const base64 = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' });
    const base64Response = await fetch(
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`,
    );
    const blob = await base64Response.blob();

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  console.log('headRows:', headRows);
  const headers = headRows.reduce(
    (acc, { id, label }) => ({ ...acc, [id]: label.replace(/,/g, '') }),
    {},
  );

  const items = rows.map((row) => {
    const result = {};
    for (const key in headers) {
      result[headers[key].toUpperCase()] =
        key === 'proyectoLatitud' || key === 'proyectoLongitud'
          ? row[key]
          : row[key].replace(/,/g, '');
    }
    return result;
  });

  await exportCSVFile(headers, items);
};

export const logOutFunction = () => {
  try {
    const company = JSON.parse(localStorage.getItem('company'));
    const path = company && company.identifier ? `/login/${company.identifier}` : '/';
    localStorage.clear();
    window.location.replace(path);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('e: ', e);
  }
};

export const getProfileIsAdmin = () => {
  const profile = JSON.parse(localStorage.getItem('profile')) || {};

  return profile.name === ADMIN;
};
