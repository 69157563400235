
import React from 'react';
import {TableHead, TableRow, TableCell, TableSortLabel} from '@material-ui/core';


const headCells = [
  {id: 'collapse', label: ' '},
  {id: 'dup-check', label: 'Duplicar'},
  {id: 'username', label: 'Nombre'},
  {id: 'email', label: 'Email'},
  {id: 'notification-check', label: 'Enviar notificación'}
];

const DuplicateLeadsHead = () => {  
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
          >
            <TableSortLabel>
              {headCell.label} 
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default DuplicateLeadsHead;
