import * as countryActions from '../actions/types/countries';

const initialState = {
  countries: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {countries, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case countryActions.FILTER_COUNTRIES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case countryActions.FILTER_COUNTRIES_SUCCESS:
    return {...state, sending: false, countries, error: null, message: 'Países obtenidos satisfactoriamente', dataLength};
  case countryActions.FILTER_COUNTRIES_FAILED:
    return {...state, sending: false, countries: [], error, message, dataLength};
  case countryActions.GET_COUNTRIES_SENDING:
    return {...state, sending: true};
  case countryActions.GET_COUNTRIES_SUCCESS:
    return {...state, sending: false, countries, error: null, message: 'Países obtenidos satisfactoriamente', dataLength};
  case countryActions.GET_COUNTRIES_FAILED:
    return {...state, sending: false, countries: [], error, message, dataLength};
  case countryActions.CREATE_COUNTRY_SENDING:
    return {...state, sending: true};
  case countryActions.CREATE_COUNTRY_SUCCESS:
    return {...state, sending: false, error: null, message: 'País creada satisfactoriamente'};
  case countryActions.CREATE_COUNTRY_FAILED:
    return {...state, sending: false, error, message};
  case countryActions.UPDATE_COUNTRY_SENDING:
    return {...state, sending: true};
  case countryActions.UPDATE_COUNTRY_SUCCESS:
    return {...state, sending: false, error: null, message: 'País actualizada satisfactoriamente'};
  case countryActions.UPDATE_COUNTRY_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
