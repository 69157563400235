import * as leadStageActions from '../actions/types/leadStages';

const initialState = {
  leadStages: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'company',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {leadStages, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case leadStageActions.FILTER_LEAD_STAGES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case leadStageActions.FILTER_LEAD_STAGES_SUCCESS:
    return {...state, sending: false, leadStages, error: null, message: 'Estados de lead obtenidos satisfactoriamente', dataLength};
  case leadStageActions.FILTER_LEAD_STAGES_FAILED:
    return {...state, sending: false, leadStages: [], error, message, dataLength};
  case leadStageActions.GET_LEAD_STAGES_SENDING:
    return {...state, sending: true};
  case leadStageActions.GET_LEAD_STAGES_SUCCESS:
    return {...state, sending: false, leadStages, error: null, message: 'Estados de lead obtenidos satisfactoriamente', dataLength};
  case leadStageActions.GET_LEAD_STAGES_FAILED:
    return {...state, sending: false, leadStages: [], error, message, dataLength};
  case leadStageActions.CREATE_LEAD_STAGE_SENDING:
    return {...state, sending: true};
  case leadStageActions.CREATE_LEAD_STAGE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Estado de lead creada satisfactoriamente'};
  case leadStageActions.CREATE_LEAD_STAGE_FAILED:
    return {...state, sending: false, error, message};
  case leadStageActions.UPDATE_LEAD_STAGE_SENDING:
    return {...state, sending: true};
  case leadStageActions.UPDATE_LEAD_STAGE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Estado de lead actualizada satisfactoriamente'};
  case leadStageActions.UPDATE_LEAD_STAGE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
