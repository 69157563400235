import * as identificationTypeActions from '../actions/types/identificationTypes';

const initialState = {
  identificationTypes: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {identificationTypes, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case identificationTypeActions.FILTER_IDENTIFICATION_TYPES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case identificationTypeActions.FILTER_IDENTIFICATION_TYPES_SUCCESS:
    return {...state, sending: false, identificationTypes, error: null, message: 'Identificaciones obtenidas satisfactoriamente', dataLength};
  case identificationTypeActions.FILTER_IDENTIFICATION_TYPES_FAILED:
    return {...state, sending: false, identificationTypes: [], error, message, dataLength};
  case identificationTypeActions.GET_IDENTIFICATION_TYPES_SENDING:
    return {...state, sending: true};
  case identificationTypeActions.GET_IDENTIFICATION_TYPES_SUCCESS:
    return {...state, sending: false, identificationTypes, error: null, message: 'Identificaciones obtenidas satisfactoriamente', dataLength};
  case identificationTypeActions.GET_IDENTIFICATION_TYPES_FAILED:
    return {...state, sending: false, identificationTypes: [], error, message, dataLength};
  case identificationTypeActions.CREATE_IDENTIFICATION_TYPE_SENDING:
    return {...state, sending: true};
  case identificationTypeActions.CREATE_IDENTIFICATION_TYPE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Identificaciones creada satisfactoriamente'};
  case identificationTypeActions.CREATE_IDENTIFICATION_TYPE_FAILED:
    return {...state, sending: false, error, message};
  case identificationTypeActions.UPDATE_IDENTIFICATION_TYPE_SENDING:
    return {...state, sending: true};
  case identificationTypeActions.UPDATE_IDENTIFICATION_TYPE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Identificaciones actualizada satisfactoriamente'};
  case identificationTypeActions.UPDATE_IDENTIFICATION_TYPE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
