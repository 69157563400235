import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { Paper, Chip, Toolbar, Link, Fab, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { styles } from './styles';
import {
  setProject,
  setZone,
  setRoom,
  setUnitType,
  orderChips,
} from '../../../actions/smartDemandFilters';

const FilterPaper = (props) => {
  const { classes } = props;

  const dispatch = useDispatch();

  const { chipData, project, zone, room, unitType } = useSelector(
    (state) => state.smartDemandFilters,
  );

  const handleDelete = (chipToDelete) => () => {
    const newChips = chipData.filter((chip) => chip.key !== chipToDelete.key);
    dispatch(orderChips(newChips));

    if (chipToDelete.type === 'zone')
      dispatch(setZone(zone.filter((item) => item !== chipToDelete.id)));
    else if (chipToDelete.type === 'room')
      dispatch(setRoom(room.filter((item) => item !== chipToDelete.id)));
    else if (chipToDelete.type === 'unitType')
      dispatch(setUnitType(unitType.filter((item) => item !== chipToDelete.id)));
    else if (chipToDelete.type === 'project')
      dispatch(setProject(project.filter((item) => item !== chipToDelete.id)));
  };

  const renderFilters = () => {
    const chipColor = (filterType) => {
      return [
        { type: 'date', color: '#c6f1d6' },
        { type: 'zone', color: '#ffba92' },
        { type: 'room', color: '#e0f5b9' },
        { type: 'unitType', color: '#ff8080' },
        { type: 'project', color: '#f4efd3' },
      ].find((item) => item.type === filterType).color;
    };

    return (
      <Paper component="div" className={classes.filterItems}>
        {chipData.map((data) => {
          return (
            <span key={data.key}>
              <Chip
                label={data.label}
                onDelete={data.type === 'date' ? undefined : handleDelete(data)}
                className={classes.chip}
                style={{ backgroundColor: chipColor(data.type) }}
              />
            </span>
          );
        })}
      </Paper>
    );
  };

  return (
    <div className={classes.filterContent}>
      <Toolbar>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6">Filtros actuales</Typography>
          <Link href="/trivo-smart-oferta">
            <Fab
              variant="extended"
              color="secondary"
              size="medium"
              className={classes.toolbarButton}
            >
              <SwapHorizIcon className={classes.extendedIcon} />
              Ver Oferta
            </Fab>
          </Link>
        </div>
      </Toolbar>
      {renderFilters()}
    </div>
  );
};

FilterPaper.propTypes = {
  clsses: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilterPaper);
