import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Slide from '@material-ui/core/Slide';
import {withStyles, CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {styles} from './styles';
import InfiniteGrid from './infiniteGrid';
import {icons} from '../../../../../utils/iconList';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

export class IconsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: null,
      iconList: icons.slice(0, 50),
      open: false,
      searchList: [],
      searchText: '',
      hasMoreItems: true,
      isLoading: true,
      searching: false
    };
    this.chooseIcon = this.chooseIcon.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.searching = this.searching.bind(this);
    this.loadItems = this.loadItems.bind(this);
    this.cancelSearch = this.cancelSearch.bind(this);
    this.search = this.search.bind(this);
    this.clickIcon = this.clickIcon.bind(this);
  }

  componentDidMount() {
    this.loadItems();
  }

  openDialog() {
    this.setState({open: true, isLoading: true});
  }

  closeDialog() {
    this.setState({open: false});
  }

  chooseIcon() {
    this.setState({open: false});
    this.props.chooseIcon(this.state.icon);
  }

  cancelSearch() {
    this.setState({searching: false, searchText: ''});
    setTimeout(() => {
      this.loadItems();
    }, 100);
  }

  searching(ev) {
    if (ev.key === 'Enter') {
      this.search();
      ev.preventDefault();
    }
  }

  search() {
    const searchList = icons.filter(obj => obj.join('-').includes(this.state.searchText));
    this.setState({searchList, searching: true, isLoading: true});
    setTimeout(() => {
      this.loadItems();
    }, 100);
  }

  loadItems() {
    this.setState({isLoading: true});
    const newSlice = this.state.iconList.length + 50;
    if (newSlice <= icons.length) {
      let nextIcons = [];
      if (this.state.searching === true) {
        if (this.state.searchList.length > 0) {
          nextIcons = this.state.searchList.slice(0, newSlice);
          this.setState({
            hasMoreItems: (nextIcons.length < this.state.searchList.length),
            isLoading: false,
            iconList: nextIcons
          });
        } else {
          this.setState({isLoading: false, hasMoreItems: false, iconList: []});
        }
      } else {
        nextIcons = icons.slice(0, newSlice);
        this.setState({
          hasMoreItems: (nextIcons.length <= icons.length),
          isLoading: false,
          iconList: nextIcons
        });
      }
    } else {
      this.setState({isLoading: false, hasMoreItems: false, iconList: icons});
    }
  }

  clickIcon(icon) {
    if (this.state.icon === null) {
      this.setState({icon});
    } else {
      this.setState({icon: null});
    }
  }

  renderSearch() {
    const {classes} = this.props;
    return (
      <Toolbar>
        <TextField
          value={this.state.searchText}
          autoFocus
          onChange={e => this.setState({searchText: e.target.value})}
          onKeyPress={this.searching}
          className={classes.textSearch}
          label="Buscar..."
        />
        {
          this.state.searchText !== '' &&
          <IconButton className={classes.icon} onClick={this.cancelSearch}>
            <CloseIcon />
          </IconButton>
        }
        <IconButton className={classes.icon} onClick={this.search}>
          <SearchIcon />
        </IconButton>
      </Toolbar>
    );
  }

  render() {
    const {classes} = this.props;
    const {iconList, hasMoreItems, icon, isLoading} = this.state;
    return (
      <div>
        <Button variant="outlined" color="primary" className={classes.button} onClick={this.openDialog}>
                    Escoger ícono
        </Button>
        <Dialog maxWidth="xl" fullWidth open={this.state.open} onClose={this.closeDialog} TransitionComponent={Transition}>
          <DialogTitle id="form-dialog-title">Escoger ícono</DialogTitle>                   
          {this.renderSearch()}  
          <DialogContent>
            <Toolbar>
              <Typography variant="caption">
                            Íconos
              </Typography>
            </Toolbar>
            <InfiniteGrid
              iconList={iconList}
              hasMoreItems={hasMoreItems}
              icon={icon}
              loadItems={this.loadItems}
              clickIcon={this.clickIcon}
            />
            {
              isLoading &&
                <CircularProgress style={{margin: '0 auto', display: 'table'}} />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.chooseIcon} color="primary" disabled={this.state.icon === null}>
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );       
  }
}

IconsGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  chooseIcon: PropTypes.func.isRequired
};

export default withStyles(styles)(IconsGrid);
