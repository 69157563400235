import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  ListItemIcon,
  Divider,
  Typography,
  Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { styles } from './styles';
import { ASESOR_JR_ID, ASESOR_ID } from '../../../../../utils/utils';
import { sortObjectByProperty } from './../../../../../utils/utils';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const AutomaticAssign = (props) => {
  const {
    classes,
    openAutomaticAssignModal,
    hasJuniorRol,
    leadsCount,
    closeModal,
    onLeadAssignChange,
    companyId,
    origin,
  } = props;
  const { users } = useSelector((state) => state.users);

  const [brokers, setBrokers] = useState([]);
  const [checked, setChecked] = useState([]);
  const [selectAll, setSelectAll] = useState(true);

  const handleToggleAll = () => {
    if (selectAll) setChecked([]);
    else setChecked(brokers.map((x) => x.id));

    setSelectAll(!selectAll);
  };

  const handleToggle = (id) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) newChecked.push(id);
    else newChecked.splice(currentIndex, 1);

    setSelectAll(newChecked.length === brokers.length);
    setChecked(newChecked);
  };

  useEffect(() => {
    const getBrokers = (profile) =>
      users.filter(
        (user) => user.profile.id === profile && user.company.id === companyId,
      );

    const brokerType = hasJuniorRol
      ? getBrokers(ASESOR_JR_ID)
      : getBrokers(ASESOR_ID);

    setBrokers(brokerType);
    setChecked(brokerType.map((x) => x.id));
  }, []);

  const handleNext = () =>
    onLeadAssignChange(
      null,
      brokers.map((x) => x.id).filter((x) => !checked.includes(x)),
    );

  const getHeaderTitle = () => {
    switch (checked.length) {
      case 0:
        return 'No se asignarán leads a los asesores';

      case brokers.length:
        return `Se asignarán ${leadsCount} leads a todos los asesores`;

      default:
        return `Se asignarán ${leadsCount} leads a ${checked.length} asesores`;
    }
  };

  const company = JSON.parse(localStorage.getItem('company'));

  const sortedBrokers = () => {
    if (!company) return brokers;

    const originAssignment =
      (company.assigmentConfiguration &&
        company.assigmentConfiguration.originAssignment) ||
      [];

    const formattedBrokers = brokers.map((item) => {
      const target = originAssignment.find(({ asesor }) => asesor === item.id);
      return {
        ...item,
        haveOrigin: target ? target.origins.includes(origin) : false,
      };
    });

    return sortObjectByProperty(formattedBrokers, 'haveOrigin', 'desc');
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        open={openAutomaticAssignModal}
        TransitionComponent={Transition}
      >
        <DialogTitle>Asignación automática de leads</DialogTitle>
        <DialogContent>
          <List className={classes.root}>
            <ListItem className={classes.header}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    checked.length !== 0 && checked.length === brokers.length
                  }
                  tabIndex={-1}
                  onChange={handleToggleAll}
                  inputProps={{ 'aria-labelledby': 'toggle all' }}
                  indeterminate={
                    checked.length > 0 && checked.length !== brokers.length
                  }
                />
              </ListItemIcon>
              <ListItemText id="toggle all" primary={getHeaderTitle()} />
            </ListItem>
            <Divider />
            {sortedBrokers().map((value, index) => {
              const labelId = `checkbox-list-secondary-label-${index}`;
              const initials = value.name
                .split(' ')
                .map((n) => n[0])
                .join('');

              return (
                <ListItem key={index}>
                  <ListItemAvatar>
                    {value.profile_img && value.profile_img.length > 0 ? (
                      <Avatar
                        alt={`Avatar n°${index + 1}`}
                        src={value.profile_img}
                      />
                    ) : (
                      <Avatar>{initials}</Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={
                      <Typography>
                        {value.name}
                        {value.haveOrigin && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip title="Asesor priorizado por orígen">
                              <InfoIcon color="secondary" />
                            </Tooltip>
                          </>
                        )}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={handleToggle(value.id)}
                      checked={checked.indexOf(value.id) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleNext}>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AutomaticAssign.propTypes = {
  classes: PropTypes.object.isRequired,
  openAutomaticAssignModal: PropTypes.bool.isRequired,
  hasJuniorRol: PropTypes.bool.isRequired,
  leadsCount: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  onLeadAssignChange: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
};

export default withStyles(styles)(AutomaticAssign);
