/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as observationsActions from './types/observations';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterObservations = (page, rowsPerPage, order, orderBy, searchText, filtersToApply) => async (dispatch) => {
  if (
    (searchText !== '' && store.getState().observations.searchText !== searchText) || 
    (Object.keys(filtersToApply).length && filtersToApply !== store.getState().observations.filters)
  ) {
    page = 0;
  }
  if (order !== store.getState().observations.order || orderBy !== store.getState().observations.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    filtersToApply = {};
  }
  dispatch({
    type: observationsActions.FILTER_OBSERVATIONS_SENDING,
    payload: {
      observations: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
      filters: filtersToApply
    }
  });
  try {
    const filter = {
      filters: filtersToApply,
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order
      },
      searchText,
      company: company && company._id
    };
    const request = axiosMaker('POST', 'observations/filter', filter, true);
    const response = await axios(request);
    return dispatch({
      type: observationsActions.FILTER_OBSERVATIONS_SUCCESS,
      payload: {
        observations: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: observationsActions.FILTER_OBSERVATIONS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getObservations = () => async (dispatch) => {
  dispatch({
    type: observationsActions.GET_OBSERVATIONS_SENDING,
    payload: {
      observations: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {},
      company: company && company._id
    };
    const response = await axios(axiosMaker('POST', 'observations/filter', data, true));
    return dispatch({
      type: observationsActions.GET_OBSERVATIONS_SUCCESS,
      payload: {
        observations: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: observationsActions.GET_OBSERVATIONS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

