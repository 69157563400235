export const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 4,
    padding: theme.spacing.unit * 3,
  },
  filterSelect: {
    width: '95%',
    margin: '0 auto',
    display: 'table',
    marginBottom: theme.spacing.unit * 2,
  },
  datePicker: {
    width: '100%',
  },
  datePickerRange: {
    width: '50%',
  },
  menuItemSpan: {
    fontSize: 10,
    color: '#949494',
    marginLeft: 20,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  dialogContent: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
  },
  noPadding: {
    padding: '0px !important',
  },
  mapContainer: {
    height: '100%',
    width: '100%',
  },
  markerTrivo: {
    marginLeft: '-15px',
    marginTop: '-24px',
  },
  muiDialog: {
    height: '100%',
    width: '100%',
    paddingRight: '0px !important',
  },
  titleProformas: {
    padding: '15px 24px 10px',
    borderBottom: '1px solid #f3f3f3',
  },
  footerActions: {
    borderTop: '1px solid #f3f3f3',
  },
  loader: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  headerProformas: {
    paddingBottom: theme.spacing.unit * 4,
    fontWeight: 'bold',
  },
  inputForm: {
    '& input': {
      padding: '6px 14px',
    },
  },
  selectInputForm: {
    '& div div': {
      padding: '10px 6px',
    },
  },
  labelForm: {
    transform: 'translate(14px, 6px) scale(1)',
  },
  textField: {
    width: '100%',
    marginTop: '10px',
    '& input': {
      padding: '6px 14px',
    },
  },
  inputShrink: {
    backgroundColor: 'white',
  },
  textFieldSelect: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '8px',
  },
  gridItem: {
    paddingBottom: '0px !important',
    paddingTop: '0px !important',
  },
  listText: {
    textAlign: 'start',
    '& *': {
      fontWeight: 'bold',
      color: 'grey',
    },
    '&.warning *': {
      fontWeight: 'bold',
      color: '#d0a703 !important',
    },
    '& span': {
      fontSize: 14,
    },
    '& p': {
      fontSize: 22,
      marginBottom: 0,
    },
  },
  warning: {
    fontSize: 11,
    fontWeight: '400',
    color: '#d0a703 !important',
  },
  listHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'grey',
    position: 'relative',
    height: 40,
  },
});

export default styles;
