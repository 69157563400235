import * as commentsActions from '../actions/types/comments';

const initialState = {
  comments: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, message, dataLength, comments} = action.payload;
  switch (action.type) {
  case commentsActions.FILTER_COMMENTS_SENDING:
    return {...state, sending: true, dataLength, comments};
  case commentsActions.FILTER_COMMENTS_SUCCESS:
    return {...state, sending: false, dataLength, comments};
  case commentsActions.FILTER_COMMENTS_FAILED:
    return {...state, sending: false, error, message, dataLength};
  default:
    return state;
  }
};
