export const styles = (theme) => ({
    textFields: {
      width: '80%',
      marginLeft: '10%',
      marginBottom: 16,
    },
    collapsable: {
      paddingLeft: '4px !important',
    },
    sectionPadding: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
    },
    section: {
      marginBottom: 16,
      marginTop: 16,
      width: '80%',
      marginLeft: '10%',
    },
    picker: {
      width: '100%',
    },
  });
  
  export default styles;
  