import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { styles } from './styles';

export class EnhancedTableHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderClassNames = this.renderClassNames.bind(this);
    this.renderCells = this.renderCells.bind(this);
  }

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  renderCells() {
    const { cols, direction, by, hasEditPermission } = this.props;
    const cells = cols.map((col, index) => {
      if (by === '' && direction === '') {
        return (
          <TableCell
            key={`${col.id}-${index}`}
            align={col.right ? 'right' : 'left'}
            padding={col.disablePadding ? 'none' : 'default'}
            className={this.renderClassNames(col.type)}
          >
            {col.label}
          </TableCell>
        );
      }
      if (
        (col.type === 'edit' || col.type === 'duplicate') &&
        !hasEditPermission
      ) {
        return null;
      }
      if (col.type === 'noSortableNumber') {
        return (
          <TableCell
            key={`${col.id}-${index}`}
            align={col.right ? 'right' : 'left'}
            padding={col.disablePadding ? 'none' : 'default'}
            className={this.renderClassNames(col.type)}
          >
            <TableSortLabel active={by === col.id}>{col.label}</TableSortLabel>
          </TableCell>
        );
      }
      return (
        <TableCell
          key={`${col.id}-${index}`}
          align={col.right ? 'right' : 'left'}
          padding={col.disablePadding ? 'none' : 'default'}
          sortDirection={by === col.id ? direction : false}
          className={this.renderClassNames(col.type)}
        >
          <Tooltip
            title="Ordenar"
            placement={col.right ? 'bottom-end' : 'bottom-start'}
            enterDelay={300}
          >
            <TableSortLabel
              active={by === col.id}
              direction={direction}
              onClick={this.createSortHandler(col.id)}
            >
              {col.label}
            </TableSortLabel>
          </Tooltip>
        </TableCell>
      );
    });
    return cells;
  }

  renderClassNames(type) {
    const { classes } = this.props;
    let className = classes.auto;
    if (
      type === 'edit' ||
      type === 'duplicate' ||
      type === 'attach' ||
      type === 'save' ||
      type === 'attributes' ||
      type === 'geolocation' ||
      type === 'bool' ||
      type === 'proforma'
    ) {
      className = classes.small;
    } else if (type === 'number' || type === 'noSortableNumber') {
      className = classes.medium;
    } else if (type === 'date') {
      className = classes.date;
    } else if (type === 'dateLink') {
      className = classes.dateLink;
    }
    return `${className} ${classes.cellPadding}`;
  }

  render() {
    const {
      onSelectAllClick,
      numSelected,
      rowCount,
      isSelectable,
      renderCheckGLobal,
    } = this.props;
    const pageRowCount = 20;
    const checkedCondition =
      numSelected === pageRowCount || numSelected === rowCount;
    return (
      <TableHead>
        <TableRow>
          {isSelectable && (
            <TableCell padding="checkbox">
              {renderCheckGLobal && (
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={checkedCondition}
                  onChange={onSelectAllClick}
                />
              )}
            </TableCell>
          )}
          {this.renderCells()}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  direction: PropTypes.string,
  by: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  cols: PropTypes.array.isRequired,
  isSelectable: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  renderCheckGLobal: PropTypes.bool.isRequired,
};

export default withStyles(styles)(EnhancedTableHead);
