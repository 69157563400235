export const styles = () => ({
  small: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 70,
    textAlign: 'center',
  },
  medium: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 150,
    textAlign: 'center',
  },
  auto: {
    textAlign: 'left',
  },
  date: {
    textAlign: 'left',
    minWidth: 180,
  },
  dateLink: {
    textAlign: 'center',
    minWidth: 180,
  },
  cellPadding: {
    padding: '4px 20px 4px 20px',
  },
});

export default styles;
