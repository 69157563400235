import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import {styles} from './styles';
import AttachmentsDetail from './detail';
import CONSTANTS from '../../../utils/constants';

export class Attachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attach: null,
      isNew: false,
      url: '',
      name: '',
      type: '',
      attachIndex: null
    };
    this.renderAttachments = this.renderAttachments.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.renderAttachType = this.renderAttachType.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
    this.handleUpdateName = this.handleUpdateName.bind(this);
    this.handleUpdateType = this.handleUpdateType.bind(this);
    this.handleUpdateAttachment = this.handleUpdateAttachment.bind(this);
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
  }

  handleGoBack() {
    this.setState({attach: null, attachIndex: null, isNew: false, name: '', url: '', type: ''});
  }

  handleUpdateAttachment() {
    const {unit} = this.props;
    const {isNew, name, url, type, attachIndex} = this.state;
    const {attachments} = unit;
    if (isNew) {
      const newAttachment = {
        name,
        url,
        type
      };
      attachments.push(newAttachment);
    } else {
      attachments[attachIndex] = {
        name,
        url,
        type
      };
    }
    this.props.onUpdateAttachments(attachments);
  }

  handleDeleteAttachment() {
    const {unit} = this.props;
    const {attachments} = unit;
    const {attachIndex} = this.state;
    attachments.splice(attachIndex, 1);  
    unit.attachments = attachments;
    this.props.onUpdateAttachments(attachments);
  }

  handleUpdateName(e) {
    this.setState({name: e.target.value});
  }

  handleUpdateType(e) {
    this.setState({type: e.target.value});
  }

  handleUrlUploaded(url) {
    //const domainUrl = url.replace(CONSTANTS.BUCKET_S3_NAME,CONSTANTS.BUCKET_DOMAIN_NAME) ;
    this.setState({url});
  }

  renderAttachType = (type) => {
    switch (type) {
    case 'image':
      return 'Imagen';
    case 'featured_image':
      return 'Imagen Destacada';
    case 'pdf':
      return 'Pdf';
    case 'other':
      return 'Otro';
    default:
      return type;
    }
  }

  renderAttachments() {
    const {unit} = this.props;
    const {attachments} = unit;
    if (attachments.length > 0) {
      return attachments.map((attach, index) => {
        return (
          <TableRow key={index}>
            <TableCell>{attach.name}</TableCell>  
            <TableCell>{this.renderAttachType(attach.type)}</TableCell>  
            <TableCell>
              <Tooltip title="Editar Adjunto">
                <IconButton onClick={() => this.setState({attachIndex: index, attach, name: attach.name, type: attach.type, url: attach.url, isNew: false})}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </TableCell>  
          </TableRow>
        );
      });
    }
    return null;
  }

  render() {
    const {classes, isLoading, unit, permissions} = this.props;
    const {attach, isNew, name, type, url} = this.state;
    const unitName = unit.name ? unit.name : '';
    return (
      <div>
        {
          attach === null ?
            <Paper elevation={2} className={classes.paperRoot}>
              <Toolbar>
                <IconButton onClick={this.props.onGoBack}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography variant="h6" style={{width: 500}}>
                  {`Adjuntos de la unidad ${unitName}`}
                </Typography>
                <div className={classes.spacer} />
                {
                  permissions && (
                    <Tooltip title="Agregar Adjunto">
                      <IconButton onClick={() => this.setState({attach: {}, name: '', type: '', url: '', isNew: true})}>
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }
                
              </Toolbar>
              <div style={{width: 'auto', overflowX: 'scroll'}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Editar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.renderAttachments()}
                  </TableBody>
                </Table>
              </div>
            </Paper>
            :
            <AttachmentsDetail 
              name={name}
              type={type}
              url={url}
              isNew={isNew}
              isLoading={isLoading}
              onGoBack={this.handleGoBack}
              onUrlUploaded={this.handleUrlUploaded}
              onUpdateName={this.handleUpdateName}
              onUpdateType={this.handleUpdateType}
              onUpdateAttachment={this.handleUpdateAttachment}
              onDeleteAttachment={this.handleDeleteAttachment}
              permissions={permissions}
            />
        }
      </div>
    );
  }
}

Attachments.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  permissions: PropTypes.bool,
  unit: PropTypes.object.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onUpdateAttachments: PropTypes.func.isRequired
};
  
export default (withStyles(styles)(Attachments));
