export const styles = (theme) => ({
  radioGroupContainer: {
    padding: `0 ${theme.spacing.unit * 4}px`,
  },
  footerContained: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginBottom: '20px',
    marginTop: '10px',
  },
});

export default styles;
