export const GET_CALENDAR_SUCCESS = 'GET_CALENDARS_SUCCESS';

export const GET_CALENDAR_SENDING = 'GET_CALENDAR_SENDING';

export const GET_CALENDAR_FAILED = 'GET_CALENDAR_FAILED';

export const GET_EVENTS_LIST_FAILED = 'GET_EVENTS_LIST_FAILED';

export const GET_EVENTS_LIST_SUCCESS = 'GET_EVENTS_LIST_SUCCESS';

export const GET_EVENTS_LIST_SENDING = 'GET_EVENTS_LIST_SENDING';

export const GET_EVENTS_LIST_FIRST_LOAD = 'GET_EVENTS_LIST_FIRST_LOAD';

export const GET_HISTORY_EVENTS_FAILED = 'GET_HISTORY_EVENTS_FAILED';

export const GET_HISTORY_EVENTS_SUCCESS = 'GET_HISTORY_EVENTS_SUCCESS';

export const GET_HISTORY_EVENTS_SENDING = 'GET_HISTORY_EVENTS_SENDING';

export const GET_HISTORY_EVENTS_FIRST_LOAD = 'GET_HISTORY_EVENTS_FIRST_LOAD';

export const GET_FILTER_CALENDAR_SUCCESS = 'GET_FILTER_CALENDAR_SUCCESS';

export const GET_FILTER_CALENDAR_SENDING = 'GET_FILTER_CALENDAR_SENDING';

export const GET_FILTER_CALENDAR_FAILED = 'GET_FILTER_CALENDAR_FAILED';

export const UPDATE_EVENT_SENDING = 'UPDATE_EVENT_SENDING';

export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';

export const UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED';

export const CREATE_EVENT_SENDING = 'CREATE_EVENT_SENDING';

export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';

export const CREATE_EVENT_FAILED = 'CREATE_EVENT_FAILED';

export const CANCEL_EVENT_SUCCESS = 'CANCEL_EVENT_SUCCESS';

export const CANCEL_EVENT_SENDING = 'CANCEL_EVENT_SENDING';

export const CANCEL_EVENT_FAILED = 'CANCEL_EVENT_FAILED';

export const GET_ACTIVE_EVENTS_FAILED = 'GET_ACTIVE_EVENTS_FAILED';

export const GET_ACTIVE_EVENTS_SUCCESS = 'GET_ACTIVE_EVENTS_SUCCESS';

export const GET_ACTIVE_EVENTS_SENDING = 'GET_ACTIVE_EVENTS_SENDING';

export const RESCHEDULE_EVENT_SENDING = 'RESCHEDULE_EVENT_SENDING';

export const RESCHEDULE_EVENT_SUCCESS = 'RESCHEDULE_EVENT_SUCCESS';

export const RESCHEDULE_EVENT_FAILED = 'RESCHEDULE_EVENT_FAILED';

export const GET_EVENTS_STATISTICS_SENDING = 'GET_EVENTS_STATISTICS_SENDING';

export const GET_EVENTS_STATISTICS_SUCCESS = 'GET_EVENTS_STATISTICS_SUCCESS';

export const GET_EVENTS_STATISTICS_FAILED = 'GET_EVENTS_STATISTICS_FAILED';

export const GET_EVENTS_REPORT_SENDING = 'GET_EVENTS_REPORT_SENDING';

export const GET_EVENTS_REPORT_SUCCESS = 'GET_EVENTS_REPORT_SUCCESS';

export const GET_EVENTS_REPORT_FAILED = 'GET_REVENTS_REPORT_FAILED';

export const GET_CALENDAR_EVENTS_SENDING = 'GET_CALENDAR_EVENTS_SENDING';

export const GET_CALENDAR_EVENTS_SUCCESS = 'GET_CALENDAR_EVENTS_SUCCESS';

export const GET_CALENDAR_EVENTS_FAILED = 'GET_CALENDAR_EVENTS_FAILED';

export const CLEAN_CALENDAR_EVENTS_FAILED = 'CLEAN_CALENDAR_EVENTS_FAILED';