import * as calendarActions from '../actions/types/calendar';

const initialState = {
  events: [],
  dataLength: 0,
  page: 1,
  sending: false,
  message: '',
  error: null,
  hasMore: true,
};

const messages = { onSuccessFetching: 'Historial de citas obtenidas satisfactoriamente' };

export default (state = initialState, action = {}) => {
  const {
    events = [],
    error = null,
    message = '',
    dataLength = 0,
    sending = false,
    page = 1,
    hasMore = true,
  } = action.payload || {};

  switch (action.type) {
    case calendarActions.GET_HISTORY_EVENTS_FIRST_LOAD:
      return { ...state, page, events, sending, hasMore };
    case calendarActions.GET_HISTORY_EVENTS_FAILED:
      return { ...state, error, message, page, events: [], sending, hasMore };
    case calendarActions.GET_HISTORY_EVENTS_SENDING:
      return {
        ...state,
        error: null,
        message: '',
        sending,
        page,
        dataLength,
        hasMore,
      };
    case calendarActions.GET_HISTORY_EVENTS_SUCCESS:
      return {
        ...state,
        error: null,
        message: messages.onSuccessFetching,
        sending,
        page,
        events,
        dataLength,
        hasMore,
      };

    default:
      return state;
  }
};
