/* eslint-disable no-redeclare */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Route, BrowserRouter, Redirect, Switch } from 'react-router-dom';
import PageTitle from '../components/common/pageTitle';
import CustomDrawer from '../components/common/customDrawer';
import Login from '../components/login';
import TrivoSmart from '../components/trivoSmart';
import TrivoSmartDemand from '../components/trivoSmartDemand';
import Users from '../components/users';
import Profiles from '../components/profiles';
import Leads from '../components/leads';
import Dashboard from '../components/dashboard';
import PipelineDashboard from '../components/pipelineDashboard';
import LeadsPdf from '../components/leadsPdf';
import PropertyTypes from '../components/catalogs/propertyTypes';
import Zones from '../components/catalogs/zones';
import LeadStates from '../components/catalogs/leadStates/index';
import LeadStages from '../components/catalogs/leadStages';
import LeadsAssignment from '../components/catalogs/leadsAssignment';
import Proformas from '../components/catalogs/proformas';
import Stages from '../components/catalogs/stages';
import Builders from '../components/catalogs/builders';
import Parameters from '../components/parameters';
import Projects from '../components/projects';
import WhatsappTemplates from '../components/catalogs/whatsappTemplates';
import EmailTemplates from '../components/catalogs/emailTemplates';
import Units from '../components/units';
import Rules from '../components/rules';
import CollectComissions from '../components/collectCommission';
import PayComissions from '../components/payCommissions';
import Comments from '../components/comments';
import Advance from '../components/advance';
import Observations from '../components/observations';
import store from '../store';
import { styles } from './styles';
import { CLIENT_ID, REFERRER_ID, BUILDER_ID } from '../utils/utils';
import Origins from '../components/catalogs/origins';
import LeadsReadonly from '../components/leadsReadonly';
import IdentificationTypes from '../components/catalogs/identificationTypes';
import Companies from '../components/catalogs/companies';
import Countries from '../components/catalogs/countries';
import Provinces from '../components/catalogs/provinces';
import Cities from '../components/catalogs/cities';
import Stands from '../components/catalogs/stands';
import CONSTANTS from '../utils/constants';
import TrivoSmartOffer from '../components/trivoSmartOffer';
import News from '../components/catalogs/news';
import Plans from '../components/catalogs/plans';
import { userHasTrivoSmart } from '../actions/users';
import Calendar from '../components/calendar';
import Groups from '../components/catalogs/groups';
import LeadsPdfV2 from '../components/leadsPdfV2';

const PrivateRoute = ({
  component: Component,
  authed,
  classes,
  open,
  title,
  handleDrawerOpen,
  handleDrawerClose,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <div>
            <PageTitle open={open} title={title} handleDrawerOpen={handleDrawerOpen} />
            <CustomDrawer handleDrawerClose={handleDrawerClose} open={open} />
            <div className={classes.content}>
              <Component {...props} />
            </div>
          </div>
        ) : (
          // eslint-disable-next-line react/prop-types
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

const ReportRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const PublicRoute = ({ component: Component, authed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <div>
            {localStorage.getItem('profileId') === BUILDER_ID ? (
              <Redirect to="/home" />
            ) : (
              <Redirect to="/" />
            )}
          </div>
        )
      }
    />
  );
};

export class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      authed: false,
      open: false,
      openTrivoSmart: false,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  componentWillMount() {
    if (localStorage.token) {
      const company = JSON.parse(localStorage.getItem('company'));
      this.setState({ authed: true });

      userHasTrivoSmart().then((hasTrivoSmartPermission) => {
        this.setState({
          openTrivoSmart: company && company.hasTrivoSmart && hasTrivoSmartPermission,
        });
      });
    } else {
      if (
        store.getState().user.token !== null &&
        store.getState().user.user !== null &&
        store.getState().user.profile !== null &&
        store.getState().user.profile._id !== CLIENT_ID &&
        store.getState().user.profile._id !== REFERRER_ID
      ) {
        console.log('store.getState().user defined ok:', store.getState().user);
        localStorage.setItem('token', store.getState().user.token);
        localStorage.setItem('profile', JSON.stringify(store.getState().user.profile));
        localStorage.setItem('profileId', store.getState().user.profile._id);
        localStorage.setItem('user', store.getState().user.user._id);
        localStorage.setItem('userEmail', store.getState().user.user.email);
        localStorage.setItem('zones', JSON.stringify(store.getState().user.user.zones));
        this.setState({ authed: true });
        if (store.getState().user.user.is_asesor) {
          localStorage.setItem('isBroker', store.getState().user.user.is_asesor);
        }
        if (store.getState().user.user.strategicPartner) {
          localStorage.setItem('strategicPartner', store.getState().user.user.strategicPartner);
        }
        if (store.getState().user.user.company) {
          localStorage.setItem('company', JSON.stringify(store.getState().user.user.company));
        }
        if (store.getState().user.user.builder) {
          localStorage.setItem('builder', store.getState().user.user.builder);
        } else {
          localStorage.setItem('builder', 'false');
        }
      }
      store.subscribe(() => {
        if (
          store.getState().user.token !== null &&
          store.getState().user.user !== null &&
          store.getState().user.profile !== null &&
          store.getState().user.profile._id !== CLIENT_ID &&
          store.getState().user.profile._id !== REFERRER_ID
        ) {
          console.log('store.getState().user defined ok:', store.getState().user);
          localStorage.setItem('token', store.getState().user.token);
          localStorage.setItem('profile', JSON.stringify(store.getState().user.profile));
          localStorage.setItem('profileId', store.getState().user.profile._id);
          localStorage.setItem('user', store.getState().user.user._id);
          localStorage.setItem('userName', store.getState().user.user.name);
          localStorage.setItem('userEmail', store.getState().user.user.email);
          localStorage.setItem('zones', JSON.stringify(store.getState().user.user.zones));
          localStorage.setItem('builder', store.getState().user.user.builder);
          this.setState({ authed: true });
          if (store.getState().user.user.is_asesor) {
            localStorage.setItem('isBroker', store.getState().user.user.is_asesor);
          }
          if (store.getState().user.user.strategicPartner) {
            localStorage.setItem('strategicPartner', store.getState().user.user.strategicPartner);
          }
          if (store.getState().user.user.company) {
            localStorage.setItem('company', JSON.stringify(store.getState().user.user.company));
          }
          if (store.getState().user.user.builder) {
            localStorage.setItem('builder', store.getState().user.user.builder);
          } else {
            localStorage.setItem('builder', 'false');
          }
        }
      });
    }
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  render() {
    const { isLoading, authed, open, openTrivoSmart } = this.state;
    const { classes } = this.props;
    const partner = localStorage.getItem('strategicPartner');
    return isLoading === true ? (
      <CircularProgress
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
      />
    ) : (
      <BrowserRouter basename={process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '/'} forceRefresh>
        <Switch>
          {!partner ? (
            <PrivateRoute
              path="/"
              authed={authed}
              exact
              component={openTrivoSmart ? TrivoSmart : Leads}
              open={open}
              classes={classes}
              handleDrawerClose={this.handleDrawerClose}
              handleDrawerOpen={this.handleDrawerOpen}
              title={openTrivoSmart ? 'Trivo Smart' : 'Leads'}
            />
          ) : (
            <PrivateRoute
              path="/"
              authed={authed}
              exact
              component={LeadsReadonly}
              open={open}
              classes={classes}
              handleDrawerClose={this.handleDrawerClose}
              handleDrawerOpen={this.handleDrawerOpen}
              title="Leads"
            />
          )}
          <PublicRoute authed={authed} path="/login" component={Login} />
          <PrivateRoute
            authed={authed}
            path="/usuarios"
            component={Users}
            title="Usuarios"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/parametros"
            component={Parameters}
            title="Perfilamiento"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/proyectos"
            component={Projects}
            title="Desarrollos"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/unidades"
            component={Units}
            title="Unidades"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          {/* <PrivateRoute
            authed={authed}
            path="/dashboard"
            component={Dashboard}
            title="Dashboard"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          /> */}
           <PrivateRoute
            authed={authed}
            path="/home"
            component={PipelineDashboard}
            title="Home"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/trivo-smart"
            component={TrivoSmart}
            title="Trivo Smart"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/trivo-smart-demanda"
            component={TrivoSmartDemand}
            title="Trivo Smart Demanda"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/trivo-smart-oferta"
            component={TrivoSmartOffer}
            title="Trivo Smart Oferta"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/leads"
            exact
            component={Leads}
            title="Leads"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/leads/:id"
            exact
            component={Leads}
            title="Leads"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/readonly"
            exact
            component={LeadsReadonly}
            title="Leads"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <ReportRoute path="/pdfv2/:id" component={LeadsPdf} />
          <ReportRoute path="/pdf/:id" component={LeadsPdfV2} />
          <PrivateRoute
            authed={authed}
            path="/catalogos/tiposdepropiedad"
            component={PropertyTypes}
            title="Tipos de Propiedad"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/sectores"
            component={Zones}
            title="Sectores"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/constructoras"
            component={Builders}
            title="Constructoras"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/etapas"
            component={Stages}
            title="Etapas"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/whatsapp"
            component={WhatsappTemplates}
            title="Mensajes de Whatsapp"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/plantillas"
            component={EmailTemplates}
            title="Plantillas de Correo"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/reglas"
            component={Rules}
            title="Reglas"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/perfiles"
            component={Profiles}
            title="Perfiles"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/estadosLead"
            component={LeadStates}
            title="Estados de Lead"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/etapas-lead"
            component={LeadStages}
            title="Etapas de Lead"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/comisiones/por-cobrar"
            component={CollectComissions}
            title="Comisiones"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/comisiones/por-pagar"
            component={PayComissions}
            title="Comisiones"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/comentarios"
            component={Comments}
            title="Comentarios"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/citas"
            component={Calendar}
            title="Citas"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/avance"
            component={Advance}
            title="Avance"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/observaciones"
            component={Observations}
            title="Observaciones"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/origenes"
            component={Origins}
            title="Orígenes"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/identificacion"
            component={IdentificationTypes}
            title="Tipo de identificación"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/empresas"
            component={Companies}
            title="Empresas"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/planes"
            component={Plans}
            title="Planes"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/paises"
            component={Countries}
            title="Paises"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/provincias"
            component={Provinces}
            title="Provincias"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/ciudades"
            component={Cities}
            title="Ciudades"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/asignacion-lead"
            component={LeadsAssignment}
            title="Asignación de Leads"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/proformas"
            component={Proformas}
            title="Proformas"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/stands"
            component={Stands}
            title="Stands"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <PrivateRoute
            authed={authed}
            path="/catalogos/noticias"
            component={News}
            title="Noticias"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
            <PrivateRoute
            authed={authed}
            path="/catalogos/grupos"
            component={Groups}
            title="Grupos"
            open={open}
            classes={classes}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <Route render={() => <h6>Error 404 No Encontrado</h6>} />
        </Switch>
      </BrowserRouter>
    );
  }
}
Router.propTypes = {
  classes: PropTypes.object.isRequired,
};
PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  authed: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.object,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

PublicRoute.propTypes = {
  component: PropTypes.object.isRequired,
  authed: PropTypes.bool.isRequired,
};

ReportRoute.propTypes = {
  component: PropTypes.object.isRequired,
};

export default withStyles(styles)(Router);
