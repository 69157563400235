/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {advanceCols, editAdvanceCols, advanceMultipleCols} from '../../utils/colTypes';
import {advanceOptions} from '../../utils/filterTypes';
import {filterAdvances, updateAdvance} from '../../actions/advance';
import {getLeadStates} from '../../actions/leadStates';
import {getUsers} from '../../actions/users';
import EnhancedTable from '../common/enhancedTable';
import CustomSnackbar from '../common/customSnackbar';
import {copyObject} from '../../utils/utils';

export class Advance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Avance',
      filterChips: [],
      filterOptions: copyObject(advanceOptions)
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.renderCols = this.renderCols.bind(this);
    this.filtersAttributes = this.filtersAttributes.bind(this);
  }
  
  async componentWillMount() {    
    this.getViewPermission();
    const now = new Date();
    const month = now.getMonth();
    const year = now.getFullYear();
    const monthsArray = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    this.props.getLeadStates();
    await this.props.getUsers();
    const asesors = this.props.users.filter((obj) => {
      if (obj.profile) {
        if (obj.profile._id) {
          if (obj.is_asesor === true) {
            return true;
          }
        }
      }
      return false;
    });
    const initialFilters = {
      userId: asesors.length > 0 ? asesors[0].id : '',
      monthFrom: monthsArray[month],
      yearFrom: year,
      monthTo: monthsArray[month],
      yearTo: year
    };
    this.props.filterAdvances(
      this.props.page,
      this.props.rowsPerPage,
      this.props.searchText,
      initialFilters
    );
    const filterChips = [
      {
        id: 'userId',
        label: 'Asesor',
        title: asesors[0].name,
        type: 'select',
        value: asesors[0].id
      },
      {
        id: 'monthFrom',
        label: 'Mes desde',
        title: monthsArray[month],
        type: 'select',
        value: monthsArray[month]
      },
      {
        id: 'yearFrom',
        label: 'Año desde',
        title: year,
        type: 'select',
        value: year
      },
      {
        id: 'monthTo',
        label: 'Mes hasta',
        title: monthsArray[month],
        type: 'select',
        value: monthsArray[month]
      },
      {
        id: 'yearTo',
        label: 'Año hasta',
        title: year,
        type: 'select',
        value: year
      }
    ];
    this.setState({filterChips});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.renderFilters();
    }
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_advance) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  };

  handleUpdate(advance) {
    const {_id} = advance;
    const {filterChips} = this.state;
    const patch = {
      planned: advance.planned
    };
    const filters = {
      userId: filterChips[0].value,
      monthFrom: filterChips[1].value,
      yearFrom: filterChips[2].value,
      monthTo: filterChips[3].value,
      yearTo: filterChips[4].value
    };
    this.props.updateAdvance(_id, patch).then(() => {
      this.props.filterAdvances(this.props.page, this.props.rowsPerPage, this.props.searchText, filters);
    });
  }

  filtersAttributes = (allFilters) => {
    const items = allFilters;
    const preFilters = Object.entries(items).filter(obj => obj[1] !== '');
    const filters = {};
    preFilters.forEach((item) => {
      const key = item[0];
      if (key === 'status' || key === 'stage') {
        if (filters[key]) {
          const indexOf = filters[key].indexOf(item[1].value);
          if (indexOf > -1) {
            filters[key].splice(indexOf, 1);
          } else {
            filters[key].push(item[1].value);
          }
        } else {
          filters[key] = [item[1].value];
        }
      } else if (item[1].value) {
        filters[key] = item[1].value;
      } else {
        filters[key] = item[1];
      }
    });
    return filters;
  }

  handleFilter(filters, filterChips) {
    const filtersToApply = this.filtersAttributes(filters);
    this.setState({filterChips});
    this.props.filterAdvances(this.props.page, this.props.rowsPerPage, this.props.searchText, filtersToApply);
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterAdvances(this.props.page, rowsPerPage, this.props.searchText, this.props.filters);
  }

  handleChangePage(page) {
    this.props.filterAdvances(page, this.props.rowsPerPage, this.props.searchText, this.props.filters);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  renderFilters() {
    const {users, leadStates} = this.props;
    if (users.length > 0 && leadStates.length > 0) {
      const filterOptions = copyObject(advanceOptions).map((item) => {
        const filter = item;
        if (filter.id === 'userId') {
          const types = [{title: 'Todos', value: 'all'}];
          if (users) {
            const asesors = users.filter((obj) => {
              if (obj.profile) {
                if (obj.profile._id) {
                  if (obj.is_asesor === true) {
                    return true;
                  }
                }
              }
              return false;
            });
            asesors.forEach((leadStage) => {
              const newType = {
                title: leadStage.name,
                value: leadStage.id
              };
              types.push(newType);
            });
            filter.types = types;
          }
        } else if (filter.id === 'status') {
          const types = [{title: 'Todos', value: 'all'}];
          leadStates.forEach((leadState) => {
            const newType = {
              title: leadState.name,
              value: leadState.id
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'stage') {
          const types = [{title: 'Todos', value: 'all'}];
          leadStates.forEach((leadState) => {
            if (leadState.stages) {
              leadState.stages.forEach((stage) => {
                const newType = {
                  title: stage.name,
                  value: stage.id,
                  leadState: leadState.id,
                  leadStateName: leadState.name
                };
                types.push(newType);
              });
            }
          });
          filter.types = types;
        } 
        return filter;
      });
      this.setState({filterOptions});
    }
  }

  renderCols() {
    const {filterChips} = this.state;
    if (filterChips.length > 0) {
      if (filterChips.length === 3) {
        if (filterChips[1].title !== filterChips[2].title) {
          return advanceMultipleCols;
        }
      }
    }
    return advanceCols;
  }

  render() {
    const {advances, dataLength, isLoadingUsers, isLoading} = this.props;
    const {title, filterOptions, filterChips} = this.state;
    const data = {
      rows: advances,
      cols: this.renderCols(),
      editCols: editAdvanceCols
    };
    const handlers = {
      onOrder: this.handleOrder,
      onFilter: this.handleFilter,
      onUpdate: this.handleUpdate,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isSortable: false,
      isSelectable: false,
      isEditableInline: true,
      hasEditPermission: true,
      hasCreatePermission: false,
      cantSearch: true,
      isSingle: false,
      searchText: this.props.searchText,
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: true,
        options: filterOptions,
        chips: filterChips,
        by: this.props.filters,
        avoidRemove: true 
      }
    };
    return isLoading === true || isLoadingUsers === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <div>
        {this.renderSnackBar()}
        <EnhancedTable 
          title={title}
          data={data} 
          handlers={handlers}
          config={config}
        /> 
      </div>
    );
  }
}

Advance.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingUsers: PropTypes.bool.isRequired,
  filterAdvances: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getLeadStates: PropTypes.func.isRequired,
  leadStates: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  advances: PropTypes.array.isRequired,
  dataLength: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  updateAdvance: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const {advances, users, leadStates} = state;
  return { 
    isLoading: advances.sending,
    isLoadingLeadStates: leadStates.sending, 
    leadStates: leadStates.leadStates,
    advances: advances.advances,
    users: users.users,
    isLoadingUsers: users.sending,
    dataLength: advances.dataLength, 
    message: advances.message,
    error: advances.error,
    page: advances.page,
    rowsPerPage: advances.rowsPerPage,
    searchText: advances.searchText,
    filters: advances.filters
  };
};
const mapDispatchToProps = {
  filterAdvances,
  getLeadStates,
  updateAdvance,
  getUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(Advance);
