/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment-timezone';
import {
  Chip,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { getUser } from '../../../actions/users';
import { styles } from './styles';
import Preferences from './preferences';
import AppointmentSelectorModal from '../../common/appointmentSelectorModal';
import User from './user';
import NextActions from './nextActions';
import Observations from './observations';
import BestOptions from './bestOptions';
import CustomSnackbar from './../../common/customSnackbar';
import {
  dateRegion,
  ADVICE,
  OBSERVATION,
  findIndexFromObjectsArray,
  observationTypes,
  FERIA_ONLINE_QUITO_ORIGIN,
  ASESOR_ID,
} from '../../../utils/utils';
import { GET_USER_SUCCESS } from '../../../actions/types/users';
import { getLead } from '../../../actions/leads';
import { rescheduleEvent, getActiveEvents } from '../../../actions/calendar'
import { sortObjectByProperty } from './../../../utils/utils';
import LeadTasks from './leadTasks';
import EventsHistory from './eventsHistory';
import TasksHistory from './tasksHistory';

export class LeadsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextActions: false,
      leadObservations: '',
      referrer: null,
      typeObservation: 'nota',
      stands: [],
      isOpenEventRescheduleModal: false,
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCreateLead = this.handleCreateLead.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.handleNextActions = this.handleNextActions.bind(this);
    this.onObservationsChange = this.onObservationsChange.bind(this);
    this.handleUpdateLeadMatches = this.handleUpdateLeadMatches.bind(this);
    this.handleRemoveMatch = this.handleRemoveMatch.bind(this);
    this.handleRemoveMatches = this.handleRemoveMatches.bind(this);
    this.handleSwapMatchUnits = this.handleSwapMatchUnits.bind(this);
    this.handleSwapMatches = this.handleSwapMatches.bind(this);
    this.handleAddUnitMatchComment = this.handleAddUnitMatchComment.bind(this);
    this.handleAddObservation = this.handleAddObservation.bind(this);
    this.handleChangeUnitComment = this.handleChangeUnitComment.bind(this);
    this.handleChangeProjectMatchStage = this.handleChangeProjectMatchStage.bind(
      this,
    );
    this.renderLoadingLead = this.renderLoadingLead.bind(this);
    this.renderReferrer = this.renderReferrer.bind(this);
    this.handleNextLead = this.handleNextLead.bind(this);
    this.handleGoBackLead = this.handleGoBackLead.bind(this);
    this.handlerPreReschedule = this.handlerPreReschedule.bind(this);
    this.handlerReschedule = this.handlerReschedule.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.getActiveEvents = this.getActiveEvents.bind(this);
  }

  componentWillMount() {
    const { lead } = this.props;

    this.getActiveEvents();

    if (lead.referrer) {
      this.props.getUser(lead.referrer).then((result) => {
        if (result.type === GET_USER_SUCCESS) {
          this.setState({ referrer: result.payload.user });
        }
      });
    }
    if (lead.stands && lead.stands.length > 0) {
      this.setState({ stands: lead.stands });
    }
  }

  async getActiveEvents() {
    const lead = this.props.lead;
    const broker = localStorage.getItem('user');
    await this.props.getActiveEvents({ leads: [lead.id], brokers: [broker] });
  }

  onObservationsChange(e) {
    this.setState({ leadObservations: e.target.value });
  }

  handleUpdateLeadMatches(parameters) {
    return this.props.onUpdateLeadMatches(parameters);
  }

  handleRemoveMatch(id, index) {
    this.props.onRemoveMatch(id, index);
  }

  handleRemoveMatches(id, indexes) {
    this.props.onRemoveMatches(id, indexes);
  }

  handleSwapMatchUnits(id, projectIndex, oldIndex, newIndex) {
    this.props.onSwapMatchUnits(id, projectIndex, oldIndex, newIndex);
  }

  handleSwapMatches(id, oldIndex, newIndex) {
    this.props.onSwapMatches(id, oldIndex, newIndex);
  }

  handleAddObservation({ newObservation, newType }) {
    const { lead } = this.props;
    const { leadObservations, typeObservation } = this.state;
    if (lead.observations) {
      const isBroker = localStorage.getItem('isBroker');
      let origin = ADVICE;
      if (isBroker) {
        origin = OBSERVATION;
      }
      const userId = localStorage.getItem('user');
      const observation = {
        date_created: moment.tz(moment(), dateRegion),
        origin,
        description: newObservation || leadObservations,
        userId,
        type: newType || typeObservation,
      };
      lead.observations.push(observation);
      this.props.handleAddObservation(lead);
      this.setState({ leadObservations: '' });
    }
  }

  handleAddUnitMatchComment(id, index, unitId, comment) {
    this.props.onAddUnitMatchComment(id, index, unitId, comment);
  }

  handleChangeProjectMatchStage(id, matchIndex, stage) {
    this.props.onChangeProjectMatchStage(id, matchIndex, stage);
  }

  handleChangeUnitComment(projectIndex, comment) {
    this.props.onChangeUnitComment(projectIndex, comment);
  }

  handleNextActions() {
    this.setState({ nextActions: true });
  }

  handleGoBack() {
    const { nextActions } = this.state;
    if (nextActions) {
      this.setState({ nextActions: false });
    } else {
      this.props.handleGoBack();
      window.history.replaceState('', '', '/leads');
    }
  }

  handleCreateLead(lead) {
    this.props.onCreateLead(lead);
  }

  async handleUpdateUser(id, user) {
    await this.props.onUpdateLeadUser(id, user);
    this.props.getLead(id);
  }

  async handleNextLead() {
    const { leads, lead, page, rowsPerPage, dataLength } = this.props;
    const limitPages = Math.ceil(dataLength / rowsPerPage);
    let position = 0;
    const validateLeadPosition = findIndexFromObjectsArray(
      leads,
      '_id',
      lead._id,
    );
    position = validateLeadPosition + 1;
    if (
      validateLeadPosition >= 0 &&
      validateLeadPosition < rowsPerPage &&
      position < leads.length
    ) {
      this.props.getLead(leads[position]._id);
    } else if (limitPages > page + 1 && position === leads.length) {
      const result = await this.props.handleChangePage();
      if (result.type === 'FILTER_LEADS_SUCCESS') {
        const newLeads = result.payload.leads[0];
        await this.props.getLead(newLeads._id);
      }
    } else {
      // eslint-disable-next-line no-undef
      alert('Llegaste al final de los leads');
    }
  }

  async handleGoBackLead() {
    const { leads, lead, page, rowsPerPage, handleBackPage } = this.props;
    let position = 0;
    const validateLeadPosition = findIndexFromObjectsArray(
      leads,
      '_id',
      lead._id,
    );
    position = validateLeadPosition - 1;
    if (
      validateLeadPosition >= 0 &&
      validateLeadPosition < rowsPerPage &&
      position >= 0
    ) {
      this.props.getLead(leads[position]._id);
    } else if (page > 0 && position < 0) {
      const result = await handleBackPage();
      if (result.type === 'FILTER_LEADS_SUCCESS') {
        const newLeads = result.payload.leads[19];
        await this.props.getLead(newLeads._id);
      }
    } else {
      // eslint-disable-next-line no-undef
      alert('Llegaste al final de los leads');
    }
  }

  renderLoadingLead() {
    const { isLoading, lead } = this.props;
    if (isLoading === true || lead === null) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }
    return null;
  }

  renderReferrer() {
    const { classes } = this.props;
    const { referrer } = this.state;
    if (referrer) {
      return (
        <div className={classes.paperRootPaddingCustom}>
          <Typography variant="h6">Referido por:</Typography>
          <br />
          <Typography variant="subtitle1">
            {`${referrer.name} ${referrer.lastname}`}
          </Typography>
          <a href={`tel:${referrer.phone}`}>
            <Typography variant="body1">{referrer.phone}</Typography>
          </a>
          <a href={`mailto:${referrer.email}`}>
            <Typography variant="body1">{referrer.email}</Typography>
          </a>
        </div>
      );
    }
    return null;
  }

  renderSelectValue() {
    const { classes, stands } = this.props;
    const standsFiltered = stands.filter((stand) =>
      this.state.stands.includes(stand.id),
    );
    return (
      <div className={classes.selectMenu}>
        {standsFiltered.map((stand, index) => (
          <div key={index}>
            <Chip label={stand.name.toString()} className={classes.chip} />
          </div>
        ))}
      </div>
    );
  }

  handlerPreReschedule() {
    const processEvents = () => {
      const activeEvents = this.props.activeEvents;

      if (activeEvents.length) {
        if (activeEvents.length === 1) {
          return this.handlerReschedule(activeEvents[0].id);
        }
        return this.setState({ isOpenEventRescheduleModal: true });
      }
    }

    this.getActiveEvents().then(processEvents).catch(processEvents);
    
  }

  handlerReschedule(eventId) {
    this.props.rescheduleEvent(eventId)
  }

  renderSnackBar() {
    const rescheduleEventInfo = this.props.rescheduleEventInfo;

    if (rescheduleEventInfo.message) {
      return (
        <CustomSnackbar
          variant={rescheduleEventInfo.error !== null ? 'error' : 'success'}
          message={rescheduleEventInfo.message}
        />
      );
    }

    return null;
  }
  render() {
    const {
      classes,
      isNew,
      zones,
      propertyTypes,
      lead,
      leadStates,
      users,
      allParameters,
      hasAdminPermissions,
      leadStages,
      origins,
      companies,
      stands,
      searchText,
      projects,
      activeEvents,
    } = this.props;
    const { nextActions, leadObservations, isOpenEventRescheduleModal } = this.state;
    let renderNavigate = true;
    const profileId = localStorage.getItem('profileId');
    const userIdStorage = localStorage.getItem('user');
    if (
      (window.location.pathname == '/' ||
        window.location.pathname == '/leads') &&
      profileId == ASESOR_ID && searchText !== ""
    ) {
      renderNavigate = false;
    }


    return (
      <div>
        {isOpenEventRescheduleModal && (
          <AppointmentSelectorModal
            rescheduleEvent={this.handlerReschedule}
            onClose={() => this.setState({ isOpenEventRescheduleModal: false })}
          />
        )}
        {this.renderLoadingLead()}
        {this.renderSnackBar()}
        {nextActions === true ? (
          <NextActions
            handleGoBack={this.handleGoBack}
            zones={zones}
            users={users}
            rescheduleEvent={this.handlerPreReschedule}
            leadStates={leadStates}
            onAddNewAction={this.props.onAddNewAction}
            onUpdateLeadState={this.props.onUpdateLeadState}
            onReassignLead={this.props.onReassignLead}
            onSendLeadEmail={this.props.onSendLeadEmail}
            onUpdateTemperature={this.props.onUpdateTemperature}
            onUpdatePrequalification={this.props.onUpdatePrequalification}
            onUpdateEstimatedClosingTime={
              this.props.onUpdateEstimatedClosingTime
            }
            onUpdateClosingPrice={this.props.onUpdateClosingPrice}
            handleAddObservation={this.handleAddObservation}
          />
        ) : (
          <div>
            <Paper elevation={2} className={classes.paperRoot}>
              <Toolbar>
                <IconButton onClick={this.handleGoBack}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography variant="h6" className={classes.flexToolbar}>
                  {!isNew ? 'Detalle de ' : 'Nuevo '} Lead
                </Typography>
                <div>
                  {renderNavigate ? (
                    <Button
                      size="small"
                      className={classes.margin}
                      onClick={this.handleGoBackLead}
                    >
                      <ArrowBackIosIcon />
                    </Button>
                  ) : (
                    ''
                  )}

                  {renderNavigate ? (
                    <Button
                      size="small"
                      className={classes.margin}
                      onClick={this.handleNextLead}
                    >
                      <ArrowForwardIos />
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </Toolbar>
              <User
                onCreateLead={this.handleCreateLead}
                onUpdateOrigin={(origin) => this.props.onOriginChange(origin)}
                onUpdateUser={(user) => this.props.onUpdateUser(user)}
                onUpdateLeadUser={this.handleUpdateUser}
                onClickNextActions={this.handleNextActions}
                isNew={isNew}
                origins={origins}
                companies={companies}
              />
            </Paper>
            {!isNew && (
              <div>
                <Paper elevation={2} className={classes.paperRoot}>
                  <Toolbar>
                    <Typography variant="h6">Desarrollo Origen</Typography>
                  </Toolbar>
                  <div style={{ padding: 20 }}>
                    <Select
                      value={lead.originProject || ""}
                      onChange={(e) =>
                        this.props.onOriginProjectUpdate(e.target.value)
                      }
                      fullWidth
                    >
                      {sortObjectByProperty(projects, 'name').map((project, projectIndex) => (
                        <MenuItem value={project.id} key={projectIndex}>
                          {project.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Paper>
                {lead.origin && (
                  <Paper elevation={2} className={classes.paperRoot}>
                    <Toolbar>
                      <Typography variant="h6">Origen</Typography>
                    </Toolbar>
                    <div style={{ padding: 20 }}>
                      <Select
                        value={lead.origin}
                        onChange={(e) =>
                          this.props.onOriginUpdate(e.target.value)
                        }
                        fullWidth
                        disabled={hasAdminPermissions === false}
                      >
                        {origins.map((origin, originIndex) => (
                          <MenuItem value={origin.id} key={originIndex}>
                            {origin.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {this.renderReferrer()}
                  </Paper>
                )}
                {lead.origin && lead.origin === FERIA_ONLINE_QUITO_ORIGIN && (
                  <Paper elevation={2} className={classes.paperRoot}>
                    <Toolbar>
                      <Typography variant="h6">Stands</Typography>
                    </Toolbar>
                    <div style={{ padding: 20 }}>
                      <Select
                        multiple
                        value={this.state.stands}
                        onChange={(e) => {
                          this.setState({ stands: e.target.value });
                          this.props.onStandsUpdate(e.target.value);
                        }}
                        renderValue={() => this.renderSelectValue()}
                        fullWidth
                      >
                        {stands.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {this.renderReferrer()}
                  </Paper>
                )}

                {lead.user.avalData &&
                  lead.user.avalData.resultadoSegmentacion[0]
                    .ResultadoEvaluacionIntegral === 'Aprobado' &&
                  lead.user.avalData.montosPreaprobados &&
                  lead.user.avalData.montosPreaprobados.length > 0 && (
                    <Paper elevation={2} className={classes.paperRoot}>
                      <Toolbar>
                        <Typography variant="h6">
                          Montos Preaprobados
                        </Typography>
                      </Toolbar>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Rango</TableCell>
                            <TableCell align="center">Plazo (Años)</TableCell>
                            <TableCell align="center">Monto Aprobado</TableCell>
                            <TableCell align="center">Cuota</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {lead.user.avalData.montosPreaprobados.map((row) => (
                            <TableRow key={row.rango}>
                              <TableCell align="center">{row.rango}</TableCell>
                              <TableCell align="center">
                                {row.plazo / 12}
                              </TableCell>
                              <TableCell align="center">{`$ ${row.montoAprobado.toLocaleString(
                                'en-US',
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )}`}</TableCell>
                              <TableCell align="center">{`$ ${row.cuota.toLocaleString(
                                'en-US',
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              )}`}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  )}

                <Paper elevation={2} className={classes.paperRoot}>
                  <Toolbar>
                    <Typography variant="h6">Observaciones</Typography>
                  </Toolbar>

                  <div style={{ padding: 20 }}>
                    <FormControl fullWidth>
                      <Select
                        value={this.state.typeObservation}
                        onChange={(e) =>
                          this.setState({ typeObservation: e.target.value })
                        }
                        fullWidth
                        placeholder="Tipo de interacción"
                      >
                        {observationTypes.map(
                          (observation, observationIndex) => (
                            <MenuItem
                              value={observation.value}
                              key={observationIndex}
                            >
                              {observation.name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                      <FormHelperText>Tipo de interacción</FormHelperText>
                    </FormControl>
                  </div>
                  <div style={{ padding: 20 }}>
                    <TextField
                      variant="outlined"
                      value={leadObservations}
                      fullWidth
                      placeholder="Nueva observación..."
                      multiline
                      rows={2}
                      onChange={this.onObservationsChange}
                    />
                  </div>
                  <div
                    style={{ display: 'table', margin: '0 auto', padding: 10 }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      className={classes.submit}
                      color="primary"
                      onClick={this.handleAddObservation}
                      disabled={leadObservations === ''}
                    >
                      Agregar observación
                    </Button>
                  </div>
                  <Observations />
                </Paper>
                {/* <Tasks /> */}
                <LeadTasks lead={lead.id} />
                <TasksHistory leadId={lead.id} />
                <EventsHistory leadId={lead.id} />
                <Preferences
                  onUpdateAnswer={this.props.onUpdateAnswer}
                  onEditAttributes={this.props.onEditAttributes}
                  allParameters={allParameters}
                />
                <BestOptions
                  zones={zones}
                  // eslint-disable-next-line no-return-assign
                  ref={(section) => {
                    this.BestOptions = section;
                  }}
                  onUpdateLeadMatches={this.handleUpdateLeadMatches}
                  onRemoveMatch={this.handleRemoveMatch}
                  onRemoveMatches={this.handleRemoveMatches}
                  onSwapMatchUnits={this.handleSwapMatchUnits}
                  onSwapMatches={this.handleSwapMatches}
                  onAddUnitMatchComment={this.handleAddUnitMatchComment}
                  onChangeUnitComment={this.handleChangeUnitComment}
                  onChangeProjectMatchStage={this.handleChangeProjectMatchStage}
                  handleChangePointsInFavor={
                    this.props.handleChangePointsInFavor
                  }
                  handleChangePointsAgainst={
                    this.props.handleChangePointsAgainst
                  }
                  handleSeeCommentInFavor={this.props.handleSeeCommentInFavor}
                  handleSeeCommentAgainst={this.props.handleSeeCommentAgainst}
                  onReplaceMatches={this.props.onReplaceMatches}
                  propertyTypes={propertyTypes}
                  leadStages={leadStages}
                  onBlockProject={this.props.onBlockProject}
                  onUnBlockProject={this.props.onUnBlockProject}
                  handleDuplicateProject={this.props.handleDuplicateProject}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

LeadsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasAdminPermissions: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  lead: PropTypes.object.isRequired,
  zones: PropTypes.array.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  leadStages: PropTypes.array.isRequired,
  allParameters: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  leadStates: PropTypes.array.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleAddObservation: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  onChangeUnitComment: PropTypes.func.isRequired,
  onUpdateAnswer: PropTypes.func.isRequired,
  onUpdateTemperature: PropTypes.func.isRequired,
  onUpdateEstimatedClosingTime: PropTypes.func.isRequired,
  onUpdateLeadState: PropTypes.func.isRequired,
  onAddNewAction: PropTypes.func.isRequired,
  onBlockProject: PropTypes.func.isRequired,
  onUnBlockProject: PropTypes.func.isRequired,
  onEditAttributes: PropTypes.func.isRequired,
  onCreateLead: PropTypes.func.isRequired,
  onOriginChange: PropTypes.func.isRequired,
  onOriginUpdate: PropTypes.func.isRequired,
  onOriginProjectUpdate: PropTypes.func.isRequired,
  onStandsUpdate: PropTypes.func.isRequired,
  onUpdateLeadMatches: PropTypes.func.isRequired,
  onReplaceMatches: PropTypes.func.isRequired,
  onRemoveMatch: PropTypes.func.isRequired,
  onRemoveMatches: PropTypes.func.isRequired,
  onSwapMatchUnits: PropTypes.func.isRequired,
  onSwapMatches: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  rescheduleEvent: PropTypes.func.isRequired,
  getActiveEvents: PropTypes.func.isRequired,
  onAddUnitMatchComment: PropTypes.func.isRequired,
  onUpdateClosingPrice: PropTypes.func.isRequired,
  onSendLeadEmail: PropTypes.func.isRequired,
  onUpdateLeadUser: PropTypes.func.isRequired,
  onReassignLead: PropTypes.func.isRequired,
  handleSeeCommentAgainst: PropTypes.func.isRequired,
  handleSeeCommentInFavor: PropTypes.func.isRequired,
  handleChangePointsInFavor: PropTypes.func.isRequired,
  handleChangePointsAgainst: PropTypes.func.isRequired,
  onChangeProjectMatchStage: PropTypes.func.isRequired,
  handleDuplicateProject: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleBackPage: PropTypes.func.isRequired,
  getLead: PropTypes.func,
  activeEvents: PropTypes.array.isRequired,
  rescheduleEventInfo: PropTypes.object.isRequired,
  leads: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  dataLength: PropTypes.number.isRequired,
  origins: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  stands: PropTypes.array,
  searchText: PropTypes.string,
  projects: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { leads, leadActiveEvents } = state;
  return {
    activeEvents: leadActiveEvents.events,
    rescheduleEventInfo: leadActiveEvents.rescheduleEvent,
    leads: leads.leads,
    lead: leads.lead,
    page: leads.page,
    rowsPerPage: leads.rowsPerPage,
    dataLength: leads.dataLength,
    isLoading: leads.sendingLead,
  };
};

const mapDispatchToProps = {
  getUser,
  rescheduleEvent,
  getActiveEvents,
  getLead,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(LeadsDetails));
