import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {filterOrigins, createOrigin, updateOrigin} from '../../../actions/origins';
import {getProfile} from '../../../actions/profiles';
import EnhancedTable from '../../common/enhancedTable';
import {originsCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import OriginDetail from './detail';
import {getCompanies} from '../../../actions/companies';
import {SUPER_ADMIN_ROL_ID} from '../../../utils/utils';

export class Origins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      origin: null,
      isNew: false,
      title: 'Orígenes'
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleUpdateOrigin = this.handleUpdateOrigin.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.getCompanies();
    this.props.filterOrigins(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  onAdd() {
    this.setState({isNew: true, origin: {}});
  }

  onDetail(id) {
    const origin = this.props.origins.filter(obj => obj.id === id)[0];
    this.setState({origin, isNew: false});
  }

  onOrder(order, orderBy) {
    this.props.filterOrigins(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText);
  }

  onSearch(searchText) {
    this.props.filterOrigins(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_origins) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_profiles) {
        return true;
      }
    }
    return false;
  }

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_profiles) {
        return true;
      }
    }
    return false;
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterOrigins(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleChangePage(page) {
    this.props.filterOrigins(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleGoBack() {
    this.props.filterOrigins(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
    this.setState({origin: null, isNew: false});
  }

  handleUpdateOrigin(originEdited) {
    const {origin} = this.state;
    this.setState({origin: originEdited});
    const edited = {};
    if (origin.active !== originEdited.active) {
      edited.active = originEdited.active;
    }
    if (origin.name !== originEdited.name) {
      edited.name = originEdited.name;
    }
    if (origin.company !== originEdited.company) {
      edited.company = originEdited.company;
    }
    this.props.updateOrigin(originEdited.id, edited).then(() => {
      this.handleGoBack();
    });
  }

  formatColsFromUser = (cols) => {
    const profileId = localStorage.getItem('profileId');
    let newCols = cols;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      newCols = cols.filter(col => col.id !== 'company');
    }
    return newCols;
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, origins, dataLength, companies} = this.props;
    console.log('this.props:', this.props)
    const {title, origin, isNew} = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const newProfilesCols = this.formatColsFromUser(originsCols);
    const data = {
      rows: origins,
      cols: newProfilesCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };
    if (isLoading === true) {
      return (
        <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {
          origin === null ?
            <EnhancedTable
              title={title}
              data={data}
              handlers={handlers}
              config={config}
            />
            :
            <OriginDetail
              origin={origin}
              handleGoBack={this.handleGoBack}
              handleCreateOrigin={this.props.createOrigin}
              handleUpdateOrigin={this.handleUpdateOrigin}
              isLoading={isLoading}
              isNew={isNew}
              companies={companies}
            />
        }
      </div>
    );
  }
}

Origins.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  origins: PropTypes.array.isRequired,
  filterOrigins: PropTypes.func.isRequired,
  getCompanies: PropTypes.func,
  createOrigin: PropTypes.func.isRequired,
  updateOrigin: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  error: PropTypes.object,
  companies: PropTypes.array
};

const mapStateToProps = (state) => {
  const {origins, companies} = state;
  return {
    isLoading: origins.sending,
    origins: origins.origins,
    dataLength: origins.dataLength,
    error: origins.error,
    message: origins.message,
    searchText: origins.searchText,
    order: origins.order,
    orderBy: origins.orderBy,
    page: origins.page,
    rowsPerPage: origins.rowsPerPage,
    filters: origins.filters,
    companies: companies.companies
  };
};

const mapDispatchToProps = {
  filterOrigins,
  createOrigin,
  updateOrigin,
  getProfile,
  getCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(Origins);
