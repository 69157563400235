export const GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS';

export const GET_PROFILES_SENDING = 'GET_PROFILES_SENDING';

export const GET_PROFILES_FAILED = 'GET_PROFILES_FAILED';

export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';

export const GET_PROFILE_SENDING = 'GET_PROFILE_SENDING';

export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';

export const GET_PERMISSIONS_SENDING = 'GET_PERMISSIONS_SENDING';

export const GET_PERMISSIONS_FAILED = 'GET_PERMISSIONS_FAILED';

export const FILTER_PROFILES_SUCCESS = 'FILTER_PROFILES_SUCCESS';

export const FILTER_PROFILES_SENDING = 'FILTER_PROFILE_SENDING';

export const FILTER_PROFILES_FAILED = 'FILTER_PROFILE_FAILED';

export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';

export const CREATE_PROFILE_SENDING = 'CREATE_PROFILE_SENDING';

export const CREATE_PROFILE_FAILED = 'CREATE_PROFILE_FAILED';

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

export const UPDATE_PROFILE_SENDING = 'UPDATE_PROFILE_SENDING';

export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';
