/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as ruleActions from './types/rules';
import store from '../store';

export const filterRules = (page, rowsPerPage, order, orderBy, searchText, filtersToApply) => async (dispatch) => {
  if (
    (searchText !== '' && store.getState().rules.searchText !== searchText) || 
    (Object.keys(filtersToApply).length && filtersToApply !== store.getState().rules.filters)
  ) {
    page = 0;
  }
  if (order !== store.getState().rules.order || orderBy !== store.getState().rules.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
    filtersToApply = {};
  }
  dispatch({
    type: ruleActions.FILTER_RULES_SENDING,
    payload: {
      rules: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
      filters: filtersToApply 
    }
  });
  try {
    const filters = filtersToApply;
    if (searchText !== '') {
      filters.name = searchText;
    }
    const data = {
      filters,
      operator: Object.keys(filtersToApply).length === 0 ? 'or' : 'and',
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order
      }
    };
    if (searchText !== '') {
      data.filters = {
        name: searchText
      };
    } 
    const response = await axios(axiosMaker('POST', 'rules/filter', data, true));
    return dispatch({
      type: ruleActions.FILTER_RULES_SUCCESS,
      payload: {
        rules: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: ruleActions.FILTER_RULES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getRules = () => async (dispatch) => {
  dispatch({
    type: ruleActions.GET_RULES_SENDING,
    payload: {
      rules: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {}
    };
    const response = await axios(axiosMaker('POST', 'rules/filter', data, true));
    return dispatch({
      type: ruleActions.GET_RULES_SUCCESS,
      payload: {
        rules: response.data.results,
        dataLength: response.data.results.length
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: ruleActions.GET_RULES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createRule = data => async (dispatch) => {
  dispatch({
    type: ruleActions.CREATE_RULE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'rules', data, true));
    return dispatch({
      type: ruleActions.CREATE_RULE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: ruleActions.CREATE_RULE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateRule = (id, rules) => async (dispatch) => {
  dispatch({
    type: ruleActions.UPDATE_RULE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `rules/${id}`, rules, true));
    return dispatch({
      type: ruleActions.UPDATE_RULE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: ruleActions.UPDATE_RULE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
