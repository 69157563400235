import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {styles} from './styles';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// const headRows = [    // eliminar cuando validemos la funcionalidad de trivo smart
//   {
//     id: 'id',
//     numeric: true,
//     label: 'id',
//   },
//   { id: 'name', numeric: false, label: 'Name' },
//   { id: 'value', numeric: false, label: 'Value' },
//   { id: 'detalle', numeric: false, label: 'Detalle' },
//   { id: 'observacion', numeric: false, label: 'observacion' },
// ];

const SimpleTableHead = (props) => {
  const { order, orderBy, rowCount, onRequestSort, headRows } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            style={{ backgroundColor: '#fff', position: 'sticky', top: 0 }}
            key={`cell-item-row-${row.id}-${index}`}
            align={'center'}
            padding={'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

SimpleTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default withStyles(styles)(SimpleTableHead);
