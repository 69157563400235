import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  IconButton,
  List,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  ListItemIcon,
  Checkbox,
  Button,
  DialogContent,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';

const OriginAssignment = (props) => {
  const { classes, origins, asesors, details, setDetails } = props;

  const [selectedAsesor, setSelectedAsesor] = useState();
  const [selectedOrigins, setSelectedOrigins] = useState([]);

  const handleSelectedOrigin = (origin) => {
    const currentIndex = selectedOrigins.indexOf(origin);
    const newChecked = [...selectedOrigins];
    if (currentIndex === -1) {
      newChecked.push(origin);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedOrigins(newChecked);
  };

  const addOriginToAsesor = () => {
    const { originAssignment } = details;

    const newOriginAssignment = [
      ...(originAssignment || []).filter(
        ({ asesor }) => asesor !== selectedAsesor,
      ),
      { asesor: selectedAsesor, origins: selectedOrigins },
    ];

    setDetails({ ...details, originAssignment: newOriginAssignment });
    handleCloseDialog();
  };

  const getOriginsCount = (asesorId) => {
    let count = 0;
    const { originAssignment } = details;

    const target = (originAssignment || []).find(
      ({ asesor }) => asesor === asesorId,
    );

    if (target) count = target.origins.length;

    return `${count} ${count === 1 ? 'orígen' : 'orígenes'}`;
  };

  const handleCloseDialog = () => {
    setSelectedAsesor(null);
    setSelectedOrigins([]);
  };

  const handleSelectedAsesor = (asesorId) => {
    const { originAssignment } = details;

    const target = (originAssignment || []).find(
      ({ asesor }) => asesor === asesorId,
    );

    setSelectedOrigins(target ? target.origins : []);
    setSelectedAsesor(asesorId);
  };

  return (
    <>
      <Toolbar>
        <Typography variant="subtitle1">
          <strong>Asesores</strong>
        </Typography>
      </Toolbar>
      <Grid container className={classes.root}>
        {asesors
          .filter(({ isJunior }) => isJunior === details.hasJuniorRol)
          .map((asesor) => (
            <Grid key={asesor.id} item xs={12} md={6} lg={4}>
              <Paper elevation={4} className={classes.paperRoot}>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      {asesor.image ? (
                        <Avatar alt={asesor.name} src={asesor.image} />
                      ) : (
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={asesor.name}
                      secondary={getOriginsCount(asesor.id)}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => handleSelectedAsesor(asesor.id)}
                      >
                        <Tooltip title="Gestionar orígenes" placement="top">
                          <AddIcon />
                        </Tooltip>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          ))}
      </Grid>
      <Dialog onClose={handleCloseDialog} open={!!selectedAsesor}>
        <DialogTitle>Selecciona los orígenes</DialogTitle>
        <DialogContent>
          <List className={classes.root}>
            {origins.map((origin) => {
              const labelId = `checkbox-list-label-${origin.id}`;
              return (
                <ListItem
                  key={origin.id}
                  dense
                  button
                  onClick={() => handleSelectedOrigin(origin.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedOrigins.indexOf(origin.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={origin.name} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>

        <div className={classes.footerContained}>
          <Button
            variant="contained"
            color="primary"
            onClick={addOriginToAsesor}
          >
            Finalizar
          </Button>
        </div>
      </Dialog>
    </>
  );
};

OriginAssignment.propTypes = {
  classes: PropTypes.object.isRequired,
  origins: PropTypes.array.isRequired,
  asesors: PropTypes.array.isRequired,
  details: PropTypes.object.isRequired,
  setDetails: PropTypes.func.isRequired,
};

export default withStyles(styles)(OriginAssignment);
