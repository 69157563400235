import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EnhancedTableRow from '../enhancedTableRow';
import {styles} from './styles';

const SortableItem = SortableElement(({row, index, cols, onDetail, rowIndex, hasEditPermission}) => {
  return (
    <EnhancedTableRow 
      key={index}
      isSortable
      isAddingNew={false}
      isSelectable={false}
      onDetail={onDetail}
      isSelected={false} 
      row={row}
      rowIndex={rowIndex}
      cols={cols}
      hasEditPermission={hasEditPermission}
    />
  );
});
const SortList = SortableContainer(({items, cols, onDetail, hasEditPermission}) => {
  if (items) {
    const list = items.map((row, index) => {
      return (
        <SortableItem key={index} index={index} row={row} hasEditPermission={hasEditPermission} cols={cols} onDetail={onDetail} rowIndex={index} />
      );
    });
    return (
      <TableBody>
        {list}
      </TableBody>
    );
  }
  return null;
});

export class EnhancedSortableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd({oldIndex, newIndex}) {
    this.props.onSortEnd({oldIndex, newIndex});
  } 

  render() {
    const {classes, cols, hasEditPermission} = this.props;
    const headCells = cols.map((element, index) => {
      return (
        <TableCell variant="head" key={index}>
          {element.label}
        </TableCell>
      );
    });
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headCells}
          </TableRow>
        </TableHead>
        <SortList 
          items={this.props.items}  
          cols={cols} 
          onSortEnd={this.onSortEnd}
          hasEditPermission={hasEditPermission}
          onDetail={this.props.onDetail}
          lockAxis="y"
          axis="y"
          pressDelay={200}
        />
      </Table>
    );
  }
}
EnhancedSortableTable.propTypes = {
  classes: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onDetail: PropTypes.func.isRequired
};

export default ((withStyles)(styles)(EnhancedSortableTable));
