import React from 'react';
import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { styles } from './styles';
import { formatFunnel } from './chartFunctions';
import Chart from '../../trivoSmartOffer/Chart';
import Highcharts from 'highcharts';
import Funnel3d from 'highcharts/modules/funnel';

Funnel3d(Highcharts);

const Pipeline = (props) => {
  const { classes, pipelineAnalysis, funnel = [] } = props;

  const renderChart = () => {
    return (
      <Chart
        options={{
          ...formatFunnel('Pipeline', funnel),
          credits: {
            enabled: false,
          },
        }}
        highcharts={Highcharts}
      />
    );
  };

  const pipelineanalysis = [
    {
      name: 'Leads sin contacto más de 30 días',
      count: (pipelineAnalysis && pipelineAnalysis.newLeadsWithoutAttentionFor30Days) || 0,
    },
    {
      name: 'Leads sin contacto más de 15 días',
      count: (pipelineAnalysis && pipelineAnalysis.newLeadsWithoutAttentionFor15Days) || 0,
    },
    {
      name: 'Leads en seguimiento dentro de etapas activas',
      count: (pipelineAnalysis && pipelineAnalysis.activeLeads) || 0,
    },
    {
      name: 'Leads dentro de etapas activas sin contacto enlos últimos 30 días',
      count: (pipelineAnalysis && pipelineAnalysis.activeLeadsWithoutAttentionFor30Days) || 0,
    },
    {
      name: 'Leads dentro de etapas activas sin contacto enlos últimos 15 días',
      count: (pipelineAnalysis && pipelineAnalysis.activeLeadsWithoutAttentionFor15Days) || 0,
    },
    {
      name: 'Promedio de días sin atención de leads nuevos',
      count: (pipelineAnalysis && pipelineAnalysis.daysWithoutAttention) || 0,
    },
  ];

  const renderPipelineList = () => {
    return (
      <List className={classes.root}>
        {pipelineanalysis.map((pipelinenItem, index) => {
          const labelId = `checkbox-list-label-${index}`;
          return (
            <ListItem className={classes.mainIitemList} key={index} role={undefined} dense>
              <ListItemText
                className={classes.itemList}
                id={labelId}
                primary={pipelinenItem.name}
              />
              <ListItemSecondaryAction className={classes.itemValue}>
                {pipelinenItem.count}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={24}>
        <Grid item md={12} xs={12}>
          <Paper className={`${classes.paper} ${classes.mainCard}`}>
            <h5>Así está tu pipeline</h5>
            <hr></hr>
            <div>{renderChart()}</div>
          </Paper>
        </Grid>
        <Grid item md={12} xs={12}>
          <Paper className={`${classes.paper} ${classes.cardAdjusted}`}>
            <h5 className={classes.titleCard}> Análisis de tu pipeline</h5>
            <hr></hr>
            <div>{renderPipelineList()}</div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Pipeline);
