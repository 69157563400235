const drawerWidth = 240;

export const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  zoneLabel: {
    padding: theme.spacing.unit * 2,
    fontSize: 18,
    fontWeight: 'bold',
    color: 'grey',

    '&:focus': {
      outline: 'none',
    },
  },
  zoneMenu: {
    paddingLeft: theme.spacing.unit * 5,
  },
  filterTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'grey',
  },
  switchText: {
    paddingLeft: 0,
    '& span': {
      fontSize: 16,
      fontWeight: 'bold',
      color: 'grey',
    },
  },
});

export default styles;
