import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { styles } from './styles';
import { InlineDatePicker } from 'material-ui-pickers';
import { countriesArray, IDENTIFICATION_CARD_ID } from '../../../utils/utils';
import verifyIdentification from '../../../utils/validations/validateIdentification';

const CustomerForm = (props) => {
  const {
    formValues,
    classes,
    handleInputChange,
    currentVersion,
    setDocumentInvalid,
  } = props;

  const {
    name,
    lastname,
    document,
    civilStatus,
    address,
    phone,
    email,
    date,
    sector,
    nationality,
    workSector,
    cellPhone,
  } = formValues;

  const [documentInvalid, setDocumentInvalidState] = useState(false);

  useEffect(() => {
    setDocumentInvalidState(
      document !== '' &&
        !verifyIdentification(document, IDENTIFICATION_CARD_ID),
    );
  }, [document]);

  useEffect(() => {
    setDocumentInvalid(documentInvalid);
  }, [documentInvalid]);

  return (
    <Paper className={classes.root}>
      <Typography
        variant="h5"
        color="primary"
        className={classes.headerProformas}
      >
        Datos Personales
      </Typography>
      <Grid container spacing={24}>
        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <TextField
            disabled={!!currentVersion}
            label="Nombre(s) del comprador"
            value={name}
            name="name"
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <TextField
            disabled={!!currentVersion}
            label="Apellido(s) del comprador"
            value={lastname}
            name="lastname"
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <TextField
            disabled={!!currentVersion}
            label="Correo electrónico"
            value={email}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <FormControl style={{ width: '100%' }}>
            <InlineDatePicker
              disabled={!!currentVersion}
              label="Fecha"
              variant="outlined"
              format="YYYY-MM-DD"
              value={date}
              onChange={(selected) =>
                handleInputChange({
                  target: {
                    name: 'date',
                    value: { ...selected },
                  },
                })
              }
              className={classes.pickerContainer}
              InputProps={{ className: classes.inputForm }}
            />
          </FormControl>
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <TextField
            disabled={!!currentVersion}
            label="Teléfono"
            value={phone}
            name="phone"
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <TextField
            disabled={!!currentVersion}
            label="Celular"
            value={cellPhone}
            name="cellPhone"
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <FormControl variant="outlined" className={classes.textFieldSelect}>
            <InputLabel
              classes={{ shrink: classes.inputShrink }}
              className={classes.labelForm}
              htmlFor="nationality"
            >
              Nacionalidad
            </InputLabel>
            <Select
              disabled={!!currentVersion}
              value={nationality}
              name="nationality"
              onChange={handleInputChange}
              input={
                <OutlinedInput
                  className={classes.selectInputForm}
                  labelWidth={0}
                  name="nationality"
                />
              }
            >
              {countriesArray.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <FormControl variant="outlined" className={classes.textFieldSelect}>
            <InputLabel
              classes={{ shrink: classes.inputShrink }}
              className={classes.labelForm}
              htmlFor="civilStatus"
            >
              Estado civil
            </InputLabel>
            <Select
              disabled={!!currentVersion}
              value={civilStatus}
              name="civilStatus"
              onChange={handleInputChange}
              input={
                <OutlinedInput
                  className={classes.selectInputForm}
                  labelWidth={0}
                  name="civilStatus"
                />
              }
            >
              <MenuItem value="">
                <em>Seleccione</em>
              </MenuItem>
              <MenuItem value="single">Soltero</MenuItem>
              <MenuItem value="free_union">Unión libre</MenuItem>
              <MenuItem value="married">Casado</MenuItem>
              <MenuItem value="separated">Separado</MenuItem>
              <MenuItem value="widower">Viudo</MenuItem>
              <MenuItem value="divorced">Divorciado</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <TextField
            disabled={!!currentVersion}
            label="Dirección"
            value={address}
            name="address"
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <TextField
            disabled={!!currentVersion}
            label="Sector laboral"
            value={workSector}
            name="workSector"
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <TextField
            disabled={!!currentVersion}
            label="Nro. cédula"
            value={document}
            name="document"
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
            error={documentInvalid}
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={3}>
          <TextField
            disabled={!!currentVersion}
            label="Sector"
            value={sector}
            name="sector"
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

CustomerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  lead: PropTypes.object,
  formValues: PropTypes.object,
  handleInputChange: PropTypes.func,
};

export default withStyles(styles)(CustomerForm);
