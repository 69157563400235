import * as propertyTypesActions from '../actions/types/propertyTypes';

const initialState = {
  propertyTypes: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {propertyTypes, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case propertyTypesActions.FILTER_PROPERTY_TYPES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case propertyTypesActions.FILTER_PROPERTY_TYPES_SUCCESS:
    return {...state, sending: false, propertyTypes, error: null, message: 'Tipos de propiedad obtenidos satisfactoriamente', dataLength};
  case propertyTypesActions.FILTER_PROPERTY_TYPES_FAILED:
    return {...state, sending: false, propertyTypes: [], error, message, dataLength};
  case propertyTypesActions.GET_PROPERTY_TYPES_SENDING:
    return {...state, sending: true};
  case propertyTypesActions.GET_PROPERTY_TYPES_SUCCESS:
    return {...state, sending: false, propertyTypes, error: null, message: 'Tipos de propiedad obtenidos satisfactoriamente', dataLength};
  case propertyTypesActions.GET_PROPERTY_TYPES_FAILED:
    return {...state, sending: false, propertyTypes: [], error, message, dataLength};
  case propertyTypesActions.CREATE_PROPERTY_TYPES_SENDING:
    return {...state, sending: true};
  case propertyTypesActions.CREATE_PROPERTY_TYPES_SUCCESS:
    return {...state, sending: false, error: null, message: 'Tipo de propiedad creado satisfactoriamente'};
  case propertyTypesActions.CREATE_PROPERTY_TYPES_FAILED:
    return {...state, sending: false, error, message};
  case propertyTypesActions.UPDATE_PROPERTY_TYPES_SENDING:
    return {...state, sending: true};
  case propertyTypesActions.UPDATE_PROPERTY_TYPES_SUCCESS:
    return {...state, sending: false, error: null, message: 'Tipo de propiedad actualizado satisfactoriamente'};
  case propertyTypesActions.UPDATE_PROPERTY_TYPES_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
