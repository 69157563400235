import axios from 'axios';
import { axiosMaker, apiHost } from '../utils/utils';

export const oauthGetCredentials = async (company) => {
  try {
    const result = await axios(
      axiosMaker('GET', `oauth/credentials/${company}`, null, true),
    );
    return result.data;
  } catch (e) {
    return null;
  }
};

export const generateToken = async (id, secret) => {
  await axios.post(
    `${apiHost}oauth/token`,
    `grant_type=client_credentials&client_id=${id}&client_secret=${secret}`,
  );
};

export const createClient = async (object) => {
  try {
    const { data } = await axios(
      axiosMaker('POST', 'oauth/createClient', object, true),
    );
    await generateToken(data.id, data.secret);
    return true;
  } catch (e) {
    return false;
  }
};

export const updateClient = async (object) => {
  try {
    await axios(axiosMaker('PUT', 'oauth/updateClient', object, true));
    return true;
  } catch (e) {
    return false;
  }
};

export const updateClientPassword = async (company) => {
  try {
    await axios(
      axiosMaker('PATCH', 'oauth/updateClientPassword', { company }, true),
    );
    return true;
  } catch (e) {
    return false;
  }
};

export const oauthDeletePage = async (company, pageId) => {
  return axios(
    axiosMaker('PATCH', `oauth/deletePage`, { company, pageId }, true),
  );
};
