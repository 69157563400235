export const GET_FORM_SUCCESS = 'GET_FORM_SUCCESS';

export const GET_FORM_SENDING = 'GET_FORM_SENDING';

export const GET_FORM_FAILED = 'GET_FORM_FAILED';

export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';

export const CREATE_FORM_SENDING = 'CREATE_FORM_SENDING';

export const CREATE_FORM_FAILED = 'CREATE_FORM_FAILED';

export const GET_GRAPHS_SUCCESS = 'GET_GRAPHS_SUCCESS';

export const GET_GRAPHS_SENDING = 'GET_GRAPHS_SENDING';

export const GET_GRAPHS_FAILED = 'GET_GRAPHS_FAILED';

export const GET_ANSWERS_SUCCESS = 'GET_ANSWERS_SUCCESS';

export const GET_ANSWERS_SENDING = 'GET_ANSWERS_SENDING';

export const GET_ANSWERS_FAILED = 'GET_ANSWERS_FAILED';

export const GET_SUPPLY_GRAPHS_SUCCESS = 'GET_SUPPLY_GRAPHS_SUCCESS';

export const GET_SUPPLY_GRAPHS_SENDING = 'GET_SUPPLY_GRAPHS_SENDING';

export const GET_SUPPLY_GRAPHS_FAILED = 'GET_SUPPLY_GRAPHS_FAILED';
