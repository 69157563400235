export const styles = (theme) => ({
  card: {
    margin: 20,
  },
  title: {
    flex: 1,
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '16px !important',
  },
  kpiCard: {
    display: 'flex',
    margin: 20,
  },
  kpiCardItem: {
    flex: 1,
  },
  chip: {
    margin: 3,
  },
});

export default styles;
