import * as projectsActions from '../actions/types/projects';

const initialState = {
  sending: false,
  message: '',
  projects: [],
  dataLength: 0,
  error: null,
  project: null,
  searchText: '',
  order: 'asc',
  orderBy: 'name',
  page: 0,
  rowsPerPage: 20,
  filters: {},
  images: [],
  projectUnits: []
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, images, projects, project, message, dataLength, searchText, order, orderBy, page, rowsPerPage, filters, projectUnits} = action.payload;
  switch (action.type) {
  case projectsActions.FILTER_PROJECTS_SENDING:
    return {...state, sending: true, response: false, projects: [], dataLength, searchText, order, orderBy, page, rowsPerPage, filters};
  case projectsActions.FILTER_PROJECTS_SUCCESS:
    return {...state, sending: false, response: true, message: 'Proyectos obtenidos satisfactoriamente', projects, dataLength, error: null};
  case projectsActions.FILTER_PROJECTS_FAILED:
    return {...state, sending: false, response: true, message, error};
  case projectsActions.GET_PROJECTS_SENDING:
    return {...state, sending: true, response: false, projects: [], dataLength};
  case projectsActions.GET_PROJECTS_SUCCESS:
    return {...state, sending: false, response: true, message: 'Proyectos obtenidos satisfactoriamente', projects, dataLength, error: null};
  case projectsActions.GET_PROJECTS_FAILED:
    return {...state, sending: false, response: true, message, error};
  case projectsActions.GET_PROJECT_SENDING:
    return {...state, sending: true, response: false, projects: []};
  case projectsActions.GET_PROJECT_SUCCESS:
    return {...state, sending: false, response: true, message: 'Proyecto obtenido satisfactoriamente', project, error: null};
  case projectsActions.GET_PROJECT_FAILED:
    return {...state, sending: false, response: true, message, error};
  case projectsActions.GET_PROJECT_IMAGES_SENDING:
    return {...state, sending: true, response: false};
  case projectsActions.GET_PROJECT_IMAGES_SUCCESS:
    return {...state, sending: false, response: true, message: 'Imágenes obtenidas satisfactoriamente', images, error: null};
  case projectsActions.GET_PROJECT_IMAGES_FAILED:
    return {...state, sending: false, response: true, message, error, images: []};
  case projectsActions.ADD_PROJECT_SENDING:
    return {...state, sending: true, response: false, message: '', error: null};
  case projectsActions.ADD_PROJECT_SUCCESS:
    return {...state, sending: false, response: true, message: 'Proyecto creado satisfactoriamente', error: null};
  case projectsActions.ADD_PROJECT_FAILED:
    return {...state, sending: false, response: true, message, error};
  case projectsActions.EDIT_PROJECT_SENDING:
    return {...state, sending: true, response: false, message: '', error: null};
  case projectsActions.EDIT_PROJECT_SUCCESS:
    return {...state, sending: false, response: true, message: 'Proyecto modificado satisfactoriamente', error: null};
  case projectsActions.EDIT_PROJECT_FAILED:
    return {...state, sending: false, response: true, message, error};
  case projectsActions.EDIT_PROJECT_ATTACHMENTS_SENDING:
    return {...state, sending: true, response: false, message: '', error: null};
  case projectsActions.EDIT_PROJECT_ATTACHMENTS_SUCCESS:
    return {...state, sending: false, response: true, message: 'Adjuntos modificados satisfactoriamente', error: null};
  case projectsActions.EDIT_PROJECT_ATTACHMENTS_FAILED:
    return {...state, sending: false, response: true, message, error};
  case projectsActions.CLONE_PROJECT_SENDING:
    return {...state, sending: true, message: '', error: null};
  case projectsActions.CLONE_PROJECT_FAILED:
    return {...state, sending: false, response: true, message, error};
  case projectsActions.GET_PROJECT_UNITS_SENDING:
    return {...state, sending: true, projectUnits: []};
  case projectsActions.GET_PROJECT_UNITS_SUCCESS:
    return {...state, sending: false, message: 'Proyecto con unidades obtenidos satisfactoriamente', projectUnits, error: null};
  case projectsActions.GET_PROJECT_UNITS_FAILED:
    return {...state, sending: false, message, error};
  default:
    return state;
  }
};
