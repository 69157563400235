export const styles = (theme) => ({
  card: {
    margin: 20,
  },
  cardAdjusted: {
    padding: 0,
    height:'100%'
  },
  root: {
    flexGrow: 1,
    margin: 20, 
  },
  title: {
    fontSize: 14,
    flex: 1,
  },
  mainCard: {
    padding: 20,
  },
  titleCard: {
    padding: '20px 20px 0px 20px',
  },
  itemList: {
    '& span': {
      fontSize: '1.1rem !important',
      "lineHeight": "1.8",
      fontWeight: 500,
    },
  },
  itemValue: {
    fontSize: '1.2rem',
    fontWeight: '500',
    color: 'white',
    padding: '2px 10px',
    borderRadius: '10px',
    background: theme.palette.primary.main,
  },
  mainIitemList: {
    paddingRight: '60px',
  },
});

export default styles;
