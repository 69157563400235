export const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 4,
    padding: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  rowTotal: {
    backgroundColor: theme.palette.primary.main,
    '&.error': {
      backgroundColor: theme.palette.error.main,
    },
    '& td': {
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
  table: {
    minWidth: 340,
    [theme.breakpoints.down('sm')]: {
      '& *': {
        fontSize: 12,
      },
    },
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
  headerProformas: {
    paddingBottom: theme.spacing.unit * 4,
    fontWeight: 'bold',
  },
  iconButton: {
    '&:hover, &:focus': {
      outline: 'none',
    },
  },
  inputForm: {
    '& input': {
      padding: '6px 14px',
    },
  },
  textField: {
    '& input': {
      padding: '6px 14px',
    },
  },
  pickerContainer: {
    marginTop: 16,
    marginBottom: 8,
  },
  textError: {
    color: theme.palette.error.main,
  },
  textFieldSelect: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '8px',
  },
  inputShrink: {
    backgroundColor: 'white',
  },
  labelForm: {
    transform: 'translate(14px, 6px) scale(1)',
  },
  selectInputForm: {
    '& div div': {
      padding: '6px 14px',
    },
  },
  gridItem: {
    paddingTop: 0
  }
});

export default styles;
