/* eslint-disable no-param-reassign */
import * as smartSupplyFilters from './types/smartSupplyFilters';
import store from '../store';

export const setFilters = (filters) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_FILTERS,
    payload: { filters },
  });
};

const getChipData = (chips) => {
  const sectorChips = chips.filter((chip) => chip.type === 'sector');
  const zoneChips = chips.filter((chip) => chip.type === 'zone');
  const cityChips = chips.filter((chip) => chip.type === 'city');
  const roomChips = chips.filter((chip) => chip.type === 'room');
  const unitTypeChips = chips.filter((chip) => chip.type === 'unitType');
  const projectChips = chips.filter((chip) => chip.type === 'project');
  const projectStatusChips = chips.filter((chip) => chip.type === 'status');

  return [
    ...sectorChips,
    ...zoneChips,
    ...cityChips,
    ...roomChips,
    ...unitTypeChips,
    ...projectChips,
    ...projectStatusChips,
  ].map((chip, index) => ({ ...chip, key: index }));
};

export const orderChips = (chips) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_CHIP_DATA,
    payload: {
      chipData: getChipData(chips),
    },
  });
};

export const setZones = (zones) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_ZONES,
    payload: { zones },
  });
};

export const setCities = (cities) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_CITIES,
    payload: { cities },
  });
};

export const setRooms = (rooms) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_ROOMS,
    payload: { rooms },
  });
};

export const setUnitTypes = (unitTypes) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_UNIT_TYPES,
    payload: { unitTypes },
  });
};

export const setProjectStatus = (status) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_PROJECT_STATUS,
    payload: { status },
  });
};

export const setSectors = (sectors) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_SECTORS,
    payload: { sectors },
  });
};

export const setProjects = (projects) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_PROJECTS,
    payload: { projects },
  });
};

export const changeFilters = () => (dispatch) => {
  const filtersObj = {};
  const {
    projects,
    sectors,
    zones,
    cities,
    unitTypes,
    rooms,
    status,
  } = store.getState().smartSupplyFilters;

  if (projects && projects.length > 0) filtersObj.projects = projects;
  if (sectors && sectors.length > 0) filtersObj.sectors = sectors;
  if (zones && zones.length > 0) filtersObj.zones = zones;
  if (cities && cities.length > 0) filtersObj.cities = cities;
  if (unitTypes && unitTypes.length > 0) filtersObj.types = unitTypes;
  if (rooms && rooms.length > 0) filtersObj.rooms = rooms;
  if (status && status.length > 0) filtersObj.status = status;

  dispatch({
    type: smartSupplyFilters.SET_CHANGE_FILTERS,
    payload: { filtersObj },
  });
};

export const setDisabledFilter = () => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_DISABLE_FILTER,
    payload: {},
  });
};

export const handleChangeProject = ({ target: { value } }) => (dispatch) => {
  dispatch({
    type: smartSupplyFilters.SET_PROJECTS,
    payload: { projects: value },
  });

  const { chipData } = store.getState().smartSupplyFilters;

  const chipsWithoutTypes = chipData.filter((chip) => chip.type !== 'project');

  const newTypes = value.map((item, index) => ({
    key: chipsWithoutTypes.length + index,
    type: 'project',
    label: item,
    id: item,
  }));

  dispatch({
    type: smartSupplyFilters.SET_CHIP_DATA,
    payload: {
      chipData: getChipData([...chipsWithoutTypes, ...newTypes]),
    },
  });
};
