import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Delete from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import Uploader from '../../../common/uploader';

export class AttachmentsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderButton = this.renderButton.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.validate = this.validate.bind(this);
  }

  validate() {
    const {name, type, url} = this.props;
    if (name !== '' && type !== '' && url !== '') {
      return false;
    }
    return true;
  }

  renderButton() {
    const {isLoading, classes, permissions} = this.props;
    if (!permissions) {
      return null;
    }
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText="¿Está seguro que desea guardar este adjunto?"
          buttonText="Guardar"
          variant="contained"
          isDisabled={this.validate()}
          confirmAction={this.props.onUpdateAttachment}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderFile() {
    const {url} = this.props;
    if (url !== '') {
      if (url.substring(url.length - 3) === 'png' || url.substring(url.length - 3) === 'jpg' || url.substring(url.length - 4) === 'jpeg') {
        return (
          <a href={url} rel="noopener noreferrer" target="_blank"><img src={url} alt="Foto de perfil" style={{width: 100, cursor: 'pointer'}} /></a>
        );
      }
      return (
        <Tooltip title="Descargar">
          <IconButton href={url} rel="noopener noreferrer" target="_blank">
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
                    
      );
    }
    return null;
  }

  render() {
    const {classes, isLoading, name, type, isNew, permissions} = this.props;
    return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <Tooltip title="Atras">
            <IconButton onClick={this.props.onGoBack}>
              <ArrowBackIosIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6">
                        Adjuntos
          </Typography>
          <div className={classes.spacer} />
          {
            (!isNew && permissions) &&
            <Tooltip title="Eliminar adjunto">
              <IconButton onClick={this.props.onDeleteAttachment}>
                <Delete />
              </IconButton>
            </Tooltip>
          }
        </Toolbar>
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Nombre</b></TableCell>
                <TableCell>
                  <TextField 
                    autoFocus
                    value={name}
                    onChange={this.props.onUpdateName}
                    fullWidth
                    disabled={permissions === false}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Tipo</b></TableCell>
                <TableCell>
                  <Select
                    fullWidth
                    value={type}
                    onChange={this.props.onUpdateType}
                    disabled={permissions === false}
                  >
                    <MenuItem value="featured_image">Imagen Destacada</MenuItem>
                    <MenuItem value="image">Imagen</MenuItem>
                    <MenuItem value="pdf">PDF</MenuItem>
                    <MenuItem value="other">Otro</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRowTitle}>
                <TableCell><b>Nuevo Adjunto</b></TableCell>
                <TableCell>
                  {this.renderFile()}
                  {
                    permissions && (
                      <Uploader 
                        buttonText="Subir adjunto" 
                        photo={false} 
                        dirName="Projects" 
                        urlUploaded={this.props.onUrlUploaded}
                      />
                    )
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>
          {this.renderButton()}
        </div>
      </Paper>
    );
  }
}

AttachmentsDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  permissions: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onDeleteAttachment: PropTypes.func.isRequired,
  onUpdateAttachment: PropTypes.func.isRequired,
  onUrlUploaded: PropTypes.func.isRequired,
  onUpdateName: PropTypes.func.isRequired,
  onUpdateType: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired
};
  
export default (withStyles(styles)(AttachmentsDetail));
