/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as standActions from './types/stands';
import store from '../store';
import CONSTANTS from '../utils/constants';

const company = JSON.parse(localStorage.getItem('company'));

export const filterStands = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().stands.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().stands.order || orderBy !== store.getState().stands.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: standActions.FILTER_STANDS_SENDING,
    payload: {
      stands: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && order !== null && orderBy !== null && searchText !== null) {
      filter = {
        filters: {
          name: searchText
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order
        }
      };
    }
    const response = await axios(axiosMaker('POST', 'stands/filter', filter, true));
    return dispatch({
      type: standActions.FILTER_STANDS_SUCCESS,
      payload: {
        stands: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: standActions.FILTER_STANDS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getStands = () => async (dispatch) => {
  dispatch({
    type: standActions.GET_STANDS_SENDING,
    payload: {
      stands: [],
      dataLength: 0
    }
  });
  try {
    if (company && company._id === CONSTANTS.TRIVO_COMPANY_ID) {
      const data = {
        sort: {
          name: 'asc'
        },
        filters: {active: true}
      };
      const response = await axios(axiosMaker('POST', 'stands/filter', data, true));
      return dispatch({
        type: standActions.GET_STANDS_SUCCESS,
        payload: {
          stands: response.data.results,
          dataLength: response.data.results.length
        }
      });
    }    
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: standActions.GET_STANDS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createStand = data => async (dispatch) => {
  dispatch({
    type: standActions.CREATE_STAND_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'stands', data, true));
    return dispatch({
      type: standActions.CREATE_STAND_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: standActions.CREATE_STAND_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateStand = (id, STAND) => async (dispatch) => {
  dispatch({
    type: standActions.UPDATE_STAND_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `stands/${id}`, STAND, true));
    return dispatch({
      type: standActions.UPDATE_STAND_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: standActions.UPDATE_STAND_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
