import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
/* import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; */
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Select, MenuItem} from '@material-ui/core';
import EmailEditor from 'react-email-editor';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {SUPER_ADMIN_ROL_ID} from '../../../../utils/utils';


export class EmailTemplatesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      name: '',
      subject: '',
      emailTemplate: null,
      template: '',
      smartEditor: false,
      isDefault: false,
      company: ''
    };
    this.renderButton = this.renderButton.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.onLoadHTML = this.onLoadHTML.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleHTMLTemplateChange = this.handleHTMLTemplateChange.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
  }

  componentWillMount() {
    const {isNew, emailTemplate} = this.props;

    const profileId = localStorage.getItem('profileId');  
    let {company} = emailTemplate;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      const companyStored = JSON.parse(localStorage.getItem('company'));  
      company = companyStored ? companyStored._id : '';  
      this.setState({company});
    }    

    if (isNew === false) {
      this.setState({
        emailTemplate,
        name: emailTemplate.name,
        template: emailTemplate.template,
        subject: emailTemplate.subject,
        isDefault: emailTemplate.isDefault,
        active: emailTemplate.active,
        company
      });
    }
  }

  onAddButtonClick() {
    const {name, subject, smartEditor, isDefault, company} = this.state;
    if (smartEditor === true) {
      this.editor.exportHtml((data) => {
        const {html} = data;
        const template = html;
        this.setState({template});
        this.props.handleCreateEmailTemplate({template, name, subject, isDefault, company});
      });
    } else {
      this.props.handleCreateEmailTemplate({template: this.state.template, name, subject, isDefault, company});
    }
  }

  onEditButtonClick() {
    const {emailTemplate, active, name, subject, smartEditor, isDefault, company} = this.state;
    if (smartEditor === true) {
      this.editor.exportHtml((data) => {
        const {html} = data;
        const template = html;
        const emailTemplateEdited = {
          id: emailTemplate.id,
          active,
          template,
          subject,
          name,
          isDefault,
          company
        };
        this.setState({template});
        this.props.handleUpdateEmailTemplate(emailTemplateEdited);
      });
    } else {
      const emailTemplateEdited = {
        id: emailTemplate.id,
        active,
        template: this.state.template,
        subject,
        name,
        isDefault,
        company
      };
      this.props.handleUpdateEmailTemplate(emailTemplateEdited);
    }
  }

  onLoadHTML() {
    const {template} = this.state;
    if (template !== '') {
      const json = {
        counters: {
          u_column: 1,
          u_row: 1,
          u_content_html: 1
        },
        body: {
          rows: [
            {
              cells: [1],
              columns: [
                {
                  contents: [
                    {
                      type: 'html',
                      values: {
                        containerPadding: '10px',
                        _meta: {
                          htmlID: 'u_content_html_1',
                          htmlClassNames: 'u_content_html'
                        },
                        selectable: true,
                        draggable: true,
                        deletable: true,
                        html: template
                      }
                    }
                  ],
                  values: {
                    backgroundColor: '',
                    padding: '0px',
                    border: {},
                    _meta: {
                      htmlID: 'u_column_1',
                      htmlClassNames: 'u_column'
                    }
                  }
                }
              ],
              values: {
                columns: false,
                backgroundColor: '',
                columnsBackgroundColor: '',
                backgroundImage: {
                  url: '',
                  fullWidth: true,
                  repeat: false,
                  center: true,
                  cover: false
                },
                padding: '0px',
                hideMobile: false,
                noStackMobile: false,
                _meta: {
                  htmlID: 'u_row_1', 
                  htmlClassNames: 'u_row'
                }, 
                selectable: true,
                draggable: true,
                deletable: true
              }
            }
          ], 
          values: {
            backgroundColor: '#e7e7e7', 
            backgroundImage: {
              url: '', 
              fullWidth: true, 
              repeat: false, 
              center: true, 
              cover: false
            }, 
            contentWidth: '100%', 
            fontFamily: {
              label: 'Arial', 
              value: 'arial,helvetica,sans-serif'
            }, 
            _meta: {
              htmlID: 'u_body', 
              htmlClassNames: 'u_body'
            }
          }
        }
      };
      if (this.editor) {
        this.editor.loadDesign(json);
      } else {
        console.log('no editor found');
        setTimeout(() => {
          this.editor.loadDesign(json);
        }, 500);
      }
    }
  }  

  getEmailDefaultPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    return profile && profile.permissions.update_default_email_templates;
  }
  
  handleHTMLTemplateChange(e) {
    const template = e.target.value;
    this.setState({template});
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  handleChangeCompany(e) {
    const company = e.target.value;
    this.setState({company});
  }

  isInvalid() {
    const {subject, name} = this.state;
    if (subject !== '' || name !== '') {
      return false;
    }
    return true;
  }

  renderButton() {
    const {isLoading, isNew, classes} = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} esta plantilla de correo?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} plantilla de correo`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderLoading() {
    const {isLoading} = this.props;
    if (
      isLoading === true
    ) {
      return (
        <div style={{zIndex: 100, position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)'}}>
          <CircularProgress 
            style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} 
          />
        </div>
      );
    }
    return null;
  }

  renderCompanies() {
    const {companies} = this.props;
    return companies.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
      );
    });
  }  

  renderRowCompany(classes) {
    const profileId = localStorage.getItem('profileId');  
    return (
      SUPER_ADMIN_ROL_ID === profileId &&
      <TableRow>
        <TableCell className={classes.tableRowTitle}><b>Empresa</b></TableCell>
        <TableCell>
          <Select
            value={this.state.company}
            fullWidth
            onChange={this.handleChangeCompany}
          >
            {this.renderCompanies()}
          </Select>
        </TableCell>
      </TableRow>
    );
  }  
    
  render() {
    const {classes, isNew} = this.props;
    return (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de ' : 'Nueva '} plantilla de email
          </Typography>
        </Toolbar>
        {this.renderLoading()}
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              {
                !isNew &&
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Disponible</b></TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.active}
                          onChange={e => this.setState({active: e.target.checked})}
                          color="primary"
                        />
                      }
                      label={this.state.active === true ? 'Disponible' : 'No disponible'}
                    />
                  </TableCell>
                </TableRow>
              }
              {
                !isNew && this.getEmailDefaultPermission() &&
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Plantilla genérica</b></TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.isDefault}
                          onChange={e => this.setState({isDefault: e.target.checked})}
                          color="primary"
                        />
                      }
                      label={this.state.isDefault === true ? 'Habilitada' : 'No habilitada'}
                    />
                  </TableCell>
                </TableRow>
              }
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Título</b></TableCell>
                <TableCell>
                  <TextField 
                    autoFocus
                    value={this.state.name}
                    onChange={e => this.setState({name: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Asunto</b></TableCell>
                <TableCell>
                  <TextField 
                    value={this.state.subject}
                    onChange={e => this.setState({subject: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              {this.renderRowCompany(classes)}
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Smart Editor</b></TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.smartEditor}
                        onChange={e => this.setState({smartEditor: e.target.checked})}
                        color="primary"
                      />
                    }
                    label={this.state.smartEditor === true ? 'Activado' : 'No activado'}
                  />
                </TableCell>
              </TableRow>
              {
                this.state.smartEditor === true && 
                  <TableRow>
                    <TableCell colSpan={2} style={{textAlign: 'center'}}><b>Plantilla</b></TableCell>
                  </TableRow>
              }
              {
                this.state.smartEditor === true &&
                  <TableRow>
                    <TableCell colSpan={2}>
                      <EmailEditor
                        // eslint-disable-next-line no-return-assign
                        ref={editor => this.editor = editor}
                        onLoad={this.onLoadHTML}
                      />
                    </TableCell>
                  </TableRow>
              }
              {
                this.state.smartEditor === false && 
                  <TableRow>
                    <TableCell colSpan={2} style={{textAlign: 'center'}}><b>Código HTML</b></TableCell>
                  </TableRow>
              }
              {
                this.state.smartEditor === false && 
                  <TableRow>
                    <TableCell colSpan={2}>
                      <TextField 
                        value={this.state.template}
                        multiline
                        fullWidth
                        onChange={this.handleHTMLTemplateChange}
                        rows={30}
                        style={{fontFamily: '"courier" !important'}}
                      />
                    </TableCell>
                  </TableRow>
              }
              {
                this.state.smartEditor === false && 
                  <TableRow>
                    <TableCell colSpan={2} style={{textAlign: 'center'}}><b>Preview</b></TableCell>
                  </TableRow>
              }
              {
                this.state.smartEditor === false && 
                  <TableRow>
                    <TableCell colSpan={2}>
                      {renderHTML(this.state.template)}
                    </TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>
          {this.renderButton()}
        </div>
      </Paper>
    );
  }
}

EmailTemplatesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  emailTemplate: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleCreateEmailTemplate: PropTypes.func.isRequired,
  handleUpdateEmailTemplate: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired
};
  
export default (withStyles(styles)(EmailTemplatesDetails));
