/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { InlineDatePicker, TimePicker } from 'material-ui-pickers';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { styles } from './styles';
import SalesProfile from '../../../saleProfile';
import {
  dateFormat,
  dateRegion,
  currentDate,
  isInArray,
  ZONE,
  LEAD_STATE_NUEVO,
  LEAD_STATE_SEGUIMIENTO,
  LEAD_STATE_PERDIDO,
  LEAD_STATE_GANADO,
  getUrlExtension,
  isNumber,
  sortObjectByProperty,
} from '../../../../utils/utils';
import ConfirmationDialog from '../../../common/confirmationDialog';
import Uploader from '../../../common/uploader';
import { getEmailTemplates } from '../../../../actions/emailTemplates';
import { getEventsStatistics } from '../../../../actions/calendar';
import { getWhatsappTemplates } from '../../../../actions/whatsappTemplates';
import Autocomplete from '../../../common/enhancedTable/enhancedTableRow/enhancedTableCell/autocomplete';
import AppointmentModal from '../../../../components/common/appointmentModal';
import CalendarIcon from '@material-ui/icons/Event';
import EventList from '../../../common/eventList';

const companyObject = JSON.parse(localStorage.getItem('company'));

export class NextActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: currentDate(),
      dateManual: '',
      timeManual: '',
      manual: '',
      attachment: '',
      nextState: '',
      nextStage: '',
      brokers: '',
      temperature: 'cold',
      prequalification: '',
      estimatedClosingTime: '+90',
      closingPrice: '',
      emailTemplate: '',
      whatsappTemplate: '',
      project: '',
      unit: '',
      broker: '',
      renderSalesProfiling: false,
      openAppointmentModal: false,
      openFinishAppointmentModal: false,
      isEvent: false,
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.renderStatesOptions = this.renderStatesOptions.bind(this);
    this.renderStagesOptions = this.renderStagesOptions.bind(this);
    this.renderBrokersOptions = this.renderBrokersOptions.bind(this);
    this.renderEmailTemplatesOptions = this.renderEmailTemplatesOptions.bind(this);
    this.renderClosingPrice = this.renderClosingPrice.bind(this);
    this.renderPotentialTime = this.renderPotentialTime.bind(this);
    this.renderProjectsOptions = this.renderProjectsOptions.bind(this);
    this.renderUnitsOptions = this.renderUnitsOptions.bind(this);
    this.renderProjectBrokersOptions = this.renderProjectBrokersOptions.bind(this);
    this.renderTemperature = this.renderTemperature.bind(this);
    this.renderWhatsappTemplatesOptions = this.renderWhatsappTemplatesOptions.bind(this);
    this.handleAddAction = this.handleAddAction.bind(this);
    this.getEmailTemplateById = this.getEmailTemplateById.bind(this);
    this.getWhatsappTemplateById = this.getWhatsappTemplateById.bind(this);
    this.handleChangeProject = this.handleChangeProject.bind(this);
    this.handleChangeBroker = this.handleChangeBroker.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
    this.handleDateManualChange = this.handleDateManualChange.bind(this);
    this.handleTimeManualChange = this.handleTimeManualChange.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.handleReasignLead = this.handleReasignLead.bind(this);
    this.handleSendWhatsapp = this.handleSendWhatsapp.bind(this);
    this.handleChangeTemperature = this.handleChangeTemperature.bind(this);
    this.handleChangePrequalification = this.handleChangePrequalification.bind(this);
    this.handleValidateChangeStatusStageWonUnit = this.handleValidateChangeStatusStageWonUnit.bind(
      this,
    );
    this.handleChangeEstimatedClosingTime = this.handleChangeEstimatedClosingTime.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleUpdateClosingPrice = this.handleUpdateClosingPrice.bind(this);
    this.handleNextActions = this.handleNextActions.bind(this);
    this.getZoneNameById = this.getZoneNameById.bind(this);
    this.getZoneByUserId = this.getZoneByUserId.bind(this);
    this.renderSalesProfile = this.renderSalesProfile.bind(this);
    this.handleGoBackSalesProfile = this.handleGoBackSalesProfile.bind(this);
    this.fetchEventsStatistics = this.fetchEventsStatistics.bind(this);
  }

  fetchEventsStatistics() {
    const broker = localStorage.getItem('user');

    return this.props.getEventsStatistics(this.props.lead._id, {
      leads: [this.props.lead._id],
      brokers: [broker],
    });
  }

  componentWillMount() {
    this.props.getEmailTemplates();
    this.props.getWhatsappTemplates();
    const { lead } = this.props;
    const { status, stage, temperature, prequalification } = lead;
    this.setState({
      temperature: temperature || 'cold',
      prequalification: prequalification || '',
      estimatedClosingTime: lead.estimated_closing_time || '+90',
      closingPrice: lead.closing_price || '',
      nextState: status ? (status._id ? status._id : status.id ? status.id : '') : '',
      nextStage: stage || '',
      isProfiled: lead.isProfiled,
    });
    if (lead.matches.length > 0) {
      if (lead.unitSold && lead.projectSold) {
        const project = lead.matches.filter((obj) => obj._id === lead.projectSold)[0];
        this.setState({ project, unit: lead.unitSold });
      } else {
        const project = '';
        const unit = '';
        this.setState({ project, unit });
      }
    }
    if (lead.brokers.length > 0) {
      this.setState({ broker: lead.brokers[0] });
    }
  }

  renderSalesProfile() {
    console.log('cambio de estado');
    this.setState({ renderSalesProfiling: true });
  }

  getZoneNameById(zonesArr) {
    if (zonesArr) {
      const { zones } = this.props;
      const zonesNames = [];
      if (zones.length > 0) {
        zonesArr.forEach((item) => {
          zones.forEach((element) => {
            if (element.id === item) {
              zonesNames.push(element.name);
            }
          });
        });
      }
      return zonesNames;
    }
    return null;
  }

  getZoneByUserId(userId) {
    const { users, zones } = this.props;
    // eslint-disable-next-line no-shadow
    let zone = '';
    if (zones.length > 0) {
      const user = users.filter((obj) => userId === obj.id)[0];
      if (user) {
        const zonesToAssign = user.zones.filter((obj) => obj !== '')[0];
        if (zonesToAssign) {
          zone = zonesToAssign;
        }
      }
    }
    return zone;
  }

  getEmailTemplateById(templateId) {
    const { emailTemplates } = this.props;
    let emailTemplate = '';
    if (emailTemplates.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      emailTemplate = emailTemplates.filter((obj) => templateId === obj.id)[0].template;
    }
    return emailTemplate;
  }

  getWhatsappTemplateById(id) {
    const { whatsappTemplates } = this.props;
    let template = '';
    if (whatsappTemplates.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      template = whatsappTemplates.filter((obj) => id === obj.id)[0].template;
    }
    return <div style={{ margin: 10 }}>{template}</div>;
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  handleGoBackSalesProfile() {
    this.setState({ renderSalesProfiling: false, isProfiled: true });
  }

  handleUrlUploaded(attachment) {
    this.setState({ attachment: attachment === false ? '' : attachment });
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  handleDateManualChange(dateManual) {
    this.setState({ dateManual });
  }

  handleTimeManualChange(timeManual) {
    this.setState({ timeManual });
  }

  handleChangeProject(e) {
    const { lead } = this.props;
    if (lead.matches && lead.matches.length > 0) {
      const leadMatches = lead.matches;
      leadMatches.forEach((match) => {
        if (match._id === e.target.value) {
          const project = match;
          const unit = project.units[0]._id || '';
          this.setState({ project, unit });
        }
      });
    }
  }

  handleChangeBroker(e) {
    const { lead } = this.props;
    if (lead.brokers && lead.brokers.length > 0) {
      const { brokers } = lead;
      brokers.forEach((broker) => {
        if (broker.userId._id === e.target.value) {
          this.setState({ broker });
        }
      });
    }
  }

  handleAddAction() {
    const { lead } = this.props;
    const { manual, dateManual, timeManual } = this.state;
    if (manual !== '') {
      if (dateManual !== '' && timeManual !== '') {
        const dateString = moment(dateManual).format(dateFormat);
        const timeString = moment(timeManual).format('HH:mm');
        const deadlineDate = moment.tz(moment(`${dateString} ${timeString}`), dateRegion);
        const deadline = deadlineDate.format();
        this.props.onAddNewAction(lead._id, manual, deadline);
      } else {
        alert('Para crear tarea se debe ingresar fecha y hora');
      }
    }
  }

  handleChangeTemperature() {
    const { temperature } = this.state;
    const { lead } = this.props;

    if (temperature !== lead.temperature) {
      this.props.onUpdateTemperature(lead._id, temperature);
    }
  }

  handleChangePrequalification() {
    const { prequalification } = this.state;
    const { lead } = this.props;

    if (prequalification !== lead.prequalification) {
      this.props.onUpdatePrequalification(lead._id, prequalification);
    }
  }

  handleChangeEstimatedClosingTime() {
    const { estimatedClosingTime } = this.state;
    const { lead } = this.props;
    if (estimatedClosingTime !== lead.estimated_closing_time) {
      this.props.onUpdateEstimatedClosingTime(lead._id, estimatedClosingTime);
    }
  }

  handleUpdateClosingPrice() {
    const { closingPrice } = this.state;
    const { lead } = this.props;
    if (closingPrice) {
      if (closingPrice !== '' && lead.closing_price !== closingPrice) {
        this.props.onUpdateClosingPrice(lead._id, closingPrice);
      } else {
        alert(
          'No se puede cambiar el valor cotizado porque no existen unidades asignadas a este lead',
        );
      }
    }
  }

  async handleUpdateStatus() {
    const { lead, leadStates } = this.props;
    const { nextState, nextStage, project, unit, closingPrice, broker } = this.state;
    if (nextState !== '' || nextStage !== '') {
      const nextStateObject = leadStates.filter((obj) => obj.id === nextState)[0];
      const oldStatusIsWon = lead.status._id !== LEAD_STATE_GANADO;
      if (
        lead.status.name !== nextStateObject.name ||
        lead.stage !== nextStage ||
        closingPrice !== lead.closing_price
      ) {
        const beneficiary = {
          referrer: '',
          broker: '',
        };
        const builder = project ? project.builder || '' : '';
        if (nextState === LEAD_STATE_GANADO || lead.status === LEAD_STATE_GANADO) {
          if (lead.referrer !== '') {
            beneficiary.referrer = lead.referrer;
          }
          if (broker !== '') {
            beneficiary.broker = broker.userId._id;
          }
        }
        this.props.onUpdateLeadState(
          lead._id,
          nextStateObject,
          nextStage,
          oldStatusIsWon,
          project,
          unit,
          beneficiary,
          builder,
          closingPrice,
        );
      }
    }
  }

  handleValidateChangeStatusStageWonUnit() {
    const { lead } = this.props;
    const { nextState, nextStage, project, unit, closingPrice } = this.state;
    if (nextState === LEAD_STATE_GANADO || lead.status === LEAD_STATE_GANADO) {
      if (project) {
        if (project !== '' && unit !== '' && closingPrice !== '') {
          if (nextStage !== '' && lead.stage !== nextStage) {
            return false;
          } else if (
            project._id !== lead.projectSold ||
            unit !== lead.unitSold ||
            closingPrice !== lead.closing_price
          ) {
            return false;
          }
        }
      }
    } else if (nextState !== '' && lead.status._id !== nextState) {
      return false;
    } else if (nextStage !== '' && lead.stage !== nextStage) {
      return false;
    }
    return true;
  }

  handleReasignLead() {
    const { lead } = this.props;
    const { brokers } = this.state;
    if (brokers !== '') {
      this.props.onReassignLead(
        lead._id,
        localStorage.getItem('user'),
        brokers,
        this.getZoneByUserId(brokers),
      );
    }
  }

  handleSendWhatsapp() {
    const { whatsappTemplates, lead } = this.props;
    const { whatsappTemplate } = this.state;
    if (whatsappTemplate !== '' && whatsappTemplates) {
      const template = whatsappTemplates.filter((obj) => whatsappTemplate === obj.id)[0];
      // eslint-disable-next-line prefer-destructuring
      let phone = lead.user.phone;
      console.log("phone", phone);
  
      if (phone && template) {
        const userName = lead.user.name;
        const message = encodeURIComponent(template.template.replace('{{name}}', userName).trim());
        if (phone.length === 10 && phone[0] === '0') {
          phone = phone.substring(1);
        }
        console.log("phone subs", phone);
        phone = phone.indexOf('+') >= 0 ? phone.replace('+', '') : `521${phone}`;
        console.log("phone replaced", phone);
        const url = `https://api.whatsapp.com/send?l=es&phone=${phone}&text=${message}`;

        this.props.handleAddObservation({
          newObservation: `Mensaje: ${template.template.replace('{{name}}', userName).trim()}`,
          newType: 'whatsapp',
        });
        window.open(url, '_blank');
      }
    }
  }

  handleSendEmail() {
    const { lead, emailTemplates } = this.props;
    const { emailTemplate, attachment } = this.state;
    if (emailTemplate !== '') {
      const replaceWith = {
        texto_nombre: lead.user.name,
      };
      const to = lead.user.email;
      const fileType = getUrlExtension(attachment);
      const from = localStorage.getItem('userEmail') || '';
      this.props.onSendLeadEmail(
        lead._id,
        emailTemplate,
        to,
        attachment,
        fileType,
        replaceWith,
        from,
      );

      this.props.handleAddObservation({
        newObservation: `Asunto: ${emailTemplates.find(({ id }) => id === emailTemplate).subject}`,
        newType: 'email',
      });
    }
  }

  handleNextActions() {
    const {
      manual,
      dateManual,
      timeManual,
      temperature,
      estimatedClosingTime,
      closingPrice,
      whatsappTemplate,
      emailTemplate,
      brokers,
      prequalification
    } = this.state;
    const { lead } = this.props;
    if (manual !== '' && dateManual !== '' && timeManual !== '') {
      console.log('Agregando acción manual');
      this.handleAddAction();
    }
    if (estimatedClosingTime !== lead.estimated_closing_time && estimatedClosingTime !== '') {
      console.log('Cambiando el tiempo de cierre');
      this.handleChangeEstimatedClosingTime();
    }
    if (temperature !== '' && temperature !== lead.temperature) {
      console.log('Cambiando la temperatura del lead');
      this.handleChangeTemperature();
    }
    if (prequalification !== '' && prequalification !== lead.prequalification) {
      console.log('Cambiando la precalificación del lead');
      this.handleChangePrequalification();
    }
    if (closingPrice !== lead.closing_price && closingPrice !== '') {
      console.log('Cambiando el valor cotizado');
      this.handleUpdateClosingPrice();
    }
    if (whatsappTemplate !== '') {
      console.log('Enviando whatsapp');
      this.handleSendWhatsapp();
    }
    if (emailTemplate !== '') {
      console.log('Enviando correo electrónico');
      this.handleSendEmail();
    }
    if (brokers !== '') {
      console.log('Reasignando lead');
      this.handleReasignLead();
    }
    const invalidChangeStatus = this.handleValidateChangeStatusStageWonUnit();
    if (!invalidChangeStatus) {
      console.log('Cambiando estado del lead');
      this.handleUpdateStatus();
    }
  }

  renderUpdateStatusMessage() {
    let confirmationText = '¿Está seguro que desea cambiar estado y etapa del lead?';
    const oldStatus = this.props.lead.status._id;
    const newStatus = this.state.nextState;
    const unitSold = this.state.unit;
    if (oldStatus === LEAD_STATE_GANADO && newStatus !== LEAD_STATE_GANADO) {
      confirmationText =
        'Este lead estaba como estado Ganado, si cambia de estado, la unidad asignada como ganada volverá a estar activa. ¿Está seguro que desea ejecutar estas acciones?';
    } else if (
      oldStatus !== LEAD_STATE_GANADO &&
      newStatus === LEAD_STATE_GANADO &&
      unitSold !== ''
    ) {
      confirmationText =
        'Al poner este lead como ganado la unidad asignada se desactivará. ¿Está seguro que desea ejecutar estas acciones?';
    }
    return confirmationText;
  }

  renderEmailTemplatesOptions() {
    const { emailTemplates } = this.props;
    if (emailTemplates.length > 0) {
      return emailTemplates
        .filter((obj) => obj.active)
        .map((item, index) => {
          return (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          );
        });
    }
    return null;
  }

  renderWhatsappTemplatesOptions() {
    const { whatsappTemplates } = this.props;
    if (whatsappTemplates.length > 0) {
      return whatsappTemplates
        .filter((obj) => obj.active)
        .map((item, index) => {
          return (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          );
        });
    }
    return null;
  }

  renderStatesOptions() {
    const { leadStates } = this.props;
    if (leadStates.length > 0) {
      return leadStates.map((item, index) => {
        return (
          <MenuItem key={index} value={item.id}>
            {item.name}
          </MenuItem>
        );
      });
    }
    return null;
  }

  renderBrokersOptions() {
    const { users, lead } = this.props;
    if (lead.preferences) {
      const leadZones = lead.preferences
        .filter((obj) => obj.attributeId === ZONE)
        .map((obj) => obj.answerValues)[0];
      if (leadZones) {
        const possibleBrokers = users
          .filter((obj) => obj.is_asesor === true)
          .map((item) => {
            // eslint-disable-next-line no-param-reassign
            item.zonesNames = this.getZoneNameById(item.zones);
            return item;
          });
        if (possibleBrokers) {
          const brokers = possibleBrokers.filter((obj) => isInArray(leadZones, obj.zonesNames));
          if (brokers.length > 0) {
            return brokers.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            });
          }
        }
      }
    }
    return [];
  }

  renderTemperature() {
    const { classes } = this.props;
    const { nextState } = this.state;
    if (nextState === LEAD_STATE_SEGUIMIENTO) {
      return (
        <div className={classes.divRootCustom}>
          <hr />
          <Toolbar>
            <Typography variant="subtitle2">Cambiar temperatura del lead</Typography>
          </Toolbar>
          <div className={classes.centerContentContainer}>
            <FormControl className={classes.picker}>
              <Select
                value={this.state.temperature}
                onChange={(e) => this.setState({ temperature: e.target.value })}
                name="Temperatura del lead"
                displayEmpty
                className={classes.selectEmpty}
              >
                {this.renderTemperatures()}
              </Select>
              <FormHelperText>Temperatura del lead</FormHelperText>
            </FormControl>
          </div>
        </div>
      );
    }
    return null;
  }

  renderPrequalification() {
    const { classes } = this.props;
    const { nextState } = this.state;
    if (nextState === LEAD_STATE_SEGUIMIENTO) {
      return (
        <div className={classes.divRootCustom}>
          <hr />
          <Toolbar>
            <Typography variant="subtitle2">Cambiar precalificación</Typography>
          </Toolbar>
          <div className={classes.centerContentContainer}>
            <FormControl className={classes.picker}>
              <Select
                value={this.state.prequalification}
                onChange={(e) => this.setState({ prequalification: e.target.value })}
                name="Precalificación del lead"
                displayEmpty
                className={classes.selectEmpty}
              >
                {this.renderQualifications()}
              </Select>
              <FormHelperText>Precalificación del lead</FormHelperText>
            </FormControl>
          </div>
        </div>
      );
    }
    return null;
  }

  renderPotentialTime() {
    const { classes } = this.props;
    const { nextState, estimatedClosingTime } = this.state;
    if (nextState !== LEAD_STATE_NUEVO && nextState !== LEAD_STATE_PERDIDO) {
      return (
        <div className={classes.divRootCustom}>
          <hr />
          <Toolbar>
            <Typography variant="subtitle2">Cambiar tiempo potencial de cierre</Typography>
          </Toolbar>
          <div className={classes.centerContentContainer}>
            <FormControl className={classes.picker}>
              <Select
                value={estimatedClosingTime}
                onChange={(e) => this.setState({ estimatedClosingTime: e.target.value })}
                name="Tiempo potencial de cierre"
                displayEmpty
                className={classes.selectEmpty}
              >
                {this.renderPotentialTimes()}
              </Select>
              <FormHelperText>Tiempo potencial de cierre (días)</FormHelperText>
            </FormControl>
          </div>
        </div>
      );
    }
    return null;
  }

  renderClosingPriceWon() {
    const { classes, lead } = this.props;
    const { closingPrice, renderSalesProfiling, unit, project, isProfiled } = this.state;
    const unitData = {};
    if (renderSalesProfiling && unit !== '') {
      unitData.id = unit;
      unitData.projectId = project._id;
    }
    return (
      <div>
        <Toolbar>
          <Typography variant="subtitle2">Valor de Venta</Typography>
        </Toolbar>
        <div>
          <FormControl className={classes.picker}>
            <TextField
              value={closingPrice}
              placeholder="Valor de Venta"
              onChange={(e) =>
                isNumber(e.target.value) && this.setState({ closingPrice: e.target.value })
              }
            />
            <FormHelperText>Valor de Venta</FormHelperText>
          </FormControl>
          <div>
            <FormControl className={classes.picker}>
              {console.log(unit !== '' && project !== '')}
              <Button
                size="small"
                disabled={(unit === '' && project === '') || isProfiled || closingPrice === ''}
                style={{ minWidth: '96px', fontSize: '10px' }}
                variant="contained"
                color="primary"
                onClick={this.renderSalesProfile}
              >
                Perfilar Venta
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    );
  }

  renderClosingPrice() {
    const { classes } = this.props;
    const { nextState, closingPrice } = this.state;
    if (nextState === LEAD_STATE_SEGUIMIENTO) {
      return (
        <div className={classes.divRootCustom}>
          <hr />
          <Toolbar>
            <Typography variant="subtitle2">Cambiar valor cotizado</Typography>
          </Toolbar>
          <div className={classes.centerContentContainer}>
            <FormControl className={classes.picker}>
              <TextField
                value={closingPrice}
                placeholder="Valor cotizado"
                onChange={(e) =>
                  isNumber(e.target.value) && this.setState({ closingPrice: e.target.value })
                }
              />
              <FormHelperText>Valor cotizado</FormHelperText>
            </FormControl>
          </div>
        </div>
      );
    }
    return null;
  }

  renderStagesOptions() {
    const { leadStates } = this.props;
    const { nextState } = this.state;

    if (nextState !== '') {
      const leadState = leadStates.filter((obj) => obj.id === nextState)[0];
      leadState.stages = sortObjectByProperty(leadState.stages, 'order');

      if (leadState) {
        if (companyObject && companyObject._id) {
          return leadState.stages
            .filter((obj) => obj.type === 'lead' && obj.company === companyObject._id)
            .map((leadStage, index) => {
              return (
                <MenuItem key={index} value={leadStage.id}>
                  {leadStage.name}
                </MenuItem>
              );
            });
        }
        return leadState.stages
          .filter((obj) => obj.type === 'lead')
          .map((leadStage, index) => {
            return (
              <MenuItem key={index} value={leadStage.id}>
                {leadStage.name}
              </MenuItem>
            );
          });
      }
    }
    return null;
  }

  renderProjectsOptions() {
    const { lead } = this.props;
    if (lead.matches.length > 0) {
      return lead.matches.map((match, index) => {
        return (
          <MenuItem key={index} value={match._id}>
            {match.name}
          </MenuItem>
        );
      });
    }
    return null;
  }

  renderUnitsOptions() {
    const { project } = this.state;
    if (project) {
      if (project !== '' && project.units) {
        if (project.units.length > 0) {
          return project.units.map((unit, index) => {
            return (
              <MenuItem key={index} value={unit._id}>
                {unit.name}
              </MenuItem>
            );
          });
        }
      }
    }
    return null;
  }

  renderProjectBrokersOptions() {
    const { lead } = this.props;
    if (lead && lead.brokers) {
      if (lead.brokers.length > 0) {
        return lead.brokers.map((broker, index) => {
          return (
            <MenuItem key={index} value={broker.userId._id}>
              {`${broker.userId.name} ${broker.userId.lastname || ''}`}
            </MenuItem>
          );
        });
      }
    }
    return null;
  }

  renderReassignLead() {
    const { classes, lead } = this.props;
    if (lead.preferences) {
      const leadZones = lead.preferences
        .filter((obj) => obj.attributeId === ZONE)
        .map((obj) => obj.answerValues)[0];
      if (leadZones) {
        return (
          <div className={classes.divRootCustom}>
            <Toolbar>
              <Typography variant="subtitle2">Reasignar Lead</Typography>
            </Toolbar>
            <div className={classes.centerContentContainer}>
              <FormControl className={classes.customPicker}>
                <Autocomplete
                  suggestions={this.renderBrokersOptions()}
                  value={this.state.brokers}
                  handleChangeCell={(brokrs) => this.setState({ brokers: brokrs })}
                  multiple={false}
                />
                <FormHelperText>Reasignar lead</FormHelperText>
              </FormControl>
            </div>
          </div>
        );
      }
    }
    return null;
  }

  renderManualActionButton = () => {
    const { isLoading, classes } = this.props;
    const { manual, dateManual, timeManual } = this.state;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText="¿Está seguro que desea agregar esta tarea?"
          buttonText="Agregar tarea"
          variant="contained"
          isDisabled={manual === '' || dateManual === '' || timeManual === ''}
          confirmAction={this.handleAddAction}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  };

  renderChangeStatus() {
    const { classes, leadStates } = this.props;
    return (
      <div className={classes.divRootCustom}>
        <hr />
        <Toolbar>
          <Typography variant="subtitle2">Cambiar estado y etapa del lead</Typography>
        </Toolbar>
        <div className={classes.centerContentContainer}>
          <FormControl className={classes.picker}>
            <Select
              value={this.state.nextState}
              onChange={(e) => this.setState({ nextState: e.target.value })}
              name="Cambiar estado del lead"
              displayEmpty
              className={classes.selectEmpty}
            >
              <MenuItem value="" disabled>
                Ninguno
              </MenuItem>
              {this.renderStatesOptions()}
            </Select>
            <FormHelperText>Cambiar estado del lead</FormHelperText>
          </FormControl>
          <FormControl className={classes.picker}>
            <Select
              value={this.state.nextStage}
              onChange={async (e) => {
                const { lead, eventsStatistics: { statistics = {} } } = this.props;
            
                try {
                  await this.fetchEventsStatistics();
                } catch (error) {}
            
                console.log('statistics:', statistics);
                const { activeEventsNotRealized = 0 } = statistics[lead._id]  ? (statistics[lead._id][0] ? statistics[lead._id][0] : {}) : {};

                const leadState =
                  leadStates && leadStates.filter((obj) => obj.id === this.state.nextState)[0];
                const leadStages = leadState && leadState.stages;

                const selectedStage =
                  leadStages &&
                  leadStages.length &&
                  leadStages.find((stage) => stage.id === e.target.value);
                console.log('selectedStage:', selectedStage);

                if (selectedStage.isReschedule) 
                  this.props.rescheduleEvent();
                else if (selectedStage.isConfirmedAppointment)
                  this.setState({ openAppointmentModal: true, isEvent: true });
                else if (selectedStage.isCompletedAppointment)
                  this.setState({ openFinishAppointmentModal: true });
                else {
                  activeEventsNotRealized > 0 && 
                    this.setState({ openFinishAppointmentModal: true });
                }

                this.setState({ nextStage: e.target.value });
              }}
              name="Cambiar etapa del lead"
              displayEmpty
              className={classes.selectEmpty}
            >
              <MenuItem value="" disabled>
                Ninguno
              </MenuItem>
              {this.renderStagesOptions()}
            </Select>
            <FormHelperText>Cambiar etapa del lead</FormHelperText>
          </FormControl>
          {this.renderBroker()}
          {this.renderWonUnit()}
        </div>
      </div>
    );
  }

  renderWonUnit() {
    const { classes } = this.props;
    const { nextState, project } = this.state;
    if (nextState === LEAD_STATE_GANADO) {
      return (
        <div>
          <Toolbar>
            <Typography variant="subtitle2">Proyecto ganado del lead</Typography>
          </Toolbar>
          <div>
            <FormControl className={classes.picker}>
              <Select
                value={project ? this.state.project._id : ''}
                onChange={this.handleChangeProject}
                name="Proyecto"
                displayEmpty
                className={classes.selectEmpty}
              >
                {this.renderProjectsOptions()}
              </Select>
              <FormHelperText>Proyecto</FormHelperText>
            </FormControl>
          </div>
          <Toolbar>
            <Typography variant="subtitle2">Unidad ganada del lead</Typography>
          </Toolbar>
          <div>
            <FormControl className={classes.picker}>
              <Select
                value={this.state.unit}
                onChange={(e) => this.setState({ unit: e.target.value })}
                name="Unidad"
                displayEmpty
                className={classes.selectEmpty}
              >
                {this.renderUnitsOptions()}
              </Select>
              <FormHelperText>Unidad</FormHelperText>
            </FormControl>
          </div>
          {this.renderClosingPriceWon()}
          {/* aqui va el boton de perfilamiento */}
        </div>
      );
    }
    return null;
  }

  renderBroker() {
    const { nextState } = this.state;
    const { classes, lead } = this.props;
    if (nextState === LEAD_STATE_GANADO && lead.status._id === LEAD_STATE_GANADO) {
      return (
        <div>
          <Toolbar>
            <Typography variant="subtitle2">Asesor</Typography>
          </Toolbar>
          <div>
            <FormControl className={classes.picker}>
              <Select
                value={
                  this.state.broker && this.state.broker.userId ? this.state.broker.userId._id : ''
                }
                onChange={this.handleChangeBroker}
                name="Asesor"
                displayEmpty
                className={classes.selectEmpty}
              >
                {this.renderProjectBrokersOptions()}
              </Select>
              <FormHelperText>Asesor</FormHelperText>
            </FormControl>
          </div>
        </div>
      );
    }
    return null;
  }

  renderSendEmail() {
    const { emailTemplate, date } = this.state;
    const { classes } = this.props;
    return (
      <div elevation={2} className={classes.divRootCustom}>
        <hr />
        <Toolbar>
          <Typography variant="subtitle2">Enviar Correo</Typography>
        </Toolbar>
        <div className={classes.centerContentContainer}>
          <FormControl className={classes.pickerInline}>
            <Select
              value={emailTemplate}
              onChange={(e) => this.setState({ emailTemplate: e.target.value })}
              name="Enviar correo"
              displayEmpty
              className={classes.selectEmpty}
            >
              <MenuItem value="" disabled>
                Ninguno
              </MenuItem>
              {this.renderEmailTemplatesOptions()}
            </Select>
            <FormHelperText>Enviar Correo</FormHelperText>
          </FormControl>
          <FormControl className={classes.pickerInline}>
            {this.state.attachment && (
              <Tooltip title="Ver adjunto">
                <a href={this.state.attachment} rel="noopener noreferrer" target="_blank">
                  <CloudDownload />
                </a>
              </Tooltip>
            )}
            <Uploader
              buttonText="Subir adjunto"
              dirName="Attachments"
              photo={false}
              urlUploaded={this.handleUrlUploaded}
              iconStyle
            />
          </FormControl>

          {/* <InlineDatePicker
            value={date}
            disablePast
            clearable
            label="Fecha de envío"
            format={dateFormat}
            className={classes.picker}
            onChange={(e) => this.setState({ date: e })}
          /> */ }
          {this.renderEmailTemplate()}
        </div>
      </div>
    );
  }

  renderEmailTemplate() {
    const { emailTemplate } = this.state;
    const { classes } = this.props;
    if (emailTemplate !== '') {
      return (
        <Paper elevation={2} className={classes.paperRootFullWidth}>
          <Toolbar>
            <Typography variant="subtitle2">Plantilla de correo</Typography>
          </Toolbar>
          <div>{renderHTML(this.getEmailTemplateById(emailTemplate))}</div>
        </Paper>
      );
    }
    return null;
  }

  renderSendWhatsapp() {
    const { classes } = this.props;
    const { whatsappTemplate } = this.state;
    return (
      <div elevation={2} className={classes.divRootCustom}>
        <hr />
        <Toolbar>
          <Typography variant="subtitle2">Enviar mensaje de Whatsapp</Typography>
        </Toolbar>
        <div className={classes.centerContentContainer}>
          <FormControl className={classes.picker}>
            <Select
              value={whatsappTemplate}
              onChange={(e) => this.setState({ whatsappTemplate: e.target.value })}
              name="Enviar whatsapp"
              displayEmpty
              className={classes.selectEmpty}
            >
              <MenuItem value="" disabled>
                Ninguno
              </MenuItem>
              {this.renderWhatsappTemplatesOptions()}
            </Select>
            <FormHelperText>Enviar mensaje de Whatsapp</FormHelperText>
          </FormControl>
          {this.renderWhatsappTemplate()}
        </div>
      </div>
    );
  }

  renderWhatsappTemplate() {
    const { whatsappTemplate } = this.state;
    const { classes } = this.props;
    if (whatsappTemplate !== '') {
      return (
        <Paper elevation={2} className={classes.paperRoot}>
          <Toolbar>
            <Typography variant="subtitle2">Plantilla de Whatsapp</Typography>
          </Toolbar>
          <div>{this.getWhatsappTemplateById(whatsappTemplate)}</div>
        </Paper>
      );
    }
    return null;
  }

  renderTemperatures = () => {
    const temperatures = [
      {
        value: 'cold',
        title: 'Frío',
      },
      {
        value: 'potential',
        title: 'Con Potencial',
      },
      {
        value: 'hot',
        title: 'Caliente',
      },
    ];
    return temperatures.map((item, index) => {
      return (
        <MenuItem key={index} value={item.value}>
          {item.title}
        </MenuItem>
      );
    });
  };

  renderQualifications = () => {
    const temperatures = [
      {
        value: 'inProcess',
        title: 'En proceso',
      },
      {
        value: 'notRequire',
        title: 'No requiere',
      },
      {
        value: 'hasPrequalification',
        title: 'Tiene precalificación',
      },
      {
        value: 'notStart',
        title: 'No inicia precalificación',
      },
    ];
    return temperatures.map((item, index) => {
      return (
        <MenuItem key={index} value={item.value}>
          {item.title}
        </MenuItem>
      );
    });
  };


  renderPotentialTimes = () => {
    const estimatedClosingTimes = ['15', '30', '60', '90', '+90'];
    return estimatedClosingTimes.map((item, index) => {
      return (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      );
    });
  };

  renderTask() {
    const { dateManual, manual, timeManual } = this.state;
    const { classes } = this.props;
    return (
      <div elevation={2} className={classes.divRootCustom}>
        <hr />
        <Toolbar>
          <Typography variant="subtitle2" style={{ marginRight: 16 }}>
            Agregar tarea
          </Typography>
          <CalendarIcon
            style={{ cursor: 'pointer', color: 'grey' }}
            onClick={() => this.setState({ openAppointmentModal: true, isEvent: false })}
          />
        </Toolbar>
      </div>
    );
  }

  renderLoader() {
    const { isLoadingMailTemplates, isLoadingWhatsappTemplates, lead, isLoading } = this.props;
    if (
      lead === null ||
      isLoading === true ||
      isLoadingMailTemplates === true ||
      isLoadingWhatsappTemplates === true
    ) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }
    return null;
  }

  renderNextActionsButton = () => {
    const { isLoading, classes, leadStates } = this.props;
    const { nextState, closingPrice, project, unit, nextStage } = this.state;

    if (!isLoading) {
      return (
        <div className={classes.centerContent}>
          <br />
          <ConfirmationDialog
            confirmationText="¿Está seguro que desea agregar esta tarea?"
            buttonText="Añadir siguientes acciones"
            variant="contained"
            confirmAction={this.handleNextActions}
            isDisabled={
              !(
                nextState !== LEAD_STATE_GANADO ||
                (nextState === LEAD_STATE_GANADO &&
                  closingPrice !== '' &&
                  project !== '' &&
                  unit !== '' &&
                  leadStates
                    .find(({ id }) => id === LEAD_STATE_GANADO)
                    .stages.map(({ id }) => id)
                    .includes(nextStage))
              )
            }
          />
          <br />
        </div>
      );
    }
    return (
      <div className={classes.centerContent}>
        <CircularProgress className={classes.progress} />
      </div>
    );
  };

  render() {
    const { classes, lead } = this.props;
    const {
      renderSalesProfiling,
      unit,
      project,
      openAppointmentModal,
      isEvent,
      openFinishAppointmentModal,
    } = this.state;
    const unitData = {};
    if (renderSalesProfiling && unit !== '') {
      unitData.id = unit;
      unitData.projectId = project._id;
    }
    return (
      <div>
        {this.renderLoader()}
        {renderSalesProfiling ? (
          <SalesProfile
            handleGoBack={this.handleGoBackSalesProfile}
            unit={unitData}
            leadId={lead.id}
          />
        ) : (
          <div>
            <Paper elevation={2} className={classes.paperRoot}>
              <Toolbar>
                <IconButton onClick={this.handleGoBack}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography variant="h6">Siguientes Acciones</Typography>
              </Toolbar>
            </Paper>
            <Paper elevation={2} className={classes.paperRoot}>
              {this.renderReassignLead()}
              {this.renderSendEmail()}
              {this.renderSendWhatsapp()}
              {this.renderChangeStatus()}
              {this.renderClosingPrice()}
              {this.renderTemperature()}
              {this.renderPrequalification()}
              {this.renderPotentialTime()}
              {this.renderTask()}
              {this.renderNextActionsButton()}
            </Paper>
          </div>
        )}
        {openAppointmentModal && (
          <AppointmentModal
            open={openAppointmentModal}
            isEventFlag={isEvent}
            close={() => this.setState({ openAppointmentModal: false })}
            lead={lead._id}
          />
        )}
        {openFinishAppointmentModal && (
          <EventList
            open={openFinishAppointmentModal}
            closeModal={() => {
              this.setState({ openFinishAppointmentModal: false });
            }}
            lead={lead.id}
          />
        )}
      </div>
    );
  }
}
NextActions.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoadingMailTemplates: PropTypes.bool.isRequired,
  isLoadingWhatsappTemplates: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  lead: PropTypes.object.isRequired,
  onAddNewAction: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  onReassignLead: PropTypes.func.isRequired,
  onUpdateTemperature: PropTypes.func.isRequired,
  onUpdatePrequalification: PropTypes.func.isRequired,
  onUpdateLeadState: PropTypes.func.isRequired,
  onUpdateEstimatedClosingTime: PropTypes.func.isRequired,
  onUpdateClosingPrice: PropTypes.func.isRequired,
  onSendLeadEmail: PropTypes.func.isRequired,
  getWhatsappTemplates: PropTypes.func.isRequired,
  whatsappTemplates: PropTypes.array.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  leadStates: PropTypes.array.isRequired,
  handleAddObservation: PropTypes.func.isRequired,
  rescheduleEvent: PropTypes.func.isRequired,
  eventsStatistics: PropTypes.shape({
    statistics: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
  }).isRequired,
  getEventsStatistics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { whatsappTemplates, emailTemplates, leads, users, eventsStatistics } = state;
  return {
    whatsappTemplates: whatsappTemplates.whatsappTemplates,
    isLoadingWhatsappTemplates: whatsappTemplates.sending,
    emailTemplates: emailTemplates.emailTemplates,
    isLoadingMailTemplates: emailTemplates.sending,
    lead: leads.lead,
    users: users.users,
    isLoading: leads.sendingLead,
    eventsStatistics,
  };
};

const mapDispatchToProps = {
  getWhatsappTemplates,
  getEmailTemplates,
  getEventsStatistics,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NextActions));
