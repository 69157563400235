export const GET_PROFORMA_COMPANY_CONFIG_SUCCESS =
  'GET_PROFORMA_COMPANY_CONFIG_SUCCESS';

export const GET_PROFORMA_COMPANY_CONFIG_SENDING =
  'GET_PROFORMA_COMPANY_CONFIG_SENDING';

export const GET_PROFORMA_COMPANY_CONFIG_FAILED =
  'GET_PROFORMA_COMPANY_CONFIG_FAILED';

export const UPDATE_PROFORMA_COMPANY_CONFIG_SUCCESS =
  'UPDATE_PROFORMA_COMPANY_CONFIG_SUCCESS';

export const UPDATE_PROFORMA_COMPANY_CONFIG_SENDING =
  'UPDATE_PROFORMA_COMPANY_CONFIG_SENDING';

export const UPDATE_PROFORMA_COMPANY_CONFIG_FAILED =
  'UPDATE_PROFORMA_COMPANY_CONFIG_FAILED';

export const VALID_PROFORMA_COMPANY_CONFIG_SUCCESS =
  'VALID_PROFORMA_COMPANY_CONFIG_SUCCESS';

export const VALID_PROFORMA_COMPANY_CONFIG_SENDING =
  'VALID_PROFORMA_COMPANY_CONFIG_SENDING';

export const VALID_PROFORMA_COMPANY_CONFIG_FAILED =
  'VALID_PROFORMA_COMPANY_CONFIG_FAILED';

export const GET_UNITS_PROFORMA_COMPANY_CONFIG_SUCCESS =
  'GET_UNITS_PROFORMA_COMPANY_CONFIG_SUCCESS';

export const GET_UNITS_PROFORMA_COMPANY_CONFIG_SENDING =
  'GET_UNITS_PROFORMA_COMPANY_CONFIG_SENDING';

export const GET_UNITS_PROFORMA_COMPANY_CONFIG_FAILED =
  'GET_UNITS_PROFORMA_COMPANY_CONFIG_FAILED';

export const CREATE_PROFORMA_SUCCESS = 'CREATE_PROFORMA_SUCCESS';

export const CREATE_PROFORMA_SENDING = 'CREATE_PROFORMA_SENDING';

export const CREATE_PROFORMA_FAILED = 'CREATE_PROFORMA_FAILED';

export const GET_PROFORMAS_SUCCESS = 'GET_PROFORMAS_SUCCESS';

export const GET_PROFORMAS_SENDING = 'GET_PROFORMAS_SENDING';

export const GET_PROFORMAS_FAILED = 'GET_PROFORMAS_FAILED';

export const GET_PROJECTS_BY_LEAD_SUCCESS = 'GET_PROJECTS_BY_LEAD_SUCCESS';

export const GET_PROJECTS_BY_LEAD_SENDING = 'GET_PROJECTS_BY_LEAD_SENDING';

export const GET_PROJECTS_BY_LEAD_FAILED = 'GET_PROJECTS_BY_LEAD_FAILED';
