import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import *  as ReactSelect from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import { InlineDatePicker } from 'material-ui-pickers';
import { withStyles, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { styles } from './styles';
import { dateFormat, isNumber } from '../../../../../utils/utils';
import { connect } from 'react-redux';
//import { AutocompleteSelect } from '../autoCompleteSelect';
import SearchSelect from '../searchSelect';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

var top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },];

export class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      filters: [],
      active: false,
      cities: [],
      zonesSelected: [],
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.getValue = this.getValue.bind(this);
    this.renderItems = this.renderItems.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.getFiltersToShowValue = this.getFiltersToShowValue.bind(this);
    this.renderFilterType = this.renderFilterType.bind(this);
    this.renderSelectValue = this.renderSelectValue.bind(this);
    this.startFilters = this.startFilters.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeZone = this.handleChangeZone.bind(this);
    this.getNameZones = this.getNameZones.bind(this);
  }

  componentWillMount() {
    const { zones } = this.props;
    const formatedCities = zones.map((itemZone) => itemZone.id, []);
    this.setState({ cities: formatedCities });
    this.startFilters();
  }

  onFilter() {
    const { filters, zonesSelected } = this.state;
    const filtersToSend = [];
    filters.forEach((filter) => {
      let shouldAdd = true;
      if (filter.type === 'number' && filter.value !== '') {
        // eslint-disable-next-line no-param-reassign
        filter.value = Number(filter.value);
      } else if (
        filter.type === 'numberRange' &&
        filter.value !== '' &&
        filter.value !== 'all'
      ) {
        // eslint-disable-next-line prefer-destructuring
        const value = filter.value;
        value.$gt = Number(filter.value.$gt);
        value.$lt = Number(filter.value.$lt);
        // eslint-disable-next-line no-param-reassign
        filter.value = value;
      } else if (
        filter.type === 'dateRange' &&
        filter.value !== '' &&
        filter.value !== 'all'
      ) {
        // eslint-disable-next-line prefer-destructuring
        const value = filter.value;
        value.start = moment(filter.value.start)
          .format(dateFormat)
          .substring(0, 10);
        value.end = moment(filter.value.end)
          .format(dateFormat)
          .substring(0, 10);
        // eslint-disable-next-line no-param-reassign
        filter.value = value;
      } else if (filter.type === 'select' && filter.id !== 'zone') {
        if (filter.value === 'all') {
          shouldAdd = false;
        } else if (Array.isArray(filter.value)) {
          if (filter.value.length > 0) {
            const indexOfAll = filter.value.indexOf('all');
            if (indexOfAll > -1) {
              filter.value.splice(indexOfAll, 1);
            }
          }
        }
      }else if(filter.id === 'zone'){
        if (zonesSelected && zonesSelected.length > 0) {
          const indexOfAll = filter.value.indexOf('all');
          filter.label = 'Sector';
          filter.title = this.getNameZones();
          filter.value = zonesSelected;
        }
      }
      if (shouldAdd) {
        filtersToSend.push(filter);
      }
    });
    this.props.onFilter(filtersToSend);
    this.setState({ open: false });
  }

  getNameZones() {
    const { classes, zones } = this.props;
    const { cities, zonesSelected } = this.state;

    let labelZones = '';
    const dataMaped = zones
      .filter((city) => cities.includes(city.id))
      .map((city) => {
        city.zones.map((zone) => {
          zone.sectors.map((sector) => {
            if (zonesSelected.indexOf(sector.id) >= 0) {
              labelZones += sector.name + ',';
            }
          });
        });
      });
    return labelZones;
  }

  renderZoneChip(selected) {
    const { classes, zones } = this.props;
    const { cities, zonesSelected } = this.state;

    let labelZones = null;
    const dataMaped = zones
      .filter((city) => cities.includes(city.id))
      .map((city) => {
        city.zones.map((zone) => {
          zone.sectors.map((sector, index) => {
            // todo render chips zones
            // if (sector.id == zoneId) { 
              // labelZones =  <Chip key={index} label={sector.name} className={classes.chip} />;
            // }
          });
        });
      });
    return labelZones;
  }

  getFilterTitle(target) {
    const { name } = target;
    let { value } = target;
    const filter = this.props.options.filter((obj) => obj.id === name)[0];
    const titleArr = [];
    if (!Array.isArray(value)) {
      const array = [value];
      value = array;
    }
    value.forEach((valueItem) => {
      const item = filter.types.filter((obj) => valueItem === obj.value)[0];
      if (item) {
        if (item.title) {
          titleArr.push(item.title);
        }
      }
    });
    const indexOfAll = titleArr.indexOf('Todos');
    if (indexOfAll > -1) {
      titleArr.splice(indexOfAll, 1);
    }
    return titleArr.join(', ');
  }

  getValue(
    filter,
    date = false,
    numberRange = false,
    isFromOrTo = 'from',
    dateRange = false,
    select = false,
  ) {
    const { filters } = this.state;
    const valueArr = filters.filter((obj) => obj.id === filter.id);
    if (valueArr.length > 0) {
      if (date === true) {
        return moment(valueArr[0].value).format(dateFormat);
      } else if (numberRange === true) {
        if (isFromOrTo === 'from') {
          return valueArr[0].value.$gt;
        }
        return valueArr[0].value.$lt;
      } else if (dateRange === true) {
        if (valueArr[0].value === 'all') {
          return null;
        }
        if (isFromOrTo === 'from') {
          return valueArr[0].value.start;
        }
        return valueArr[0].value.end;
      }
      if (select) {
        if (valueArr[0].value === 'all') {
          return [valueArr[0].value];
        }
      }
      return valueArr[0].value;
    }
    return null;
  }

  getFiltersToShowValue(filter) {
    let filters = {
      id: filter.id,
      title: 'Todos',
      value: 'all',
      type: filter.type,
      label: filter.label,
    };
    if (
      filter.type === 'date' ||
      filter.type === 'text' ||
      filter.type === 'range' ||
      filter.type === 'number'
    ) {
      filters = {
        id: filter.id,
        title: '',
        value: '',
        type: filter.type,
        label: filter.label,
      };
    }
    if (filter.attribute) {
      filters.attribute = true;
    }
    for (let i = 0; i < this.props.chips.length; i += 1) {
      const element = this.props.chips[i];
      if (filter.id === element.id) {
        filters = {
          value: element.value,
          id: element.id,
          title: element.title,
          label: filter.label,
          type: element.type,
        };
        if (filter.attribute) {
          filters.attribute = true;
        }
      }
    }
    return filters;
  }

  changeFilter(e, type = 'select', name = null, isFromOrTo = 'from') {
    let filters = this.state.filters.map((element) => {
      if (type === 'select') {
        if (element.id === e.target.name) {
          const changingFilter = {
            value: e.target.value,
            title: this.getFilterTitle(e.target),
            id: element.id,
            type,
            label: element.label,
          };
          return changingFilter;
        }
      } else if (type === 'autocomplete') {
        if (element.id === e.target.name) {
          const changingFilter = {
            value: e.target.value,
            title: this.getFilterTitle(e.target),
            id: element.id,
            type,
            label: element.label,
          };
          return changingFilter;
        }
      }
      else if (type === 'date') {
        if (element.id === name) {
          return {
            value: e,
            title: moment(e).format(dateFormat),
            id: element.id,
            type,
            label: element.label,
          };
        }
      } else if (type === 'dateRange') {
        if (element.id === name) {
          // eslint-disable-next-line prefer-destructuring
          let value = element.value;
          if (value === 'all') {
            value = {
              start: '',
              end: '',
            };
          }
          if (isFromOrTo === 'from') {
            value.start = e;
          } else {
            value.end = e;
          }
          return {
            value,
            title: moment(e).format(dateFormat),
            id: element.id,
            type,
            label: element.label,
          };
        }
      } else if (type === 'text') {
        if (element.id === name) {
          return {
            value: e.target.value,
            title: e.target.value,
            id: element.id,
            type,
            label: element.label,
          };
        }
      } else if (type === 'number' || type === 'range') {
        if (isNumber(e.target.value)) {
          if (element.id === name) {
            return {
              value: e.target.value,
              title: e.target.value,
              id: element.id,
              type,
              label: element.label,
            };
          }
        }
      } else if (type === 'numberRange') {
        if (isNumber(e.target.value)) {
          if (element.id === name) {
            // eslint-disable-next-line prefer-destructuring
            let value = element.value;
            if (value === 'all') {
              value = {
                $gt: '',
                $lt: '',
              };
            }
            if (isFromOrTo === 'from') {
              value.$gt = e.target.value;
            } else {
              value.$lt = e.target.value;
            }
            return {
              value,
              title: e.target.value,
              id: element.id,
              type,
              label: element.label,
            };
          }
        }
      }
      return element;
    });
    this.setState({ filters });
    if (e) {
      if (e.target) {
        if (e.target.name === 'status') {
          filters = filters.map((filter) => {
            if (filter.id === 'stage') {
              // eslint-disable-next-line no-param-reassign
              filter.value = [];
            }
            return filter;
          });
        }
      }
    }
  }

  startFilters() {
    
    if (this.props.options) {
      if (this.props.options.length > 0) {
        const filters = this.props.options.map((filter) => {
          const dataValueFiltered = this.getFiltersToShowValue(filter);
          if (
            dataValueFiltered.id == 'zone' &&
            dataValueFiltered.value != 'all'
          ) {
            this.setState({ zonesSelected: dataValueFiltered.value });
          }
          return dataValueFiltered;
        });
        this.setState({ active: true, filters });
      }
    }
  }

  openDialog() {
    this.setState({ open: true });
  }

  closeDialog() {
    this.setState({ open: false });
    this.startFilters();
  }

  renderSelectValue(selected, filter) {
    if (!Array.isArray(selected)) {
      const array = [selected];
      // eslint-disable-next-line no-param-reassign
      selected = array;
    }
    const { classes } = this.props;
    const chipsArray = selected.map((value, index) => {
      const titleArr = filter.types.filter((obj) => obj.value === value);
      if (titleArr) {
        if (titleArr[0]) {
          const label = titleArr[0].title;
          if (label) {
            return <Chip key={index} label={label} className={classes.chip} />;
          }
        }
      }
      return null;
    });
    return <div className={classes.chips}>{chipsArray}</div>;
  }

  renderMultiple = (isSingle, filter) => {
    if (
      filter.id === 'userId' ||
      filter.id === 'monthFrom' ||
      filter.id === 'monthTo' ||
      filter.id === 'yearFrom' ||
      filter.id === 'yearTo'
    ) {
      return false;
    }
    return !isSingle;
  };

  handleChangeZone({ target }) {
    const value = target.value.filter((item) => item);
    this.setState({ zonesSelected: value });
    this.getNameZones();
  }

  handleChangeCity({ target }) {
    const { value } = target;
    this.setState({ cities: value });
  }

  renderCities() {
    const { classes, zones } = this.props;
    return zones.map((item, index) => (
      <MenuItem value={item.id} key={`cities_${index}`}>
        {item.name}
      </MenuItem>
    ));
  }

  renderCity() {
    const { classes } = this.props;
    const { cities } = this.state;
    return (
      <div style={{ padding: '10px 0 30px 0px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="city-select">
            Ciudad
          </InputLabel>
          <Select
            value={cities}
            fullWidth
            onChange={this.handleChangeCity}
            multiple
          >
            {this.renderCities()}
          </Select>
        </FormControl>
      </div>
    );
  }

  renderZone(filter) {
    const { classes } = this.props;
    const { zonesSelected } = this.state;
    return (
      <div>
        {this.renderCity()}
        <div style={{}}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel className={classes.filterTitle} id="zone-select">
              Zona
            </InputLabel>
            <Select
              value={zonesSelected}
              placeholder="Todos"
              fullWidth
              onChange={this.handleChangeZone}
              // renderValue={(selected) => this.renderZoneChip(selected, filter)}
              multiple
            >
              {this.renderZones()}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }

  renderZones() {
    const { classes, zones } = this.props;
    const { cities } = this.state;

    if (!zones || zones.length === 0) return [];
    // const filteredZones = zones.find(
    //   (item) => item.id === '5eb3642afbcce860f00478e5',
    // ).zones;

    let zonesArray = [];
    zones
      .filter((city) => cities.includes(city.id))
      .map((city, cityIndex) => {
        const response = city.zones.reduce((acc, val, index) => {
          acc.push(
            <div
              className={classes.zoneLabel}
              key={`zone_${cityIndex}_${index}`}
            >
              {val.name}
              <Chip label={city.name} className={classes.cityChip} />
            </div>,
          );
          val.sectors.map((item, i) =>
            acc.push(
              <MenuItem
                className={classes.zoneMenu}
                value={item.id}
                key={`sector_${cityIndex}_${index}_${i}`}
              >
                {item.name}
              </MenuItem>,
            ),
          );
          return acc;
        }, []);
        zonesArray = [...zonesArray, ...response];
      });

    return zonesArray;
  }

  renderFilterType(filter) {
    const { classes, isSingle } = this.props;
    if (filter.type === 'date') {
      return (
        <InlineDatePicker
          value={this.getValue(filter, true)}
          fullWidth
          clearable
          emptyLabel=""
          invalidLabel=""
          invalidDateMessage=""
          autoOk
          onChange={(e) => this.changeFilter(e, 'date', filter.id)}
          label={filter.label}
          format={dateFormat}
          className={classes.datePicker}
        />
      );
    } else if (filter.type === 'dateRange') {
      return (
        <div>
          <InlineDatePicker
            value={this.getValue(filter, false, false, 'from', true)}
            clearable
            emptyLabel=""
            invalidLabel=""
            invalidDateMessage=""
            autoOk
            onChange={(e) =>
              this.changeFilter(e, 'dateRange', filter.id, 'from')
            }
            label={filter.label}
            format={dateFormat}
            className={classes.datePickerRange}
          />
          <InlineDatePicker
            value={this.getValue(filter, false, false, 'to', true)}
            clearable
            emptyLabel=""
            invalidLabel=""
            invalidDateMessage=""
            autoOk
            onChange={(e) => this.changeFilter(e, 'dateRange', filter.id, 'to')}
            label={filter.label}
            format={dateFormat}
            className={classes.datePickerRange}
          />
        </div>
      );
    } else if (
      filter.type === 'text' ||
      filter.type === 'range' ||
      filter.type === 'number'
    ) {
      return (
        <TextField
          fullWidth
          variant="outlined"
          label={filter.label}
          onChange={(e) => this.changeFilter(e, filter.type, filter.id)}
          value={this.getValue(filter)}
        />
      );
    } else if (filter.type === 'numberRange') {
      return (
        <div>
          <TextField
            variant="outlined"
            label={`${filter.label} desde: `}
            onChange={(e) =>
              this.changeFilter(e, filter.type, filter.id, 'from')
            }
            value={this.getValue(filter, false, true, 'from')}
            style={{ width: '50%' }}
          />
          <TextField
            variant="outlined"
            label={`${filter.label} hasta: `}
            onChange={(e) => this.changeFilter(e, filter.type, filter.id, 'to')}
            value={this.getValue(filter, false, true, 'to')}
            style={{ width: '50%' }}
          />
        </div>
      );
    } else if (filter.type === 'autocomplete' ){
      const autcompleteValue = this.getValue(filter, false, false, null, false, true);
      const selectedOptions = filter.types.filter(option=> autcompleteValue.includes(option.value)).map(option=>{return {value:option.value, label:option.title}});
     return  <SearchSelect  onChange={(e) => this.changeFilter(e)} options = {filter} label = {filter.label}  selectedOptions ={selectedOptions}/>
      //return this.renderAutocomplete(filter);
    }
    else if (filter.id === 'zone') {
      const valSelect = this.getValue(filter, false, false, null, false, true);

      return this.renderZone(filter);
    }
    const val = this.getValue(filter, false, false, null, false, true);

    return (
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel htmlFor={`${filter.id}-filter`}>{filter.label}</InputLabel>
        <Select
          value={val}
          inputProps={{
            name: filter.id,
            id: `${filter.id}-filter`,
          }}
          onChange={(e) => this.changeFilter(e)}
          multiple={this.renderMultiple(isSingle, filter)}
          renderValue={(selected) => this.renderSelectValue(selected, filter)}
        >
          {this.renderItems(filter)}
        </Select>
      </FormControl>
    );
  }


  

  renderAutocomplete (autocompleteOptions){
  // return <AutocompleteSelect />
  }

  renderFilters() {
    const { classes, options } = this.props;
    const { filters } = this.state;
    if (filters.length > 0) {
      return options.map((filter, filterIndex) => {
        return (
          <div className={classes.filterSelect} key={filterIndex}>
            {this.renderFilterType(filter)}
          </div>
        );
      });
    }
    return null;
  }

  renderItems = (filter) => {
    const { filters } = this.state;
    const { classes } = this.props;
    const items = filter.types;
    if (filter.id === 'stage') {
      if (items) {
        const currentLeadState = filters.filter(
          (obj) => obj.id === 'status',
        )[0];
        if (currentLeadState) {
          return items
            .filter((obj) => currentLeadState.value.includes(obj.leadState))
            .map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {`${item.title} `}
                  {item.leadStateName &&
                    typeof item.leadStateName === 'string' &&
                    item.leadStateName !== null &&
                    item.leadStateName !== '' && (
                      <span className={classes.menuItemSpan}>
                        {item.leadStateName}
                      </span>
                    )}
                </MenuItem>
              );
            });
        }
      }
    }

    if (items) {
      return items.map((item, index) => {
        return (
          <MenuItem key={index} value={item.value}>
            {item.title}
          </MenuItem>
        );
      });
    }
    return null;
  };

  render() {
    const { active } = this.state;
    const { classes } = this.props;
    return active === false ? (
      ''
    ) : (
      <div>
        <Tooltip title="Filtrar">
          <IconButton onClick={this.openDialog}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="lg"
          fullWidth
          open={this.state.open}
          onClose={this.closeDialog}
          TransitionComponent={Transition}
        >
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {this.renderFilters()}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.onFilter} color="primary">
              Filtrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  isSingle: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  chips: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { zones } = state;
  return {
    zones: zones.formattedZones,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Filters));
