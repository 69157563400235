import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Select,
  CircularProgress,
  TextField,
  Paper,
  Toolbar,
  Switch,
  FormControlLabel,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { styles } from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import { createLeadStage, updateLeadStage } from '../../../../actions/leadStages';
import { SUPER_ADMIN_ROL_ID } from '../../../../utils/utils';

const LeadStageDetail = (props) => {
  const [leadStageDetail, setLeadStage] = useState({
    active: true,
    name: '',
    order: '',
    type: '',
    companyId: '',
    leadStatusId: '',
    isConfirmedAppointment: false,
    isCompletedAppointment: false,
    isReschedule: false,
    funnelLevel: '',
    isActiveStage: false,
  });
  const [orders, setOrders] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const { isNew, leadStage, leadStages } = props;

    if (!isNew) {
      const companyOrders = [
        ...new Array(
          leadStages.filter(
            (doc) =>
              doc.companyId === leadStage.companyId && doc.leadStatusId === leadStage.leadStatusId,
          ).length,
        ),
      ].map((_, n) => n + 1);
      setOrders(companyOrders);
      setLeadStage(leadStage);
    }
  }, []);

  const onAddButtonClick = async () => {
    const { active, order, ...rest } = leadStageDetail;
    const { companyId, leadStatusId, ...object } = rest;
    await dispatch(
      createLeadStage({
        ...object,
        company: companyId,
        leadStatus: leadStatusId,
      }),
    );
    props.handleGoBack();
  };

  const onEditButtonClick = async () => {
    const { leadStage } = props;
    const {
      name,
      active,
      order,
      type,
      leadStatusId,
      isConfirmedAppointment,
      isCompletedAppointment,
      isReschedule,
      funnelLevel,
      isActiveStage,
    } = leadStageDetail;
    const data = {};

    if (leadStage.name !== name) data.name = name;
    if (leadStage.active !== active) data.active = active;
    if (leadStage.order !== order) data.order = order;
    if (leadStage.type !== type) data.type = type;
    if (leadStage.leadStatusId !== leadStatusId) data.leadStatus = leadStatusId;
    if (leadStage.isConfirmedAppointment !== isConfirmedAppointment)
      data.isConfirmedAppointment = isConfirmedAppointment;
    if (leadStage.isCompletedAppointment !== isCompletedAppointment)
      data.isCompletedAppointment = isCompletedAppointment;
    if (leadStage.funnelLevel !== funnelLevel) data.funnelLevel = funnelLevel;
    if (leadStage.isActiveStage !== isActiveStage) data.isActiveStage = isActiveStage;
    if (leadStage.isReschedule !== isReschedule) data.isReschedule = isReschedule;

    await dispatch(updateLeadStage(leadStage.id, data));
    props.handleGoBack();
  };

  const handleChangeLeadStage = (e) => {
    const name = e.target.value;
    setLeadStage((prevState) => {
      return { ...prevState, name };
    });
  };

  const isInvalid = () => {
    const { name } = leadStageDetail;
    if (name !== '') {
      return false;
    }
    return true;
  };

  const renderButton = () => {
    const { isLoading, isNew, classes } = props;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText={`¿Está seguro que desea ${
            isNew ? 'crear' : 'modificar'
          } esta etapa de lead?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} etapa de lead`}
          variant="contained"
          isDisabled={isInvalid()}
          confirmAction={isNew ? onAddButtonClick : onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  };

  const { classes, isLoading, isNew, handleGoBack, types, companies, leadStates } = props;

  const profileId = localStorage.getItem('profileId');

  return isLoading === true ? (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    />
  ) : (
    <Paper elevation={2} className={classes.paperRoot}>
      <Toolbar>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">{!isNew ? 'Detalle de ' : 'Nueva '} etapa de lead</Typography>
      </Toolbar>
      <div style={{ width: 'auto', overflowX: 'scroll' }}>
        <Table>
          <TableBody>
            {!isNew && (
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Disponible</b>
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={leadStageDetail.active}
                        onChange={(e) =>
                          setLeadStage((prevState) => {
                            return { ...prevState, active: e.target.checked };
                          })
                        }
                        color="primary"
                      />
                    }
                    label={leadStageDetail.active === true ? 'Disponible' : 'No disponible'}
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Habiltitar evento de cita confirmada</b>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={leadStageDetail.isConfirmedAppointment}
                      onChange={(e) =>
                        setLeadStage((prevState) => {
                          return { ...prevState, isConfirmedAppointment: e.target.checked };
                        })
                      }
                      color="primary"
                    />
                  }
                  label={
                    leadStageDetail.isConfirmedAppointment === true ? 'Habilitado' : 'No habilitado'
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Habiltitar evento de cita realizada</b>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={leadStageDetail.isCompletedAppointment}
                      onChange={(e) =>
                        setLeadStage((prevState) => {
                          return { ...prevState, isCompletedAppointment: e.target.checked };
                        })
                      }
                      color="primary"
                    />
                  }
                  label={
                    leadStageDetail.isCompletedAppointment === true ? 'Habilitado' : 'No habilitado'
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Habiltitar evento de Reagendar</b>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={leadStageDetail.isReschedule}
                      onChange={(e) =>
                        setLeadStage((prevState) => {
                          return { ...prevState, isReschedule: e.target.checked };
                        })
                      }
                      color="primary"
                    />
                  }
                  label={leadStageDetail.isReschedule === true ? 'Habilitado' : 'No habilitado'}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>¿Es una Etapa Activa?</b>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={leadStageDetail.isActiveStage}
                      onChange={(e) =>
                        setLeadStage((prevState) => {
                          return { ...prevState, isActiveStage: e.target.checked };
                        })
                      }
                      color="primary"
                    />
                  }
                  label={leadStageDetail.isActiveStage === true ? 'Si' : 'No'}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Nivel de Funnel</b>
              </TableCell>
              <TableCell>
                <Select
                  value={leadStageDetail.funnelLevel}
                  onChange={(e) =>
                    setLeadStage({ ...leadStageDetail, funnelLevel: e.target.value })
                  }
                  fullWidth
                >
                  <MenuItem value="">Ninguno</MenuItem>
                  <MenuItem value="UPPER">Superior</MenuItem>
                  <MenuItem value="MEDIUM">Medio</MenuItem>
                  <MenuItem value="LOWER">Inferior</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Nombre</b>
              </TableCell>
              <TableCell>
                <TextField
                  autoFocus
                  value={leadStageDetail.name}
                  onChange={handleChangeLeadStage}
                  fullWidth
                />
              </TableCell>
            </TableRow>
            {!isNew && (
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Orden:</b>
                </TableCell>
                <TableCell>
                  <Select
                    value={leadStageDetail.order}
                    onChange={(e) =>
                      setLeadStage({
                        ...leadStageDetail,
                        order: e.target.value,
                      })
                    }
                    fullWidth
                  >
                    {orders.map((order, index) => (
                      <MenuItem value={order} key={index}>
                        {order}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Tipo de Etapa:</b>
              </TableCell>
              <TableCell>
                <Select
                  value={leadStageDetail.type}
                  onChange={(e) => setLeadStage({ ...leadStageDetail, type: e.target.value })}
                  fullWidth
                >
                  {types.map((type, index) => (
                    <MenuItem value={type.id} key={index}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Estado de lead:</b>
              </TableCell>
              <TableCell>
                <Select
                  value={leadStageDetail.leadStatusId}
                  onChange={(e) =>
                    setLeadStage({
                      ...leadStageDetail,
                      leadStatusId: e.target.value,
                    })
                  }
                  fullWidth
                >
                  {leadStates.map((state, index) => (
                    <MenuItem value={state.id} key={index}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            {isNew && SUPER_ADMIN_ROL_ID === profileId && (
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Empresa:</b>
                </TableCell>
                <TableCell>
                  <Select
                    value={leadStageDetail.companyId}
                    onChange={(e) =>
                      setLeadStage({
                        ...leadStageDetail,
                        companyId: e.target.value,
                      })
                    }
                    fullWidth
                  >
                    {companies.map((company, index) => (
                      <MenuItem value={company.id} key={index}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className={classes.submitContainer}>{renderButton()}</div>
    </Paper>
  );
};

LeadStageDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  leadStage: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  leadStages: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  leadStates: PropTypes.array.isRequired,
};

export default withStyles(styles)(LeadStageDetail);
