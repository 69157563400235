import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import ExitIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import logo from '../../../assets/logotrivo.png';
import {styles} from './styles';

export class PageTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logOut = () => {
    try {
      const company = JSON.parse(localStorage.getItem('company'));
      const path = company && company.identifier ? `/login/${company.identifier}` : '/';       
      localStorage.clear();
      window.location.replace(path);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e: ', e);
    }
  }

  render() {
    const {classes} = this.props;
    const company = JSON.parse(localStorage.getItem('company'));
    const userName = localStorage.getItem('userName');
    return (
      <AppBar
        position="absolute"
        className={classNames(classes.appBar, this.props.open && classes.appBarShift)}
      >
        <Toolbar disableGutters={!this.props.open} className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={this.props.handleDrawerOpen}
            className={classNames(
              classes.menuButton,
              this.props.open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />    
          </IconButton>
          <Link to="/" href="null">
            <img src={company && company.layout.logo ? company.layout.logo : logo} alt="logo" className={classes.logo} />
          </Link>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {this.props.title}
          </Typography>
          {userName && <div className={classes.userName}>{userName}</div>}
          {
            localStorage.token &&
            <IconButton
              color="inherit"
              onClick={this.logOut}
            >
              <ExitIcon />
            </IconButton>
          }
        </Toolbar>
      </AppBar>
    );
  }
}
PageTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired
};
  
export default (withStyles(styles)(PageTitle));
