import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import CheckBox from '@material-ui/icons/CheckBox';
import {withStyles} from '@material-ui/core/styles';
import {styles} from './styles';

export class ImagePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerId: null
    };
    this.renderAnswers = this.renderAnswers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answerId} = currentAnswer;
      if (answerId.length > 0) {
        this.setState({answerId});
      }
    }
  }

  handleChange(answerId) {
    const {identifier, permissions} = this.props;
    if (!permissions) {
      return;
    }
    this.setState({answerId});
    this.props.updateAnswer(identifier, [answerId], '');
  }

  renderAnswers() {
    const {classes} = this.props;
    return this.props.answers.map((options, index) => {
      return (
        <div key={index} className={classes.cell}>
          <Typography variant="body1" style={{cursor: 'pointer', marginBottom: 10}} onClick={() => this.handleChange(options.answerId)}>{options.value}</Typography>
          <GridListTile className={classes.gridListTile}>
            <button className={classes.transparentButton} onClick={() => this.handleChange(options.answerId)} >
              <img 
                src={options.url} 
                alt={options.value} 
                className={classes.image}                 
              />    
            </button>                
            <GridListTileBar
              className={classes.gridTile}
              onClick={() => this.handleChange(options.answerId)}
              actionIcon={this.state.answerId === options.answerId &&
              <div className={classes.iconContainer}>
                <IconButton className={classes.icon} onClick={() => this.handleChange(options.answerId)}>
                  <CheckBox fontSize="large" />
                </IconButton>
              </div>
              }
            />
          </GridListTile>
        </div>
      );
    });
  }

  render() {
    return this.renderAnswers();
  }
}
ImagePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  currentAnswer: PropTypes.object,
  identifier: PropTypes.string,
  permissions: PropTypes.bool,
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    url: PropTypes.string,
    answerId: PropTypes.string
  })).isRequired,
  updateAnswer: PropTypes.func.isRequired
};

export default withStyles(styles)(ImagePicker);
