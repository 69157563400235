/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as leadStageActions from './types/leadStages';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterLeadStages = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().leadStages.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().leadStages.order || orderBy !== store.getState().leadStages.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: leadStageActions.FILTER_LEAD_STAGES_SENDING,
    payload: {
      leadStages: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  if (orderBy === 'companyName') orderBy = 'company';
  else if (orderBy === 'leadStatusName') orderBy = 'leadStatus';
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && order !== null && orderBy !== null && searchText !== null) {
      filter = {
        filters: {
          name: searchText
        },
        operator: 'and',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order
        }
      };
      if (company && company._id) {
        filter.filters.company = [company._id];
      }
    }
    const response = await axios(axiosMaker('POST', 'leadStages/filter', filter, true));
    return dispatch({
      type: leadStageActions.FILTER_LEAD_STAGES_SUCCESS,
      payload: {
        leadStages: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadStageActions.FILTER_LEAD_STAGES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getLeadStages = () => async (dispatch) => {
  dispatch({
    type: leadStageActions.GET_LEAD_STAGES_SENDING,
    payload: {
      leadStages: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      operator: 'and',
      sort: {},
      filters: company && company._id ? {company: [company._id]} : {}
    };
    const response = await axios(axiosMaker('POST', 'leadStages/filter', data, true));
    return dispatch({
      type: leadStageActions.GET_LEAD_STAGES_SUCCESS,
      payload: {
        leadStages: response.data.results,
        dataLength: response.data.results.length
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadStageActions.GET_LEAD_STAGES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createLeadStage = data => async (dispatch) => {
  dispatch({
    type: leadStageActions.CREATE_LEAD_STAGE_SENDING,
    payload: {}
  });
  try {
    if (company && company._id) data.company = company._id;
    await axios(axiosMaker('POST', 'leadStages', data, true));
    return dispatch({
      type: leadStageActions.CREATE_LEAD_STAGE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadStageActions.CREATE_LEAD_STAGE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateLeadStage = (id, leadStage) => async (dispatch) => {
  dispatch({
    type: leadStageActions.UPDATE_LEAD_STAGE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `leadStages/${id}`, leadStage, true));
    return dispatch({
      type: leadStageActions.UPDATE_LEAD_STAGE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadStageActions.UPDATE_LEAD_STAGE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
