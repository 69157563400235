import * as unitsActions from '../actions/types/units';

const initialState = {
  sending: false,
  message: '',
  units: [],
  unit: null,
  dataLength: 0,
  error: null,
  searchText: '',
  order: 'asc',
  orderBy: 'project',
  page: 0,
  rowsPerPage: 20,
  filters: {},
  images: []
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, images, units, unit, message, dataLength, searchText, order, orderBy, page, rowsPerPage, filters} = action.payload;
  switch (action.type) {
  case unitsActions.GET_UNITS_SENDING:
    return {...state, sending: true, units: [], dataLength, searchText, order, orderBy, page, rowsPerPage, filters};
  case unitsActions.GET_UNITS_SUCCESS:
    return {...state, sending: false, message: 'Unidades obtenidas satisfactoriamente', units, dataLength, error: null};
  case unitsActions.GET_UNITS_FAILED:
    return {...state, sending: false, message, error};
  case unitsActions.GET_UNIT_IMAGES_SENDING:
    return {...state, sending: true};
  case unitsActions.GET_UNIT_IMAGES_SUCCESS:
    return {...state, sending: false, message: 'Imágenes obtenidas satisfactoriamente', images};
  case unitsActions.GET_UNIT_IMAGES_FAILED:
    return {...state, sending: false, message, error, images: []};
  case unitsActions.ADD_UNIT_SENDING:
    return {...state, sending: true, message: '', error: null};
  case unitsActions.ADD_UNIT_SUCCESS:
    return {...state, sending: false, message: 'Unidad creada satisfactoriamente', error: null};
  case unitsActions.ADD_UNIT_FAILED:
    return {...state, sending: false, message, error};
  case unitsActions.EDIT_UNIT_SENDING:
    return {...state, sending: true, message: '', error: null};
  case unitsActions.EDIT_UNIT_SUCCESS:
    return {...state, sending: false, message: 'Unidad modificada satisfactoriamente', error: null};
  case unitsActions.EDIT_UNIT_FAILED:
    return {...state, sending: false, message, error};
  case unitsActions.EDIT_UNIT_ATTACHMENTS_SENDING:
    return {...state, sending: true, message: '', error: null};
  case unitsActions.EDIT_UNIT_ATTACHMENTS_SUCCESS:
    return {...state, sending: false, message: 'Adjuntos modificados satisfactoriamente', error: null};
  case unitsActions.EDIT_UNIT_ATTACHMENTS_FAILED:
    return {...state, sending: false, message, error};
  case unitsActions.GET_UNIT_SENDING:
    return {...state, sending: true, message: '', error: null};
  case unitsActions.GET_UNIT_SUCCESS:
    return {...state, sending: false, message: 'Unidad modificada satisfactoriamente', error: null, unit};
  case unitsActions.GET_UNIT_FAILED:
    return {...state, sending: false, message, error};
  case unitsActions.ADD_UNITS_SENDING:
    return {...state, sending: true, message: '', error: null};
  case unitsActions.ADD_UNITS_SUCCESS:
    return {...state, sending: false, message: 'Unidades creadas satisfactoriamente', error: null};
  case unitsActions.ADD_UNITS_FAILED:
    return {...state, sending: false, message, error};
  default:
    return state;
  }
};
