export const SET_PROJECT = 'SET_PROJECT';

export const SET_ZONE = 'SET_ZONE';

export const SET_CITIES = 'SET_CITIES';

export const SET_ROOM = 'SET_ROOM';

export const SET_UNIT_TYPE = 'SET_UNIT_TYPE';

export const SET_END_DATE = 'SET_END_DATE';

export const SET_START_DATE = 'SET_START_DATE';

export const SET_CHIP_DATA = 'SET_CHIP_DATA';

export const SET_GLOBAL_FILTER = 'SET_GLOBAL_FILTER';

export const SET_FILTERS = 'SET_FILTERS';

export const SET_DISABLE_FILTER = 'SET_DISABLE_FILTER';
