export const formatPie = (title, data) => {
  const mapedData = data.map((item) => {
    return [item.name, item.count || null];
  }, []);
  const chart = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
      },
    },
    title: {
      text: title,
    },
    subtitle: {
      text: '',
    },
    tooltip: {
      pointFormat:
        '{series.name}:<b>{point.y}</b> / <b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
      pie: {
        depth: 20,
      },
      dataLabels: {
        enabled: true,
        format: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
    },
    series: [
      {
        colors: [
          '#17a2b8',
          '#006064',
          '#23dce4',
          '#4ebb3a',
          '#3F51B5',
          '#497f81',
        ],
        name: '#',
        data: mapedData,
      },
    ],
  };
  return chart;
};

export const formatDynamicPie = (title, data) => {
  const mapedData = data.map((item) => {
    return { name: item.name, y: item.count || null, z: item.count || null };
  }, []);
  const chart = {
    chart: {
      type: 'variablepie',
    },
    title: {
      text: title,
    },
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        '#: <b>{point.y}</b><br/>' +
        '%: <b>{point.percentage:.1f}</b><br/>',
    },
    plotOptions: {
      variablepie: {
        showInLegend: true,
      },
    },
    series: [
      {
        minPointSize: 10,
        innerSize: '20%',
        zMin: 0,
        name: title,
        data: mapedData,
      },
    ],
  };
  return chart;
};

export const formatDonnut = (title, data) => {
  const mapedData = data.map((item) => {
    return [item.name, item.count || null];
  }, []);
  const chart = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
      },
    },
    title: {
      text: title,
    },
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        depth: 45,
      },
    },
    tooltip: {
      pointFormat:
        '{series.name}: <b>{point.y}</b> / <b>{point.percentage:.1f}%</b>',
    },
    series: [
      {
        name: title,
        data: mapedData,
      },
    ],
  };
  return chart;
};

export const formatColumn = (title, data) => {
  const categories = [];
  const seriesData = [];
  let dataSum = 0;
  const mapedData = data.map((item) => {
    categories.push(item.name);
    seriesData.push(item.count);
    dataSum += item.count;
    return [item.name, item.count || null];
  }, []);
  const chart = {
    title: {
      text: title,
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Valores',
      },
    },
    plotOptions: {
      column: {
        stacking: 'y',
      },
    },
    tooltip: {
      formatter: function () {
        var pcnt = (this.y / dataSum) * 100;
        return `<span style="color:${this.series.color}">${
          this.x
        }</span>: <b>#${this.point.y}</b> / 
          ${pcnt.toFixed(1)} 
          %`;
      },
    },
    series: [
      {
        type: 'column',
        name: title,
        colorByPoint: true,
        data: seriesData,
        showInLegend: false,
      },
    ],
  };
  return chart;
};

export const formatColumnIversed = (title, data) => {
  const categories = [];
  const seriesData = [];
  let dataSum = 0;
  const mapedData = data.map((item) => {
    categories.push(item.name);
    seriesData.push(item.count || null);
    dataSum += item.count;
    return [item.name, item.count];
  }, []);
  const chart = {
    chart: {
      type: 'bar',
    },
    title: {
      text: title,
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: '#',
      },
    },
    tooltip: {
      formatter: function () {
        var pcnt = (this.y / dataSum) * 100;
        return `<span style="color:${this.series.color}">${
          this.x
        }</span>: <b>#${this.point.y}</b> / 
          ${pcnt.toFixed(1)} 
          %`;
      },
    },
    series: [
      {
        type: 'column',
        name: title,
        colorByPoint: true,
        colors: [
          '#f2efea',
          '#fc7753',
          '#66d7d1',
          '#3fbdc5',
          '#17a2b8',
          '#159aae',
          '#1292a3',
          '#0c818e',
          '#006064',
          '#23dce4',
          '#4ebb3a',
          '#3F51B5',
          '#497f81',
        ],
        data: seriesData,
        showInLegend: false,
      },
    ],
  };
  return chart;
};

export const formatColumnIversed3D = (title, data, inverted = true) => {
  const categories = [];
  const seriesData = [];
  let dataSum = 0;
  const mapedData = data.map((item) => {
    categories.push(item.name);
    seriesData.push(item.count || null);
    dataSum += item.count;
    return [item.name, item.count];
  }, []);
  const chart = {
    chart: {
      type: 'column',
      inverted,
      options3d: {
        enabled: true,
        alpha: 20,
        beta: 0,
        depth: 50,
        viewDistance: 25,
      },
    },
    title: {
      text: title,
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories,
    },
    tooltip: {
      formatter: function () {
        var pcnt = (this.y / dataSum) * 100;
        return `<span style="color:${this.series.color}">${
          this.x
        }</span>: <b>#${this.y}</b> / 
          ${pcnt.toFixed(1)} 
          %`;
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '#',
      },
    },
    series: [
      {
        type: 'column',
        colorByPoint: true,
        name: title,
        colors: [
          '#17a2b8',
          '#006064',
          '#23dce4',
          '#4ebb3a',
          '#3F51B5',
          '#497f81',
        ],
        data: seriesData,
        showInLegend: false,
      },
    ],
  };
  return chart;
};

export const pieWithLegends = (title, data) => {
  const categories = [];
  const seriesData = [];
  const mapedData = data.map((item) => {
    categories.push(item.name);
    seriesData.push(item.count || null);
    return [item.name, item.count || null];
  }, []);
  const chart = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: title,
    },
    tooltip: {
      pointFormat: '{series.name}: #{point.y}  / <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: title,
        colorByPoint: true,
        data: mapedData,
      },
    ],
  };
  return chart;
};

export const semiPie = (title, data) => {
  const mapedData = data.map((item) => {
    return [item.name, item.count || null];
  }, []);
  const chart = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
    },
    title: {
      text: title,
      align: 'center',
      verticalAlign: 'middle',
      y: 60,
    },
    tooltip: {
      pointFormat: '{series.name}: #{point.y} / <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: 'bold',
            color: 'white',
          },
        },
        showInLegend: true,
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '75%'],
        // size: '110%',
      },
    },
    series: [
      {
        type: 'pie',
        name: title,
        innerSize: '50%',
        data: mapedData,
      },
    ],
  };
  return chart;
};

function getPointCategoryName(point, dimension) {

  var series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x']];
}

export const treeMapFormater = (
  title,
  data,
  minColor = '#FFFFFF',
  maxColor = '#006064',
) => {
  let dataSum = 0;
  const mapedData = data.map((item, index) => {
    dataSum += item.count;
    return { name: item.name, value: item.count || null, colorValue: index };
  }, []);
  const chart = {
    colorAxis: {
      minColor,
      maxColor,
      visible:false
    },
    tooltip: {
      formatter: function () {
        var pcnt = (this.point.value / dataSum) * 100;
        return `<b>${this.point.name} </b>  <br>
          <b># ${this.point.value} </b>
          <br><b>% ${pcnt.toFixed(1)} </b>`;
      },
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        alternateStartingDirection: true,
        data: mapedData,
      },
    ],
    title: {
      text: title,
    },
  };
  return chart;
};
