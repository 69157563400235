import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {filterCommissions, patchCommission} from '../../actions/commissions';
import EnhancedTable from './table';
import {commisionsPayCols} from '../../utils/colTypes';
import CustomSnackbar from '../common/customSnackbar';
import {commissionsOptions} from '../../utils/filterTypes';
import {getBuilders} from '../../actions/builders';
import {getProjects} from '../../actions/projects';
import {copyObject} from '../../utils/utils';

export class PayCommissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Comisiones por pagar',
      filterChips: [{
        id: 'status',
        label: 'Estado',
        title: 'Pendiente',
        type: 'select',
        value: ['pending']
      }],
      filterOptions: copyObject(commissionsOptions)
    };
    this.onOrder = this.onOrder.bind(this);
    this.filtersAttributes = this.filtersAttributes.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.getProjects().then(() => {
      this.props.getBuilders().then(() => {
        this.props.filterCommissions(
          this.props.page,
          this.props.rowsPerPage,
          this.props.order,
          this.props.orderBy,
          this.props.searchText,
          {type: 'pay', status: ['pending']}
        ); 
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.renderFilters();
    }
  }  

  onOrder(order, orderBy) {
    this.props.filterCommissions(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText, this.props.filters);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_revenues_pay) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  filtersAttributes = (allFilters) => {
    const items = allFilters;
    const attributes = [];
    for (let i = 0; i < Object.entries(items).length; i += 1) {
      const filter = Object.entries(items)[i];
      if (
        filter[0] !== 'builder' && 
        filter[0] !== 'project' && 
        filter[0] !== 'status' &&
        filter[0] !== 'total' &&
        filter[0] !== 'date_created' &&
        filter[1] !== ''
      ) {
        let query = '';
        if (filter[1].type === 'select') {
          query = 'preferences.answerId';
          attributes.push({
            [query]: filter[1].value
          });
        } else {
          query = 'preferences.answer';
          attributes.push({
            [query]: filter[1].value
          });
        }
        delete items[filter[0]];
      }
    }
    if (attributes.length > 0) {
      attributes.forEach((attribute) => {
        const attributeObject = Object.entries(attribute);
        const key = attributeObject[0][0];
        const value = attributeObject[0][1];
        if (value !== '') {
          items[key] = value;
        } else {
          delete items[key];
        }
      });
    }
    const preFilters = Object.entries(items).filter(obj => obj[1] !== '');
    const filters = {};
    preFilters.forEach((item) => {
      if (typeof item[1] === 'string' || item[1] instanceof String) {
        // eslint-disable-next-line prefer-destructuring
        filters[item[0]] = item[1];
      } else {
        // eslint-disable-next-line prefer-destructuring
        filters[item[0]] = item[1].value;
      }
    });
    return filters;
  }

  handleSearch(searchText) {
    const {filters} = this.props;
    filters.type = 'pay';
    this.props.filterCommissions(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText, filters);
  }

  async handleChangeStatus(id, status) {
    const data = {
      status
    };
    await this.props.patchCommission(id, data);
    const {filters} = this.props;
    filters.type = 'pay';
    this.props.filterCommissions(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, filters);
  }

  handleChangeRowsPerPage(rowsPerPage) {
    const {filters} = this.props;
    filters.type = 'pay';
    this.props.filterCommissions(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, filters);
  }

  handleFilter(filters, filterChips) {
    const filtersToApply = this.filtersAttributes(filters);
    filtersToApply.type = 'pay';
    this.setState({filterChips});
    this.props.filterCommissions(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, filtersToApply);
  }

  handleChangePage(page) {
    const {filters} = this.props;
    filters.type = 'pay';
    this.props.filterCommissions(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, filters);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }


  renderFilters() {
    const {builders, projects} = this.props;
    let defaultFilters = copyObject(commissionsOptions);
    if (builders.length > 0 && projects.length > 0) {
      defaultFilters = copyObject(commissionsOptions).map((item) => {
        const filter = item;
        if (filter.id === 'builder') {
          const types = [{title: 'Todos', value: 'all'}];
          builders.forEach((builder) => {
            const newType = {
              title: builder.name,
              value: builder.id
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'project') {
          const types = [{title: 'Todos', value: 'all'}];
          projects.forEach((project) => {
            const newType = {
              title: project.name,
              value: project.id
            };
            types.push(newType);
          });
          filter.types = types;
        }
        return filter;
      });
    }
    this.setState({filterOptions: defaultFilters});
  }

  render() {
    const {isLoading, dataLength, commissions, isLoadingProjects, isLoadingBuilders} = this.props;
    const {title, filterOptions, filterChips} = this.state;
    const data = {
      rows: commissions,
      cols: commisionsPayCols
    };
    const handlers = {
      onOrder: this.onOrder,
      onFilter: this.handleFilter,
      onSearch: this.handleSearch,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      onChangeStatus: this.handleChangeStatus
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: true,
        options: filterOptions,
        chips: filterChips,
        by: this.props.filters
      }
    };
    if (isLoading || isLoadingBuilders || isLoadingProjects) {
      return (
        <CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}} />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        <EnhancedTable 
          title={title}
          data={data} 
          handlers={handlers}
          config={config}
        />  
      </div>
    );
  }
}

PayCommissions.propTypes = {
  commissions: PropTypes.array.isRequired,
  builders: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  filterCommissions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingProjects: PropTypes.bool.isRequired,
  isLoadingBuilders: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  orderBy: PropTypes.string.isRequired,
  error: PropTypes.object,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  searchText: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  getProjects: PropTypes.func.isRequired,
  patchCommission: PropTypes.func.isRequired,
  getBuilders: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {commissions, builders, projects} = state;
  return { 
    commissions: commissions.commissions,
    isLoadingBuilders: builders.sending,
    isLoadingProjects: projects.sending,
    builders: builders.builders,
    projects: projects.projects,
    isLoading: commissions.sending, 
    order: commissions.order,
    orderBy: commissions.orderBy,
    searchText: commissions.searchText,
    page: commissions.page,
    rowsPerPage: commissions.rowsPerPage,
    dataLength: commissions.dataLength, 
    error: commissions.error,
    message: commissions.message,
    filters: commissions.filters
  };
};
  
const mapDispatchToProps = {
  getBuilders,
  getProjects,
  patchCommission,
  filterCommissions
};
  
export default connect(mapStateToProps, mapDispatchToProps)(PayCommissions);
