import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import {TableHead} from '@material-ui/core';
import EnhancedTableToolbar from './enhancedTableToolbar';
import {styles} from './styles';

export class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.renderToolBar = this.renderToolBar.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderThumbsUp = this.renderThumbsUp.bind(this);
    this.renderThumbsDown = this.renderThumbsDown.bind(this);
  }

  componentWillMount() {

  }

  createSortHandler = property => (event) => {
    this.handleRequestSort(event, property);
  };

  displayedRows = (info) => {
    return (
      <div>{`${info.from} - ${info.to} de ${info.count}`}  </div>
    );
  }

  handleRequestSort(event, property) {
    const {orderBy, orderDirection} = this.props;
    const newOrderBy = property;
    let newOrderDirection = 'desc';
    if (orderBy === property && orderDirection === 'desc') {
      newOrderDirection = 'asc';
    }
    this.props.onOrder(newOrderDirection, newOrderBy);
  }

  handleChangePage(event, page) {
    this.props.handleChangePage(page);
  }

  handleChangeRowsPerPage(event) {
    this.props.handleChangeRowsPerPage(event.target.value);
  }

  renderToolBar() {
    const {title} = this.props;
    return (
      <EnhancedTableToolbar 
        title={title} 
      />
    );
  }

  renderHeader() {
    const {data, classes} = this.props;
    const by = this.props.orderBy;
    const direction = this.props.orderDirection;
    const {cols} = data;
    return (
      <TableHead>
        <TableRow>
          {cols.map(col => (
            <TableCell
              key={col.id}
              align={col.right ? 'right' : 'left'}
              sortDirection={by === col.id ? direction : false}
              className={col.type === 'rating' ? classes.rating : classes.tableHeaderCell}
              style={col.type === 'rating' ? {padding: 0} : {width: 'auto'}}
            >
              {
                col.id === 'builder' || col.id === 'project' || col.id === 'unit' || col.id === 'lead' || col.id === 'pointsInFavor' || col.id === 'pointsAgainst' ?
                  <Tooltip
                    title="Ordenar"
                    placement={col.right ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={by === col.id}
                      direction={direction}
                      onClick={this.createSortHandler(col.id)}
                    >
                      {col.label}
                    </TableSortLabel>

                  </Tooltip>
                  :
                  <div style={{width: 150}}>
                    {col.label}
                  </div>
              }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  renderThumbsUp = (value) => {
    return (
      <Rating
        emptySymbol={<IconButton style={{padding: 0}}> <ThumbUp style={{margin: 2, color: '#757575'}} /></IconButton>}
        fullSymbol={<IconButton style={{padding: 0}}><ThumbUp style={{margin: 2, color: '#00AF9A'}} /></IconButton>}
        initialRating={value}
        style={{overflow: 'hidden', marginRight: 10, '& :hover': {cursor: 'default'}}}
        readonly
        quiet

      />
    );
  }

  renderThumbsDown = (value) => {
    return (
      <Rating
        emptySymbol={<IconButton style={{padding: 0}}> <ThumbDown style={{margin: 2, color: '#757575'}} /></IconButton>}
        fullSymbol={<IconButton style={{padding: 0}}><ThumbDown style={{margin: 2, color: '#FF8C00'}} /></IconButton>}
        initialRating={value}
        style={{overflow: 'hidden', marginRight: 10, '& :hover': {cursor: 'default'}}}
        readonly
        quiet
      />
    );
  }

  renderTable() {
    const {classes, data, dataLength, rowsPerPage, page} = this.props;
    const {rows} = data;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataLength - (page * rowsPerPage));
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          {this.renderHeader()}
          <TableBody>
            { rows.map((row, index) => {
              const pros = row.unit.pros[row.unit.pros.length - 1];
              const cons = row.unit.cons[row.unit.cons.length - 1];
              return (
                <TableRow key={index}>
                  <TableCell >{row.project.builder}</TableCell>
                  <TableCell >{row.project.name}</TableCell>
                  <TableCell >{row.unit.name}</TableCell>
                  <TableCell >{row.lead.user.name} {row.lead.user.lastname}</TableCell>
                  <TableCell className={classes.rating}>{pros ? this.renderThumbsUp(pros.value) : ''}</TableCell>
                  <TableCell >{pros ? pros.comment : ''}</TableCell>
                  <TableCell className={classes.rating}>{cons ? this.renderThumbsDown(cons.value) : ''}</TableCell>
                  <TableCell >{cons ? cons.comment : ''}</TableCell>
                </TableRow>
              );  
            })
            }


            {emptyRows > 0 && (
              <TableRow style={{height: 49 * emptyRows}}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
          component="div"
          count={dataLength}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Mostrar:"
          labelDisplayedRows={this.displayedRows}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.paper}>
        {this.renderToolBar()}
        {this.renderTable()}
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  orderDirection: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  onOrder: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  data: PropTypes.shape({
    rows: PropTypes.array.isRequired,
    cols: PropTypes.array.isRequired
  })
};

export default ((withStyles)(styles)(EnhancedTable));
