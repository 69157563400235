import * as whatsappTemplatesActions from '../actions/types/whatsappTemplates';

const initialState = {
  whatsappTemplates: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {whatsappTemplates, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case whatsappTemplatesActions.FILTER_WHATSAPP_TEMPLATES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case whatsappTemplatesActions.FILTER_WHATSAPP_TEMPLATES_SUCCESS:
    return {...state, sending: false, whatsappTemplates, error: null, message: 'Plantillas de mensajes de WhatsApp obtenidas satisfactoriamente', dataLength};
  case whatsappTemplatesActions.FILTER_WHATSAPP_TEMPLATES_FAILED:
    return {...state, sending: false, whatsappTemplates: [], error, message, dataLength};
  case whatsappTemplatesActions.GET_WHATSAPP_TEMPLATES_SENDING:
    return {...state, sending: true};
  case whatsappTemplatesActions.GET_WHATSAPP_TEMPLATES_SUCCESS:
    return {...state, sending: false, whatsappTemplates, error: null, message: 'Plantillas de mensajes de WhatsApp obtenidas satisfactoriamente', dataLength};
  case whatsappTemplatesActions.GET_WHATSAPP_TEMPLATES_FAILED:
    return {...state, sending: false, whatsappTemplates: [], error, message, dataLength};
  case whatsappTemplatesActions.CREATE_WHATSAPP_TEMPLATE_SENDING:
    return {...state, sending: true};
  case whatsappTemplatesActions.CREATE_WHATSAPP_TEMPLATE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Plantilla de mensaje de WhatsApp creada satisfactoriamente'};
  case whatsappTemplatesActions.CREATE_WHATSAPP_TEMPLATE_FAILED:
    return {...state, sending: false, error, message};
  case whatsappTemplatesActions.UPDATE_WHATSAPP_TEMPLATE_SENDING:
    return {...state, sending: true};
  case whatsappTemplatesActions.UPDATE_WHATSAPP_TEMPLATE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Plantilla de mensaje de WhatsApp actualizada satisfactoriamente'};
  case whatsappTemplatesActions.UPDATE_WHATSAPP_TEMPLATE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
