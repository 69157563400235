export const styles = theme => ({
    root: {
      width: '100%'
    },
    paperRoot: {
        margin: '14px 24px'
    },
    footerContained: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        marginBottom: '20px',
        marginTop: '10px',
      },
  });
  
  export default styles;
  