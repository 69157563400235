import 'moment/locale/es';
import React, { Component } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import store from './store';
import Router from './router';
import { theme } from './utils/theme';

moment.locale('es');

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <HttpsRedirect>
        <Provider store={store}>
          <SnackbarProvider maxSnack={4} preventDuplicate>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale="es"
              moment={moment}
            >
              <MuiThemeProvider theme={theme}>
                <Router />
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </Provider>
      </HttpsRedirect>
    );
  }
}
