import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ConfirmationDialog from '../../common/confirmationDialog';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const CloneDialog = (props) => {
  const {
    classes,
    closeDialog,
    onCloneProject,
    projectId,
    companyWithBuilders,
  } = props;

  const [openedCompanies, setOpenedCompanies] = useState([]);
  const [checkedBuilder, setCheckBuilder] = useState(null);

  const handleCheckBuilder = (builder) => {
    if (checkedBuilder !== builder) setCheckBuilder(builder);
    else setCheckBuilder(null);
  };

  const handleCloneProject = () => {
    const data = companyWithBuilders.reduce((acc, val) => {
      for (const builder of val.builders)
        acc.push({ companyTarget: val.id, projectId, builderId: builder.id });
      return acc;
    }, []).find(({builderId}) => builderId === checkedBuilder);

    onCloneProject(data);
  };

  const onCollapseCompanies = (company) => {
    if (openedCompanies.includes(company))
      setOpenedCompanies(openedCompanies.filter((item) => item !== company));
    else setOpenedCompanies([...openedCompanies, company]);
  };

  const renderCompanies = () => {
    return (
      <List component="nav" className={classes.companyPadding}>
        {companyWithBuilders.map((company, companyIndex) => {
          return (
            <div key={companyIndex}>
              <ListItem button onClick={() => onCollapseCompanies(company.id)}>
                <ListItemText
                  inset
                  className={classes.companyText}
                  primary={company.name}
                />
                {openedCompanies.includes(company.id) ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={openedCompanies.includes(company.id)}
                timeout="auto"
                unmountOnExit
              >
                <List component="div">
                  {company.builders.map((builder, builderIndex) => {
                    return (
                      <ListItem
                        button
                        className={`${classes.companyPadding} listPadding`}
                        key={builderIndex}
                        button
                        onClick={() => handleCheckBuilder(builder.id)}
                      >
                        <Checkbox
                          checked={checkedBuilder === builder.id}
                          color="secondary"
                        />
                        <ListItemText inset primary={builder.name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </div>
          );
        })}
      </List>
    );
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
      open
      onClose={closeDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>Elija la constructora de destino</DialogTitle>
      <DialogContent style={{ padding: '20px 50px' }}>
        {renderCompanies()}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancelar
        </Button>
        <div className={classes.submitContainer}>
          <ConfirmationDialog
            confirmationText="¿Está seguro que desea clonar este proyecto? "
            buttonText="Clonar Proyecto"
            variant="contained"
            isDisabled={!checkedBuilder}
            confirmAction={handleCloneProject}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

CloneDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onCloneProject: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  companyWithBuilders: PropTypes.array.isRequired,
};

export default withStyles(styles)(CloneDialog);
