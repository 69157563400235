import {emphasize} from '@material-ui/core/styles/colorManipulator';

export const styles = theme => ({
  container: {
    width: '95%',
    minHeight: '100vh',
    backgroundColor: '#fff',
    margin: '0 auto',
    marginTop: 20,
    padding: 20
  },
  dashboardContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '5px 10px 5px 10px'
    },
    margin: 5
  },
  funnelContainer: {
    minHeight: 700,
    marginBottom: 50
  },
  rankingContainer: {
    display: 'grid',
    gridTemplateColumns: '32% 32% 32%',
    gridGap: '2%',
    marginTop: 20
  },
  rankingItem: {
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid gray'
  },
  valueItem: {
    fontSize: '2rem',
    margin: 0
  },
  textItem: {
    fontSize: 12,
    margin: 0
  },
  profileContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      gridGap: '1rem'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  profileItem: {
    marginTop: 20
  },
  profileDescription: {
    display: 'flex',
    marginTop: 20
  },
  profileRightDescription: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20
  },
  selectsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: 20
  },
  select: {
    marginRight: 30,
    marginBottom: 20,
    minWidth: 180
  },
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: 'flex',
    padding: 0,
    width: 200
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  thumbUp: {
    color: '#00AF9A',
    fontSize: 100,
    display: 'inline-block',
    verticalAlign: 'top'
  },
  thumbDown: {
    color: '#FF8C00',
    fontSize: 100,
    display: 'inline-block',
    verticalAlign: 'top'
  },
  comment: {
    color: '#949494'
  },
  commentContainer: {
    display: 'inline-block',
    marginLeft: 10,
    verticalAlign: 'top'
  },
  more: {
    textDecoration: 'underline',
    textAlign: 'right',
    overflow: 'hidden'
  },
  containerComments: {
    display: 'inline-block',
    width: '49%',
    verticalAlign: 'top'
  }
});

export default styles;
