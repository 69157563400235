import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import CustomSnackbar from '../common/customSnackbar';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FiltersDashboard from './filtersDashboard';
import { styles } from './styles';
import Pipeline from './pipeline';
import Interactions from './interactions';
import LeadsResume from './leadsResume';
import Kpis from './kpis';
import { getAsesors, getUser } from '../../actions/users';
import CONSTANTS from '../../utils/constants';
import { getDashboard } from '../../actions/dashboard';
import TasksDashboard from './tasksDashboard';
import { ASESOR_ID, ASESOR_JR_ID, COMERCIAL_MANAGER } from '../../utils';

const profile = JSON.parse(localStorage.getItem('profile'));
const user = localStorage.getItem('user');

let flag = true;

const getViewPermission = () => {
  if (profile.permissions.view_dashboard) {
    return true;
  }
  window.location.href = '/';
  return false;
};

const PipelineDashboard = (props) => {
  const title = 'Dashboard';

  const { classes } = props;

  const dispatch = useDispatch();

  const {
    dashboard: { kpis, indicators, activityIndicators, pipelineAnalysis, funnel },
    filters,
    sending,
  } = useSelector((state) => state.dashboard);
  const {
    user:localUser,
  } = useSelector((state) => state.users);

  const { users } = useSelector((state) => state.users);

  useEffect(() => {
    console.log("entro ");
    if (profile._id === CONSTANTS.ADMIN_ROL_ID || profile._id === COMERCIAL_MANAGER ) dispatch(getAsesors());
    console.log("paso es admin ", profile._id === CONSTANTS.ADMIN_ROL_ID);
    if (profile._id === COMERCIAL_MANAGER) dispatch(getUser(user));
    console.log("paso es comercial ", profile._id === COMERCIAL_MANAGER);
  }, []);

  if (((profile._id !== CONSTANTS.ADMIN_ROL_ID &&  profile._id !== COMERCIAL_MANAGER) || users.length) && flag) {
    flag = false;
    if(profile._id === COMERCIAL_MANAGER){
      console.log("localUser", localUser);
      const filterUsers= users.filter((userItem) => localUser.advisers.includes(userItem.id))
      dispatch(
        getDashboard(
          { ...filters, brokers: filterUsers.length ? filterUsers.map(({ _id }) => _id) :[null] },
          filters.brokers,
        ),
      );
    }
    else if(profile._id === ASESOR_ID || profile._id === ASESOR_JR_ID){
      dispatch(
        getDashboard(
          { ...filters, brokers: [user] },
          filters.brokers,
        ),
      );
        }
    else{
      const adminUsers = users.filter(allUser=> allUser.is_asesor && allUser.active )
    dispatch(
      getDashboard(
        { ...filters, brokers: adminUsers.length ? adminUsers.map(({ _id }) => _id) : [user] },
        filters.brokers,
      ),
    );
      }
  }

  const renderFilter = () => {
    return <div></div>;
  };

  return sending ? (
    <CircularProgress
      style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }}
    />
  ) : (
    (profile._id !== CONSTANTS.ADMIN_ROL_ID || profile._id !== COMERCIAL_MANAGER ||  users.length > 0) && (
      <div>
        <FiltersDashboard users={users} filters={filters} />
        <Kpis kpis={kpis} />

        <Grid container>
          <Grid item md={6} xs={12}>
            <TasksDashboard brokers={filters.brokers} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Pipeline pipelineAnalysis={pipelineAnalysis} funnel={funnel} />
          </Grid>
        </Grid>
          <Interactions activityIndicators={activityIndicators} />

        <LeadsResume indicators={indicators} />
      </div>
    )
  );
};

export default withStyles(styles)(PipelineDashboard);
