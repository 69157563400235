import React from 'react';
import {Toolbar, Typography, Radio, FormControl, RadioGroup, FormControlLabel} from '@material-ui/core';
import {PropTypes} from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {styles} from './styles';

const DuplicateLeadsToolbar = (props) => {
  const {numSelected, classes, selectType, handleSelectType} = props;

  let title = `Se duplicarán ${numSelected} leads`;
  if (numSelected === 0) title = 'No se duplicarán leads';
  else if (numSelected === 1) title = 'Se duplicará un lead';

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {title}
      </Typography>
      <FormControl component="fieldset" className={classes.radioGroup}>
        <RadioGroup row aria-label="selectType" name="selectType1" value={selectType} onChange={handleSelectType} >
          <FormControlLabel value="all" control={<Radio />} label="Subir sin validar" className={classes.radioButton} />
          <FormControlLabel value="none" control={<Radio />} label="No duplicar, solo enviar notificaciones" className={classes.radioButton} />
          <FormControlLabel value="manual" control={<Radio />} label="Validacion manual" disabled={selectType !== 'manual'} className={classes.radioButton} />
        </RadioGroup>
      </FormControl>
    </Toolbar>
  );
};

DuplicateLeadsToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  selectType: PropTypes.string,
  handleSelectType: PropTypes.func.isRequired
};

export default withStyles(styles)(DuplicateLeadsToolbar);
