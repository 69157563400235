/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useDispatch } from 'react-redux';
import {
  getUnits,
  createUnit,
  editUnit,
  editUnitAttachments,
  setSold,
  setAvailable,
  getUnit,
  areDisposable,
} from '../../actions/units';
import { getParametersByCategory } from '../../actions/parameters';
import { getBrokerLeads, onAddLeadMatch } from '../../actions/leads';
import { getPropertyTypes } from '../../actions/propertyTypes';
import { getBuilders } from '../../actions/builders';
import { getProjects } from '../../actions/projects';
import { getStages } from '../../actions/stages';
import { getZones } from '../../actions/zones';
import { getCompanies } from '../../actions/companies';
import { editUnitsCols, newUnitsCols, unitsColsAdmin, unitsColsAsesor } from '../../utils/colTypes';
import { unitsOptions } from '../../utils/filterTypes';
import EnhancedTable from '../common/enhancedTable';
import Autocomplete from '../common/enhancedTable/enhancedTableRow/enhancedTableCell/autocomplete';
import CustomSnackbar from '../common/customSnackbar';
import Attributes from './attributes';
import Attachments from './attachments';
import {
  copyObject,
  sortObjectByProperty,
  SUPER_ADMIN_ROL_ID,
  isEmptyObject,
  getExcelData,
  toCamelCase,
} from '../../utils/utils';
import { GET_UNIT_SUCCESS } from '../../actions/types/units';
import { ADD_LEAD_MATCH_SUCCESS } from '../../actions/types/leads';
import SalesProfile from '../saleProfile';
import { getFormattedZones } from '../../actions/zones';
import { uploadTrivoSmartSupply, getTrivoSmartSupplyVersions } from '../../actions/smart';
import UploadTrivoSmartModal from './uploadTrivoSmartModal';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const company = JSON.parse(localStorage.getItem('company'));
export class Units extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unit: null,
      attributes: false,
      title: 'Unidades',
      attachments: false,
      filterChips: [],
      filterOptions: copyObject(unitsOptions),
      openModalWithLeads: false,
      openBadFixModal: false,
      openModalRedirect: false,
      unitToAdd: null,
      leadToAdd: '',
      isDisposable: true,
      openDialog: false,
      renderMock: false,
      isLoadingUpload: false,
      trivoSmartFile: null,
    };
    this.isLoading = this.isLoading.bind(this);
    this.handleAttributes = this.handleAttributes.bind(this);
    this.handleAttach = this.handleAttach.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateAttachments = this.handleUpdateAttachments.bind(this);
    this.handleUpdateAnswer = this.handleUpdateAnswer.bind(this);
    this.handleEditAttributes = this.handleEditAttributes.bind(this);
    this.handleEditDescription = this.handleEditDescription.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSetSold = this.handleSetSold.bind(this);
    this.handleSetAvailable = this.handleSetAvailable.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleAddToLead = this.handleAddToLead.bind(this);
    this.handleCloseLeadsModal = this.handleCloseLeadsModal.bind(this);
    this.handleOpenRedirectModal = this.handleOpenRedirectModal.bind(this);
    this.handleCloseRedirectModal = this.handleCloseRedirectModal.bind(this);
    this.handleRedirectToLead = this.handleRedirectToLead.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCols = this.handleCols.bind(this);
    this.handleDisplayLeads = this.handleDisplayLeads.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.renderDisposableSnackBar = this.renderDisposableSnackBar.bind(this);
    this.renderRedirectModal = this.renderRedirectModal.bind(this);
    this.renderExtras = this.renderExtras.bind(this);
    this.renderLeadModal = this.renderLeadModal.bind(this);
    this.filtersAttributes = this.filtersAttributes.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
    this.handleChangeLead = this.handleChangeLead.bind(this);
    this.setUnit = this.setUnit.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.renderMockup = this.renderMockup.bind(this);
    this.handleCloseBadFix = this.handleCloseBadFix.bind(this);
    this.renderBadFixDialog = this.renderBadFixDialog.bind(this);
    this.handleUploadTrivoSmartOffers = this.handleUploadTrivoSmartOffers.bind(this);
    this.onUploadTrivoSmartOffers = this.onUploadTrivoSmartOffers.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.getParametersByCategory('unit');
    this.props.getProjects();
    // this.props.getZones();
    this.props.getPropertyTypes();
    this.props.getBuilders();
    this.props.getStages();
    this.props.getCompanies();
    this.props.getFormattedZones('cities', {}, company && company._id ? company._id : null);
    this.props.getUnits(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );

    if (JSON.parse(localStorage.getItem('isBroker')) === true) {
      this.props.getBrokerLeads();
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.renderFilters();
    }
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_units || profile.permissions.view_zone_units) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  };

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_units) {
        return true;
      }
    }
    return false;
  };

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_units) {
        return true;
      }
    }
    return false;
  };

  setUnit(unitId, attachments, attributes) {
    this.props.getUnit(unitId).then((result) => {
      if (result.type === GET_UNIT_SUCCESS) {
        const { unit } = result.payload;
        unit._id = unit.id;
        this.setState({ unit, attachments, attributes });
      }
    });
  }

  isLoading() {
    const { isLoadingUnits, isLoadingLeads, isLoadingZones } = this.props;
    const { isLoadingUpload } = this.state;
    if (isLoadingUnits || isLoadingLeads || isLoadingZones || isLoadingUpload) {
      return true;
    }
    return false;
  }

  handleCols = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const profileId = localStorage.getItem('profileId');
    if (profile) {
      if (JSON.parse(localStorage.getItem('isBroker')) === true) {
        return unitsColsAsesor;
      }
    }
    let cols = unitsColsAdmin;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      cols = unitsColsAdmin.filter((col) => !['companyName', 'companyId'].includes(col.id));
    }
    return cols;
  };

  handleAttach(unit) {
    this.setUnit(unit.id, true, false);
  }

  handleDisplayLeads(unit) {
    if (JSON.parse(localStorage.getItem('isBroker')) === true) {
      this.setState({
        openModalWithLeads: true,
        unitToAdd: unit,
        openModalRedirect: false,
      });
    }
  }

  handleCloseLeadsModal() {
    this.setState({ openModalWithLeads: false });
  }

  handleOpenRedirectModal() {
    this.setState({ openModalWithLeads: false, openModalRedirect: true });
  }

  handleCloseRedirectModal() {
    this.setState({ openModalWithLeads: false, openModalRedirect: false });
  }

  handleRedirectToLead() {
    const { leadToAdd } = this.state;
    if (leadToAdd) {
      window.location.href = `/leads/${leadToAdd}`;
    }
  }

  handleAddToLead() {
    const { leads } = this.props;
    const { unitToAdd, leadToAdd } = this.state;

    const getLead = leads.find((item) => item.id === leadToAdd);
    if (
      getLead &&
      getLead.budgets.length > 0 &&
      getLead.unitTypes.length > 0 &&
      getLead.zones.length > 0
    ) {
      this.props.onAddLeadMatch(leadToAdd, unitToAdd.id, unitToAdd.projectId).then((result) => {
        if (result.type === ADD_LEAD_MATCH_SUCCESS) {
          this.handleOpenRedirectModal();
        }
      });
    } else {
      this.handleCloseLeadsModal(true);
      this.setState({ openBadFixModal: true });
    }
  }

  handleSetSold(id) {
    const now = moment();
    this.props.setSold(id, now.format()).then(() => {
      this.handleGoBack();
    });
  }

  handleSetAvailable(id) {
    const now = moment();
    this.props.setAvailable(id, now.format()).then(() => {
      this.handleGoBack();
    });
  }

  handleAttributes(unit) {
    this.setUnit(unit.id, false, true);
  }

  handleEditDescription(e) {
    const description = e.target.value;
    const { unit } = this.state;
    unit.description = description;
    this.setState({ unit });
  }

  // eslint-disable-next-line consistent-return
  async handleUpdate(unit) {
    const isDisposable = await areDisposable(unit.id);
    const editedUnit = copyObject(unit);
    const newCompany = this.props.projects.find((x) => x.id === editedUnit.projectId).company;
    let company = editedUnit.companyId === '' ? null : editedUnit.companyId;
    if (newCompany !== company) {
      if (!isDisposable) {
        return this.setState({ isDisposable: false });
      }
      company = newCompany;
    }

    const unitToEdit = {};
    unitToEdit.project = editedUnit.projectId;
    unitToEdit.name = editedUnit.name;
    unitToEdit.type = editedUnit.typeId;
    unitToEdit.stage = editedUnit.stageId;
    unitToEdit.area = editedUnit.area;
    unitToEdit.price = editedUnit.price;
    unitToEdit.active = editedUnit.active;
    unitToEdit.company = company;
    const { id } = editedUnit;
    this.props.editUnit(id, unitToEdit).then(() => {
      this.props.getUnits(
        this.props.page,
        this.props.rowsPerPage,
        this.props.order,
        this.props.orderBy,
        this.props.searchText,
        this.props.filters,
      );
    });
  }

  handleUpdateAnswer(attributeId, answerId, answer) {
    const { unit } = this.state;
    if (!unit.attributes) {
      unit.attributes = {};
    }
    unit.attributes[attributeId] = {
      answerId,
      answer,
    };
    this.setState({ unit });
  }

  handleEditAttributes() {
    const { unit } = this.state;
    const { parameters } = this.props;
    const { id } = unit;
    const notUpdating = parameters.filter((obj) => obj.is_catalog === true || obj.active === false);
    const { attributes } = unit;
    if (notUpdating.length > 0) {
      notUpdating.forEach((value) => {
        if (attributes[value.identifier]) {
          delete attributes[value.identifier];
        }
      });
    }
    const identifiers = parameters
      .filter((obj) => obj.is_catalog === false || obj.active === true)
      .map((item) => item.identifier);
    Object.entries(attributes).forEach((attribute) => {
      const identifier = attribute[0];
      if (!identifiers.includes(identifier)) {
        delete attributes[identifier];
      }
    });
    const edit = {
      attributes,
      description: unit.description,
    };
    this.props.editUnit(id, edit);
  }

  handleAdd(unit) {
    const newUnit = unit;
    if (!newUnit.attributes) {
      const { parameters } = this.props;
      const filterParams = parameters.filter(
        (obj) => obj.is_catalog === false && obj.active === true,
      );
      if (filterParams) {
        const attributes = {};
        filterParams.forEach((element) => {
          attributes[element.identifier] = {
            answerId: [],
            answer: '',
          };
        });
        newUnit.attributes = attributes;
      }
    }
    if (!isEmptyObject(newUnit.attributes)) {
      const company = this.props.projects.find((x) => x.id === newUnit.projectId).company;
      const unitToAdd = {};
      unitToAdd.name = newUnit.name;
      unitToAdd.description = '';
      unitToAdd.type = newUnit.typeId;
      unitToAdd.stage = newUnit.stageId;
      unitToAdd.area = newUnit.area;
      unitToAdd.project = newUnit.projectId;
      unitToAdd.price = newUnit.price;
      unitToAdd.attributes = newUnit.attributes;
      unitToAdd.company = company || null;
      this.props.createUnit(unitToAdd).then(() => {
        this.props.getUnits(
          this.props.page,
          this.props.rowsPerPage,
          this.props.order,
          this.props.orderBy,
          this.props.searchText,
          this.props.filters,
        );
      });
    } else {
      this.setState({ openDialog: true });
    }
  }

  handleUpdateAttachments(attachments) {
    const { unit } = this.state;
    this.props.editUnitAttachments(unit.id, attachments).then(() => {
      this.setUnit(unit.id, true, false);
    });
  }

  handleCloseDialog() {
    this.setState({ openDialog: false });
    this.props.getUnits(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleCloseBadFix() {
    this.setState({ openBadFixModal: false });
  }

  async handleUploadTrivoSmartOffers(event) {
    this.setState({ trivoSmartFile: event.target.files[0] });
  }

  async onUploadTrivoSmartOffers(version) {
    this.setState({ isLoadingUpload: true });
    const [array] = await getExcelData(this.state.trivoSmartFile);
    
    this.setState({ trivoSmartFile: null });

    const data = array.map((item) => {
      const obj = {};
      for (const key in item) {
        let value = item[key].toString();
        if (['SI', 'S'].includes(value.toUpperCase())) value = true;
        else if (['NO', 'N'].includes(value.toUpperCase())) value = false;
        obj[toCamelCase(key)] = value;
      }
      return obj;
    });

    try {
      const length = await uploadTrivoSmartSupply(data, version);
      alert(`Se han agregado ${length} registros exitosamente`);
    } catch (err) {
      const { message } = err.response.data;
      if (['missingProps', 'invalidValues'].every((item) => message.includes(item))) {
        const { missingProps, invalidValues } = JSON.parse(message);

        const missingPropsData = missingProps.map(
          ({ column, row }) => `\n    Columna: ${column}, Fila: ${row}`,
        );
        const invalidValuesData = invalidValues.map(
          ({ column, row }) => `\n     Columna: ${column}, Fila: ${row}`,
        );

        const missingPropsMessages = missingPropsData.length
          ? `Error: Propiedad faltante ${missingPropsData.join('')}  \n\n`
          : '';
        const invalidValuessMessages = invalidValuesData.length
          ? `Error: Valor no Válido ${invalidValuesData.join('')}`
          : '';

        alert(`${missingPropsMessages} ${invalidValuessMessages}`);
      } else alert('Ha habido un error en la carga de datos');
    }

    this.setState({ isLoadingUpload: false });
  }

  filtersAttributes = (allFilters) => {
    console.log('allFilters:', allFilters);
    const attributes = [];
    for (let i = 0; i < Object.entries(allFilters).length; i += 1) {
      const filter = Object.entries(allFilters)[i];
      if (
        filter[0] !== 'active' &&
        filter[0] !== 'project' &&
        filter[0] !== 'type' &&
        filter[0] !== 'price' &&
        filter[0] !== 'area' &&
        filter[0] !== 'stage' &&
        filter[0] !== 'builder' &&
        filter[0] !== 'zone' &&
        filter[0] !== 'company' &&
        filter[1] !== ''
      ) {
        let query = '';
        if (filter[1].type === 'select') {
          if (filter[1].value.length > 0) {
            query = `attributes.${filter[0]}.answerId`;
            const $in = {
              $in: filter[1].value,
            };
            attributes.push({
              [query]: $in,
            });
          }
        } else {
          query = `attributes.${filter[0]}.answer`;
          attributes.push({
            [query]: filter[1].value,
          });
        }
      } else {
        const defaultFilter = {
          [filter[0]]: filter[1].value,
        };
        attributes.push(defaultFilter);
      }
    }
    const filterToApply = {};
    if (attributes.length > 0) {
      for (let i = 0; i < attributes.length; i += 1) {
        const attribute = attributes[i];
        const attributeObject = Object.entries(attribute);
        const key = attributeObject[0][0];
        const value = attributeObject[0][1];
        if (value !== '') {
          filterToApply[key] = value;
        }
      }
    }
    const preFilters = Object.entries(filterToApply).filter((obj) => obj[1] !== '');
    const filters = {};
    for (let i = 0; i < preFilters.length; i += 1) {
      const preFilter = preFilters[i];
      if (preFilter[1].value) {
        if (Array.isArray(preFilter[1].value)) {
          if (preFilter[1].value.length > 0) {
            filters[preFilter[0]] = preFilter[1].value;
          }
        } else {
          filters[preFilter[0]] = preFilter[1].value;
        }
      } else if (Array.isArray(preFilter[1])) {
        if (preFilter[1].length > 0) {
          filters[preFilter[0]] = preFilter[1];
        }
      } else {
        filters[preFilter[0]] = preFilter[1];
      }
    }
    console.log('filters on FILTERS:', filters);
    return filters;
  };

  handleFilter(filters, filterChips) {
    console.log('filters, filterChips:', filters, filterChips);
    const filtersToApply = this.filtersAttributes(filters);
    this.setState({ filterChips });
    this.props.getUnits(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      filtersToApply,
    );
  }

  handleSearch(searchText) {
    let { filters } = this.props;
    if (searchText === null) {
      filters = {};
      this.setState({ filterChips: [] });
    }
    this.props.getUnits(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      searchText,
      filters,
    );
  }

  handleOrder(order, orderBy) {
    this.props.getUnits(
      this.props.page,
      this.props.rowsPerPage,
      order,
      orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleGoBack() {
    // eslint-disable-next-line react/no-unused-state
    this.setState({
      unit: null,
      isNew: false,
      cols: this.handleCols(),
      attributes: false,
      attachments: false,
    });
    this.props.getUnits(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.getUnits(
      this.props.page,
      rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleChangePage(page) {
    this.props.getUnits(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleChangeLead(leadToAdd) {
    this.setState({ leadToAdd });
  }

  renderAsesorLeads() {
    const { leads } = this.props;
    const { leadToAdd } = this.state;
    if (leads.length > 0) {
      return (
        <FormControl style={{ width: '100%' }}>
          <Autocomplete
            suggestions={leads
              .filter((lead) => lead.status !== 'Perdido' && lead.status !== 'Ganado')
              .map((lead) => ({
                value: lead._id,
                label: lead.complete_name,
              }))}
            value={leadToAdd}
            handleChangeCell={this.handleChangeLead}
            multiple={false}
          />
          <FormHelperText>Leads</FormHelperText>
        </FormControl>
      );
    }
    return null;
  }

  renderSnackBar() {
    const { error, message } = this.props;
    let variant = 'success';
    if (error !== null) {
      variant = 'error';
    }
    if (message) {
      return <CustomSnackbar variant={variant} message={message} />;
    }
    return null;
  }

  renderDisposableSnackBar() {
    const { isDisposable } = this.state;
    if (!isDisposable) {
      this.setState({ isDisposable: true });
      return (
        <CustomSnackbar
          variant="error"
          message="No se puede cambiar el proyecto porque la unidad tiene leads dependientes"
        />
      );
    }
    return null;
  }

  renderFilters() {
    const { projects, propertyTypes, stages, parameters, builders, zones, companies } = this.props;
    console.log('parameters:', parameters);
    let defaultFilters = [];
    let parameterFilters = [];
    let filterOptions = [];
    const profileId = localStorage.getItem('profileId');
    if (
      projects.length > 0 &&
      propertyTypes.length > 0 &&
      stages.length > 0 &&
      parameters.length > 0 &&
      companies.length > 0
    ) {
      let options = copyObject(unitsOptions);
      if (SUPER_ADMIN_ROL_ID !== profileId) {
        options = options.filter((option) => option.id !== 'company');
      }
      defaultFilters = options.map((item) => {
        const filter = item;
        if (filter.id === 'project') {
          const types = [{ title: 'Todos', value: 'all' }];
          const sortProjects = sortObjectByProperty(projects, 'name');
          sortProjects.forEach((project) => {
            const newType = {
              title: project.name,
              value: project.id,
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'type') {
          const types = [{ title: 'Todos', value: 'all' }];
          propertyTypes.forEach((propertyType) => {
            const newType = {
              title: propertyType.name,
              value: propertyType.id,
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'stage') {
          const types = [{ title: 'Todos', value: 'all' }];
          stages.forEach((stage) => {
            const newType = {
              title: stage.name,
              value: stage.id,
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'builder') {
          const types = [{ title: 'Todos', value: 'all' }];
          builders.forEach((builder) => {
            const newType = {
              title: builder.name,
              value: builder.id,
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'company') {
          const types = [{ title: 'Todos', value: 'all' }];
          companies.forEach((company) => {
            const newType = {
              title: company.name,
              value: company.id,
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'zone') {
          const types = [{ title: 'Todos', value: 'all' }];
          const profile = JSON.parse(localStorage.getItem('profile'));
          const { permissions } = profile;
          if (permissions) {
            if (permissions.view_zone_units === true) {
              const userZones = JSON.parse(localStorage.getItem('zones'));
              zones
                .filter((obj) => profile.name === 'Admin' || userZones.includes(obj.id))
                .forEach((zone) => {
                  const newType = {
                    title: zone.name,
                    value: zone.id,
                  };
                  types.push(newType);
                });
              filter.types = types;
            } else {
              zones.forEach((zone) => {
                const newType = {
                  title: zone.name,
                  value: zone.id,
                };
                types.push(newType);
              });
              filter.types = types;
            }
          } else {
            zones.forEach((zone) => {
              const newType = {
                title: zone.name,
                value: zone.id,
              };
              types.push(newType);
            });
            filter.types = types;
          }
        }
        return filter;
      });
    }

    console.log('defaultFilters:', defaultFilters)
    if (parameters.length > 0) {
      const clearParams = parameters
        .filter((obj) => {
          if (obj.is_catalog === false && obj.active === true) {
            if (obj.options.is_multiple === true && obj.options.content) {
              if (obj.options.content.length > 0) {
                return obj;
              }
            } else if (obj.input_type === 'yes_no') {
              return obj;
            } else if (obj.input_type === 'text_box') {
              return obj;
            } else if (obj.input_type === 'date') {
              return obj;
            } else if (obj.input_type === 'range') {
              return obj;
            }
          }
          return null;
        })
        .filter((obj) => obj !== null);
      const sortParams = sortObjectByProperty(clearParams, 'name');
      parameterFilters = sortParams
        .map((parameter) => {
          let parameterTypes = [];
          if (parameter.input_type === 'yes_no') {
            return {
              id: parameter.identifier,
              label: parameter.name,
              types: [
                { title: 'Todos', value: 'all' },
                { title: 'Si', value: 'Si' },
                { title: 'No', value: 'No' },
              ],
            };
          } else if (parameter.input_type === 'text_box') {
            return {
              id: parameter.identifier,
              label: parameter.name,
              types: [],
              type: 'text',
            };
          } else if (parameter.input_type === 'date') {
            return {
              id: parameter.identifier,
              label: parameter.name,
              types: [],
              type: 'date',
            };
          } else if (parameter.input_type === 'range') {
            return {
              id: parameter.identifier,
              label: parameter.name,
              types: [],
              type: 'numberRange',
            };
          } else if (parameter.options.content.length > 0) {
            parameterTypes = parameter.options.content.map((item) => {
              return {
                title: item.value,
                value: item.answerId,
              };
            });
            const filter = {
              id: parameter.identifier,
              label: parameter.name,
              types: [{ title: 'Todos', value: 'all' }],
            };

            if (parameterTypes.length > 0) {
              filter.types = filter.types.concat(parameterTypes);
            }
            return filter;
          }
          return null;
        })
        .filter((obj) => obj !== null);
    }

    if (defaultFilters.length > 0) {
      filterOptions = defaultFilters.concat(parameterFilters);
      this.setState({ filterOptions });
    }
    console.log(filterOptions,"filterOptions");
  }

  renderLeadModal() {
    return (
      <Dialog
        open={this.state.openModalWithLeads}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseLeadsModal}
      >
        <DialogTitle>Escoja a qué lead desea agregar la siguiente unidad</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.renderAsesorLeads()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseLeadsModal} color="primary">
            Cancelar
          </Button>
          <Button
            disabled={!this.state.leadToAdd.length > 0}
            onClick={this.handleAddToLead}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderBadFixDialog() {
    return (
      <Dialog
        open={this.state.openBadFixModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseBadFix}
      >
        <DialogTitle>Atención</DialogTitle>
        <DialogContent>
          <DialogContentText>
            En el lead seleccionado debe especificar en las preferencias Sector, Tipo de Unidad y
            Presupuesto para poder asignarle una unidad
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseBadFix} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderRedirectModal() {
    return (
      <Dialog
        open={this.state.openModalRedirect}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseRedirectModal}
      >
        <DialogTitle>¿Desea agregar más unidades al lead?</DialogTitle>
        <DialogActions>
          <Button onClick={this.handleRedirectToLead} color="primary">
            No
          </Button>
          <Button onClick={this.handleCloseRedirectModal} color="primary">
            Si
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderExtras() {
    const { isLoadingUnits, parameters } = this.props;
    const { attachments, unit, attributes, renderMock } = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    if (attachments && !renderMock) {
      return (
        <Attachments
          unit={unit}
          isLoading={isLoadingUnits}
          onGoBack={this.handleGoBack}
          onUpdateAttachments={this.handleUpdateAttachments}
          permissions={hasEditPermission && hasCreatePermission}
        />
      );
    }
    if (attributes && !renderMock) {
      const attributesToUse = parameters
        .filter((obj) => obj.is_catalog === false && obj.active === true)
        .map((item) => {
          const attr = item;
          if (attr && attr.options && attr.options.content && attr.options.content.length > 0) {
            attr.options.content = attr.options.content.filter((x) => x.active);
          }
          return attr;
        });

      return (
        <Attributes
          unit={unit}
          isLoading={isLoadingUnits}
          onGoBack={this.handleGoBack}
          onSetSold={this.handleSetSold}
          onSetUnSold={this.handleSetAvailable}
          onUpdateAnswer={this.handleUpdateAnswer}
          attributes={attributesToUse}
          onEditAttributes={this.handleEditAttributes}
          onEditDescription={this.handleEditDescription}
          permissions={hasEditPermission && hasCreatePermission}
        />
      );
    }

    if (renderMock) {
      return <SalesProfile handleGoBack={this.handleGoBack} unit={unit} />;
    }

    return <div />;
  }

  renderMockup(unit) {
    console.log('unit:', unit);
    this.setState({ renderMock: true, unit });
  }

  render() {
    const { units, dataLength, projects, propertyTypes, stages, zones, builders } = this.props;
    const { title, unit, filterOptions, openDialog, trivoSmartFile } = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const profileId = localStorage.getItem('profileId');

    const data = {
      rows: units,
      cols: this.handleCols(),
      autoFillers: {
        project: projects,
        type: propertyTypes,
        stage: stages,
        zone: zones,
        builder: builders,
      },
      editCols: editUnitsCols,
      newCols: newUnitsCols,
    };
    const handlers = {
      onAttributes: this.handleAttributes,
      onAttach: this.handleAttach,
      onAdd: this.handleAdd,
      onAddToLead: this.handleDisplayLeads,
      onSearch: this.handleSearch,
      onOrder: this.handleOrder,
      onFilter: this.handleFilter,
      onUpdate: this.handleUpdate,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      renderMockup: this.renderMockup,
    };

    if (SUPER_ADMIN_ROL_ID === profileId) {
      handlers.onUploadTrivoSmartOffers = this.handleUploadTrivoSmartOffers;
    }

    const config = {
      isDragable: false,
      isSelectable: false,
      isEditableInline: true,
      allowAddToLead: JSON.parse(localStorage.getItem('isBroker')) === true,
      hasEditPermission,
      hasCreatePermission,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order,
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength,
      },
      filters: {
        is: true,
        options: filterOptions,
        chips: this.state.filterChips,
        by: this.props.filters,
      },
    };
    return this.isLoading() === true ? (
      <CircularProgress
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
      />
    ) : (
      <div>
        {!!trivoSmartFile && (
          <UploadTrivoSmartModal
            getVersions={getTrivoSmartSupplyVersions}
            onUpload={this.onUploadTrivoSmartOffers}
          />
        )}
        {this.renderSnackBar()}
        {this.renderSnackBar()}
        {this.renderDisposableSnackBar()}
        {this.renderLeadModal()}
        {this.renderBadFixDialog()}
        {this.renderRedirectModal()}
        {unit !== null ? (
          this.renderExtras()
        ) : (
          <EnhancedTable title={title} data={data} handlers={handlers} config={config} />
        )}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="lg"
          fullWidth
          open={openDialog}
          onClose={() => this.handleCloseDialog()}
          TransitionComponent={Transition}
        >
          <DialogTitle>
            No puede crear una unidad sin al menos un atributo de unidad activo
          </DialogTitle>
          <DialogActions>
            <Button style={{ color: 'red' }} onClick={() => this.handleCloseDialog()}>
              Cerrar
            </Button>
            <Button href="/parametros" color="primary">
              Configurar atributos de unidades
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Units.propTypes = {
  isLoadingUnits: PropTypes.bool.isRequired,
  isLoadingZones: PropTypes.bool,
  isLoadingLeads: PropTypes.bool.isRequired,
  getUnits: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  leads: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  builders: PropTypes.array.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
  parameters: PropTypes.array.isRequired,
  dataLength: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setSold: PropTypes.func.isRequired,
  onAddLeadMatch: PropTypes.func.isRequired,
  setAvailable: PropTypes.func.isRequired,
  getBrokerLeads: PropTypes.func.isRequired,
  getZones: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  getPropertyTypes: PropTypes.func.isRequired,
  getUnit: PropTypes.func.isRequired,
  getStages: PropTypes.func.isRequired,
  getBuilders: PropTypes.func.isRequired,
  getParametersByCategory: PropTypes.func.isRequired,
  editUnit: PropTypes.func.isRequired,
  editUnitAttachments: PropTypes.func.isRequired,
  error: PropTypes.object,
  createUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    units,
    projects,
    propertyTypes,
    parameters,
    stages,
    builders,
    zones,
    leads,
    companies,
  } = state;
  return {
    isLoadingBuilders: builders.sending,
    isLoadingUnits: units.sending,
    isLoadingProjects: projects.sending,
    isLoadingPropertyTypes: propertyTypes.sending,
    isLoadingParameters: parameters.sending,
    isLoadingStages: stages.sending,
    isLoadingZones: zones.sending,
    isLoadingCompanies: companies.sending,
    isLoadingLeads: leads.sending,
    leads: leads.leads,
    units: units.units,
    zones: zones.formattedZones,
    companies: companies.companies,
    builders: builders.builders,
    parameters: parameters.parameters,
    stages: stages.stages,
    projects: projects.projects,
    propertyTypes: propertyTypes.propertyTypes,
    dataLength: units.dataLength,
    message: units.message,
    error: units.error,
    page: units.page,
    rowsPerPage: units.rowsPerPage,
    order: units.order,
    orderBy: units.orderBy,
    searchText: units.searchText,
    filters: units.filters,
  };
};
const mapDispatchToProps = {
  getUnits,
  getUnit,
  getBuilders,
  createUnit,
  editUnit,
  editUnitAttachments,
  setSold,
  getParametersByCategory,
  getPropertyTypes,
  getProjects,
  getBrokerLeads,
  getZones,
  setAvailable,
  onAddLeadMatch,
  getStages,
  getCompanies,
  getFormattedZones,
};

export default connect(mapStateToProps, mapDispatchToProps)(Units);
