/* eslint-disable no-mixed-operators */
export const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  logo: {
    width: 75,
    marginBottom: 20
  },
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  progress: {
    margin: '0 auto',
    display: 'table',
    marginTop: theme.spacing.unit * 3
  },
  input: {
    marginTop: theme.spacing.unit
  },
  linkButton: {
    marginTop: 32,
    color: '#94cbd4'
  },
  linkButtonIcon: {
    fontSize: 16, 
    marginRight: 8
  }
});

export default styles;
