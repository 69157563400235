import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { styles } from './styles';

const LeadsResume = (props) => {
  const { classes, indicators } = props;

  const data = [
    {
      title: 'Contactos asignados',
      value: (indicators && indicators.assignedLeads) || 0,
      class: 'secondaryBar',
    },
    {
      title: 'Leads calientes ',
      value: (indicators && indicators.hotLeads) || 0,
      class: 'blueBar',
    },
    {
      title: 'Visitas confirmadas',
      value: (indicators && indicators.visitConfirmed) || 0,
      class: 'blackBar',
    },
    {
      title: 'Visitas realizadas',
      value: (indicators && indicators.visitsMade) || 0,
      class: 'violetBar',
    },
    {
      title: 'Negocio Ganado ',
      value: (indicators && indicators.winLeads) || 0,
      class: 'greenBar',
    },
    {
      title: 'Negocios perdidos',
      value: (indicators && indicators.lostLeads) || 0,
      class: 'redBar',
    },
  ];

  const renderLeadStatus = () => {
    return (
      <div className={classes.root2}>
        <Grid container>
          {data.map((item, index) => (
            <Grid item md={4} xs={12} key={`indicator_${index}`}>
              <div className={classes.borderedCards}>
                <div className={classes.flexCard}>
                  <div className={classes.flexCardTitle}>
                    <h5>{item.title}</h5>
                  </div>
                  <div className={classes.flexCardSubtitle}>
                    <h5>{item.value}</h5>
                  </div>
                  <div className={`${classes.itemValue} ${classes[item.class]} `}>
                    <div className={classes.barDecorator}></div>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={24}>
        <Grid item md={12} xs={12}>
          <Paper className={`${classes.paper} ${classes.cardAdjustedcardAdjusted}`}>
            <h5 className={classes.titleCard}>
              Este es el estado de tus Leads en el período seleccionado{' '}
            </h5>
            <hr></hr>
            {renderLeadStatus()}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(LeadsResume);
