import * as advanceActions from '../actions/types/advance';

const initialState = {
  advances: [],
  permissions: {},
  advance: null,
  message: '',
  dataLength: 0,
  sending: false,
  sendingPermissions: false,
  error: null,
  page: 0,
  rowsPerPage: 50,
  filters: {},
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {advances, error, message, dataLength, page, rowsPerPage, searchText, filters} = action.payload;
  switch (action.type) {
  case advanceActions.FILTER_ADVANCES_SENDING:
    return {...state, sending: true, page, rowsPerPage, searchText, dataLength, filters};
  case advanceActions.FILTER_ADVANCES_SUCCESS:
    return {...state, sending: false, advances, error: null, message: 'Avances obtenidos satisfactoriamente', dataLength};
  case advanceActions.FILTER_ADVANCES_FAILED:
    return {...state, sending: false, advances: [], error, message, dataLength};
  case advanceActions.UPDATE_ADVANCE_SENDING:
    return {...state, sending: true};
  case advanceActions.UPDATE_ADVANCE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Avance actualizado satisfactoriamente'};
  case advanceActions.UPDATE_ADVANCE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
