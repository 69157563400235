import axios from 'axios';
import * as proformasActions from './types/proformas';
import { axiosMaker, errorMessage } from '../utils/utils';

const company = JSON.parse(localStorage.getItem('company'));

export const getCompanyProformaConfig = () => async (dispatch) => {
  dispatch({
    type: proformasActions.GET_PROFORMA_COMPANY_CONFIG_SENDING,
    payload: {},
  });
  try {
    const queryString =
      (company && company._id && `?companyId=${company._id}`) || '';
    const response = await axios(
      axiosMaker(
        'GET',
        `companies/getProformaConfig${queryString}`,
        null,
        true,
      ),
    );
    return dispatch({
      type: proformasActions.GET_PROFORMA_COMPANY_CONFIG_SUCCESS,
      payload: { companyConfig: response.data },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: proformasActions.GET_PROFORMA_COMPANY_CONFIG_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updateCompanyProformaConfig = (
  id,
  proformaConfiguration,
) => async (dispatch) => {
  dispatch({
    type: proformasActions.UPDATE_PROFORMA_COMPANY_CONFIG_SENDING,
    payload: {},
  });
  try {
    await axios(
      axiosMaker('PATCH', `companies/${id}`, { proformaConfiguration }, true),
    );
    return dispatch({
      type: proformasActions.UPDATE_PROFORMA_COMPANY_CONFIG_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: proformasActions.UPDATE_PROFORMA_COMPANY_CONFIG_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const validCompanyProformaConfig = (projectId, units) => async (
  dispatch,
) => {
  dispatch({
    type: proformasActions.VALID_PROFORMA_COMPANY_CONFIG_SENDING,
    payload: {},
  });
  try {
    const response = await axios(
      axiosMaker(
        'POST',
        `proformas/validate`,
        { projectId, units },
        true,
        'proformas',
      ),
    );
    return dispatch({
      type: proformasActions.VALID_PROFORMA_COMPANY_CONFIG_SUCCESS,
      payload: {
        validProforma: true,
        currentProjectValidProforma: projectId,
        validUnits: response.data,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: proformasActions.VALID_PROFORMA_COMPANY_CONFIG_FAILED,
      payload: {
        error: true,
        currentProjectValidProforma: projectId,
      },
    });
  }
};

export const getUnitsCompanyProformaConfig = (project, unitsLocal) => async (
  dispatch,
) => {
  dispatch({
    type: proformasActions.GET_UNITS_PROFORMA_COMPANY_CONFIG_SENDING,
    payload: { units: [], defaultConfigurations: {} },
  });
  try {
    const response = await axios(
      axiosMaker(
        'POST',
        `proformas/getDefaultInfo/`,
        { project, units: unitsLocal },
        true,
        'proformas',
      ),
    );
    const { units, defaultConfigurations } = response.data;
    return dispatch({
      type: proformasActions.GET_UNITS_PROFORMA_COMPANY_CONFIG_SUCCESS,
      payload: { units, defaultConfigurations },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: proformasActions.GET_UNITS_PROFORMA_COMPANY_CONFIG_FAILED,
      payload: {
        error: true,
      },
    });
  }
};

export const createProforma = (data) => async (dispatch) => {
  dispatch({
    type: proformasActions.CREATE_PROFORMA_SENDING,
    payload: {},
  });
  try {
    const response = await axios(
      axiosMaker('POST', 'proformas', data, true, 'proformas'),
    );
    return dispatch({
      type: proformasActions.CREATE_PROFORMA_SUCCESS,
      payload: { ...response.data },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: proformasActions.CREATE_PROFORMA_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getProformas = (project, lead, broker, populateBroker) => async (
  dispatch,
) => {
  dispatch({
    type: proformasActions.GET_PROFORMAS_SENDING,
    payload: { proformas: [] },
  });
  try {
    let query = broker
      ? `project=${project}&lead=${lead}&broker=${broker}`
      : `project=${project}&lead=${lead}`;

    if (populateBroker) query = `${query}&populateBroker=true`;

    const response = await axios(
      axiosMaker('GET', `proformas?${query}`, null, true, 'proformas'),
    );
    return dispatch({
      type: proformasActions.GET_PROFORMAS_SUCCESS,
      payload: { proformas: response.data },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: proformasActions.GET_PROFORMAS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const sendEmail = (proformaId, email) => {
  return axios(
    axiosMaker(
      'POST',
      'proformas/sendEmail',
      { proformaId, email },
      true,
      'proformas',
    ),
  );
};

export const getProjectsByLead = (lead, broker) => async (dispatch) => {
  dispatch({
    type: proformasActions.GET_PROJECTS_BY_LEAD_SENDING,
    payload: {},
  });
  try {
    const query = broker ? `lead=${lead}&broker=${broker}` : `lead=${lead}`;
    const response = await axios(
      axiosMaker(
        'GET',
        `proformas/getProjectsByLead?${query}`,
        null,
        true,
        'proformas',
      ),
    );
    return dispatch({
      type: proformasActions.GET_PROJECTS_BY_LEAD_SUCCESS,
      payload: { projects: response.data },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: proformasActions.GET_PROJECTS_BY_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};
