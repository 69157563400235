export const styles = (theme) => ({
         filterSelect: {
           width: '95%',
           margin: '0 auto',
           display: 'table',
           marginBottom: theme.spacing.unit * 2,
         },
         datePicker: {
           width: '100%',
         },
         datePickerRange: {
           width: '50%',
         },
         menuItemSpan: {
           fontSize: 10,
           color: '#949494',
           marginLeft: 20,
         },
         chips: {
           display: 'flex',
           flexWrap: 'wrap',
         },
         chip: {
           margin: 2,
         },
         dialogContent: {
           paddingTop: 20,
           paddingBottom: 20,
           paddingLeft: 50,
           paddingRight: 50,
         },
         noPadding: {
           padding: '0px !important',
         },
         mapContainer: {
           height: '100%',
           width: '100%',
         },
         markerTrivo: {
           marginLeft: '-15px',
           marginTop: '-24px',
         },
         activePlace: {
           color: theme.palette.secondary.main,
           '& svg': {
             color: 'red',
           },
         },
         muiDialog: {
           height: '100%',
           width: '100%',
           paddingRight: '0px !important',
         },
         closeButton: {
           float: 'right',
         },
         buttonContainer: {
           justifyContent: 'center',
           alignItems: 'center',
           display: 'flex',
           '& button': {
             position: 'absolute',
             marginTop: '-80px',
           },
         },
       });

export default styles;
