export const styles = theme => ({
  attributesContainer: {
    width: '90%',
    margin: '0 auto'
  },
  submitContainer: {
    display: 'table',
    margin: '0 auto'
  },
  paperRoot: {
    width: '95%',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 3
  },
  questionContainer: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
});

export default styles;
