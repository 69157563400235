export const GET_ZONES_SUCCESS = 'GET_ZONE_SUCCESS';

export const GET_ZONES_SENDING = 'GET_ZONE_SENDING';

export const GET_ZONES_FAILED = 'GET_ZONE_FAILED';


export const GET_ALL_ZONES_SUCCESS = 'GET_ALL_ZONES_SUCCESS';

export const GET_ALL_ZONES_SENDING = 'GET_ALL_ZONES_SENDING';

export const GET_ALL_ZONES_FAILED = 'GET_ALL_ZONES_FAILED';


export const FILTER_ZONES_SUCCESS = 'FILTER_ZONES_SUCCESS';

export const FILTER_ZONES_SENDING = 'FILTER_ZONE_SENDING';

export const FILTER_ZONES_FAILED = 'FILTER_ZONE_FAILED';


export const CREATE_ZONE_SUCCESS = 'CREATE_ZONE_SUCCESS';

export const CREATE_ZONE_SENDING = 'CREATE_ZONE_SENDING';

export const CREATE_ZONE_FAILED = 'CREATE_ZONE_FAILED';


export const UPDATE_ZONE_SUCCESS = 'UPDATE_ZONE_SUCCESS';

export const UPDATE_ZONE_SENDING = 'UPDATE_ZONE_SENDING';

export const UPDATE_ZONE_FAILED = 'UPDATE_ZONE_FAILED';

export const GET_FORMATTED_ZONES_SUCCESS = 'GET_FORMATTED_ZONE_SUCCESS';

export const GET_FORMATTED_ZONES_SENDING = 'GET_FORMATTED_ZONE_SENDING';

export const GET_FORMATTED_ZONES_FAILED = 'GET_FORMATTED_ZONE_FAILED';
