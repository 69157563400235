import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { cancelTask, finishTask } from '../../../../actions/calendar';
import TaskModal from './taskModal';


const ActionsTask = (props) => {
  const { task, reloadData } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const open = Boolean(anchorEl);
  const { sending, messageAction, error } = useSelector((state) => state.tasks);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const finishEventAction = () => {
    dispatch(finishTask(task.id));
    reloadData();
    setAnchorEl(null);
  };

  const viewEvent = () => {
    setOpenModal(true);
  };
  const cancelEvent = () => {
    dispatch(cancelTask(task.id));
    reloadData();
    setAnchorEl(null);
  };

  const closeModal = () => {
    setOpenModal(false);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={finishEventAction}>Realizada</MenuItem>
        <MenuItem onClick={viewEvent}>Ver</MenuItem>
        <MenuItem onClick={cancelEvent}>Cancelar</MenuItem>
      </Menu>
      <TaskModal task={task} closeModal={closeModal} open={openModal} />
    </div>
  );
};

export default ActionsTask;
