export const styles = theme => ({
  content: {
    height: '100%',
    width: '100%',
    backgroundColor: '#F5F5F6',
    paddingBottom: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 8
  }
});

export default styles;
