import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {createProvince, updateProvince} from '../../../../actions/provinces';
import {getCountries} from '../../../../actions/countries';


const ProvinceDetails = (props) => {
  const [provinceDetail, setProvince] = useState({
    active: true,
    name: '',
    country: ''
  });

  const {countries} = useSelector(state => state.countries);

  const dispatch = useDispatch();

  useEffect(() => {
    const {isNew, province} = props;
    dispatch(getCountries());
    if (!isNew) {
      setProvince(province);
    }
  }, []);

  const onAddButtonClick = async () => {
    const {name, country} = provinceDetail;
    await dispatch(createProvince({name, country}));
    props.handleGoBack();
  };

  const onEditButtonClick = async () => {
    const {province} = props;
    const {name, active, country} = provinceDetail;
    await dispatch(updateProvince(province.id, {name, active, country}));
    props.handleGoBack();
  };

  const handleChangeProvince = (e) => {
    const {value, name} = e.target;
    setProvince((prevState) => {
      return {...prevState, [name]: value};
    });
  };


  const isInvalid = () => {
    const {name, country} = provinceDetail;
    if (name !== '' && country !== '') {
      return false;
    }
    return true;
  };

  const renderButton = () => {
    const {isLoading, isNew, classes} = props;
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} esta provincia?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} provincia`}
          variant="contained"
          isDisabled={isInvalid()}
          confirmAction={isNew ? onAddButtonClick : onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  };

  const renderCountries = () => {
    return countries.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
      );
    });
  }; 

  const renderRowCountry = () => {
    const {classes} = props;
    return (
      <TableRow>
        <TableCell className={classes.tableRowTitle}><b>País</b></TableCell>
        <TableCell>
          <Select
            name="country"
            value={provinceDetail.country}
            fullWidth
            onChange={handleChangeProvince}
          >
            {renderCountries()}
          </Select>
        </TableCell>
      </TableRow>
    );
  };


  const {classes, isLoading, isNew, handleGoBack} = props;
  return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
    <Paper elevation={2} className={classes.paperRoot}>
      <Toolbar>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">
          {!isNew ? 'Detalle de ' : 'Nueva '} provincia
        </Typography>
      </Toolbar>
      <div style={{width: 'auto', overflowX: 'scroll'}}>
        <Table>
          <TableBody>
            {
              !isNew &&
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Disponible</b></TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={provinceDetail.active}
                        onChange={e => setProvince((prevState) => {
                          return {...prevState, active: e.target.checked};
                        })}
                        color="primary"
                      />
                    }
                    label={provinceDetail.active === true ? 'Disponible' : 'No disponible'}
                  />
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell className={classes.tableRowTitle}><b>Nombre</b></TableCell>
              <TableCell>
                <TextField 
                  autoFocus
                  name="name"
                  value={provinceDetail.name}
                  onChange={handleChangeProvince}
                  fullWidth
                />
              </TableCell>
            </TableRow>
            {renderRowCountry()}  
          </TableBody>
        </Table>
      </div>
      <div className={classes.submitContainer}>
        {renderButton()}
      </div>
    </Paper>
  );
};

ProvinceDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  province: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired
};

export default (withStyles(styles)(ProvinceDetails));