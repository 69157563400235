import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import {withStyles} from '@material-ui/core/styles';
import {styles} from './styles';
import {isNumber} from '../../../utils/utils';

export class Range extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: 0,
      min: 0,
      max: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
  }
  
  componentWillMount() {
    const {answers, currentAnswer} = this.props;
    const min = parseFloat(answers[0].min_val);
    const max = parseFloat(answers[0].max_val);
    this.setState({min, max});
    // const calcAnswer= (max+min)/2;
    // this.setState({answer:calcAnswer})
    if (currentAnswer) {
      const {answer} = currentAnswer;
      if (answer !== '') {
        this.setState({answer});
      }
    }
  }

  validate() {
    const {answer, min, max} = this.state;
    if (answer !== '') {
      // eslint-disable-next-line radix
      if (parseInt(answer) < min || parseInt(answer) > max) {
        return true;
      }
      return false;
    }
    return true;
  }

  handleChange(event, answer) {
    const {identifier} = this.props;
    if (isNumber(answer) || answer === '') {
      this.setState({answer});
      this.props.updateAnswer(identifier, [], answer);
    }
  }

  render() {
    const {classes, permissions} = this.props;
    const {answer, min, max} = this.state;
    return (
      <div>
        <Toolbar className={classes.toolbar}>
          <Typography>Ingrese un valor desde: {min} hasta {max}</Typography>
        </Toolbar>
        <TextField
          value={answer}
          onChange={e => this.handleChange(e, e.target.value)}
          error={this.validate()}
          disabled={permissions === false}
        />
      </div>
    );
  }
}

Range.propTypes = {
  classes: PropTypes.object.isRequired,
  updateAnswer: PropTypes.func,
  permissions: PropTypes.bool,
  identifier: PropTypes.string,
  currentAnswer: PropTypes.object,
  answers: PropTypes.array.isRequired
};

export default withStyles(styles)(Range);
