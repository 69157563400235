import React, {useState, useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector, useDispatch} from 'react-redux';
import {filterLeadStages} from '../../../actions/leadStages';
import EnhancedTable from '../../common/enhancedTable';
import {leadStagesCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import LeadStageDetail from './detail';
import {getCompanies} from '../../../actions/companies';
import {getLeadStates} from './../../../actions/leadStates';
import {SUPER_ADMIN_ROL_ID} from '../../../utils/utils';

const LeadStages = (props) => {
  const title = 'Etapa de Lead';
  const {
    page,
    rowsPerPage,
    order,
    orderBy,
    leadStages,
    searchText,
    sending,
    dataLength,
    error,
    message
  } = useSelector(state => state.leadStages);
  const {companies} = useSelector(state => state.companies);
  const {leadStates} = useSelector(state => state.leadStates);

  const [leadStage, setLeadStage] = useState(null);
  const [isNew, setIsNew] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(filterLeadStages(page, rowsPerPage, order, orderBy, searchText));
    dispatch(getCompanies());
    dispatch(getLeadStates());
  }, []);

  const onSearch = (searchedText) => {
    dispatch(filterLeadStages(page, rowsPerPage, order, orderBy, searchedText));
  };

  const onAdd = () => {
    setLeadStage({});
    setIsNew(true);
  };

  const onDetail = (id) => {
    const leadStageLocal = leadStages.filter(obj => obj.id === id)[0];
    setLeadStage(leadStageLocal);
    setIsNew(false);
  };

  const handleChangeRowsPerPage = (rowsPerPageLocal) => {
    dispatch(filterLeadStages(page, rowsPerPageLocal, order, orderBy, searchText));
  };

  const handleChangePage = (pageLocal) => {
    dispatch(filterLeadStages(pageLocal, rowsPerPage, order, orderBy, searchText));
  };

  const onOrder = (orderLocal, orderByLocal) => {
    dispatch(filterLeadStages(page, rowsPerPage, orderLocal, orderByLocal, searchText));
  };

  const formatColsFromUser = (cols) => {
    const profileId = localStorage.getItem('profileId');
    let newCols = cols;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      newCols = cols.filter(col => col.id !== 'companyName');
    }
    return newCols;
  };

  const getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_profiles) {
        return true;
      }
    }
    return false;
  };

  const getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_profiles) {
        return true;
      }
    }
    return false;
  };

  const handleGoBack = () => {
    dispatch(filterLeadStages(page, rowsPerPage, order, orderBy, searchText));
    setLeadStage(null);
    setIsNew(false);
  };

  const renderSnackBar = () => {
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    } 
    return null;
  };

  const isLoading = sending;
  const hasEditPermission = getEditPermissions();
  const hasCreatePermission = getCreatePermission();
  const types = [
    {id: 'lead', name: 'Etapa de Lead'},
    {id: 'unit', name: 'Etapa de Unidad'}
  ];
  const data = {rows: leadStages.map(doc => ({
    ...doc,
    type: types.find(item => item.id === doc.type).name
  })),
  cols: formatColsFromUser(leadStagesCols)};
  const handlers = {
    onDetail,
    onAdd,
    onSearch,
    onOrder,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage
  };
  const config = {
    isDragable: false,
    isSelectable: false,
    hasEditPermission,
    hasCreatePermission,
    isFiterable: false,
    searchText: searchText || '',
    order: {
      by: orderBy,
      direction: order
    },
    pagination: {
      current: page,
      rowsPerPage,
      totalItems: dataLength
    },
    filters: {
      is: false
    }
  };
  return isLoading === true ? (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto'
      }}
    />
  ) : (
    <div>
      {renderSnackBar()}
      {!leadStage ? (
        <EnhancedTable
          title={title}
          data={data}
          handlers={handlers}
          config={config}
        />
      ) : (
        <LeadStageDetail
          leadStage={leadStage}
          handleGoBack={handleGoBack}
          isLoading={isLoading}
          isNew={isNew}
          leadStages={leadStages}
          types={types}
          companies={companies}
          leadStates={leadStates}
        />
      )}
    </div>
  );
};

export default LeadStages;
