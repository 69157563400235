import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {filterPropertyTypes, createPropertyType, updatePropertyType} from '../../../actions/propertyTypes';
import EnhancedTable from '../../common/enhancedTable';
import {propertiesCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import PropertiesDetail from './detail';

export class PropertyTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {           
      propertyType: null,
      isNew: false,
      title: 'Propiedades'
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleUpdatePropertyType = this.handleUpdatePropertyType.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.filterPropertyTypes(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    ); 
  }

  onAdd() {
    this.setState({isNew: true, propertyType: {}});
  }

  onDetail(id) {
    const propertyType = this.props.propertyTypes.filter(obj => obj.id === id)[0];
    this.setState({propertyType, isNew: false});
  }

  onOrder(order, orderBy) {
    this.props.filterPropertyTypes(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText);
  }

  onSearch(searchText) {
    this.props.filterPropertyTypes(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_unit_types) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_unit_types) {
        return true;
      }
    }
    return false;
  }

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_unit_types) {
        return true;
      }
    }
    return false;
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterPropertyTypes(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleChangePage(page) {
    this.props.filterPropertyTypes(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleGoBack() {
    this.props.filterPropertyTypes(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
    this.setState({propertyType: null, isNew: false});
  }

  handleUpdatePropertyType(propertyTypeEdited) {
    const {propertyType} = this.state;
    this.setState({propertyType: propertyTypeEdited});
    const edited = {};
    if (propertyType.active !== propertyTypeEdited.active) {
      edited.active = propertyTypeEdited.active;
    }
    if (propertyType.name !== propertyTypeEdited.name) {
      edited.name = propertyTypeEdited.name;
    }
    if (propertyType.revenue_percentage !== propertyTypeEdited.revenue_percentage) {
      edited.revenue_percentage = propertyTypeEdited.revenue_percentage;
    }
    if (propertyType.persons_name !== propertyTypeEdited.persons_name) {
      edited.persons_name = propertyTypeEdited.persons_name;
    }
    if (propertyType.icon !== propertyTypeEdited.icon) {
      edited.icon = propertyTypeEdited.icon;
    }
    if (propertyType.code !== propertyTypeEdited.code) {
      edited.code = propertyTypeEdited.code;
    }
    this.props.updatePropertyType(propertyType.id, edited);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, propertyTypes, dataLength} = this.props;
    const {title, propertyType, isNew} = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const data = {
      rows: propertyTypes,
      cols: propertiesCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };
    if (isLoading === true) {
      return (
        <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {
          propertyType === null ?
            <EnhancedTable 
              title={title}
              data={data} 
              handlers={handlers}
              config={config}
            />  
            :
            <PropertiesDetail 
              propertyType={propertyType}
              handleGoBack={this.handleGoBack}
              isLoading={isLoading}
              isNew={isNew}
              handleCreatePropertyType={this.props.createPropertyType}
              handleUpdatePropertyType={this.handleUpdatePropertyType}
            />
        }
      </div>
    );
  }
}

PropertyTypes.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  filterPropertyTypes: PropTypes.func.isRequired,
  updatePropertyType: PropTypes.func.isRequired,
  createPropertyType: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {propertyTypes} = state;
  return { 
    isLoading: propertyTypes.sending, 
    propertyTypes: propertyTypes.propertyTypes,
    dataLength: propertyTypes.dataLength, 
    error: propertyTypes.error,
    message: propertyTypes.message,
    searchText: propertyTypes.searchText,
    order: propertyTypes.order,
    orderBy: propertyTypes.orderBy,
    page: propertyTypes.page,
    rowsPerPage: propertyTypes.rowsPerPage,
    filters: propertyTypes.filters
  };
};
  
const mapDispatchToProps = {
  filterPropertyTypes,
  createPropertyType,
  updatePropertyType
};
  
export default connect(mapStateToProps, mapDispatchToProps)(PropertyTypes);
