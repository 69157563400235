import React, {useState, useEffect, Fragment} from 'react';
import {useSelector} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {PropTypes} from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Checkbox,
  IconButton,
  TableHead
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {styles} from './styles';
import DuplicateLeadsToolbar from './toolbar/index';
import DuplicateLeadsHead from './head/index';
import {validateBulkEmails} from '../../../../../actions/leads';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const DuplicateLeads = (props) => {
  const {classes, openDuplicatesModal, leadsToAdd, onDuplicatesChange, closeModal, companyId} = props;

  const {leadStages} = useSelector(state => state.leadStages);

  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [sendNotification, setSendNotification] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectType, setSelectType] = useState('all');
  const [openDetail, setOpenDetail] = useState();
  const [duplicateLeads, setDuplicateLeads] = useState([]);

  useEffect(() => {
    (async () => {
      const emails = leadsToAdd.map(lead => lead.user.email);
      const leads = await validateBulkEmails(emails, companyId);

      if (!leads || leads.length === 0) {
        onDuplicatesChange(leadsToAdd, null);
      }

      const currentRows = leads
      .map((lead) => {
        const leadStage = leadStages.find(item => item.id === lead.stage);
        const stage = lead.stage && leadStage ? leadStage.name : '';
        return {...lead, stage};
      })
      .reduce((acc, val) => {
        const {_id, email, username, origin, stage, status, dateCreated, brokerId, brokerName} = val;
        const date = new Date(dateCreated.split('T')[0]);

        const emailIndex = acc.map(x => x.email).indexOf(email);

        if (emailIndex === -1) {
          acc.push({
            email,
            username,
            leads: [{_id, origin, stage, status, brokerId, brokerName, dateCreated: date}]
          });
        } else {
          acc[emailIndex].leads = [...acc[emailIndex].leads, {_id, origin, stage, status, brokerId, brokerName, dateCreated: date}]
          .sort((a, b) => b.dateCreated - a.dateCreated);
        }

        return acc;
      }, []);

      setRows(currentRows);
      setDuplicateLeads(leads);
      setSelected(currentRows.map(x => x.email));
    })();
  }, []);

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = rows.map(x => x.email);
      setSelected(newSelecteds);
      setSendNotification([]);
      return;
    }
    setSelected([]);
    setSendNotification(rows.map(x => x.email));
  };

  const handleClick = (event, name, notification = false) => {
    const selectedIndex = notification
      ? sendNotification.indexOf(name)
      : selected.indexOf(name);
    let newSelected = [];
    const array = notification ? sendNotification : selected;

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(array, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(array.slice(1));
    } else if (selectedIndex === array.length - 1) {
      newSelected = newSelected.concat(array.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        array.slice(0, selectedIndex),
        array.slice(selectedIndex + 1)
      );
    }

    if (notification) setSendNotification(newSelected);
    else {
      handleClick(event, name, true);
      setSelected(newSelected);
    }

    if (!notification && newSelected.length === rows.length) {
      setSelectType('all');
    } else if (notification && newSelected.length === rows.length) {
      setSelectType('none');
    } else setSelectType('manual');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectType = (e) => {
    const {value} = e.target;
    if (value === 'all') {
      setSelectType('all');
      handleSelectAllClick(true);
    } else if (value === 'none') {
      setSelectType('none');
      handleSelectAllClick(false);
    }
  };

  const isSelected = name => selected.indexOf(name) !== -1;
  const isSendNotification = name => sendNotification.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - (page * rowsPerPage));  

  const validateField = item =>
    (item && item !== '' ? item : '-');

  const handleNext = () => {
    const userId = localStorage.getItem('user');

    const excludedLeads = rows
    .filter(row => ![...selected, ...sendNotification].includes(row.email))
    .map(row => row.email);
    
    const addLeads = leadsToAdd.filter(lead => ![...excludedLeads, ...sendNotification].includes(lead.user.email));

    const notificationLeads = rows
    .filter(row => sendNotification.includes(row.email))
    .map(row => row.leads.map(lead => ({id: lead._id, email: row.email})))
    .flat()
    .map((item) => {
      const {email, id} = item;
      const broker = duplicateLeads.find(lead => lead._id === id).brokerId;
      let body = leadsToAdd.find(lead => lead.user.email === email);
      body = {
        name: body.user.name,
        lastname: body.user.lastname,
        email: body.user.email,
        phone: body.user.phone,
        observations: body.observations && body.observations.length > 0 ? body.observations[0].description : '',
        campaignId: body.campaignId || '',
        adId: body.adId || '',
        stand: body.stand || ''
      };
      return {id, broker, userId, body};
    });
    
    onDuplicatesChange(addLeads, notificationLeads);
  };

  const renderDetailRow = (leadRows, index) => {  
    return (
      <TableRow key={`${index}_detail`}>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell className={classes.rowTitle}>Leads</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Origen</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Etapa</TableCell>
                <TableCell>Asesor</TableCell>
                <TableCell>Fecha de creación</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leadRows.map((lead) => {            
                const origin = validateField(lead.origin);
                const status = validateField(lead.status);
                const stage = validateField(lead.stage);
                const broker = validateField(lead.brokerName);
                
                const dateCreated = lead.dateCreated.toISOString().split('T')[0];

                return (
                  <TableRow key={lead._id}>
                    <TableCell>{origin}</TableCell>
                    <TableCell>{status}</TableCell>
                    <TableCell>{stage}</TableCell>
                    <TableCell>{broker}</TableCell>
                    <TableCell>{dateCreated}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  const renderSelectableRow = (row, index) => {
    const isItemSelected = isSelected(row.email);
    const isItemNotificationSelected = isSendNotification(row.email);
    const labelId = `enhanced-table-checkbox-${index}`;

    const username = validateField(row.username);
    const email = validateField(row.email);

    return (
      <Fragment>
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={index}
          selected={isItemSelected}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() =>
                (openDetail === row.email
                  ? setOpenDetail(null)
                  : setOpenDetail(row.email))
              }
            >
              {openDetail === row.email ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell>
            <Checkbox
              checked={isItemSelected}
              onChange={event =>
                handleClick(event, row.email)
              }
              inputProps={{'aria-labelledby': labelId}}
            />
          </TableCell>
          <TableCell align="left">{username}</TableCell>
          <TableCell align="left">{email}</TableCell>
          <TableCell>
            <Checkbox
              checked={isItemNotificationSelected}
              onChange={event =>
                handleClick(event, row.email, true)
              }
              disabled={isItemSelected}
            />
          </TableCell>
        </TableRow>
        {openDetail === row.email && renderDetailRow(row.leads)}
      </Fragment>
    );
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xl"
        fullWidth
        open={openDuplicatesModal}
        TransitionComponent={Transition}
      >
        <DialogTitle>Gestionar duplicados</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Paper elevation={1} className={classes.paper}>
              <DuplicateLeadsToolbar
                handleSelectType={handleSelectType}
                selectType={selectType}
                numSelected={selected.length}
              />
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <DuplicateLeadsHead />
                <TableBody>
                  {rows
                  .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                  .map((row, index) => {
                    return renderSelectableRow(row, index);
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows}}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeModal}>Cancelar</Button>
          <Button color="primary" onClick={handleNext}>Continuar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DuplicateLeads.propTypes = {
  classes: PropTypes.object.isRequired,
  openDuplicatesModal: PropTypes.bool.isRequired,
  leadsToAdd: PropTypes.array,
  onDuplicatesChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  companyId: PropTypes.string
};

export default withStyles(styles)(DuplicateLeads);
