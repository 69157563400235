export const parameterOptions = [
  {
    id: 'input_type',
    label: 'Tipo',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Cuadro de texto',
        value: 'text_box',
      },
      {
        title: 'Si / No',
        value: 'yes_no',
      },
      {
        title: 'Selección de imágenes única',
        value: 'image_unique',
      },
      {
        title: 'Selección de imágenes múltiple',
        value: 'image_multiple',
      },
      {
        title: 'Selección única',
        value: 'radio_group',
      },
      {
        title: 'Selección múltiple',
        value: 'check_list',
      },
      {
        title: 'Rango',
        value: 'range',
      },
      {
        title: 'Fecha',
        value: 'date',
      },
      {
        title: 'Fecha de nacimiento',
        value: 'birth_date',
      },
      {
        title: 'Rango de Fechas',
        value: 'date_range',
      },
    ],
  },
  {
    id: 'active',
    label: 'Disponible',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Disponible',
        value: true,
      },
      {
        title: 'No disponible',
        value: false,
      },
    ],
  },
  {
    id: 'enable_wizard',
    label: 'Visible cliente',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Visible para cliente',
        value: true,
      },
      {
        title: 'No Visible para cliente',
        value: false,
      },
    ],
  },
  {
    id: 'enable_web',
    label: 'Visible Web',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Visible para web',
        value: true,
      },
      {
        title: 'No Visible para web',
        value: false,
      },
    ],
  },
];

export const projectsOptions = [
  {
    id: 'active',
    label: 'Disponible',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Disponible',
        value: true,
      },
      {
        title: 'No disponible',
        value: false,
      },
    ],
  },
  {
    id: 'builder',
    label: 'Constructora',
    type: 'select',
    types: [],
  },
  {
    id: 'zone',
    label: 'Sector',
    type: 'select',
    types: [],
  },
  {
    id: 'company',
    label: 'Empresa',
    type: 'select',
    types: [],
  },
];

export const unitsOptions = [
  {
    id: 'project',
    label: 'Proyecto',
    type: 'autocomplete',
    types: [],
  },
  {
    id: 'type',
    label: 'Tipo de propiedad',
    type: 'select',
    types: [],
  },
  {
    id: 'stage',
    label: 'Etapa',
    type: 'select',
    types: [],
  },
  {
    id: 'area',
    label: 'Área m2',
    type: 'numberRange',
    types: [],
  },
  {
    id: 'price',
    label: 'Precio $',
    type: 'numberRange',
    types: [],
  },
  {
    id: 'active',
    label: 'Activa',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Activa',
        value: true,
      },
      {
        title: 'No activa',
        value: false,
      },
    ],
  },
  {
    id: 'builder',
    label: 'Constructora',
    type: 'select',
    types: [],
  },
  {
    id: 'zone',
    label: 'Sector',
    type: 'select',
    types: [],
  },
  {
    id: 'company',
    label: 'Empresa',
    type: 'select',
    types: [],
  },
];

// Constantes para filtros de leads
export const leadsOptions = [
  {
    id: 'brokers',
    label: 'Asesor',
    type: 'select',
    types: [],
  },
  {
    id: 'originProject',
    label: 'Desarrollo Origen',
    type: 'autocomplete',
    types: [],
  },
  {
    id: 'origin',
    label: 'Origen',
    type: 'select',
    types: [],
  },
  // {
  //   id: 'stand',
  //   label: 'Stands',
  //   type: 'select',
  //   types: [],
  // },
  {
    id: 'status',
    label: 'Estado',
    type: 'select',
    types: [],
  },
  {
    id: 'stage',
    label: 'Etapa',
    type: 'select',
    types: [],
  },
  {
    id: 'zone',
    label: 'Zona',
    type: 'select',
    types: [],
  },
  {
    id: 'next_deadline',
    label: 'Tareas',
    type: 'dateRange',
  },
  {
    id: 'date_created',
    label: 'Fecha de creación',
    type: 'dateRange',
  },
  {
    id: 'date_updated',
    label: 'Fecha de últ. contacto',
    type: 'dateRange',
  },
  {
    id: 'last_contact_days',
    label: '# de días últ. contacto',
    type: 'number',
  },
  {
    id: 'estimated_closing_time',
    label: 'Tiempo potencial de cierre',
    type: 'select',
    types: [
      { title: '15', value: '15' },
      { title: '30', value: '30' },
      { title: '60', value: '60' },
      { title: '90', value: '90' },
      { title: '+90', value: '+90' },
    ],
  },
  {
    id: 'temperature',
    label: 'Temperatura',
    type: 'select',
    types: [
      { title: 'Frío', value: 'cold' },
      { title: 'Con Potencial', value: 'potential' },
      { title: 'Caliente', value: 'hot' },
    ],
  },
  {
    id: 'company',
    label: 'Empresa',
    type: 'select',
    types: [],
  },
  {
    id: 'proformas_date_created',
    label: 'Fecha de creación de proformas',
    type: 'dateRange',
  },
];

export const advanceOptions = [
  {
    id: 'userId',
    label: 'Asesor',
    type: 'select',
    types: [],
  },
  {
    id: 'monthFrom',
    label: 'Mes desde',
    type: 'select',
    types: [
      {
        title: 'Enero',
        value: 'Enero',
      },
      {
        title: 'Febrero',
        value: 'Febrero',
      },
      {
        title: 'Marzo',
        value: 'Marzo',
      },
      {
        title: 'Abril',
        value: 'Abril',
      },
      {
        title: 'Mayo',
        value: 'Mayo',
      },
      {
        title: 'Junio',
        value: 'Junio',
      },
      {
        title: 'Julio',
        value: 'Julio',
      },
      {
        title: 'Agosto',
        value: 'Agosto',
      },
      {
        title: 'Septiembre',
        value: 'Septiembre',
      },
      {
        title: 'Octubre',
        value: 'Octubre',
      },
      {
        title: 'Noviembre',
        value: 'Noviembre',
      },
      {
        title: 'Diciembre',
        value: 'Diciembre',
      },
    ],
  },
  {
    id: 'yearFrom',
    label: 'Año desde',
    type: 'select',
    types: [
      {
        title: '2019',
        value: '2019',
      },
      {
        title: '2020',
        value: '2020',
      },
    ],
  },
  {
    id: 'monthTo',
    label: 'Mes hasta',
    type: 'select',
    types: [
      {
        title: 'Enero',
        value: 'Enero',
      },
      {
        title: 'Febrero',
        value: 'Febrero',
      },
      {
        title: 'Marzo',
        value: 'Marzo',
      },
      {
        title: 'Abril',
        value: 'Abril',
      },
      {
        title: 'Mayo',
        value: 'Mayo',
      },
      {
        title: 'Junio',
        value: 'Junio',
      },
      {
        title: 'Julio',
        value: 'Julio',
      },
      {
        title: 'Agosto',
        value: 'Agosto',
      },
      {
        title: 'Septiembre',
        value: 'Septiembre',
      },
      {
        title: 'Octubre',
        value: 'Octubre',
      },
      {
        title: 'Noviembre',
        value: 'Noviembre',
      },
      {
        title: 'Diciembre',
        value: 'Diciembre',
      },
    ],
  },
  {
    id: 'yearTo',
    label: 'Año hasta',
    type: 'select',
    types: [
      {
        title: '2019',
        value: '2019',
      },
      {
        title: '2020',
        value: '2020',
      },
    ],
  },
  {
    id: 'status',
    label: 'Estado',
    type: 'select',
    types: [],
  },
  {
    id: 'stage',
    label: 'Etapa',
    type: 'select',
    types: [],
  },
];

export const commissionsOptions = [
  {
    id: 'builder',
    label: 'Constructora',
    type: 'select',
    types: [],
  },
  {
    id: 'project',
    label: 'Proyecto',
    type: 'select',
    types: [],
  },
  {
    id: 'status',
    label: 'Estado',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Pagado',
        value: 'paid',
      },
      {
        title: 'Pendiente',
        value: 'pending',
      },
    ],
  },
  {
    id: 'date_created',
    label: 'Fecha de creación',
    type: 'dateRange',
    types: [],
  },
];

export const rulesOptions = [
  {
    id: 'active',
    label: 'Disponible',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Disponible',
        value: true,
      },
      {
        title: 'No disponible',
        value: false,
      },
    ],
  },
  {
    id: 'action',
    label: 'Acción',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Enviar correo',
        value: 'send_mail',
      },
      {
        title: 'Agregar próxima acción al lead',
        value: 'add_action',
      },
      {
        title: 'Cambiar estado',
        value: 'change_status',
      },
    ],
  },
  {
    id: 'status',
    label: 'Estado',
    type: 'select',
    types: [],
  },
  {
    id: 'stage',
    label: 'Etapa',
    type: 'select',
    types: [],
  },
  {
    id: 'days',
    label: 'Último contácto (días)',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: '0 días',
        value: 0,
      },
      {
        title: '1 día',
        value: 1,
      },
      {
        title: '2 días',
        value: 2,
      },
      {
        title: '3 días',
        value: 3,
      },
      {
        title: '4 días',
        value: 4,
      },
      {
        title: '5 días',
        value: 5,
      },
      {
        title: '6 días',
        value: 6,
      },
      {
        title: '7 días',
        value: 7,
      },
      {
        title: '8 días',
        value: 8,
      },
      {
        title: '9 días',
        value: 9,
      },
      {
        title: '10 días',
        value: 10,
      },
      {
        title: '11 días',
        value: 11,
      },
      {
        title: '12 días',
        value: 12,
      },
      {
        title: '13 días',
        value: 13,
      },
      {
        title: '14 días',
        value: 14,
      },
      {
        title: '15 días',
        value: 15,
      },
      {
        title: '16 días',
        value: 16,
      },
      {
        title: '17 días',
        value: 17,
      },
      {
        title: '18 días',
        value: 18,
      },
      {
        title: '19 días',
        value: 19,
      },
      {
        title: '20 días',
        value: 20,
      },
      {
        title: '21 días',
        value: 21,
      },
      {
        title: '22 días',
        value: 22,
      },
      {
        title: '23 días',
        value: 23,
      },
      {
        title: '24 días',
        value: 24,
      },
      {
        title: '25 días',
        value: 25,
      },
      {
        title: '26 días',
        value: 26,
      },
      {
        title: '27 días',
        value: 27,
      },
      {
        title: '28 días',
        value: 28,
      },
      {
        title: '29 días',
        value: 29,
      },
      {
        title: '30 días',
        value: 30,
      },
    ],
  },
];

export const defaultOrderLeads = [
  'brokers',
  'originProject',
  'origin',
  'stand',
  // 'zone_1551401186572',
  'zone',
  'status',
  'stage',
  'next_deadline',
  'date_created',
  'date_updated',
  'last_contact_days',
  'estimated_closing_time',
  'temperature',
  'company',
  'proformas_date_created',
];

export const observationsOptions = [
  {
    id: 'broker',
    label: 'Asesor',
    type: 'select',
    types: [],
  },
  {
    id: 'type',
    label: 'Tipo',
    type: 'select',
    types: [
      {
        title: 'Todos',
        value: 'all',
      },
      {
        title: 'Nota',
        value: 'nota',
      },
      {
        title: 'Llamada',
        value: 'llamada',
      },
      {
        title: 'Whatsapp',
        value: 'whatsapp',
      },
      {
        title: 'Email',
        value: 'email',
      },
      {
        title: 'Personal',
        value: 'personal',
      },
    ],
  },
  {
    id: 'date',
    label: 'Fecha de la observación',
    type: 'date',
    types: [],
  },
];

// Constantes para filtros de calendario
export const calendarOptions = [
  {
    id: 'project',
    label: 'Proyecto',
    type: 'autocomplete',
    types: [],
  },
  {
    id: 'date',
    label: 'Fecha de evento',
    type: 'dateRange',
  },
  {
    id: 'brokers',
    label: 'Asesor',
    type: 'select',
    types: [],
  }
  // {
  //   id: 'builder',
  //   label: 'Constructora',
  //   type: 'select',
  //   types: [],
  // },
];
