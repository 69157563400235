import * as stageActions from '../actions/types/stages';

const initialState = {
  stages: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {stages, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case stageActions.FILTER_STAGES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case stageActions.FILTER_STAGES_SUCCESS:
    return {...state, sending: false, stages, error: null, message: 'Etapas obtenidas satisfactoriamente', dataLength};
  case stageActions.FILTER_STAGES_FAILED:
    return {...state, sending: false, stages: [], error, message, dataLength};
  case stageActions.GET_STAGES_SENDING:
    return {...state, sending: true};
  case stageActions.GET_STAGES_SUCCESS:
    return {...state, sending: false, stages, error: null, message: 'Etapas obtenidas satisfactoriamente', dataLength};
  case stageActions.GET_STAGES_FAILED:
    return {...state, sending: false, stages: [], error, message, dataLength};
  case stageActions.CREATE_STAGE_SENDING:
    return {...state, sending: true};
  case stageActions.CREATE_STAGE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Etapa creada satisfactoriamente'};
  case stageActions.CREATE_STAGE_FAILED:
    return {...state, sending: false, error, message};
  case stageActions.UPDATE_STAGE_SENDING:
    return {...state, sending: true};
  case stageActions.UPDATE_STAGE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Etapa actualizada satisfactoriamente'};
  case stageActions.UPDATE_STAGE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
