export const options = [
  {
    title: 'Selección única',
    type: 'radio_group',
    is_multiple: true,
    properties: [
      {
        value: 'Ingresa la opción',
        active: 'Disponible/No disponible'
      }
    ],
    content: [
      {
        value: '',
        active: true,
        order: 1,
        creationOrder: 1
      }
    ]
  },
  {
    title: 'Selección múltiple',
    type: 'check_list',
    is_multiple: true,
    properties: [
      {
        value: 'Ingresa la opción',
        active: 'Disponible/No disponible'
      }
    ],
    content: [
      {
        value: '',
        active: true,
        order: 1,
        creationOrder: 1
      }
    ]
  },
  {
    title: 'Selección de imágenes única',
    type: 'image_unique',
    is_multiple: true,
    properties: [
      {
        url: 'Ingresa el url de la imagen',
        value: 'Ingresa el título de la imagen',
        active: 'Disponible/No disponible'
      }
    ],
    content: [
      {
        url: '',
        value: '',
        active: true,
        order: 1,
        creationOrder: 1
      }
    ]
  },
  {
    title: 'Selección de imágenes múltiple',
    type: 'image_multiple',
    is_multiple: true,
    properties: [
      {
        url: 'Ingresa el url de la imagen',
        value: 'Ingresa el título de la imagen',
        active: 'Disponible/No disponible'
      }
    ],
    content: [
      {
        url: '',
        value: '',
        active: true,
        order: 1,
        creationOrder: 1
      }
    ]
  },
  {
    title: 'Cuadro de texto',
    type: 'text_box',
    is_multiple: false,
    properties: [
      {
        placeholder: 'Ingresa el texto de referencia'
      }
    ],
    content: [
      {
        placeholder: ''
      }
    ]
  },
  {
    title: 'Si / No',
    type: 'yes_no',
    is_multiple: false,
    content: []
  },
  {
    title: 'Rango',
    type: 'range',
    is_multiple: false,
    properties: [
      {
        min_val: 'Ingresa el valor mínimo',
        max_val: 'Ingresa el valor máximo'
      }
    ],
    content: [
      {
        min_val: '',
        max_val: ''
      }
    ]
  },
  {
    title: 'Fecha',
    type: 'date',
    is_multiple: false,
    properties: [
      {
        date: 'Ingresa el texto de referencia'
      }
    ],
    content: [
      {
        date: ''
      }
    ]
  },
  {
    title: 'Fecha de nacimiento',
    type: 'birth_date',
    is_multiple: false,
    content: []
  },
  {
    title: 'Rango de fechas',
    type: 'date_range',
    is_multiple: false,
    content: []
  }
];

export const switchableOptions = [
  {
    title: 'Selección única',
    type: 'radio_group',
    is_multiple: true,
    properties: [
      {
        value: 'Ingresa la opción',
        active: 'Disponible/No disponible'
      }
    ],
    content: [
      {
        value: '',
        active: true
      }
    ]
  },
  {
    title: 'Selección múltiple',
    type: 'check_list',
    is_multiple: true,
    properties: [
      {
        value: 'Ingresa la opción',
        active: 'Disponible/No disponible'
      }
    ],
    content: [
      {
        value: '',
        active: true
      }
    ]
  },
  {
    title: 'Selección de imágenes única',
    type: 'image_unique',
    is_multiple: true,
    properties: [
      {
        url: 'Ingresa el url de la imagen',
        value: 'Ingresa el título de la imagen',
        active: 'Disponible/No disponible'
      }
    ],
    content: [
      {
        url: '',
        value: '',
        active: true
      }
    ]
  },
  {
    title: 'Selección de imágenes múltiple',
    type: 'image_multiple',
    is_multiple: true,
    properties: [
      {
        url: 'Ingresa el url de la imagen',
        value: 'Ingresa el título de la imagen',
        active: 'Disponible/No disponible'
      }
    ],
    content: [
      {
        url: '',
        value: '',
        active: true
      }
    ]
  }
];

export const catalogOptions = [
  {
    title: 'Selección única',
    type: 'radio_group',
    is_multiple: true,
    properties: [
      {
        value: 'Ingresa la opción'
      }
    ],
    content: []
  },
  {
    title: 'Selección múltiple',
    type: 'check_list',
    is_multiple: true,
    properties: [
      {
        value: 'Ingresa la opción'
      }
    ],
    content: []
  },
  {
    title: 'Selección de imágenes única',
    type: 'image_unique',
    is_multiple: true,
    properties: [
      {
        url: 'Ingresa el url de la imagen',
        value: 'Ingresa el título de la imagen'
      }
    ],
    content: []
  },
  {
    title: 'Selección de imágenes múltiple',
    type: 'image_multiple',
    is_multiple: true,
    properties: [
      {
        url: 'Ingresa el url de la imagen',
        value: 'Ingresa el título de la imagen'
      }
    ],
    content: []
  }
    
];
