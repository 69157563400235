import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { styles } from './styles';
import SimpleTableHead from './simpleTableHead';
import { Toolbar, Typography, IconButton } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { downladTableAsCsv } from '../../../utils/utils';
import slug from 'slug';

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

const SimpleTable = (props) => {
  const { classes, rows, headRows, title, subtitle, downloadable } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [disableCopy, setDisableCopy] = useState(false);
  useEffect(() => {
    if (title === 'Contactos por proyecto') {
      setDisableCopy(true);
    }
  }, []);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  return (
    <div className={classes.root}>
      <div className={disableCopy ? classes.notCopy : ''}>
        <Paper className={classes.paper}>
          {title && (
            <Toolbar>
              <Typography variant="subtitle1" className={classes.title}>
                {title}
              </Typography>
              {downloadable && (
                <IconButton
                  edge="end"
                  onClick={() => downladTableAsCsv(headRows, rows, slug(title))}
                >
                  <CloudDownloadIcon />
                </IconButton>
              )}
            </Toolbar>
          )}
          {subtitle && (
            <Typography variant="subtitle2" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
          <div className={classes.tableWrapper} style={disableCopy ? { height: 470 } : {}}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <SimpleTableHead
                headRows={headRows}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length || 0}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Descomentar si es que vamos a usar paginacion
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const objetKeys = Object.keys(row);
                    return (
                      <TableRow hover tabIndex={-1} key={`row-item${index}`}>
                        {headRows.map((mapedColumn, cellIndex) => {
                          return (
                            <TableCell
                              key={`row-item-cell-${cellIndex}`}
                              align={'center'}
                              padding="none"
                            >
                              {row[mapedColumn.id]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
        </Paper>
      </div>
    </div>
  );
};

SimpleTable.propTypes = {
  rows: PropTypes.any.isRequired,
  headRows: PropTypes.any.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  downloadable: PropTypes.bool,
};

export default withStyles(styles)(SimpleTable);
