import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {PropTypes} from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  TableHead,
  Toolbar,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import {styles} from './styles';
import {validateBulkEmails} from '../../../../../actions/leads';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const DuplicateLead = (props) => {
  const {
    classes,
    openDuplicatesModal,
    newLead,
    closeModal,
    handleCreateLead
  } = props;

  const {leadStages} = useSelector(state => state.leadStages);

  const [rows, setRows] = useState([]);
  const [selectType, setSelectType] = useState('all');
  const [duplicateLeads, setDuplicateLeads] = useState([]);

  useEffect(() => {
    (async () => {
      const leads = await validateBulkEmails([newLead.user.email], newLead.company);
      
      if (!leads || leads.length === 0) {
        handleCreateLead();
        closeModal();
      }

      const currentRows = leads
      .map((lead) => {
        const leadStage = leadStages.find(item => item.id === lead.stage);
        const stage = lead.stage && leadStage ? leadStage.name : '';
        return {...lead, stage};
      })
      .reduce((acc, val) => {
        const {
          _id,
          email,
          username,
          origin,
          stage,
          status,
          dateCreated,
          brokerId,
          brokerName
        } = val;
        const date = new Date(dateCreated.split('T')[0]);

        const emailIndex = acc.map(x => x.email).indexOf(email);

        if (emailIndex === -1) {
          acc.push({
            email,
            username,
            leads: [
              {
                _id,
                origin,
                stage,
                status,
                brokerId,
                brokerName,
                dateCreated: date
              }
            ]
          });
        } else {
          acc[emailIndex].leads = [
            ...acc[emailIndex].leads,
            {
              _id,
              origin,
              stage,
              status,
              brokerId,
              brokerName,
              dateCreated: date
            }
          ].sort((a, b) => b.dateCreated - a.dateCreated);
        }

        return acc;
      }, []);
      setRows(currentRows);
      setDuplicateLeads(leads);
    })();
  }, []);

  const handleSelectType = (e) => {
    const {value} = e.target;
    if (value === 'all') {
      setSelectType('all');
    } else if (value === 'none') {
      setSelectType('none');
    }
  };

  const validateField = item => (item && item !== '' ? item : '-');

  const handleNext = () => {
    const userId = localStorage.getItem('user');

    if (selectType === 'all') handleCreateLead();
    else if (selectType === 'none') {
      const notificationLeads = rows
      .map(row => row.leads.map(lead => ({id: lead._id, email: row.email})))
      .flat()
      .map((item) => {
        const {id} = item;
        const broker = duplicateLeads.find(lead => lead._id === id).brokerId;
        let body = newLead;
        body = {
          name: body.user.name,
          lastname: body.user.lastname,
          email: body.user.email,
          phone: body.user.phone,
          observations: '',
          campaignId: '',
          adId: '',
          stand: ''
        };
        return {id, broker, userId, body};
      });
      
      handleCreateLead(notificationLeads);
    }

    closeModal();
  };

  return (
    <div>
      { duplicateLeads && duplicateLeads.length > 0 && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xl"
          fullWidth
          open={openDuplicatesModal}
          TransitionComponent={Transition}
        >
          <DialogTitle>Duplicar lead</DialogTitle>
          <DialogContent>
            {rows.length > 0 && (
              <div className={classes.root}>
                <Paper elevation={1} className={classes.paper}>
                  <Toolbar className={classes.toolbarRoot}>
                    <FormControl
                      component="fieldset"
                      className={classes.radioGroup}
                    >
                      <RadioGroup
                        row
                        aria-label="selectType"
                        name="selectType1"
                        value={selectType}
                        onChange={handleSelectType}
                      >
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label="Duplicar"
                          className={classes.radioButton}
                        />
                        <FormControlLabel
                          value="none"
                          control={<Radio />}
                          label="No duplicar, solo enviar notificaciones"
                          className={classes.radioButton}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Toolbar>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.rowTitle}>Leads</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Origen</TableCell>
                        <TableCell>Estado</TableCell>
                        <TableCell>Etapa</TableCell>
                        <TableCell>Asesor</TableCell>
                        <TableCell>Fecha de creación</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows[0].leads.map((lead) => {
                        const origin = validateField(lead.origin);
                        const status = validateField(lead.status);
                        const stage = validateField(lead.stage);
                        const broker = validateField(lead.brokerName);

                        const dateCreated = lead.dateCreated
                        .toISOString()
                        .split('T')[0];

                        return (
                          <TableRow key={lead._id}>
                            <TableCell>{origin}</TableCell>
                            <TableCell>{status}</TableCell>
                            <TableCell>{stage}</TableCell>
                            <TableCell>{broker}</TableCell>
                            <TableCell>{dateCreated}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={closeModal}>
            No duplicar
            </Button>
            <Button color="primary" onClick={handleNext}>
            Continuar
            </Button>
          </DialogActions>
        </Dialog>
      )
      }
    </div>
  );
};

DuplicateLead.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCreateLead: PropTypes.func.isRequired,
  openDuplicatesModal: PropTypes.bool.isRequired,
  newLead: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default withStyles(styles)(DuplicateLead);
