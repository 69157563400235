import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {ZONE} from '../../../utils/utils';

export class CheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerId: []
    };
    this.renderAnswers = this.renderAnswers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answerId} = currentAnswer;
      if (answerId.length > 0) {
        this.setState({answerId});
      }
    }
  }

  handleChange(value) {
    const {answerId} = this.state;
    const {identifier} = this.props;
    if (answerId.includes(value)) {
      const index = answerId.indexOf(value);
      if (index > -1) {
        answerId.splice(index, 1);
      }
    } else {
      answerId.push(value);
    }
    this.setState({answerId});
    this.props.updateAnswer(identifier, answerId, '');
  }

  renderAnswers() {
    const {permissions, identifier} = this.props;
    if (identifier === ZONE) {
      return this.props.answers.map((options, index) => {
        const {father} = options;
        const fatherObject = this.props.answers.filter(obj => obj.catId === father)[0];
        const fatherLabel = fatherObject ? fatherObject.value : '';
        const label = father === null ? options.value : `${options.value} (${fatherLabel})`;
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                className="checkBoxInput"
                checked={!!this.state.answerId.includes(options.answerId)}
                onChange={() => this.handleChange(options.answerId)}
                value={options.answerId}
                disabled={permissions === false || father === null}
              />
            }
            label={label}
          />
        );
      });  
    }
    return this.props.answers.map((options, index) => {
      return (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              className="checkBoxInput"
              checked={!!this.state.answerId.includes(options.answerId)}
              onChange={() => this.handleChange(options.answerId)}
              value={options.answerId}
              disabled={permissions === false}
            />
          }
          label={options.value}
        />
      );
    });
  }

  render() {
    return (
      <FormGroup>
        {this.renderAnswers()}
      </FormGroup>
    );
  }
}
CheckList.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    answerId: PropTypes.string
  })).isRequired,
  permissions: PropTypes.bool,
  updateAnswer: PropTypes.func,
  currentAnswer: PropTypes.object,
  identifier: PropTypes.string
};

export default CheckList;
