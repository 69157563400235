/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as groupActions from './types/groups';
import store from '../store';


const company = JSON.parse(localStorage.getItem('company'));

export const filterGroups = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().groups.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().groups.order || orderBy !== store.getState().groups.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: groupActions.FILTER_GROUPS_SENDING,
    payload: {
      groups: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && order !== null && orderBy !== null && searchText !== null) {
      filter = {
        filters: {
          name: searchText
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        company: company._id,
        sort: {
          [orderBy]: order
        }
      };
    }
    const response = await axios(axiosMaker('POST', 'groups/filter', filter, true));
    return dispatch({
      type: groupActions.FILTER_GROUPS_SUCCESS,
      payload: {
        groups: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: groupActions.FILTER_GROUPS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getGroups = () => async (dispatch) => {
  dispatch({
    type: groupActions.GET_GROUPS_SENDING,
    payload: {
      groups: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {}
    };
    const response = await axios(axiosMaker('POST', 'groups/filter', data, true));
    return dispatch({
      type: groupActions.GET_GROUPS_SUCCESS,
      payload: {
        groups: response.data.results,
        dataLength: response.data.results.length
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: groupActions.GET_GROUPS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createGroup = data => async (dispatch) => {
  dispatch({
    type: groupActions.CREATE_GROUP_SENDING,
    payload: {}
  });
  data.company = company._id;
  try {
    await axios(axiosMaker('POST', 'groups', data, true));
    return dispatch({
      type: groupActions.CREATE_GROUP_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: groupActions.CREATE_GROUP_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateGroup = (id, data) => async (dispatch) => {
  dispatch({
    type: groupActions.UPDATE_GROUP_SENDING,
    payload: {}
  });
  data.company = company._id;
  try {
    await axios(axiosMaker('PATCH', `groups/${id}`, data, true));
    return dispatch({
      type: groupActions.UPDATE_GROUP_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: groupActions.UPDATE_GROUP_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
