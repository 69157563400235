export const styles = (theme) => ({
  title: {
    '& h6': {
      color: theme.palette.primary.main,
    },
  },
  tipsButton: {
    width: '100%',
  },
});

export default styles;
