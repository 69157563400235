/* eslint-disable no-param-reassign */
import axios from 'axios';
import * as parametersActions from './types/parameters';
import {axiosMaker, errorMessage, logOutFunction, maxLimit} from '../utils/utils';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterParameters = (category, page, rowsPerPage, order, orderBy, searchText, filters) => async (dispatch) => {
  if (
    (searchText !== '' && store.getState().parameters.searchText !== searchText) || 
    (Object.keys(filters).length && filters !== store.getState().parameters.filters)
  ) {
    page = 0;
  }
  if (order !== store.getState().parameters.order || orderBy !== store.getState().parameters.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
    filters = {};
  }
  dispatch({
    type: parametersActions.FILTER_PARAMETERS_SENDING,
    payload: {
      dataLength: 0,
      category,
      rowsPerPage,
      page,
      order,
      orderBy,
      searchText,
      filters
    }
  });
  try {
    let filtersToApply = {};
    if (category === 'orders') {
      filtersToApply = {
        category: ['personal', 'unit', 'project'],
        enabled: true
      };
    } else if (category === 'wizard') {
      filtersToApply = {
        enable_wizard: true,
        enabled: true
      };
    } else {
      filtersToApply = filters;
      filtersToApply.category = category;
      if (searchText !== '') {
        filtersToApply.name = searchText;
      }
      if (company) {
        filtersToApply.enabled = true;
      }
    }
    const data = {
      filters: filtersToApply,
      operator: 'and',
      page,
      limit: category !== 'wizard' ? rowsPerPage : 1000,
      sort: category !== 'wizard' ? {
        [orderBy]: order
      }
        : {
          order: 'asc'
        },
      company: company && company._id
    };
    const response = await axios(axiosMaker('POST', 'attributes/filter', data, true));
    return dispatch({
      type: parametersActions.FILTER_PARAMETERS_SUCCESS,
      payload: {
        parameters: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    const { data } = e.response;
    if (data) {
      const { message } = data;
      if (message === 'jwt expired') {
        logOutFunction();
      }
    }
    return dispatch({
      type: parametersActions.FILTER_PARAMETERS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getParametersByCategory = category => async (dispatch) => {
  dispatch({
    type: parametersActions.GET_PARAMETERS_SENDING,
    payload: {
      dataLength: 0,
      category
    }
  });
  try {
    const data = {
      filters: {category},
      operator: 'and',
      sort: {
        globalOrder: 'asc'
      },
      limit: maxLimit,
      company: company && company._id
    };

    if (company) {
      data.filters.enabled = true;
    }
        
    const response = await axios(axiosMaker('POST', 'attributes/filter', data, true));
    return dispatch({
      type: parametersActions.GET_PARAMETERS_SUCCESS,
      payload: {
        parameters: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: parametersActions.GET_PARAMETERS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getAllParameters = (enableWeb = false) => async (dispatch) => {
  dispatch({
    type: parametersActions.GET_ALL_PARAMETERS_SENDING,
    payload: {
      dataLengthAllParameters: 0
    }
  });
  try {
    const data = {
      filters: {
        active: true
      },
      sort: {
        globalOrder: 'asc'
      },
      limit: maxLimit,
      company: company && company._id
    };
    if (company) {
      data.filters.enabled = true;
    }
    if (enableWeb) {
      data.filters.enable_web = true;
    }
    const response = await axios(axiosMaker('POST', 'attributes/filter', data, true));
    return dispatch({
      type: parametersActions.GET_ALL_PARAMETERS_SUCCESS,
      payload: {
        allParameters: response.data.results,
        dataLengthAllParameters: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: parametersActions.GET_ALL_PARAMETERS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const sortParameters = (sortedParameters, category) => async (dispatch) => {
  dispatch({
    type: parametersActions.SORT_PARAMETERS_SENDING,
    payload: {}
  });
  try {

    let orderType = '';
    if (category === 'wizard') orderType = 'order';
    else if (category === 'orders') orderType = 'globalOrder';
    
    const attributes = sortedParameters.map(({id}, i) => ({id, [orderType]: ++i}));
    
    const response = await axios(axiosMaker('PUT', 'attributes/updateOrder', {attributes}, true));
    return dispatch({
      type: parametersActions.SORT_PARAMETERS_SUCCESS,
      payload: {
        parameters: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: parametersActions.SORT_PARAMETERS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createParameter = parameter => async (dispatch) => {
  dispatch({
    type: parametersActions.ADD_PARAMETER_SENDING,
    payload: {
            
    }
  });
  const data = {...parameter, 
    company: company && company._id
  };
  try {
    const response = await axios(axiosMaker('POST', 'attributes', parameter, true));
    return dispatch({
      type: parametersActions.ADD_PARAMETER_SUCCESS,
      payload: {
        parameters: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: parametersActions.ADD_PARAMETER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const editParameter = (id, parameter) => async (dispatch) => {
  dispatch({
    type: parametersActions.EDIT_PARAMETER_SENDING,
    payload: {}
  });
  const data = {...parameter, 
    company: company && company._id
  };
  try {
    const options = axiosMaker('PATCH', `attributes/${id}`, data, true);
    const response = await axios(options);
    return dispatch({
      type: parametersActions.EDIT_PARAMETER_SUCCESS,
      payload: {
        parameters: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: parametersActions.EDIT_PARAMETER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
