import * as zoneActions from '../actions/types/zones';

const initialState = {
  zones: [],
  allZones: [],
  formattedZones: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {zones, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText, allZones, formattedZones} = action.payload;
  switch (action.type) {
  case zoneActions.FILTER_ZONES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case zoneActions.FILTER_ZONES_SUCCESS:
    return {...state, sending: false, zones, error: null, message: 'Sectores obtenidos satisfactoriamente', dataLength};
  case zoneActions.FILTER_ZONES_FAILED:
    return {...state, sending: false, zones: [], error, message, dataLength};
  case zoneActions.GET_ZONES_SENDING:
    return {...state, sending: true};
  case zoneActions.GET_ZONES_SUCCESS:
    return {...state, sending: false, zones, error: null, message: 'Sectores obtenidos satisfactoriamente', dataLength};
  case zoneActions.GET_ZONES_FAILED:
    return {...state, sending: false, zones: [], error, message, dataLength};
  case zoneActions.GET_FORMATTED_ZONES_SENDING:
    return {...state, sending: true};
  case zoneActions.GET_FORMATTED_ZONES_SUCCESS:
    return {...state, sending: false, formattedZones, error: null, message: 'Sectores obtenidos satisfactoriamente', dataLength};
  case zoneActions.GET_FORMATTED_ZONES_FAILED:
    return {...state, sending: false, formattedZones: [], error, message, dataLength};
  case zoneActions.GET_ALL_ZONES_SENDING:
    return {...state, sending: true};
  case zoneActions.GET_ALL_ZONES_SUCCESS:
    return {...state, sending: false, allZones, error: null, message: 'Sectores obtenidos satisfactoriamente'};
  case zoneActions.GET_ALL_ZONES_FAILED:
    return {...state, sending: false, zones: [], error, message, dataLength};
  case zoneActions.CREATE_ZONE_SENDING:
    return {...state, sending: true};
  case zoneActions.CREATE_ZONE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Sector creado satisfactoriamente'};
  case zoneActions.CREATE_ZONE_FAILED:
    return {...state, sending: false, error, message};
  case zoneActions.UPDATE_ZONE_SENDING:
    return {...state, sending: true};
  case zoneActions.UPDATE_ZONE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Sector actualizado satisfactoriamente'};
  case zoneActions.UPDATE_ZONE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
