/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as countryActions from './types/countries';
import store from '../store';


export const filterCountries = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().countries.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().countries.order || orderBy !== store.getState().countries.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: countryActions.FILTER_COUNTRIES_SENDING,
    payload: {
      countries: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && order !== null && orderBy !== null && searchText !== null) {
      filter = {
        filters: {
          name: searchText
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order
        }
      };
    }
    const response = await axios(axiosMaker('POST', 'countries/filter', filter, true));
    return dispatch({
      type: countryActions.FILTER_COUNTRIES_SUCCESS,
      payload: {
        countries: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: countryActions.FILTER_COUNTRIES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getCountries = () => async (dispatch) => {
  dispatch({
    type: countryActions.GET_COUNTRIES_SENDING,
    payload: {
      countries: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {}
    };
    const response = await axios(axiosMaker('POST', 'countries/filter', data, true));
    return dispatch({
      type: countryActions.GET_COUNTRIES_SUCCESS,
      payload: {
        countries: response.data.results,
        dataLength: response.data.results.length
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: countryActions.GET_COUNTRIES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createCountry = data => async (dispatch) => {
  dispatch({
    type: countryActions.CREATE_COUNTRY_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'countries', data, true));
    return dispatch({
      type: countryActions.CREATE_COUNTRY_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: countryActions.CREATE_COUNTRY_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateCountry = (id, country) => async (dispatch) => {
  dispatch({
    type: countryActions.UPDATE_COUNTRY_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `countries/${id}`, country, true));
    return dispatch({
      type: countryActions.UPDATE_COUNTRY_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: countryActions.UPDATE_COUNTRY_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
