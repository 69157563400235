import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Slide from '@material-ui/core/Slide';
import {
  withStyles,
  ListItemIcon
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import {Container, Draggable} from 'react-smooth-dnd';
import arrayMove from 'array-move';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {styles} from './styles';
import {copyObject} from '../../../../../utils/utils';
import {
  options,
  catalogOptions,
  switchableOptions
} from '../../../../../utils/typesList';
import TypeOptions from './typeOptions';

const Transition = props => <Slide direction="up" {...props} />;

export class TypesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: copyObject(options)[0],
      typeTitle: 'Selección única',
      open: false
    };
    this.setOptions = this.setOptions.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleDisabledButton = this.handleDisabledButton.bind(this);
    this.handleChangeTypeValue = this.handleChangeTypeValue.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderToggle = this.renderToggle.bind(this);
    this.renderAddButton = this.renderAddButton.bind(this);
    this.renderSelectOptions = this.renderSelectOptions.bind(this);
    this.renderTypes = this.renderTypes.bind(this);
    this.addContent = this.addContent.bind(this);
    this.switchContent = this.switchContent.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentWillMount() {
    const {type, isCatalog, inputType} = this.props;
    if (type !== null) {
      if (isCatalog === true) {
        type.type = inputType;
        this.setState({type, typeTitle: type.title});
      } else {
        this.setState({type, typeTitle: type.title});
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const {type, inputType} = nextProps;
    if (this.props.type !== type && type !== null) {
      type.type = inputType;
      this.setState({type});
    }
  }

  componentWillUnmount() {
    this.setState({type: copyObject(options)[0]});
  }

  onDrop({removedIndex, addedIndex}) {
    const {type} = this.state;
    
    type.content = arrayMove(type.content, removedIndex, addedIndex)
    .map((item, index) => ({...item, order: index + 1}));
    
    this.setState({type});
  }

  setOptions() {
    const {type} = this.state;
    this.setState({open: false});
    this.props.setOptions(type);
  }

  openDialog() {
    this.setState({open: true});
  }

  closeDialog() {
    this.setState({open: false});
  }

  handleDisabledButton() {
    const {type} = this.state;
    if (type !== null) {
      return false;
    }
    return true;
  }

  handleChangeType(e) {
    const {isCatalog} = this.props;
    const {type} = this.state;
    const {content} = type;
    const typeTitle = e.target.value;
    if (isCatalog) {
      const newType = copyObject(catalogOptions).filter(obj => obj.title === typeTitle)[0];
      newType.content = content;
      this.setState({type: newType, typeTitle});
    } else {
      const newType = copyObject(options).filter(obj => obj.title === typeTitle)[0];
      newType.content = content;
      this.setState({type: newType, typeTitle});
    }
  }

  handleChangeTypeValue(e, isDate, index, key) {
    const value = isDate ? e : e.target.value;
    const {type} = this.state;
    type.content[index][key] = value;
    this.setState({type});
  }

  handleUrlUploaded(url, contentIndex) {
    if (url) {
      const {type} = this.state;
      type.content[contentIndex].url = url;
      this.setState({type});
    }
  }

  isSwitchable = (type) => {
    if (
      type === 'radio_group' ||
      type === 'check_list' ||
      type === 'image_unique' ||
      type === 'image_multiple'
    ) {
      return true;
    }
    return false;
  };

  addContent() {
    const {type} = this.state;
    let newContent = copyObject(options.filter(obj => obj.type === type.type)[0].content[0]);

    if (
      type.type === 'radio_group' ||
      type.type === 'check_list' ||
      type.type === 'image_unique' ||
      type.type === 'image_multiple'
    ) {
      newContent = {...newContent, order: type.content.length + 1, creationOrder: type.content.length + 1};
    }

    type.content.push(newContent);
    this.setState({type});
  }

  switchContent(e, index) {
    const {type} = this.state;
    type.content[index].active = e.target.checked;
    this.setState({type});
  }

  renderSelectOptions() {
    const {isNew, isCatalog, type} = this.props;
    const {typeTitle} = this.state;
    if (isNew === true || isCatalog === true) {
      return (
        <Select
          fullWidth
          value={typeTitle}
          name="Tipo"
          onChange={e => this.handleChangeType(e)}
        >
          {this.renderTypes(isCatalog)}
        </Select>
      );
    } else if (this.isSwitchable(type.type)) {
      return (
        <Select
          fullWidth
          value={typeTitle}
          name="Tipo"
          onChange={e => this.handleChangeType(e)}
        >
          {this.renderTypes(isCatalog, true)}
        </Select>
      );
    }

    return <Typography variant="body1">{typeTitle}</Typography>;
  }

  renderAddButton() {
    const {isCatalog} = this.props;
    if (!isCatalog) {
      const {type} = this.state;
      if (type.is_multiple === true) {
        return (
          <Toolbar style={{paddingLeft: 0}}>
            <Typography
              variant="caption"
              onClick={this.addContent}
              style={{cursor: 'pointer'}}
            >
              Agregar opción
            </Typography>
            <Tooltip title="Agregar">
              <IconButton onClick={this.addContent}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        );
      }
    }
    return null;
  }

  renderToggle(active, index) {
    const {type} = this.state;
    if (
      type.type === 'radio_group' ||
      type.type === 'check_list' ||
      type.type === 'image_unique' ||
      type.type === 'image_multiple' ||
      type.type === 'range'
    ) {
      return (
        <Tooltip title={active ? 'Desactivar' : 'Activar'}>
          <Switch
            checked={active}
            onChange={e => this.switchContent(e, index)}
            value={active}
            color="primary"
          />
        </Tooltip>
      );
    }
    return null;
  }

  renderTypes = (isCatalog, switching = false) => {
    if (isCatalog) {
      return catalogOptions.map((element, index) => {
        return (
          <MenuItem key={index} value={element.title}>
            {element.title}
          </MenuItem>
        );
      });
    } else if (switching === true) {
      return switchableOptions.map((element, index) => {
        return (
          <MenuItem key={index} value={element.title}>
            {element.title}
          </MenuItem>
        );
      });
    }
    return options.map((element, index) => {
      return (
        <MenuItem key={index} value={element.title}>
          {element.title}
        </MenuItem>
      );
    });
  };

  renderContent() {
    const {classes, isCatalog} = this.props;
    const {type} = this.state;
    if (!isCatalog && type.properties) {
      return (
        <List>
          <Container
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={this.onDrop}
          >
            {type.content &&
              type.content.sort((a, b) => a.order - b.order).map((content, index) => {
                return (
                  <Draggable key={index}>
                    <div className={classes.multipleContainer}>
                      <ListItem>
                        {[
                          'check_list',
                          'image_multiple',
                          'radio_group',
                          'image_unique'
                        ].includes(type.type) && (
                          <ListItemIcon className={classes.actionsPointer}>
                            <ListItemIcon className="drag-handle">
                              <DragHandleIcon />
                            </ListItemIcon>
                          </ListItemIcon>
                        )}
                        <ListItemText>
                          <Grid container>
                            <Grid item xs>
                              <TypeOptions
                                contentIndex={index}
                                properties={type.properties}
                                content={type.content}
                                handleChangeTypeValue={
                                  this.handleChangeTypeValue
                                }
                                handleUrlUploaded={this.handleUrlUploaded}
                              />
                            </Grid>
                            <Grid item>
                              {this.renderToggle(content.active, index)}
                            </Grid>
                          </Grid>
                        </ListItemText>
                      </ListItem>
                    </div>
                  </Draggable>
                );
              })}
          </Container>
        </List>
      );
    }
    return null;
  }

  render() {
    const {classes} = this.props;
    const {open} = this.state;
    return (
      <div>
        <Button
          variant="text"
          color="primary"
          className={classes.button}
          onClick={this.openDialog}
        >
          Tipo de parámetro
        </Button>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="lg"
          fullWidth
          open={open}
          onClose={this.closeDialog}
          TransitionComponent={Transition}
        >
          <DialogTitle id="form-dialog-title">
            Escoger tipo de parámetro
          </DialogTitle>
          <DialogContent>
            <Typography variant="caption">Tipo de parámetro</Typography>
            <div className={classes.root}>
              {this.renderSelectOptions()}
              {this.renderContent()}
              {this.renderAddButton()}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={this.setOptions}
              color="primary"
              disabled={this.handleDisabledButton()}
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

TypesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
  isCatalog: PropTypes.bool.isRequired,
  inputType: PropTypes.string.isRequired,
  type: PropTypes.object,
  setOptions: PropTypes.func.isRequired
};

export default withStyles(styles)(TypesDialog);
