import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { styles } from './styles';
import { getFormattedZones } from '../../../actions/zones';
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { sortObjectByProperty } from '../../../utils/utils';

const CollapsableZones = (props) => {
  const { classes, checkedSectors, onCheckSector, level, filterBy } = props;

  const zones = useSelector((state) => state.zones).formattedZones;

  const [openedCountries, setOpenedCountries] = useState([]);
  const [openedCities, setOpenedCities] = useState([]);
  const [openedZones, setOpenedZones] = useState([]);
  const [processedZones, setProcessedZones] = useState([]);

  const dispatch = useDispatch();

  const company = JSON.parse(localStorage.getItem('company'));
  const companyId = company && company._id ? company._id : null;

  useEffect(() => {
    dispatch(getFormattedZones(level, filterBy, companyId));
  }, []);

  useEffect(() => {
    const value = [];

    level === 'countries'
      ? zones.map((country) =>
          country.provinces.map((province) =>
            province.cities.map((city) =>
              city.zones.map((zone) =>
                zone.sectors.map((sector) => {
                  value.push({
                    sector: sector.id,
                    zone: zone.id,
                    city: city.id,
                    province: province.id,
                    country: country.id,
                  });
                }),
              ),
            ),
          ),
        )
      : zones.map((city) =>
          city.zones.map((zone) =>
            zone.sectors.map((sector) => {
              value.push({
                sector: sector.id,
                zone: zone.id,
                city: city.id,
              });
            }),
          ),
        );
    setProcessedZones(value);
  }, [zones]);

  const handleCheckSector = (zone) => {
    const action = !checkedSectors.includes(zone);
    if (action) onCheckSector([...checkedSectors, zone]);
    else onCheckSector(checkedSectors.filter((item) => item !== zone));
  };

  const onCollapseCountry = (country) => {
    if (openedCountries.includes(country))
      setOpenedCountries(openedCountries.filter((item) => item !== country));
    else setOpenedCountries([...openedCountries, country]);
  };

  const onCollapseCities = (city) => {
    if (openedCities.includes(city))
      setOpenedCities(openedCities.filter((item) => item !== city));
    else setOpenedCities([...openedCities, city]);
  };

  const onCollapseZones = (zone) => {
    if (openedZones.includes(zone))
      setOpenedZones(openedZones.filter((item) => item !== zone));
    else setOpenedZones([...openedZones, zone]);
  };

  const sortName = (arr) => sortObjectByProperty(arr, 'name');

  const renderCities = (array, countryIndex, padding) => (
    <List component="nav" className={padding}>
      {sortName(array).map((city, cityIndex) => {
        return (
          <div key={`zone_${countryIndex}_${cityIndex}`}>
            <ListItem button onClick={() => onCollapseCities(city.id)}>
              {processedZones
                .filter((item) => checkedSectors.includes(item.sector))
                .some((item) => item.city === city.id) && (
                <ListItemIcon>
                  <CheckRoundedIcon style={{ color: 'green' }} />
                </ListItemIcon>
              )}
              <ListItemText
                className={classes.collapsableLevel}
                inset
                primary={city.name}
              />
              {openedCities.includes(city.id) ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItem>
            <Collapse
              in={openedCities.includes(city.id)}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="nav"
                className={`${classes.zonesPadding} listPadding`}
              >
                {sortName(city.zones).map((zone, zoneIndex) => {
                  return (
                    <div key={`zone_${countryIndex}_${cityIndex}_${zoneIndex}`}>
                      <ListItem button onClick={() => onCollapseZones(zone.id)}>
                        {processedZones
                          .filter((item) =>
                            checkedSectors.includes(item.sector),
                          )
                          .some((item) => item.zone === zone.id) && (
                          <ListItemIcon>
                            <CheckRoundedIcon style={{ color: 'green' }} />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          className={classes.collapsableLevel}
                          inset
                          primary={zone.name}
                        />
                        {openedZones.includes(zone.id) ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ListItem>
                      <Collapse
                        in={openedZones.includes(zone.id)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div">
                          {sortName(zone.sectors).map((sector, sectorIndex) => {
                            return (
                              <ListItem
                                button
                                className={`${classes.zonesPadding} listPadding`}
                                key={`zone_${countryIndex}_${cityIndex}_${zoneIndex}_${sectorIndex}`}
                                button
                                onClick={() => handleCheckSector(sector.id)}
                              >
                                <Checkbox
                                  checked={checkedSectors.includes(sector.id)}
                                  color="secondary"
                                />
                                <ListItemText inset primary={sector.name} />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Collapse>
                    </div>
                  );
                })}
              </List>
            </Collapse>
          </div>
        );
      })}
    </List>
  );

  const renderCountries = () => (
    <List component="nav" className={classes.zonesPadding}>
      {sortName(zones).map((country, countryIndex) => {
        const cities = country.provinces.reduce((acc, val) => {
          acc = [...acc, ...val.cities];
          return acc;
        }, []);
        return (
          <div key={`zone_${countryIndex}`}>
            <ListItem button onClick={() => onCollapseCountry(country.id)}>
              {processedZones
                .filter((item) => checkedSectors.includes(item.sector))
                .some((item) => item.country === country.id) && (
                <ListItemIcon>
                  <CheckRoundedIcon style={{ color: 'green' }} />
                </ListItemIcon>
              )}
              <ListItemText
                className={classes.collapsableLevel}
                inset
                primary={country.name}
              />
              {openedCountries.includes(country.id) ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItem>
            <Collapse
              in={openedCountries.includes(country.id)}
              timeout="auto"
              unmountOnExit
            >
              {renderCities(
                cities,
                countryIndex,
                `${classes.zonesPadding} listPadding`,
              )}
            </Collapse>
          </div>
        );
      })}
    </List>
  );

  return level === 'countries'
    ? renderCountries()
    : renderCities(zones, '', classes.zonesPadding);
};

CollapsableZones.propTypes = {
  classes: PropTypes.object.isRequired,
  checkedSectors: PropTypes.array.isRequired,
  onCheckSector: PropTypes.func.isRequired,
  level: PropTypes.string.isRequired,
  filterBy: PropTypes.string,
};

export default withStyles(styles)(CollapsableZones);
