import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {filterParameters, sortParameters, createParameter, editParameter, getAllParameters} from '../../actions/parameters';
import {copyObject, SUPER_ADMIN_ROL_ID} from '../../utils/utils';
import {getPropertyTypes} from '../../actions/propertyTypes';
import CustomSnackbar from '../common/customSnackbar';
import ParametersList from './list';

export class Parameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parameter: null,
      isNew: false,
      filtersToShow: [],
      tab: 0,
      tabsAreVisible: true
    };
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.hideTabs = this.hideTabs.bind(this);
    this.showTabs = this.showTabs.bind(this);
    this.sortParameters = this.sortParameters.bind(this);
    this.createParameter = this.createParameter.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.editParameter = this.editParameter.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.orderData = this.orderData.bind(this);
    this.saveFilters = this.saveFilters.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.onEditRowClick = this.onEditRowClick.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.filterParameters(this.props.category, this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters).then(() => {
      this.props.getPropertyTypes();
      // this.props.getAllParameters(); // TODO VERIFICAR QUE NO AECTE EN PARAMETROS
    });
  }

  onEditRowClick(id) {
    const {parameters} = this.props;
    const parameter = copyObject(parameters.filter(obj => id === obj.id)[0]);
    this.setState({parameter});
    this.hideTabs();
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_attributes) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  hideTabs() {
    this.setState({tabsAreVisible: false});
  }

  handleAddNew() {
    this.setState({parameter: {}, isNew: true});
    this.hideTabs();
  }

  handleSearch(searchText) {
    this.setState({filtersToShow: []});
    this.props.filterParameters(this.props.category, this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText, {});
  }

  saveFilters(filters, filtersToShow) {
    this.setState({filtersToShow});
    this.props.filterParameters(this.props.category, this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, '', filters);
  }

  orderData(order, orderBy) {
    this.props.filterParameters(this.props.category, this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText, this.props.filters);
  }

  showTabs() {
    this.setState({tabsAreVisible: true});
  }

  handleGoBack() {
    this.props.filterParameters(this.props.category, this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters).then(() => {
      this.props.getAllParameters();
      this.showTabs();
      this.setState({parameter: null, isNew: false});
    });
  }

  handleChangeTab(index) {
    let category = 'personal';
    switch (index) {
    case 0:
      category = 'personal';
      break;
    case 1:
      category = 'unit';
      break;
    case 2: 
      category = 'project';
      break;
    case 3: 
      category = 'wizard';
      break;
    case 4: 
      category = 'orders';
      break;
    default: 
      category = 'personal';
      break;
    }
    this.setState({tab: index});

    const orderBy = category === 'orders' ? 'globalOrder' : 'name';
    const rowsPerPage = category === 'orders' ? 1000 : this.props.rowsPerPage;
    this.props.filterParameters(category, 0, rowsPerPage, 'asc', orderBy, '', this.props.filters);
  }

  handleChangeCategory(e) {
    const category = e.target.value;
    let index = 0;
    switch (category) {
    case 'personal':
      index = 0;
      break;
    case 'unit':
      index = 1;
      break;
    case 'project':
      index = 2;
      break;
    default:
      index = 0;
      break;
    }
    this.handleChangeTab(index);
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterParameters(this.props.category, this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters);
  }

  handleChangePage(page) {
    this.props.filterParameters(this.props.category, page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters);
  }

  async sortParameters(sortedParameters) {
    await this.props.sortParameters(sortedParameters, this.props.category);
    this.props.filterParameters(this.props.category, this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters);
  }

  createParameter(parameter) {
    this.props.createParameter(parameter).then(() => {
      this.handleGoBack();
    });
  }

  editParameter(currentParameter) {
    const {parameter} = this.state;
    const newParameter = currentParameter;
    this.setState({parameter: newParameter});
    const toEdit = {
      active: newParameter.active,
      enable_wizard: newParameter.enable_wizard,
      enable_web: newParameter.enable_web,
      isForMatch: newParameter.isForMatch
    };
    if (parameter.isMatchField !== newParameter.isMatchField) {
      toEdit.isMatchField = newParameter.isMatchField;
    }
    if (parameter.enabled !== newParameter.enabled) {
      toEdit.enabled = newParameter.enabled;
    }
    if (parameter.identifier !== newParameter.identifier) {
      toEdit.identifier = newParameter.identifier;
    }
    if (parameter.title !== newParameter.title) {
      toEdit.title = newParameter.title;
    }
    if (parameter.name !== newParameter.name) {
      toEdit.name = newParameter.name;
    }
    if (parameter.input_type !== newParameter.input_type) {
      toEdit.input_type = newParameter.input_type;
    }
    if (parameter.icon !== newParameter.icon) {
      toEdit.icon = newParameter.icon;
    }
    if (parameter.property_type !== newParameter.property_type) {
      toEdit.property_type = newParameter.property_type;
    }
    if (parameter.options !== newParameter.options) {
      if (newParameter.options.content) {
        if (newParameter.options.content.length > 0) {
          const content = newParameter.options.content.map((element) => {
            const contentItem = element;
            contentItem.answerId = `${newParameter.identifier}_${element.creationOrder ? element.creationOrder - 1 : 0}`;
            return contentItem;
          });
          newParameter.options.content = content;
        }
      }
      toEdit.options = newParameter.options;
    }
    this.props.editParameter(newParameter.id, toEdit).then(() => {
      this.handleGoBack();
    });
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, parameters, dataLength, category, allParameters} = this.props;

    const profileId = localStorage.getItem('profileId');
    const isSuperAdmin = SUPER_ADMIN_ROL_ID === profileId;

    return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <div><br />
        {this.renderSnackBar()}
        {
          this.state.tabsAreVisible === true &&
          <AppBar position="static" color="default">
            <Tabs value={this.state.tab} onChange={(e, index) => this.handleChangeTab(index)} variant="fullWidth">
              <Tab label="Personales" />    
              <Tab label="Unidades" />
              <Tab label="Desarrollos" />
              {isSuperAdmin && <Tab label="Asistente" />}
              {isSuperAdmin && <Tab label="Ordenamiento" />}
            </Tabs>
          </AppBar>
        }
        {
          parameters &&
          <ParametersList 
            category={category} 
            isLoading={isLoading} 
            parameters={parameters}  
            handleGoBack={this.handleGoBack}
            allParameters={allParameters}
            dataLength={dataLength}
            hideTabs={this.hideTabs} 
            handleAddNew={this.handleAddNew}
            createParameter={this.createParameter}
            editParameter={this.editParameter}
            isSorted={this.sortParameters}
            page={this.props.page}
            propertyTypes={this.props.propertyTypes}
            rowsPerPage={this.props.rowsPerPage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleChangePage={this.handleChangePage}
            handleSearch={this.handleSearch}
            order={this.props.order}
            orderData={this.orderData}
            handleChangeCategory={this.handleChangeCategory}
            saveFilters={this.saveFilters}
            orderBy={this.props.orderBy}
            filters={this.props.filters}
            filtersToShow={this.state.filtersToShow}
            searchText={this.props.searchText}
            parameter={this.state.parameter}
            onEditRowClick={this.onEditRowClick}
            isNew={this.state.isNew}
          />
        }
      </div>
    );
  }
}

Parameters.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPropertyTypes: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  filterParameters: PropTypes.func.isRequired,
  parameters: PropTypes.array.isRequired,
  allParameters: PropTypes.array.isRequired,
  dataLength: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  editParameter: PropTypes.func.isRequired,
  error: PropTypes.object,
  propertyTypes: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  getAllParameters: PropTypes.func.isRequired,
  sortParameters: PropTypes.func.isRequired,
  createParameter: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {parameters, propertyTypes} = state;
  return { 
    isLoading: parameters.sending,
    parameters: parameters.parameters,
    allParameters: parameters.allParameters,
    dataLength: parameters.dataLength, 
    message: parameters.message,
    error: parameters.error,
    propertyTypes: propertyTypes.propertyTypes,
    category: parameters.category,
    searchText: parameters.searchText,
    order: parameters.order,
    orderBy: parameters.orderBy,
    page: parameters.page,
    rowsPerPage: parameters.rowsPerPage,
    filters: parameters.filters
  };
};
const mapDispatchToProps = {
  filterParameters,
  getAllParameters,
  sortParameters,
  createParameter,
  editParameter,
  getPropertyTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(Parameters);
