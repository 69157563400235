import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {styles} from './styles';

export class EnhancedTableToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {classes, title} = this.props;
    return (
      <div>
        <Toolbar className={classes.root}>
          <Typography variant="h6" id="tableTitle" style={{width: '100%'}}>
            {title}
          </Typography>
        </Toolbar>
      </div>
    );
  }
}
  
EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(EnhancedTableToolbar);
