import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {filterRules, createRule, updateRule} from '../../actions/rules';
import {rulesOptions} from '../../utils/filterTypes';
import {getLeadStates} from '../../actions/leadStates';
import EnhancedTable from '../common/enhancedTable';
import {rulesCols} from '../../utils/colTypes';
import CustomSnackbar from '../common/customSnackbar';
import RuleDetail from './detail';
import {styles} from './styles';
import {copyObject} from '../../utils/utils';

const actions = [
  {
    id: 'add_action',
    name: 'Agregar próxima acción al lead'
  },
  {
    id: 'send_mail',
    name: 'Enviar correo'
  },
  {
    id: 'change_status',
    name: 'Cambiar estado'
  }
];

export class Rules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {           
      rule: null,
      isNew: false,
      title: 'Reglas',
      filterChips: [],
      filterOptions: []
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleUpdateRule = this.handleUpdateRule.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
  }

  componentWillMount() {
    this.setState({filterOptions: copyObject(rulesOptions)});
    this.getViewPermission();
    this.props.getLeadStates();
    this.props.filterRules(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters
    ).then(() => {
      this.renderFilters();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.renderFilters();
    }
  }

  onAdd() {
    this.setState({isNew: true, rule: {}});
  }

  onDetail(id) {
    const rule = this.props.rules.filter(obj => obj.id === id)[0];
    this.setState({rule, isNew: false});
  }

  onSearch(searchText) {
    this.props.filterRules(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText, this.props.filters);
  }

  onOrder(order, orderBy) {
    this.props.filterRules(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText, this.props.filters);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_rules) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_rules) {
        return true;
      }
    }
    return false;
  }

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_rules) {
        return true;
      }
    }
    return false;
  }

  handleFilter(filters, filterChips) {
    const filtersToApply = filters;
    this.setState({filterChips});
    this.props.filterRules(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, filtersToApply);
  }

  handleUpdateRule(ruleEdited) {
    const {rule} = this.state;
    this.setState({rule: ruleEdited});
    const edited = {};
    if (rule.active !== ruleEdited.active) {
      edited.active = ruleEdited.active;
    }
    if (rule.name !== ruleEdited.name) {
      edited.name = ruleEdited.name;
    }
    if (rule.icon !== ruleEdited.icon) {
      edited.icon = ruleEdited.icon;
    }
    this.props.updateRule(ruleEdited.id, edited);
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterRules(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters);
  }

  
  handleChangePage(page) {
    this.props.filterRules(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters);
  }

  handleGoBack() {
    this.props.filterRules(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText, this.props.filters);
    this.setState({rule: null, isNew: false});
  }

  renderFilters() {
    const {leadStates, leadStages} = this.props;
    let defaultFilters = [];
    if (leadStates.length > 0) {
      defaultFilters = copyObject(rulesOptions).map((item) => {
        const filter = item;
        if (filter.id === 'status') {
          const types = [{title: 'Todos', value: 'all'}];
          leadStates.forEach((leadState) => {
            const newType = {
              title: leadState.name,
              value: leadState.id
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'stage') {
          const types = [{title: 'Todos', value: 'all'}];
          leadStages.forEach((leadStage) => {
            const newType = {
              title: leadStage.name,
              value: leadStage._id
            };
            types.push(newType);
          });
          filter.types = types;
        }
        return filter;
      });
    }
    if (defaultFilters.length > 0) {
      this.setState({filterOptions: defaultFilters});
    }
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, isLoadingProfile, rules, dataLength, leadStates, leadStages, filters} = this.props;
    const {title, rule, isNew, filterOptions, filterChips} = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const data = {
      rows: rules,
      cols: rulesCols,
      autoFillers: {leadStates, leadStages, actions}
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onFilter: this.handleFilter,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: true,
        options: filterOptions,
        chips: filterChips,
        by: filters
      }
    };
    if (isLoading === true || isLoadingProfile === true) {
      return (
        <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {
          rule === null ?
            <EnhancedTable 
              title={title}
              data={data} 
              handlers={handlers}
              config={config}
            />  
            :
            <RuleDetail 
              rule={rule}
              handleGoBack={this.handleGoBack}
              handleCreateRule={this.props.createRule}
              handleUpdateRule={this.handleUpdateRule}
              isLoading={isLoading}
              isNew={isNew}
              actions={actions}
            />
        }
      </div>
    );
  }
}

Rules.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  rules: PropTypes.array.isRequired,
  leadStates: PropTypes.array.isRequired,
  isLoadingProfile: PropTypes.bool.isRequired,
  leadStages: PropTypes.array.isRequired,
  getLeadStates: PropTypes.func.isRequired,
  filterRules: PropTypes.func.isRequired,
  createRule: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  dataLength: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {rules, leadStates} = state;
  return { 
    isLoading: rules.sending, 
    rules: rules.rules,
    leadStates: leadStates.leadStates,
    leadStages: leadStates.leadStages,
    dataLength: rules.dataLength, 
    error: rules.error,
    message: rules.message,
    searchText: rules.searchText,
    order: rules.order,
    orderBy: rules.orderBy,
    page: rules.page,
    rowsPerPage: rules.rowsPerPage,
    filters: rules.filters
  };
};
  
const mapDispatchToProps = {
  filterRules,
  createRule,
  updateRule,
  getLeadStates
};
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Rules));
