import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  CircularProgress,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Editor from '../../../common/editor.js/index.js';
import ConfirmationDialog from '../../../common/confirmationDialog/index';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Uploader from '../../../common/uploader';

const NewsItemDetail = (props) => {
  const { classes, newsItem, isLoading, isNew, handleGoBack, handleCreate, handleUpdate } = props;

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [active, setActive] = useState(true);
  const [mainImage, setMainImage] = useState();

  useEffect(() => {
    if (!isNew) {
      setTitle(newsItem.title);
      setContent(newsItem.content);
      setActive(newsItem.active);
      setMainImage(newsItem.mainImage);
    }
  }, []);

  const onAdd = () => handleCreate({ title, content, mainImage });

  const onEdit = () => handleUpdate({ ...newsItem, title, content, active, mainImage });

  const renderButton = isLoading ? (
    <CircularProgress />
  ) : (
    <ConfirmationDialog
      confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} esta noticia?`}
      buttonText={`${isNew ? 'Agregar' : 'Actualizar'} noticia`}
      variant="contained"
      isDisabled={!title.length || !content.length || !mainImage}
      confirmAction={isNew ? onAdd : onEdit}
    />
  );

  return (
    <Paper elevation={4} className={classes.paperRoot}>
      <Toolbar>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">{isNew ? 'Nueva' : 'Actualizar'} Noticia</Typography>
      </Toolbar>
      <Table>
        <TableBody>
          {!isNew && (
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Disponibilidad</b>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={active}
                      onChange={({ target: { checked } }) => setActive(checked)}
                      color="primary"
                    />
                  }
                  label={active === true ? 'Disponible' : 'No disponible'}
                />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className={classes.tableRowTitle}>
              <b>Título</b>
            </TableCell>
            <TableCell>
              <TextField
                className={classes.title}
                autoFocus
                value={title}
                onChange={({ target: { value } }) => setTitle(value)}
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableRowTitle}>
              <b>Imagen Principal</b>
            </TableCell>
            <TableCell>
              {mainImage && <img src={mainImage} alt="Foto" style={{ width: 100 }} />}
              <Uploader
                uploaderId="uploader-mainImage"
                buttonText={mainImage ? 'Actualizar imagen' : 'Subir imagen'}
                photo
                dirName="News"
                urlUploaded={setMainImage}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className={classes.content}>
        <Editor
          createMode={isNew}
          content={!isNew ? newsItem.content : ''}
          setContent={setContent}
        />
      </div>
      <div className={classes.submitContainer}>{renderButton}</div>
    </Paper>
  );
};

NewsItemDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  newsItem: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isNew: PropTypes.bool,
  handleGoBack: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default withStyles(styles)(NewsItemDetail);
