import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import {InlineDatePicker} from 'material-ui-pickers';
import {withStyles, TextField} from '@material-ui/core';
import {styles} from './styles';
import {dateFormat, isNumber} from '../../../../../utils/utils';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

export class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      filters: [],
      active: false
    };
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.getValue = this.getValue.bind(this);
    this.renderItems = this.renderItems.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.getFiltersToShowValue = this.getFiltersToShowValue.bind(this);
    this.renderFilterType = this.renderFilterType.bind(this);
    this.renderSelectValue = this.renderSelectValue.bind(this);
    this.startFilters = this.startFilters.bind(this);
  }

  componentWillMount() {
    this.startFilters();
  }

  onFilter() {
    const {filters} = this.state;
    const filtersToSend = [];
    filters.forEach((filter) => {
      let shouldAdd = true;
      if (filter.type === 'number' && filter.value !== '') {
        // eslint-disable-next-line no-param-reassign
        filter.value = Number(filter.value);
      } else if (filter.type === 'numberRange' && filter.value !== '' && filter.value !== 'all') {
        // eslint-disable-next-line prefer-destructuring
        const value = filter.value;
        value.$gt = Number(filter.value.$gt);
        value.$lt = Number(filter.value.$lt);
        // eslint-disable-next-line no-param-reassign
        filter.value = value;
      } else if (filter.type === 'dateRange' && filter.value !== '' && filter.value !== 'all') {
        // eslint-disable-next-line prefer-destructuring
        const value = filter.value;
        value.start = moment(filter.value.start).format(dateFormat).substring(0, 10);
        value.end = moment(filter.value.end).format(dateFormat).substring(0, 10);
        // eslint-disable-next-line no-param-reassign
        filter.value = value;
      } else if (filter.type === 'select') {
        if (filter.value === 'all') {
          shouldAdd = false;
        } else if (Array.isArray(filter.value)) {
          if (filter.value.length > 0) {
            const indexOfAll = filter.value.indexOf('all');
            if (indexOfAll > -1) {
              filter.value.splice(indexOfAll, 1);
            }
          }
        }
      }
      if (shouldAdd) {
        filtersToSend.push(filter);
      }
    });
    this.props.onFilter(filtersToSend);
    this.setState({open: false});
  }

  getFilterTitle(target) {
    const {name, value} = target;
    const filter = this.props.options.filter(obj => obj.id === name)[0];
    const titleArr = [];
    value.forEach((valueItem) => {
      const item = filter.types.filter(obj => valueItem === obj.value)[0];
      if (item) {
        if (item.title) {
          titleArr.push(item.title);
        }
      }
    });
    const indexOfAll = titleArr.indexOf('Todos');
    if (indexOfAll > -1) {
      titleArr.splice(indexOfAll, 1);
    }
    return titleArr.join(', ');
  }

  getValue(filter, date = false, numberRange = false, isFromOrTo = 'from', dateRange = false, select = false) {
    const {filters} = this.state;
    const valueArr = filters.filter(obj => obj.id === filter.id);
    if (valueArr.length > 0) {
      if (date === true) {
        return moment(valueArr[0].value).format(dateFormat);
      } else if (numberRange === true) {
        if (isFromOrTo === 'from') {
          return valueArr[0].value.$gt;
        } 
        return valueArr[0].value.$lt;
      } else if (dateRange === true) {
        if (valueArr[0].value === 'all') {
          return null;
        }
        if (isFromOrTo === 'from') {
          return valueArr[0].value.start;
        } 
        return valueArr[0].value.end;
      }
      if (select) { 
        if (valueArr[0].value === 'all') {
          return [valueArr[0].value];
        }
      }
      return valueArr[0].value;
    }
    return null;
  }

  getFiltersToShowValue(filter) {
    let filters = {
      id: filter.id,
      title: 'Todos',
      value: 'all', 
      type: filter.type,
      label: filter.label
    };
    if (filter.type === 'date' || filter.type === 'text' || filter.type === 'range' || filter.type === 'number') {
      filters = {
        id: filter.id,
        title: '',
        value: '',
        type: filter.type,
        label: filter.label
      };
    }
    if (filter.attribute) {
      filters.attribute = true;
    }
    for (let i = 0; i < this.props.chips.length; i += 1) {
      const element = this.props.chips[i];
      if (filter.id === element.id) {
        filters = {
          value: element.value,
          id: element.id,
          title: element.title,
          label: filter.label,
          type: element.type
        };
        if (filter.attribute) {
          filters.attribute = true;
        }
      }
    }
    return filters;
  }

  changeFilter(e, type = 'select', name = null, isFromOrTo = 'from') {
    const filters = this.state.filters.map((element) => {
      if (type === 'select') {
        if (element.id === e.target.name) {
          const changingFilter = {
            value: e.target.value,
            title: this.getFilterTitle(e.target),
            id: element.id,
            type,
            label: element.label
          };
          return changingFilter;
        }
      } else if (type === 'date') {
        if (element.id === name) {
          return {
            value: e,
            title: moment(e).format(dateFormat),
            id: element.id,
            type,
            label: element.label
          };
        }
      } else if (type === 'dateRange') {
        if (element.id === name) {
          // eslint-disable-next-line prefer-destructuring
          let value = element.value;
          if (value === 'all') {
            value = { 
              start: '', 
              end: ''
            };
          }
          if (isFromOrTo === 'from') {
            value.start = e;
          } else {
            value.end = e;
          }
          return {
            value,
            title: moment(e).format(dateFormat),
            id: element.id,
            type,
            label: element.label
          };
        }
      } else if (type === 'text') {
        if (element.id === name) {
          return {
            value: e.target.value,
            title: e.target.value,
            id: element.id,
            type,
            label: element.label
          };
        }
      } else if (type === 'number' || type === 'range') {
        if (isNumber(e.target.value)) {
          if (element.id === name) {
            return {
              value: e.target.value,
              title: e.target.value,
              id: element.id,
              type,
              label: element.label
            };
          }
        }
      } else if (type === 'numberRange') {
        if (isNumber(e.target.value)) {
          if (element.id === name) {
            // eslint-disable-next-line prefer-destructuring
            let value = element.value;
            if (value === 'all') {
              value = { 
                $gt: '', 
                $lt: ''
              };
            }
            if (isFromOrTo === 'from') {
              value.$gt = e.target.value;
            } else {
              value.$lt = e.target.value;
            }
            return {
              value,
              title: e.target.value,
              id: element.id,
              type,
              label: element.label
            };
          }
        }
      }
      return element;
    });
    this.setState({filters});
  }

  startFilters() {
    if (this.props.options) {
      if (this.props.options.length > 0) {
        const filters = this.props.options.map(filter => this.getFiltersToShowValue(filter));
        this.setState({active: true, filters});
      }
    }
  }

  openDialog() {
    this.setState({open: true});
  }
  
  closeDialog() {
    this.setState({open: false});
    this.startFilters();
  }

  renderSelectValue(selected, filter) {
    const {classes} = this.props;
    const chipsArray = selected.map((value, index) => {
      const titleArr = filter.types.filter(obj => obj.value === value);
      if (titleArr) {
        if (titleArr[0]) {
          const label = titleArr[0].title;
          if (label) {
            return (
              <Chip key={index} label={label} className={classes.chip} />
            );
          }
        }
      }
      return null;
    });
    return (
      <div className={classes.chips}>
        {chipsArray}
      </div>
    );
  }

  renderFilterType(filter) {
    const {classes} = this.props;
    if (filter.type === 'date') {
      return (
        <InlineDatePicker 
          value={this.getValue(filter, true)} 
          fullWidth
          clearable
          emptyLabel=""
          invalidLabel=""
          invalidDateMessage=""
          autoOk
          onChange={e => this.changeFilter(e, 'date', filter.id)}
          label={filter.label}
          format={dateFormat}
          className={classes.datePicker}
        />
      );
    } else if (filter.type === 'dateRange') {
      return (
        <div>
          <InlineDatePicker 
            value={this.getValue(filter, false, false, 'from', true)} 
            clearable
            emptyLabel=""
            invalidLabel=""
            invalidDateMessage=""
            autoOk
            onChange={e => this.changeFilter(e, 'dateRange', filter.id, 'from')}
            label={filter.label}
            format={dateFormat}
            className={classes.datePickerRange}
          />
          <InlineDatePicker 
            value={this.getValue(filter, false, false, 'to', true)} 
            clearable
            emptyLabel=""
            invalidLabel=""
            invalidDateMessage=""
            autoOk
            onChange={e => this.changeFilter(e, 'dateRange', filter.id, 'to')}
            label={filter.label}
            format={dateFormat}
            className={classes.datePickerRange}
          />
        </div>
      );
    } else if (filter.type === 'text' || filter.type === 'range' || filter.type === 'number') {
      return (
        <TextField 
          fullWidth
          variant="outlined"
          label={filter.label}
          onChange={e => this.changeFilter(e, filter.type, filter.id)}
          value={this.getValue(filter)}
        />
      );
    } else if (filter.type === 'numberRange') {
      return (
        <div>
          <TextField 
            variant="outlined"
            label={`${filter.label} desde: `}
            onChange={e => this.changeFilter(e, filter.type, filter.id, 'from')}
            value={this.getValue(filter, false, true, 'from')}
            style={{width: '50%'}}
          />
          <TextField 
            variant="outlined"
            label={`${filter.label} hasta: `}
            onChange={e => this.changeFilter(e, filter.type, filter.id, 'to')}
            value={this.getValue(filter, false, true, 'to')}
            style={{width: '50%'}}
          />
        </div>
      );
    }
    const val = this.getValue(filter, false, false, null, false, true);
    return (
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel htmlFor={`${filter.id}-filter`}>{filter.label}</InputLabel>
        <Select 
          value={val}
          inputProps={{
            name: filter.id,
            id: `${filter.id}-filter`
          }}
          onChange={e => this.changeFilter(e)}
          multiple
          renderValue={selected => this.renderSelectValue(selected, filter)}
        >
          {this.renderItems(filter)}
        </Select>
      </FormControl>
    );
  }

  renderFilters() {
    const {classes, options} = this.props;
    const {filters} = this.state;
    if (filters.length > 0) {
      return options.map((filter, filterIndex) => {
        return (
          <div className={classes.filterSelect} key={filterIndex}>
            {this.renderFilterType(filter)}
          </div>
        );
      });
    }
    return null;
  }


  renderItems = (filter) => {
    const {filters} = this.state;
    const {classes} = this.props;
    const items = filter.types;
    if (filter.id === 'stage') {
      if (items) {
        const currentLeadState = filters.filter(obj => obj.id === 'status')[0];
        if (currentLeadState) {
          return items.filter(obj => currentLeadState.value.includes(obj.leadState)).map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {`${item.title} `}
                {
                  item.leadStateName && 
                    (typeof (item.leadStateName) === 'string' && item.leadStateName !== null && item.leadStateName !== '') && 
                      (<span className={classes.menuItemSpan}>{item.leadStateName}</span>)
                }
              </MenuItem>
            );
          });
        }
      } 
    }

    if (items) {
      return items.map((item, index) => {
        return (
          <MenuItem key={index} value={item.value}>
            {item.title}
          </MenuItem>
        );
      });
    } 
    return null;
  }

  render() {
    const {active} = this.state;
    return active === false ? '' : (
      <div>
        <Tooltip title="Filtrar">
          <IconButton onClick={this.openDialog}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Dialog 
          disableBackdropClick 
          disableEscapeKeyDown 
          maxWidth="md" 
          fullWidth 
          open={this.state.open} 
          onClose={this.closeDialog} 
          TransitionComponent={Transition} 
        >
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>
            {this.renderFilters()}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.onFilter} color="primary">
              Filtrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  chips: PropTypes.array.isRequired
};

export default ((withStyles)(styles)(Filters));
