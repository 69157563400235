import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CheckBox from '@material-ui/icons/CheckBox';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import {styles} from './styles';

export class ImagePickerMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerId: []
    };
    this.renderAnswers = this.renderAnswers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answerId} = currentAnswer;
      if (answerId.length > 0) {
        this.setState({answerId});
      }
    }
  }

  handleChange(id) {
    const {answerId} = this.state;
    const {identifier, permissions} = this.props;
    if (!permissions) {
      return;
    }
    if (answerId.includes(id)) {
      const index = answerId.indexOf(id);
      if (index > -1) {
        answerId.splice(index, 1);
      }
    } else {
      answerId.push(id);
    }
    this.setState({answerId});
    this.props.updateAnswer(identifier, answerId, '');
  }

  renderAnswers() {
    const {classes} = this.props;
    return this.props.answers.map((options, index) => {
      return (
        <div key={index} className={classes.cell}>
          <Typography variant="body1" style={{cursor: 'pointer', marginBottom: 10}} onClick={() => this.handleChange(options.answerId)}>{options.value}</Typography>
          <GridListTile className={classes.gridListTile}>
            <button className={classes.transparentButton} onClick={() => this.handleChange(options.answerId)} >
              <img 
                src={options.url} 
                alt={options.value} 
                className={classes.image} 
              />
            </button>
            <GridListTileBar
              className={classes.gridTile}
              onClick={() => this.handleChange(options.answerId)}
              actionIcon={this.state.answerId.includes(options.answerId) &&
              <div className={classes.iconContainer}>
                <IconButton className={classes.icon} onClick={() => this.handleChange(options.answerId)}>
                  <CheckBox fontSize="large" />
                </IconButton>
              </div>
              }
            />
          </GridListTile>
        </div>
      );
    });
  }

  render() {
    return this.renderAnswers();
  } 
}

ImagePickerMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    url: PropTypes.string,
    answerId: PropTypes.string
  })).isRequired,
  updateAnswer: PropTypes.func.isRequired,
  currentAnswer: PropTypes.object,
  permissions: PropTypes.bool,
  identifier: PropTypes.string.isRequired
};

export default withStyles(styles)(ImagePickerMultiple);
