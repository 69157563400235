import moment from 'moment';
import * as dashboardActions from '../actions/types/dashboard';

const initialState = {
  dashboard: {},
  ranking: [],
  filters: {
    startDate: moment(new Date()).subtract(1, 'months'),
    endDate: new Date(),
    brokers: [],
  },
  rankingFilters: {},
  message: '',
  sending: false,
  sendingRanking: false,
  error: null,
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const { dashboard, error, message, filters, rankingFilters, ranking } = action.payload;
  switch (action.type) {
    case dashboardActions.GET_DASHBOARD_SENDING:
      return { ...state, sending: true, filters };
    case dashboardActions.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        sending: false,
        dashboard,
        error: null,
        message: 'Dashboard obtenido satisfactoriamente',
      };
    case dashboardActions.GET_DASHBOARD_FAILED:
      return { ...state, sending: false, error, message };
    case dashboardActions.GET_RANKING_SENDING:
      return { ...state, sendingRanking: true, rankingFilters };
    case dashboardActions.GET_RANKING_SUCCESS:
      return {
        ...state,
        sendingRanking: false,
        ranking,
        error: null,
        message: 'Ranking obtenido satisfactoriamente',
      };
    case dashboardActions.GET_RANKING_FAILED:
      return { ...state, sendingRanking: false, error, message };
    case dashboardActions.CHANGE_FILTERS:
      return { ...state, filters };
    default:
      return state;
  }
};
