import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import copy from 'clipboard-copy';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  IconButton,
  Toolbar,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Snackbar,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Chip,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ReplayIcon from '@material-ui/icons/Replay';
import { styles } from './styles';
import {
  generateToken,
  oauthDeletePage,
  oauthGetCredentials,
  updateClientPassword,
} from './../../../../actions/oauth';
import FbDialogCredentials from './fbDialogCredentials';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const OauthCredentials = (props) => {
  const {
    classes,
    openIntegration,
    closeDialog,
    companyName,
    generateOauthCredentials,
    companyId,
    origins,
  } = props;

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [userToken, setUserToken] = useState();
  const [facebookUrl, setFacebookUrl] = useState();
  const [facebookToken, setFacebookToken] = useState();
  const [showUrl, setShowUrl] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [facebookCredentials, setFacebookCredentials] = useState([]);
  const [pageToDelete, setPageToDelete] = useState();
  const [clientId, setClientId] = useState();
  const [secret, setSecret] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarCredentials, setOpenSnackBarCredentials] = useState(null);
  const [openSnackBarDeleteIntegration, setOpenSnackBarDeleteIntegration] = useState();
  const [openSnackBarGenerateToken, setOpenSnackBarGenerateToken] = useState();
  const [openSnackBarNewPassword, setOpenSnackBarNewPassword] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openTokenDialog, setOpenTokenDialog] = useState(false);
  const [openNewPassword, setOpenNewPassword] = useState(false);

  useEffect(() => {
    (async () => {
      const credentials = await oauthGetCredentials(companyId);
      if (credentials) {
        setUsername(credentials.username);
        setPassword(credentials.password);
        setUserToken(credentials.accessToken);
        setClientId(credentials.clientId);
        setSecret(credentials.secret);
        if (credentials.facebookUrl && credentials.accessToken) {
          setFacebookUrl(credentials.facebookUrl);
          setFacebookToken(credentials.accessToken);
        }
        if (credentials.facebookCredentials) {
          setFacebookCredentials(credentials.facebookCredentials);
        }
      } else {
        setIsNew(true);
      }
    })();
  }, []);

  const handleNext = async (newFbCredentials) => {
    setOpenConfirmation(false);
    const result = await generateOauthCredentials(
      (Array.isArray(newFbCredentials) && newFbCredentials) || facebookCredentials,
      !Array.isArray(newFbCredentials),
    );
    if (result) {
      const credentials = await oauthGetCredentials(companyId);
      if (credentials) {
        setIsNew(false);
        setUsername(credentials.username);
        setPassword(credentials.password);
        setUserToken(credentials.accessToken);
        setClientId(credentials.clientId);
        setSecret(credentials.secret);
        if (credentials.facebookUrl && credentials.accessToken) {
          setFacebookUrl(credentials.facebookUrl);
          setFacebookToken(credentials.accessToken);
        }
        if (credentials.facebookCredentials) {
          setFacebookCredentials(credentials.facebookCredentials);
        }
      }
      setOpenSnackBarCredentials(result);
    } else {
      setOpenSnackBarCredentials(result);
    }
  };

  const addFbCredentials = async (data) => {
    const newFbCredentials = [...facebookCredentials, { ...data, temporal: true }];
    setFacebookCredentials(newFbCredentials);
    if (!isNew) handleNext(newFbCredentials);
  };

  const onCopy = (value) => {
    copy(value);
    setOpenSnackBar(true);
  };

  const getCredentials = async () => {
    const credentials = await oauthGetCredentials(companyId);
    setUsername(credentials.username);
    setPassword(credentials.password);
    setUserToken(credentials.accessToken);
    setClientId(credentials.clientId);
    setSecret(credentials.secret);
    if (credentials.facebookUrl && credentials.accessToken) {
      setFacebookUrl(credentials.facebookUrl);
      setFacebookToken(credentials.accessToken);
    }
    if (credentials.facebookCredentials) {
      setFacebookCredentials(credentials.facebookCredentials);
    }
  };

  const handleDeleteSubscription = async (pageId) => {
    try {
      await oauthDeletePage(companyId, pageId);
      setOpenSnackBarDeleteIntegration(true);
    } catch (error) {
      setOpenSnackBarDeleteIntegration(false);
    }
    await getCredentials();
    setPageToDelete(null);
  };

  const handleNewToken = async () => {
    try {
      await generateToken(clientId, secret);
      setOpenSnackBarGenerateToken(true);
    } catch (error) {
      setOpenSnackBarGenerateToken(false);
    }
    await getCredentials();
    setOpenTokenDialog(null);
  };

  const handleNewPassword = async () => {
    try {
      await updateClientPassword(companyId);
      setOpenSnackBarNewPassword(true);
    } catch (error) {
      setOpenSnackBarNewPassword(false);
    }
    await getCredentials();
    setOpenNewPassword(null);
  };

  const renderUserCredentials = () => {
    return (
      <div className={classes.root}>
        <Grid container spacing={8} justify="center">
          <Grid item xs={12}>
            <Card elevation={0} className={classes.cardContainer}>
              <CardHeader
                className={classes.cardHeader}
                title="Credenciales de usuario"
                action={
                  <IconButton
                    aria-label="settings"
                    className={classes.copyIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                }
              />
              <CardContent className={classes.cardContent}>
                <Button
                  className={classes.selectableText}
                  variant="text"
                  onClick={() => onCopy(username)}
                >
                  <span className={classes.textHighlight}>Usuario:</span>
                  &nbsp;&nbsp;{username}
                </Button>
                <br />
                <Button
                  className={classes.selectableText}
                  variant="text"
                  onClick={() => onCopy(password)}
                >
                  <span className={classes.textHighlight}>Contraseña:</span>
                  &nbsp;&nbsp;{showPassword ? password : '●●●●●●●●●●●●●●●'}
                </Button>
                <Tooltip
                  title="Generar nueva contrseña"
                  placement="right"
                  style={{ display: 'inline-block' }}
                >
                  <IconButton
                    className={classes.reloadIcon}
                    onClick={() => setOpenNewPassword(true)}
                  >
                    <ReplayIcon />
                  </IconButton>
                </Tooltip>

                <br />
                <Button
                  className={classes.selectableText}
                  variant="text"
                  onClick={() => onCopy(userToken)}
                >
                  <span className={classes.textHighlight}>TOKEN DE INTEGRACIÓN:</span>
                  &nbsp;&nbsp;
                  {showPassword ? userToken : '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●'}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderFacebookCredentials = (title, value, visible, visibleFunction) => {
    return (
      <div className={classes.root}>
        <Grid container spacing={8} justify="center">
          <Grid item xs={10}>
            <Card elevation={0} className={classes.cardContainer}>
              <CardHeader
                className={classes.cardHeader}
                title={title}
                action={
                  <IconButton
                    aria-label="settings"
                    className={classes.copyIcon}
                    onClick={() => visibleFunction(!visible)}
                  >
                    {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                }
              />
              <CardContent className={classes.cardContent}>
                {visible ? (
                  <Button
                    className={classes.selectableText}
                    variant="text"
                    onClick={() => onCopy(value)}
                  >
                    {value}
                  </Button>
                ) : (
                  <Typography variant="h6" style={{ color: 'white' }}>
                    ●●●●●●●●●●●●●●●
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderFbItems = () => {
    return facebookCredentials.map((itemFB, index) => {
      const origin = origins && origins.find((o) => o.id === itemFB.origin);
      return (
        <ExpansionPanel key={`expansion_${index}`}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {itemFB.name}{' '}
              <Chip
                className={`${classes.chip} ${itemFB.subscribed ? 'subscribed' : ''}`}
                label={itemFB.subscribed ? 'suscrito' : 'no suscrito'}
              />
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.bodyPanel}>
            <div className={classes.itemListCredentials}>
              <span className={classes.subtitleList}>Identificador de la aplicación: </span>
              <span>{itemFB.appId}</span>
            </div>
            <div className={classes.itemListCredentials}>
              <span className={classes.subtitleList}>Identificador de la página: </span>
              <span>{itemFB.pageId}</span>
            </div>
            <div className={classes.itemListCredentials}>
              <span className={classes.subtitleList}>Token de usuario: </span>
              <span className={classes.textToken}>{itemFB.facebookUserToken}</span>
            </div>
            <div className={classes.itemListCredentials}>
              <span className={classes.subtitleList}>Origen: </span>
              <span>{(origin && origin.name) || 'NULL'}</span>
            </div>
            {!itemFB.temporal && (
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.deleteButton}
                  variant="contained"
                  onClick={() => setPageToDelete(itemFB.pageId)}
                >
                  Eliminar
                </Button>
              </div>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  const renderCustomDialog = () => {
    const data = [
      {
        open: openConfirmation,
        content: '¿Está seguro que desea generar las credenciales?',
        onClose: () => setOpenConfirmation(false),
        onSuccess: handleNext,
      },
      {
        open: !!pageToDelete,
        content: '¿Está seguro que desea eliminar esta integración?',
        onClose: () => setPageToDelete(false),
        onSuccess: () => handleDeleteSubscription(pageToDelete),
      },
      {
        open: openTokenDialog,
        content: '¿Está seguro que desea generar un nuevo token?',
        onClose: () => setOpenTokenDialog(false),
        onSuccess: handleNewToken,
      },
      {
        open: openNewPassword,
        content: '¿Está seguro que desea generar una nueva contraseña?',
        onClose: () => setOpenNewPassword(false),
        onSuccess: handleNewPassword,
      },
    ];

    return data.map((dialog, index) => (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        open={dialog.open}
        TransitionComponent={Transition}
        fullWidth
        key={`dialog_${index}`}
      >
        <DialogTitle disableTypography>
          <Typography variant="h5">{dialog.title || 'Confirmación'}</Typography>
        </DialogTitle>
        <DialogContent>{dialog.content}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={dialog.onClose}>
            {dialog.closeText || 'Cancelar'}
          </Button>
          <Button color="primary" onClick={dialog.onSuccess}>
            {dialog.successText || 'Continuar'}
          </Button>
        </DialogActions>
      </Dialog>
    ));
  };

  const handleCloseSnackBar = (reason, onClose) => {
    if (reason === 'clickaway') return;
    onClose();
  };

  const renderCustomSnackBars = () => {
    const data = [
      {
        type: 'success',
        open: openSnackBar,
        onClose: setOpenSnackBar,
        message: ['Texto copiado en el portapapeles'],
      },
      {
        type: 'tandem',
        open: openSnackBarCredentials,
        onClose: setOpenSnackBarCredentials,
        message: ['Credenciales generadas exitosamente', 'Error al generar credenciales'],
      },
      {
        type: 'tandem',
        open: openSnackBarDeleteIntegration,
        onClose: setOpenSnackBarDeleteIntegration,
        message: ['Integración eliminada exitosamente', 'Error al eliminar la integración'],
      },
      {
        type: 'tandem',
        open: openSnackBarGenerateToken,
        onClose: setOpenSnackBarGenerateToken,
        message: ['Token generado exitosamente', 'Error al generar el token'],
      },
      {
        type: 'tandem',
        open: openSnackBarNewPassword,
        onClose: setOpenSnackBarNewPassword,
        message: ['Contraseña generado exitosamente', 'Error al generar la contraseña'],
      },
    ];

    const component = (snackProps) => (
      <Snackbar
        ContentProps={{ classes: { root: classes[snackProps.class] } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={2000}
        open={snackProps.open}
        onClose={(_, reason) => handleCloseSnackBar(reason, () => snackProps.onClose(null))}
        message={snackProps.message}
      />
    );

    return data.reduce((acc, val, index) => {
      if (val.type === 'success') {
        acc = [...acc, component({ ...val, message: val.message[0], class: 'snack' })];
      } else {
        acc = [
          ...acc,
          component({ ...val, message: val.message[0], class: 'snack' }),
          component({
            ...val,
            message: val.message[1],
            class: 'snackError',
            open: val.open === false,
          }),
        ];
      }
      return acc;
    }, []);
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        fullWidth
        open={openIntegration}
        TransitionComponent={Transition}
        scroll="body"
      >
        <DialogTitle disableTypography>
          <Typography className={classes.title} variant="h5">
            {companyName}
          </Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Toolbar className={classes.toolbarRoot}>
              <Typography variant="h6">
                Credenciales de integración con aplicaciones de terceros
              </Typography>
            </Toolbar>
            {username && username !== '' && password && password !== '' && renderUserCredentials()}
            <br />
            <Toolbar className={classes.toolbarRoot}>
              <Typography variant="h6">Credenciales de integración con Facebook</Typography>
              <div className={classes.fbActionContainer}>
                <FbDialogCredentials
                  addFbCredentials={addFbCredentials}
                  origins={origins.filter((origin) => origin.company._id === companyId)}
                />
              </div>
            </Toolbar>
            <div className={classes.expandedContainer}>
              {facebookCredentials.length > 0 ? renderFbItems() : ''}
            </div>
            <br />
            <br />
            {facebookUrl &&
              facebookUrl !== '' &&
              facebookToken &&
              facebookToken !== '' &&
              facebookCredentials.length > 0 &&
              renderFacebookCredentials(
                'URL de devolución de llamada',
                facebookUrl,
                showUrl,
                setShowUrl,
              )}
            {facebookUrl &&
              facebookUrl !== '' &&
              facebookToken &&
              facebookToken !== '' &&
              facebookCredentials.length > 0 &&
              renderFacebookCredentials(
                'Identificador de verificación',
                facebookToken,
                showToken,
                setShowToken,
              )}
          </div>
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => (isNew ? setOpenConfirmation(true) : setOpenTokenDialog(true))}
          >
            {isNew ? 'Generar Credenciales' : 'Regenerar Token'}
          </Button>
        </DialogActions>
        <br />
      </Dialog>
      {renderCustomDialog()}
      {renderCustomSnackBars()}
    </div>
  );
};

OauthCredentials.propTypes = {
  classes: PropTypes.object.isRequired,
  generateOauthCredentials: PropTypes.func.isRequired,
  openIntegration: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  origins: PropTypes.array.isRequired,
};

export default withStyles(styles)(OauthCredentials);
