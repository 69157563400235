import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Select,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  FormControlLabel,
  Switch,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import ConfirmationDialog from '../../common/confirmationDialog';
import {
  getCompanyProformaConfig,
  updateCompanyProformaConfig,
} from '../../../actions/proformas';
import { getEmailTemplates } from '../../../actions/emailTemplates';
import CustomSnackbar from '../../common/customSnackbar';
import { SUPER_ADMIN_ROL_ID } from '../../../utils/utils';
import AttachmentsList from '../../common/attachmentsList';
import Uploader from './../../common/uploader/index';

const company = JSON.parse(localStorage.getItem('company'));
const profile = JSON.parse(localStorage.getItem('profile'));

const Proforma = (props) => {
  const { companyConfig, sending, error, message } = useSelector(
    (state) => state.proformas,
  );

  const { emailTemplates } = useSelector((state) => state.emailTemplates);

  const [details, setDetails] = useState({
    projectSection: false,
    defaultAttachments: false,
    attachments: [],
    observations: '',
    purchaseIntention: '',
    defaultEmailTemplate: '',
    headerText: '',
    footerText: '',
    logo: '',
  });
  const [companyId, setCompanyId] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!profile || !profile.permissions.view_proforma_menu)
      window.location.href = '/';

    dispatch(getEmailTemplates());
    dispatch(getCompanyProformaConfig());
    const val = company && company._id ? company._id : '';
    setCompanyId(val);
  }, []);

  useEffect(() => {
    if (companyConfig.length > 0) {
      const object = companyConfig.find((item) => item.id === companyId);
      if (object) setDetails(object.proformaConfiguration);
    }
  }, [companyConfig]);

  const onChangeCompany = (e) => {
    setCompanyId(e.target.value);
    const object = companyConfig.find((item) => item.id === e.target.value);
    setDetails(object.proformaConfiguration);
  };

  const onEditButtonClick = async () => {
    await dispatch(updateCompanyProformaConfig(companyId, details));
    dispatch(getCompanyProformaConfig());
  };

  const onAddAttachment = (attachment) =>
    setDetails({
      ...details,
      attachments: [attachment, ...details.attachments],
    });

  const onDeleteAttachment = (url) =>
    setDetails({
      ...details,
      attachments: details.attachments.filter((item) => item.url !== url),
    });

  const onEditAttachment = (attachment, target) => {
    const attachments = details.attachments.map((item) =>
      item.url === target ? attachment : item,
    );
    setDetails({ ...details, attachments });
  };

  const onReorderAttachment = (attachments) => {
    setDetails({ ...details, attachments });
  };

  const renderButton = (
    <ConfirmationDialog
      confirmationText="¿Está seguro que desea modificar la configuración de proformas?"
      buttonText="Actualizar"
      variant="contained"
      isDisabled={companyId === ''}
      confirmAction={onEditButtonClick}
    />
  );

  const { classes } = props;

  const renderDefaultAttachments = (
    <TableRow>
      <TableCell className={`${classes.tableRowTitle} topTitle`}>
        <b>Adjuntos por defecto</b>
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={details.defaultAttachments}
              onChange={(e) =>
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    defaultAttachments: e.target.checked,
                  };
                })
              }
              color="primary"
            />
          }
          label={
            details.defaultAttachments === true ? 'Habilitado' : 'No habilitado'
          }
        />
        {details.defaultAttachments && (
          <AttachmentsList
            attachments={details.attachments}
            onAddAttachment={onAddAttachment}
            onDeleteAttachment={onDeleteAttachment}
            onEditAttachment={onEditAttachment}
            onReorderAttachment={onReorderAttachment}
          />
        )}
      </TableCell>
    </TableRow>
  );

  const renderObservations = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Sección de observaciones</b>
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          value={details.observations}
          fullWidth
          placeholder="Observaciones generales de la empresa para la proforma"
          multiline
          rows={2}
          onChange={(e) =>
            setDetails({
              ...details,
              observations: e.target.value,
            })
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderPurchaseIntention = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Sección de intención de compra</b>
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          value={details.purchaseIntention}
          fullWidth
          placeholder="Texto por defecto de la intención de compra de la proforma"
          multiline
          rows={2}
          onChange={(e) =>
            setDetails({
              ...details,
              purchaseIntention: e.target.value,
            })
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderHeaderText = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Texto de Encabezado</b>
      </TableCell>
      <TableCell>
        <TextField
          value={details.headerText}
          fullWidth
          onChange={(e) =>
            setDetails({
              ...details,
              headerText: e.target.value,
            })
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderFooterText = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Texto de pie de página</b>
      </TableCell>
      <TableCell>
        <TextField
          value={details.footerText}
          fullWidth
          onChange={(e) =>
            setDetails({
              ...details,
              footerText: e.target.value,
            })
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderEmailTemplate = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Plantilla por defecto</b>
      </TableCell>
      <TableCell>
        <Select
          value={details.defaultEmailTemplate}
          onChange={(e) =>
            setDetails({
              ...details,
              defaultEmailTemplate: e.target.value,
            })
          }
          fullWidth
        >
          {emailTemplates
            .filter(({ company }) => company === companyId)
            .map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
        </Select>
      </TableCell>
    </TableRow>
  );

  const renderCompany = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Empresa:</b>
      </TableCell>
      <TableCell>
        <Select value={companyId} onChange={onChangeCompany} fullWidth>
          {companyConfig.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );

  const renderLoadImage = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Imagen del logo</b>
      </TableCell>
      <TableCell id="imageLogo">
        {details.logo && (
          <a href={details.logo} rel="noopener noreferrer" target="_blank">
            <img
              src={details.logo}
              alt="Foto de perfil"
              style={{ width: 100 }}
            />
          </a>
        )}
        <Uploader
          buttonText={details.logo ? 'Actualizar logo' : 'Subir logo'}
          photo
          dirName="Proforma"
          uploaderId="catalog-proforma-logo"
          urlUploaded={(logo) =>
            setDetails({
              ...details,
              logo: logo ? logo : '',
            })
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderSnackBar = () => {
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  };

  return sending ? (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    />
  ) : (
    <div>
      {renderSnackBar()}
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <Typography variant="h6">Proformas</Typography>
        </Toolbar>
        <div style={{ width: 'auto', overflowX: 'scroll' }}>
          <Table>
            <TableBody>
              {profile._id === SUPER_ADMIN_ROL_ID && renderCompany}
              {companyId !== '' && renderObservations}
              {companyId !== '' && renderPurchaseIntention}
              {companyId !== '' && renderHeaderText}
              {companyId !== '' && renderFooterText}
              {companyId !== '' && renderEmailTemplate}
              {companyId !== '' && renderDefaultAttachments}
              {companyId !== '' && renderLoadImage}
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>{renderButton}</div>
      </Paper>
    </div>
  );
};

Proforma.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Proforma);
