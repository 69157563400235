import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { filterNews, createNews, updateNews } from '../../../actions/news';
import EnhancedTable from '../../common/enhancedTable';
import { newsCols } from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import NewsItemDetail from './detail';

const News = () => {
  const {
    page,
    rowsPerPage,
    order,
    orderBy,
    news,
    searchText,
    sending,
    dataLength,
    error,
    message,
  } = useSelector((state) => state.news);
  const [newsItem, setNewsItem] = useState(null);
  const [isNew, setIsNew] = useState(false);

  const dispatch = useDispatch();

  const profile = JSON.parse(localStorage.getItem('profile'));

  const getViewPermission = () => {
    if (profile.permissions.view_news) {
      return true;
    }
    window.location.href = '/';
    return false;
  };

  useEffect(() => {
    getViewPermission();
    dispatch(filterNews(page, rowsPerPage, order, orderBy, searchText));
  }, []);

  const onSearch = (searchedText) => {
    dispatch(filterNews(page, rowsPerPage, order, orderBy, searchedText));
  };

  const onAdd = () => {
    setNewsItem({});
    setIsNew(true);
  };

  const onDetail = (id) => {
    const newsItemLocal = news.filter((obj) => obj.id === id)[0];
    setNewsItem(newsItemLocal);
    setIsNew(false);
  };

  const handleChangeRowsPerPage = (rowsPerPageLocal) => {
    dispatch(filterNews(page, rowsPerPageLocal, order, orderBy, searchText));
  };

  const handleChangePage = (pageLocal) => {
    dispatch(filterNews(pageLocal, rowsPerPage, order, orderBy, searchText));
  };

  const onOrder = (orderLocal, orderByLocal) => {
    dispatch(filterNews(page, rowsPerPage, orderLocal, orderByLocal, searchText));
  };

  const handleGoBack = () => {
    dispatch(filterNews(page, rowsPerPage, order, orderBy, searchText));
    setNewsItem(null);
    setIsNew(false);
  };

  const handleCreate = async (data) => {
    dispatch(createNews(data));
  };

  const handleUpdate = async (newsItemEdited) => {
    setNewsItem(newsItemEdited);
    const edited = {};
    if (newsItem.active !== newsItemEdited.active) {
      edited.active = newsItemEdited.active;
    }
    if (newsItem.title !== newsItemEdited.title) {
      edited.title = newsItemEdited.title;
    }
    if (newsItem.content !== newsItemEdited.content) {
      edited.content = newsItemEdited.content;
    }
    if (newsItem.mainImage !== newsItemEdited.mainImage) {
      edited.mainImage = newsItemEdited.mainImage;
    }

    dispatch(updateNews(newsItemEdited.id, edited));
  };

  const renderSnackBar = () => {
    if (message) {
      return <CustomSnackbar variant={error !== null ? 'error' : 'success'} message={message} />;
    }
    return null;
  };

  const isLoading = sending;
  const data = {
    rows: news,
    cols: newsCols,
  };
  const handlers = {
    onDetail,
    onAdd,
    onSearch,
    onOrder,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
  };
  const config = {
    isDragable: false,
    isSelectable: false,
    isFiterable: false,
    hasEditPermission: true,
    hasCreatePermission: true,
    searchText: searchText || '',
    order: {
      by: orderBy,
      direction: order,
    },
    pagination: {
      current: page,
      rowsPerPage,
      totalItems: dataLength,
    },
    filters: {
      is: false,
    },
  };
  return isLoading === true ? (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    />
  ) : (
    <div>
      {renderSnackBar()}
      {!newsItem ? (
        <EnhancedTable title="Noticias" data={data} handlers={handlers} config={config} />
      ) : (
        <NewsItemDetail
          newsItem={newsItem}
          handleGoBack={handleGoBack}
          isLoading={isLoading}
          isNew={isNew}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
        />
      )}
    </div>
  );
};

export default News;
