export const styles = (theme) => ({
  attributesContainer: {
    width: '90%',
    margin: '0 auto',
  },
  inlineItem: {
    display: 'inline-block',
  },
  footerContained: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginBottom: '20px',
    marginTop: '10px',
  },
});

export default styles;
