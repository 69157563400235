export const GET_ADVANCES_SUCCESS = 'GET_ADVANCES_SUCCESS';

export const GET_ADVANCES_SENDING = 'GET_ADVANCES_SENDING';

export const GET_ADVANCES_FAILED = 'GET_ADVANCES_FAILED';


export const GET_ADVANCE_SUCCESS = 'GET_ADVANCE_SUCCESS';

export const GET_ADVANCE_SENDING = 'GET_ADVANCE_SENDING';

export const GET_ADVANCE_FAILED = 'GET_ADVANCE_FAILED';


export const FILTER_ADVANCES_SUCCESS = 'FILTER_ADVANCES_SUCCESS';

export const FILTER_ADVANCES_SENDING = 'FILTER_ADVANCE_SENDING';

export const FILTER_ADVANCES_FAILED = 'FILTER_ADVANCE_FAILED';


export const CREATE_ADVANCE_SUCCESS = 'CREATE_ADVANCE_SUCCESS';

export const CREATE_ADVANCE_SENDING = 'CREATE_ADVANCE_SENDING';

export const CREATE_ADVANCE_FAILED = 'CREATE_ADVANCE_FAILED';


export const UPDATE_ADVANCE_SUCCESS = 'UPDATE_ADVANCE_SUCCESS';

export const UPDATE_ADVANCE_SENDING = 'UPDATE_ADVANCE_SENDING';

export const UPDATE_ADVANCE_FAILED = 'UPDATE_ADVANCE_FAILED';
