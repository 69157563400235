import * as taskActions from '../actions/types/tasks';

const initialState = {
  tasks: [],
  task: {},
  message: '',
  messageAction: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  size: 10,
  order: 'asc',
  orderBy: 'name',
  filters: {},
  searchText: '',
  hasMore: false,
  firstLoad: true,
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {
    message,
    error,
    tasks,
    dataLength,
    page,
    size,
    order,
    orderBy,
    searchText,
    filters,
    hasMore,
  } = action.payload;
  switch (action.type) {
    case taskActions.GET_TASKS_SENDING:
      return { ...state, sending: true, order, orderBy, searchText, dataLength, messageAction: '' };
    case taskActions.GET_TASKS_SUCCESS:
      return {
        ...state,
        sending: false,
        tasks: state.tasks.concat(tasks),
        error: null,
        message: 'Eventos obtenidos satisfactoriamente',
        dataLength,
        hasMore,
        page,
        size,
      };
    case taskActions.GET_TASKS_FAILED:
      return { ...state, sending: false, tasks: [], error, message, dataLength };
    case taskActions.FIRSTLOAD_TASKS:
      return { ...state, firstLoad: false, tasks: [] };
    case taskActions.FINISH_TASKS_SENDING:
      return { ...state, sending: true, messageAction: '' };
    case taskActions.FINISH_TASKS_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        messageAction: 'Tarea Finalizada',
      };
    case taskActions.FINISH_TASKS_FAILED:
      return { ...state, sending: false, error: true, message };
    case taskActions.CANCEL_TASKS_SENDING:
      return { ...state, sending: true, messageAction: '' };
    case taskActions.CANCEL_TASKS_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        messageAction: 'Tarea Cancelada',
      };
    case taskActions.CANCEL_TASKS_FAILED:
      return { ...state, sending: false, error: true, message };
    default:
      return state;
  }
};
