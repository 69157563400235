import * as leadsAssignmentAction from '../actions/types/leadAssignment';

const initialState = {
  leadsAssignment: [],
  message: '',
  sending: false,
  error: null
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {leadsAssignment, error, message} = action.payload;
  switch (action.type) {
  case leadsAssignmentAction.GET_LEADS_ASSIGNMENT_SENDING:
    return {...state, sending: true};
  case leadsAssignmentAction.GET_LEADS_ASSIGNMENT_SUCCESS:
    return {...state, sending: false, leadsAssignment, error: null, message: 'Asignaciones de lead obtenidos satisfactoriamente'};
  case leadsAssignmentAction.GET_LEADS_ASSIGNMENT_FAILED:
    return {...state, sending: false, leadsAssignment: [], error, message};
  case leadsAssignmentAction.UPDATE_LEAD_ASSIGNMENT_SENDING:
    return {...state, sending: true};
  case leadsAssignmentAction.UPDATE_LEAD_ASSIGNMENT_SUCCESS:
    return {...state, sending: false, error: null, message: 'Asignación de lead actualizado satisfactoriamente'};
  case leadsAssignmentAction.UPDATE_LEAD_ASSIGNMENT_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
