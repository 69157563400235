import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {PropTypes} from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListItemSecondaryAction
} from '@material-ui/core';
import {styles} from './styles';
import {
  not,
  intersection,
  union,
  ASESOR_JR_ID,
  ASESOR_ID
} from '../../../../../utils/utils';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const ManualAssign = (props) => {
  const {classes, openManualAssignModal, leadsToAdd, hasJuniorRol, closeModal, onLeadAssignChange, companyId} = props;
  const {users} = useSelector(state => state.users);

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [brokerSelected, setBrokerSelected] = useState();

  useEffect(() => {
    const getBrokers = profile =>
      users.filter(user => user.profile.id === profile && user.company.id === companyId);
    setBrokers(hasJuniorRol ? getBrokers(ASESOR_JR_ID) : getBrokers(ASESOR_ID));
    setLeft(leadsToAdd);
  }, []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked.map(x => ({...x, brokerId: brokerSelected.id}))));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked.map(({brokerId, ...x}) => x)));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleNext = () => {
    const leadsWithBrokers = right.map(({brokerId, ...item}) => {
      const broker = brokers.find(x => x.id === brokerId);  
      const assignedBroker = [{
        userId: brokerId,
        username: `${broker.name.trim()} ${broker.lastname.trim()}`,
        active: true,
        date_assigned: new Date().toISOString()
      }];

      return {...item, brokers: assignedBroker};
    });

    return onLeadAssignChange([...left, ...leadsWithBrokers]);
  };

  const customList = (items, title, isBrokerList = false) => {
    const virtualItems = isBrokerList
      ? items.filter(x => x.brokerId === brokerSelected.id)
      : items;
    return (
      <Card elevation={2}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(virtualItems)}
              checked={
                numberOfChecked(virtualItems) === virtualItems.length &&
                virtualItems.length !== 0
              }
              indeterminate={
                numberOfChecked(virtualItems) !== virtualItems.length &&
                numberOfChecked(virtualItems) !== 0
              }
              disabled={virtualItems.length === 0}
              inputProps={{'aria-label': 'all items selected'}}
            />
          }
          action={
            isBrokerList && (
              <FormControl style={{minWidth: '100%'}}>
                <InputLabel id="broker-select">Asesor</InputLabel>
                <Select
                  value={brokerSelected ? brokerSelected.id : ''}
                  name={brokerSelected ? brokerSelected.name : ''}
                  onChange={({target}) =>
                    setBrokerSelected({id: target.value, name: target.name})
                  }
                  fullWidth
                >
                  {brokers.map((broker, index) => {
                    const count = right.filter(lead => lead.brokerId === broker.id).length;
                    return (
                      <MenuItem value={broker.id} key={`broker_${index}`}>
                        <ListItemText primary={broker.name} />
                        <ListItemSecondaryAction
                          style={{
                            color: 'gray',
                            paddingRight: 24,
                            fontSize: 14
                          }}
                        >
                          {count}
                        </ListItemSecondaryAction>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )
          }
          title={title}
          subheader={`${numberOfChecked(virtualItems)}/${
            virtualItems.length
          } seleccionados`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-all-item-${value.user.email}-label`;

            return (
              ((isBrokerList && value.brokerId === brokerSelected.id) ||
                !isBrokerList) && (
                <ListItem
                  key={value.user.email}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{'aria-labelledby': labelId}}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${value.user.name.trim()} ${value.user.lastname.trim()}`}
                    secondary={`${value.user.email}`}
                  />
                </ListItem>
              )
            );
          })}
        </List>
      </Card>
    );
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xl"
        fullWidth
        open={openManualAssignModal}
        TransitionComponent={Transition}
      >
        <DialogTitle>Asignación manual de leads</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.gridRoot}
            >
              <Grid item xs>
                {customList(left, 'Nuevos leads')}
              </Grid>
              <Grid item xs={1}>
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0 || !brokerSelected}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0 || !brokerSelected}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs>
                {customList(right, 'Leads asignados', true)}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeModal}>Cancelar</Button>
          <Button color="primary" onClick={handleNext}>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ManualAssign.propTypes = {
  classes: PropTypes.object.isRequired,
  openManualAssignModal: PropTypes.bool.isRequired,
  leadsToAdd: PropTypes.array,
  hasJuniorRol: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onLeadAssignChange: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired
};

export default withStyles(styles)(ManualAssign);
