import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import { getNewItem } from './../../../actions/news';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const NewsModal = (props) => {
  const { classes, title, newItemId, open, setOpen } = props;

  const [content, setContent] = useState('');

  useEffect(() => {
    (async function () {
      const { content: fetchedContent } = await getNewItem(newItemId);
      setContent(fetchedContent);
    })();
  }, []);

  return (
    <Dialog
      maxWidth="sm"
      scroll="body"
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      className={classes.customDialog}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>{parse(content)}</DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  newItemId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};

export default withStyles(styles)(NewsModal);
