import * as uploaderActions from '../actions/types/uploader';

const initialState = {
  sending: false,
  error: null,
  message: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, message} = action.payload;
  switch (action.type) {
  case uploaderActions.UPLOAD_SENDING:
    return {...state, sending: true};
  case uploaderActions.UPLOAD_SUCCESS:
    return {...state, sending: false, error: null, message: 'Subido satisfactoriamente'};
  case uploaderActions.UPLOAD_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
