import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Chip,
  List,
  ListItem,
  ListItemText,
  Typography,
  FormControl,
  InputLabel,
  ListItemSecondaryAction,
  Switch,
  Divider,
  Drawer,
  Hidden,
  Select,
  MenuItem,
} from '@material-ui/core';
import { InlineDatePicker } from 'material-ui-pickers';
import { useSelector, useDispatch } from 'react-redux';
import { styles } from './styles';
import {
  setProject,
  setZone,
  setCities,
  setRoom,
  setUnitType,
  setEndDate,
  setStartDate,
  setGlobalFilter,
  orderChips,
  setDisabledFilter
} from '../../../actions/smartDemandFilters';
import { getDemandGraphs } from '../../../actions/smart';

const FilterDrawer = (props) => {
  const {
    window,
    classes,
    handleDrawerToggle,
    mobileOpen,
    normalizedZones,
    roomsData,
    unitTypesData,
    projectsData,
    zones,
    propertyTypes,
    allParameters,
    projects,
  } = props;

  const dispatch = useDispatch();

  const {
    chipData,
    project,
    zone,
    cities,
    room,
    unitType,
    endDate,
    startDate,
    globalFilter,
    filters
  } = useSelector((state) => state.smartDemandFilters);

  useEffect(() => {
    dispatch(getDemandGraphs(filters));
  }, [filters]);

  useEffect(() => {
    dispatch(setDisabledFilter(false));
  }, [chipData, project, zone, room, unitType, endDate, startDate, globalFilter]);

  const handleChangeZone = ({ target }) => {
    const value = target.value.filter((item) => item);
    dispatch(setZone(value));

    const chipsWithoutZones = chipData.filter((chip) => chip.type !== 'zone');

    const newZones = value.map((item, index) => ({
      key: chipsWithoutZones.length + index,
      type: 'zone',
      label: normalizedZones.find(({ id }) => id === item).name,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutZones, ...newZones]));
  };

  useEffect(() => {
    let sectorsToDelete = [];
    zones
      .filter((x) => cities.includes(x.id))
      .map((x) =>
        x.zones.map((y) =>
          y.sectors.map((z) => {
            if (zone.includes(z.id)) sectorsToDelete.push(z.id);
          }),
        ),
      );
    handleChangeZone({ target: { value: sectorsToDelete } });
  }, [cities]);

  const container = window !== undefined ? () => window().document.body : undefined;

  const handleChangeDateRange = (value, type) => {
    let label;
    if (type === 'start') {
      dispatch(setStartDate(value));
      label = `${value.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    } else if (type === 'end') {
      dispatch(setEndDate(value));
      label = `${startDate.toLocaleDateString()} - ${value.toLocaleDateString()}`;
    }

    dispatch(
      orderChips([
        ...chipData.filter((chip) => chip.type !== 'date'),
        {
          key: 0,
          type: 'date',
          label,
        },
      ]),
    );
  };

  const renderStartDate = () => {
    const { classes } = props;
    return (
      <div style={{ padding: '10px' }}>
        <InputLabel className={classes.filterTitle} id="start-date">
          Desde
        </InputLabel>
        <FormControl style={{ width: '100%' }}>
          <InlineDatePicker
            maxDate={endDate}
            value={startDate}
            onChange={(value) => handleChangeDateRange(new Date(value.format()), 'start')}
            format="YYYY-MM-DD"
            className={classes.datePicker}
          />
        </FormControl>
      </div>
    );
  };

  const renderEndDate = () => {
    const { classes } = props;
    return (
      <div style={{ padding: '10px' }}>
        <InputLabel className={classes.filterTitle} id="end-date">
          Hasta
        </InputLabel>
        <FormControl style={{ width: '100%' }}>
          <InlineDatePicker
            maxDate={new Date()}
            minDate={startDate}
            value={endDate}
            onChange={(value) => handleChangeDateRange(new Date(value.format()), 'end')}
            format="YYYY-MM-DD"
            className={classes.datePicker}
          />
        </FormControl>
      </div>
    );
  };

  const renderUnitTypes = () => {
    if (!propertyTypes || propertyTypes.length === 0 || !unitTypesData) {
      return [];
    }

    return unitTypesData.map((item, index) => (
      <MenuItem value={item.id} key={`unitType_${index}`}>
        {item.name}
      </MenuItem>
    ));
  };

  const handleChangeUnitType = ({ target }) => {
    const { value } = target;
    dispatch(setUnitType(value));

    const chipsWithoutTypes = chipData.filter((chip) => chip.type !== 'unitType');

    const newTypes = value.map((item, index) => ({
      key: chipsWithoutTypes.length + index,
      type: 'unitType',
      label: unitTypesData.find((val) => val.id === item).name,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutTypes, ...newTypes]));
  };

  const renderUnitType = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="room-select">
            Tipo de propiedad
          </InputLabel>
          <Select value={unitType} fullWidth onChange={handleChangeUnitType} multiple>
            {renderUnitTypes()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderRooms = () => {
    if (!allParameters || allParameters.length === 0 || !roomsData) return [];

    return roomsData.map((item, index) => (
      <MenuItem value={item._id} key={`room_${index}`}>
        {item.value}
      </MenuItem>
    ));
  };

  const handleChangeRoom = ({ target }) => {
    const { value } = target;
    dispatch(setRoom(value));

    const chipsWithoutRooms = chipData.filter((chip) => chip.type !== 'room');

    const newRooms = value.map((item, index) => ({
      key: chipsWithoutRooms.length + index,
      type: 'room',
      label: `${roomsData.find((val) => val._id === item).value} ${
        roomsData.find((val) => val._id === item).value === '1' ? 'habitación' : 'habitaciones'
      }`,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutRooms, ...newRooms]));
  };

  const renderRoom = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="room-select">
            Habitaciones
          </InputLabel>
          <Select value={room} fullWidth onChange={handleChangeRoom} multiple>
            {renderRooms()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderCities = () => {
    return zones.map((item, index) => (
      <MenuItem value={item.id} key={`cities_${index}`}>
        {item.name}
      </MenuItem>
    ));
  };

  const handleChangeCity = ({ target }) => {
    const { value } = target;
    dispatch(setCities(value));
  };

  const renderCity = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="city-select">
            Ciudad
          </InputLabel>
          <Select value={cities} fullWidth onChange={handleChangeCity} multiple>
            {renderCities()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderZones = () => {
    if (!zones || zones.length === 0) return [];

    let zonesArray = [];

    zones
      .filter((city) => cities.includes(city.id))
      .map((city, cityIndex) => {
        const response = city.zones.reduce((acc, val, index) => {
          acc.push(
            <Typography className={classes.zoneLabel} key={`zone_${cityIndex}_${index}`}>
              {val.name}
              <Chip label={city.name} className={classes.cityChip} />
            </Typography>,
          );
          val.sectors.map((item, i) =>
            acc.push(
              <MenuItem
                className={classes.zoneMenu}
                value={item.id}
                key={`sector_${cityIndex}_${index}_${i}`}
              >
                {item.name}
              </MenuItem>,
            ),
          );
          return acc;
        }, []);
        zonesArray = [...zonesArray, ...response];
        console.log('zonesArray:', zonesArray);
      });

    return zonesArray;
  };

  const renderZone = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="zone-select">
            Zona
          </InputLabel>
          <Select value={zone} fullWidth onChange={handleChangeZone} multiple>
            {renderZones()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderProjects = () => {
    if (!projects || projects.length === 0) return [];

    return projectsData.map((item, index) => (
      <MenuItem value={item._id} key={`project_${index}`}>
        {item.name}
      </MenuItem>
    ));
  };

  const handleChangeProject = ({ target }) => {
    const { value } = target;
    dispatch(setProject(value));

    const chipsWithoutProjects = chipData.filter((chip) => chip.type !== 'project');

    const newProjects = value.map((item, index) => ({
      key: chipsWithoutProjects.length + index,
      type: 'project',
      label: projectsData.find((val) => val._id === item).name,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutProjects, ...newProjects]));
  };

  const renderProject = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="project-select">
            Proyecto
          </InputLabel>
          <Select value={project} fullWidth onChange={handleChangeProject} multiple>
            {renderProjects()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const handleGlobalSwitch = ({ target }) => {
    if (target.checked) {
      const chipsToDelete = chipData.filter((item) => item.type === 'project');
      if (chipsToDelete.length > 0) {
        dispatch(orderChips(chipData.filter((chip) => chip.type !== 'project')));
        dispatch(setProject([]));
      }
    }

    dispatch(setGlobalFilter(target.checked));
  };

  const renderGlobalSwitch = () => {
    return (
      <div style={{ padding: '10px' }}>
        <List>
          <ListItem className={classes.switchText}>
            <ListItemText primary="Buscar en todo el sector inmobiliario" />
            <ListItemSecondaryAction>
              <Switch onChange={(e) => handleGlobalSwitch(e)} checked={globalFilter} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    );
  };

  const drawer = (
    <>
      <div className={classes.toolbar} />
      {renderStartDate()}
      {renderEndDate()}
      {renderUnitType()}
      {renderRoom()}
      <Divider />
      {renderCity()}
      {renderZone()}
      <Divider />
      {!globalFilter && renderProject()}
      <div>
        <br />
        <Divider />
        {renderGlobalSwitch()}
      </div>
    </>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

FilterDrawer.propTypes = {
  window: PropTypes.func,
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  mobileOpen: PropTypes.bool,
};

export default withStyles(styles)(FilterDrawer);
