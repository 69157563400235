import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { getProjectsByLead } from '../../../actions/proformas';
import DialogProformas from './dialogProformas';

const ProformasAdmin = (props) => {
  const { classes, lead, onClose } = props;
  const dispatch = useDispatch();

  const { projects } = useSelector((state) => state.proformas);

  const [value, setValue] = useState();
  const [openProformas, setOpenProformas] = useState(false);

  useEffect(() => {
    dispatch(
      getProjectsByLead(
        lead,
        localStorage.getItem('isBroker') && localStorage.getItem('user'),
      ),
    );
  }, []);

  useEffect(() => {
    if (projects && projects.length === 1) {
      setValue(projects[0].id);
      setOpenProformas(true);
    }
  }, [projects]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const renderProjects = (
    <Dialog open={!openProformas} onClose={onClose}>
      <DialogTitle>Seleccione el proyecto cotizado</DialogTitle>
      <FormControl component="fieldset" className={classes.radioGroupContainer}>
        <RadioGroup value={value} onChange={handleChange}>
          {projects.map(({ name, id }) => (
            <FormControlLabel
              key={id}
              value={id}
              control={<Radio />}
              label={name}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <div className={classes.footerContained}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenProformas(true)}
          disabled={!value}
        >
          Continuar
        </Button>
      </div>
    </Dialog>
  );

  return (
    <Fragment>
      {renderProjects}
      {openProformas && (
        <DialogProformas lead={lead} onClose={onClose} project={value} />
      )}
    </Fragment>
  );
};

ProformasAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  lead: PropTypes.string,
  onClose: PropTypes.func,
};

export default withStyles(styles)(ProformasAdmin);
