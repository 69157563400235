export const GET_LEAD_STAGES_SUCCESS = 'GET_LEAD_STAGES_SUCCESS';

export const GET_LEAD_STAGES_SENDING = 'GET_LEAD_STAGES_SENDING';

export const GET_LEAD_STAGES_FAILED = 'GET_LEAD_STAGES_FAILED';

export const FILTER_LEAD_STAGES_SUCCESS = 'FILTER_LEAD_STAGES_SUCCESS';

export const FILTER_LEAD_STAGES_SENDING = 'FILTER_LEAD_STAGES_SENDING';

export const FILTER_LEAD_STAGES_FAILED = 'FILTER_LEAD_STAGES_FAILED';

export const CREATE_LEAD_STAGE_SUCCESS = 'CREATE_LEAD_STAGE_SUCCESS';

export const CREATE_LEAD_STAGE_SENDING = 'CREATE_LEAD_STAGE_SENDING';

export const CREATE_LEAD_STAGE_FAILED = 'CREATE_LEAD_STAGE_FAILED';

export const UPDATE_LEAD_STAGE_SUCCESS = 'UPDATE_LEAD_STAGE_SUCCESS';

export const UPDATE_LEAD_STAGE_SENDING = 'UPDATE_LEAD_STAGE_SENDING';

export const UPDATE_LEAD_STAGE_FAILED = 'UPDATE_LEAD_STAGE_FAILED';
