/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {withSnackbar} from 'notistack';

export class CustomSnackbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderSnackBar = this.renderSnackBar.bind(this);
  }

  componentDidMount() {
    this.renderSnackBar();    
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.message !== nextProps.message) {
      this.renderSnackBar();
    }
  }

  renderSnackBar() {
    const {message, variant} = this.props;
    const action = key => (
      <Button onClick={() => this.props.closeSnackbar(key)} style={{color: '#fff'}}>
        {variant === 'error' ? '' : 'Ok'}
      </Button>
    );
    this.props.enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      autoHideDuration: variant === 'error' ? 7000 : 1000,
      action,
      variant
    });
  }

  render() {
    return null;
  }
}

CustomSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'default']).isRequired
};

export default withSnackbar(CustomSnackbar);
