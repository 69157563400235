/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import {connect} from 'react-redux';
import moment from 'moment-timezone';
import {Link} from 'react-router-dom';
import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import ReactApexChart from 'react-apexcharts';
import 'react-dates/lib/css/_datepicker.css';
import {getDashboard, getRanking} from '../../actions/dashboard';
import {getBuilders} from '../../actions/builders';
import {getZones} from '../../actions/zones';
import {filterProjects, getProjectNames} from '../../actions/projects';
import {getAsesors} from '../../actions/users';
import {getComments} from '../../actions/comments';
import Autocomplete from '../common/enhancedTable/enhancedTableRow/enhancedTableCell/autocomplete';
import CustomSnackbar from '../common/customSnackbar';
import Funnel from './Funnel';
import {styles} from './styles';
import './cssStyle.css';
import {copyObject, BUILDER_ID} from '../../utils/utils';
import {getOrigins} from '../../actions/origins';

const windowWidth = window.innerWidth;

export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      builder: null,
      funnelHeight: 900,
      funnelWidth: 0,
      builderSelected: '',
      builderSelectedRanking: '',
      projectSelected: '',
      projectSelectedRanking: '',
      zoneSelected: [],
      zoneSelectedRanking: [],
      brokerSelected: '',
      startDateSelected: null,
      endDateSelected: null,
      focusedInput: null,
      startDateSelectedRanking: null,
      endDateSelectedRanking: null, 
      focusedInputRanking: null
    };
    this.funnelContainer = React.createRef();
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.handleChangeBuilder = this.handleChangeBuilder.bind(this);
    this.handleChangeBuilderRanking = this.handleChangeBuilderRanking.bind(this);
    this.handleChangeZone = this.handleChangeZone.bind(this);
    this.handleChangeZoneRanking = this.handleChangeZoneRanking.bind(this);
    this.handleChangeProject = this.handleChangeProject.bind(this);
    this.handleChangeProjectRanking = this.handleChangeProjectRanking.bind(this);
    this.handleChangeBroker = this.handleChangeBroker.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeDateRanking = this.handleChangeDateRanking.bind(this);
    this.renderComments = this.renderComments.bind(this);
    this.renderLabels = this.renderLabels.bind(this);
    this.renderFunnel = this.renderFunnel.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    const {filters, rankingFilters} = this.props;
    const profileId = localStorage.getItem('profileId');
    const filtersToAply = copyObject(filters); 
    const filtersToAplyRanking = copyObject(rankingFilters);
    const startDateSelected = moment().startOf('month');
    const endDateSelected = moment().endOf('month');
    startDateSelected.set({h: 0, m: 0, s: 0});
    filtersToAply.startDate = moment(startDateSelected).format();
    filtersToAplyRanking.startDate = moment(startDateSelected).format();
    endDateSelected.set({h: 0, m: 0, s: 0});
    filtersToAply.endDate = moment(endDateSelected).format();
    filtersToAplyRanking.endDate = moment(endDateSelected).format();
    this.setState({startDateSelected, endDateSelected, startDateSelectedRanking: startDateSelected, endDateSelectedRanking: endDateSelected});
    this.props.getOrigins();
    if (profileId === BUILDER_ID) {
      const builder = localStorage.getItem('builder');
      this.setState({builder, builderSelected: builder});
      filtersToAply.builder = builder;
      filtersToAplyRanking.builder = builder;
      this.props.filterProjects(0, 999999999, 'asc', 'name', '', {builder: [builder]});
      this.props.getDashboard(filtersToAply);
      this.props.getRanking(filtersToAplyRanking);
    } else {
      this.props.getProjectNames();
      this.props.getDashboard(filtersToAply);
      this.props.getRanking(filtersToAplyRanking);
    }
    this.props.getComments();
    this.props.getBuilders();
    this.props.getZones();
    this.props.getAsesors();
  }

  componentDidMount() {
    if (this.funnelContainer.current) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        funnelWidth: this.funnelContainer.current.offsetWidth
      });
    }
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_dashboard) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  handleChangeBuilder(builderId) {
    const {filters, builders} = this.props;
    const filtersToAply = copyObject(filters);
    filtersToAply.builder = builderId;
    if (builderId) {
      const builder = builders.filter(obj => obj.id === builderId.toString())[0];
      const builderSelected = {
        value: builder.id,
        label: builder.name
      };
      this.setState({builderSelected});
      this.props.filterProjects(0, 999999999, 'asc', 'name', '', {builder: [builderId]});
    } else {
      this.setState({builderSelected: null});
      this.props.filterProjects(0, 999999999, 'asc', 'name', '', {});
    }
    this.props.getDashboard(filtersToAply);
  }

  handleChangeBuilderRanking(builderId) {
    const {rankingFilters, builders} = this.props;
    const filtersToAply = copyObject(rankingFilters);
    filtersToAply.builder = builderId;
    if (builderId) {
      const builder = builders.filter(obj => obj.id === builderId.toString())[0];
      const builderSelectedRanking = {
        value: builder.id,
        label: builder.name
      };
      this.setState({builderSelectedRanking});
    } else {
      this.setState({builderSelectedRanking: null});
    }
    this.props.getRanking(filtersToAply);
    this.props.filterProjects(0, 999999999, 'asc', 'name', '', {builder: [builderId]});
  }

  handleChangeZone(zoneSelected) {
    const {filters} = this.props;
    const filtersToAply = copyObject(filters);
    this.setState({zoneSelected});
    if (zoneSelected.length > 0) {
      filtersToAply.zone = zoneSelected.map(obj => obj.value);
    } else {
      delete filtersToAply.zone;
    }
    this.props.getDashboard(filtersToAply);
  }

  handleChangeZoneRanking(zoneSelectedRanking) {
    const {rankingFilters} = this.props;
    const filtersToAply = copyObject(rankingFilters);
    this.setState({zoneSelectedRanking});
    if (zoneSelectedRanking.length > 0) {
      filtersToAply.zone = zoneSelectedRanking.map(obj => obj.value);
    } else {
      delete filtersToAply.zone;
    }
    this.props.getRanking(filtersToAply);
  }

  handleChangeProject(projectId) {
    const {filters, projects} = this.props;
    const filtersToAply = copyObject(filters);
    filtersToAply.project = projectId;
    if (projectId) {
      const project = projects.filter(obj => obj.id === projectId.toString())[0];
      const projectSelected = {
        value: project.id,
        label: project.name
      };
      this.setState({projectSelected});
    } else {
      this.setState({projectSelected: null});
    }
    this.props.getDashboard(filtersToAply);
  }

  handleChangeProjectRanking(projectId) {
    const {rankingFilters, projects} = this.props;
    const filtersToAply = copyObject(rankingFilters);
    filtersToAply.project = projectId;
    if (projectId) {
      const project = projects.filter(obj => obj.id === projectId.toString())[0];
      const projectSelectedRanking = {
        value: project.id,
        label: project.name
      };
      this.setState({projectSelectedRanking});
    } else {
      this.setState({projectSelectedRanking: null});
    }
    this.props.getRanking(filtersToAply);
  }

  handleChangeBroker(userId) {
    const {filters, users} = this.props;
    const filtersToAply = copyObject(filters);
    filtersToAply.broker = userId;
    if (userId) {
      const broker = users.filter(obj => obj.id === userId.toString())[0];
      const brokerSelected = {
        value: broker.id,
        label: `${broker.name} ${broker.lastname}`
      };
      this.setState({brokerSelected});
    } else {
      this.setState({brokerSelected: null});
    }
    this.props.getDashboard(filtersToAply);
  }

  handleChangeDate(startDateSelected, endDateSelected) {
    const {filters} = this.props;
    const filtersToAply = copyObject(filters);
    if (startDateSelected) {
      startDateSelected.set({h: 0, m: 0});
      filtersToAply.startDate = moment(startDateSelected).format();
    }
    
    if (endDateSelected) {
      endDateSelected.set({h: 0, m: 0});
      filtersToAply.endDate = moment(endDateSelected).format();
    }
    
    this.setState({startDateSelected, endDateSelected});
    if (endDateSelected !== this.state.endDateSelected) {
      this.props.getDashboard(filtersToAply);
    }
  }

  handleChangeDateRanking(startDateSelectedRanking, endDateSelectedRanking) {
    const {rankingFilters} = this.props;
    const filtersToAply = copyObject(rankingFilters);
    
    if (startDateSelectedRanking) {
      filtersToAply.startDate = moment(startDateSelectedRanking).format();
    }
    
    if (endDateSelectedRanking) {
      filtersToAply.endDate = moment(endDateSelectedRanking).format();
    }
    this.setState({startDateSelectedRanking, endDateSelectedRanking});
    if (endDateSelectedRanking !== this.state.endDateSelectedRanking) {
      this.props.getRanking(filtersToAply);
    }
  }

  renderComments(isPositive) {
    const {classes} = this.props;
    let {comments} = this.props;
    if (comments) {
      if (comments.length > 0) {
        const profileId = localStorage.getItem('profileId');
        if (profileId === BUILDER_ID) {
          const builder = localStorage.getItem('builder');
          comments = comments.filter(obj => obj.project.builderId === builder);
        }
        let coms = comments.map((obj) => {
          const pros = obj.unit.pros.map(ob => ob.comment.trim()).filter(ob => ob !== '');
          const cons = obj.unit.cons.map(ob => ob.comment.trim()).filter(ob => ob !== '');
          return isPositive ? pros : cons;
        }).filter(ob => ob.length > 0);
        if (coms.length >= 4) {
          coms = coms.splice(coms.length - 4, 4);
        }
        return coms.map((com, index) => (<div key={index}><Typography className={classes.comment}>{`"${com[com.length - 1]}"`}</Typography></div>));
      }
    }
    return null;
  }

  renderZoneFilter = () => {
    const {classes, zones} = this.props;
    const {zoneSelected, builder} = this.state;
    const zoneSuggestions = zones.map((obj) => {
      return {
        value: obj.id,
        label: obj.name
      };
    });
    if (builder === null) {
      return (
        <FormControl className={classes.select}>
          <Autocomplete 
            suggestions={zoneSuggestions} 
            value={zoneSelected} 
            handleChangeCell={this.handleChangeZone} 
            multiple
          />
          <FormHelperText>Zona</FormHelperText>
        </FormControl>
      );
    }
    return null;
  }
  
  renderZoneFilterRanking = () => {
    const {classes, zones} = this.props;
    const {zoneSelectedRanking} = this.state;
    const zoneSuggestions = zones.map((obj) => {
      return {
        value: obj.id,
        label: obj.name
      };
    });
    return (
      <FormControl className={classes.select}>
        <Autocomplete 
          suggestions={zoneSuggestions} 
          value={zoneSelectedRanking} 
          handleChangeCell={this.handleChangeZoneRanking}
          multiple
        />
        <FormHelperText>Zona</FormHelperText>
      </FormControl>
    );
  }

  renderBuilderFilter = () => {
    const {classes, builders} = this.props;
    const {builderSelected, builder} = this.state;
    const builderSuggestions = builders.map((obj) => {
      return {
        value: obj.id,
        label: obj.name
      };
    });
    if (builder === null) {
      return (
        <FormControl className={classes.select}>
          <Autocomplete 
            suggestions={builderSuggestions} 
            value={builderSelected} 
            handleChangeCell={this.handleChangeBuilder} 
            multiple={false}
          />
          <FormHelperText>Constructora</FormHelperText>
        </FormControl>
      );
    }
    return null;
  }

  renderBuilderFilterRanking = () => {
    const {classes, builders} = this.props;
    const {builderSelectedRanking, builder} = this.state;
    const builderSuggestions = builders.map((obj) => {
      return {
        value: obj.id,
        label: obj.name
      };
    });
    if (builder === null) {
      return (
        <FormControl className={classes.select}>
          <Autocomplete 
            suggestions={builderSuggestions} 
            value={builderSelectedRanking} 
            handleChangeCell={this.handleChangeBuilderRanking} 
            multiple={false}
          />
          <FormHelperText>Constructora</FormHelperText>
        </FormControl>
      );
    }
    return null;
  }

  renderProjectFilter = () => {
    const {classes, projects} = this.props;
    const {projectSelected} = this.state;
    const projectSuggestions = projects.map((obj) => {
      return {
        value: obj.id,
        label: obj.name
      };
    });
    return (
      <FormControl className={classes.select}>
        <Autocomplete 
          suggestions={projectSuggestions} 
          value={projectSelected} 
          handleChangeCell={this.handleChangeProject}
          multiple={false} 
        />
        <FormHelperText>Proyecto</FormHelperText>
      </FormControl>
    );
  }

  renderProjectFilterRanking = () => {
    const {classes, projects} = this.props;
    const {projectSelectedRanking} = this.state;
    const projectSuggestions = projects.map((obj) => {
      return {
        value: obj.id,
        label: obj.name
      };
    });
    return (
      <FormControl className={classes.select}>
        <Autocomplete 
          suggestions={projectSuggestions} 
          value={projectSelectedRanking} 
          handleChangeCell={this.handleChangeProjectRanking} 
          multiple={false}
        />
        <FormHelperText>Proyecto</FormHelperText>
      </FormControl>
    );
  }

  renderBrokerFilter = () => {
    const {classes, users} = this.props;
    const {brokerSelected, builder} = this.state;
    const brokerSuggestions = users.map((obj) => {
      return {
        value: obj.id,
        label: `${obj.name} ${obj.lastname}`
      };
    });
    if (builder === null) {
      return (
        <FormControl className={classes.select}>
          <Autocomplete 
            suggestions={brokerSuggestions} 
            value={brokerSelected} 
            handleChangeCell={this.handleChangeBroker}
            multiple={false} 
          />
          <FormHelperText>Asesor</FormHelperText>
        </FormControl>
      );
    }
    return null;
  }

  renderDateFilter = () => {
    const {classes} = this.props;
    const {
      startDateSelected, 
      endDateSelected, 
      focusedInput
    } = this.state;
    return (
      <FormControl className={classes.select}>
        <DateRangePicker
          startDate={startDateSelected} 
          startDateId="your_unique_start_date_id" 
          endDate={endDateSelected} 
          endDateId="your_unique_end_date_id" 
          onDatesChange={({startDate, endDate}) => this.handleChangeDate(startDate, endDate)} 
          focusedInput={focusedInput}
          onFocusChange={focused => this.setState({focusedInput: focused})} 
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false}
          startDatePlaceholderText="Desde"
          endDatePlaceholderText="Hasta"
        />
        <FormHelperText>Fecha</FormHelperText>
      </FormControl>
    );
  }

  renderDateFilterRanking = () => {
    const {classes} = this.props;
    const {
      startDateSelectedRanking, 
      endDateSelectedRanking, 
      focusedInputRanking
    } = this.state;
    return (
      <FormControl className={classes.select}>
        <DateRangePicker
          startDate={startDateSelectedRanking} 
          startDateId="your_unique_start_date_id" 
          endDate={endDateSelectedRanking} 
          endDateId="your_unique_end_date_id" 
          onDatesChange={({startDate, endDate}) => this.handleChangeDateRanking(startDate, endDate)} 
          focusedInput={focusedInputRanking}
          onFocusChange={focused => this.setState({focusedInputRanking: focused})} 
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false}
          startDatePlaceholderText="Desde"
          endDatePlaceholderText="Hasta"
        />
        <FormHelperText>Fecha</FormHelperText>
      </FormControl>
    );
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  renderFunnel() {
    const {
      funnelHeight, 
      funnelWidth
    } = this.state;
    const {dashboard, origins} = this.props;
    if (dashboard.length > 0) {
      const hasData = dashboard.reduce((a, b) => a.concat(b)).reduce((a, b) => a + b);
      if (hasData > 0) {
        return (
          <Funnel
            height={funnelHeight}
            width={funnelWidth}
            values={dashboard}
            originsToFunnel={origins}
          />
        );
      }
    }
    return null;
  }

  renderLabels() {
    const {ranking} = this.props;
    const builderSession = localStorage.getItem('builder');
    if (builderSession !== 'false') {
      const rankingLabels = ranking.map((o) => {
        if (o.builderId) {
          if (o.builderId.toString() === builderSession.toString()) {
            return o.label;
          }
          return o.label.split('.')[0];
        } 
        return o.label;
      });
      return rankingLabels;
    }
    return ranking.map(o => o.label);
  }

  render() {
    const {
      classes,
      ranking
    } = this.props;

    const builderSession = localStorage.getItem('builder');

    const {
      isLoading, 
      isLoadingRanking,
      isLoadingBuilders, 
      isLoadingProjects, 
      isLoadingUsers, 
      isLoadingZones, 
      isLoadingComments
    } = this.props;
    
    if (
      isLoading === true || 
      isLoadingRanking === true || 
      isLoadingComments === true || 
      isLoadingBuilders === true || 
      isLoadingProjects === true || 
      isLoadingUsers === true || 
      isLoadingZones === true
    ) {
      return (
        <div style={{zIndex: 100, position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)'}}>
          <CircularProgress 
            style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} 
          />
        </div>
      );
    }

    return (
      <Paper className={classes.container}>
        {this.renderSnackBar()}
        <div className={classes.dashboardContainer}>
          <div className={classes.funnelContainer} ref={this.funnelContainer}>
            <Typography variant="h6">Embudo de conversión</Typography>
            <div className={classes.selectsContainer}>
              {this.renderBuilderFilter()}
              {this.renderZoneFilter()}
              {this.renderProjectFilter()}
              {this.renderBrokerFilter()}
              {this.renderDateFilter()}
            </div>
            <div>
              {this.renderFunnel()}
            </div>
            <div style={windowWidth >= 720 ? {marginTop: 200} : {marginTop: 500}}>
              <Typography variant="h6">Ranking de preferencia</Typography>
              <div className={classes.selectsContainer}>
                {this.renderBuilderFilterRanking()}
                {this.renderZoneFilterRanking()}
                {this.renderDateFilterRanking()}
              </div>
              <div>
                {
                  ranking && (
                    <ReactApexChart 
                      options={{
                        plotOptions: {
                          bar: {
                            horizontal: true
                          }
                        },
                        dataLabels: {
                          enabled: builderSession === 'false'
                        },
                        xaxis: {
                          categories: this.renderLabels()
                        },
                        fill: {
                          colors: ['#075E72']
                        }
                      }} 
                      series={[
                        {
                          name: 'Leads',
                          data: ranking.map(o => o.value)
                        }
                      ]} 
                      type="bar" 
                      height={ranking.length > 4 ? (30 * ranking.length) : ranking.length > 2 ? (60 * ranking.length) : (120 * ranking.length)} 
                    />
                  )
                }
              </div>
            </div>
            <div style={{marginTop: 50}}>
              <Typography variant="h6">Últimos Comentarios</Typography>
              <br />
              <div>
                <div className={classes.containerComments}>
                  <ThumbUp className={classes.thumbUp} />
                  <div className={classes.commentContainer}>
                    {this.renderComments(true)}
                  </div>
                </div>
                <div className={classes.containerComments}>
                  <ThumbDown className={classes.thumbDown} />
                  <div className={classes.commentContainer}>
                    {this.renderComments(false)}
                  </div>
                </div>
                <div>
                  <Link to="comentarios" className={classes.link} href="null">
                    <Typography variant="body1" className={classes.more}>Ver todos</Typography>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

Dashboard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingRanking: PropTypes.bool.isRequired,
  isLoadingBuilders: PropTypes.bool.isRequired,
  isLoadingProjects: PropTypes.bool.isRequired,
  isLoadingUsers: PropTypes.bool.isRequired,
  isLoadingComments: PropTypes.bool.isRequired,
  isLoadingZones: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  getDashboard: PropTypes.func.isRequired,
  getBuilders: PropTypes.func.isRequired,
  getZones: PropTypes.func.isRequired,
  filterProjects: PropTypes.func.isRequired,
  getProjectNames: PropTypes.func.isRequired,
  getAsesors: PropTypes.func.isRequired,
  getRanking: PropTypes.func.isRequired,
  dashboard: PropTypes.array,
  comments: PropTypes.array,
  builders: PropTypes.array,
  zones: PropTypes.array,
  projects: PropTypes.array,
  users: PropTypes.array,
  message: PropTypes.string,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {dashboard, builders, zones, projects, users, comments, origins} = state;
  return {
    isLoading: dashboard.sending,
    isLoadingRanking: dashboard.sendingRanking,
    dashboard: dashboard.dashboard,
    ranking: dashboard.ranking,
    comments: comments.comments,
    isLoadingComments: comments.sending,
    filters: dashboard.filters,
    rankingFilters: dashboard.rankingFilters,
    builders: builders.builders,
    isLoadingBuilders: builders.sending,
    projects: projects.projects,
    isLoadingProjects: projects.sending,
    users: users.users,
    isLoadingUsers: users.sending,
    zones: zones.zones,
    isLoadingZones: zones.sending,
    origins: origins.origins
  };
};

const mapDispatchToProps = {
  getDashboard,
  getRanking,
  getZones,
  getProjectNames,
  filterProjects,
  getAsesors,
  getBuilders,
  getComments,
  getOrigins
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(Dashboard));
