export const styles = theme => ({
  root: {
    width: '100%',
    height: '60vh'
  },
  gridRoot: {
    margin: 'auto'
  },
  cardHeader: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    '& .MuiCardHeader-content-734': {
      flex: '0 0 auto',
      width: '40%'
    },

    '& div[class^="MuiCardHeader-action"], div[class*=" MuiCardHeader-action"]': {
      flex: '1 1 auto',
      width: '50%'
    }
  },
  list: {
    height: '50vh',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  button: {
    margin: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 0}px`
  }
});

export default styles;
