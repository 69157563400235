/* eslint-disable no-param-reassign */
import axios from 'axios';
import { axiosMaker, errorMessage, logOutFunction, maxLimit } from '../utils/utils';
import * as leadActions from './types/leads';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterLeads = (
  page,
  rowsPerPage,
  order,
  orderBy,
  searchText,
  filtersToApply,
  strategicPartner,
) => async (dispatch) => {
  if (
    (searchText !== '' && store.getState().leads.searchText !== searchText) ||
    (Object.keys(filtersToApply).length && filtersToApply !== store.getState().leads.filters)
  ) {
    page = 0;
  }
  if (order !== store.getState().leads.order || orderBy !== store.getState().leads.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
    filtersToApply = {};
  }
  if (strategicPartner) {
    filtersToApply = strategicPartner;
  }
  dispatch({
    type: leadActions.FILTER_LEADS_SENDING,
    payload: {
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
      filters: filtersToApply,
    },
  });
  try {
    if (company && company._id) {
      filtersToApply.company = [company._id];
    }
    const data = {
      operator: Object.keys(filtersToApply).length === 0 ? 'or' : 'and',
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order,
      },
      forAdmin: true,
    };
    data.filters = filtersToApply;
    if (searchText !== '' && !filtersToApply.user) {
      data.filters.complete_name = searchText;
    }
    const request = axiosMaker('POST', 'leads/filter', data, true);
    const response = await axios(request);

    return dispatch({
      type: leadActions.FILTER_LEADS_SUCCESS,
      payload: {
        leads: response.data.results,
        dataLength: response.data.total,
      },
    });
  } catch (e) {
    const { data } = e.response;
    if (data) {
      const { message } = data;
      if (message === 'jwt expired') {
        logOutFunction();
      }
    }
    const error = e.response;
    return dispatch({
      type: leadActions.FILTER_LEADS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const searchLeads = (searchText) => async (dispatch) => {
  if (searchText === '') {
    return dispatch({
      type: leadActions.SEARCH_LEADS_SUCCESS,
      payload: {
        leadNames: [],
      },
    });
  }
  dispatch({
    type: leadActions.SEARCH_LEADS_SENDING,
    payload: {},
  });
  try {
    const data = {
      searchText,
    };
    const request = axiosMaker('POST', 'leads/search', data, true);
    const response = await axios(request);

    return dispatch({
      type: leadActions.SEARCH_LEADS_SUCCESS,
      payload: {
        leadNames: response.data.results,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadActions.SEARCH_LEADS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getBrokerLeads = () => async (dispatch) => {
  dispatch({
    type: leadActions.GET_BROKER_LEADS_SENDING,
    payload: {
      dataLength: 0,
    },
  });
  try {
    const data = {
      operator: 'and',
      page: 0,
      limit: maxLimit,
      sort: {
        user: 'asc',
      },
      filters: {},
      forAdmin: true,
    };
    const response = await axios(axiosMaker('POST', 'leads/filter', data, true));
    return dispatch({
      type: leadActions.GET_BROKER_LEADS_SUCCESS,
      payload: {
        leads: response.data.results,
        dataLength: response.data.total,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadActions.GET_BROKER_LEADS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getLead = (id) => async (dispatch) => {
  dispatch({
    type: leadActions.GET_LEAD_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('GET', `leads/${id}/getLeadForAdmin`, null, true);
    const response = await axios(request);
    return dispatch({
      type: leadActions.GET_LEAD_SUCCESS,
      payload: {
        lead: response.data.results[0],
        matches: response.data.results[0].matches,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.GET_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updatePointsInFavor = (id, data) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('POST', `leads/${id}/addUnitMatchPro`, data, true);
    const response = await axios(request);
    const lead = response.data;
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: { matches: lead.matches },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updatePointsAgainst = (id, data) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('POST', `leads/${id}/addUnitMatchCon`, data, true);
    await axios(request);
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getLeadPdf = (id) => async (dispatch) => {
  dispatch({
    type: leadActions.GET_LEAD_SENDING,
    payload: {},
  });
  try {
    const response = await axios(axiosMaker('GET', `leads/${id}`, null, true));
    return dispatch({
      type: leadActions.GET_LEAD_SUCCESS,
      payload: {
        lead: response.data.results[0],
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.GET_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const setLead = (lead) => (dispatch) => {
  dispatch({
    type: leadActions.SET_LEAD_SUCCESS,
    payload: {
      lead,
    },
  });
};

export const createLead = (data) => async (dispatch) => {
  dispatch({
    type: leadActions.CREATE_LEAD_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('POST', 'leads', data, true);
    const response = await axios(request);
    return dispatch({
      type: leadActions.CREATE_LEAD_SUCCESS,
      payload: {
        leadId: response.data.id,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadActions.CREATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onBulkAddLeads = (leadsToAdd, ignoredBrokers) => async (dispatch) => {
  dispatch({
    type: leadActions.CREATE_LEADS_BULK_SENDING,
    payload: {},
  });
  try {
    const request = await axiosMaker(
      'POST',
      'leads/createBulk',
      { leadsToAdd, ignoredBrokers },
      true,
    );
    await axios(request);
    return dispatch({
      type: leadActions.CREATE_LEADS_BULK_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadActions.CREATE_LEADS_BULK_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const assignLead = (id) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PUT', `leads/${id}/assignLead/`, null, true));
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const addNewAction = (id, description, deadline) => async (dispatch) => {
  dispatch({
    type: leadActions.ADD_LEAD_ACTION_SENDING,
    payload: {},
  });
  try {
    const data = {
      deadline,
      description,
    };
    const request = axiosMaker('POST', `leads/${id}/addAction`, data, true);
    await axios(request);
    return dispatch({
      type: leadActions.ADD_LEAD_ACTION_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadActions.ADD_LEAD_ACTION_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updateLead = (id, lead) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PATCH', `leads/${id}`, lead, true));
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onUpdateTemperature = (id, temperature) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PATCH', `leads/${id}`, { temperature }, true));
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};
export const onUpdatePrequalification = (id, prequalification) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PATCH', `leads/${id}`, { prequalification }, true));
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onUpdateEstimatedClosingTime = (id, estimatedClosingTime) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    const data = {
      estimated_closing_time: estimatedClosingTime,
    };
    await axios(axiosMaker('PATCH', `leads/${id}`, data, true));
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onUpdateClosingPrice = (
  id,
  closingPrice,
  project,
  unit,
  builder,
  beneficiary,
) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    const data = {
      project,
      unit,
      builder,
      beneficiary,
      closing_price: parseFloat(closingPrice),
    };

    await axios(axiosMaker('PATCH', `leads/${id}/patchClosingPrice`, data, true));
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onWinUnit = (id, data) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('PATCH', `leads/${id}/winUnit`, data, true);
    await axios(request);
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const addObservation = (id, description, origin, userId, type) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker(
      'POST',
      `leads/${id}/addObservation`,
      { description, origin, userId, type },
      true,
    );
    await axios(request);
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updateLeadState = (
  id,
  status = '',
  stage = '',
  updateHistoric = true,
  project,
  unit,
  winData,
) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {
      isEditingPreferences: false,
    },
  });
  try {
    const data = {};
    if (status !== '') {
      data.status = status;
    }
    if (stage !== '') {
      data.stage = stage;
    }
    data.updateHistoric = updateHistoric;
    data.projectSold = project;
    data.unitSold = unit;
    data.winData = winData;
    const request = axiosMaker('PATCH', `leads/${id}/patchLeadStatus`, data, true);
    await axios(request);
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updateLeadStage = (id, stage) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PATCH', `leads/${id}`, { stage }, true));
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onSendLeadEmail = (
  id,
  templateId,
  to,
  attachment,
  fileType,
  replaceWith,
  from,
) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_SENDING,
    payload: {},
  });
  try {
    const data = {
      leadId: id,
      templateId,
      to,
      data: replaceWith,
    };
    if (attachment !== '') {
      data.attachment = attachment;
      data.attachmentType = fileType;
    }
    if (from !== '') {
      data.from = from;
    }
    await axios(axiosMaker('POST', 'users/sendEmailTemplate/', data, true));
    return dispatch({
      type: leadActions.UPDATE_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onUpdateLeadMatches = (lead, parameters) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_MATCHES_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('PUT', `leads/${lead._id}/generateMatches`, { parameters }, true);
    const response = await axios(request);
    const leadWithMatches = lead;
    const matches = response.data.results;
    leadWithMatches.matches = matches;
    return dispatch({
      type: leadActions.UPDATE_LEAD_MATCHES_SUCCESS,
      payload: {
        matches,
        lead: leadWithMatches,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_MATCHES_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onUpdateLeadUser = (id, user) => async (dispatch) => {
  dispatch({
    type: leadActions.UPDATE_LEAD_USER_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PATCH', `leads/${id}`, { user }, true));
    return dispatch({
      type: leadActions.UPDATE_LEAD_USER_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UPDATE_LEAD_USER_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onAddLeadMatch = (leadId, unitId, projectId) => async (dispatch) => {
  dispatch({
    type: leadActions.ADD_LEAD_MATCH_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker(
      'POST',
      `leads/${leadId}/addLeadMatchNew`,
      { unitId, projectId },
      true,
    );
    await axios(request);
    return dispatch({
      type: leadActions.ADD_LEAD_MATCH_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.ADD_LEAD_MATCH_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onSwapMatches = (leadId, oldIndex, newIndex) => async (dispatch) => {
  dispatch({
    type: leadActions.SWAP_MATCH_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker(
      'PUT',
      `leads/${leadId}/swapMatches`,
      { a_index: oldIndex, b_index: newIndex },
      true,
    );
    const response = await axios(request);
    const lead = response.data;
    return dispatch({
      type: leadActions.SWAP_MATCH_SUCCESS,
      payload: { matches: lead.matches },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.SWAP_MATCH_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onReplaceMatches = (leadId, sortBy) => async (dispatch) => {
  dispatch({
    type: leadActions.SWAP_MATCH_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('PUT', `leads/${leadId}/replaceMatches`, { sortBy }, true);
    const response = await axios(request);
    const lead = response.data;
    return dispatch({
      type: leadActions.SWAP_MATCH_SUCCESS,
      payload: { matches: lead.matches },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.SWAP_MATCH_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onSwapMatchUnits = (leadId, projectIndex, oldIndex, newIndex) => async (dispatch) => {
  dispatch({
    type: leadActions.SWAP_UNIT_MATCH_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker(
      'PUT',
      `leads/${leadId}/swapMatchUnits`,
      { projectIndex, a_index: oldIndex, b_index: newIndex },
      true,
    );
    const response = await axios(request);
    const lead = response.data;
    return dispatch({
      type: leadActions.SWAP_UNIT_MATCH_SUCCESS,
      payload: { matches: lead.matches },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.SWAP_UNIT_MATCH_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onAddUnitMatchComment = (leadId, index, unitId, comment) => async (dispatch) => {
  dispatch({
    type: leadActions.ADD_UNIT_MATCH_COMMENT_SENDING,
    payload: {},
  });
  try {
    await axios(
      axiosMaker('POST', `leads/${leadId}/addUnitMatchComment`, { comment, index, unitId }, true),
    );
    return dispatch({
      type: leadActions.ADD_UNIT_MATCH_COMMENT_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.ADD_UNIT_MATCH_COMMENT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onChangeProjectMatchStage = (leadId, projectIndex, stage) => async (dispatch) => {
  dispatch({
    type: leadActions.CHANGE_PROJECT_MATCH_STAGE_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker(
      'POST',
      `leads/${leadId}/changeProjectMatchStage`,
      { stage, projectIndex },
      true,
    );
    await axios(request);
    return dispatch({
      type: leadActions.CHANGE_PROJECT_MATCH_STAGE_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.CHANGE_PROJECT_MATCH_STAGE_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onBlockProject = (leadId, index) => async (dispatch) => {
  dispatch({
    type: leadActions.BLOCK_MATCH_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('PUT', `leads/${leadId}/blockMatch`, { index }, true);
    const response = await axios(request);
    const lead = response.data;
    return dispatch({
      type: leadActions.BLOCK_MATCH_SUCCESS,
      payload: { matches: lead.matches },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.BLOCK_MATCH_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onUnBlockProject = (leadId, index) => async (dispatch) => {
  dispatch({
    type: leadActions.UNBLOCK_MATCH_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('PUT', `leads/${leadId}/unBlockMatch`, { index }, true);
    const response = await axios(request);
    const lead = response.data;
    return dispatch({
      type: leadActions.UNBLOCK_MATCH_SUCCESS,
      payload: { matches: lead.matches },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.UNBLOCK_MATCH_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onDuplicateProject = (leadId, index) => async (dispatch) => {
  dispatch({
    type: leadActions.DUPLICATE_MATCH_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('PUT', `leads/${leadId}/duplicateMatch`, { index }, true);
    const response = await axios(request);
    const lead = response.data;
    return dispatch({
      type: leadActions.DUPLICATE_MATCH_SUCCESS,
      payload: { matches: lead.matches },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.DUPLICATE_MATCH_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onRemoveMatch = (leadId, index) => async (dispatch) => {
  dispatch({
    type: leadActions.REMOVE_MATCH_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('PUT', `leads/${leadId}/removeMatch`, { index }, true);
    const response = await axios(request);
    const lead = response.data;
    return dispatch({
      type: leadActions.REMOVE_MATCH_SUCCESS,
      payload: { matches: lead.matches },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.REMOVE_MATCH_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onRemoveMatches = (leadId, indexes) => async (dispatch) => {
  dispatch({
    type: leadActions.REMOVE_MATCH_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('PUT', `leads/${leadId}/removeMatches`, { indexes }, true);
    const response = await axios(request);
    const lead = response.data;
    return dispatch({
      type: leadActions.REMOVE_MATCH_SUCCESS,
      payload: { matches: lead.matches },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.REMOVE_MATCH_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onReassignLead = (leadId, currentBrokerId, newBrokerId, zoneId) => async (
  dispatch,
) => {
  dispatch({
    type: leadActions.REASSIGN_LEAD_SENDING,
    payload: {},
  });
  try {
    const data = {
      currentBrokerId,
      newBrokerId,
      zoneId,
    };
    const request = axiosMaker('PUT', `leads/${leadId}/reAssignLead`, data, true);

    await axios(request);

    return dispatch({
      type: leadActions.REASSIGN_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.REASSIGN_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const assignBrokerToLead = (leadId, brokerId) => async (dispatch) => {
  dispatch({
    type: leadActions.REASSIGN_LEAD_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('PUT', `leads/${leadId}/assignBrokerToLead`, { brokerId }, true);
    await axios(request);
    return dispatch({
      type: leadActions.REASSIGN_LEAD_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadActions.REASSIGN_LEAD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const onChangeUnitComment = (lead, projectIndex, comment) => async (dispatch) => {
  dispatch({
    type: leadActions.CHANGE_UNIT_COMMENT_SENDING,
    payload: {},
  });
  try {
    const newLead = lead;
    if (lead.matches) {
      newLead.matches[projectIndex].units[0].comments = comment;
    }
    return dispatch({
      type: leadActions.CHANGE_UNIT_COMMENT_SUCCESS,
      payload: {
        lead: newLead,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.CHANGE_UNIT_COMMENT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const switchEditingPreferences = () => (dispatch) => {
  // eslint-disable-next-line prefer-destructuring
  const isEditingPreferences = !store.getState().leads.isEditingPreferences;
  return dispatch({
    type: leadActions.SWITCH_EDITING_LEADS,
    payload: {
      isEditingPreferences,
    },
  });
};

export const bulkLeadAssign = (brokerId, leads) => async (dispatch) => {
  dispatch({
    type: leadActions.BULK_LEAD_ASSIGN_SENDING,
    payload: {},
  });
  try {
    const result = await axios(axiosMaker('PUT', `leads/${brokerId}/bulkAssign`, { leads }, true));
    return dispatch({
      type: leadActions.BULK_LEAD_ASSIGN_SUCCESS,
      payload: result.data,
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadActions.BULK_LEAD_ASSIGN_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const validateBulkEmails = async (emails, companyId) => {
  try {
    const response = await axios(
      axiosMaker('POST', 'leads/validateBulkEmails', { emails, company: companyId }, true),
    );
    return response.data || [];
  } catch (e) {
    return [];
  }
};
