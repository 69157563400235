/* eslint-disable no-param-reassign */
import * as smartDemandFilters from './types/smartDemandFilters';
import store from '../store';

export const changeFilters = () => (dispatch) => {
  const {
    startDate,
    endDate,
    globalFilter,
    project,
    zone,
    unitType,
    room,
  } = store.getState().smartDemandFilters;

  const filters = {
    startDate,
    endDate,
  };

  if (!globalFilter) filters.company = JSON.parse(localStorage.getItem('company')) && JSON.parse(localStorage.getItem('company'))._id;
  if (project && project.length > 0) filters.projects = project;
  if (zone && zone.length > 0) filters.zones = zone;
  if (unitType && unitType.length > 0) filters.types = unitType;
  if (room && room.length > 0) filters.unitAttributes = room;

  dispatch({
    type: smartDemandFilters.SET_FILTERS,
    payload: { filters },
  });
};

const getChipData = (chips) => {
  const dateChips = chips.filter((chip) => chip.type === 'date');
  const zoneChips = chips.filter((chip) => chip.type === 'zone');
  const roomChips = chips.filter((chip) => chip.type === 'room');
  const unitTypeChips = chips.filter((chip) => chip.type === 'unitType');
  const projectChips = chips.filter((chip) => chip.type === 'project');

  return [
    ...dateChips,
    ...zoneChips,
    ...roomChips,
    ...unitTypeChips,
    ...projectChips,
  ].map((chip, index) => ({ ...chip, key: index }));
};

export const orderChips = (chips) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_CHIP_DATA,
    payload: {
      chipData: getChipData(chips),
    },
  });
};

export const setDisabledFilter = (disabledFilter) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_DISABLE_FILTER,
    payload: { disabledFilter },
  });
};

export const setProject = (project) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_PROJECT,
    payload: { project },
  });
};

export const setZone = (zone) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_ZONE,
    payload: { zone },
  });
};

export const setCities = (cities) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_CITIES,
    payload: { cities },
  });
};

export const setRoom = (room) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_ROOM,
    payload: { room },
  });
};

export const setUnitType = (unitType) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_UNIT_TYPE,
    payload: { unitType },
  });
};

export const setStartDate = (startDate) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_START_DATE,
    payload: { startDate },
  });
};

export const setEndDate = (endDate) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_END_DATE,
    payload: { endDate },
  });
};

export const setGlobalFilter = (globalFilter) => (dispatch) => {
  dispatch({
    type: smartDemandFilters.SET_GLOBAL_FILTER,
    payload: { globalFilter },
  });
};