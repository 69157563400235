import React from 'react';
import HighchartsReact from 'highcharts-react-official';

const Chart = ({ options, highcharts }) => (
  <HighchartsReact
    highcharts={highcharts}
    constructorType="chart"
    options={options}
    allowChartUpdate={true}
    // containerProps={{ style: { height: '300px' } }}
  />
);

export default Chart;
