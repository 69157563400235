import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import {styles} from './styles';

export class CustomYesNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answer} = currentAnswer;
      if (answer !== '') {
        this.setState({answer});
      }
    }
  }

  handleChange(event) {
    const {identifier} = this.props;
    const answer = event.target.value;
    this.setState({answer});
    this.props.updateAnswer(identifier, [], answer);
  }

  render() {
    const {classes} = this.props;
    return (
      <FormGroup>
        <FormControlLabel
          className={classes.check}
          control={
            <Checkbox
              checked={this.state.answer === 'Si'}
              onChange={this.handleChange}
              value="Si"
              className="PruebaSi"
              icon={<RadioButtonUnchecked />} 
              checkedIcon={<RadioButtonChecked />} 
            />
          }
          label="Si"
        />
        <FormControlLabel
          className={classes.check}
          control={
            <Checkbox
              checked={this.state.answer === 'No'}
              onChange={this.handleChange}
              value="No"
              className="PruebaNo"
              icon={<RadioButtonUnchecked />} 
              checkedIcon={<RadioButtonChecked />} 
            />
          }
          label="No"
        />
      </FormGroup>
    );
  }
}

CustomYesNo.propTypes = {
  classes: PropTypes.object.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  currentAnswer: PropTypes.object,
  identifier: PropTypes.string
};

export default withStyles(styles)(CustomYesNo);
