/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as cityActions from './types/cities';
import store from '../store';


export const filterCities = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().cities.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().cities.order || orderBy !== store.getState().cities.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: cityActions.FILTER_CITIES_SENDING,
    payload: {
      cities: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && order !== null && orderBy !== null && searchText !== null) {
      filter = {
        filters: {
          name: searchText
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order
        }
      };
    }
    const response = await axios(axiosMaker('POST', 'cities/filter', filter, true));
    return dispatch({
      type: cityActions.FILTER_CITIES_SUCCESS,
      payload: {
        cities: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: cityActions.FILTER_CITIES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getCities = () => async (dispatch) => {
  dispatch({
    type: cityActions.GET_CITIES_SENDING,
    payload: {
      cities: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {}
    };
    const response = await axios(axiosMaker('POST', 'cities/filter', data, true));
    return dispatch({
      type: cityActions.GET_CITIES_SUCCESS,
      payload: {
        cities: response.data.results,
        dataLength: response.data.results.length
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: cityActions.GET_CITIES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createCity = data => async (dispatch) => {
  dispatch({
    type: cityActions.CREATE_CITY_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'cities', data, true));
    return dispatch({
      type: cityActions.CREATE_CITY_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: cityActions.CREATE_CITY_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateCity = (id, province) => async (dispatch) => {
  dispatch({
    type: cityActions.UPDATE_CITY_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `cities/${id}`, province, true));
    return dispatch({
      type: cityActions.UPDATE_CITY_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: cityActions.UPDATE_CITY_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
