import { combineReducers } from 'redux';
import userReducer from './user';
import usersReducer from './users';
import parametersReducer from './parameters';
import uploaderReducer from './uploader';
import drawerReducer from './drawer';
import projectsReducer from './projects';
import unitsReducer from './units';
import zonesReducer from './zones';
import leadsReducer from './leads';
import leadStatesReducer from './leadStates';
import stagesReducer from './stages';
import commissionsReducer from './commissions';
import buildersReducer from './builders';
import propertyTypesReducer from './propertyTypes';
import whatsappTemplatesReducer from './whatsappTemplates';
import emailTemplatesReducer from './emailTemplates';
import profilesReducer from './profiles';
import dashboardReducer from './dashboard';
import commentsReducer from './comments';
import rulesReducer from './rules';
import advanceReducer from './advance';
import originsReducer from './origins';
import observationsReducer from './observations';
import identificationTypesReducer from './identificationTypes';
import companiesReducer from './companies';
import countriesReducer from './countries';
import provincesReducer from './provinces';
import leadStagesReducer from './leadStages';
import citiesReducer from './cities';
import leadsAssignmentReducer from './leadsAssignment';
import standReducer from './stands';
import smartReducer from './smart';
import plansReducer from './plans';
import proformasReducer from './proformas';
import newsReducer from './news';
import SmartSupplyFilters from './smartSupplyFilters';
import SmartDemandFilters from './smartDemandFilters';
import calendarReducer from './calendar';
import tasksReducer from './tasks';
import eventsHistoryReducer from './eventsHistory';
import tasksHistoryReducer from './tasksHistory';
import leadActiveEventsReducer from './leadActiveEvents';
import eventsStatisticsReducer from './eventsStatistics';
import groupsReducer from './groups';

export const rootReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  comments: commentsReducer,
  parameters: parametersReducer,
  dashboard: dashboardReducer,
  uploader: uploaderReducer,
  drawer: drawerReducer,
  leads: leadsReducer,
  projects: projectsReducer,
  units: unitsReducer,
  zones: zonesReducer,
  stages: stagesReducer,
  commissions: commissionsReducer,
  leadStates: leadStatesReducer,
  builders: buildersReducer,
  propertyTypes: propertyTypesReducer,
  whatsappTemplates: whatsappTemplatesReducer,
  emailTemplates: emailTemplatesReducer,
  rules: rulesReducer,
  profiles: profilesReducer,
  advances: advanceReducer,
  origins: originsReducer,
  observations: observationsReducer,
  identificationTypes: identificationTypesReducer,
  companies: companiesReducer,
  countries: countriesReducer,
  provinces: provincesReducer,
  leadStages: leadStagesReducer,
  cities: citiesReducer,
  leadsAssignment: leadsAssignmentReducer,
  stands: standReducer,
  smart: smartReducer,
  plans: plansReducer,
  proformas: proformasReducer,
  news: newsReducer,
  smartSupplyFilters: SmartSupplyFilters,
  smartDemandFilters: SmartDemandFilters,
  calendar: calendarReducer,
  tasks: tasksReducer,
  eventsHistory: eventsHistoryReducer,
  tasksHistory: tasksHistoryReducer,
  leadActiveEvents: leadActiveEventsReducer,
  eventsStatistics: eventsStatisticsReducer,
  groups: groupsReducer,
});

export default rootReducer;
