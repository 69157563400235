import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DeviceHub from '@material-ui/icons/DeviceHub';
import { editProject } from '../../actions/projects';

import { styles } from './styles';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';

const Integrations = (props) => {
  const { project } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openModalIntegrations, setOpenModalIntegrations] = useState(false);
  const [properatiProjectCode, setProperatiProjectCode] = useState('');
  const [plusvaliaProjectCode, setPlusvaliaProjectCode] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (project.properatiProjectCode) {
      setProperatiProjectCode(project.properatiProjectCode);
    }
    if (project.plusvaliaProjectCode) {
      setPlusvaliaProjectCode(project.plusvaliaProjectCode);
    }
  }, []);

  const showError = () => {
    enqueueSnackbar('Error al guardar el proyecto!', {
      autoHideDuration: 1200,
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const openModal = () => {
    setOpenModalIntegrations(true);
  };
  const closeModal = () => {
    setOpenModalIntegrations(false);
  };

  const saveProject = () => {
    console.log('plusvaliaProjectCode:', plusvaliaProjectCode,properatiProjectCode);
    dispatch(editProject(project.id,{
      plusvaliaProjectCode,
      properatiProjectCode
    }));
  };

  const renderIntegrations = () => {
    const { poperatiCode, plusavaliaCode } = project;
    return (
      <Dialog
        onClose={closeModal}
        aria-labelledby="simple-dialog-title"
        open={openModalIntegrations}
      >
        <DialogTitle id="simple-dialog-title">Integraciones</DialogTitle>
        <div className={classes.containerModal}>
          <TextField
            label="Código de proyecto de plusvalía"
            value={plusvaliaProjectCode}
            name="plusvaliaProjectCode"
            onChange={(e) => setPlusvaliaProjectCode(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
          <TextField
            label="Código de proyecto de properati"
            value={properatiProjectCode}
            name="properatiProjectCode"
            onChange={(e) => setProperatiProjectCode(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
          />
        </div>
        <div className={classes.footerContained}>
          <Button variant="contained" color="primary" onClick={saveProject}>
            Guardar
          </Button>
          <Button variant="contained" onClick={closeModal}>
            Cancelar
          </Button>
        </div>
      </Dialog>
    );
  };

  const { classes } = props;
  return (
    <div className={classes.inlineItem}>
      <Tooltip title="Integraciones" className={classes.cursor}>
        <IconButton className={classes.matchButton} onClick={openModal}>
          <DeviceHub />
        </IconButton>
      </Tooltip>
      {renderIntegrations()}
    </div>
  );
};

Integrations.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
};

export default withStyles(styles)(Integrations);
