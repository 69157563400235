export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';

export const GET_DASHBOARD_SENDING = 'GET_DASHBOARD_SENDING';

export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED';


export const GET_RANKING_SUCCESS = 'GET_RANKING_SUCCESS';

export const GET_RANKING_SENDING = 'GET_RANKING_SENDING';

export const GET_RANKING_FAILED = 'GET_RANKING_FAILED';

export const CHANGE_FILTERS = 'CHANGE_FILTERS';
