import * as provinceActions from '../actions/types/provinces';

const initialState = {
  provinces: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {provinces, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case provinceActions.FILTER_PROVINCES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case provinceActions.FILTER_PROVINCES_SUCCESS:
    return {...state, sending: false, provinces, error: null, message: 'Provincias obtenidos satisfactoriamente', dataLength};
  case provinceActions.FILTER_PROVINCES_FAILED:
    return {...state, sending: false, provinces: [], error, message, dataLength};
  case provinceActions.GET_PROVINCES_SENDING:
    return {...state, sending: true};
  case provinceActions.GET_PROVINCES_SUCCESS:
    return {...state, sending: false, provinces, error: null, message: 'Provincias obtenidos satisfactoriamente', dataLength};
  case provinceActions.GET_PROVINCES_FAILED:
    return {...state, sending: false, provinces: [], error, message, dataLength};
  case provinceActions.CREATE_PROVINCE_SENDING:
    return {...state, sending: true};
  case provinceActions.CREATE_PROVINCE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Provincia creada satisfactoriamente'};
  case provinceActions.CREATE_PROVINCE_FAILED:
    return {...state, sending: false, error, message};
  case provinceActions.UPDATE_PROVINCE_SENDING:
    return {...state, sending: true};
  case provinceActions.UPDATE_PROVINCE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Provincia actualizada satisfactoriamente'};
  case provinceActions.UPDATE_PROVINCE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
