export const GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS';

export const SET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS';

export const GET_LEAD_SENDING = 'GET_LEAD_SENDING';

export const GET_LEAD_FAILED = 'GET_LEAD_FAILED';


export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';

export const GET_LEADS_SENDING = 'GET_LEADS_SENDING';

export const GET_LEADS_FAILED = 'GET_LEADS_FAILED';


export const GET_BROKER_LEADS_SUCCESS = 'GET_BROKER_LEADS_SUCCESS';

export const GET_BROKER_LEADS_SENDING = 'GET_BROKER_LEADS_SENDING';

export const GET_BROKER_LEADS_FAILED = 'GET_BROKER_LEADS_FAILED';


export const SEARCH_LEADS_SUCCESS = 'SEARCH_LEADS_SUCCESS';

export const SEARCH_LEADS_SENDING = 'SEARCH_LEADS_SENDING';

export const SEARCH_LEADS_FAILED = 'SEARCH_LEADS_FAILED';


export const FILTER_LEADS_SUCCESS = 'FILTER_LEADS_SUCCESS';

export const FILTER_LEADS_SENDING = 'FILTER_LEADS_SENDING';

export const FILTER_LEADS_FAILED = 'FILTER_LEADS_FAILED';


export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';

export const CREATE_LEAD_SENDING = 'CREATE_LEAD_SENDING';

export const CREATE_LEAD_FAILED = 'CREATE_LEAD_FAILED';


export const CREATE_LEADS_BULK_SUCCESS = 'CREATE_LEADS_BULK_SUCCESS';

export const CREATE_LEADS_BULK_SENDING = 'CREATE_LEADS_BULK_SENDING';

export const CREATE_LEADS_BULK_FAILED = 'CREATE_LEADS_BULK_FAILED';


export const UPDATE_LEAD_SUCCESS = 'UPDATE_LEAD_SUCCESS';

export const UPDATE_LEAD_SENDING = 'UPDATE_LEAD_SENDING';

export const UPDATE_LEAD_FAILED = 'UPDATE_LEAD_FAILED';


export const UPDATE_LEAD_MATCHES_SUCCESS = 'UPDATE_LEAD_MATCHES_SUCCESS';

export const UPDATE_LEAD_MATCHES_SENDING = 'UPDATE_LEAD_MATCHES_SENDING';

export const UPDATE_LEAD_MATCHES_FAILED = 'UPDATE_LEAD_MATCHES_FAILED';


export const UPDATE_LEAD_USER_SUCCESS = 'UPDATE_LEAD_USER_SUCCESS';

export const UPDATE_LEAD_USER_SENDING = 'UPDATE_LEAD_USER_SENDING';

export const UPDATE_LEAD_USER_FAILED = 'UPDATE_LEAD_USER_FAILED';


export const ADD_LEAD_ACTION_SUCCESS = 'ADD_LEAD_ACTION_SUCCESS';

export const ADD_LEAD_ACTION_SENDING = 'ADD_LEAD_ACTION_SENDING';

export const ADD_LEAD_ACTION_FAILED = 'ADD_LEAD_ACTION_FAILED';


export const ADD_LEAD_MATCH_SUCCESS = 'ADD_LEAD_MATCH_SUCCESS';

export const ADD_LEAD_MATCH_SENDING = 'ADD_LEAD_MATCH_SENDING';

export const ADD_LEAD_MATCH_FAILED = 'ADD_LEAD_MATCH_FAILED';


export const ADD_UNIT_MATCH_COMMENT_SUCCESS = 'ADD_UNIT_MATCH_COMMENT_SUCCESS';

export const ADD_UNIT_MATCH_COMMENT_SENDING = 'ADD_UNIT_MATCH_COMMENT_SENDING';

export const ADD_UNIT_MATCH_COMMENT_FAILED = 'ADD_UNIT_MATCH_COMMENT_FAILED';


export const SWAP_MATCH_SUCCESS = 'SWAP_MATCH_SUCCESS';

export const SWAP_MATCH_SENDING = 'SWAP_MATCH_SENDING';

export const SWAP_MATCH_FAILED = 'SWAP_MATCH_FAILED';


export const SWAP_UNIT_MATCH_SUCCESS = 'SWAP_UNIT_MATCH_SUCCESS';

export const SWAP_UNIT_MATCH_SENDING = 'SWAP_UNIT_MATCH_SENDING';

export const SWAP_UNIT_MATCH_FAILED = 'SWAP_UNIT_MATCH_FAILED';


export const REMOVE_MATCH_SUCCESS = 'REMOVE_MATCH_SUCCESS';

export const REMOVE_MATCH_SENDING = 'REMOVE_MATCH_SENDING';

export const REMOVE_MATCH_FAILED = 'REMOVE_MATCH_FAILED';


export const BLOCK_MATCH_SUCCESS = 'BLOCK_MATCH_SUCCESS';

export const BLOCK_MATCH_SENDING = 'BLOCK_MATCH_SENDING';

export const BLOCK_MATCH_FAILED = 'BLOCK_MATCH_FAILED';

export const UNBLOCK_MATCH_SUCCESS = 'UNBLOCK_MATCH_SUCCESS';

export const UNBLOCK_MATCH_SENDING = 'UNBLOCK_MATCH_SENDING';

export const UNBLOCK_MATCH_FAILED = 'UNBLOCK_MATCH_FAILED';


export const CHANGE_UNIT_COMMENT_SUCCESS = 'CHANGE_UNIT_COMMENT_SUCCESS';

export const CHANGE_UNIT_COMMENT_SENDING = 'CHANGE_UNIT_COMMENT_SENDING';

export const CHANGE_UNIT_COMMENT_FAILED = 'CHANGE_UNIT_COMMENT_FAILED';


export const CHANGE_PROJECT_MATCH_STAGE_SUCCESS = 'CHANGE_PROJECT_MATCH_STAGE_SUCCESS';

export const CHANGE_PROJECT_MATCH_STAGE_SENDING = 'CHANGE_PROJECT_MATCH_STAGE_SENDING';

export const CHANGE_PROJECT_MATCH_STAGE_FAILED = 'CHANGE_PROJECT_MATCH_STAGE_FAILED';


export const REASSIGN_LEAD_SENDING = 'REASSIGN_LEAD_SENDING';

export const REASSIGN_LEAD_SUCCESS = 'REASSIGN_LEAD_SUCCESS';

export const REASSIGN_LEAD_FAILED = 'REASSIGN_LEAD_FAILED';


export const SWITCH_EDITING_LEADS = 'SWITCH_EDITING_LEADS';


export const BULK_LEAD_ASSIGN_SENDING = 'BULK_LEAD_ASSIGN_SENDING';

export const BULK_LEAD_ASSIGN_SUCCESS = 'BULK_LEAD_ASSIGN_SUCCESS';

export const BULK_LEAD_ASSIGN_FAILED = 'BULK_LEAD_ASSIGN_FAILED';


export const DUPLICATE_MATCH_SENDING = 'DUPLICATE_MATCH_SENDING';

export const DUPLICATE_MATCH_SUCCESS = 'DUPLICATE_MATCH_SUCCESS';

export const DUPLICATE_MATCH_FAILED = 'DUPLICATE_MATCH_FAILED';
