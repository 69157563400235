import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import moment from 'moment-timezone';
import { getEvents } from '../../../actions/calendar';
import CustomSnackbar from '../../common/customSnackbar';
import { styles } from './styles';
import { withStyles } from '@material-ui/core';
import ActionsEvents from './actionsEvent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const EventList = (props) => {
  const { open, closeModal, task, classes, brokers, lead } = props;
  const dispatch = useDispatch();

  const { page, size, tasks, sending, hasMore, firstLoad, messageAction, error } = useSelector(
    (state) => state.tasks,
  );

  console.log({ events: tasks });

  useEffect(() => {
    dispatch(getEvents(1, size, brokers, lead));
  }, []);

  const reloadData = async () => {
    await dispatch(getEvents(1, size, brokers, lead));
  };

  const handleClose = () => {
    closeModal();
  };

  const renderSnackBar = () => {
    if (messageAction && messageAction != '') {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={messageAction || 'texto'}
        />
      );
    }
    return null;
  };

  const renderDate = (date) => {
    return `Fecha: ${moment(date).format('LLLL')}`;
  };

  const renderList = () => {
    return (
      <div className={classes.root}>
        {tasks.length > 0 ? (
          <List className={classes.root2}>
            {tasks.map((event, index) => (
              <ListItem key={`list-task-${index}`} role={undefined} dense button>
                <div className={classes.itemTask}>
                  <div className={classes.itemTaskContent}>
                    <div className={classes.taskTitle}> {event.title}</div>
                    <div className={classes.taskSubtitle}>{event.lead}</div>
                    <div className={classes.date}>{renderDate(event.date)}</div>
                  </div>
                </div>
                <ListItemSecondaryAction>
                  <ActionsEvents event={event} reloadData={handleClose} />
                </ListItemSecondaryAction>
              </ListItem>
            ))}

            {renderSnackBar()}
          </List>
        ) : (
          <div className={classes.emptyList}>No hay citas confirmadas</div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Citas Confirmadas</DialogTitle>
        <DialogContent>
          <div>{renderList()}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(EventList);
