import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import Uploader from '../../../common/uploader';
import {getCities} from '../../../../actions/cities';

export class ZonesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      name: '',
      icon: '',
      isFather: false,
      father: null,
      city: '',
      code: '',
    };
    this.renderButton = this.renderButton.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
    this.handleChangeZone = this.handleChangeZone.bind(this);
    this.handleChangeIsFather = this.handleChangeIsFather.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
  }

  componentWillMount() {
    const {isNew, zone} = this.props;
    this.props.getCities();
    if (isNew === false) {
      this.setState({
        zone,
        name: zone.name,
        icon: zone.icon ? zone.icon : '',
        active: zone.active,
        isFather: zone.father === null,
        father: zone.father === null ? null : typeof zone.father === 'string' ? zone.father : zone.father.id,
        city: zone.city,
        code: zone.code,
      });
    }
  }

  onAddButtonClick() {
    const {name, icon, father, city, code} = this.state;
    this.props.handleCreateZone({name, icon, father, city, code});
  }

  onEditButtonClick() {
    const {zone, active, icon, name, father, city, code} = this.state;
    const zoneEdited = {
      id: zone.id,
      active,
      father,
      name,
      icon,
      city, 
      code
    };
    this.props.handleUpdateZone(zoneEdited);
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  handleUrlUploaded(icon) {
    this.setState({icon: icon === false ? '' : icon});
  }

  handleChangeZone(e) {
    this.setState({father: e.target.value});
  }

  handleChangeCity(e) {
    this.setState({city: e.target.value});
  }

  handleChangeIsFather(e) {
    this.setState({isFather: e.target.checked, father: null});
  }

  isInvalid() {
    const {name, isFather, father, city} = this.state;
    if (name === '' || (city === null || city === '')) {
      return true;
    }
    if (!isFather && (father === null || father === '')) {
      return true;
    }
    if (isFather && father !== null) {
      return true;
    }
    return false;
  }

  renderButton() {
    const {isLoading, isNew, classes} = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} este sector?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} sector`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderCities = () => {
    const {cities} = this.props;
    return cities.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
      );
    });
  }; 

  renderRowCity = () => {
    const {classes} = this.props;
    return (
      <TableRow>
        <TableCell className={classes.tableRowTitle}><b>Ciudad</b></TableCell>
        <TableCell>
          <Select
            name="city"
            value={this.state.city}
            fullWidth
            onChange={this.handleChangeCity}
          >
            {this.renderCities()}
          </Select>
        </TableCell>
      </TableRow>
    );
  };

  renderFathers() {
    const {classes, allZones} = this.props;
    const {isFather, father, city} = this.state;
    const fathers = city && city !== '' ? allZones.filter(item => item.father === null && item.city === city) : allZones.filter(item => item.father === null);
    
    if (isFather === false) {
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}><b>Sector Padre</b></TableCell>
          <TableCell>
            <Select
              value={father}
              fullWidth
              onChange={this.handleChangeZone}
            >
              {fathers.map((item, index) => <MenuItem value={item.id} key={index}>{item.name}</MenuItem>)}
            </Select>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  }
    
  render() {
    const {classes, isLoading, isNew} = this.props;
    return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de ' : 'Nuevo '} sector
          </Typography>
        </Toolbar>
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              {
                !isNew &&
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Disponible</b></TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.active}
                          onChange={e => this.setState({active: e.target.checked})}
                          color="primary"
                        />
                      }
                      label={this.state.active === true ? 'Disponible' : 'No disponible'}
                    />
                  </TableCell>
                </TableRow>
              }
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Nombre</b></TableCell>
                <TableCell>
                  <TextField 
                    autoFocus
                    value={this.state.name}
                    onChange={e => this.setState({name: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow> 
              {this.renderRowCity()}
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>¿Sector Padre?</b></TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.isFather}
                        onChange={this.handleChangeIsFather}
                        color="primary"
                      />
                    }
                    label={this.state.isFather === true ? 'Sector padre' : 'No es sector padre'}
                  />
                </TableCell>
              </TableRow>
              {this.renderFathers()} 
              {this.state.isFather === false && ( <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Código</b></TableCell>
                <TableCell>
                  <TextField 
                    autoFocus
                    value={this.state.code}
                    onChange={e => this.setState({code: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow> )}
             
              <TableRow className={classes.tableRowTitle}>
                <TableCell><b>Foto</b></TableCell>
                <TableCell>
                  {
                    this.state.icon &&
                    <img src={this.state.icon} alt="Foto" style={{width: 100}} />
                  }
                  <Uploader 
                    buttonText={this.state.icon ? 'Actualizar foto' : 'Subir foto'} 
                    photo 
                    dirName="Zones" 
                    urlUploaded={this.handleUrlUploaded}
                  />
                </TableCell>
              </TableRow>                     
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>
          {this.renderButton()}
        </div>
      </Paper>
    );
  }
}

ZonesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  zone: PropTypes.object.isRequired,
  allZones: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
  handleCreateZone: PropTypes.func.isRequired,
  handleUpdateZone: PropTypes.func.isRequired
};
  
const mapStateToProps = (state) => {
  const {zones, cities} = state;
  return { 
    allZones: zones.allZones,
    cities: cities.cities
  };
};
  
const mapDispatchToProps = {
  getCities
};
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ZonesDetails));
