import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import Uploader from '../../../common/uploader';

export class StagesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      name: '',
      icon: '',
      code: '',
    };
    this.renderButton = this.renderButton.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
  }

  componentWillMount() {
    const {isNew, stage} = this.props;
    if (isNew === false) {
      this.setState({
        stage,
        name: stage.name,
        icon: stage.icon ? stage.icon : '',
        active: stage.active,
        code: stage.code || '',
      });
    }
  }

  onAddButtonClick() {
    const {name, icon, code} = this.state;
    this.props.handleCreateStage({name, icon, code}).then(() => {
      this.handleGoBack();
    });
  }

  onEditButtonClick() {
    const {stage, active, icon, name, code} = this.state;
    const stageEdited = {
      id: stage.id,
      active,
      name,
      icon,
      code
    };
    this.props.handleUpdateStage(stageEdited).then(() => {
      this.handleGoBack();
    });
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  handleUrlUploaded(icon) {
    this.setState({icon: icon === false ? '' : icon});
  }

  isInvalid() {
    const {name} = this.state;
    if (name !== '') {
      return false;
    }
    return true;
  }

  renderButton() {
    const {isLoading, isNew, classes} = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} esta etapa?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} etapa`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }
    
  render() {
    const {classes, isLoading, isNew} = this.props;
    return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de ' : 'Nueva '} etapa
          </Typography>
        </Toolbar>
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              {
                !isNew &&
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Disponible</b></TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.active}
                          onChange={e => this.setState({active: e.target.checked})}
                          color="primary"
                        />
                      }
                      label={this.state.active === true ? 'Disponible' : 'No disponible'}
                    />
                  </TableCell>
                </TableRow>
              }
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Nombre</b></TableCell>
                <TableCell>
                  <TextField 
                    autoFocus
                    value={this.state.name}
                    onChange={e => this.setState({name: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow>  
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Código</b></TableCell>
                <TableCell>
                  <TextField 
                    autoFocus
                    value={this.state.code}
                    onChange={e => this.setState({code: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow>  
              <TableRow className={classes.tableRowTitle}>
                <TableCell><b>Imagen</b></TableCell>
                <TableCell>
                  {
                    this.state.icon &&
                    <img src={this.state.icon} alt="Foto" style={{width: 100}} />
                  }
                  <Uploader 
                    buttonText={this.state.icon ? 'Actualizar foto' : 'Subir foto'} 
                    photo 
                    dirName="Stages" 
                    urlUploaded={this.handleUrlUploaded}
                  />
                </TableCell>
              </TableRow>               
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>
          {this.renderButton()}
        </div>
      </Paper>
    );
  }
}

StagesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  stage: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleCreateStage: PropTypes.func.isRequired,
  handleUpdateStage: PropTypes.func.isRequired
};
  
export default (withStyles(styles)(StagesDetails));
