export const styles = (theme) => ({
  separator: { marginLeft: 20, marginRight: 20 },

  kpiCard: { display: 'flex', margin: 20, marginLeft: 0 },

  kpiCardItem: {
    flex: 1,
    marginLeft: 20,
    border: '0',
    display: 'flex',
    overflow: 'unset',
    position: 'relative',
    minWidth: '0',
    wordWrap: 'break-word',
    marginBottom: '30px',
    flexDirection: 'column',
    backgroundClip: 'initial',
  },

  cardItem: {
    padding: '1rem 1.5rem',
    width: 'unset',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    borderRadius: '.375rem',
    color: 'white',
    '& h5': {
      fontSize: '1.1rem',
      fontFamily: 'inherit',
      fontWeight: '600',
      lineHeight: '1.5',
      marginBottom: 0,
      textTransform: 'uppercase',
    },
    '& h2': {
      fontSize: '1.5rem',
      fontFamily: 'inherit',
      fontWeight: '600',
      lineHeight: '1.5',
      marginBottom: '.5rem',
    },
  },

  primary: { backgroundColor: theme.palette.primary.main },

  badgeKpi: {
    width: '3rem',
    height: '3rem',
    display: 'inline-flex',
    padding: '12px',
    textAlign: 'center',
    alignItems: 'center',
    opacity: '0.7',
    justifyContent: 'center',
    '& svg': {
      fontSize: 60,
    },
  },

  secondary: { backgroundColor: theme.palette.secondary.main },

  default: { backgroundColor: 'white', color: 'black' },

  badgeKpiPrimary: { color: theme.palette.primary.main, opacity: 1 },
});

export default styles;
