export const GET_STAGES_SUCCESS = 'GET_STAGES_SUCCESS';

export const GET_STAGES_SENDING = 'GET_STAGES_SENDING';

export const GET_STAGES_FAILED = 'GET_STAGES_FAILED';

export const FILTER_STAGES_SUCCESS = 'FILTER_STAGES_SUCCESS';

export const FILTER_STAGES_SENDING = 'FILTER_STAGES_SENDING';

export const FILTER_STAGES_FAILED = 'FILTER_STAGES_FAILED';

export const CREATE_STAGE_SUCCESS = 'CREATE_STAGE_SUCCESS';

export const CREATE_STAGE_SENDING = 'CREATE_STAGE_SENDING';

export const CREATE_STAGE_FAILED = 'CREATE_STAGE_FAILED';

export const UPDATE_STAGE_SUCCESS = 'UPDATE_STAGE_SUCCESS';

export const UPDATE_STAGE_SENDING = 'UPDATE_STAGE_SENDING';

export const UPDATE_STAGE_FAILED = 'UPDATE_STAGE_FAILED';
