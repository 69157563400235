import axios from 'axios';
import * as leadAssignmentActions from './types/leadAssignment';
import { axiosMaker, errorMessage } from '../utils/utils';

const company = JSON.parse(localStorage.getItem('company'));

export const getLeadsAssignment = () => async (dispatch) => {
  dispatch({
    type: leadAssignmentActions.GET_LEADS_ASSIGNMENT_SENDING,
    payload: {},
  });
  try {
    const queryString = company && company._id && `?companyId=${company._id}` || '';
    const response = await axios(
      axiosMaker(
        'GET',
        `companies/getLeadsAssignment${queryString}`,
        null,
        true,
      ),
    );
    return dispatch({
      type: leadAssignmentActions.GET_LEADS_ASSIGNMENT_SUCCESS,
      payload: { leadsAssignment: response.data },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadAssignmentActions.GET_LEADS_ASSIGNMENT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updateLeadAssignment = (id, assigmentConfiguration) => async (
  dispatch,
) => {
  dispatch({
    type: leadAssignmentActions.UPDATE_LEAD_ASSIGNMENT_SENDING,
    payload: {},
  });
  try {
    await axios(
      axiosMaker('PATCH', `companies/${id}`, { assigmentConfiguration }, true),
    );
    
    localStorage.setItem('company', JSON.stringify({...company, assigmentConfiguration}));

    return dispatch({
      type: leadAssignmentActions.UPDATE_LEAD_ASSIGNMENT_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadAssignmentActions.UPDATE_LEAD_ASSIGNMENT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};
