export const icons = [
  ['fab', 'accessible-icon'],
  ['fas', 'american-sign-language-interpreting'],
  ['fas', 'assistive-listening-systems'],
  ['fas', 'audio-description'],
  ['fas', 'blind'],
  ['fas', 'braille'],
  ['fas', 'closed-captioning'],
  ['fas', 'deaf'],
  ['fas', 'low-vision'],
  ['fas', 'phone-volume'],
  ['fas', 'question-circle'],
  ['fas', 'sign-language'],
  ['fas', 'tty'],
  ['fas', 'universal-access'],
  ['fas', 'wheelchair'],
  ['fas', 'angle-double-down'],
  ['fas', 'angle-double-left'],
  ['fas', 'angle-double-right'],
  ['fas', 'angle-double-up'],
  ['fas', 'angle-down'],
  ['fas', 'angle-left'],
  ['fas', 'angle-right'],
  ['fas', 'angle-up'],
  ['fas', 'arrow-alt-circle-down'],
  ['fas', 'arrow-alt-circle-left'],
  ['fas', 'arrow-alt-circle-right'],
  ['fas', 'arrow-alt-circle-up'],
  ['fas', 'arrow-circle-down'],
  ['fas', 'arrow-circle-left'],
  ['fas', 'arrow-circle-right'],
  ['fas', 'arrow-circle-up'],
  ['fas', 'arrow-down'],
  ['fas', 'arrow-left'],
  ['fas', 'arrow-right'],
  ['fas', 'arrow-up'],
  ['fas', 'arrows-alt'],
  ['fas', 'arrows-alt-h'],
  ['fas', 'arrows-alt-v'],
  ['fas', 'caret-down'],
  ['fas', 'caret-left'],
  ['fas', 'caret-right'],
  ['fas', 'caret-square-down'],
  ['fas', 'caret-square-left'],
  ['fas', 'caret-square-right'],
  ['fas', 'caret-square-up'],
  ['fas', 'caret-up'],
  ['fas', 'cart-arrow-down'],
  ['fas', 'chart-line'],
  ['fas', 'chevron-circle-down'],
  ['fas', 'chevron-circle-left'],
  ['fas', 'chevron-circle-right'],
  ['fas', 'chevron-circle-up'],
  ['fas', 'chevron-down'],
  ['fas', 'chevron-left'],
  ['fas', 'chevron-right'],
  ['fas', 'chevron-up'],
  ['fas', 'cloud-download-alt'],
  ['fas', 'cloud-upload-alt'],
  ['fas', 'download'],
  ['fas', 'exchange-alt'],
  ['fas', 'expand-arrows-alt'],
  ['fas', 'external-link-alt'],
  ['fas', 'external-link-square-alt'],
  ['fas', 'hand-point-down'],
  ['fas', 'hand-point-left'],
  ['fas', 'hand-point-right'],
  ['fas', 'hand-point-up'],
  ['fas', 'hand-pointer'],
  ['fas', 'history'],
  ['fas', 'level-down-alt'],
  ['fas', 'level-up-alt'],
  ['fas', 'location-arrow'],
  ['fas', 'long-arrow-alt-down'],
  ['fas', 'long-arrow-alt-left'],
  ['fas', 'long-arrow-alt-right'],
  ['fas', 'long-arrow-alt-up'],
  ['fas', 'mouse-pointer'],
  ['fas', 'play'],
  ['fas', 'random'],
  ['fas', 'recycle'],
  ['fas', 'redo'],
  ['fas', 'redo-alt'],
  ['fas', 'reply'],
  ['fas', 'reply-all'],
  ['fas', 'retweet'],
  ['fas', 'share'],
  ['fas', 'share-square'],
  ['fas', 'sign-in-alt'],
  ['fas', 'sign-out-alt'],
  ['fas', 'sort'],
  ['fas', 'sort-alpha-down'],
  ['fas', 'sort-alpha-up'],
  ['fas', 'sort-amount-down'],
  ['fas', 'sort-amount-up'],
  ['fas', 'sort-down'],
  ['fas', 'sort-numeric-down'],
  ['fas', 'sort-numeric-up'],
  ['fas', 'sort-up'],
  ['fas', 'sync'],
  ['fas', 'sync-alt'],
  ['fas', 'text-height'],
  ['fas', 'text-width'],
  ['fas', 'undo'],
  ['fas', 'undo-alt'],
  ['fas', 'upload'],
  ['fas', 'backward'],
  ['fas', 'circle'],
  ['fas', 'compress'],
  ['fas', 'eject'],
  ['fas', 'expand'],
  ['fas', 'fast-backward'],
  ['fas', 'fast-forward'],
  ['fas', 'file-audio'],
  ['fas', 'file-video'],
  ['fas', 'film'],
  ['fas', 'forward'],
  ['fas', 'headphones'],
  ['fas', 'microphone'],
  ['fas', 'microphone-slash'],
  ['fas', 'music'],
  ['fas', 'pause'],
  ['fas', 'pause-circle'],
  ['fas', 'play-circle'],
  ['fas', 'podcast'],
  ['fas', 'rss'],
  ['fas', 'rss-square'],
  ['fas', 'step-backward'],
  ['fas', 'step-forward'],
  ['fas', 'stop'],
  ['fas', 'stop-circle'],
  ['fas', 'video'],
  ['fas', 'volume-down'],
  ['fas', 'volume-off'],
  ['fas', 'volume-up'],
  ['fab', 'youtube'],
  ['fas', 'address-book'],
  ['fas', 'address-card'],
  ['fas', 'archive'],
  ['fas', 'balance-scale'],
  ['fas', 'birthday-cake'],
  ['fas', 'book'],
  ['fas', 'briefcase'],
  ['fas', 'building'],
  ['fas', 'bullhorn'],
  ['fas', 'bullseye'],
  ['fas', 'calculator'],
  ['fas', 'calendar'],
  ['fas', 'calendar-alt'],
  ['fas', 'certificate'],
  ['fas', 'chart-area'],
  ['fas', 'chart-bar'],
  ['fas', 'chart-pie'],
  ['fas', 'clipboard'],
  ['fas', 'coffee'],
  ['fas', 'columns'],
  ['fas', 'compass'],
  ['fas', 'copy'],
  ['fas', 'copyright'],
  ['fas', 'cut'],
  ['fas', 'edit'],
  ['fas', 'envelope'],
  ['fas', 'envelope-open'],
  ['fas', 'envelope-square'],
  ['fas', 'eraser'],
  ['fas', 'fax'],
  ['fas', 'file'],
  ['fas', 'file-alt'],
  ['fas', 'folder'],
  ['fas', 'folder-open'],
  ['fas', 'globe'],
  ['fas', 'industry'],
  ['fas', 'paperclip'],
  ['fas', 'paste'],
  ['fas', 'pen-square'],
  ['fas', 'pencil-alt'],
  ['fas', 'percent'],
  ['fas', 'phone'],
  ['fas', 'phone-square'],
  ['fas', 'registered'],
  ['fas', 'save'],
  ['fas', 'sitemap'],
  ['fas', 'sticky-note'],
  ['fas', 'suitcase'],
  ['fas', 'table'],
  ['fas', 'tag'],
  ['fas', 'tags'],
  ['fas', 'tasks'],
  ['fas', 'thumbtack'],
  ['fas', 'trademark'],
  ['fas', 'chess'],
  ['fas', 'chess-bishop'],
  ['fas', 'chess-board'],
  ['fas', 'chess-king'],
  ['fas', 'chess-knight'],
  ['fas', 'chess-pawn'],
  ['fas', 'chess-queen'],
  ['fas', 'chess-rook'],
  ['fas', 'square-full'],
  ['fas', 'barcode'],
  ['fas', 'bath'],
  ['fas', 'bug'],
  ['fas', 'code'],
  ['fas', 'code-branch'],
  ['fas', 'file-code'],
  ['fas', 'filter'],
  ['fas', 'fire-extinguisher'],
  ['fas', 'keyboard'],
  ['fas', 'microchip'],
  ['fas', 'qrcode'],
  ['fas', 'shield-alt'],
  ['fas', 'terminal'],
  ['fas', 'user-secret'],
  ['fas', 'window-close'],
  ['fas', 'window-maximize'],
  ['fas', 'window-minimize'],
  ['fas', 'window-restore'],
  ['fas', 'at'],
  ['fas', 'bell'],
  ['fas', 'bell-slash'],
  ['fab', 'bluetooth'],
  ['fab', 'bluetooth-b'],
  ['fas', 'comment'],
  ['fas', 'comment-alt'],
  ['fas', 'comments'],
  ['fas', 'inbox'],
  ['fas', 'language'],
  ['fas', 'mobile'],
  ['fas', 'mobile-alt'],
  ['fas', 'paper-plane'],
  ['fas', 'wifi'],
  ['fas', 'desktop'],
  ['fas', 'hdd'],
  ['fas', 'laptop'],
  ['fas', 'plug'],
  ['fas', 'power-off'],
  ['fas', 'print'],
  ['fas', 'server'],
  ['fas', 'tablet'],
  ['fas', 'tablet-alt'],
  ['fas', 'tv'],
  ['fab', 'bitcoin'],
  ['fab', 'btc'],
  ['fas', 'dollar-sign'],
  ['fas', 'euro-sign'],
  ['fab', 'gg'],
  ['fab', 'gg-circle'],
  ['fas', 'lira-sign'],
  ['fas', 'money-bill-alt'],
  ['fas', 'pound-sign'],
  ['fas', 'ruble-sign'],
  ['fas', 'rupee-sign'],
  ['fas', 'shekel-sign'],
  ['fas', 'won-sign'],
  ['fas', 'yen-sign'],
  ['fas', 'calendar-check'],
  ['fas', 'calendar-minus'],
  ['fas', 'calendar-plus'],
  ['fas', 'calendar-times'],
  ['fas', 'clock'],
  ['fas', 'hourglass'],
  ['fas', 'hourglass-end'],
  ['fas', 'hourglass-half'],
  ['fas', 'hourglass-start'],
  ['fas', 'stopwatch'],
  ['fas', 'adjust'],
  ['fas', 'clone'],
  ['fas', 'crop'],
  ['fas', 'crosshairs'],
  ['fas', 'eye'],
  ['fas', 'eye-dropper'],
  ['fas', 'eye-slash'],
  ['fas', 'object-group'],
  ['fas', 'object-ungroup'],
  ['fas', 'paint-brush'],
  ['fas', 'tint'],
  ['fas', 'align-center'],
  ['fas', 'align-justify'],
  ['fas', 'align-left'],
  ['fas', 'align-right'],
  ['fas', 'bold'],
  ['fas', 'font'],
  ['fas', 'heading'],
  ['fas', 'i-cursor'],
  ['fas', 'indent'],
  ['fas', 'italic'],
  ['fas', 'link'],
  ['fas', 'list'],
  ['fas', 'list-alt'],
  ['fas', 'list-ol'],
  ['fas', 'list-ul'],
  ['fas', 'outdent'],
  ['fas', 'paragraph'],
  ['fas', 'quote-left'],
  ['fas', 'quote-right'],
  ['fas', 'strikethrough'],
  ['fas', 'subscript'],
  ['fas', 'superscript'],
  ['fas', 'th'],
  ['fas', 'th-large'],
  ['fas', 'th-list'],
  ['fas', 'trash'],
  ['fas', 'trash-alt'],
  ['fas', 'underline'],
  ['fas', 'unlink'],
  ['fas', 'file-archive'],
  ['fas', 'file-excel'],
  ['fas', 'file-image'],
  ['fas', 'file-pdf'],
  ['fas', 'file-powerpoint'],
  ['fas', 'file-word'],
  ['fas', 'genderless'],
  ['fas', 'mars'],
  ['fas', 'mars-double'],
  ['fas', 'mars-stroke'],
  ['fas', 'mars-stroke-h'],
  ['fas', 'mars-stroke-v'],
  ['fas', 'mercury'],
  ['fas', 'neuter'],
  ['fas', 'transgender'],
  ['fas', 'transgender-alt'],
  ['fas', 'venus'],
  ['fas', 'venus-double'],
  ['fas', 'venus-mars'],
  ['fas', 'hand-lizard'],
  ['fas', 'hand-paper'],
  ['fas', 'hand-peace'],
  ['fas', 'hand-rock'],
  ['fas', 'hand-scissors'],
  ['fas', 'hand-spock'],
  ['fas', 'handshake'],
  ['fas', 'thumbs-down'],
  ['fas', 'thumbs-up'],
  ['fas', 'ambulance'],
  ['fas', 'h-square'],
  ['fas', 'heart'],
  ['fas', 'heartbeat'],
  ['fas', 'hospital'],
  ['fas', 'medkit'],
  ['fas', 'plus-square'],
  ['fas', 'stethoscope'],
  ['fas', 'user-md'],
  ['fas', 'bolt'],
  ['fas', 'camera'],
  ['fas', 'camera-retro'],
  ['fas', 'id-badge'],
  ['fas', 'id-card'],
  ['fas', 'image'],
  ['fas', 'images'],
  ['fas', 'sliders-h'],
  ['fas', 'ban'],
  ['fas', 'bars'],
  ['fas', 'beer'],
  ['fas', 'check'],
  ['fas', 'check-circle'],
  ['fas', 'check-square'],
  ['fas', 'cloud'],
  ['fas', 'cog'],
  ['fas', 'cogs'],
  ['fas', 'database'],
  ['fas', 'dot-circle'],
  ['fas', 'ellipsis-h'],
  ['fas', 'ellipsis-v'],
  ['fas', 'exclamation'],
  ['fas', 'exclamation-circle'],
  ['fas', 'exclamation-triangle'],
  ['fas', 'flag'],
  ['fas', 'flag-checkered'],
  ['fas', 'frown'],
  ['fas', 'hashtag'],
  ['fas', 'home'],
  ['fas', 'info'],
  ['fas', 'info-circle'],
  ['fas', 'magic'],
  ['fas', 'meh'],
  ['fas', 'minus'],
  ['fas', 'minus-circle'],
  ['fas', 'minus-square'],
  ['fas', 'plus'],
  ['fas', 'plus-circle'],
  ['fas', 'question'],
  ['fas', 'anchor'],
  ['fas', 'bed'],
  ['fas', 'bicycle'],
  ['fas', 'binoculars'],
  ['fas', 'bomb'],
  ['fas', 'bookmark'],
  ['fas', 'car'],
  ['fas', 'fighter-jet'],
  ['fas', 'fire'],
  ['fas', 'flask'],
  ['fas', 'gamepad'],
  ['fas', 'gavel'],
  ['fas', 'gift'],
  ['fas', 'glass-martini'],
  ['fas', 'graduation-cap'],
  ['fas', 'key'],
  ['fas', 'leaf'],
  ['fas', 'lemon'],
  ['fas', 'life-ring'],
  ['fas', 'lightbulb'],
  ['fas', 'magnet'],
  ['fas', 'male'],
  ['fas', 'map'],
  ['fas', 'map-marker'],
  ['fas', 'map-marker-alt'],
  ['fas', 'map-pin'],
  ['fas', 'map-signs'],
  ['fas', 'motorcycle'],
  ['fas', 'newspaper'],
  ['fas', 'paw'],
  ['fas', 'plane'],
  ['fas', 'road'],
  ['fas', 'rocket'],
  ['fas', 'search'],
  ['fas', 'search-minus'],
  ['fas', 'search-plus'],
  ['fas', 'ship'],
  ['fas', 'shopping-bag'],
  ['fas', 'shopping-basket'],
  ['fas', 'shopping-cart'],
  ['fas', 'shower'],
  ['fas', 'street-view'],
  ['fas', 'subway'],
  ['fas', 'taxi'],
  ['fas', 'bus'],
  ['fas', 'cube'],
  ['fas', 'cubes'],
  ['fas', 'futbol'],
  ['fas', 'gem'],
  ['fas', 'lock'],
  ['fas', 'lock-open'],
  ['fab', 'amazon-pay'],
  ['fab', 'apple-pay'],
  ['fas', 'cart-plus'],
  ['fab', 'cc-amazon-pay'],
  ['fab', 'cc-amex'],
  ['fab', 'cc-apple-pay'],
  ['fab', 'cc-diners-club'],
  ['fab', 'cc-discover'],
  ['fab', 'cc-jcb'],
  ['fab', 'cc-mastercard'],
  ['fab', 'cc-paypal'],
  ['fab', 'cc-stripe'],
  ['fab', 'cc-visa'],
  ['fas', 'credit-card'],
  ['fab', 'ethereum'],
  ['fab', 'google-wallet'],
  ['fab', 'paypal'],
  ['fas', 'star'],
  ['fab', 'stripe'],
  ['fab', 'stripe-s'],
  ['fas', 'trophy'],
  ['fas', 'square'],
  ['fas', 'asterisk'],
  ['fas', 'circle-notch'],
  ['fas', 'snowflake'],
  ['fas', 'spinner'],
  ['fas', 'sun'],
  ['fas', 'baseball-ball'],
  ['fas', 'basketball-ball'],
  ['fas', 'bowling-ball'],
  ['fas', 'football-ball'],
  ['fas', 'golf-ball'],
  ['fas', 'hockey-puck'],
  ['fas', 'quidditch'],
  ['fas', 'table-tennis'],
  ['fas', 'volleyball-ball'],
  ['fas', 'battery-empty'],
  ['fas', 'battery-full'],
  ['fas', 'battery-half'],
  ['fas', 'battery-quarter'],
  ['fas', 'battery-three-quarters'],
  ['fas', 'thermometer-empty'],
  ['fas', 'thermometer-full'],
  ['fas', 'thermometer-half'],
  ['fas', 'thermometer-quarter'],
  ['fas', 'thermometer-three-quarters'],
  ['fas', 'toggle-off'],
  ['fas', 'toggle-on'],
  ['fas', 'unlock'],
  ['fas', 'unlock-alt'],
  ['fas', 'child'],
  ['fas', 'female'],
  ['fas', 'smile'],
  ['fas', 'user'],
  ['fas', 'user-circle'],
  ['fas', 'user-plus'],
  ['fas', 'user-times'],
  ['fas', 'users'],
  ['fas', 'space-shuttle'],
  ['fas', 'train'],
  ['fas', 'truck'],
  ['fab', 'apple']
];

export default icons;
