/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as commentActions from './types/comments';

const company = JSON.parse(localStorage.getItem('company'));

export const getComments = () => async (dispatch) => {
  dispatch({
    type: commentActions.FILTER_COMMENTS_SENDING,
    payload: {}
  });

  const data = {company: company && company._id};
  try {
    const request = axiosMaker('POST', 'leads/getLeadsComments', data, true);
    console.log('request:', request)
    const response = await axios(request);
    return dispatch({
      type: commentActions.FILTER_COMMENTS_SUCCESS,
      payload: {
        comments: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: commentActions.FILTER_COMMENTS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export default getComments;
