import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  FormControl,
  InputAdornment,
  List,
  ListSubheader,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { styles } from './styles';
import { parseNumber } from '../../../utils/utils';
import { InlineDatePicker } from 'material-ui-pickers';

const PayForm = (props) => {
  const {
    formValues,
    classes,
    handleInputChange,
    setPayFormInvalid,
    currentVersion,
    changeUnitManualState,
  } = props;

  const {
    coveredAreaPrice,
    gardenOrTerraceAreaPrice,
    parkingSpacesPrice,
    warehouseValue,
    reserveValue,
    entryValue,
    interestPercentage,
    totalQuotes,
    builderFinancingPercentage,
    cashPrice,
    reserveDate,
    entryDate,
    discountValue,
  } = formValues;

  const [entryPercentage, setEntryPercentage] = useState('');
  const [reservePercentage, setReservePercentage] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [valueMode, setValueMode] = useState(true);

  const currentPrice =
    parseNumber(coveredAreaPrice) +
    parseNumber(gardenOrTerraceAreaPrice) +
    parseNumber(parkingSpacesPrice) +
    parseNumber(warehouseValue) -
    parseNumber(discountValue);

  useEffect(() => {
    if (valueMode)
      setReservePercentage(
        currentPrice > 0 && parseNumber(reserveValue) > 0
          ? ((parseNumber(reserveValue) * 100) / currentPrice).toFixed(2)
          : '',
      );
    else setValueMode(true);
  }, [reserveValue]);

  useEffect(() => {
    if (valueMode)
      setEntryPercentage(
        currentPrice > 0 && parseNumber(entryValue) > 0
          ? ((parseNumber(entryValue) * 100) / currentPrice).toFixed(2)
          : '',
      );
    else setValueMode(true);
  }, [entryValue]);

  useEffect(() => {
    if (valueMode) {
      const defaultPrice =
        parseNumber(coveredAreaPrice) +
        parseNumber(gardenOrTerraceAreaPrice) +
        parseNumber(parkingSpacesPrice) +
        parseNumber(warehouseValue);

      setDiscountPercentage(
        defaultPrice > 0 && parseNumber(discountValue) > 0
          ? ((parseNumber(discountValue) * 100) / defaultPrice).toFixed(2)
          : '',
      );
    } else setValueMode(true);
  }, [discountValue]);

  const handleConversion = ({ target }) => {
    setValueMode(false);
    const prefix = target.name.split('Percentage')[0];
    const otherTarget = {
      name: `${prefix}Value`,
      value:
        currentPrice > 0
          ? ((parseNumber(target.value) * currentPrice) / 100).toFixed(2)
          : '',
    };

    handleInputChange({ target: otherTarget });

    if (prefix === 'reserve') setReservePercentage(target.value);
    else if (prefix === 'entry') setEntryPercentage(target.value);
  };

  const handleConversionDiscount = ({ target }) => {
    const defaultPrice =
      parseNumber(coveredAreaPrice) +
      parseNumber(gardenOrTerraceAreaPrice) +
      parseNumber(parkingSpacesPrice) +
      parseNumber(warehouseValue);

    const isValue = target.name.includes('Value');

    setValueMode(false);
    const otherTarget = {
      name: 'discountValue',
      value: isValue
        ? target.value
        : defaultPrice > 0
        ? ((parseNumber(target.value) * defaultPrice) / 100).toFixed(2)
        : '',
    };

    const getPercentage = (itemValue) =>
      parseNumber(
        itemValue === 'entryValue' ? entryPercentage : reservePercentage,
      );

    const getNewValue = (itemName) => {
      const tempPrice = defaultPrice - parseNumber(otherTarget.value);
      const percentage = getPercentage(itemName);
      const response = ((tempPrice * percentage) / 100).toFixed(2);
      return response;
    };

    changeUnitManualState({
      [otherTarget.name]: otherTarget.value,
      content: [],
      entryValue: getNewValue('entryValue'),
      reserveValue: getNewValue('reserveValue'),
    });

    setDiscountPercentage(
      isValue
        ? ((parseNumber(target.value) * 100) / defaultPrice).toFixed(2)
        : target.value,
    );
  };

  const invalidValue =
    currentPrice -
      (currentPrice * parseNumber(builderFinancingPercentage)) / 100 -
      parseNumber(entryValue) <
    0;

  const invalidReserve = parseNumber(reserveValue) > parseNumber(entryValue);

  setPayFormInvalid(invalidValue || invalidReserve);

  return (
    <Paper className={classes.root}>
      <Typography
        variant="h5"
        color="primary"
        className={classes.headerProformas}
      >
        Formas de pago
      </Typography>
      <Grid container spacing={24}>
        <Grid item className={classes.gridItem} xs={12} md={6}>
          <List
            subheader={
              <ListSubheader className={classes.listHeader} component="div">
                Reserva
              </ListSubheader>
            }
          >
            <Grid container spacing={24}>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  disabled={!!currentVersion}
                  type="number"
                  placeholder="0"
                  label="Valor"
                  name="reserveValue"
                  value={reserveValue || ''}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                  error={invalidReserve}
                  helperText={
                    invalidReserve
                      ? 'El valor de reserva debe estar contenido dentro del valor de entrada'
                      : ''
                  }
                />
              </Grid>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  disabled={!!currentVersion}
                  type="number"
                  placeholder="0"
                  label="Porcentaje"
                  name="reservePercentage"
                  value={reservePercentage || ''}
                  onChange={handleConversion}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <FormControl style={{ width: '100%', paddingTop: 8 }}>
                  <InlineDatePicker
                    disabled={!!currentVersion}
                    label="Fecha"
                    variant="outlined"
                    format="YYYY-MM-DD"
                    value={reserveDate}
                    onChange={(selected) =>
                      handleInputChange({
                        target: {
                          name: 'reserveDate',
                          value: selected,
                        },
                      })
                    }
                    className={classes.pickerContainer}
                    InputProps={{ className: classes.inputForm }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </List>
        </Grid>
        <Grid item className={classes.gridItem} xs={12} md={6}>
          <List
            subheader={
              <ListSubheader className={classes.listHeader} component="div">
                Entrada
              </ListSubheader>
            }
          >
            <Grid container spacing={24}>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  disabled={!!currentVersion}
                  type="number"
                  placeholder="0"
                  label="Valor"
                  name="entryValue"
                  value={entryValue || ''}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                  error={invalidValue}
                  helperText={invalidValue ? 'El valor no es válido' : ''}
                />
              </Grid>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  disabled={!!currentVersion}
                  type="number"
                  placeholder="0"
                  label="Porcentaje"
                  name="entryPercentage"
                  value={entryPercentage || ''}
                  onChange={handleConversion}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <FormControl style={{ width: '100%', paddingTop: 8 }}>
                  <InlineDatePicker
                    disabled={!!currentVersion}
                    label="Fecha"
                    variant="outlined"
                    format="YYYY-MM-DD"
                    value={entryDate}
                    onChange={(selected) =>
                      handleInputChange({
                        target: {
                          name: 'entryDate',
                          value: selected,
                        },
                      })
                    }
                    className={classes.pickerContainer}
                    InputProps={{ className: classes.inputForm }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </List>
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <Divider style={{ margin: '32px 0 16px' }} />
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <List
            subheader={
              <ListSubheader className={classes.listHeader} component="div">
                Financiamiento directo de la constructora
              </ListSubheader>
            }
          >
            <Grid container spacing={24}>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  type="number"
                  placeholder="0"
                  label="Valor"
                  name="builderFinancingValue"
                  value={(
                    (currentPrice * parseNumber(builderFinancingPercentage)) /
                    100
                  ).toFixed(2)}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  type="number"
                  placeholder="0"
                  label="Porcentaje"
                  name="builderFinancingPercentage"
                  value={builderFinancingPercentage || ''}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                  disabled={!!currentVersion}
                />
              </Grid>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  type="number"
                  placeholder="0"
                  label="Tasa de interés"
                  name="interestPercentage"
                  value={interestPercentage || ''}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  disabled={!!currentVersion}
                  type="number"
                  placeholder="0"
                  label="Número de cuotas"
                  name="totalQuotes"
                  value={totalQuotes || ''}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{ className: classes.inputForm }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </List>
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <Divider style={{ margin: '32px 0 16px' }} />
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <List
            subheader={
              <ListSubheader className={classes.listHeader} component="div">
                Descuento
              </ListSubheader>
            }
          >
            <Grid container spacing={24}>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  disabled={!!currentVersion}
                  type="number"
                  placeholder="0"
                  label="Valor"
                  name="discountValue"
                  value={discountValue || ''}
                  onChange={handleConversionDiscount}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                  error={invalidReserve}
                  helperText={
                    invalidReserve
                      ? 'El valor de reserva debe estar contenido dentro del valor de entrada'
                      : ''
                  }
                />
              </Grid>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  disabled={!!currentVersion}
                  type="number"
                  placeholder="0"
                  label="Porcentaje"
                  name="discountPercentage"
                  value={discountPercentage || ''}
                  onChange={handleConversionDiscount}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </List>
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <List
            subheader={
              <ListSubheader className={classes.listHeader} component="div">
                Saldo de Crédito
              </ListSubheader>
            }
          >
            <Grid container spacing={24}>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  type="number"
                  placeholder="0"
                  label="Valor"
                  value={(
                    currentPrice -
                    (currentPrice * parseNumber(builderFinancingPercentage)) /
                      100 -
                    parseNumber(entryValue)
                  ).toFixed(2)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  type="number"
                  placeholder="0"
                  label="Porcentaje"
                  value={(
                    100 -
                    parseNumber(builderFinancingPercentage) -
                    parseNumber(entryPercentage)
                  ).toFixed(2)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
            </Grid>
          </List>
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <Divider style={{ margin: '32px 0 16px' }} />
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <List
            subheader={
              <ListSubheader className={classes.listHeader} component="div">
                Precio al Contado
              </ListSubheader>
            }
          >
            <Grid container spacing={24}>
              <Grid item className={classes.gridItem} sm={6} xs={12}>
                <TextField
                  type="number"
                  placeholder="0"
                  label="Valor"
                  name="cashPrice"
                  value={cashPrice || ''}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.inputForm,
                    endAdornment: (
                      <InputAdornment position="end">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.labelForm,
                    shrink: true,
                  }}
                  disabled={!!currentVersion}
                />
              </Grid>
            </Grid>
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

PayForm.propTypes = {
  classes: PropTypes.object.isRequired,
  lead: PropTypes.object,
  formValues: PropTypes.object,
  handleInputChange: PropTypes.func,
};

export default withStyles(styles)(PayForm);
