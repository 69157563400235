export const styles = theme => ({
  root: {
    display: 'flex'
  },
  br: {
    width: '100%',
    height: 25
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    height: '100%',
    backgroundColor: '#F5F5F6',
    width: '100%'
  },
  paperRoot: {
    width: '95%',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 3
  },
  submitContainer: {
    display: 'table',
    margin: '0 auto'
  },
  tableRowTitle: {
    width: 250
  },
  textInput: {
    marginBottom: theme.spacing.unit * 2
  }
});

export default styles;
