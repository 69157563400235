import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Slide, Button } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import Place from '@material-ui/icons/Place';
import { useDispatch } from 'react-redux';
import { styles } from './styles';
import MouseOverPopover from './MouseOverPopover';
import { changeFilters, handleChangeProject } from '../../../actions/smartSupplyFilters';

const MarkerComponent = (props) => {
  const { content, project, active, classes, onSelect } = props;
  return (
    <div
      className={active ? classes.activePlace : ''}
      onClick={(e) => {
        e.preventDefault();
        onSelect(project);
      }}
    >
      {content}
    </div>
  );
};
MarkerComponent.propTypes = {
  content: PropTypes.object.isRequired,
};

const SimpleMap = (props) => {
  const { classes, points, setShowProjects } = props;

  const dispatch = useDispatch();

  const [selectedPoint, setselectedPoint] = React.useState({
    lat: -0.22985,
    lng: -78.5249481,
  });
  const [selectedProjects, setSelectedProjects] = React.useState([]);
  const [filtered, setFiltered] = React.useState(false);
  const [localPoints, setLocalPoints] = React.useState(points);

  const selectPosition = (e) => {
    setselectedPoint({ lat: e.lat, lng: e.lng });
  };

  useEffect(() => {
    setLocalPoints(points);
    setSelectedProjects([]);
  }, [points]);

  const renderMarker = (title) => {
    return <MouseOverPopover title={title} />;
  };

  const filtermarkers = async () => {
    // const newLocalPoints = localPoints.filter(
    //   (localItem) => selectedProjects.indexOf(localItem.project) >= 0,
    // );
    // setLocalPoints(newLocalPoints);
    dispatch(handleChangeProject({ target: { value: selectedProjects } }));
    dispatch(changeFilters());
  };

  const renderButton = () => {
    return (
      selectedProjects.length > 0 && (
        <div className={classes.buttonContainer}>
          <Button variant="contained" color={'primary'} onClick={filtermarkers}>
            Filtrar
          </Button>
        </div>
      )
    );
  };

  const onSelectProject = (projectName) => {
    const tempProjects = [...selectedProjects];
    const indexItem = selectedProjects.indexOf(projectName);
    if (indexItem >= 0) {
      tempProjects.splice(indexItem, 1);
      setSelectedProjects(tempProjects);
    } else {
      const tempProjects = [...selectedProjects, projectName];
      setSelectedProjects(tempProjects);
    }
  };

  const renderMarkers = () => {
    let newPoints = localPoints.map((point) => {
      if (selectedProjects.indexOf(point.project) >= 0) {
        point.activeItem = true;
      } else {
        point.activeItem = false;
      }
      return point;
    });

    return newPoints.map((pointItem, index) => {
      const { builder, coordinates, project, activeItem } = pointItem;
      return (
        <MarkerComponent
          key={`market-${index}`}
          project={project}
          active={activeItem}
          lat={coordinates[0]}
          lng={coordinates[1]}
          classes={classes}
          onSelect={setShowProjects ? onSelectProject : () => {}}
          content={renderMarker(
            project ? `${builder}-${project}` : `Proyecto ${index + 1}`,
            project,
          )}
        />
      );
    });
  };

  return (
    <div style={{ height: '500px' }}>
      <div style={{ height: '500px' }}>
        <div className={classes.mapContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCRlpd9z_60R4K_R4QVBENJ6D3jZbLfU94' }}
            defaultCenter={selectedPoint}
            defaultZoom={10}
            options={{ fullscreenControl: false }}
            onClick={selectPosition}
          >
            {renderMarkers()}
          </GoogleMapReact>
          {renderButton()}
        </div>
      </div>
    </div>
  );
};
SimpleMap.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleMap);
