export const styles = (theme) => ({
  submitContainer: {
    display: 'table',
    margin: '0 20px',
  },
  companyPadding: {
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    '&.listPadding': {
      paddingLeft: theme.spacing.unit * 8,
      paddingRight: theme.spacing.unit * 8,
    },
  },
  companyText: {
    '& span': {
      fontWeight: 'bold',
      color: 'grey'
    },
  },
});

export default styles;
