import * as calendarActions from '../actions/types/calendar';

const initialState = {
  calendar: {},
  event: {},
  message: '',
  dataLength: 0,
  sending: false,
  eventsByLead: [],
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  filters: {},
  searchText: '',
  eventCancel: {
    message: '',
    error: '',
  },
  report: [],
};

export default (state = initialState, action = {}) => {
  const {
    message,
    error,
    calendar = {},
    dataLength,
    page,
    rowsPerPage,
    order,
    orderBy,
    searchText,
    filters,
    eventCancel = {},
    report,
    eventsByLead
  } = action.payload || {};
  switch (action.type) {
    case calendarActions.GET_CALENDAR_SENDING:
      return { ...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength };
    case calendarActions.GET_CALENDAR_SUCCESS:
      return {
        ...state,
        sending: false,
        filters,
        calendar,
        error: null,
        message: 'Eventos obtenidos satisfactoriamente',
        dataLength,
      };
    case calendarActions.GET_CALENDAR_FAILED:
      return { ...state, sending: false, calendar: {}, error, message, dataLength };
    case calendarActions.CREATE_EVENT_SENDING:
      return { ...state, sending: true, message: '', filters };
    case calendarActions.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        message: 'Evento creado satisfactoriamente',
      };
    case calendarActions.CREATE_EVENT_FAILED:
      return { ...state, sending: false, error, message };
    case calendarActions.CANCEL_EVENT_SUCCESS:
      return {
        ...state,
        eventCancel: { error: null, message: 'Evento cancelado satisfactoriamente' },
      };
    case calendarActions.CANCEL_EVENT_SENDING:
      return { ...state, eventCancel: { error: null, message: '' } };
    case calendarActions.CANCEL_EVENT_FAILED:
      return { ...state, eventCancel: { error: eventCancel.error, message: eventCancel.message } };
    case calendarActions.GET_EVENTS_REPORT_SENDING:
      return { ...state, report: [] };
    case calendarActions.GET_EVENTS_REPORT_SUCCESS:
      return {
        ...state,
        sending: false,
        report,
        error: null,
        message: 'Reporte obtenido satisfactoriamente',
      };
    case calendarActions.GET_EVENTS_REPORT_FAILED:
      return { ...state, sending: false, report: [], error, message };
    case calendarActions.GET_CALENDAR_EVENTS_SENDING:
      return { ...state,eventsByLead  };
    case calendarActions.GET_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        message: 'Eventos obtenidos satisfactoriamente',
        eventsByLead,
      };
    case calendarActions.GET_CALENDAR_EVENTS_FAILED:
      return { ...state};
    case calendarActions.CLEAN_CALENDAR_EVENTS_FAILED:
       return { ...state, eventsByLead:[]};
    default:
      return state;
  }
};
