export const GET_STANDS_SUCCESS = 'GET_STANDS_SUCCESS';

export const GET_STANDS_SENDING = 'GET_STANDS_SENDING';

export const GET_STANDS_FAILED = 'GET_STANDS_FAILED';

export const FILTER_STANDS_SUCCESS = 'FILTER_STANDS_SUCCESS';

export const FILTER_STANDS_SENDING = 'FILTER_STANDS_SENDING';

export const FILTER_STANDS_FAILED = 'FILTER_STANDS_FAILED';

export const CREATE_STAND_SUCCESS = 'CREATE_STAND_SUCCESS';

export const CREATE_STAND_SENDING = 'CREATE_STAND_SENDING';

export const CREATE_STAND_FAILED = 'CREATE_STAND_FAILED';

export const UPDATE_STAND_SUCCESS = 'UPDATE_STAND_SUCCESS';

export const UPDATE_STAND_SENDING = 'UPDATE_STAND_SENDING';

export const UPDATE_STAND_FAILED = 'UPDATE_STAND_FAILED';
