import * as calendarActions from '../actions/types/calendar';

const initialState = {
  statistics: { 'sample-lead-Id': [] },
  sending: false,
  message: '',
  error: null,
};

export default (state = initialState, action = {}) => {
  const {
    sending = false,
    message = '',
    error = null,
    leadId = 'sample-lead-Id',
    statistics = [],
  } = action.payload || {};

  switch (action.type) {
    case calendarActions.GET_EVENTS_STATISTICS_FAILED:
      return { ...state, sending, statistics: { [leadId]: statistics }, error, message };
    case calendarActions.GET_EVENTS_STATISTICS_SUCCESS:
      return { ...state, sending, statistics: { [leadId]: statistics }, message, error };
    case calendarActions.GET_EVENTS_STATISTICS_SENDING:
      return { ...state, sending, message, error, statistics: { [leadId]: statistics } };
    default:
      return { ...state };
  }
};
