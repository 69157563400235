import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {dateFormatExtended} from '../../../../utils/utils';

export class Observations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderObservations = this.renderObservations.bind(this);
  }

  renderObservations() {
    const {lead} = this.props;
    if (lead.observations) {
      if (lead.observations.length > 0) {
        const observations = lead.observations.map((observation, index) => {
          let userName = observation.userId;
          // Esenario cuando creas una observación
          if (typeof userName === 'string') {
            userName = localStorage.getItem('userName');
          } else if (userName && typeof userName === 'object') {
            userName = userName.name;
          }
          return (
            <TableRow key={index}>
             
              <TableCell>
                {observation.origin || ''}
              </TableCell>
              <TableCell>
                {moment(observation.date_created).format(dateFormatExtended)}
              </TableCell>
              <TableCell>
                {userName}
              </TableCell>
              <TableCell>
                {observation.type ? observation.type.charAt(0).toUpperCase() + observation.type.slice(1) : 'Ninguno'}
              </TableCell>
              <TableCell>
                {observation.description}
              </TableCell>
            </TableRow>
          );
        });
        return (
          <div style={{width: 'auto', overflowX: 'scroll'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Origen
                  </TableCell>
                  <TableCell>
                    Fecha
                  </TableCell>
                  <TableCell>
                    Usuario
                  </TableCell>
                  <TableCell>
                    Tipo de interacción
                  </TableCell>
                  <TableCell>
                    Observación
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {observations}
              </TableBody>
            </Table>
          </div>
        );
      }
    }
    return null;
  }

  render() {
    const {isLoading, lead} = this.props;
    if (lead === null) {
      return (
        <div style={{zIndex: 100, position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)'}}>
          <CircularProgress
            style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}}
          />
        </div>
      );
    }
    return isLoading === true ?
      <div style={{zIndex: 100, position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)'}}>
        <CircularProgress
          style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}}
        />
      </div>
      :
      this.renderObservations();
  }
}
Observations.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  lead: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const {leads} = state;
  return {
    lead: leads.lead,
    isLoading: leads.sendingLead
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Observations);
