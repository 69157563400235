/* eslint-disable no-param-reassign */
import axios from 'axios';
import { axiosMaker, errorMessage } from '../utils/utils';
import * as newsActions from './types/news';
import store from '../store';

export const filterNews = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().news.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().news.order || orderBy !== store.getState().news.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: newsActions.FILTER_NEWS_SENDING,
    payload: {
      news: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
    },
  });
  try {
    let filter = {};
    if (
      page !== null &&
      rowsPerPage !== null &&
      order !== null &&
      orderBy !== null &&
      searchText !== null
    ) {
      filter = {
        filters: {
          title: searchText,
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order,
        },
      };
    }
    const response = await axios(axiosMaker('POST', 'news/filter', filter, true));
    return dispatch({
      type: newsActions.FILTER_NEWS_SUCCESS,
      payload: {
        news: response.data.results,
        dataLength: response.data.total,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: newsActions.FILTER_NEWS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getNews = () => async (dispatch) => {
  dispatch({
    type: newsActions.GET_NEWS_SENDING,
    payload: {
      news: [],
      dataLength: 0,
    },
  });
  try {
    const data = {
      sort: {
        title: 'asc',
      },
      filters: {},
    };
    const response = await axios(axiosMaker('POST', 'news/filter', data, true));
    return dispatch({
      type: newsActions.GET_NEWS_SUCCESS,
      payload: {
        news: response.data.results,
        dataLength: response.data.results.length,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: newsActions.GET_NEWS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const createNews = (data) => async (dispatch) => {
  dispatch({
    type: newsActions.CREATE_NEWS_ITEM_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('POST', 'news', data, true));
    return dispatch({
      type: newsActions.CREATE_NEWS_ITEM_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: newsActions.CREATE_NEWS_ITEM_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updateNews = (id, newsItem) => async (dispatch) => {
  dispatch({
    type: newsActions.UPDATE_NEWS_ITEM_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PATCH', `news/${id}`, newsItem, true));
    return dispatch({
      type: newsActions.UPDATE_NEWS_ITEM_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: newsActions.UPDATE_NEWS_ITEM_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getNewItem = async (id) => {
  try {
    const response = await axios(axiosMaker('GET', `news/${id}`, null, true));
    return response.data.results;
  } catch (e) {
    const error = e.response;
    console.log(e.response);
    return { content: '' };
  }
};

export const getNewsToShow = (limit = 10) => async (dispatch) => {
  try {
    const response = await axios(axiosMaker('POST', `news/toShow`, { limit }, true));
    return dispatch({
      type: newsActions.GET_NEWS_TO_SHOW,
      payload: { newsToShow: response.data.results.results },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: newsActions.FAIL_NEWS_TO_SHOW,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};
