import { orange500, white } from 'material-ui/styles/colors';

export const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  root2: {
    height: 'auto',
    maxHeight: '800px',
    overflow: 'auto',
  },
  descriptionContainer: {
    color: 'white',
    position: 'absolute',
    bottom: '0',
    right: '0',
    // paddingRight: '20px',
    textAlign: 'end',
    zIndex: 1,
    marginBottom: '20px',
    fontSize: '14px',
    lineHeight: '25px',
  },
  myEvent: {
    position: 'absolute',
    top: '0',
    right: '0',
    borderLeft: '40px solid transparent',
  },
  cardImageContainer: {
    backgroundSize: 'cover',
    height: '350px',
    backgroundColor: '#f5f6fa',
    // [theme.breakpoints.up('lg')]: {
    //   height: '420px',
    // },
  },
  cardGradient: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    backgroundImage:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.01) 0%, rgba(0, 0, 0, 0.7) 100%)',
  },
  containerList: {
    justifyContent: 'center',
  },
  subtitle: {
    // color: theme.palette.secondary.main,
    fontSize: '16px',
    letterSpacing: '0.3px',
  },
  cardTitle: {
    fontSize: '18px',
    letterSpacing: '0.3px',
  },
  loaderContainer: {
    // position: 'absolute',
    // left: '50%',
  },
  itemTask: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginRight: '50px',
  },
  itemTaskContent: {
    flex: '1',
    // paddingLeft: '20px',
  },
  emptyList: {
    background: '#ececec',
    padding: '20px',
  },
  taskTitle: {
    fontSize: '1rem',
    color: 'black',
    fontWeight: '600',
    marginRight: '50px',
  },
  date: {
    fontSize: '0.7rem',
    color: '#a29d9d',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  taskSubtitle: {
    fontSize: '0.8rem',
    color: theme.palette.primary.main,
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  badgeItem: {
    display: 'inline-flex',
    fontSize: '0.8rem',
    background: theme.palette.secondary.main,
    color: 'white',
    padding: '0 10px',
    borderRadius: '5px',
    marginLeft: '10px',
    float: 'right',
    // marginRight: '50px',
  },
  temperature: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20%',
    '& .potential': {
      width: 15,
      height: 15,
      borderRadius: '25%',
      background: theme.palette.primary.main,
    },
    '& .cold': {
      width: 15,
      height: 15,
      borderRadius: '25%',
      border: '1px solid lightgray',
    },
    '& .hot': {
      width: 15,
      height: 15,
      borderRadius: '25%',
      background: orange500,
    },
  },
  creditsContainer: {
    display: 'flex',
    position: 'absolute',
    top: '136px',
    right: '18px',
    backgroundColor: 'white',
    padding: '12px 35px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
  },
  creditsDescription: {
    display: 'inline-flex',
    color: '#3590F3',
    fontSize: '18px',
    '& span': {
      marginLeft: '5px',
    },
  },
});

export default styles;
