const displayWidth = window.innerWidth;

let size = 0;
let marginRight = 0;

if (displayWidth > 450) {
  size = 160;
  marginRight = 20;
} else if (displayWidth > 340) {
  size = 140;
  marginRight = 18;
} else {
  size = 120;
  marginRight = 16;
}

export const styles = theme => ({
  image: {
    width: '100%',
    height: size,
    cursor: 'pointer'
  },
  cell: {
    marginRight,
    width: size,
    display: 'inline-block'
  },
  gridListTile: {
    listStyleType: 'none'
  },
  iconContainer: {
    background: '#fff',
    borderRadius: 5,
    marginRight: 3,
    marginTop: 2
  },
  gridTile: {
    background: 'transparent',
    cursor: 'pointer'
  },
  icon: {
    color: theme.palette.secondary.main,
    padding: 0
  },
  titleBar: {
    background:
    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  transparentButton: {
    background: '#fff',
    border: 0
  }
});

export default styles;
