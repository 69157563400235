import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import CheckIcon from '@material-ui/icons/Check';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import CalendarIcon from '@material-ui/icons/Event';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BackupIcon from '@material-ui/icons/Backup';
import Assignment from '@material-ui/icons/Assignment';
import AddCommentIcon from '@material-ui/icons/AddComment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SaveIcon from '@material-ui/icons/Save';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import Place from '@material-ui/icons/Place';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Button from '@material-ui/core/Button';
import { green500, red500, grey700 } from 'material-ui/styles/colors';
import { styles } from './styles';
import Autocomplete from './autocomplete';
import { isNumber, dateFormat, SUPER_ADMIN_ROL_ID, ASESOR_ID } from '../../../../../utils/utils';
import imagePlaceholder from '../../../../../assets/error.png';
import CONSTANTS from '../../../../../utils/constants';
import Integrations from '../../../../integrations';
import DropDown from '../../../dropdown';

const userIdsForMockup = [
  '5d0953b31da52bc72005c3bc',
  '5d55a3ace553cd0957a98c15',
  '5cfb23985872fe84bb4545a8',
  '5c5c90decae2e42e1cd668dc',
  '5d07daca47a9f79d6beefa29',
];

export class EnhancedTableCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderCategory = this.renderCategory.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.handleChangeCellNumber = this.handleChangeCellNumber.bind(this);
    this.handleAddToLead = this.handleAddToLead.bind(this);
    this.renderAutocompletable = this.renderAutocompletable.bind(this);
    this.renderToggle = this.renderToggle.bind(this);
    this.renderObjectArray = this.renderObjectArray.bind(this);
    this.renderObject = this.renderObject.bind(this);
    this.renderDate = this.renderDate.bind(this);
    this.renderUser = this.renderUser.bind(this);
    this.renderImage = this.renderImage.bind(this);
    this.renderAddToLead = this.renderAddToLead.bind(this);
  }

  handleChangeCellNumber(e) {
    const number = e.target.value;
    if (isNumber(number)) {
      this.props.handleChangeCell(number);
    } else if (number.length > 1) {
      this.props.handleChangeCell(number.substring(0, number.length - 1));
    } else {
      this.props.handleChangeCell('');
    }
  }

  handleAddToLead(cellData) {
    const { allowAddToLead } = this.props;
    if (allowAddToLead) {
      this.props.onAddToLead(cellData);
    }
  }

  renderAddToLead(cellData) {
    const { classes } = this.props;
    if (cellData.active === true && cellData.availability) {
      if (cellData.availability.status === 'available') {
        return (
          <Tooltip title="Agregar a lead">
            <Assignment
              className={classes.assignment}
              onClick={() => this.handleAddToLead(cellData)}
            />
          </Tooltip>
        );
      }
    }
    return '-';
  }

  renderToggle(cellData) {
    return (
      <Switch
        checked={cellData}
        onChange={(e) => this.props.handleChangeCell(e.target.checked)}
        color="primary"
      />
    );
  }

  renderCategory = (category) => {
    switch (category) {
      case 'project':
        return 'Proyecto';

      case 'unit':
        return 'Unidad';

      case 'personal':
        return 'Personal';

      case 'wizard':
        return 'Asistente';

      default:
        return 'Proyecto';
    }
  };

  renderAvailability() {
    const { cellData } = this.props;
    if (cellData) {
      if (cellData.status === 'available') {
        return <CheckIcon style={{ color: green500 }} />;
      }
      return <CloseIcon style={{ color: red500 }} />;
    }
    return null;
  }

  renderAutocompletable() {
    const { suggestions, cellData, isNoClearable } = this.props;
    return (
      <Autocomplete
        suggestions={suggestions}
        value={cellData}
        handleChangeCell={this.props.handleChangeCell}
        multiple={false}
        isNoClearable={isNoClearable}
      />
    );
  }

  renderImage = (cellData) => {
    if (cellData) {
      return (
        <a href={cellData} target="_blank" rel="noreferrer noopener">
          <img src={cellData} style={{ width: 50 }} alt="imagen" />
        </a>
      );
    }
    return <img src={imagePlaceholder} style={{ width: 50 }} alt="imagen" />;
  };

  renderStageType = (cellData) => {
    if (cellData) {
      if (cellData === 'unit') {
        return 'Etapa de Unidad';
      }
      if (cellData === 'lead') {
        return 'Etapa de Lead';
      }
    }
    return '-';
  };

  renderObject = (cellData) => {
    if (cellData) {
      if (cellData.name) {
        return cellData.name;
      }
    }
    return '-';
  };

  renderObjectArray = (cellData) => {
    if (cellData) {
      const result = cellData.map((data) => {
        if (data.name) {
          return data.name;
        }
        return '-';
      });
      return result.join(', ');
    }
    return '-';
  };

  renderUser = (cellData) => {
    if (cellData) {
      if (cellData.name && cellData.lastname) {
        return `${cellData.name} ${cellData.lastname}`;
      } else if (cellData.name) {
        return cellData.name;
      } else if (cellData.lastname) {
        return cellData.lastname;
      }
    }
    return '-';
  };

  renderZones = (cellData) => {
    if (cellData) {
      if (cellData.answerValues) {
        return cellData.answerValues.join(', ');
      }
    }
    return '-';
  };

  renderDate = (cellData) => {
    if (cellData) {
      return moment(cellData).format(dateFormat);
    }
    return '-';
  };

  renderDateTime = (cellData) => {
    if (cellData) {
      return moment(cellData).format(`${dateFormat} HH:mm`);
    }
    return '-';
  };

  renderDateLink = (cellData) => {
    const style = {
      fontSize: '0.8125rem',
      fontWeight: '400',
    };

    if (cellData) {
      return (
        <Tooltip title="Ver Proformas">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              this.props.onProformaAdmin();
            }}
            style={style}
            color="secondary"
          >
            {moment(cellData).format(dateFormat)}
          </Button>
        </Tooltip>
      );
    }
    return '-';
  };

  renderCell() {
    const { type, cellData, classes, row } = this.props;
    const profileId = localStorage.getItem('profileId');
    const userIdStorage = localStorage.getItem('user');
    const profile = JSON.parse(localStorage.getItem('profile'));
    let renderEdit = true;
    if (
      (window.location.pathname == '/' || window.location.pathname == '/leads') &&
      profileId == ASESOR_ID &&
      row.brokerId !== userIdStorage
    ) {
      renderEdit = false;
    }

    const userStorage = localStorage.getItem('user');
    let show = false;
    let disabledProfiling = false;
    let formSalesProfileValidation = false;
    if (row.availability && row.availability && row.availability.status === 'available') {
      if (profile) {
        if (profile.permissions.formSalesProfile) {
          formSalesProfileValidation = true;
        }
      }
    }
    const { isProfiled } = row;
    if (SUPER_ADMIN_ROL_ID == profileId || !row.isBase) {
      show = true;
    }
    if (CONSTANTS.ADMIN_ROL_ID != profileId && row.isProfiled) {
      disabledProfiling = true;
    }
    const partner = localStorage.getItem('strategicPartner');
    switch (type) {
      case 'text':
        return cellData || '-';
      case 'company':
        return (cellData && cellData.name) || '-';
      case 'title':
        return cellData.charAt(0).toUpperCase() + cellData.slice(1) || '-';
      case 'array':
        if (cellData) {
          if (cellData.length === 0) {
            return ' - ';
          }
          // eslint-disable-next-line no-case-declarations
          const outputText = cellData
            ? cellData.reduce((a, b) => {
                if (a.indexOf(b) < 0) {
                  a.push(b);
                }
                return a;
              }, [])
            : null;
          if (outputText) {
            return outputText.join(', ');
          }
        }
        return ' - ';
      case 'objectArray':
        return this.renderObjectArray(cellData);
      case 'object':
        return this.renderObject(cellData);
      case 'user':
        return this.renderUser(cellData);
      case 'date':
        return this.renderDate(cellData);
      case 'datetime':
        return this.renderDateTime(cellData);
      case 'dateLink':
        return this.renderDateLink(cellData);
      case 'stageType':
        return this.renderStageType(cellData);
      case 'number':
        return cellData || '0';
      case 'noSortableNumber':
        return cellData || '0';
      case 'area':
        return cellData ? `${cellData} m2` : '0 m2';
      case 'price':
        return cellData
          ? Number(cellData).toLocaleString('de-DE', {
              style: 'currency',
              currency: 'USD',
            })
          : '$ 0';
      case 'percentage':
        return cellData ? `${cellData}%` : '0%';
      case 'editableText':
        if (this.props.suggestions.length > 0) {
          return this.renderAutocompletable();
        }
        return (
          <TextField
            value={cellData}
            multiline
            fullWidth
            onChange={(e) => this.props.handleChangeCell(e.target.value)}
          />
        );
      case 'editableNumber':
        return (
          <TextField value={cellData} multiline fullWidth onChange={this.handleChangeCellNumber} />
        );
      case 'image':
        return this.renderImage(cellData);
      case 'editablePercentage':
        return (
          <TextField value={cellData} multiline fullWidth onChange={this.handleChangeCellNumber} />
        );
      case 'parameter_type':
        return cellData ? cellData.title : '-';
      case 'category':
        return this.renderCategory(cellData);
      case 'zones':
        return this.renderZones(cellData);
      case 'addToLead':
        return this.renderAddToLead(cellData);
      case 'dropdown':
        return <DropDown options={cellData || []} />;
      case 'icon':
        return cellData ? <FontAwesomeIcon icon={cellData} color="#111" size="2x" /> : '-';
      case 'edit':
        return !renderEdit ? (
          <div></div>
        ) : !partner ? (
          <Tooltip title="Editar">
            <EditIcon style={{ cursor: 'pointer', color: 'grey' }} onClick={this.props.onDetail} />
          </Tooltip>
        ) : (
          <Tooltip title="Detalles">
            <VisibilityIcon
              style={{ cursor: 'pointer', color: 'grey' }}
              onClick={this.props.onDetail}
            />
          </Tooltip>
        );
      case 'calendar':
        return (
          <Tooltip title="Crear Evento">
            <CalendarIcon
              style={{ cursor: 'pointer', color: 'grey' }}
              onClick={this.props.onEvent}
            />
          </Tooltip>
        );
      case 'editWithPermissions':
        return (
          show && (
            <Tooltip title="Editar">
              <EditIcon
                style={{ cursor: 'pointer', color: 'grey' }}
                onClick={this.props.onDetail}
              />
            </Tooltip>
          )
        );
      case 'sold':
        return (
          formSalesProfileValidation && (
            <Button
              size="small"
              disabled={disabledProfiling}
              style={{ minWidth: '96px', fontSize: '10px' }}
              variant="contained"
              color="primary"
              onClick={this.props.renderMockup}
            >
              {isProfiled ? 'Editar Perfilamiento' : 'Perfilar Venta'}
            </Button>
          )
        );
      case 'uploadUnits':
        return (
          <div style={{ textAlign: 'center' }}>
            <input
              accept=".csv"
              style={{ display: 'none' }}
              id={`upload-button-file-${row.id}`}
              type="file"
              onChange={(e) => this.props.onAddUnits(row.id, e)}
            />
            <label style={{ margin: 0 }} htmlFor={`upload-button-file-${row.id}`}>
              <Tooltip title="Importar">
                <BackupIcon style={{ cursor: 'pointer', color: 'grey' }} />
              </Tooltip>
            </label>
          </div>
        );
      case 'duplicate':
        return (
          <Tooltip title="Duplicar">
            <FileCopyIcon
              style={{ cursor: 'pointer', color: 'grey' }}
              onClick={this.props.onDuplicate}
            />
          </Tooltip>
        );
      case 'attributes':
        return (
          <Tooltip title="Atributos">
            <AddCommentIcon
              style={{ cursor: 'pointer', color: 'grey' }}
              onClick={this.props.onAttributes}
            />
          </Tooltip>
        );
      case 'attach':
        return (
          <Tooltip title="Adjuntos">
            <AttachFileIcon
              style={{ cursor: 'pointer', color: 'grey' }}
              onClick={this.props.onAttach}
            />
          </Tooltip>
        );
      case 'save':
        return (
          <Tooltip title="Guardar">
            <SaveIcon className={classes.assignment} fontSize="large" onClick={this.props.onSave} />
          </Tooltip>
        );
      case 'modify':
        return (
          <Tooltip title="Modificar">
            <SaveIcon
              className={classes.assignment}
              fontSize="large"
              onClick={this.props.onUpdate}
            />
          </Tooltip>
        );
      case 'component':
        return <>{cellData}</>;
      case 'history_events':
        return (
          <Tooltip title="Historial">
            <EyeIcon
              className={classes.assignment}
              fontSize="large"
              onClick={this.props.onViewHistory}
            />
          </Tooltip>
        );
      case 'toggle':
        if (renderEdit) {
          return this.renderToggle(cellData);
        }
      case 'boolx':
        if (cellData) {
          return <CloseIcon style={{ color: red500 }} />;
        }
        return '-';
      case 'bool':
        if (cellData) {
          return <CheckIcon style={{ color: green500 }} />;
        }
        return <CloseIcon style={{ color: red500 }} />;
      case 'bool2':
        if (cellData) {
          return <CheckIcon style={{ color: green500 }} />;
        }
        return '-';
      case 'notNull':
        if (cellData === null) {
          return <CheckIcon style={{ color: green500 }} />;
        }
        return <CloseIcon style={{ color: red500 }} />;
      case 'availability':
        return this.renderAvailability();
      case 'empty':
        return '-';
      case 'link':
        return (
          <Button className={classes.button} onClick={this.props.onGetAllLeadsOfUser}>
            {cellData}
          </Button>
        );
      case 'temperature':
        if (cellData) {
          let classTemeprature = null;
          if (cellData === 'cold') {
            classTemeprature = classes.cold;
          } else if (cellData === 'hot') {
            classTemeprature = classes.hot;
          } else {
            classTemeprature = classes.potential;
          }
          return (
            <div className={classes.temperatureContainer}>
              <div className={`${classTemeprature} ${classes.temperatureItem}`} />
            </div>
          );
        }
        return '-';
      case 'geolocation':
        return (
          <Tooltip title="Ubicación">
            <Place style={{ cursor: 'pointer', color: 'grey' }} onClick={this.props.openMapModal} />
          </Tooltip>
        );
      case 'addUser':
        return (
          <Tooltip title="Agregar usuario">
            <PersonAddIcon
              style={{ cursor: 'pointer', color: 'grey', marginLeft: 35 }}
              onClick={this.props.onAddUser}
            />
          </Tooltip>
        );
      case 'oauthIntegration':
        return (
          <Tooltip title="Credenciales de integración">
            <SettingsIcon
              style={{ cursor: 'pointer', color: 'grey', marginLeft: 35 }}
              onClick={this.props.onGenerateCredentials}
            />
          </Tooltip>
        );
      case 'proforma':
        return (
          <Tooltip title="Proforma">
            <ReceiptIcon
              style={{ cursor: 'pointer', color: 'grey' }}
              onClick={this.props.onProformas}
            />
          </Tooltip>
        );
      case 'cloneProject':
        return (
          <Tooltip title="Clonar Proyecto">
            <FontAwesomeIcon
              icon="clone"
              color="grey"
              style={{ cursor: 'pointer', marginLeft: 16, fontSize: 22 }}
              onClick={this.props.onCloneProject}
            />
          </Tooltip>
        );
      case 'configIntegrations':
        return <Integrations project={row} />;
      default:
        return '-';
    }
  }

  renderCellClassName() {
    const { type, classes } = this.props;
    let className = classes.auto;
    if (
      type === 'edit' ||
      type === 'duplicate' ||
      type === 'attach' ||
      type === 'save' ||
      type === 'geolocation' ||
      type === 'attributes' ||
      type === 'bool' ||
      type === 'proforma'
    ) {
      className = classes.small;
    }
    if (type === 'number') {
      className = classes.medium;
    }
    if (type === 'date' || type === 'datetime') {
      className = classes.date;
    }
    if (type === 'dateLink') {
      className = classes.dateLink;
    }
    if (
      type === 'editableNumber' ||
      type === 'editableText' ||
      type === 'area' ||
      type === 'price'
    ) {
      className = classes.editableText;
    }
    return `${className} ${classes.cellPadding}`;
  }

  render() {
    return <TableCell className={this.renderCellClassName()}>{this.renderCell()}</TableCell>;
  }
}
EnhancedTableCell.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  cellData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  suggestions: PropTypes.array,
  onDetail: PropTypes.func,
  onEvent: PropTypes.func,
  onDuplicate: PropTypes.func,
  onSave: PropTypes.func,
  onGetAllLeadsOfUser: PropTypes.func,
  onUpdate: PropTypes.func,
  onViewHistory: PropTypes.func,
  onAttach: PropTypes.func,
  onAttributes: PropTypes.func,
  onAddToLead: PropTypes.func,
  allowAddToLead: PropTypes.bool,
  handleChangeCell: PropTypes.func,
  openMapModal: PropTypes.func,
  onAddUser: PropTypes.func,
  onGenerateCredentials: PropTypes.func,
  isNoClearable: PropTypes.bool,
  onCloneProject: PropTypes.func,
  onProformas: PropTypes.func,
  onProformaAdmin: PropTypes.func,
  onAddUnits: PropTypes.func,
};

export default withStyles(styles)(EnhancedTableCell);
