export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';

export const GET_EMAIL_TEMPLATES_SENDING = 'GET_EMAIL_TEMPLATES_SENDING';

export const GET_EMAIL_TEMPLATES_FAILED = 'GET_EMAIL_TEMPLATES_FAILED';

export const FILTER_EMAIL_TEMPLATES_SUCCESS = 'FILTER_EMAIL_TEMPLATES_SUCCESS';

export const FILTER_EMAIL_TEMPLATES_SENDING = 'FILTER_EMAIL_TEMPLATES_SENDING';

export const FILTER_EMAIL_TEMPLATES_FAILED = 'FILTER_EMAIL_TEMPLATES_FAILED';

export const CREATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_EMAIL_TEMPLATE_SUCCESS';

export const CREATE_EMAIL_TEMPLATE_SENDING = 'CREATE_EMAIL_TEMPLATE_SENDING';

export const CREATE_EMAIL_TEMPLATE_FAILED = 'CREATE_EMAIL_TEMPLATE_FAILED';

export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS';

export const UPDATE_EMAIL_TEMPLATE_SENDING = 'UPDATE_EMAIL_TEMPLATE_SENDING';

export const UPDATE_EMAIL_TEMPLATE_FAILED = 'UPDATE_EMAIL_TEMPLATE_FAILED';
