const CONSTANTS = {
  UNITS_TYPES_WITHOUT_ROOMS: ['5c77886082eb022b6cbe5ec6', '5c77886082eb025045be5ec7', '5cdb3f8264c76d49f25fe3fa'],
  ROLES_DEFAULT_FILTER_LEADS: ['5cc327ae76008939e447fc73', '5cc327c976008939e447fc74'],
  DEFAULT_CHIPS_FILTER_LEADS: [
    {value: ['5cbdf596ba0b4000172a4857', '5cbdf569ba0b4000172a4851'], title: 'Nuevo, Seguimiento ', id: 'status', type: 'select', label: 'Estado'}
  ],
  TRIVO_COMPANY_ID: '5e793bfe142fff7b1494b7e6',
  ADMIN_ROL_ID: '5cc327ae76008939e447fc73',
  BUCKET_DOMAIN_NAME: 'https://media.trivo.com.mx/',
  BUCKET_S3_NAME: 'https://s3.amazonaws.com/media.trivo.com.mx/',
  UNIT_TYPES_NAMES: {
    "Apart Estudios": "Apart Estudio",
    "Bodegas" : "Bodega",
    "Casas": "Casa",
    "Departamentos": "Departamento",
    "Locales Comerciales": "Local Comercial",
    "Lofts" : "Loft",
    "Oficinas/Consultorios" : "Oficina/Consultorio",
    "Suites": "Suite",
    "Terrenos": "Terreno"
  },
};

export default CONSTANTS;
