const drawerWidth = 240;

export const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar, 
  drawerPaper: {
    width: drawerWidth,
  },
  filterTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'grey',
  },
});

export default styles;
