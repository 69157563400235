export const styles = theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    minHeight: '90vh',
    marginBottom: theme.spacing.unit * 2
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  rowTitle: {
    borderBottom: 'none',
    fontSize: 22,
    fontWeight: '600'
  }
});

export default styles;
