import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CustomSnackbar from '../common/customSnackbar';
import logo from '../../assets/logotrivo.png';
import {styles} from './styles';
import {login} from '../../actions/security';
import {isEmail} from '../../utils/utils';
import {validateCompany} from './../../actions/companies';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      identifier: '',
      company: null,
      validIdentifier: true,
      isSuperAdmin: false
    };
    this.onLoginClick = this.onLoginClick.bind(this);
    this.onIdentifierClick = this.onIdentifierClick.bind(this);
    this.onIdentifierChange = this.onIdentifierChange.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.renderSnack = this.renderSnack.bind(this);
    this.renderIdentifierSnack = this.renderIdentifierSnack.bind(this);
    this.validate = this.validate.bind(this);
    this.checkIdentifier = this.checkIdentifier.bind(this);
    this.onkeyPress = this.onkeyPress.bind(this);
  }

  componentWillMount() {
    const {location} = this.props;
    const identifier = location.pathname.slice(7);
    const regex = /^\/login\/[^ "]+$/g;
    if (regex.test(location.pathname)) this.checkIdentifier(identifier);
  }

  onLoginClick() {
    const {username, password, company} = this.state;
    const id = company ? company._id : null;
    this.props.login(username, password, id);
  }

  onIdentifierClick() {
    window.location.href = `/login/${this.state.identifier}`;
  }

  onIdentifierChange(e) {
    this.setState({identifier: e.target.value});
  }

  onUsernameChange(e) {
    this.setState({username: e.target.value});
  }

  onPasswordChange(e) {
    this.setState({password: e.target.value});
  }

  onkeyPress(e) {
    if (e.key === 'Enter') {
      this.onIdentifierClick();
      e.preventDefault();
    }
  }

  onkeyPasswordPress(e) {
    if (e.key === 'Enter') {
      this.onLoginClick();
      e.preventDefault();
    }
  }

  async checkIdentifier(identifier) {
    const company = await this.props.validateCompany(null, identifier);
    if (company) this.setState({company});
    else {
      this.setState({validIdentifier: false});
      window.setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    }
  }

  validate() {
    if (
      this.state.username !== '' &&
      this.state.password !== '' &&
      isEmail(this.state.username)
    ) {
      return false;
    }
    return true;
  }

  renderButton() {
    const {classes, isLoading} = this.props;
    const {company} = this.state;

    const primaryColor =
      !this.validate() && company
        ? {backgroundColor: company.layout.colors[0]}
        : {};
    if (!isLoading) {
      return (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={this.validate()}
          color="primary"
          style={primaryColor}
          onClick={this.onLoginClick}
          className={classes.submit}
        >
          Iniciar Sesi&oacute;n
        </Button>
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderIdentifierButton() {
    const {classes, isLoading} = this.props;
    if (!isLoading) {
      return (
        <Button
          type="button"
          fullWidth
          variant="contained"
          disabled={this.state.identifier === ''}
          color="primary"
          onClick={this.onIdentifierClick}
          className={classes.submit}
        >
          Ingresar
        </Button>
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderSnack() {
    const {message, error} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          message={message}
          variant={error === null ? 'success' : 'error'}
        />
      );
    }
    return null;
  }

  renderIdentifierSnack() {
    const {validIdentifier} = this.state;
    if (!validIdentifier) {
      return (
        <CustomSnackbar
          message="La empresa no es válida"
          variant="error"
        />
      );
    }
    return null;
  }

  renderIdentifierForm() {
    const {classes, location} = this.props;
    const {identifier, isSuperAdmin} = this.state;
    return isSuperAdmin ? (
      <div>{this.renderForm()}</div>
    ) : (
      location.pathname === '/login' && (
        <div>
          <Paper className={classes.paper}>
            <img src={logo} alt="logo" className={classes.logo} />
            <Typography component="h1" variant="h5">
              Inicio
            </Typography>
            <form className={classes.form}>
              <TextField
                required
                value={identifier}
                autoFocus
                onChange={this.onIdentifierChange}
                fullWidth
                label="Empresa"
                className={classes.input}
                onKeyPress={this.onkeyPress}
              />
              {this.renderIdentifierButton()}
              <Button
                size="small"
                color="primary"
                onClick={() => this.setState({isSuperAdmin: true})}
                className={classes.linkButton}
              >
                <SupervisorAccountIcon className={classes.linkButtonIcon} />
                Ingresar como Super Administrador
              </Button>
            </form>
          </Paper>
        </div>
      )
    );
  }

  renderLoginForm() {
    const {location} = this.props;
    const regex = /^\/login\/[^ "]+$/g;
    return regex.test(location.pathname) && <div>{this.renderForm()}</div>;
  }

  renderForm() {
    const {classes} = this.props;
    const {company, isSuperAdmin, validIdentifier} = this.state;
    const image = company && company.layout.logo ? company.layout.logo : logo;
    return (
      <div>
        <Paper className={classes.paper}>
          <img src={image} alt="logo" className={classes.logo} />
          <Typography component="h1" variant="h5">
            Iniciar Sesi&oacute;n
          </Typography>
          <form className={classes.form}>
            {validIdentifier && (
              <div>
                <TextField
                  required
                  value={this.state.username}
                  autoFocus
                  onChange={this.onUsernameChange}
                  fullWidth
                  label="Correo electrónico"
                  className={classes.input}
                  data-testid="email-input"
                />
                <TextField
                  required
                  value={this.state.password}
                  onChange={this.onPasswordChange}
                  fullWidth
                  type="password"
                  label="Contraseña"
                  className={classes.input}
                  data-testid="password-input"
                  onKeyPress={this.onkeyPasswordPress}

                />
                {this.renderButton()}
                {/* <Button
                  href="/login"
                  size="small"
                  color="primary"
                  className={classes.linkButton}
                >
                  <ArrowBackIcon className={classes.linkButtonIcon} />
                  Regresar
                </Button> */}
              </div>
            )}
            {this.renderSnack()}
            {this.renderIdentifierSnack()}
            {!isSuperAdmin && !company && (
              <CircularProgress className={classes.progress} />
            )}
          </form>
        </Paper>
      </div>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.main}>
        {this.renderIdentifierForm()}
        {this.renderLoginForm()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {user} = state;
  return {isLoading: user.sending, error: user.error, message: user.message};
};

const mapDispatchToProps = {
  login,
  validateCompany
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  location: PropTypes.object,
  validateCompany: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
