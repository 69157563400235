/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as originActions from './types/origins';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterOrigins = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().origins.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().origins.order || orderBy !== store.getState().origins.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: originActions.FILTER_ORIGINS_SENDING,
    payload: {
      origins: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && order !== null && orderBy !== null && searchText !== null) {
      filter = {
        filters: {
          name: searchText
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order
        },
        company: company && company._id
      };
    }
    const response = await axios(axiosMaker('POST', 'origins/filter', filter, true));
    return dispatch({
      type: originActions.FILTER_ORIGINS_SUCCESS,
      payload: {
        origins: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: originActions.FILTER_ORIGINS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getOrigins = filters => async (dispatch) => {
  dispatch({
    type: originActions.GET_ORIGINS_SENDING,
    payload: {
      origins: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: filters || {active: true},
      company: company && company._id
    };
    const response = await axios(axiosMaker('POST', 'origins/filter', data, true));
    return dispatch({
      type: originActions.GET_ORIGINS_SUCCESS,
      payload: {
        origins: response.data.results,
        dataLength: response.data.results.length
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: originActions.GET_ORIGINS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createOrigin = data => async (dispatch) => {
  dispatch({
    type: originActions.CREATE_ORIGIN_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'origins', data, true));
    return dispatch({
      type: originActions.CREATE_ORIGIN_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: originActions.CREATE_ORIGIN_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateOrigin = (id, origin) => async (dispatch) => {
  dispatch({
    type: originActions.UPDATE_ORIGIN_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `origins/${id}`, origin, true));
    return dispatch({
      type: originActions.UPDATE_ORIGIN_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: originActions.UPDATE_ORIGIN_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
