import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

const UploadTrivoSmartModal = (props) => {
  const { classes, getVersions, onUpload } = props;

  const [value, setValue] = useState('-1');
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    async function fetchVersions() {
      const versions = await getVersions();
      setVersions(versions);
    }
    fetchVersions();
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Dialog open={true} onClose={() => console.log('close')}>
      <DialogTitle>Seleccione una acción</DialogTitle>
      <FormControl component="fieldset" className={classes.radioGroupContainer}>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel value="-1" control={<Radio />} label="Crear una nueva version" />
          {versions.map(({ version }) => (
            <FormControlLabel
              key={version.toString()}
              value={version.toString()}
              control={<Radio />}
              label={`Actualizar la version ${version}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <div className={classes.footerContained}>
        <Button variant="contained" color="primary" onClick={() => onUpload(Number(value))}>
          Subir
        </Button>
      </div>
    </Dialog>
  );
};

UploadTrivoSmartModal.propTypes = {
  classes: PropTypes.object.isRequired,
  getVersions: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
};

export default withStyles(styles)(UploadTrivoSmartModal);
