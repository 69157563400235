/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChevronRightOutlined from '@material-ui/icons/ChevronRightOutlined';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Fab,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Grid,
} from '@material-ui/core';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import highcharts3d from 'highcharts/highcharts-3d';
import { useSelector, useDispatch } from 'react-redux';
import variablePie from 'highcharts/modules/variable-pie';
import treeMap from 'highcharts/modules/treemap';
import heatmap from 'highcharts/modules/heatmap';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styles } from './styles';
import Chart from './Chart';
import { getFormattedZones } from '../../actions/zones';
import { getAllParameters } from '../../actions/parameters';
import { getPropertyTypes } from '../../actions/propertyTypes';
import { getProjectsNameByCompany } from '../../actions/projects';
import {
  formatPie,
  formatColumn,
  formatColumnIversed,
  formatColumnIversed3D,
  pieWithLegends,
  semiPie,
  treeMapFormater,
  formatDonnut,
  formatDynamicPie,
} from './chartFunctions';
import { userHasTrivoSmart } from '../../actions/users';
import { FREE_PLAN, PLANS_TO_UPGRADE, UPGRADE_PLAN_LINK } from '../../utils/utils';
import FilterDrawer from './filterDrawer';
import FilterPaper from './filterPaper';
import { changeFilters, setDisabledFilter } from '../../actions/smartDemandFilters';

highchartsMore(Highcharts);
highcharts3d(Highcharts);
variablePie(Highcharts);
treeMap(Highcharts);
heatmap(Highcharts);

const CustomFab = ({ classes }) => {
  const dispatch = useDispatch();

  const { disabledFilter } = useSelector((state) => state.smartDemandFilters);

  return (
    <Fab
      variant="extended"
      color="primary"
      className={classes.fab}
      disabled={disabledFilter}
      onClick={() => dispatch(changeFilters())}
    >
      <FontAwesomeIcon icon="filter" className={classes.extendedIcon} />
      Filtrar
    </Fab>
  );
};

const TrivoSmartDemand = (props) => {
  const { classes } = props;
  
  let defaultEndDate = new Date();
  defaultEndDate.setMonth(defaultEndDate.getMonth() - 1);

  const {
    graphs,
    unitsCount,
    projectsCount,
    priceAvg,
    typesCount,
    availability,
    sending,
  } = useSelector((state) => state.smart);
  const { projects } = useSelector((state) => state.projects);
  const zones = useSelector((state) => state.zones).formattedZones;
  const { allParameters } = useSelector((state) => state.parameters);
  const { propertyTypes } = useSelector((state) => state.propertyTypes);

  let normalizedZones = [];
  let roomsData;
  let unitTypesData;
  let projectsData;

  if (projects && projects.length > 0) {
    projectsData = projects;
  }

  if (zones && zones.length > 0) {
    zones.map((city) => {
      const temp = city.zones.reduce((acc, val) => {
        for (const item of val.sectors) acc.push(item);
        return acc;
      }, []);
      normalizedZones = [...normalizedZones, ...temp];
      return;
    });
  }

  if (allParameters && allParameters.length > 0) {
    roomsData = allParameters.find((param) => param.id === '5c6c59cf64c425aa3398f436').options
      .content;
  }

  if (propertyTypes && propertyTypes.length > 0) {
    unitTypesData = propertyTypes.filter((item) => item.active);
  }
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const company = JSON.parse(localStorage.getItem('company'));
      const hasTrivoSmartPermission = await userHasTrivoSmart();
      const userWithTrivoSmart = company && company.hasTrivoSmart && hasTrivoSmartPermission;
      if (!userWithTrivoSmart) props.history.push('/');
      else {
        const filterBy = !company.plan || company.plan === FREE_PLAN ? 'projects' : null;

        dispatch(getFormattedZones('cities', filterBy, company._id));
        dispatch(getAllParameters(true));
        dispatch(getPropertyTypes());
        dispatch(getProjectsNameByCompany());
        dispatch(setDisabledFilter(true));
      }
    })();
  }, []);

  const validEmptyCharts = (dataToValid) => {
    return dataToValid.reduce((total, current) => {
      return current.count + total;
    }, 0);
  };

  const renderOnChart = (typeChart, data) => {
    switch (typeChart) {
      case 'GENDER':
        return formatPie('Género', data);
      case 'CIVIL_STATUS':
        return formatColumn('Estado civil', data);
      case 'DATE_OF_BIRTH':
        return formatColumnIversed('Rango de Edades', data);
      case 'PROFESSION':
        return formatColumnIversed3D('Profesiones', data);
      case 'JOB_TYPE':
        return pieWithLegends('Tipo de Trabajo', data);
      case 'REVENUE_RANGE':
        return semiPie('Rango de Sueldo', data);
      case 'RESIDENCE_PLACE':
        return pieWithLegends('Vivienda Actual', data);
      case 'PROPERTY_TYPE':
        return formatColumn('Estado de Vivienda Actual', data);
      case 'HOME_ZONE':
        return treeMapFormater('Lugar de Residencia Actual', data);
      case 'DECISION_REASON':
        return formatDonnut('Peso de Decisión de Compra', data);
      case 'SALE_TRIGGER':
        return formatColumnIversed('Motivo de Compra', data);
      case 'LIFECYCLE_PHASE':
        return formatColumn('Ciclo de vida', data);
      case 'CREDIT_BANK':
        return formatDynamicPie('Institución de crédito', data);
      case 'DISCOVERY_WAY':
        return formatColumnIversed3D('Medio de influencia', data, false);
      case 'CLOSING_TIME':
        return treeMapFormater('Tiempo de compra', data, '#f2efea', '#fc7753');
      default:
        break;
    }
  };

  const renderCol = (typeChart, data) => {
    switch (typeChart) {
      case 'GENDER':
        return 6;
      case 'CIVIL_STATUS':
        return 3;
      case 'DATE_OF_BIRTH':
        return 3;
      case 'PROFESSION':
        return 6;
      case 'JOB_TYPE':
        return 6;
      case 'REVENUE_RANGE':
        return 6;
      case 'RESIDENCE_PLACE':
        return 3;
      case 'PROPERTY_TYPE':
        return 3;
      case 'HOME_ZONE':
        return 12;
      case 'DECISION_REASON':
        return 6;
      case 'SALE_TRIGGER':
        return 6;
      case 'LIFECYCLE_PHASE':
        return 6;
      case 'CREDIT_BANK':
        return 6;
      case 'DISCOVERY_WAY':
        return 6;
      case 'CLOSING_TIME':
        return 6;
      default:
        break;
    }
  };

  const renderReportByChart = () => {
    if (graphs.length > 0) {
      return (
        <Grid container spacing={24}>
          {graphs.map((chartItem, index) => {
            return validEmptyCharts(chartItem.result) ? (
              <Grid key={index} item md={renderCol(chartItem.graph)} xs={12}>
                <Paper className={classes.paperCharts}>
                  <Chart
                    options={{
                      ...renderOnChart(chartItem.graph, chartItem.result),
                      credits: {
                        enabled: false,
                      },
                    }}
                    highcharts={Highcharts}
                  />
                </Paper>
              </Grid>
            ) : (
              ''
            );
          })}
        </Grid>
      );
    }
    return '';
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderIndicators = () => {
    return (
      <div>
        <Grid alignItems="center" container className={classes.rootGrow} spacing={24}>
          <Grid item md={6} xs={12}>
            <div className={`${classes.indicatorContent} primary`}>
              <FontAwesomeIcon icon="filter" className={classes.backgroundIcon} />
              <Grid item xs={12} className={classes.rootGrow}>
                <ListItem>
                  <ListItemText
                    className={classes.topIndicatorSecondary}
                    primary="Vendidas:"
                    secondary={
                      <CountUp
                        duration={1}
                        end={unitsCount}
                        suffix={` ${unitsCount === 1 ? 'Unidad' : 'Unidades'}`}
                      />
                    }
                  />
                </ListItem>
              </Grid>
              <Grid item xs={12} className={classes.rootGrow}>
                <ListItem>
                  <ListItemText
                    className={classes.topIndicatorSecondary}
                    primary="De:"
                    secondary={
                      <CountUp
                        duration={1}
                        end={projectsCount}
                        suffix={` ${projectsCount === 1 ? 'Desarrollo' : 'Desarrollos'}`}
                      />
                    }
                  />
                </ListItem>
              </Grid>
              <Grid item xs={12} className={classes.rootGrow}>
                <ListItem>
                  <ListItemText
                    className={classes.topIndicatorSecondary}
                    primary="Con un precio promedio de:"
                    secondary={<CountUp duration={1} prefix="$ " end={priceAvg} />}
                  />
                </ListItem>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={`${classes.indicatorContent} secondary`}>
              <FontAwesomeIcon icon="home" className={classes.backgroundIcon} />
              <Grid container className={classes.rootGrow}>
                <Grid item lg={4} xs={6}>
                  <ListItem style={{ paddingBottom: 0 }}>
                    <ListItemText
                      className={classes.unitIndicator}
                      primary={typesCount.find((item) => item.title === 'Casa').title}
                      secondary={
                        <List>
                          {typesCount
                            .find((item) => item.title === 'Casa')
                            .rooms.map((itemsRooms, j) => (
                              <ListItemText
                                key={`typesCount_casa_${j}`}
                                className={classes.unitRoomsIndicator}
                                primary={itemsRooms}
                              />
                            ))}
                        </List>
                      }
                    />
                  </ListItem>
                </Grid>
                <Grid item lg={4} xs={6}>
                  <ListItem style={{ paddingBottom: 0 }}>
                    <ListItemText
                      className={classes.unitIndicator}
                      primary={typesCount.find((item) => item.title === 'Departamento').title}
                      secondary={
                        <List>
                          {typesCount
                            .find((item) => item.title === 'Departamento')
                            .rooms.map((itemsRooms, j) => (
                              <ListItemText
                                key={`typesCount_departamento_${j}`}
                                className={classes.unitRoomsIndicator}
                                primary={itemsRooms}
                              />
                            ))}
                        </List>
                      }
                    />
                  </ListItem>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Grid container className={classes.rootGrow}>
                    {typesCount
                      .filter((item) => !['Casa', 'Departamento'].includes(item.title))
                      .map((itemType, i) => {
                        return (
                          <Grid key={`typesCount_${i}`} item lg={12} xs={6}>
                            <ListItem style={{ paddingBottom: 0 }}>
                              <ListItemText
                                className={classes.unitIndicator}
                                primary={itemType.title}
                              />
                            </ListItem>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <FilterDrawer
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        normalizedZones={normalizedZones}
        roomsData={roomsData}
        unitTypesData={unitTypesData}
        projectsData={projectsData}
        zones={zones}
        propertyTypes={propertyTypes}
        allParameters={allParameters}
        projects={projects}
      />
      <main className={classes.content}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <ChevronRightOutlined color="primary" />
        </IconButton>
        <FilterPaper />
        {renderIndicators()}
        {renderReportByChart()}
      </main>
      <CustomFab {...props} />
    </div>
  );
};

TrivoSmartDemand.propTypes = {
  history: PropTypes.any,
};

export default withStyles(styles)(TrivoSmartDemand);
