/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import EnhancedTable from './table';
import {commentsCols} from '../../utils/colTypes';
import CustomSnackbar from '../common/customSnackbar';
import getComments from '../../actions/comments';
import {BUILDER_ID} from '../../utils/utils';

export class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Comentarios',
      rows: [],
      page: 0,
      sort: 'builder',
      sortDirection: 'asc',
      rowsPerPage: 20,
      dataLength: 0
    };
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onOrder = this.onOrder.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.getComments().then(() => {
      const profileId = localStorage.getItem('profileId'); 
      const {page, rowsPerPage} = this.state;
      if (this.props.comments) {
        let rowsArray = this.props.comments.sort((a, b) => (a.project.builder > b.project.builder) ? 1 : ((b.project.builder > a.project.builder) ? -1 : 0));
        let dataLength = rowsArray.length;
        if (profileId === BUILDER_ID) {
          const builder = localStorage.getItem('builder'); 
          rowsArray = rowsArray.filter(obj => obj.project.builderId === builder);
          dataLength = rowsArray.length;
        }
        this.setState({rows: rowsArray.slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage), dataLength});
      }
    });
  } 

  onOrder(orderDirection, orderBy, page = null, rowsPerPage = null) {
    const profileId = localStorage.getItem('profileId'); 
    if (page === null) {
      page = this.state.page;
    }
    if (rowsPerPage === null) {
      rowsPerPage = this.state.rowsPerPage;
    }
    let rows = this.props.comments;
    if (profileId === BUILDER_ID) {
      const builder = localStorage.getItem('builder'); 
      rows = rows.filter(obj => obj.project.builderId === builder);
    }
    switch (orderBy) {
    case 'builder':
      let orderByBuilder = rows.sort((a, b) => (a.project.builder > b.project.builder) ? 1 : ((b.project.builder > a.project.builder) ? -1 : 0));
      if (orderDirection === 'desc') {
        orderByBuilder = orderByBuilder.reverse();
      }
      rows = orderByBuilder.slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage);
      break;
    case 'project':
      let orderByProject = rows.sort((a, b) => (a.project.name > b.project.name) ? 1 : ((b.project.name > a.project.name) ? -1 : 0));
      if (orderDirection === 'desc') {
        orderByProject = orderByProject.reverse();
      }
      rows = orderByProject.slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage);
      break;
    case 'unit':
      let orderByUnit = rows.sort((a, b) => (a.unit.name > b.unit.name) ? 1 : ((b.unit.name > a.unit.name) ? -1 : 0));
      if (orderDirection === 'desc') {
        orderByUnit = orderByUnit.reverse();
      }
      rows = orderByUnit.slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage);
      break;
    case 'lead':
      let orderByLead = rows.sort((a, b) => (a.lead.name > b.lead.name) ? 1 : ((b.lead.name > a.lead.name) ? -1 : 0));
      if (orderDirection === 'desc') {
        orderByLead = orderByLead.reverse();
      }
      rows = orderByLead.slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage);
      break;
    case 'pointsInFavor':
      let orderByFavor = rows.filter(obj => obj.unit.pros.length > 0).sort((a, b) => (Number(a.unit.pros[0].value) > Number(b.unit.pros[0].value)) ? 1 : ((Number(b.unit.pros[0].value) > Number(a.unit.pros[0].value)) ? -1 : 0));
      orderByFavor = rows.filter(obj => obj.unit.pros.length === 0).concat(orderByFavor);
      if (orderDirection === 'desc') {
        orderByFavor = orderByFavor.reverse();
      }
      rows = orderByFavor.slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage);
      break;
    case 'pointsAgainst':
      let orderByAgainst = rows.filter(obj => obj.unit.cons.length > 0).sort((a, b) => (Number(a.unit.cons[0].value) > Number(b.unit.cons[0].value)) ? 1 : ((Number(b.unit.cons[0].value) > Number(a.unit.cons[0].value)) ? -1 : 0));
      orderByAgainst = rows.filter(obj => obj.unit.cons.length === 0).concat(orderByAgainst);
      if (orderDirection === 'desc') {
        orderByAgainst = orderByAgainst.reverse();
      }
      rows = orderByAgainst.slice((page * rowsPerPage), (page * rowsPerPage) + rowsPerPage);
      break;
    default:
      break;
    }
    this.setState({sort: orderBy, sortDirection: orderDirection, rows});
    return rows;
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_comments) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  handleChangePage(page) {
    const {rowsPerPage, sortDirection, sort} = this.state;
    const rows = this.onOrder(sortDirection, sort, page, rowsPerPage);
    this.setState({rows, page});
  }

  handleChangeRowsPerPage(rowsPerPage) {
    const {page, sortDirection, sort} = this.state;
    const rows = this.onOrder(sortDirection, sort, page, rowsPerPage);
    this.setState({rows, rowsPerPage});
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }


  render() {
    const {isLoading, isLoadingBuilders} = this.props;
    const {title, page, rowsPerPage, rows, sort, sortDirection, dataLength} = this.state;
    const data = {
      rows,
      cols: commentsCols
    };
    if (isLoading || isLoadingBuilders) {
      return (
        <CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}} />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        <EnhancedTable 
          title={title}
          data={data}
          dataLength={dataLength}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onOrder={this.onOrder}
          orderBy={sort}
          orderDirection={sortDirection}
        />
      </div>
    );
  }
}

Comments.propTypes = {
  comments: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isLoadingBuilders: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  getComments: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {comments} = state;
  return { 
    comments: comments.comments,
    isLoadingBuilders: comments.sending,
    error: comments.error,
    message: comments.message,
    isLoading: comments.sending
  };
};
  
const mapDispatchToProps = {
  getComments
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Comments);
