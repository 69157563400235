export const styles = () => ({
  rowGridAttachment: {
      display: 'flex'
    },
    iconButton: {
      cursor: 'pointer',
      '&:focus': {
        outline: 'none'
      }
    },
    inputPadding: {
      paddingBottom: '48px !important'
    },
    divider: {
      width: '80%',
      marginBottom: 36,
      marginTop: 36
    },
    legacy: {
      '& *': {
        color : 'darkgrey !important'
      }
    }
  });
  
  export default styles;
  