/* eslint-disable no-alert */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {
  filterUsers,
  createUser,
  editUser,
  resetPassword,
  getUser,
  getOnlyAsessors
} from '../../actions/users';
import {getProfiles} from '../../actions/profiles';
import {getBuilders} from '../../actions/builders';
import EnhancedTable from '../common/enhancedTable';
import {userCols} from '../../utils/colTypes';
import CustomSnackbar from '../common/customSnackbar';
import UsersDetail from './detail';
import {
  CREATE_USER_SUCCESS,
  USER_ALREADY_EXISTS
} from '../../actions/types/users';
import {PARTNERS, STRATEGIC_PARTNER_ID, SUPER_ADMIN_ROL_ID} from '../../utils/utils';
import {getCompanies} from '../../actions/companies';

export class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isNew: false,
      title: 'Usuarios'
    };
    this.onAdd = this.onAdd.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.editUser = this.editUser.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.createUser = this.createUser.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.getProfiles();
    this.props.getCompanies();
    this.props.getBuilders();
    this.props.filterUsers(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
     this.props.getOnlyAsessors();
  }
 

  onAdd() {
    this.setState({isNew: true, user: {}});
  }

  onDetail(id) {
    this.props.getUser(id).then((result) => {
      const {user} = result.payload;
      this.setState({user, isNew: false});
    });
  }

  onOrder(order, orderBy) {
    this.props.filterUsers(
      this.props.page,
      this.props.rowsPerPage,
      order,
      orderBy,
      this.props.searchText
    );
  }

  onSearch(searchText) {
    this.props.filterUsers(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      searchText
    );
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_users) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  };

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_users) {
        return true;
      }
    }
    return false;
  };

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_users) {
        return true;
      }
    }
    return false;
  };

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterUsers(
      this.props.page,
      rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  handleChangePage(page) {
    this.props.filterUsers(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  handleGoBack() {
    this.props.filterUsers(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
    this.setState({user: null, isNew: false});
  }

  handleCols = () => {
    const profileId = localStorage.getItem('profileId');
    if (SUPER_ADMIN_ROL_ID !== profileId) { return userCols.filter(col => !['companyName'].includes(col.id)); }   
    return userCols;
  };

  resetPassword(email) {
    this.props.resetPassword(email);
  }

  createUser(user) {
    this.props.createUser(user).then((result) => {
      if (result.type === CREATE_USER_SUCCESS) {
        this.handleGoBack();
      } else if (result.type === USER_ALREADY_EXISTS) {
        alert('El correo electrónico ingresado ya existe');
      }
    });
  }

  editUser(userEdited) {
    const {user} = this.state;
    const wasStrategicPartner = user.profile === STRATEGIC_PARTNER_ID;

    this.setState({user: userEdited});
    const edited = {};
    if (user.active !== userEdited.active) {
      edited.active = userEdited.active;
    }
    if (user.name !== userEdited.name) {
      edited.name = userEdited.name;
    }
    if (user.lastname !== userEdited.lastname) {
      edited.lastname = userEdited.lastname;
    }
    if (user.email !== userEdited.email) {
      edited.email = userEdited.email;
    }
    if (user.profile_img !== userEdited.profile_img) {
      edited.profile_img = userEdited.profile_img;
    }
    if (user.profile !== userEdited.profile) {
      edited.profile = userEdited.profile;
      if (wasStrategicPartner) {
        edited.strategicPartner = null;
      }
    }
    if (user.zones !== userEdited.zones) {
      edited.zones = userEdited.zones;
    }
    if (user.phone !== userEdited.phone) {
      edited.phone = userEdited.phone;
    }
    if (user.commissionPercentage !== userEdited.commissionPercentage) {
      edited.commissionPercentage = userEdited.commissionPercentage;
    }
    if (user.is_asesor !== userEdited.is_asesor) {
      edited.is_asesor = userEdited.is_asesor;
    }
    if (user.is_builder !== userEdited.is_builder) {
      edited.is_builder = userEdited.is_builder;
    }
    if (user.builder !== userEdited.builder) {
      edited.builder = userEdited.builder;
    }
    if (userEdited.profile && user.strategicPartner !== userEdited.strategicPartner) {
      edited.strategicPartner = userEdited.strategicPartner;
    }
    if (user.trivoSmartPermission !== userEdited.trivoSmartPermission) {
      edited.trivoSmartPermission = userEdited.trivoSmartPermission;
    }
    if (user.document !== userEdited.document) {
      edited.document = userEdited.document;
    }
    if (user.advisers !== userEdited.advisers) {
      edited.advisers = userEdited.advisers;
    }
    this.props.editUser(userEdited.id, edited, userEdited.company).then(() => {
      this.handleGoBack();
    });
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {
      isLoading,
      users,
      dataLength,
      profiles,
      builders,
      companies,
      asessors
    } = this.props;
    const {title, user, isNew} = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const data = {
      rows: users,
      cols: this.handleCols(),
      autoFillers: {profile: profiles}
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      hasEditPermission,
      hasCreatePermission,
      isSelectable: false,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };
    if (isLoading === true) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)'
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto'
            }}
          />
        </div>
      );
    }
    return (
      <div id="divTestUsers">
        {this.renderSnackBar()}
        {user === null ? (
          <EnhancedTable
            title={title}
            data={data}
            handlers={handlers}
            config={config}
          />
        ) : (
          <UsersDetail
            user={user}
            asessors={asessors}
            handleGoBack={this.handleGoBack}
            isLoading={isLoading}
            profiles={profiles}
            builders={builders}
            companies={companies}
            isNew={isNew}
            createUser={this.createUser}
            editUser={this.editUser}
            resetPassword={this.resetPassword}
            strategicPartner={PARTNERS}
          />
        )}
      </div>
    );
  }
}

Users.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  filterUsers: PropTypes.func.isRequired,
  getOnlyAsessors: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  getProfiles: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  getBuilders: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  profiles: PropTypes.array.isRequired,
  builders: PropTypes.array.isRequired,
  companies: PropTypes.array,
  resetPassword: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {users, profiles, builders, companies} = state;
  return {
    isLoading: users.sending,
    isLoadingProfile: profiles.sending,
    isLoadingBuilders: profiles.sending,
    users: users.users,
    builders: builders.builders,
    profiles: profiles.profiles,
    profile: profiles.profile,
    dataLength: users.dataLength,
    error: users.error,
    message: users.message,
    searchText: users.searchText,
    order: users.order,
    orderBy: users.orderBy,
    page: users.page,
    rowsPerPage: users.rowsPerPage,
    filters: users.filters,
    companies: companies.companies,
    asessors: users.asessors
  };
};

const mapDispatchToProps = {
  filterUsers,
  getOnlyAsessors,
  getProfiles,  
  getBuilders,
  getUser,
  createUser,
  editUser,
  resetPassword,
  getCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
