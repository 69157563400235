export const styles = (theme) => ({
  borderedCards: {
    padding: '30px 30px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
  },
  cardAdjusted: {
    padding: 0,
    height: '100%',
  },
  root: {
    flexGrow: 1,
    margin: 20,
  },
  root2: {
    flexGrow: 1,
    marginTop: '-1rem',
  },
  titleCard: {
    padding: '20px 20px 0px 20px',
  },
  itemList: {
    '& span': {
      fontSize: '1.1rem !important',
      lineHeight: '1.8',
      fontWeight: 500,
    },
  },
  itemValue: {
    width: '100%',
    height: '5px',
    borderRadius: '14px',
  },
  primaryBar: {
    background: theme.palette.primary.main,
  },
  secondaryBar: {
    background: theme.palette.secondary.main,
  },
  violetBar: {
    background: 'rgb(103, 58, 183)',
  },
  blueBar: {
    background: 'rgb(30, 136, 229)',
  },
  redBar: {
    background: 'rgb(244, 67, 54)',
  },
  greenBar: {
    background: 'rgb(0, 200, 83)',
  },
  blackBar: {
    background: 'rgb(41, 49, 79)',
  },
  mainIitemList: {
    paddingRight: '60px',
  },
  flexCard: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  flexCardTitle: {
    flex: 1,
    marginBottom: 10,
    '& h6': {
      fontWeight: '700',
    },
  },
  flexCardSubtitle: {
    flex: 1,
    fontWeight: 700,
    marginBottom: 10,
    color: 'black',
    '& h5': {
      marginBottom: '0px',
      fontSize: '1.5rem',
    },
  },
});

export default styles;
