/* eslint-disable no-param-reassign */
import axios from 'axios';
import * as projectsActions from './types/projects';
import { axiosMaker, errorMessage, logOutFunction } from '../utils/utils';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterProjects = (
  page,
  rowsPerPage,
  order,
  orderBy,
  searchText,
  filtersToApply,
) => async (dispatch) => {
  if (
    (searchText !== '' &&
      store.getState().projects.searchText !== searchText) ||
    (Object.keys(filtersToApply).length &&
      filtersToApply !== store.getState().projects.filters)
  ) {
    page = 0;
  }
  if (
    order !== store.getState().projects.order ||
    orderBy !== store.getState().projects.orderBy
  ) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
    filtersToApply = {};
  }
  dispatch({
    type: projectsActions.FILTER_PROJECTS_SENDING,
    payload: {
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
      filters: filtersToApply,
    },
  });
  try {
    if (company && company._id) {
      filtersToApply.company = [company._id];
    }
    const filters = filtersToApply;
    if (searchText !== '') {
      filters.name = searchText;
    }

    const data = {
      filters,
      operator: Object.keys(filtersToApply).length === 0 ? 'or' : 'and',
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order,
      },
      forAdmin: true,
    };
    const request = axiosMaker('POST', 'projects/filter', data, true);
    const response = await axios(request);

    return dispatch({
      type: projectsActions.FILTER_PROJECTS_SUCCESS,
      payload: {
        projects: response.data.results,
        dataLength: response.data.total,
      },
    });
  } catch (e) {
    const error = e.response;
    const { data } = e.response;
    if (data) {
      const { message } = data;
      if (message === 'jwt expired') {
        logOutFunction();
      }
    }

    return dispatch({
      type: projectsActions.FILTER_PROJECTS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getProjects = () => async (dispatch) => {
  dispatch({
    type: projectsActions.GET_PROJECTS_SENDING,
    payload: {
      dataLength: 0,
    },
  });

  const url =
    company && company._id ? `projects?company=${company._id}` : 'projects';

  try {
    const response = await axios(axiosMaker('GET', url, null, true));
    return dispatch({
      type: projectsActions.GET_PROJECTS_SUCCESS,
      payload: {
        projects: response.data.results,
        dataLength: response.data.results.length,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: projectsActions.GET_PROJECTS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getProjectNames = () => async (dispatch) => {
  dispatch({
    type: projectsActions.GET_PROJECTS_SENDING,
    payload: {
      dataLength: 0,
    },
  });

  const url =
    company && company._id ? `projects?company=${company._id}` : 'projects';

  try {
    const response = await axios(axiosMaker('GET', url, null, true));
    return dispatch({
      type: projectsActions.GET_PROJECTS_SUCCESS,
      payload: {
        projects: response.data.results,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: projectsActions.GET_PROJECTS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const createProject = (project) => async (dispatch) => {
  if (company && !project.company) project.company = company._id;

  dispatch({
    type: projectsActions.ADD_PROJECT_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('POST', 'projects', project, true));
    return dispatch({
      type: projectsActions.ADD_PROJECT_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: projectsActions.ADD_PROJECT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getProject = (id) => async (dispatch) => {
  dispatch({
    type: projectsActions.GET_PROJECT_SENDING,
    payload: {},
  });
  try {
    const response = await axios(
      axiosMaker('GET', `projects/${id}`, null, true),
    );
    return dispatch({
      type: projectsActions.GET_PROJECT_SUCCESS,
      payload: {
        project: response.data.results,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: projectsActions.GET_PROJECT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getProjectImages = (id) => async (dispatch) => {
  dispatch({
    type: projectsActions.GET_PROJECT_IMAGES_SENDING,
    payload: {},
  });
  try {
    const response = await axios(
      axiosMaker('GET', `projects/${id}/images`, null, true),
    );
    return dispatch({
      type: projectsActions.GET_PROJECT_IMAGES_SUCCESS,
      payload: {
        images: response.data.results,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: projectsActions.GET_PROJECT_IMAGES_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const editProject = (id, project) => async (dispatch) => {
  dispatch({
    type: projectsActions.EDIT_PROJECT_SENDING,
    payload: {},
  });
  try {
    if (project.revenue_percentage) {
      project.revenue_percentage = Number(project.revenue_percentage);
    }
    if (project.zone === null || project.zone === '') {
      delete project.zone;
    }
    const options = axiosMaker('PATCH', `projects/${id}`, project, true);
    const response = await axios(options);
    return dispatch({
      type: projectsActions.EDIT_PROJECT_SUCCESS,
      payload: {
        project: response.data,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: projectsActions.EDIT_PROJECT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const editProjectAttachments = (id, attachments) => async (dispatch) => {
  dispatch({
    type: projectsActions.EDIT_PROJECT_ATTACHMENTS_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker(
      'PATCH',
      `projects/${id}/attachments`,
      { attachments },
      true,
    );
    await axios(request);

    return dispatch({
      type: projectsActions.EDIT_PROJECT_ATTACHMENTS_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: projectsActions.EDIT_PROJECT_ATTACHMENTS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const areDisposable = async (id) => {
  try {
    const response = await axios(
      axiosMaker('POST', 'projects/areDisposable', { projects: [id] }, true),
    );
    return response.data.result;
  } catch (e) {
    return false;
  }
};

export const getProjectsNameByCompany = () => async (dispatch) => {
  dispatch({
    type: projectsActions.GET_PROJECTS_SENDING,
    payload: {
      dataLength: 0,
    },
  });

  const url = `projects/namesByCompany/${company && company._id}`;

  try {
    const response = await axios(axiosMaker('GET', url, null, true));
    return dispatch({
      type: projectsActions.GET_PROJECTS_SUCCESS,
      payload: {
        projects: response.data.results,
        dataLength: response.data.results.length,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: projectsActions.GET_PROJECTS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const cloneProject = (data) => async (dispatch) => {
  dispatch({
    type: projectsActions.CLONE_PROJECT_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('POST', 'projects/cloneProject', data, true);
    await axios(request);
    return true;
  } catch (e) {
    const error = e.response;
    dispatch({
      type: projectsActions.CLONE_PROJECT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
    return false;
  }
};

export const getProjectUnits = () => async (dispatch) => {
  dispatch({
    type: projectsActions.GET_PROJECT_UNITS_SENDING,
    payload: {
      dataLength: 0,
    },
  });

  try {
    const response = await axios(axiosMaker('GET', `getProjectUnits/${company._id}`, null, true));
    return dispatch({
      type: projectsActions.GET_PROJECT_UNITS_SUCCESS,
      payload: { projectUnits: response.data },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: projectsActions.GET_PROJECT_UNITS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};
