/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  Grid,
  Button,
  ListItem,
  Avatar,
  ListItemText,
  SvgIcon,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SearchIcon from '@material-ui/icons/Search';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import { getUnitImages } from '../../actions/units';
import { getProjectImages } from '../../actions/projects';
import { getLeadPdf } from '../../actions/leads';
import { getZones } from '../../actions/zones';
import { getUser } from '../../actions/security';
import placeholderImage from '../../assets/placeholder.png';
import { styles } from './styles';
import logo from '../../assets/logotrivo.png';
import slogan from '../../assets/slogan.png';
import help from '../../assets/help.png';
import { ME_DA_IGUAL, PROJECTS_URL, validTrivoCompany } from '../../utils/utils';
import { GET_PROJECT_IMAGES_SUCCESS } from '../../actions/types/projects';
import { GET_UNIT_IMAGES_SUCCESS } from '../../actions/types/units';
import CONSTANTS from '../../utils/constants';
import moment from 'moment-timezone';

export class LeadsPdfV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lead: null,
      isLoadingCurrent: true,
    };
    this.getZone = this.getZone.bind(this);
    this.renderAmenities = this.renderAmenities.bind(this);
    this.renderMatches = this.renderMatches.bind(this);
    this.renderNewMatches = this.renderNewMatches.bind(this);
    this.renderBrokerPhoto = this.renderBrokerPhoto.bind(this);
    this.handleGetProjectImages = this.handleGetProjectImages.bind(this);
    this.print = this.print.bind(this);
  }

  async componentWillMount() {
    const { match } = this.props;
    if (match) {
      if (match.params) {
        if (match.params.id) {
          await this.props.getZones();
          await this.props.getLeadPdf(match.params.id);
          await this.props.getUser(localStorage.getItem('user'));
          await this.handleGetProjectImages();
        }
      }
    }
  }

  // get ZONE
  getZone(id) {
    const { zones } = this.props;
    if (id) {
      return zones.filter((obj) => obj.id === id)[0].name;
    }
    return null;
  }

  renderRooms = (roomCount) => {
    if (roomCount) {
      return roomCount == 1 ? `${roomCount} recámara` : `${roomCount} recámaras`;
    }
    return '';
  };

  print = () => {
    setTimeout(() => {
       window.print();  // uncomment to test print
    }, 5000);
    return null;
  };

  async handleGetProjectImages() {
    const { lead } = this.props;
    if (lead) {
      if (lead.matches) {
        if (lead.matches.length > 0) {
          for (let i = 0; i < lead.matches.length; i += 1) {
            const match = lead.matches[i];
            await this.props.getProjectImages(match._id).then((result) => {
              if (result.type === GET_PROJECT_IMAGES_SUCCESS) {
                const { images } = result.payload;
                match.images = images;
              }
            });
            if (match.units) {
              if (match.units.length > 0) {
                await this.props.getUnitImages(match.units[0]._id).then((result) => {
                  if (result.type === GET_UNIT_IMAGES_SUCCESS) {
                    const { images } = result.payload;
                    // eslint-disable-next-line no-param-reassign
                    match.units[0].images = images;
                  }
                });
              }
            }
            // eslint-disable-next-line no-param-reassign
            lead.matches[i] = match;
          }
          this.setState({ lead, isLoadingCurrent: false });
        }
      }
    }
  }

  renderBrokerPhoto() {
    const { classes, user } = this.props;
    if (user) {
      if (user.profile_img) {
        return <img src={user.profile_img} alt="trivo" className={classes.profileBrokerImg} />;
      }
    }
    return <img src={placeholderImage} alt="trivo" className={classes.profileBrokerImg} />;
  }

  renderNewBrokerPhoto() {
    const { classes, user } = this.props;
    if (user) {
      if (user.profile_img) {
        return <img src={user.profile_img} alt="trivo" className={classes.profileNewBrokerImg} />;
      }
    }
    return <img src={placeholderImage} alt="trivo" className={classes.profileNewBrokerImg} />;
  }

  // eslint-disable-next-line class-methods-use-this
  renderAmenities(amenitiesArray) {
    const newAmenitiesArray = [];
    amenitiesArray.forEach((amenitie) => {
      if (amenitie !== ME_DA_IGUAL) {
        newAmenitiesArray.push(amenitie);
      }
    });
    return newAmenitiesArray.length > 0 ? newAmenitiesArray.join(', ') : '-';
  }

  renderNewMatches() {
    //pdf v2
    const { classes, user } = this.props;
    const { lead } = this.state;
    const company = JSON.parse(localStorage.getItem('company'));
    if (lead && user) {
      if (lead.matches) {
        if (lead.matches.length > 0) {
          return lead.matches.map((project, index) => {
            const unitToShow = project.units[0];
            const projectImagesToShow = project.images || [];
            const imagesToShow = unitToShow.images || [];
            const pdf = project.brochure ? project.brochure.url : null;
            const fixMargin = (cardIndex) => {
              if (cardIndex < 4 && cardIndex !== 3) return 0;
              if (cardIndex === 3) return 100;
              return 220;
            };
            //const dynamicHeigth = index === lead.matches.length - 1 ? 1800 : 1800;
            const dynamicHeigth =
              (index === lead.matches.length) === 0
                ? 2000
                : index === lead.matches.length - 1
                ? 2000
                : 2000;

            return (
              <div style={{ height: dynamicHeigth, marginTop: fixMargin(index) }} key={index}>
                <div className={classes.divContainer}>
                  <div className={classes.div1}>
                    <div>
                      <img
                        src={company && company.layout.logo ? company.layout.logo : logo}
                        alt="trivo"
                        className={classes.newLogo}
                      />
                      {/*  {company && company.layout.slogan && (
                    <img src={company.layout.slogan} alt="trivo" className={classes.slogan} />
                  )} */}
                    </div>
                  </div>
                  <div className={classes.div2}>
                    <div className={classes.flexContainer}>
                      <div className={classes.flexItems}>
                        <div>
                          <div
                            className={classes.itemBordered}
                          >{`${user.name} ${user.lastname}`}</div>
                          <div className={classes.itemBordered}>Teléfono: {user.phone}</div>
                        </div>
                        <div>
                          <div className={classes.itemBordered2}>
                            Fecha: {moment().format(`DD-MM-YYYY`)}
                          </div>
                        </div>
                      </div>
                      <div>{this.renderNewBrokerPhoto()}</div>
                    </div>
                  </div>
                  <div className={classes.div3}>
                    <div>
                      {project.projectLogo && (
                        <img src={project.projectLogo} alt="trivo" className={classes.newLogo} />
                      )}
                      {/*  {company && company.layout.slogan && (
                    <img src={company.layout.slogan} alt="trivo" className={classes.slogan} />
                  )} */}
                    </div>
                  </div>
                </div>
                {/*  {index > 0 && index !== lead.matches.length - 1 && (
                  <div className={classes.spaceItem}></div>
                )} */}
                <div className={classes.sectionDivider}> </div>
                <div className={classes.rowContainer}>
                  <div className={classes.featuredProjectItem}>
                    <div className={classes.simpleFlex}>
                      <div className={classes.div4}>
                        {index === 0 && (
                          <p>
                            Hola {lead.user.name}, a continuación te presentamos la propiedad que
                            más se ajusta a tus requerimientos:
                            <br />
                          </p>
                        )}

                        <p>
                          <span className={classes.boldItem}>Desarrollo:</span>
                          <br />
                          {project.name}
                        </p>

                        <p>
                          <span className={classes.boldItem}>Ubicación:</span>
                          <br />
                          {this.getZone(project.zone)}
                        </p>
                        <p>
                          <span className={classes.boldItem}> Fecha de entrega:</span>
                          <br />
                          {project.finish_date || '-'}
                        </p>
                        <p className={classes.tinyText}>
                          <span className={classes.boldItem}> Nota: </span>
                          <span>
                            Las imágenes y planos son referenciales y durante la construcción pueden
                            variar
                          </span>
                          <br />
                        </p>
                      </div>
                      <div className={classes.image1}>
                        <img
                          src={
                            projectImagesToShow[0] ? projectImagesToShow[0].url : placeholderImage
                          }
                          alt="match unit"
                          className={classes.image1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.featuredProjectSecondary}>
                    <img
                      src={projectImagesToShow[1] ? projectImagesToShow[1].url : placeholderImage}
                      alt="match unit"
                      className={classes.image2}
                    />
                  </div>
                </div>
                <div className={classes.sectionDivider}> </div>
                <div className={classes.rowContainer}>
                  <div className={classes.featuredProjectItem2}>
                    <div className={classes.simpleFlex}>
                      <div className={classes.div5}>
                        <div className={classes.div6}>
                          <p className={classes.titleUnitType}>
                            {CONSTANTS.UNIT_TYPES_NAMES[unitToShow.typeName]
                              ? CONSTANTS.UNIT_TYPES_NAMES[unitToShow.typeName]
                              : unitToShow.typeName}{' '}
                            {this.renderRooms(unitToShow.rooms)}
                          </p>
                          <hr className={classes.simpleDivisor}></hr>
                          <p className={classes.simpleP}>
                            {' '}
                            <span>
                              <SearchIcon />
                            </span>{' '}
                            Unidad: {unitToShow.name}
                          </p>
                          <p className={classes.simpleP}>
                            <span>
                              {' '}
                              <SvgIcon className='icon' >
                              <path d="M6 12v3h12v-3h-12zM8.016 6.984v3h3v-3h-3zM15.984 9.984v-3h-3v3h3zM20.016 12v5.016h-1.359l-0.656 1.969h-0.984l-0.656-1.969h-8.672l-0.703 1.969h-0.984l-0.656-1.969h-1.359v-5.016q0-0.797 0.609-1.406t1.406-0.609v-3q0-0.797 0.609-1.383t1.406-0.586h7.969q0.797 0 1.406 0.586t0.609 1.383v3q0.797 0 1.406 0.609t0.609 1.406z"></path>
                              </SvgIcon>
                            </span>{' '}
                            Área Inmueble: {`${unitToShow.area}m2` || '-'}
                          </p>
                          <p className={classes.simpleP}>
                            <span>
                              <DriveEtaIcon />
                            </span>{' '}
                            Estacionamientos: {unitToShow.parkCount || '-'}
                          </p>
                          <hr className={classes.simpleDivisor}></hr>
                          <p className={classes.simpleP}>
                            Valor total:{' '}
                            <span
                              className={classes.boldItem}
                            >{`$ ${unitToShow.price.toLocaleString('de-DE')}`}</span>
                            <br />
                            Financiamiento: <span className={classes.boldItem}>Personalizado </span>
                          </p>
                        </div>
                        <div className={classes.div7}>
                          <img
                            src={
                              projectImagesToShow[2] ? projectImagesToShow[2].url : placeholderImage
                            }
                            alt="match unit"
                            className={classes.image_3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.featuredProjectSecondary2}>
                    <div className={classes.headerCard}>Dimensiones</div>
                    <div className={classes.cardContent}>
                      {!!unitToShow.areaCubierta && (
                        <p className={classes.simpleP}>
                          Área interna:{' '}
                          <span className={classes.boldItem}>{`${unitToShow.areaCubierta}m2`}</span>
                        </p>
                      )}
                      {!!unitToShow.balconArea && (
                        <p className={classes.simpleP}>
                          Balcón:{' '}
                          <span className={classes.boldItem}>{`${unitToShow.balconArea}m2`}</span>
                        </p>
                      )}
                      {!!unitToShow.totaloAreaCubierta && (
                        <p className={classes.simpleP}>
                          Área total:{' '}
                          <span className={classes.boldItem}>
                            {unitToShow.totaloAreaCubierta}m2
                          </span>
                        </p>
                      )}
                      {!!unitToShow.externalArea && (
                        <p className={classes.simpleP}>
                          Área Terraza/Patio:{' '}
                          <span className={classes.boldItem}>{`${unitToShow.externalArea}m2`}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.sectionDivider}> </div>
                <div className={classes.fullColorRwContainer}>
                  <div className={classes.colorTitle}>Amenidades </div>
                  <div className={classes.amenitiesContent}>
                    {' '}
                    {this.renderAmenities(project.amenidades)}
                  </div>
                </div>
                <div className={classes.sectionDivider}> </div>
                <div className={classes.rowContainer}>
                  <div className={classes.imageUnitContainer}>
                    {imagesToShow.length > 0 ? (
                      <img
                        src={imagesToShow[0].url}
                        alt="match unit"
                        className={classes.newMatchImage}
                      />
                    ) : (
                      <img
                        src={placeholderImage}
                        alt="match unit"
                        className={classes.newMatchImage}
                      />
                    )}
                  </div>
                </div>

                {unitToShow.comments.length > 0 && unitToShow.comments[0].comment && (
                  <div>
                    <div className={classes.sectionDivider}> </div>
                    <div className={classes.fullColorRwContainer2}>
                      <div className={classes.amenitiesContent2}>
                        {unitToShow.comments[0].comment}
                      </div>
                    </div>
                  </div>
                )}

                <div className={classes.sectionDivider}> </div>
                <div className={classes.rowContainer2}>
                  {validTrivoCompany() && (
                    <Button
                      component={Link}
                      href={
                        project.code
                          ? `${PROJECTS_URL}propiedades/${project.code}`
                          : `${PROJECTS_URL}`
                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      underline="none"
                    >
                      <InfoIcon className={classes.buttonIcon} />
                      Ver más información
                    </Button>
                  )}
                  {pdf && (
                    <Button
                      component={Link}
                      href={pdf}
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      underline="none"
                    >
                      <InsertDriveFileIcon className={classes.buttonIcon} />
                      Descargar Brochure
                    </Button>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: 20,
                    marginTop: 40,
                    paddingTop: 30,
                    display: 'flex',
                  }}
                ></div>
              </div>
            );
          });
        }
      }
    }
    return null;
  }

  renderMatches() {
    const { classes, user } = this.props;
    const { lead } = this.state;
    if (lead && user) {
      if (lead.matches) {
        if (lead.matches.length > 0) {
          return lead.matches.map((project, index) => {
            const unitToShow = project.units[0];
            const projectImagesToShow = project.images || [];
            const imagesToShow = unitToShow.images || [];
            const pdf = project.brochure ? project.brochure.url : null;
            const fixMargin = (cardIndex) => {
              if (cardIndex < 4 && cardIndex !== 3) return 0;
              if (cardIndex === 3) return 100;
              return 220;
            };
            const dynamicHeigth = index === lead.matches.length - 1 ? 550 : 500;
            return (
              <div style={{ height: dynamicHeigth, marginTop: fixMargin(index) }} key={index}>
                <div> empecemos</div>
                <div
                  style={{
                    marginBottom: 20,
                    marginTop: 40,
                    paddingTop: 30,
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      width: '46%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Divider className={classes.fullWidthDivider} />
                  </div>
                  <div
                    style={{
                      width: '8%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1" className={classes.projectCounterText}>
                      {index + 1}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: '46%',
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <Divider className={classes.fullWidthDivider} />
                  </div>
                </div>
                <div key={index} className={classes.matchContainer}>
                  <div className={classes.leftSide}>
                    <div style={{ height: 480 }}>
                      {imagesToShow.length > 0 ? (
                        <img
                          src={imagesToShow[0].url}
                          alt="match unit"
                          className={classes.matchImage}
                        />
                      ) : (
                        <img
                          src={placeholderImage}
                          alt="match unit"
                          className={classes.matchImage}
                        />
                      )}
                      {validTrivoCompany() && (
                        <Typography className={classes.fontSize}>
                          Unidad: {`TRIVO-${unitToShow.identifier}` || ''}
                        </Typography>
                      )}
                    </div>
                    <div className={classes.imagesContainer}>
                      <img
                        src={projectImagesToShow[0] ? projectImagesToShow[0].url : placeholderImage}
                        alt="match unit"
                        className={classes.infoImage}
                      />
                      <img
                        src={projectImagesToShow[1] ? projectImagesToShow[1].url : placeholderImage}
                        alt="match unit"
                        className={classes.infoImage}
                      />
                    </div>
                    <div className={classes.description}>
                      <Typography className={classes.projectDescription}>
                        {project.description}
                      </Typography>
                      <div className={classes.gridRoot}>
                        <Grid container spacing={0}>
                          {!validTrivoCompany() && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Proyecto"
                                  secondary={project.name}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          {!validTrivoCompany() && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Unidad"
                                  secondary={unitToShow.name}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Tipo"
                                secondary={unitToShow.typeName}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Localidad"
                                secondary={this.getZone(project.zone)}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Área total"
                                secondary={`${unitToShow.area} m2` || '-'}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Fecha de entrega"
                                secondary={project.finish_date || '-'}
                              />
                            </ListItem>
                          </Grid>
                          {unitToShow.rooms && unitToShow.rooms !== '' && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Recámaras"
                                  secondary={unitToShow.rooms || '-'}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          {unitToShow.parkCount && unitToShow.parkCount !== '' && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Estacionamientos"
                                  secondary={unitToShow.parkCount || '-'}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          {!!unitToShow.externalArea && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Terraza/jardín"
                                  secondary={`${unitToShow.externalArea} m2`}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          {!!unitToShow.balconArea && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Balcón"
                                  secondary={`${unitToShow.balconArea} m2`}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridTextPrice}
                                primary="Precio"
                                secondary={`$ ${unitToShow.price.toLocaleString('de-DE')}`}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={12}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Amenidades"
                                secondary={this.renderAmenities(project.amenidades)}
                              />
                            </ListItem>
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                      {unitToShow.comments &&
                        unitToShow.comments.length > 0 &&
                        unitToShow.comments[unitToShow.comments.length - 1].comment &&
                        unitToShow.comments[unitToShow.comments.length - 1].comment !== '' && (
                          <Typography className={classes.commentText}>
                            <span>Comentario: </span>
                            {unitToShow.comments[unitToShow.comments.length - 1].comment}
                          </Typography>
                        )}
                      {validTrivoCompany() && (
                        <Button
                          component={Link}
                          //  href={`${PROJECTS_URL}${project._id}?unitId=${unitToShow._id}`}
                          href={
                            project.code
                              ? `${PROJECTS_URL}propiedades/${project.code}`
                              : `${PROJECTS_URL}`
                          }
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          underline="none"
                        >
                          <InfoIcon className={classes.buttonIcon} />
                          Ver más información
                        </Button>
                      )}
                      {pdf && (
                        <Button
                          component={Link}
                          href={pdf}
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          underline="none"
                        >
                          <InsertDriveFileIcon className={classes.buttonIcon} />
                          Descargar Brochure
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        }
      }
    }
    return null;
  }

  render() {
    const {
      classes,
      user,
      isLoading,
      isLoadingProjects,
      isLoadingUser,
      isLoadingZones,
      isLoadingUnit,
    } = this.props;
    const { lead, isLoadingCurrent } = this.state;
    const company = JSON.parse(localStorage.getItem('company'));
    if (
      lead === null ||
      user === null ||
      isLoading === true ||
      isLoadingCurrent === true ||
      isLoadingProjects === true ||
      isLoadingUser === true ||
      isLoadingZones === true ||
      isLoadingUnit === true
    ) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }

    const firstTextFooter =
      'Indícale a tu asesor tu criterio sobre las opciones para ir a conocer las que más te convencen!';
    const secondTextFooter =
      'Recuerda que trabajar con trivo no tiene costo y te garantizamos las mejores condiciones de compra con cada constructor.';

    return (
      <div>
        {this.print()}
        <div className={classes.container}>{this.renderNewMatches()}</div>
        {/* <div className={classes.customFooter}>
          <Typography variant="body1" className={classes.footer}>
            {company && company._id === CONSTANTS.TRIVO_COMPANY_ID
              ? `${firstTextFooter} ${secondTextFooter}`
              : firstTextFooter}
          </Typography>
          <img className={classes.tipImage} src={help} alt="trivo" />
        </div> */}
      </div>
    );
  }
}

LeadsPdfV2.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingUnit: PropTypes.bool.isRequired,
  isLoadingProjects: PropTypes.bool.isRequired,
  isLoadingUser: PropTypes.bool.isRequired,
  isLoadingZones: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  getLeadPdf: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getZones: PropTypes.func.isRequired,
  getProjectImages: PropTypes.func.isRequired,
  getUnitImages: PropTypes.func.isRequired,
  zones: PropTypes.array.isRequired,
  match: PropTypes.object,
  lead: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { leads, zones, units, user, projects } = state;
  return {
    isLoading: leads.sending,
    isLoadingUser: user.sending,
    isLoadingUnit: units.sending,
    isLoadingZones: zones.sending,
    isLoadingProjects: projects.sending,
    user: user.user,
    lead: leads.lead,
    zones: zones.zones,
  };
};

const mapDispatchToProps = {
  getLeadPdf,
  getUser,
  getZones,
  getUnitImages,
  getProjectImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeadsPdfV2));