export const styles = (theme) => ({
  attributesContainer: {
    width: '90%',
    margin: '0 auto',
  },
  inlineItem: {
    display: 'inline-block',
  },
  footerContained: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginBottom: '20px',
    marginTop: '10px',
    justifyContent:"space-evenly"
  },
  containerModal:{
    minWidth: '400px',
    padding: '20px'
  },
  textField:{
    width:'100%'
  }
});

export default styles;
