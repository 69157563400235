import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  TextField,
  Paper,
  Toolbar,
  Switch,
  FormControlLabel,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {
  createLeadState,
  updateLeadState
} from '../../../../actions/leadStates';

const LeadStateDetail = (props) => {
  const [leadStateDetail, setLeadState] = useState({
    active: true,
    name: ''
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const {isNew, leadState} = props;
    if (!isNew) {
      setLeadState(leadState);
    }
  }, []);

  const onAddButtonClick = async () => {
    const {name} = leadStateDetail;
    await dispatch(createLeadState({name}));
    props.handleGoBack();
  };

  const onEditButtonClick = async () => {
    const {leadState} = props;
    const {name, active} = leadStateDetail;
    const data = {};

    if (leadState.name !== name) data.name = name;
    if (leadState.active !== active) data.active = active;

    await dispatch(updateLeadState(leadState.id, data));
    props.handleGoBack();
  };

  const isInvalid = () => {
    const {name} = leadStateDetail;
    if (name !== '') {
      return false;
    }
    return true;
  };

  const renderButton = () => {
    const {isLoading, isNew, classes} = props;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText={`¿Está seguro que desea ${
            isNew ? 'crear' : 'modificar'
          } este estado de lead?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} estado de lead`}
          variant="contained"
          isDisabled={isInvalid()}
          confirmAction={isNew ? onAddButtonClick : onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  };

  const {
    classes,
    isLoading,
    isNew,
    handleGoBack
  } = props;

  return isLoading === true ? (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto'
      }}
    />
  ) : (
    <Paper elevation={2} className={classes.paperRoot}>
      <Toolbar>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">
          {!isNew ? 'Detalle de ' : 'Nuevo '} estado de lead
        </Typography>
      </Toolbar>
      <div style={{width: 'auto', overflowX: 'scroll'}}>
        <Table>
          <TableBody>
            {!isNew && (
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Disponible</b>
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={leadStateDetail.active}
                        onChange={e =>
                          setLeadState({
                            ...leadStateDetail,
                            active: e.target.checked
                          })
                        }
                        color="primary"
                      />
                    }
                    label={
                      leadStateDetail.active === true
                        ? 'Disponible'
                        : 'No disponible'
                    }
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Nombre</b>
              </TableCell>
              <TableCell>
                <TextField
                  autoFocus
                  value={leadStateDetail.name}
                  onChange={e =>
                    setLeadState({
                      ...leadStateDetail,
                      name: e.target.value
                    })
                  }
                  fullWidth
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={classes.submitContainer}>{renderButton()}</div>
    </Paper>
  );
};

LeadStateDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  leadState: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired
};

export default withStyles(styles)(LeadStateDetail);
