/* eslint-disable no-param-reassign */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export class DoubleCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerId: []
    };
    this.renderAnswers = this.renderAnswers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answerId} = currentAnswer;
      if (answerId.length > 0) {
        this.setState({answerId});
      }
    }
  }

  checkSelection = (options) => {
    const {answerId} = this.state;
    if (answerId.includes(options.answerId)) {
      return true;
    } else if (options.sonChecked) {
      return true;
    }
    return false;
  }

  handleChange(options) {
    const value = options.answerId;
    const {answerId} = this.state;
    const {identifier} = this.props;
    if (options.father === null) {
      if (options.sonChecked === true) {
        for (let i = 0; i < options.sons.length; i += 1) {
          const son = options.sons[i];
          const sonValue = son.answerId.toString();
          const index = answerId.indexOf(sonValue);
          if (index > -1) {
            answerId.splice(index, 1);
          }
        }
        options.sonChecked = false;
      } else {
        for (let i = 0; i < options.sons.length; i += 1) {
          const son = options.sons[i];
          answerId.push(son.answerId);
        }
      }
    } else if (answerId.includes(value)) {
      const index = answerId.indexOf(value);
      if (index > -1) {
        answerId.splice(index, 1);
      }
    } else {
      answerId.push(value);
    }
    this.setState({answerId});
    this.props.updateAnswer(identifier, answerId, '');
  }

  renderFullAnswers = () => {
    const allAnswers = this.props.answers;
    const currentAnswers = this.state.answerId;

    const fatherAnswers = allAnswers.filter(obj => obj.father === null).map((obj) => {
      const zone = obj;
      const sons = allAnswers.filter(ob => ob.father === obj.catId); 
      zone.sons = sons;
      zone.sonChecked = false;
      for (let i = 0; i < currentAnswers.length; i += 1) {
        const ans = currentAnswers[i];
        for (let j = 0; j < sons.length; j += 1) {
          const son = sons[j];
          if (son.answerId === ans) {
            zone.sonChecked = true;
          }
        }
      }
      return zone;
    });
    const fullAnswers = [];
    fatherAnswers.forEach((answer) => {
      fullAnswers.push(answer);
      if (answer.sonChecked === true) {
        answer.sons.forEach((sonAnswer) => {
          fullAnswers.push(sonAnswer);
        });
      }
    });
    return fullAnswers;
  };

  renderAnswers() {
    const {permissions} = this.props;
    const fullAnswers = this.renderFullAnswers();
    return fullAnswers.map((options, index) => {
      return (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              className="checkBoxInput"
              checked={this.checkSelection(options)}
              onChange={() => this.handleChange(options)}
              value={options.answerId}
              disabled={permissions === false}
            />
          }
          label={options.value}
          style={options.father !== null ? {marginLeft: 40} : {marginLeft: 0}}
        />
      );
    });  
  }

  render() {
    return (
      <FormGroup>
        {this.renderAnswers()}
      </FormGroup>
    );
  }
}
DoubleCheckList.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    answerId: PropTypes.string
  })).isRequired,
  permissions: PropTypes.bool,
  updateAnswer: PropTypes.func,
  currentAnswer: PropTypes.object,
  identifier: PropTypes.string
};

export default DoubleCheckList;
