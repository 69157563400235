import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment-timezone';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { Chip, InputAdornment, Input, Grid } from '@material-ui/core';
import { styles } from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import ColorPickerButton from '../../../common/colorPickerButton';
import Uploader from '../../../common/uploader';
import verifyIdentification from '../../../../utils/validations/validateIdentification';
import {
  DEFAULT_DOMAIN,
  getIdentifier,
  compareMultipleSelect,
  isEmail,
} from '../../../../utils/utils';
import {
  filterCompanyBuilders,
  areDisposable,
} from '../../../../actions/builders';
import CustomSnackbar from '../../../common/customSnackbar';
import CollapsableZones from '../../../common/collapsableZones';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

export class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      name: '',
      businessReason: '',
      identification: '',
      primaryColor: props.theme.palette.primary.main,
      secondaryColor: props.theme.palette.secondary.main,
      domain: DEFAULT_DOMAIN,
      createdBy: '',
      updatedBy: '',
      createdAt: '',
      updatedAt: '',
      identificationType: { id: '', name: '' },
      logo: null,
      slogan: null,
      domainStatus: false,
      openDomainDialog: false,
      isNameInvalid: false,
      builders: [],
      isDisposable: true,
      brochureText: '',
      hasTrivoSmart: false,
      plan: null,
      planError: null,
      subscribedEmails: [],
      addSubEmail: '',
      addSubEmailError: null,
      configuredSectors: [],
      plusvaliaCode: '',
      properatiCode: '',
      avalMessages: { enabled: false },
    };
    this.renderButton = this.renderButton.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
    this.handleDomainClose = this.handleDomainClose.bind(this);
    this.handleDomainSuccess = this.handleDomainSuccess.bind(this);
    this.handleDomainStatus = this.handleDomainStatus.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.handleChangeBuilders = this.handleChangeBuilders.bind(this);
    this.renderDisposableSnackBar = this.renderDisposableSnackBar.bind(this);
    this.handleBrochureTextChange = this.handleBrochureTextChange.bind(this);
    this.handleSloganUploaded = this.handleSloganUploaded.bind(this);
    this.handleAddSubEmail = this.handleAddSubEmail.bind(this);
    this.handleChangePlan = this.handleChangePlan.bind(this);
  }

  componentWillMount() {
    const { isNew, company } = this.props;
    this.props.filterCompanyBuilders(company.id);
    if (!isNew) {
      this.setState({
        company,
        name: company.name,
        status: company.status,
        businessReason: company.businessReason,
        identification: company.identification,
        primaryColor: company.layout.colors[0],
        secondaryColor: company.layout.colors[1],
        domain: company.configuration.smtp.domain,
        domainStatus: company.configuration.smtp.status,
        createdBy: company.createdBy.email,
        updatedBy: company.updatedBy.email,
        createdAt: ` el ${moment(company.createdAt).format(
          'LL',
        )} a las ${moment(company.createdAt).format('HH:mm')}`,
        updatedAt: ` el ${moment(company.updatedAt).format(
          'LL',
        )} a las ${moment(company.updatedAt).format('HH:mm')}`,
        identificationType: company.identificationType,
        logo:
          company.layout.logo && company.layout.logo.length > 0
            ? company.layout.logo
            : null,
        slogan:
          company.layout.slogan && company.layout.slogan.length > 0
            ? company.layout.slogan
            : null,
        brochureText: company.layout.brochureText,
        hasTrivoSmart: company.hasTrivoSmart,
        plan: company.plan,
        subscribedEmails: company.subscribedEmails
          ? company.subscribedEmails.map((email, index) => ({
              key: index,
              email,
            }))
          : [],
        configuredSectors: company.configuredSectors,
        plusvaliaCode: company.plusvaliaCode,
        properatiCode: company.properatiCode,
        avalMessages: company.avalMessages,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { builders } = nextProps;
      this.setState({
        builders: builders
          ? [
              ...new Set([
                ...this.state.builders,
                ...builders.filter((doc) => doc.company).map((doc) => doc.id),
              ]),
            ]
          : [],
      });
    }
  }

  onAddButtonClick() {
    const {
      name,
      businessReason,
      identificationType,
      identification,
      primaryColor,
      secondaryColor,
      logo,
      domain,
      domainStatus,
      builders,
      slogan,
      brochureText,
      hasTrivoSmart,
      plan,
      subscribedEmails,
      configuredSectors,
      plusvaliaCode,
      properatiCode
    } = this.state;
    const newCompany = {
      name,
      businessReason,
      identificationType: identificationType.id,
      identification,
      layout: {
        colors: [primaryColor, secondaryColor],
        logo,
        slogan,
        brochureText,
      },
      configuration: {
        smtp: {
          domain,
          status: domainStatus,
        },
      },
      identifier: getIdentifier(name),
      builders,
      hasTrivoSmart,
      plan,
      subscribedEmails: subscribedEmails.map(({ email }) => email),
      configuredSectors,
      plusvaliaCode,
      properatiCode,
    };

    this.props.handleCreateCompany(newCompany).then(() => {
      this.handleGoBack();
    });
  }

  // eslint-disable-next-line consistent-return
  async onEditButtonClick() {
    const {
      company,
      status,
      name,
      businessReason,
      identificationType,
      identification,
      primaryColor,
      secondaryColor,
      logo,
      domain,
      domainStatus,
      builders,
      slogan,
      brochureText,
      hasTrivoSmart,
      plan,
      subscribedEmails,
      configuredSectors,
      plusvaliaCode,
      properatiCode,
      avalMessages
    } = this.state;

    const buildersToCheck = this.props.builders
      .filter((doc) => doc.company)
      .map((doc) => doc.id)
      .filter((x) => builders.indexOf(x) === -1);

    if (buildersToCheck.length > 0) {
      const isDisposable = await areDisposable(buildersToCheck);
      if (!isDisposable) {
        return this.setState({
          isDisposable: false,
          builders: [...builders, ...buildersToCheck],
        });
      }
    }

    const companyEdited = {
      id: company.id,
      status,
      name,
      businessReason,
      identificationType,
      identification,
      layout: {
        colors: [primaryColor, secondaryColor],
        logo,
        slogan,
        brochureText,
      },
      configuration: {
        smtp: {
          domain,
          status: domainStatus,
        },
      },
      buildersChanges: compareMultipleSelect(this.props.builders, builders),
      hasTrivoSmart,
      plan,
      subscribedEmails: subscribedEmails.map(({ email }) => email),
      configuredSectors,
      plusvaliaCode,
      properatiCode,
      avalMessages
    };

    this.props.handleUpdateCompany(companyEdited);
  }

  handleDomainStatus(e) {
    const { checked } = e.target;
    if (checked) this.setState({ openDomainDialog: e.target.checked });
    else this.setState({ domainStatus: false, domain: DEFAULT_DOMAIN });
  }

  handleDomainClose() {
    this.setState({ domainStatus: false, openDomainDialog: false });
    this.setState({
      domainStatus: false,
      openDomainDialog: false,
      domain: DEFAULT_DOMAIN,
    });
  }

  handleDomainSuccess() {
    this.setState({ domainStatus: true, openDomainDialog: false, domain: '' });
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  handleChangeBuilders(e) {
    this.setState({ builders: e.target.value });
  }

  handleDeleteChip(data) {
    const newEmails = this.state.subscribedEmails
      .filter((item) => item.key !== data.key)
      .map(({ email }, key) => ({ email, key }));

    this.setState({
      subscribedEmails: newEmails,
    });

    const { numberOfEmails } = this.props.plans.find(
      (x) => x.id === this.state.plan,
    );

    if (newEmails.length <= numberOfEmails) {
      this.setState({
        planError: null,
      });
    }
  }

  isInvalid() {
    const {
      name,
      businessReason,
      identification,
      identificationType,
      domain,
      isNameInvalid,
      planError,
    } = this.state;

    if (
      name !== '' &&
      businessReason !== '' &&
      identification !== '' &&
      identificationType.id !== '' &&
      domain !== '' &&
      !isNameInvalid &&
      !planError
    ) {
      return false;
    }
    return true;
  }

  handleUrlUploaded(logo) {
    this.setState({ logo: logo || null });
  }

  handleSloganUploaded(slogan) {
    this.setState({ slogan: slogan || null });
  }

  handleAddSubEmail() {
    const { addSubEmail, subscribedEmails, plan } = this.state;
    const { plans } = this.props;

    const { numberOfEmails } = plans.find((x) => x.id === plan);

    if (subscribedEmails.length === numberOfEmails) {
      return this.setState({
        addSubEmailError: 'Su plan no admite más emails',
      });
    }

    if (isEmail(addSubEmail)) {
      return this.setState({
        subscribedEmails: [
          ...subscribedEmails,
          { key: subscribedEmails.length, email: addSubEmail },
        ],
        addSubEmail: '',
        addSubEmailError: null,
      });
    }

    return this.setState({ addSubEmailError: 'El email no es válido' });
  }

  // este
  handleChangePlan({ target }) {
    const { subscribedEmails } = this.state;
    const { plans } = this.props;
    const { numberOfEmails } = plans.find((x) => x.id === target.value);

    this.setState({ plan: target.value });

    if (subscribedEmails.length > numberOfEmails) {
      this.setState({
        planError: `El plan admite solamente ${numberOfEmails} ${
          numberOfEmails > 1 ? 'emails' : 'email'
        }`,
      });
    } else {
      this.setState({
        planError: null,
      });
    }
  }

  handleNameChange(e) {
    const name = e.target.value;
    this.setState({ name, isNameInvalid: false });
  }

  handleBrochureTextChange(e) {
    const brochureText = e.target.value;
    this.setState({ brochureText });
  }

  async handleIdentifierChange() {
    const identifier = getIdentifier(this.state.name);
    if (identifier === '') {
      this.setState({ isNameInvalid: false });
    } else {
      const isNameInvalid = await this.props.handleValidateCompany(
        this.props.company.id,
        identifier,
      );
      this.setState({ isNameInvalid });
    }
  }

  renderDisposableSnackBar() {
    const { isDisposable } = this.state;
    if (!isDisposable) {
      this.setState({ isDisposable: true });
      return (
        <CustomSnackbar
          variant="error"
          message="No se puede cambiar la compañía porque la constructora tiene leads dependientes"
        />
      );
    }
    return null;
  }

  renderButton() {
    const { isLoading, isNew, classes } = this.props;

    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText={`¿Está seguro que desea ${
            isNew ? 'crear' : 'modificar'
          } esta empresa?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} empresa`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderConfiguration() {
    const { classes, isNew } = this.props;
    const idValidation = !verifyIdentification(
      this.state.identification,
      this.state.identificationType.id,
    );
    return (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de la ' : 'Nueva '} empresa
          </Typography>
        </Toolbar>
        <Table>
          <TableBody>
            {!isNew && (
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Disponible</b>
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.status}
                        onChange={(e) =>
                          this.setState({ status: e.target.checked })
                        }
                        color="primary"
                      />
                    }
                    label={
                      this.state.status === true
                        ? 'Disponible'
                        : 'No disponible'
                    }
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Nombre</b>
              </TableCell>
              <TableCell>
                <TextField
                  autoFocus
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  onBlur={this.handleIdentifierChange}
                  error={this.state.isNameInvalid}
                  helperText={
                    this.state.isNameInvalid && 'El nombre no es válido'
                  }
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Identificador</b>
              </TableCell>
              <TableCell>
                <TextField
                  autoFocus
                  disabled
                  value={getIdentifier(this.state.name)}
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Razón social</b>
              </TableCell>
              <TableCell>
                <TextField
                  autoFocus
                  value={this.state.businessReason}
                  onChange={(e) =>
                    this.setState({ businessReason: e.target.value })
                  }
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Tipo de identificación:</b>
              </TableCell>
              <TableCell>
                <Select
                  value={this.state.identificationType.id}
                  name={this.state.identificationType.name}
                  onChange={(e) =>
                    this.setState({
                      identificationType: {
                        id: e.target.value,
                        name: e.target.name,
                      },
                    })
                  }
                  fullWidth
                >
                  {this.props.identificationTypes.map(
                    (identificationType, index) => (
                      <MenuItem value={identificationType.id} key={index}>
                        {identificationType.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </TableCell>
            </TableRow>
            {this.state.identificationType.id !== '' && (
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Identificación</b>
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    autoFocus
                    value={this.state.identification}
                    onChange={(e) =>
                      this.setState({ identification: e.target.value })
                    }
                    fullWidth
                    error={idValidation}
                    helperText={idValidation && 'Identificación no válida'}
                  />
                </TableCell>
              </TableRow>
            )}
            {this.renderBuilders()}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderBrochureConfiguration() {
    const { classes } = this.props;
    const { slogan, brochureText } = this.state;
    return (
      <Paper
        elevation={2}
        className={classes.paperRoot}
        style={{ paddingBottom: 0 }}
      >
        <Toolbar>
          <Typography variant="h6">Configuración de brochure</Typography>
        </Toolbar>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Texto de bienvenida de brochure</b>
              </TableCell>
              <TableCell>
                <div style={{ padding: 20 }}>
                  <TextField
                    variant="outlined"
                    value={brochureText}
                    fullWidth
                    placeholder="Nuevo texto de bienvenida..."
                    multiline
                    rows={2}
                    onChange={this.handleBrochureTextChange}
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Foto de eslogan</b>
              </TableCell>
              <TableCell>
                {slogan && (
                  <img src={slogan} alt="Foto" style={{ width: 100 }} />
                )}
                <Uploader
                  uploaderId="uploader-slogan"
                  buttonText={slogan ? 'Actualizar foto' : 'Subir foto'}
                  photo
                  dirName="Companies-Slogan"
                  urlUploaded={this.handleSloganUploaded}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderThemeConfiguration() {
    const { classes } = this.props;
    return (
      <Paper
        elevation={2}
        className={classes.paperRoot}
        style={{ paddingBottom: 0 }}
      >
        <Toolbar>
          <Typography variant="h6">Configuración de tema</Typography>
        </Toolbar>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Foto</b>
              </TableCell>
              <TableCell>
                {this.state.logo && (
                  <img
                    src={this.state.logo}
                    alt="Foto"
                    style={{ width: 100 }}
                  />
                )}
                <Uploader
                  uploaderId="uploader-logo"
                  buttonText={
                    this.state.logo ? 'Actualizar foto' : 'Subir foto'
                  }
                  photo
                  dirName="Companies"
                  urlUploaded={this.handleUrlUploaded}
                />
              </TableCell>
              <TableCell className={classes.tableRowTitle}>
                <b>Color primario</b>
              </TableCell>
              <TableCell>
                <ColorPickerButton
                  name="primaryColorPopover"
                  color={this.state.primaryColor}
                  onChangeColor={(color) =>
                    this.setState({ primaryColor: color })
                  }
                />
              </TableCell>
              <TableCell className={classes.tableRowTitle}>
                <b>Color secundario</b>
              </TableCell>
              <TableCell>
                <ColorPickerButton
                  name="secondaryColorPopover"
                  color={this.state.secondaryColor}
                  onChangeColor={(color) =>
                    this.setState({ secondaryColor: color })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderPlansConfiguration() {
    const { classes } = this.props;

    return (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <Typography variant="h6">
            Configuración de plan de suscripción
          </Typography>
        </Toolbar>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Tipo de plan:</b>
              </TableCell>
              <TableCell>
                <Select
                  value={this.state.plan}
                  onChange={this.handleChangePlan}
                  fullWidth
                  error={!!this.state.planError}
                >
                  {this.props.plans.map((item, index) => (
                    <MenuItem value={item.id} key={`plan_${index}`}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {!!this.state.planError && (
                  <Grid>
                    <Typography className={classes.inputError}>
                      {this.state.planError}
                    </Typography>
                  </Grid>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Trivo Smart</b>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.hasTrivoSmart}
                      onChange={(e) =>
                        this.setState({ hasTrivoSmart: e.target.checked })
                      }
                      color="primary"
                    />
                  }
                  label={
                    this.state.hasTrivoSmart ? 'Habilitado' : 'No habilitado'
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Emails suscritos</b>
              </TableCell>
              <TableCell>
                <Grid container style={{ flexGrow: 1 }} spacing={24}>
                  <Grid item xs={4}>
                    <Grid>
                      <Input
                        value={this.state.addSubEmail}
                        onChange={({ target }) =>
                          this.setState({
                            addSubEmail: target.value,
                            addSubEmailError: null,
                          })
                        }
                        placeholder="Agregar email"
                        error={!!this.state.addSubEmailError}
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={this.handleAddSubEmail}
                              className={classes.noOutline}
                            >
                              <AddRoundedIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    {!!this.state.addSubEmailError && (
                      <Grid>
                        <Typography className={classes.inputError}>
                          {this.state.addSubEmailError}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={8}>
                    <Paper
                      elevation={0}
                      component="ul"
                      className={classes.chipContainer}
                    >
                      {this.state.subscribedEmails.map((data) => {
                        return (
                          <li key={data.key}>
                            <Chip
                              label={data.email}
                              onDelete={() => this.handleDeleteChip(data)}
                              className={classes.chip}
                            />
                          </li>
                        );
                      })}
                    </Paper>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderZonesConfiguration() {
    const { classes } = this.props;

    return (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <Typography variant="h6">Configuración de Zonas</Typography>
        </Toolbar>
        <CollapsableZones
          level="countries"
          checkedSectors={this.state.configuredSectors}
          onCheckSector={(value) => this.setState({ configuredSectors: value })}
        />
      </Paper>
    );
  }

  renderEmailConfiguration() {
    const { classes } = this.props;
    return (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <Typography variant="h6">Configuración de email</Typography>
        </Toolbar>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Habilitar</b>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.domainStatus}
                      onChange={this.handleDomainStatus}
                      color="primary"
                    />
                  }
                  label={
                    this.state.domainStatus === true
                      ? 'Habilitado'
                      : 'No habilitado'
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Dominio</b>
              </TableCell>
              <TableCell>
                <TextField
                  autoFocus
                  disabled={!this.state.domainStatus}
                  value={this.state.domain}
                  onChange={(e) => this.setState({ domain: e.target.value })}
                  fullWidth
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Dialog
          open={this.state.openDomainDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleDomainClose}
        >
          <DialogTitle>
            ¿Está seguro que desea actualizar el dominio?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              El dominio debe estar registrado previamente en Amazon SES para
              que se válido
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDomainClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleDomainSuccess} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }

  renderExternalIntegrations() {
    const { classes } = this.props;
    return (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <Typography variant="h6">Integraciones</Typography>
        </Toolbar>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Código Plusvalía</b>
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.plusvaliaCode}
                  onChange={(e) =>
                    this.setState({ plusvaliaCode: e.target.value })
                  }
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Código Properati</b>
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.properatiCode}
                  onChange={(e) =>
                    this.setState({ properatiCode: e.target.value })
                  }
                  fullWidth
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderAvalMessages() {
    const { classes } = this.props;
    const { avalMessages } = this.state;
    const { noInformation, reject, underReview, approved } =
      avalMessages.messages || {};

    const setMessage = ({ target }) =>
      this.setState({
        avalMessages: {
          ...avalMessages,
          messages: {
            ...(avalMessages.messages || {}),
            [target.name]: target.value,
          },
        },
      });

    return (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <Typography variant="h6">
            Mensajes personalizados para créditos preaprobados
          </Typography>
        </Toolbar>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Crédito sin información</b>
              </TableCell>
              <TableCell>
                <TextField
                  name="noInformation"
                  value={noInformation || ''}
                  onChange={setMessage}
                  fullWidth
                  placeholder="Aprobación del crédito sujeto a revisión de un analista."
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Crédito rechazado</b>
              </TableCell>
              <TableCell>
                <TextField
                  name="reject"
                  value={reject || ''}
                  onChange={setMessage}
                  fullWidth
                  placeholder="Su crédito está en análisis, por favor acérquese a xxx"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Crédito en revisión</b>
              </TableCell>
              <TableCell>
                <TextField
                  name="underReview"
                  value={underReview || ''}
                  onChange={setMessage}
                  fullWidth
                  placeholder="Aprobación del crédito sujeto a revisión de un analista"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Crédito aprobado</b>
              </TableCell>
              <TableCell>
                <TextField
                  name="approved"
                  value={approved || ''}
                  onChange={setMessage}
                  fullWidth
                  placeholder="Su crédito ha sido preaprobado"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderSelectValue() {
    const { classes, builders } = this.props;
    console.log("this.state.builders",this.state.builders);
    const buildersFiltered = builders.filter((builder) =>
      this.state.builders.includes(builder.id),
    );
    return buildersFiltered.map((builder, index) => (
      <div key={index}>
        <Chip label={builder.name.toString()} className={classes.chip} />
      </div>
    ));
  }

  renderBuilders() {
    const { classes, builders } = this.props;
    return (
      <TableRow>
        <TableCell className={classes.tableRowTitle}>
          <b>Constructoras</b>
        </TableCell>
        <TableCell>
          <Select
            multiple
            value={this.state.builders}
            onChange={this.handleChangeBuilders}
            renderValue={() => this.renderSelectValue()}
            fullWidth
            className={classes.brokerSelect}
          >
            {builders.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
      </TableRow>
    );
  }

  renderUserInfo() {
    const { classes, isNew } = this.props;
    return (
      <Paper
        elevation={2}
        className={classes.paperRoot}
        style={{ paddingBottom: 0 }}
      >
        {!isNew && (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Creación:</b>
                </TableCell>
                <TableCell>
                  <Typography>
                    Creado por{' '}
                    <strong className={classes.highlightColor}>
                      {this.state.createdBy}
                    </strong>{' '}
                    {this.state.createdAt}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Última actialización:</b>
                </TableCell>
                <TableCell>
                  <Typography>
                    Actualizado por{' '}
                    <strong className={classes.highlightColor}>
                      {this.state.updatedBy}
                    </strong>{' '}
                    {this.state.updatedAt}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Paper>
    );
  }

  render() {
    const { classes, isLoading } = this.props;
    return isLoading === true ? (
      <CircularProgress
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
      />
    ) : (
      <div>
        {this.renderDisposableSnackBar()}
        {this.renderConfiguration()}
        {this.renderBrochureConfiguration()}
        {this.renderThemeConfiguration()}
        {this.renderPlansConfiguration()}
        {this.renderZonesConfiguration()}
        {this.renderEmailConfiguration()}
        {this.renderExternalIntegrations()}
        {this.state.avalMessages.enabled && this.renderAvalMessages()}
        {this.renderUserInfo()}
        <div className={classes.submitContainer}>{this.renderButton()}</div>
      </div>
    );
  }
}

CompanyDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleCreateCompany: PropTypes.func.isRequired,
  handleValidateCompany: PropTypes.func.isRequired,
  handleUpdateCompany: PropTypes.func.isRequired,
  identificationTypes: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  builders: PropTypes.array.isRequired,
  filterCompanyBuilders: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { builders } = state;
  return {
    builders: builders.builders,
  };
};

const mapDispatchToProps = {
  filterCompanyBuilders,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(CompanyDetails));
