import * as smartDemandFilters from '../actions/types/smartDemandFilters';

const currentDate = new Date();
let defaultEndDate = new Date();
defaultEndDate.setMonth(defaultEndDate.getMonth() - 1);

const initialState = {
  project: [],
  zone: [],
  cities: [],
  room: [],
  unitType: [],
  startDate: defaultEndDate,
  endDate: new Date(),
  chipData: [
    {
      key: 0,
      type: 'date',
      label: `${defaultEndDate.toLocaleDateString()} - ${currentDate.toLocaleDateString()}`,
    },
  ],
  globalFilter: false,
  filters: {
    startDate: defaultEndDate,
    endDate: new Date(),
    company:
      JSON.parse(localStorage.getItem('company')) &&
      JSON.parse(localStorage.getItem('company'))._id,
  },
  disabledFilter: true,
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {
    project,
    zone,
    cities,
    room,
    unitType,
    startDate,
    endDate,
    chipData,
    globalFilter,
    filters,
    disabledFilter,
  } = action.payload;
  switch (action.type) {
    case smartDemandFilters.SET_PROJECT:
      return { ...state, project };
    case smartDemandFilters.SET_ZONE:
      return { ...state, zone };
    case smartDemandFilters.SET_CITIES:
      return { ...state, cities };
    case smartDemandFilters.SET_ROOM:
      return { ...state, room };
    case smartDemandFilters.SET_UNIT_TYPE:
      return { ...state, unitType };
    case smartDemandFilters.SET_START_DATE:
      return { ...state, startDate };
    case smartDemandFilters.SET_END_DATE:
      return { ...state, endDate };
    case smartDemandFilters.SET_CHIP_DATA:
      return { ...state, chipData };
    case smartDemandFilters.SET_GLOBAL_FILTER:
      return { ...state, globalFilter };
    case smartDemandFilters.SET_FILTERS:
      return { ...state, filters, disabledFilter: true };
    case smartDemandFilters.SET_DISABLE_FILTER:
      return { ...state, disabledFilter };
    default:
      return state;
  }
};
