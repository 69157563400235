import * as emailTemplatesActions from '../actions/types/emailTemplates';

const initialState = {
  emailTemplates: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'title',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {emailTemplates, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case emailTemplatesActions.FILTER_EMAIL_TEMPLATES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case emailTemplatesActions.FILTER_EMAIL_TEMPLATES_SUCCESS:
    return {...state, sending: false, emailTemplates, error: null, message: 'Plantillas parar correo obtenidas satisfactoriamente', dataLength};
  case emailTemplatesActions.FILTER_EMAIL_TEMPLATES_FAILED:
    return {...state, sending: false, emailTemplates: [], error, message, dataLength};
  case emailTemplatesActions.GET_EMAIL_TEMPLATES_SENDING:
    return {...state, sending: true};
  case emailTemplatesActions.GET_EMAIL_TEMPLATES_SUCCESS:
    return {...state, sending: false, emailTemplates, error: null, message: 'Plantillas de correo obtenidas satisfactoriamente', dataLength};
  case emailTemplatesActions.GET_EMAIL_TEMPLATES_FAILED:
    return {...state, sending: false, emailTemplates: [], error, message, dataLength};
  case emailTemplatesActions.CREATE_EMAIL_TEMPLATE_SENDING:
    return {...state, sending: true};
  case emailTemplatesActions.CREATE_EMAIL_TEMPLATE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Plantilla de correo creada satisfactoriamente'};
  case emailTemplatesActions.CREATE_EMAIL_TEMPLATE_FAILED:
    return {...state, sending: false, error, message};
  case emailTemplatesActions.UPDATE_EMAIL_TEMPLATE_SENDING:
    return {...state, sending: true};
  case emailTemplatesActions.UPDATE_EMAIL_TEMPLATE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Plantilla de correo actualizada satisfactoriamente'};
  case emailTemplatesActions.UPDATE_EMAIL_TEMPLATE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
