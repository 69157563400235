import React, {Component} from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {InlineDatePicker} from 'material-ui-pickers';
import {styles} from './styles';
import {dateFormat} from '../../../utils/utils';

export class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      placeholder: ''
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStartOver = this.handleStartOver.bind(this);
  }

  componentWillMount() {
    const {isBirthdate, currentAnswer} = this.props;
    this.handleStartOver(isBirthdate);  
    if (currentAnswer) {
      const {answer} = currentAnswer;
      if (answer !== '') {
        this.setState({date: answer});
      }
    }
  }

  handleDateChange(date) {
    const {identifier} = this.props;
    this.setState({date});
    this.props.updateAnswer(identifier, [], moment(date).format(dateFormat));
  }

  handleStartOver(isBirthdate) {
    if (isBirthdate === true) {
      const date = null;
      const placeholder = '';
      this.setState({placeholder, date});
    } else {
      const placeholder = '';
      this.setState({placeholder, date: new Date()});
    }
  }

  render() {
    const {classes, isBirthdate, permissions} = this.props;
    const {date} = this.state;
    return isBirthdate === true ? (
      <InlineDatePicker 
        value={date} 
        fullWidth
        onChange={this.handleDateChange} 
        disableFuture
        clearable
        views={['year', 'month']}
        openToYearSelection
        format={dateFormat}
        autoOk
        className={classes.datePicker}
        disabled={!permissions}
      />
    )
      :
      (
        <InlineDatePicker 
          value={date} 
          fullWidth
          clearable
          onChange={this.handleDateChange} 
          label={this.state.placeholder}
          format={dateFormat}
          disabled={!permissions}
          className={classes.datePicker}
        />
      );
  }
}
DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  isBirthdate: PropTypes.bool,
  permissions: PropTypes.bool,
  updateAnswer: PropTypes.func.isRequired,
  identifier: PropTypes.string,
  currentAnswer: PropTypes.object
};

export default withStyles(styles)(DatePicker);
