import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { styles } from './styles';
import Proforma from '../../../proformas/proforma';
import { useForm } from './../../../../hooks/useForm';
import { getProformas } from '../../../../actions/proformas';
import { ADMIN_ID } from './../../../../utils/utils';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const profileId = localStorage.getItem('profileId');
const isAdmin = profileId === ADMIN_ID;

const DialogProformas = (props) => {
  const { classes, onClose, lead, broker, project } = props;

  const dispatch = useDispatch();
  const { proformas, sending } = useSelector((state) => state.proformas);

  const [formValues, handleInputChange, changeManualState] = useForm();
  const [unitValues, handleUnitChange, changeUnitManualState] = useForm();

  const [actualUnit, setActualUnit] = useState();
  const [versionProformedUnits, setVersionProformedUnits] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [currentVersion, setCurrentVersion] = useState();
  const [brokers, setBrokers] = useState([]);
  const [brokerSelected, setBrokerSelected] = useState();
  const [filteredProformas, setFilteredProformas] = useState([]);
  const [projectName, setProjectName] = useState('');

  useEffect(() => {
    dispatch(getProformas(project, lead, broker, isAdmin));
  }, []);

  useEffect(() => {
    if (proformas.length > 0) {
      if (isAdmin) {
        setBrokers(
          Object.values(
            proformas.reduce((acc, { broker: { _id, name, lastname } }) => {
              if (acc[_id]) return acc;
              acc[_id] = { id: _id, name: `${name} ${lastname}`.trim() };
              return acc;
            }, {}),
          ),
        );
      }
      setBrokerSelected(isAdmin ? proformas[0].broker._id : proformas[0].broker);
      setProjectName(proformas[0].projectName)
    }
  }, [proformas]);

  useEffect(() => {
    const array = isAdmin
      ? proformas.filter((item) => item.broker._id === brokerSelected)
      : proformas.filter((item) => item.broker === brokerSelected);
    setFilteredProformas(array);
  }, [brokerSelected]);

  useEffect(() => {
    if (filteredProformas.length > 0)
      setCurrentVersion([...filteredProformas].reverse()[0].version);
  }, [filteredProformas]);

  useEffect(() => {
    if (brokers.length > 0) setBrokerSelected(brokers[0].id);
  }, [brokers]);

  useEffect(() => {
    if (currentVersion) {
      changeManualState(
        filteredProformas.find((item) => item.version === currentVersion)
          .customer,
      );
      const unitsArray = filteredProformas
        .find((item) => item.version === currentVersion)
        .units.map((unit) => ({
          id: unit.id || '',
          name: unit.name || '',
          coveredArea: unit.coveredArea || '',
          coveredAreaPrice: unit.coveredAreaPrice || '',
          gardenOrTerraceArea: unit.gardenOrTerraceArea || '',
          gardenOrTerraceAreaPrice: unit.gardenOrTerraceAreaPrice || '',
          totalArea: unit.totalArea || '',
          totalPrice: unit.totalPrice || 0,
          warehouseValue: unit.warehouseValue || '',
          rooms: unit.rooms || '',
          parkingSpaces: unit.parkingSpaces || '',
          parkingSpacesPrice: unit.parkingSpacesPrice || '',
          observations: unit.observations || '',
          order: unit.order || 0,
          builderFinancingPercentage:
            unit.quotation.payInformation.builderFinancingPercentage || '',
          builderFinancingValue:
            unit.quotation.payInformation.builderFinancingValue || '',
          entryPercentage: unit.quotation.payInformation.entryPercentage || '',
          entryValue: unit.quotation.payInformation.entryValue || '',
          entryDate:
            unit.quotation.payInformation.entryDate || moment(new Date()),
          interestPercentage:
            unit.quotation.payInformation.interestPercentage || '',
          reservePercentage:
            unit.quotation.payInformation.reservePercentage || '',
          reserveValue: unit.quotation.payInformation.reserveValue || '',
          reserveDate:
            unit.quotation.payInformation.reserveDate || moment(new Date()),
          totalQuotes: unit.quotation.data.totals.totalQuotes,
          creditBalancePercentage:
            unit.quotation.data.totals.creditBalancePercentage,
          creditBalanceValue: unit.quotation.data.totals.creditBalanceValue,
          content: unit.quotation.data.content,
          quotationObservations: unit.quotationObservations || '',
          purchaseIntention: unit.purchaseIntention || '',
          deadline: unit.deadline || null,
        }));
      setVersionProformedUnits(unitsArray);
      setActualUnit(unitsArray[0].id);
    }
  }, [currentVersion]);

  useEffect(() => {
    if (actualUnit) {
      const proformedUnit = versionProformedUnits.find(
        (x) => x.id === actualUnit,
      );
      changeUnitManualState(proformedUnit);
    } else if (
      !actualUnit &&
      versionProformedUnits &&
      versionProformedUnits.length > 0
    ) {
      setActualUnit(versionProformedUnits[0].id);
    }
  }, [actualUnit, versionProformedUnits]);

  const onNext = () => {
    const array = [...versionProformedUnits];
    array[unitValues.order] = unitValues;
    setActualUnit(
      versionProformedUnits.find((x) => x.order === unitValues.order + 1).id,
    );
  };

  const renderTabs = () => {
    return versionProformedUnits.map((unit) => (
      <Tab value={unit.id} key={unit.id} label={`Unidad ${unit.name}`} />
    ));
  };

  const renderTabContent = () => {
    let tabContent = '';
    versionProformedUnits.filter((unit) => {
      if (unit.id === actualUnit) {
        tabContent = (
          <div key={unit.id}>
            <Proforma
              formValues={formValues}
              handleInputChange={handleInputChange}
              unit={unit}
              unitValues={unitValues}
              handleUnitChange={handleUnitChange}
              currentVersion={true}
              setQuotationInvalid={() => {}}
              setPayFormInvalid={() => {}}
            />
          </div>
        );
      }
    });
    return tabContent;
  };

  const isLastTab = () =>
    versionProformedUnits &&
    versionProformedUnits.length > 0 &&
    versionProformedUnits[versionProformedUnits.length - 1].id === actualUnit;

  return filteredProformas && filteredProformas.length > 0 ? (
    <Dialog
      fullScreen
      disableBackdropClick
      disableEscapeKeyDown
      open
      onClose={onClose}
      TransitionComponent={Transition}
      className={classes.muiDialog}
    >
      <DialogTitle className={classes.titleProformas}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>{`Proformas - ${projectName}`}</Grid>
          <Grid item>
            <Grid container>
              {isAdmin && brokers.length > 0 && (
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={classes.textFieldSelect}
                  >
                    <InputLabel
                      classes={{ shrink: classes.inputShrink }}
                      className={classes.labelForm}
                      htmlFor="nationality"
                    >
                      Asesor
                    </InputLabel>
                    <Select
                    style={{width: 200}}
                      value={brokerSelected}
                      onChange={({ target: { value } }) =>
                        setBrokerSelected(value)
                      }
                      input={
                        <OutlinedInput
                          className={classes.selectInputForm}
                          labelWidth={0}
                        />
                      }
                    >
                      {brokers.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item>
                <Button
                  onClick={() => setDrawer(true)}
                  className={classes.iconButton}
                  variant="contained"
                  color="primary"
                >
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold', color: 'white' }}
                  >
                    {`v${currentVersion}`}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Tabs
          className={classes.tabs}
          value={actualUnit}
          onChange={(_, id) => setActualUnit(id)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {renderTabs()}
        </Tabs>
        {renderTabContent()}
      </DialogContent>
      <DialogActions className={classes.footerActions}>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        {!isLastTab() && (
          <Button onClick={onNext} variant="contained" color="primary">
            Siguiente
          </Button>
        )}
      </DialogActions>
      <Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => setDrawer(false)}
          onKeyDown={() => setDrawer(false)}
        >
          <div className={classes.list}>
            <List>
              {[...filteredProformas]
                .reverse()
                .map(({ version, createdAt }) => (
                  <ListItem
                    button
                    onClick={() => setCurrentVersion(version)}
                    key={`version_${version}`}
                  >
                    <ListItemText
                      primary={`v${version}`}
                      secondary={moment(createdAt).format(
                        'DD-MMM-YYYY  h:mm a',
                      )}
                      className={
                        version === currentVersion ? classes.currentVersion : ''
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </div>
        </div>
      </Drawer>
      {sending && (
        <div className={classes.loaderBackdrop}>
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      )}
    </Dialog>
  ) : (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    />
  );
};

DialogProformas.propTypes = {
  classes: PropTypes.object.isRequired,
  lead: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(DialogProformas);
