import {createMuiTheme} from '@material-ui/core/styles';

const company = JSON.parse(localStorage.getItem('company'));
const [primaryColor, secondaryColor] = company ? company.layout.colors : ['#006064', '#00363a'];

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Poppins',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});

export default theme;
