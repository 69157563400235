import * as ruleActions from '../actions/types/rules';

const initialState = {
  rules: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: '',
  filters: {}
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {rules, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText, filters} = action.payload;
  switch (action.type) {
  case ruleActions.FILTER_RULES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength, filters};
  case ruleActions.FILTER_RULES_SUCCESS:
    return {...state, sending: false, rules, error: null, message: 'Reglas obtenidas satisfactoriamente', dataLength};
  case ruleActions.FILTER_RULES_FAILED:
    return {...state, sending: false, rules: [], error, message, dataLength};
  case ruleActions.GET_RULES_SENDING:
    return {...state, sending: true};
  case ruleActions.GET_RULES_SUCCESS:
    return {...state, sending: false, rules, error: null, message: 'Reglas obtenidas satisfactoriamente', dataLength};
  case ruleActions.GET_RULES_FAILED:
    return {...state, sending: false, rules: [], error, message, dataLength};
  case ruleActions.CREATE_RULE_SENDING:
    return {...state, sending: true};
  case ruleActions.CREATE_RULE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Regla creada satisfactoriamente'};
  case ruleActions.CREATE_RULE_FAILED:
    return {...state, sending: false, error, message};
  case ruleActions.UPDATE_RULE_SENDING:
    return {...state, sending: true};
  case ruleActions.UPDATE_RULE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Regla actualizada satisfactoriamente'};
  case ruleActions.UPDATE_RULE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
