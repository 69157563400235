import * as leadActions from '../actions/types/leads';

const initialState = {
  leads: [],
  leadNames: [],
  lead: null,
  leadId: null,
  message: '',
  dataLength: 0,
  sending: false,
  sendingSearch: false,
  sendingLead: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  isEditingPreferences: false,
  orderBy: 'default',
  searchText: '',
  filters: {},
  matches: [],
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {
    leads,
    leadNames,
    isEditingPreferences,
    leadId,
    lead,
    error,
    message,
    dataLength,
    page,
    rowsPerPage,
    order,
    orderBy,
    searchText,
    filters,
    matches,
  } = action.payload;
  switch (action.type) {
    case leadActions.FILTER_LEADS_SENDING:
      return {
        ...state,
        sending: true,
        page,
        rowsPerPage,
        order,
        orderBy,
        searchText,
        filters,
        dataLength,
      };
    case leadActions.FILTER_LEADS_SUCCESS:
      return {
        ...state,
        sending: false,
        leads,
        error: null,
        message: 'Leads obtenidos satisfactoriamente',
        dataLength,
      };
    case leadActions.FILTER_LEADS_FAILED:
      return {
        ...state,
        sending: false,
        leads: [],
        error,
        message,
        dataLength,
      };
    case leadActions.SEARCH_LEADS_SENDING:
      return { ...state, sendingSearch: true, searchText };
    case leadActions.SEARCH_LEADS_SUCCESS:
      return {
        ...state,
        sendingSearch: false,
        leadNames,
        error: null,
        message: 'Leads obtenidos satisfactoriamente',
      };
    case leadActions.SEARCH_LEADS_FAILED:
      return { ...state, sendingSearch: false, leadNames: [], error, message };
    case leadActions.GET_LEADS_SENDING:
      return { ...state, sending: true };
    case leadActions.GET_LEADS_SUCCESS:
      return {
        ...state,
        sending: false,
        leads,
        error: null,
        message: 'Leads obtenidos satisfactoriamente',
        dataLength,
      };
    case leadActions.GET_LEADS_FAILED:
      return {
        ...state,
        sending: false,
        leads: [],
        error,
        message,
        dataLength,
      };
    case leadActions.GET_BROKER_LEADS_SENDING:
      return { ...state, sending: true };
    case leadActions.GET_BROKER_LEADS_SUCCESS:
      return {
        ...state,
        sending: false,
        leads,
        error: null,
        message: 'Leads obtenidos satisfactoriamente',
        dataLength,
      };
    case leadActions.GET_BROKER_LEADS_FAILED:
      return {
        ...state,
        sending: false,
        leads: [],
        error,
        message,
        dataLength,
      };
    case leadActions.GET_LEAD_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.GET_LEAD_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        lead,
        error: null,
        message: 'Lead obtenido satisfactoriamente',
        matches,
      };
    case leadActions.SET_LEAD_SUCCESS:
      return { ...state, sendingLead: false, lead, message: '', error: null };
    case leadActions.GET_LEAD_FAILED:
      return { ...state, sendingLead: false, lead: null, error, message };
    case leadActions.CHANGE_UNIT_COMMENT_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.CHANGE_UNIT_COMMENT_SUCCESS:
      return { ...state, sendingLead: false, lead };
    case leadActions.CHANGE_UNIT_COMMENT_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.CREATE_LEAD_SENDING:
      return { ...state, sending: true };
    case leadActions.CREATE_LEAD_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        leadId,
        message: 'Lead creado satisfactoriamente',
      };
    case leadActions.CREATE_LEAD_FAILED:
      return { ...state, sending: false, error, message };
    case leadActions.CREATE_LEADS_BULK_SENDING:
      return { ...state, sending: true };
    case leadActions.CREATE_LEADS_BULK_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        message: 'Leads creados satisfactoriamente',
      };
    case leadActions.CREATE_LEADS_BULK_FAILED:
      return { ...state, sending: false, error, message };
    case leadActions.UPDATE_LEAD_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.UPDATE_LEAD_SUCCESS:
      const newMatches = matches && {...state.lead, matches} || {...state.lead};
      return {
        ...state,
        isEditingPreferences,
        sendingLead: false,
        error: null,
        message: 'Lead actualizado satisfactoriamente',
        lead: newMatches
      };
    case leadActions.UPDATE_LEAD_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.UPDATE_LEAD_USER_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.UPDATE_LEAD_USER_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Lead actualizado satisfactoriamente',
      };
    case leadActions.UPDATE_LEAD_USER_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.UPDATE_LEAD_MATCHES_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.UPDATE_LEAD_MATCHES_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Coincidencias del lead obtenidas satisfactoriamente',
        matches,
        lead,
      };
    case leadActions.UPDATE_LEAD_MATCHES_FAILED:
      return { ...state, sendingLead: false, error, message, matches: null };
    case leadActions.REMOVE_MATCH_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.REMOVE_MATCH_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Coincidencia del lead eliminada satisfactoriamente',
        matches,
        lead: {...state.lead, matches}
      };
    case leadActions.REMOVE_MATCH_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.BLOCK_MATCH_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.BLOCK_MATCH_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Coincidencia del lead bloqueada satisfactoriamente',
        matches,
        lead: {...state.lead, matches}
      };
    case leadActions.BLOCK_MATCH_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.UNBLOCK_MATCH_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.UNBLOCK_MATCH_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Coincidencia del lead desbloqueada satisfactoriamente',
        matches,
        lead: {...state.lead, matches}
      };
    case leadActions.UNBLOCK_MATCH_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.DUPLICATE_MATCH_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.DUPLICATE_MATCH_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Proyecto duplicado satisfactoriamente',
        matches,
        lead: {...state.lead, matches}
      };
    case leadActions.DUPLICATE_MATCH_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.SWAP_UNIT_MATCH_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.SWAP_UNIT_MATCH_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Coincidencia del lead reordenada satisfactoriamente',
        matches,
        lead: {...state.lead, matches},
      };
    case leadActions.SWAP_UNIT_MATCH_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.SWAP_MATCH_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.SWAP_MATCH_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Coincidencia del lead reordenada satisfactoriamente',
        matches,
        lead: {...state.lead, matches},
      };
    case leadActions.SWAP_MATCH_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.ADD_UNIT_MATCH_COMMENT_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.ADD_UNIT_MATCH_COMMENT_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Coincidencia del lead actualizada satisfactoriamente',
        matches,
      };
    case leadActions.ADD_UNIT_MATCH_COMMENT_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.CHANGE_PROJECT_MATCH_STAGE_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.CHANGE_PROJECT_MATCH_STAGE_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Coincidencia del lead actualizada satisfactoriamente',
        matches,
      };
    case leadActions.CHANGE_PROJECT_MATCH_STAGE_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.ADD_LEAD_MATCH_SENDING:
      return { ...state, sending: true };
    case leadActions.ADD_LEAD_MATCH_SUCCESS:
      return {
        ...state,
        sending: false,
        error: null,
        message: 'Unidad asignada satisfactoriamente',
        matches,
      };
    case leadActions.ADD_LEAD_MATCH_FAILED:
      return { ...state, sending: false, error, message };
    case leadActions.ADD_LEAD_ACTION_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.ADD_LEAD_ACTION_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Acción del lead agregada satisfactoriamente',
        matches,
      };
    case leadActions.ADD_LEAD_ACTION_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.REASSIGN_LEAD_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.REASSIGN_LEAD_SUCCESS:
      return {
        ...state,
        sendingLead: false,
        error: null,
        message: 'Lead reasignado satisfactoriamente',
        matches,
      };
    case leadActions.REASSIGN_LEAD_FAILED:
      return { ...state, sendingLead: false, error, message };
    case leadActions.SWITCH_EDITING_LEADS:
      return { ...state, isEditingPreferences };
    case leadActions.BULK_LEAD_ASSIGN_SENDING:
      return { ...state, sendingLead: true };
    case leadActions.BULK_LEAD_ASSIGN_SUCCESS:
      return {
        ...state,
        isEditingPreferences,
        sendingLead: false,
        error: null,
        message: 'Leads reasignados satisfactoriamente',
      };
    case leadActions.BULK_LEAD_ASSIGN_FAILED:
      return { ...state, sendingLead: false, error, message };
    default:
      return state;
  }
};
