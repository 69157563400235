import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import IconsGrid from './iconsGrid';
import TypesDialog from './typesDialog';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {copyObject, SUPER_ADMIN_ROL_ID} from '../../../../utils/utils';

const profileStoreId = localStorage.getItem('profileId');
const invalidSuperAdmin = SUPER_ADMIN_ROL_ID !== profileStoreId;

export class ParametersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      name: '',
      icon: null,
      inputType: '',
      options: null,
      identifier: '',
      propertyType: [],
      enableWizard: false,
      enableWeb: false,
      active: false,
      isCatalog: false,
      catalogType: '',
      isForMatch: false,
      isMatchField: false,
      enabled: false
    };
    this.renderButton = this.renderButton.bind(this);
    this.editParameter = this.editParameter.bind(this);
    this.addParameter = this.addParameter.bind(this);
    this.renderCategory = this.renderCategory.bind(this);
    this.renderActiveOption = this.renderActiveOption.bind(this);
    this.renderPropertyType = this.renderPropertyType.bind(this);
    this.renderCatalogOptions = this.renderCatalogOptions.bind(this);
    this.handleIsCatalogChange = this.handleIsCatalogChange.bind(this);
    this.handleChangePropertyType = this.handleChangePropertyType.bind(this);
    this.handleChangeCatalogType = this.handleChangeCatalogType.bind(this);
    this.switchActive = this.switchActive.bind(this);
    this.switchWizard = this.switchWizard.bind(this);
    this.switchWeb = this.switchWeb.bind(this);
    this.renderIdentifier = this.renderIdentifier.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.chooseIcon = this.chooseIcon.bind(this);
    this.renderType = this.renderType.bind(this);
    this.verifyDisabled = this.verifyDisabled.bind(this);
  }

  componentWillMount() {
    const {parameter, isNew} = this.props;
    if (!isNew) {     
      const parameterCopy = copyObject(parameter);
      this.setState({
        parameter,
        identifier: parameterCopy.identifier ? parameterCopy.identifier : '',
        title: parameterCopy.title ? parameterCopy.title : '',
        name: parameterCopy.name ? parameterCopy.name : '',
        inputType: parameterCopy.input_type ? parameterCopy.input_type : '',
        propertyType: parameterCopy.property_type ? parameterCopy.property_type : [],
        options: parameterCopy.options ? parameterCopy.options : null,
        active: parameterCopy.active ? parameterCopy.active : false,
        isForMatch: parameterCopy.isForMatch ? parameterCopy.isForMatch : false,
        isMatchField: parameterCopy.isMatchField ? parameterCopy.isMatchField : false,
        enableWizard: parameterCopy.enable_wizard ? parameterCopy.enable_wizard : false,
        enableWeb: parameterCopy.enable_web ? parameterCopy.enable_web : false,
        icon: parameterCopy.icon ? parameterCopy.icon : null,
        isCatalog: parameterCopy.is_catalog === true,
        catalogType: parameterCopy.is_catalog === true ? parameterCopy.options.catalog_type : 'zone',
        enabled: parameterCopy.enabled ? parameterCopy.enabled : false
      });
    }
  }

  setOptions(options) {
    this.setState({inputType: options.type, options});
  }

  chooseIcon(icon) {
    this.setState({icon});
  }

  switchWizard(e) {
    const newValue = e.target.checked;
    if (newValue === true) {
      this.setState({enableWizard: newValue, active: true});
    } else {
      this.setState({enableWizard: newValue});
    }
  }

  switchWeb(e) {
    const newValue = e.target.checked;
    if (newValue === true) {
      this.setState({enableWeb: newValue, active: true});
    } else {
      this.setState({enableWeb: newValue});
    }
  }

  switchActive(e) {
    const newValue = e.target.checked;
    if (newValue === false) {
      this.setState({active: newValue, enableWizard: false, enableWeb: false});
    } else {
      this.setState({active: newValue});
    }
  }
    
  addParameter() {
    const {title, name, options, inputType, enableWizard, enableWeb, icon, propertyType, catalogType, isCatalog, isForMatch, isMatchField} = this.state;
    options.catalog_type = catalogType;
    if (isCatalog) {
      options.content = [];
      options.type = 'catalog';
    }
    const parameter = {
      title,
      name,
      options,
      input_type: inputType,
      enable_wizard: enableWizard,
      enable_web: enableWeb,
      icon,
      is_catalog: isCatalog,
      property_type: propertyType,
      category: this.props.category,
      isForMatch,
      isMatchField
    };
    this.props.createParameter(parameter);
  }

  handleChangePropertyType(e, property) {
    const propertyType = copyObject(this.state.propertyType);
    if (propertyType.includes(property)) {
      propertyType.splice(propertyType.indexOf(property), 1);
    } else {
      propertyType.push(property);
    }
    this.setState({propertyType});
  }

  verifyDisabled() {
    const {name, options, inputType, isCatalog} = this.state;
    if (name !== '' && options !== null && inputType !== '') {
      if (isCatalog === true) {
        if (options.catalogType !== '') {
          return false;
        }
                
        return true;
      }
            
      return false;
    }
    return true;
  }

  editParameter() {
    const {parameter, identifier, title, name, options, inputType, active, enableWizard, enableWeb, icon, propertyType, isCatalog, catalogType, isForMatch, isMatchField, enabled} = this.state;
    if (isCatalog) {
      options.catalog_type = catalogType;
      options.content = [];
      options.type = 'catalog';
    }
    const newParameter = {
      id: parameter.id,
      identifier,
      title,
      name,
      input_type: inputType,
      icon,
      property_type: propertyType,
      active,
      enable_wizard: enableWizard,
      enable_web: enableWeb,
      options,
      isForMatch,
      isMatchField,
      enabled
    };
    this.props.editParameter(newParameter);
  }

  handleIsCatalogChange(e) {
    const isCatalog = e.target.checked;
    this.setState({isCatalog, options: null, inputType: ''});
  }

  handleChangeCatalogType(e) {
    const catalogType = e.target.value;
    const {options} = this.state;
    if (options !== null) {
      const newOptions = options;
      newOptions.catalogType = catalogType;
      newOptions.type = 'catalog';
      this.setState({catalogType, options: newOptions});
    } else {
      this.setState({catalogType});
    }
  }

  renderType() {
    const {classes, isNew} = this.props;
    const {isCatalog, options, inputType} = this.state;
    return (
      !invalidSuperAdmin &&
      <TableRow>
        <TableCell className={classes.tableRowTitle}><b>Opciones</b></TableCell>
        <TableCell>
          <TypesDialog 
            type={options} 
            setOptions={this.setOptions} 
            isNew={isNew}
            isCatalog={isCatalog}
            inputType={inputType}
          />
        </TableCell>
      </TableRow> 
    );
  }

  renderIdentifier() {
    const {classes, isNew} = this.props;
    const {identifier} = this.state;
    if (!isNew) {
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}><b>Identificador</b></TableCell>
          <TableCell>{identifier}</TableCell>
        </TableRow>
      );
    }
    return null;
  }

  renderActiveOption() {
    const {classes, isNew} = this.props;
    if (!isNew && invalidSuperAdmin) {
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}><b>Disponible</b></TableCell>
          <TableCell>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.active === true}
                  onChange={this.switchActive}
                  value="activeUser"
                  color="primary"
                />
              }
              label={this.state.active === true ? 'Disponible' : 'No disponible'}
            />
          </TableCell>
        </TableRow>
      );
    }
    return null;
  }

  renderEnabledOption() {
    const {classes, isNew} = this.props;
    if (!isNew && !invalidSuperAdmin) {
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}><b>Habilitar parámetro</b></TableCell>
          <TableCell>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.enabled}
                  onChange={() => this.setState({enabled: !this.state.enabled})}
                  color="primary"
                />
              }
              label={this.state.enabled ? 'Habilitado' : 'No habilitado'}
            />
          </TableCell>
        </TableRow>
      );
    }
    return null;
  }

  renderButton() {
    const {isLoading, classes, isNew} = this.props;
    const confirmationText = isNew ? '¿Está seguro que desea agregar este parámetro?' : '¿Está seguro que desea modificar este parámetro?';
    const buttonText = isNew ? 'Agregar parámetro' : 'Modificar parámetro';
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText={confirmationText}
          buttonText={buttonText}
          variant="contained"
          isDisabled={this.verifyDisabled()}
          confirmAction={isNew ? this.addParameter : this.editParameter}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderPropertyType() {
    const {propertyTypes} = this.props;
    return propertyTypes.map((property, index) => {
      return (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={this.state.propertyType.includes(property.id)}
              onChange={e => this.handleChangePropertyType(e, property.id)}
              value={property.id}
              color="primary"
              disabled={invalidSuperAdmin}
            />
          }
          label={property.name}
        />
      );
    });
  }

  renderCategory() {
    const {isNew, category} = this.props;
    if (isNew) {
      return (
        <Select
          fullWidth
          value={category}
          onChange={this.props.onChangeCategory}
        >
          <MenuItem value="personal">Personal</MenuItem>
          <MenuItem value="unit">Unidad</MenuItem>
          <MenuItem value="project">Proyecto</MenuItem>
        </Select>
      );
    }
    switch (category) {
    case 'project':
      return 'Proyecto';
            
    case 'unit':
      return 'Unidad';
            
    case 'personal': 
      return 'Personal';
            
    case 'wizard': 
      return 'Asistente';
            
    default: 
      return null;
    }
  }

  renderCatalogType() {
    const {isNew, classes} = this.props;
    if (isNew) {
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}><b>De Catálogo</b></TableCell>
          <TableCell>
            <Switch
              checked={this.state.isCatalog === true}
              onChange={this.handleIsCatalogChange}
              value={this.state.isCatalog}
              color="primary"
            />
          </TableCell>
        </TableRow>      
      );
    }
    return null;
  }

  renderCatalogOptions() {
    const {isCatalog, catalogType} = this.state;
    const {classes, isNew, allParameters} = this.props;
    if (isNew && isCatalog) {
      let existsBuilder = false;
      let existsUnitType = false;
      let existsZone = false;
      allParameters.forEach((obj) => {
        if (obj.is_catalog === true) {
          if (obj.options.catalogType) {
            switch (obj.options.catalogType) {
            case 'builder':
              existsBuilder = true;
              break;
            case 'zone':
              existsZone = true;
              break;
            case 'unit_type':
              existsUnitType = true;
              break;
            default:
              break;
            }
          }
        }
      });
      if (existsBuilder && existsUnitType && existsZone) {
        return (
          <TableRow>
            <TableCell className={classes.tableRowTitle}><b>Catálogo</b></TableCell>
            <TableCell>
                            No se puede crear más de una pregunta por catálogo
            </TableCell>
          </TableRow> 
        );
      }
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}><b>Catálogo</b></TableCell>
          <TableCell>
            <Select
              fullWidth
              value={catalogType}
              onChange={this.handleChangeCatalogType}
            >
              {
                !existsBuilder && <MenuItem value="builder">Constructoras</MenuItem>
              }
              {
                !existsZone && <MenuItem value="zone">Sectores</MenuItem>
              }
              {
                !existsUnitType && <MenuItem value="unit_type">Tipos de propiedad</MenuItem>
              }
            </Select>
          </TableCell>
        </TableRow>
      );
    } else if (isCatalog) {
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}><b>Catálogo</b></TableCell>
          <TableCell>
            {catalogType}
          </TableCell>
        </TableRow>
      );
    }
    return null; 
  }

  renderRegisterQuestion() {
    const {classes} = this.props;
    return (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.props.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
        </Toolbar>
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              {this.renderIdentifier()}
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Título</b></TableCell>
                <TableCell>
                  <TextField
                    value={this.state.title}
                    onChange={e => this.setState({title: e.target.value})}
                    fullWidth
                    label="Título"
                    multiline
                    rowsMax="4"
                  />
                </TableCell>
              </TableRow>                                    
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>
          {this.renderButton()}
        </div>
      </Paper>
    );
  }

  render() {
    const {classes, isNew, parameter} = this.props;
    const profileId = localStorage.getItem('profileId');
    return this.props.parameter.input_type === 'client_register' ?
      this.renderRegisterQuestion()
      : (
        <Paper elevation={2} className={classes.paperRoot}>
          <Toolbar>
            <IconButton onClick={this.props.handleGoBack}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6">
              {!isNew ? 'Detalle de ' : 'Nuevo '} parámetro
            </Typography>
          </Toolbar>
          <div style={{width: 'auto', overflowX: 'scroll'}}>
            <Table>
              <TableBody>
                {this.renderIdentifier()}
                {this.renderActiveOption()}
                {this.renderEnabledOption()}
                {
                  !invalidSuperAdmin && (
                    <TableRow>
                      <TableCell className={classes.tableRowTitle}><b>Habilitar parámetro para match</b></TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.isMatchField === true}
                              onChange={e => this.setState({isMatchField: e.target.checked})}
                              value="activeUser"
                              color="primary"
                            />
                          }
                          label={this.state.isMatchField === true ? 'Habilitado' : 'No habilitado'}
                        />
                      </TableCell>
                    </TableRow>
                  )
                } 
                {
                  invalidSuperAdmin && parameter.isMatchField && (
                    <TableRow>
                      <TableCell className={classes.tableRowTitle}><b>Disponible para match</b></TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.isForMatch === true}
                              onChange={e => this.setState({isForMatch: e.target.checked})}
                              // onChange={this.switchWizard}
                              value="activeUser"
                              color="primary"
                            />
                          }
                          label={this.state.isForMatch === true ? 'Disponible para match' : 'No Disponible para match'}
                        />
                      </TableCell>
                    </TableRow>
                  )
                }                
                {invalidSuperAdmin && <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Visible Cliente</b></TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.enableWizard === true}
                          onChange={this.switchWizard}
                          value="activeUser"
                          color="primary"
                        />
                      }
                      label={this.state.enableWizard === true ? 'Visible para el cliente' : 'No visible para el cliente'}
                    />
                  </TableCell>
                </TableRow>}
                {invalidSuperAdmin && <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Visible Web</b></TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.enableWeb === true}
                          onChange={this.switchWeb}
                          value="activeUser"
                          color="primary"
                        />
                      }
                      label={this.state.enableWeb === true ? 'Visible para la web' : 'No visible para la web'}
                    />
                  </TableCell>
                                    </TableRow>}       
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Categoría</b></TableCell>
                  <TableCell>
                    {this.renderCategory()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Tipo de Unidad</b></TableCell>
                  <TableCell>
                    {this.renderPropertyType()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Nombre</b></TableCell>
                  <TableCell>
                    <TextField 
                      value={this.state.name}
                      onChange={e => this.setState({name: e.target.value})}
                      fullWidth
                      multiline
                      label="Nombre"
                      disabled={invalidSuperAdmin}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Título</b></TableCell>
                  <TableCell>
                    <TextField
                      value={this.state.title}
                      onChange={e => this.setState({title: e.target.value})}
                      fullWidth
                      multiline
                      label="Título"
                      disabled={invalidSuperAdmin}
                    />
                  </TableCell>
                </TableRow>                                    
                {!invalidSuperAdmin && <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Ícono</b></TableCell>
                  <TableCell>
                    {
                      this.state.icon !== null && 
                      <FontAwesomeIcon 
                        icon={this.state.icon} 
                        className={classes.fontAwesomeIcon} 
                        color="#111" 
                        size="3x"
                      />
                    }
                    <IconsGrid chooseIcon={this.chooseIcon} disabled={invalidSuperAdmin} />
                  </TableCell>
                                     </TableRow> }
                {this.renderCatalogType()}                            
                {this.renderCatalogOptions()}        
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Tipo</b></TableCell>
                  <TableCell>
                    {this.state.inputType}
                  </TableCell>
                </TableRow>
                {this.renderType()}                                  
              </TableBody>
            </Table>
          </div>
          <div className={classes.submitContainer}>
            {this.renderButton()}
          </div>
        </Paper>
      );
  }
}
ParametersDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  parameter: PropTypes.object.isRequired,
  allParameters: PropTypes.array.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  createParameter: PropTypes.func.isRequired,
  editParameter: PropTypes.func.isRequired
};

export default (withStyles(styles)(ParametersDetail));
