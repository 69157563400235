import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styles } from './styles';
import DatePicker from '../../../../common/datePicker';
import CheckList from '../../../../common/checkList';
import DoubleCheckList from '../../../../common/doubleCheckList';
import TextBox from '../../../../common/textBox';
import RadioList from '../../../../common/radioGroup';
import YesNo from '../../../../common/yesNo';
import ConfirmationDialog from '../../../../common/confirmationDialog';
import Range from '../../../../common/range';
import DateRange from '../../../../common/dateRange';
import ImagePicker from '../../../../common/imagePicker';
import ImagePickerMultiple from '../../../../common/imagePickerMultiple';
import CollapsableZones from '../../../../common/collapsableZones';
import { ZONE } from '../../../../../utils/utils';

export class Attributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectors: [],
    };
    this.renderAttributes = this.renderAttributes.bind(this);
    this.renderAnswer = this.renderAnswer.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.handleEditAttributes = this.handleEditAttributes.bind(this);
    this.handleUpdateAnswer = this.handleUpdateAnswer.bind(this);
  }

  componentWillMount() {
    const zoneContent = this.props.lead.preferences.find(
      (obj) => obj.attributeId === ZONE,
    );

    if (
      zoneContent &&
      zoneContent.answerId &&
      zoneContent.answerId.length > 0
    ) {
      const sectors = this.props.allParameters
        .find((item) => item.identifier === ZONE)
        .options.content.filter((item) =>
          zoneContent.answerId.includes(item.answerId),
        )
        .map(({ id }) => id);
      this.setState({ sectors });
    }
  }

  handleUpdateAnswer(attributeId, answerId, answer) {
    const { allParameters } = this.props;
    const preference = allParameters.filter(
      (obj) => obj.identifier === attributeId,
    )[0];
    preference.answerId = answerId;
    preference.answer = answer;
    this.props.onUpdateAnswer(preference);
  }

  handleUpdateZone(sectors, answers) {
    this.setState({ sectors });
    const answerId = answers
      .filter((item) => sectors.includes(item.id))
      .map(({ answerId }) => answerId);
    this.handleUpdateAnswer(ZONE, answerId, '');
  }

  dateAnswer(identifier, currentAnswer, isBirthdate, content) {
    return (
      <DatePicker
        isBirthdate={isBirthdate}
        updateAnswer={this.handleUpdateAnswer}
        content={content}
        currentAnswer={currentAnswer}
        identifier={identifier}
        permissions
      />
    );
  }

  textAnswer(identifier, currentAnswer) {
    return (
      <TextBox
        updateAnswer={this.handleUpdateAnswer}
        placeholder="Ingrese..."
        currentAnswer={currentAnswer}
        identifier={identifier}
        permissions
      />
    );
  }

  yesNoAnswer(identifier, currentAnswer) {
    return (
      <YesNo
        identifier={identifier}
        currentAnswer={currentAnswer}
        updateAnswer={this.handleUpdateAnswer}
        permissions
      />
    );
  }

  imagePickerUnique(identifier, currentAnswer, answers) {
    return (
      <ImagePicker
        identifier={identifier}
        currentAnswer={currentAnswer}
        answers={answers}
        permissions
        updateAnswer={this.handleUpdateAnswer}
      />
    );
  }

  imagePickerMultiple(identifier, currentAnswer, answers) {
    return (
      <ImagePickerMultiple
        identifier={identifier}
        currentAnswer={currentAnswer}
        answers={answers}
        updateAnswer={this.handleUpdateAnswer}
        permissions
      />
    );
  }

  radioGroup(identifier, currentAnswer, answers) {
    return (
      <RadioList
        answers={answers}
        currentAnswer={currentAnswer}
        identifier={identifier}
        permissions
        updateAnswer={this.handleUpdateAnswer}
      />
    );
  }

  checkList(identifier, currentAnswer, answers) {
    if (identifier === ZONE) {
      return (
        <CollapsableZones
          level="cities"
          filterBy="projects"
          checkedSectors={this.state.sectors}
          onCheckSector={(value) => this.handleUpdateZone(value, answers)}
        />
      );
    }
    return (
      <CheckList
        answers={answers}
        currentAnswer={currentAnswer}
        identifier={identifier}
        updateAnswer={this.handleUpdateAnswer}
        permissions
      />
    );
  }

  range(identifier, currentAnswer, answers) {
    return (
      <Range
        identifier={identifier}
        answers={answers}
        currentAnswer={currentAnswer}
        updateAnswer={this.handleUpdateAnswer}
        permissions
      />
    );
  }

  dateRange(identifier, currentAnswer) {
    return (
      <DateRange
        identifier={identifier}
        currentAnswer={currentAnswer}
        updateAnswer={this.handleUpdateAnswer}
        permissions
      />
    );
  }

  handleEditAttributes() {
    window.scrollTo(0, 0);
    this.props.onEditAttributes();
  }

  renderButton() {
    return (
      <ConfirmationDialog
        confirmationText="¿Está seguro que desea actualizar las preferencias de este lead?"
        buttonText="Modificar Preferencias"
        variant="contained"
        isDisabled={false}
        confirmAction={this.handleEditAttributes}
      />
    );
  }

  renderAnswer(attribute) {
    const { lead } = this.props;

    if (
      attribute &&
      attribute.options &&
      attribute.options.content &&
      attribute.options.content.length > 0
    ) {
      attribute.options.content = attribute.options.content.filter(
        (x) => x.active,
      );
    }

    if (lead !== null) {
      const { identifier } = attribute;
      const currentAnswer = lead.preferences.filter(
        (obj) => identifier === obj.attributeId,
      )[0];
      switch (attribute.input_type) {
        case 'date':
          return this.dateAnswer(identifier, currentAnswer, false);
        case 'birth_date':
          return this.dateAnswer(identifier, currentAnswer, true);
        case 'text_box':
          return this.textAnswer(identifier, currentAnswer);
        case 'yes_no':
          return this.yesNoAnswer(identifier, currentAnswer);
        case 'image_unique':
          return this.imagePickerUnique(
            identifier,
            currentAnswer,
            attribute.options.content,
          );
        case 'image_multiple':
          return this.imagePickerMultiple(
            identifier,
            currentAnswer,
            attribute.options.content,
          );
        case 'radio_group':
          return this.radioGroup(
            identifier,
            currentAnswer,
            attribute.options.content,
          );
        case 'check_list':
          return this.checkList(
            identifier,
            currentAnswer,
            attribute.options.content,
          );
        case 'range':
          return this.range(
            identifier,
            currentAnswer,
            attribute.options.content,
          );
        case 'date_range':
          return this.dateRange(identifier, currentAnswer);
        default:
          return null;
      }
    }
    return null;
  }

  renderAttributes() {
    const { allParameters, classes } = this.props;
    const parametersWithOrder = [];
    const parametersWithoutOrder = [];
    allParameters.forEach((parameter) => {
      if (parameter.globalOrder) {
        parametersWithOrder.push(parameter);
      } else {
        parametersWithoutOrder.push(parameter);
      }
    });
    const parametersSorted = parametersWithOrder.concat(parametersWithoutOrder);
    return parametersSorted.map((attribute, index) => {
      return (
        <div key={index}>
          <div className={classes.questionContainer}>
            <Typography variant="subtitle1">{attribute.name}</Typography>
            {this.renderAnswer(attribute)}
          </div>
          <Divider />
        </div>
      );
    });
  }

  render() {
    const { classes, lead, isLoading } = this.props;
    if (lead === null) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }
    if (isLoading) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }
    return (
      <div>
        <div className={classes.attributesContainer}>
          {this.renderAttributes()}
        </div>
        <div className={classes.submitContainer}>{this.renderButton()}</div>
      </div>
    );
  }
}

Attributes.propTypes = {
  classes: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  allParameters: PropTypes.array.isRequired,
  onUpdateAnswer: PropTypes.func.isRequired,
  onEditAttributes: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { leads } = state;
  return {
    isLoading: leads.sendingLead,
    lead: leads.lead,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Attributes));
