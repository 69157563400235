/* eslint-disable no-alert */
/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import {getAllParameters} from '../../actions/parameters';
import {
  filterLeads,
  onUpdateLeadUser,
  setLead,
  getLead
} from '../../actions/leads';
import {getPropertyTypes} from '../../actions/propertyTypes';
import {getZones} from '../../actions/zones';
import {getOrigins} from '../../actions/origins';
import {getUsers} from '../../actions/users';
import {getLeadStates} from '../../actions/leadStates';
import EnhancedTable from '../common/enhancedTable';
import {leadsCols} from '../../utils/colTypes';
import CustomSnackbar from '../common/customSnackbar';
import LeadDetail from './detail';
import {leadsOptions, defaultOrderLeads} from '../../utils/filterTypes';
import {
  copyObject,
  sortObjectByProperty,
  ADMIN,
  ZONE,
  ASESOR_ID,
  SUPER_ADMIN_ROL_ID
} from '../../utils/utils';
import CONSTANTS from '../../utils/constants';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

export class LeadsReadonly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      title: 'Leads',
      filterChips: [],
      filterOptions: copyObject(leadsOptions),
      openExistingUserModal: false,
      existingUserId: null,
      existingUserMail: null,
      existingUserName: null,
      existingUserLastname: null,
      strategicPartner: null
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangePageDetail = this.handleChangePageDetail.bind(this);
    this.handleBackPage = this.handleBackPage.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderFilterOptions = this.renderFilterOptions.bind(this);
    this.renderConfirmUpdateExistingUser = this.renderConfirmUpdateExistingUser.bind(this);
    this.renderLoadingLead = this.renderLoadingLead.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.filtersAttributes = this.filtersAttributes.bind(this);
    this.handleRefreshLead = this.handleRefreshLead.bind(this);
    this.handleCloseExistingUserModal = this.handleCloseExistingUserModal.bind(this);
    this.handleCancelExistingUserModal = this.handleCancelExistingUserModal.bind(this);
    this.handleSuccessExistingUserModal = this.handleSuccessExistingUserModal.bind(this);
    this.hasAdminPermissions = this.hasAdminPermissions.bind(this);
  }

  componentWillMount() {
    this.props.getPropertyTypes();
    this.props.getUsers();
    this.props.getZones();
    this.props.getLeadStates();
    this.props.getAllParameters();
    this.props.getOrigins();
    const profileId = localStorage.getItem('profileId');
    let defaultFilters = this.props.filters;
    const partner = localStorage.getItem('strategicPartner');
    const strategicPartner = partner ? {origin: [partner]} : null;
    if (strategicPartner) {
      this.setState({strategicPartner});
    }
    if (CONSTANTS.ROLES_DEFAULT_FILTER_LEADS.indexOf(profileId) >= 0) {
      defaultFilters = {
        status: ['5cbdf596ba0b4000172a4857', '5cbdf569ba0b4000172a4851']
      };
      this.setState({filterChips: CONSTANTS.DEFAULT_CHIPS_FILTER_LEADS});
    }

    this.props.filterLeads(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      defaultFilters,
      strategicPartner
    );
    if (this.props.match) {
      if (this.props.match.params) {
        if (this.props.match.params.id) {
          this.handleRefreshLead(this.props.match.params.id);
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.renderFilters();
    }
  }

  onDetail(_id) {
    this.props.getLead(_id);
    this.setState({isNew: false});
  }

  onOrder(order, orderBy) {
    this.props.filterLeads(
      this.props.page,
      this.props.rowsPerPage,
      order,
      orderBy,
      this.props.searchText,
      this.props.filters,
      this.state.strategicPartner
    );
  }

  onSearch(searchText) {
    this.setState({filterChips: []});
    this.props.filterLeads(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      searchText,
      {},
      this.state.strategicPartner
    );
  }

  getBulkAssignPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_leads) {
        return true;
      }
    }
    return false;
  };

  defaulOrderFilters = (filtersToOrder) => {
    const orderedFilters = [];
    defaultOrderLeads.reduce((result, defaultItem) => {
      filtersToOrder.filter((filterItem) => {
        if (filterItem.id === defaultItem) {
          return orderedFilters.push(filterItem);
        }
        return false;
      });
      return orderedFilters;
    }, []);
    return orderedFilters;
  };

  filtersAttributes = (allFilters) => {
    const items = allFilters;
    const attributes = [];
    for (let i = 0; i < Object.entries(items).length; i += 1) {
      const filter = Object.entries(items)[i];
      if (
        filter[0] !== 'status' &&
        filter[0] !== 'stage' &&
        filter[0] !== 'origin' &&
        filter[0] !== 'brokers' &&
        filter[0] !== 'date_created' &&
        filter[0] !== 'date_updated' &&
        filter[0] !== 'next_deadline' &&
        filter[0] !== 'last_contact_days' &&
        filter[0] !== 'estimated_closing_time' &&
        filter[0] !== 'temperature' &&
        filter[1] !== ''
      ) {
        let query = '';
        if (filter[1].type === 'select') {
          query = 'preferences.answerId';
          const $in = {
            $in: filter[1].value
          };
          attributes.push({
            [query]: $in
          });
        } else {
          query = 'preferences.answer';
          attributes.push({
            [query]: filter[1].value
          });
        }
        delete items[filter[0]];
      }
    }
    if (attributes.length > 0) {
      attributes.forEach((attribute) => {
        const attributeObject = Object.entries(attribute);
        const key = attributeObject[0][0];
        const value = attributeObject[0][1];
        if (value !== '') {
          items[key] = value;
        } else {
          delete items[key];
        }
      });
    }
    const preFilters = Object.entries(items).filter(obj => obj[1] !== '');
    const filters = {};
    preFilters.forEach((item) => {
      const key = item[0];
      if (typeof item[1] === 'string' || item[1] instanceof String) {
        filters[key] = item[1];
      } else if (Array.isArray(item[1].value)) {
        if (item[1].value.length > 0) {
          filters[key] = item[1].value;
        }
      } else if (item[1].value) {
        filters[key] = item[1].value;
      } else {
        filters[key] = item[1];
      }
    });

    return filters;
  };

  handleRefreshLead(_id) {
    this.props.getLead(_id);
  }

  hasAdminPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.name === ADMIN || profile.name === SUPER_ADMIN_ROL_ID) {
        return true;
      }
    }
    return false;
  };

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterLeads(
      this.props.page,
      rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
      this.state.strategicPartner
    );
  }

  handleFilter(filters, filterChips) {
    const filtersToApply = this.filtersAttributes(filters);
    this.setState({filterChips});
    this.props.filterLeads(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      '',
      filtersToApply,
      this.state.strategicPartner
    );
  }

  handleChangePage(page) {
    this.props.filterLeads(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters
    );
  }

  async handleChangePageDetail() {
    const page = this.props.page + 1;
    const result = await this.props.filterLeads(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
      this.state.strategicPartner
    );
    return result;
  }

  async handleBackPage() {
    const page = this.props.page + -1;
    if (page >= 0) {
      const result = await this.props.filterLeads(
        page,
        this.props.rowsPerPage,
        this.props.order,
        this.props.orderBy,
        this.props.searchText,
        this.props.filters,
        this.state.strategicPartner
      );
      return result;
    }
    return null;
  }

  handleGoBack(refresh = true) {
    this.props.setLead(null);
    this.setState({isNew: false});
    if (refresh === true) {
      this.props.filterLeads(
        this.props.page,
        this.props.rowsPerPage,
        this.props.order,
        this.props.orderBy,
        this.props.searchText,
        this.props.filters,
        this.state.strategicPartner
      );
    }
  }

  handleCloseExistingUserModal() {
    this.setState({
      openExistingUserModal: false,
      existingUserId: null,
      existingUserMail: null,
      existingUserName: null,
      existingUserLastname: null
    });
  }

  handleCancelExistingUserModal() {
    this.setState({
      openExistingUserModal: false,
      existingUserId: null,
      existingUserMail: null,
      existingUserName: null,
      existingUserLastname: null
    });
    const {lead} = this.props;
    this.handleRefreshLead(lead._id);
  }

  handleSuccessExistingUserModal() {
    const {lead} = this.props;
    const {existingUserId} = this.state;
    this.props.onUpdateLeadUser(lead._id, existingUserId).then(() => {
      this.props.setLead(lead);
    });
    this.handleCloseExistingUserModal();
  }

  renderSnackBar() {
    const {error, message} = this.props;
    let variant = 'success';
    if (error !== null) {
      variant = 'error';
    }
    if (message) {
      return <CustomSnackbar variant={variant} message={message} />;
    }
    return null;
  }

  renderFilterOptions = () => {
    const cols = copyObject(leadsOptions);
    if (JSON.parse(localStorage.getItem('isBroker')) === true) {
      cols.splice(0, 1);
      return cols;
    }
    return cols;
  };

  renderFilters() {
    const {
      allParameters,
      leadStates,
      leadStages,
      users,
      origins
    } = this.props;
    let defFilters = this.renderFilterOptions();
    let filtersToSet = [];
    if (leadStates.length > 0 && leadStages.length > 0 && users.length > 0) {
      defFilters = defFilters.map((item) => {
        const filter = item;
        if (filter.id === 'status') {
          const types = [];
          leadStates.forEach((leadState) => {
            const newType = {
              title: leadState.name,
              value: leadState.id
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'stage') {
          const types = [{title: 'Todos', value: 'all'}];
          leadStates.forEach((leadState) => {
            if (leadState.stages) {
              leadState.stages.forEach((stage) => {
                const newType = {
                  title: stage.name,
                  value: stage.id,
                  leadState: leadState.id,
                  leadStateName: leadState.name
                };
                types.push(newType);
              });
            }
          });
          filter.types = types;
        } else if (filter.id === 'brokers') {
          const types = [{title: 'Todos', value: 'all'}];
          if (users) {
            const asesors = users.filter((obj) => {
              if (obj.profile) {
                if (obj.profile._id) {
                  if (obj.is_asesor === true) {
                    return true;
                  }
                }
              }
              return false;
            });
            asesors.forEach((leadStage) => {
              const newType = {
                title: leadStage.name,
                value: leadStage.id
              };
              types.push(newType);
            });
            filter.types = types;
          }
        } else if (filter.type === 'date') {
          filter.types = [{title: 'Todos', value: ''}];
        } else if (filter.id === 'origin') {
          const types = [{title: 'Todos', value: 'all'}];
          if (origins) {
            origins.forEach((origin) => {
              const newType = {
                title: origin.name,
                value: origin.id
              };
              types.push(newType);
            });
            filter.types = types;
          }
        }
        return filter;
      });
    }

    if (allParameters.length > 0) {
      const clearParams = allParameters
      .filter((obj) => {
        if (
          obj.is_catalog === true &&
            obj.active === true &&
            obj.options.is_multiple === true
        ) {
          if (obj.identifier === ZONE) {
            return obj;
          }
        }
        return null;
      })
      .filter(obj => obj !== null);
      const parameterFilters = clearParams
      .map((parameter) => {
        let parameterTypes = [];
        if (parameter.options.content.length > 0) {
          const parameterOptions = parameter.options.content;
          const sortParameterOptions = sortObjectByProperty(
            parameterOptions,
            'value'
          );
          parameterTypes = sortParameterOptions.map((item) => {
            return {
              title: item.value,
              value: item.answerId
            };
          });
          const filter = {
            id: parameter.identifier,
            label: parameter.name,
            types: [{title: 'Todos', value: 'all'}]
          };

          if (parameterTypes.length > 0) {
            filter.types = filter.types.concat(parameterTypes);
          }
          return filter;
        }
        return null;
      })
      .filter(obj => obj !== null);
      filtersToSet.push(defFilters[0]);
      filtersToSet.push(defFilters[1]);
      filtersToSet = filtersToSet.concat(parameterFilters);
      defFilters.splice(0, 2);
      filtersToSet = filtersToSet.concat(defFilters);
    }
    const orderFilters = this.defaulOrderFilters(filtersToSet);
    this.setState({filterOptions: orderFilters});
  }

  renderConfirmUpdateExistingUser() {
    const {
      existingUserMail,
      existingUserName,
      existingUserLastname
    } = this.state;
    return (
      <Dialog
        open={this.state.openExistingUserModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseExistingUserModal}
      >
        <DialogTitle>Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            El correo electrónico <code>{existingUserMail}</code> ya existe y
            está asignado a{' '}
            <code>{`${existingUserName} ${existingUserLastname}`}</code>.
            <br />
            Al actualizar el mail asignará el lead a esa persona.
            <br />
            ¿Está seguro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancelExistingUserModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.handleSuccessExistingUserModal} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderLoadingLead() {
    const {isLoadingLead, isLoadingDrawer} = this.props;
    if (isLoadingLead === true || isLoadingDrawer === true) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)'
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto'
            }}
          />
        </div>
      );
    }
    return null;
  }

  renderCols = () => {
    const isBroker = JSON.parse(localStorage.getItem('isBroker'));
    const profileId = localStorage.getItem('profileId');
    const cols = copyObject(leadsCols);
    if (isBroker === true && profileId === ASESOR_ID) {
      cols.splice(3, 1);
      return cols;
    }
    return cols;
  };

  render() {
    const {
      leads,
      isLoading,
      dataLength,
      zones,
      lead,
      allParameters,
      propertyTypes,
      leadStates,
      leadStages,
      users,
      origins
    } = this.props;
    const {title, isNew, filterOptions, filterChips} = this.state;
    const hasAdminPermissions = this.hasAdminPermissions();
    const data = {
      rows: leads,
      autoFillers: {users},
      cols: this.renderCols()
    };
    const handlers = {
      onDetail: this.onDetail,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onFilter: this.handleFilter,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isLeadSearch: false,
      isBulkable: false,
      isDownloadable: false,
      isSelectable: false,
      isFiterable: false,
      searchText: this.props.searchText,
      hasCreatePermission: false,
      hasEditPermission: true,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: true,
        options: filterOptions,
        chips: filterChips,
        by: this.props.filters
      },
      origins
    };
    if (isLoading === true) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)'
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto'
            }}
          />
        </div>
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {this.renderLoadingLead()}
        {this.renderConfirmUpdateExistingUser()}
        {lead === null ? (
          <EnhancedTable
            title={title}
            data={data}
            handlers={handlers}
            config={config}
            withoutFilter={this.state.strategicPartner}
          />
        ) : (
          <LeadDetail
            handleGoBack={this.handleGoBack}
            zones={zones}
            users={users}
            allParameters={allParameters}
            hasAdminPermissions={hasAdminPermissions}
            leadStates={leadStates}
            leadStages={leadStages}
            isNew={isNew}
            propertyTypes={propertyTypes}
            handleChangePage={this.handleChangePageDetail}
            handleBackPage={this.handleBackPage}
            origins={origins}
          />
        )}
      </div>
    );
  }
}

LeadsReadonly.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingDrawer: PropTypes.bool.isRequired,
  isLoadingLead: PropTypes.bool.isRequired,
  leads: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  lead: PropTypes.object,
  zones: PropTypes.array.isRequired,
  leadStates: PropTypes.array.isRequired,
  leadStages: PropTypes.array.isRequired,
  filterLeads: PropTypes.func.isRequired,
  onUpdateLeadUser: PropTypes.func.isRequired,
  getZones: PropTypes.func.isRequired,
  getOrigins: PropTypes.func.isRequired,
  getLead: PropTypes.func.isRequired,
  getPropertyTypes: PropTypes.func.isRequired,
  setLead: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  allParameters: PropTypes.array.isRequired,
  getAllParameters: PropTypes.func.isRequired,
  getLeadStates: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  match: PropTypes.object,
  dataLength: PropTypes.number.isRequired,
  origins: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
  const {
    leads,
    parameters,
    zones,
    leadStates,
    users,
    drawer,
    propertyTypes,
    origins
  } = state;
  return {
    isLoadingDrawer: drawer.sending,
    isLoading: leads.sending,
    isLoadingLead: leads.sendingLead,
    isLoadingParameters: parameters.sending,
    isLoadingZones: zones.sending,
    isLoadingLeadStates: leadStates.sending,
    isLoadingUsers: users.sending,
    isLoadingPropertyTypes: propertyTypes.sending,
    propertyTypes: propertyTypes.propertyTypes,
    leads: leads.leads,
    users: users.users,
    lead: leads.lead,
    leadStates: leadStates.leadStates,
    leadStages: leadStates.leadStages,
    zones: zones.zones,
    dataLength: leads.dataLength,
    error: leads.error,
    message: leads.message,
    searchText: leads.searchText,
    allParameters: parameters.allParameters,
    order: leads.order,
    orderBy: leads.orderBy,
    page: leads.page,
    rowsPerPage: leads.rowsPerPage,
    filters: leads.filters,
    origins: origins.origins
  };
};

const mapDispatchToProps = {
  filterLeads,
  getUsers,
  onUpdateLeadUser,
  getLead,
  setLead,
  getZones,
  getOrigins,
  getLeadStates,
  getPropertyTypes,
  getAllParameters
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsReadonly);
