import React, { useEffect } from 'react';
import { withStyles, Paper, Grid } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import Call from '@material-ui/icons/Call';
import WhatsApp from '@material-ui/icons/PhoneAndroid';
import Email from '@material-ui/icons/Email';
import Note from '@material-ui/icons/Note';
import Person from '@material-ui/icons/Person';
import { styles } from './styles';
import TasksTabBar from '../tasksTabBar';

const Interactions = (props) => {
  const { classes, activityIndicators } = props;

  const renderTasks = () => {
    return (
      <div className={classes.root2}>
        <TasksTabBar />
      </div>
    );
  };

  const renderInteractions = () => {
    return (
      <div className={classes.root2}>
        <Grid container>
          <Grid item md={6} xs={6}>
            <div className={classes.borderedCards}>
              <div className={classes.flexCard}>
                <div className={classes.itemValue}>
                  <ListIcon />
                </div>
                <div className={classes.flexCardTitle}>
                  <h5>{(activityIndicators && activityIndicators.tasks) || 0}</h5>
                  <h6>Tareas</h6>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={6}>
            <div className={classes.borderedCards}>
              <div className={classes.flexCard}>
                <div className={classes.itemValue}>
                  <Note />
                </div>
                <div className={classes.flexCardTitle}>
                  <h5>{(activityIndicators && activityIndicators.nota) || 0}</h5>
                  <h6>Notas</h6>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={6}>
            <div className={classes.borderedCards}>
              <div className={classes.flexCard}>
                <div className={classes.itemValue}>
                  <WhatsApp />
                </div>
                <div className={classes.flexCardTitle}>
                  <h5>{(activityIndicators && activityIndicators.whatsapp) || 0}</h5>
                  <h6>Whatsapps</h6>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={6}>
            <div className={classes.borderedCards}>
              <div className={classes.flexCard}>
                <div className={classes.itemValue}>
                  <Email />
                </div>
                <div className={classes.flexCardTitle}>
                  <h5>{(activityIndicators && activityIndicators.email) || 0}</h5>
                  <h6>Emails enviados</h6>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={6}>
            <div className={classes.borderedCards}>
              <div className={classes.flexCard}>
                <div className={classes.itemValue}>
                  <Call />
                </div>
                <div className={classes.flexCardTitle}>
                  <h5>{(activityIndicators && activityIndicators.llamada) || 0}</h5>
                  <h6>Llamadas</h6>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={6}>
            <div className={classes.borderedCards}>
              <div className={classes.flexCard}>
                <div className={classes.itemValue}>
                  <Person />
                </div>
                <div className={classes.flexCardTitle}>
                  <h5>{(activityIndicators && activityIndicators.personal) || 0}</h5>
                  <h6>Personal</h6>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={24}>
        <Grid item md={12} xs={12}>
          <Paper className={`${classes.paper} ${classes.cardAdjustedcardAdjusted}`}>
            <h5 className={classes.titleCard}>
              Estas son tus interacciones en el período seleccionado
            </h5>
            <hr></hr>
            {renderInteractions()}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Interactions);
