import React, {Component} from 'react';
import Drawer from '@material-ui/core/Drawer';
import {connect} from 'react-redux';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import BuildIcon from '@material-ui/icons/Build';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import AdvanceIcon from '@material-ui/icons/TrendingUpOutlined';
import Dashboard from '@material-ui/icons/Dashboard';
import BarChart from '@material-ui/icons/BarChart';
import CommentsIcon from '@material-ui/icons/Comment';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import EditIcon from '@material-ui/icons/Edit';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import PinDropIcon from '@material-ui/icons/PinDrop';
import MessageIcon from '@material-ui/icons/Message';
import MailIcon from '@material-ui/icons/Mail';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AssistantIcon from '@material-ui/icons/Assistant';
import SortIcon from '@material-ui/icons/Sort';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalConvenienceStore from '@material-ui/icons/LocalConvenienceStore';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import CalendarToday from '@material-ui/icons/CalendarToday';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {getUser} from '../../../actions/security';
import {getProfile} from '../../../actions/profiles';
import CustomSnackbar from '../customSnackbar';
import {styles} from './styles';
import CONSTANTS from '../../../utils/constants';
import TrivoSmart from '../../trivoSmartDemand';
import { userHasTrivoSmart } from '../../../actions/users';
import { Group } from '@material-ui/icons';

export class CustomDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCommissions: false,
      openCatalogues: false,
      openSeguimiento: false,
      hasTrivoSmartPermission: false
    };
    this.renderSnack = this.renderSnack.bind(this);
    this.renderDrawerList = this.renderDrawerList.bind(this);
  }

  componentWillMount() {
    const profileId = localStorage.getItem('profileId');
    if (profileId) {
      this.props.getProfile(profileId);
    }

    userHasTrivoSmart().then(hasTrivoSmartPermission => this.setState({hasTrivoSmartPermission}));    
  }

  renderRows() {
    const {classes, profile} = this.props;
    let rows = null;
    if (profile) {
      const {permissions} = profile;      
      const options = [
        {
          to: '/home',  // TODO ACTIVE WHEN HISTORIC ES FOR COMPANIES
          icon: <BarChart />,
          primaryText: 'Home',
          key: 'view_dashboard'
        },
        {
          to: '/trivo-smart', 
          icon: <Dashboard />,
          primaryText: 'Trivo Smart',
          key: 'trivo_smart'
        },
        {
          to: '/readonly',
          icon: <PermContactCalendar />,
          primaryText: 'Leads',
          key: 'readonly_leads'
        },
        {
          to: '/empresas',
          icon: <BusinessIcon />,
          primaryText: 'Empresas',
          key: 'view_companies'
        },
        {
          to: '/leads',
          icon: <PermContactCalendar />,
          primaryText: 'Leads',
          key: 'view_leads'
        },
        {
          to: '/leads',
          icon: <PermContactCalendar />,
          primaryText: 'Leads',
          key: 'view_own_leads'
        },
        {
          to: '/leads',
          icon: <PermContactCalendar />,
          primaryText: 'Leads',
          key: 'view_unassigned_leads'
        },
        {
          to: '/citas',
          icon: <CalendarToday />,
          primaryText: 'Citas',
          key: 'view_calendar'
        },
        {
          to: '/comentarios',
          icon: <CommentsIcon />,
          primaryText: 'Comentarios',
          key: 'view_comments'
        },
        {
          to: '/proyectos',
          icon: <AssignmentIcon />,
          primaryText: 'Desarrollos',
          key: 'view_projects'
        },
        {
          to: '/unidades',
          icon: <HomeIcon />,
          primaryText: 'Unidades',
          key: 'view_units'
        },
        {
          to: '/unidades',
          icon: <HomeIcon />,
          primaryText: 'Unidades',
          key: 'view_zone_units'
        },
        {
          to: '/usuarios',
          icon: <PeopleIcon />,
          primaryText: 'Usuarios',
          key: 'view_users'
        },
        {
          to: '/perfiles',
          icon: <AccountCircleIcon />,
          primaryText: 'Perfiles',
          key: 'view_profiles'
        }
      ];

      const company = JSON.parse(localStorage.getItem('company'));
      const userWithTrivoSmart = company && company.hasTrivoSmart && this.state.hasTrivoSmartPermission;

      rows = options.map((option, index) => {
        if (permissions[option.key] || (option.key === 'trivo_smart' && userWithTrivoSmart)) {
          return (
            <Link key={index} to={option.to} className={classes.link} href="null">
              <ListItem button onClick={this.props.handleDrawerClose}>
                <ListItemIcon>
                  {option.icon}
                </ListItemIcon>
                <ListItemText primary={option.primaryText} />
              </ListItem>
            </Link>
          );
        }
        return null;
      });
    }
    const dups = [];
    const optionRows = rows.filter((el) => {
      if (el) {
        if (dups.indexOf(el.props.to) === -1) {
          dups.push(el.props.to);
          return true;
        }
      }
      return false;
    });
    return optionRows;
  }

  renderDrawerList() {
    const {classes, profile} = this.props;
    if (profile) {
      // eslint-disable-next-line prefer-destructuring
      const permissions = profile.permissions;      
      const commissionsOptions = [
        {
          to: '/comisiones/por-cobrar',
          icon: <MonetizationOnIcon />,
          primaryText: 'Por Cobrar',
          key: 'view_revenues_collect' 
        },
        {
          to: '/comisiones/por-pagar',
          icon: <AttachMoneyIcon />,
          primaryText: 'Por Pagar',
          key: 'view_revenues_pay'
        }
      ];
      const seguimientoOptions = [
        {
          to: '/avance',
          icon: <AdvanceIcon />,
          primaryText: 'Avance',
          key: 'view_advance'
        },
        {
          to: '/observaciones',
          icon: <RemoveRedEyeIcon />,
          primaryText: 'Interacciones',
          key: 'view_observations'
        }
      ];
      const catalogOptions = [
        {
          to: '/parametros',
          icon: <EditIcon />,
          primaryText: 'Perfilamiento',
          key: 'view_attributes'
        },
        {
          to: '/catalogos/constructoras',
          icon: <BuildIcon />,
          primaryText: 'Constructoras',
          key: 'view_builders'
        },
        {
          to: '/catalogos/sectores',
          icon: <PinDropIcon />,
          primaryText: 'Sectores',
          key: 'view_zones'
        },
        {
          to: '/catalogos/tiposdepropiedad',
          icon: <DescriptionIcon />,
          primaryText: 'Tipos de propiedad',
          key: 'view_unit_types'
        },
        {
          to: '/catalogos/etapas',
          icon: <FormatListNumberedIcon />,
          primaryText: 'Etapas',
          key: 'view_stages'
        },
        {
          to: '/catalogos/estadosLead',
          icon: <AssistantIcon />,
          primaryText: 'Estados de Lead',
          key: 'view_lead_statuses'
        },
        {
          to: '/catalogos/etapas-lead',
          icon: <SortIcon />,
          primaryText: 'Etapas de Lead',
          key: 'view_lead_statuses'
        },
        {
          to: '/catalogos/whatsapp',
          icon: <MessageIcon />,
          primaryText: 'Plantillas de WhatsApp',
          key: 'view_whatsapp_templates'
        },
        {
          to: '/catalogos/plantillas',
          icon: <MailIcon />,
          primaryText: 'Plantillas de Correo',
          key: 'view_email_templates'
        },
        {
          to: '/catalogos/origenes',
          icon: <ArrowRightAlt />,
          primaryText: 'Orígenes',
          key: 'view_origins'
        },
        {
          to: '/catalogos/identificacion',
          icon: <PermIdentityIcon />,
          primaryText: 'Tipo de identificación',
          key: 'view_identification_types'
        },
        {
          to: '/catalogos/asignacion-lead',
          icon: <CallSplitIcon />,
          primaryText: 'Asignación de Leads',
          key: 'view_leads_assignments'
        },
        {
          to: '/catalogos/proformas',
          icon: <ReceiptIcon />,
          primaryText: 'Proformas',
          key: 'view_proforma_menu'
        },
        // {
        //   to: '/catalogos/stands',
        //   icon: <LocalConvenienceStore />,
        //   primaryText: 'Stands',
        //   key: 'stands'
        // },
        {
          to: '/catalogos/noticias',
          icon: <ImportContactsIcon />,
          primaryText: 'Noticias',
          key: 'view_news'
        },
        {
          to: '/catalogos/planes',
          icon: <CardMembershipIcon />,
          primaryText: 'Planes',
          key: 'view_plans'
        },
        {
          to: '/catalogos/grupos',
          icon: <Group />,
          primaryText: 'Grupos',
          key: 'view_groups'
        },
      ];

      const company = JSON.parse(localStorage.getItem('company'));
      const profileId = localStorage.getItem('profileId');
      const validStansMenu = company && company._id === CONSTANTS.TRIVO_COMPANY_ID && CONSTANTS.ADMIN_ROL_ID === profileId;
      
      const commissionsOptionsRows = commissionsOptions.map((option, index) => {
        if (permissions[option.key]) {
          if (permissions[option.key] === true ) {
            return (
              <Link key={index} to={option.to} className={classes.link} href="null">
                <ListItem button onClick={this.props.handleDrawerClose}>
                  <ListItemIcon>
                    {option.icon}
                  </ListItemIcon>
                  <ListItemText primary={option.primaryText} />
                </ListItem>
              </Link>
            );
          }
        }
        return null;
      }).filter(obj => obj !== null);
      
      const seguimientoOptionsRows = seguimientoOptions.map((option, index) => {
        if (permissions[option.key]) {
          if (permissions[option.key] === true) {
            return (
              <Link key={index} to={option.to} className={classes.link} href="null">
                <ListItem button onClick={this.props.handleDrawerClose}>
                  <ListItemIcon>
                    {option.icon}
                  </ListItemIcon>
                  <ListItemText primary={option.primaryText} />
                </ListItem>
              </Link>
            );
          }
        }
        return null;
      }).filter(obj => obj !== null);

      const catalogOptionsRows = catalogOptions.map((option, index) => {
        if (permissions[option.key] || (option.key === 'stands' && validStansMenu)) {
          if (permissions[option.key] === true || (option.key === 'stands' && validStansMenu)) {
            return (
              <Link key={index} to={option.to} className={classes.link} href="null">
                <ListItem button onClick={this.props.handleDrawerClose}>
                  <ListItemIcon>
                    {option.icon}
                  </ListItemIcon>
                  <ListItemText primary={option.primaryText} />
                </ListItem>
              </Link>
            );
          }
        }
        return null;
      }).filter(obj => obj !== null);
      return (
        <List>
          {this.renderRows()}
          {
            (JSON.parse(localStorage.getItem('isBroker')) !== true && seguimientoOptionsRows.length > 0) && (
              <div>
                <Divider />
                <ListItem button onClick={() => this.setState({openSeguimiento: !this.state.openSeguimiento})}>
                  <ListItemIcon>
                    <ControlCameraIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Seguimiento" />
                  {this.state.openSeguimiento ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openSeguimiento} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding onClick={this.props.handleDrawerClose}>
                    {seguimientoOptionsRows}
                  </List>
                </Collapse>
                <Divider />
              </div>
            )
          }
          {
            (JSON.parse(localStorage.getItem('isBroker')) !== true && commissionsOptionsRows.length > 0) && (
              <div>
                <Divider />
                <ListItem button onClick={() => this.setState({openCommissions: !this.state.openCommissions})}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Comisiones" />
                  {this.state.openCommissions ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openCommissions} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding onClick={this.props.handleDrawerClose}>
                    {commissionsOptionsRows}
                  </List>
                </Collapse>
                <Divider />
              </div>
            )
          }
          {
            (JSON.parse(localStorage.getItem('isBroker')) !== true && catalogOptionsRows.length > 0) && (
              <div>
                <ListItem button onClick={() => this.setState({openCatalogues: !this.state.openCatalogues})}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Configuración" />
                  {this.state.openCatalogues ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openCatalogues} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding onClick={this.props.handleDrawerClose}>
                    {catalogOptionsRows}
                  </List>
                </Collapse>
              </div>
            )
          }
        </List>
      );
    }
    return null;
  }

  renderSnack() {
    const {message, error} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          message={message}
          variant={error === null ? 'success' : 'error'}
        />   
      );
    }
    return null;
  }

  render() {
    const {classes, open, isLoadingUser, isLoading} = this.props;
    return isLoadingUser ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <div>
        <Drawer
          classes={{paper: classNames(classes.drawerPaper)}}
          open={open}
          onClose={this.props.handleDrawerClose}
        >
          {
            isLoading ?
              <CircularProgress style={{position: 'absolute', left: '40%', top: '50%'}} />
              :
              this.renderDrawerList()
          }
          <Divider />
        </Drawer>
        {this.renderSnack()}
      </div>
    );
  }
}

CustomDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object,
  open: PropTypes.bool.isRequired,
  isLoadingUser: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  getProfile: PropTypes.func.isRequired,
  error: PropTypes.object
};
const mapStateToProps = (state) => {
  const {drawer, user, profiles} = state;
  return { 
    isLoading: drawer.sending,
    isLoadingUser: user.sending,
    message: drawer.message,
    user: user.user,
    profile: profiles.profile,
    error: drawer.error
  };
};
const mapDispatchToProps = {
  getProfile,
  getUser
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomDrawer));   
