/* eslint-disable no-param-reassign */
import axios from 'axios';
import { axiosMaker, errorMessage } from '../utils/utils';
import * as smartActions from './types/smart';

const company = JSON.parse(localStorage.getItem('company'));

export const getForm = () => async (dispatch) => {
  dispatch({
    type: smartActions.GET_FORM_SENDING,
    payload: {
      form: [],
      dataLength: 0,
    },
  });
  try {
    const response = await axios(axiosMaker('GET', 'salesProfiling', null, true));
    return dispatch({
      type: smartActions.GET_FORM_SUCCESS,
      payload: {
        form: response.data,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: smartActions.GET_FORM_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const createForm = (unitData, unitId, project, leadId) => async (dispatch) => {
  const data = {
    answers: unitData,
    unit: unitId,
    project,
    company: company && company._id,
    leadId,
  };
  dispatch({
    type: smartActions.CREATE_FORM_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('POST', 'salesProfiling', data, true));
    return dispatch({
      type: smartActions.CREATE_FORM_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: smartActions.CREATE_FORM_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updateForm = (unitData, unitId, project, leadId) => async (dispatch) => {
  const data = {
    answers: unitData,
    unit: unitId,
    project,
    company: company && company._id,
    leadId,
  };
  dispatch({
    type: smartActions.CREATE_FORM_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PUT', 'salesProfiling', data, true));
    return dispatch({
      type: smartActions.CREATE_FORM_SUCCESS,
      payload: {
        form: [],
        answers: [],
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: smartActions.CREATE_FORM_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getDemandGraphs = (filters) => async (dispatch) => {
  dispatch({
    type: smartActions.GET_GRAPHS_SENDING,
    payload: {
      graphs: [],
    },
  });

  const filterGraphs = [
    'GENDER',
    'CIVIL_STATUS',
    'DATE_OF_BIRTH',
    'PROFESSION',
    'JOB_TYPE',
    'REVENUE_RANGE',
    'RESIDENCE_PLACE',
    'PROPERTY_TYPE',
    'HOME_ZONE',
    'DECISION_REASON',
    'SALE_TRIGGER',
    'LIFECYCLE_PHASE',
    'CREDIT_BANK',
    'DISCOVERY_WAY',
    'CLOSING_TIME',
  ];

  try {
    const response = await axios(
      axiosMaker(
        'POST',
        'trivoSmart/demand/graphs',
        { graphs: filterGraphs, filters },
        true,
        /* 'serverless', */
      ),
    );
    const { graphs, unitsCount, projectsCount, priceAvg, typesCount, availability } = response.data;

    return dispatch({
      type: smartActions.GET_GRAPHS_SUCCESS,
      payload: {
        graphs,
        unitsCount,
        projectsCount,
        priceAvg,
        typesCount,
        availability,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: smartActions.GET_GRAPHS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getAnswers = (unit) => async (dispatch) => {
  dispatch({
    type: smartActions.GET_ANSWERS_SENDING,
    payload: {
      answers: [],
    },
  });
  try {
    const response = await axios(axiosMaker('GET', `salesProfilingAnswers/${unit}`, null, true));
    return dispatch({
      type: smartActions.GET_ANSWERS_SUCCESS,
      payload: {
        answers: response.data,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: smartActions.GET_ANSWERS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const uploadTrivoSmartSupply = async (data, version) => {
  const request = axiosMaker('POST', 'trivoSmart/supply/upload', { data, version }, true);
  const response = await axios(request);
  return response.data;
};

export const getTrivoSmartSupplyVersions = async () => {
  const request = axiosMaker('GET', 'trivoSmart/supply/versions', null, true, 'serverless');
  const response = await axios(request);
  return response.data;
};

export const getSupplyGraphs = (filter = {}) => async (dispatch, getState) => {
  dispatch({
    type: smartActions.GET_SUPPLY_GRAPHS_SENDING,
    payload: {},
  });

  const { smart } = getState();

  const withFilters = !smart.supplyFilters;

  try {
    const response = await axios(
      axiosMaker(
        'POST',
        'trivoSmart/supply/graphs',
        { filter, company: company._id, withFilters },
        true,
        /* 'serverless', */
      ),
    );
    const { resume: supplyResume, graphs: supplyGraphs, filters } = response.data;

    return dispatch({
      type: smartActions.GET_SUPPLY_GRAPHS_SUCCESS,
      payload: {
        supplyResume,
        supplyGraphs,
        supplyFilters: filters || smart.supplyFilters,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: smartActions.GET_SUPPLY_GRAPHS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};
