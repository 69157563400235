import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Link, Fab, Paper, Chip } from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { useSelector, useDispatch } from 'react-redux';
import { styles } from './styles';
import {
  setZones,
  setCities,
  setRooms,
  setUnitTypes,
  setProjectStatus,
  setSectors,
  setProjects,
  orderChips,
} from '../../../actions/smartSupplyFilters';

const FilterPaper = (props) => {
  const { classes } = props;

  const dispatch = useDispatch();

  const { chipData, sectors, cities, zones, unitTypes, rooms, projects, status } = useSelector(
    (state) => state.smartSupplyFilters,
  );

  const handleDelete = (chipToDelete) => () => {
    const newChips = chipData.filter((chip) => chip.key !== chipToDelete.key);
    dispatch(orderChips(newChips));

    if (chipToDelete.type === 'sector')
      dispatch(setSectors(sectors.filter((item) => item !== chipToDelete.id)));
    else if (chipToDelete.type === 'zone')
      dispatch(setZones(zones.filter((item) => item !== chipToDelete.id)));
    else if (chipToDelete.type === 'city')
      dispatch(setCities(cities.filter((item) => item !== chipToDelete.id)));
    else if (chipToDelete.type === 'room')
      dispatch(setRooms(rooms.filter((item) => item !== chipToDelete.id)));
    else if (chipToDelete.type === 'unitType')
      dispatch(setUnitTypes(unitTypes.filter((item) => item !== chipToDelete.id)));
    else if (chipToDelete.type === 'project')
      dispatch(setProjects(projects.filter((item) => item !== chipToDelete.id)));
    else if (chipToDelete.type === 'status')
      dispatch(setProjectStatus(status.filter((item) => item !== chipToDelete.id)));
  };

  const renderFilters = () => {
    const chipColor = (filterType) => {
      return [
        { type: 'sector', color: '#c6f1d6' },
        { type: 'zone', color: '#f4efd3' },
        { type: 'city', color: '#ffba92' },
        { type: 'room', color: '#e0f5b9' },
        { type: 'unitType', color: '#ff8080' },
        { type: 'project', color: '#f4efd3' },
        { type: 'status', color: '#f4efb8' },
      ].find((item) => item.type === filterType).color;
    };

    return (
      <Paper component="div" className={classes.filterItems}>
        {chipData.map((data) => {
          return (
            <span key={data.key}>
              <Chip
                label={data.label}
                onDelete={data.type === 'date' ? undefined : handleDelete(data)}
                className={classes.chip}
                style={{ backgroundColor: chipColor(data.type) }}
              />
            </span>
          );
        })}
      </Paper>
    );
  };

  return (
    <div className={classes.filterContent}>
      <Toolbar>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6">Filtros actuales</Typography>

          <Link href="/trivo-smart-demanda">
            <Fab
              variant="extended"
              color="secondary"
              size="medium"
              className={classes.toolbarButton}
            >
              <SwapHorizIcon className={classes.extendedIcon} />
              Ver Demanda
            </Fab>
          </Link>
        </div>
      </Toolbar>
      {chipData.length ? (
        renderFilters()
      ) : (
        <Paper component="div" className={classes.filterItems}>
          <Chip
            label="Sin filtros"
            className={classes.chip}
            style={{ backgroundColor: '#58c445' }}
          />
        </Paper>
      )}
    </div>
  );
};

FilterPaper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilterPaper);
