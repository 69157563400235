import React from 'react';
import { withStyles } from '@material-ui/core';
import GroupAdd from '@material-ui/icons/PersonAdd';
import Event from '@material-ui/icons/Event';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { styles } from './styles';

const Kpis = (props) => {
  const { classes, kpis } = props;

  return (
    <>
      <div className={classes.separator}>
        <hr></hr>
        <h5>Estos son tus KPI</h5>
      </div>

      <div className={classes.kpiCard}>
        <div className={classes.kpiCardItem}>
          <div className={`${classes.cardItem} ${classes.primary}`}>
            <div>
              <h5>Leads a visitas</h5>
              <h2>{(kpis && kpis.leadPerVisits) || '0 %'}</h2>
            </div>
            <div>
              <span className={classes.badgeKpi}>
                <GroupAdd />
              </span>
            </div>
          </div>
        </div>
        <div className={classes.kpiCardItem}>
          <div className={`${classes.cardItem} ${classes.secondary}`}>
            <div>
              <h5>Visitas a cierres</h5>
              <h2>{(kpis && kpis.visitsPerWonLeads) || '0 %'}</h2>
            </div>
            <div>
              <span className={classes.badgeKpi}>
                <Event />
              </span>
            </div>
          </div>
        </div>
        <div className={classes.kpiCardItem}>
          <div className={`${classes.cardItem} ${classes.default}`}>
            <div>
              <h5>Leads a cierres</h5>
              <h2>{(kpis && kpis.newLeadsPerWonLeads) || '0 %'}</h2>
            </div>
            <div>
              <span className={`${classes.badgeKpi} ${classes.badgeKpiPrimary}`}>
                <AttachMoney />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(Kpis);
