export const GET_LEADS_ASSIGNMENT_SUCCESS = 'GET_LEADS_ASSIGNMENT_SUCCESS';

export const GET_LEADS_ASSIGNMENT_SENDING = 'GET_LEADS_ASSIGNMENT_SENDING';

export const GET_LEADS_ASSIGNMENT_FAILED = 'GET_LEADS_ASSIGNMENT_FAILED';

export const UPDATE_LEAD_ASSIGNMENT_SUCCESS = 'UPDATE_LEAD_ASSIGNMENT_SUCCESS';

export const UPDATE_LEAD_ASSIGNMENT_SENDING = 'UPDATE_LEAD_ASSIGNMENT_SENDING';

export const UPDATE_LEAD_ASSIGNMENT_FAILED = 'UPDATE_LEAD_ASSIGNMENT_FAILED';
