import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {MenuItem, Select} from '@material-ui/core';
import {SUPER_ADMIN_ROL_ID} from '../../../../utils/utils';

export class WhatsappTemplatesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      template: '',
      name: '',
      company: ''
    };
    this.renderButton = this.renderButton.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
  }

  componentWillMount() {
    const {isNew, whatsappTemplate} = this.props;
    if (isNew === false) {
      this.setState({
        whatsappTemplate,
        name: whatsappTemplate.name,
        template: whatsappTemplate.template,
        active: whatsappTemplate.active,
        company: whatsappTemplate.companyId
      });
    }
  }

  onAddButtonClick() {
    const {template, name, company} = this.state;
    this.props
    .handleCreateWhatsappTemplate({template, name, company})
    .then(() => {
      this.handleGoBack();
    });
  }

  async onEditButtonClick() {
    const {whatsappTemplate, active, template, name, company} = this.state;
    const whatsappTemplateEdited = {
      id: whatsappTemplate.id,
      active,
      template,
      name,
      company
    };
    this.props.handleUpdateWhatsappTemplate(whatsappTemplateEdited);
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  handleChangeCompany(e) {
    const company = e.target.value;
    this.setState({company});
  }

  isInvalid() {
    const {template, name, company} = this.state;
    const profileId = localStorage.getItem('profileId');
    if (template === '' || name === '') {
      return true;
    }
    if (SUPER_ADMIN_ROL_ID === profileId && (company === '')) { return true; }
    return false;
  }

  renderCompanies() {
    const {companies} = this.props;
    return companies.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>
          {item.name}
        </MenuItem>
      );
    });
  }

  renderRowCompany(classes) {
    const profileId = localStorage.getItem('profileId');
    const {company} = this.state;
    return (
      SUPER_ADMIN_ROL_ID === profileId && (
        <TableRow>
          <TableCell className={classes.tableRowTitle}>
            <b>Empresa</b>
          </TableCell>
          <TableCell>
            <Select
              value={company}
              fullWidth
              onChange={this.handleChangeCompany}
            >
              {this.renderCompanies()}
            </Select>
          </TableCell>
        </TableRow>
      )
    );
  }

  renderButton() {
    const {isLoading, isNew, classes} = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText={`¿Está seguro que desea ${
            isNew ? 'crear' : 'modificar'
          } este mensaje de Whatsapp?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} mensaje de Whatsapp`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  render() {
    const {classes, isLoading, isNew} = this.props;
    return isLoading === true ? (
      <CircularProgress
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto'
        }}
      />
    ) : (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de ' : 'Nuevo '} mensaje de Whatsapp
          </Typography>
        </Toolbar>
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              {!isNew && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Disponible</b>
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.active}
                          onChange={e =>
                            this.setState({active: e.target.checked})
                          }
                          color="primary"
                        />
                      }
                      label={
                        this.state.active === true
                          ? 'Disponible'
                          : 'No disponible'
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
              {this.renderRowCompany(classes)}
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Título</b>
                </TableCell>
                <TableCell>
                  <TextField
                    autoFocus
                    value={this.state.name}
                    onChange={e => this.setState({name: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Mensaje</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.template}
                    onChange={e =>
                      this.setState({template: e.target.value})
                    }
                    fullWidth
                    multiline
                    rows={4}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>{this.renderButton()}</div>
      </Paper>
    );
  }
}

WhatsappTemplatesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  whatsappTemplate: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleCreateWhatsappTemplate: PropTypes.func.isRequired,
  handleUpdateWhatsappTemplate: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired
};

export default withStyles(styles)(WhatsappTemplatesDetails);
