import { orange500, white } from 'material-ui/styles/colors';

export const styles = (theme) => ({
  small: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 70,
    textAlign: 'center',
  },
  medium: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 150,
    textAlign: 'center',
  },
  auto: {
    textAlign: 'left',
  },
  editableText: {
    textAlign: 'left',
    minWidth: 250,
  },
  date: {
    textAlign: 'left',
    minWidth: 180,
  },
  button: {
    padding: 0,
    fontWeight: 'normal',
    textTransform: 'none',
    fontSize: 13,
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'underline',
      background: 'transparent',
      color: '#00af9a',
    },
  },
  temperatureContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  temperatureItem: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
  },
  potential: {
    background: theme.palette.primary.main,
  },
  cold: {
    background: white,
    border: '1px solid lightgray',
  },
  hot: {
    background: orange500,
  },
  assignment: {
    cursor: 'pointer',
    color: '#006064',
  },
  dateLink: {
    textAlign: 'center',
    minWidth: 180,
  },
  cellPadding: {
    padding: '4px 20px 4px 20px',
  },
});

export default styles;
