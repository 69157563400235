export const styles = theme => ({
  attributesContainer: {
    width: '90%',
    margin: '0 auto'
  },
  submitContainer: {
    display: 'table',
    margin: '0 auto'
  },
  paperRoot: {
    width: '95%',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 3,
    padding: '40px'

  },
  questionContainer: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  errorDiv:{
    color: 'red',
    marginTop: '-10px',
    paddingBottom: '5px'
  },
  errorDiv2:{
    color: 'red',
  },
  headerContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  subtitle: {
    textAlign:'center'
  }
});

export default styles;
