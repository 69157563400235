export const GET_OBSERVATIONS_SUCCESS = 'GET_OBSERVATIONS_SUCCESS';

export const GET_OBSERVATIONS_SENDING = 'GET_OBSERVATIONS_SENDING';

export const GET_OBSERVATIONS_FAILED = 'GET_OBSERVATIONS_FAILED';


export const FILTER_OBSERVATIONS_SUCCESS = 'FILTER_OBSERVATIONS_SUCCESS';

export const FILTER_OBSERVATIONS_SENDING = 'FILTER_OBSERVATIONS_SENDING';

export const FILTER_OBSERVATIONS_FAILED = 'FILTER_OBSERVATIONS_FAILED';
