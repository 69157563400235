export const GET_PROPERTY_TYPES_SUCCESS = 'GET_PROPERTY_TYPES_SUCCESS';

export const GET_PROPERTY_TYPES_SENDING = 'GET_PROPERTY_TYPES_SENDING';

export const GET_PROPERTY_TYPES_FAILED = 'GET_PROPERTY_TYPES_FAILED';

export const FILTER_PROPERTY_TYPES_SUCCESS = 'FILTER_PROPERTY_TYPES_SUCCESS';

export const FILTER_PROPERTY_TYPES_SENDING = 'FILTER_PROPERTY_TYPES_SENDING';

export const FILTER_PROPERTY_TYPES_FAILED = 'FILTER_PROPERTY_TYPES_FAILED';

export const CREATE_PROPERTY_TYPES_SUCCESS = 'CREATE_PROPERTY_TYPES_SUCCESS';

export const CREATE_PROPERTY_TYPES_SENDING = 'CREATE_PROPERTY_TYPES_SENDING';

export const CREATE_PROPERTY_TYPES_FAILED = 'CREATE_PROPERTY_TYPES_FAILED';

export const UPDATE_PROPERTY_TYPES_SUCCESS = 'UPDATE_PROPERTY_TYPES_SUCCESS';

export const UPDATE_PROPERTY_TYPES_SENDING = 'UPDATE_PROPERTY_TYPES_SENDING';

export const UPDATE_PROPERTY_TYPES_FAILED = 'UPDATE_PROPERTY_TYPES_FAILED';

