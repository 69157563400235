import banner from '../../assets/upgrade.png';

const drawerWidth = 240;

export const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  rootGrow: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  mainContainer: {
    width: 'calc(100vw - 34px)',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100vw - 275px)',
    },
  },
  menuButton: {
    marginRight: '10px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: '20px',
  },
  filterBar: {
    margin: '10px',
  },
  filterItems: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#fff',
    padding: '5px',
    marginBottom: 0,
    listStyle: 'none',
    // display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  rootCharts: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  filterContent: {
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#fff',
    marginBottom: '20px',
    borderRadius: 12,
  },
  indicatorContent: {
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    marginBottom: '20px',
    borderRadius: 16,
    height: 250,
    overflow: 'hidden',
    position: 'relative',
    '&.primary': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.secondary': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  zoneLabel: {
    padding: theme.spacing.unit * 2,
    fontSize: 18,
    fontWeight: 'bold',
    color: 'grey',

    '&:focus': {
      outline: 'none',
    },
  },
  zoneMenu: {
    paddingLeft: theme.spacing.unit * 5,
  },
  chip: {
    margin: 3,
  },
  filterTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'grey',
  },
  topIndicator: {
    textAlign: 'start',
    '& span': {
      fontSize: 14,
      fontWeight: 'bold',
      color: 'white',
    },
    '& p>span': {
      paddingLeft: 12,
      fontSize: 19,
      fontWeight: 'bold',
    },
  },
  topIndicatorSecondary: {
    textAlign: 'start',
    '& span': {
      fontSize: 14,
      fontWeight: 'bold',
      color: 'white',
    },
    '& p>span': {
      paddingLeft: 12,
      fontSize: 28,
      fontWeight: 'bold',
    },
  },
  unitIndicator: {
    textAlign: 'start',
    paddingRight: 0,
    '& span': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
      fontWeight: 'bold',
      color: 'white',
    },
    '& ul': {
      paddingTop: 0,
    },
    '& li': {
      paddingTop: 2,
      paddingBottom: 2,
    },
  },
  unitRoomsIndicator: {
    paddingRight: 0,
    paddingLeft: '16px !important',
    '& span': {
      fontWeight: '500',
      color: 'white',
      [theme.breakpoints.down('md')]: {
        fontSize: 11,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 13,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
      },
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 8,
    },
  },
  typeIndicatorSubtitle: {
    lineHeight: '28px',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.primary.main,
    margin: '16px 0',
  },
  typeIndicatorName: {
    '& span': {
      color: 'grey',
      fontWeight: '100',
    },
  },
  typeIndicatorCount: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    paddingRight: 4,
  },
  switchText: {
    paddingLeft: 0,
    '& span': {
      fontSize: 16,
      fontWeight: 'bold',
      color: 'grey',
    },
  },
  filterButton: {
    padding: 12,
    '& button': {
      width: '100%',
    },
  },
  loader: {
    height: 20,
    position: 'absolute',
  },
  paperCharts: {
    borderRadius: 16,
    '& div': { borderRadius: 16 },
  },
  backgroundIcon: {
    position: 'absolute',
    right: -45,
    top: 25,
    width: '260px !important',
    height: 260,
    color: 'rgb(255 255 255 / 24%)',
  },
  cityChip: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    margin: '0 16px',
    fontSize: 10,
    fontWeight: '400',
    height: 16,
    borderRadius: 4,
    '& span': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  newsCard: {
    width: '100%',
    height: '100%',
    backgroundColor: '#006064',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    '& h2': {
      fontSize: '3rem',
    },
  },
  button: {
    color: 'white',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    padding: 12,
  },
  upgradeItem: {
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
    backgroundImage: `url(${banner})`,
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  upgradeButton: {
    backgroundColor: 'red',
    backgroundColor: '#F9B233',
    marginTop: '20px',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  toolbarContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarButton: {
    textTransform: 'none',
    color: 'white',
    '&:focus': {
      outline: 'none',
    },
  },
});

export default styles;
