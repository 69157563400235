/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightOutlined from '@material-ui/icons/ChevronRightOutlined';
import { withStyles } from '@material-ui/core/styles';
import CountUp from 'react-countup';
import { Paper, ListItem, ListItemText, Fab, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import { useSelector, useDispatch } from 'react-redux';
// import boost from 'highcharts/modules/boost';
import drilldown from 'highcharts/modules/drilldown.js';
import { styles } from './styles';
import Chart from './Chart';
import {
  columnLineFormater,
  columnAndLineReportFormater,
  dynamicColumnAndLineReportFormater,
  dynamicColumnAndLineYAxissReportFormater,
  formatDynamicPie,
  formatDynamicPieReverse,
  stackColumnAndLineYAxissReportFormater,
} from './chartFunctions';
import { userHasTrivoSmart } from '../../actions/users';
import SimpleTable from '../common/simpleTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TRIVO_PLAN, FREE_PLAN, PLANS_TO_UPGRADE, UPGRADE_PLAN_LINK, PROJECT_PLANS } from '../../utils/utils';
import SimpleMap from '../common/simpleMap';
import upgradeGraph from '../../assets/upgrade-graph.png';
import FilterDrawer from './filterDrawer';
import FilterPaper from './filterPaper';
import { changeFilters } from '../../actions/smartSupplyFilters';

highchartsMore(Highcharts);
drilldown(Highcharts);

(function (H) {
  H.seriesTypes.pie.prototype.drawEmpty = function () {
    var centerX,
      centerY,
      start = this.startAngleRad,
      end = this.endAngleRad,
      options = this.options;
    // Draw auxiliary graph if there're no visible points.
    if (this.total === 0) {
      centerX = this.center[0];
      centerY = this.center[1];
      if (!this.graph) {
        this.graph = this.chart.renderer
          .arc(centerX, centerY, this.center[1] / 2, 0, start, end)
          .addClass('highcharts-empty-series')
          .add(this.group);
      }
      this.graph.attr({
        d: H.SVGRenderer.prototype.symbols.arc(centerX, centerY, this.center[2] / 2, 0, {
          start: start,
          end: end,
          innerR: this.center[3] / 2,
        }),
      });
      if (!this.chart.styledMode) {
        this.graph.attr({
          'stroke-width': options.borderWidth,
          fill: options.fillColor || 'none',
          stroke: options.color || '#cccccc',
        });
      }
    } else if (this.graph) {
      // Destroy the graph object.
      this.graph = this.graph.destroy();
    }
  };
})(Highcharts);
// boost(Highcharts);

const CustomFab = ({ classes }) => {
  const dispatch = useDispatch();

  const { disabledFilter } = useSelector((state) => state.smartSupplyFilters);

  return (
    <Fab
      variant="extended"
      color="primary"
      className={classes.fab}
      disabled={disabledFilter}
      onClick={() => dispatch(changeFilters())}
    >
      <FontAwesomeIcon icon="filter" className={classes.extendedIcon} />
      Filtrar
    </Fab>
  );
};

const TrivoSmartOffer = (props) => {
  const { classes } = props;

  const { supplyGraphs: graphs, supplyResume, sending } = useSelector((state) => state.smart);

  const [upgradePlan, setUpgradePlan] = useState(false);
  const [showProjects, setShowProjects] = useState(false);

  useEffect(() => {
    (async () => {
      const company = JSON.parse(localStorage.getItem('company'));
      const hasTrivoSmartPermission = await userHasTrivoSmart();
      const userWithTrivoSmart = company && company.hasTrivoSmart && hasTrivoSmartPermission;
      if (!userWithTrivoSmart) props.history.push('/');
      setUpgradePlan(PLANS_TO_UPGRADE.split(',').includes(company.plan));
      setShowProjects(PROJECT_PLANS.split(',').includes(company.plan));
    })();
  }, []);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderCharts = () => {
    const graphTypes = {
      COLUMN_LINE: columnLineFormater,
      COLUMN_LINE_REPORT: columnAndLineReportFormater,
      DYNAMIC_COLUMN_LINE_REPORT: dynamicColumnAndLineReportFormater,
      DYNAMIC_COLUMN_LINE_YAXIS_REPORT: dynamicColumnAndLineYAxissReportFormater,
      PIE_REPORT: formatDynamicPieReverse,
      STACK_COLUMN_LINE_YAXIS_REPORT: stackColumnAndLineYAxissReportFormater,
    };

    return (
      <Grid container className={classes.mainContainer} spacing={24}>
        {upgradePlan && (
          <Grid item lg={6} xs={12}>
            <Paper className={classes.paperCharts}>
              <div className={classes.upgradeItem}>
                <div>
                  <img width={200} src={upgradeGraph} />
                </div>
                <div>
                  <Button
                    variant="contained"
                    className={classes.upgradeButton}
                    href={UPGRADE_PLAN_LINK}
                    target="_blank"
                  >
                    UPGRADE A PREMIUM
                  </Button>
                </div>
              </div>
            </Paper>
          </Grid>
        )}
        {graphs.map(({ type, data, fullColumn }, index) => (
          <Grid item lg={fullColumn ? 12 : 6} xs={12} key={`graph_${index}`}>
            <Paper className={classes.paperCharts}>
              {type === 'SIMPLE_TABLE' ? (
                <SimpleTable
                  rows={data.results}
                  headRows={data.headRows}
                  title={data.title}
                  subtitle={data.subtitle}
                  downloadable={data.downloadable}
                />
              ) : type === 'MAP_POINTS' ? (
                <SimpleMap
                  points={data}
                  setShowProjects
                  title={'Este es un mapa con puntos'}
                />
              ) : (
                <Chart
                  options={{
                    ...graphTypes[type](...data),
                    credits: {
                      enabled: false,
                    },
                    plotOptions: {
                      series: {
                        animation: false,
                      },
                    },
                  }}
                  highcharts={Highcharts}
                />
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderIndicators = (
    <Grid alignItems="center" container className={classes.rootGrow} spacing={24}>
      <Grid item md={6} xs={12}>
        <div className={`${classes.indicatorContent} primary`}>
          <FontAwesomeIcon icon="home" className={classes.backgroundIcon} />
          <Grid item xs={12} className={classes.rootGrow}>
            <ListItem>
              <ListItemText
                className={classes.topIndicator}
                primary="Filtradas:"
                secondary={
                  <>
                    <CountUp
                      duration={2}
                      end={supplyResume.totalUnits || 0}
                      suffix={` ${(supplyResume.totalUnits || 0) === 1 ? 'Unidad' : 'Unidades'}`}
                    />
                    <br />
                    <CountUp
                      duration={2}
                      end={supplyResume.availableUnits || 0}
                      suffix={` ${
                        (supplyResume.availableUnits || 0) === 1
                          ? 'Unidad disponible'
                          : 'Unidades disponibles'
                      }`}
                    />
                  </>
                }
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} className={classes.rootGrow}>
            <ListItem>
              <ListItemText
                className={classes.topIndicator}
                primary="De:"
                secondary={
                  <CountUp
                    duration={2}
                    end={supplyResume.projects || 0}
                    suffix={` ${(supplyResume.projects || 0) === 1 ? 'Proyecto' : 'Proyectos'}`}
                  />
                }
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} className={classes.rootGrow}>
            <ListItem>
              <ListItemText
                className={classes.topIndicator}
                primary={` ${
                  (supplyResume.builders || 0) === 1 ? 'Perteneciente a:' : 'Pertenecientes a:'
                }`}
                secondary={
                  <CountUp
                    duration={2}
                    end={supplyResume.builders || 0}
                    suffix={` ${
                      (supplyResume.builders || 0) === 1 ? 'Constructora' : 'Constructoras'
                    }`}
                  />
                }
              />
            </ListItem>
          </Grid>
        </div>
      </Grid>
      <Grid item md={6} xs={12}>
        <div className={`${classes.indicatorContent} secondary`}>
          <FontAwesomeIcon icon="map-marker-alt" className={classes.backgroundIcon} />
          <Grid item xs={12} className={classes.rootGrow}>
            <ListItem>
              <ListItemText
                className={classes.topIndicatorSecondary}
                primary="Filtrados:"
                secondary={
                  <CountUp
                    duration={2}
                    end={supplyResume.sectors || 0}
                    suffix={` ${(supplyResume.sectors || 0) === 1 ? 'Sector' : 'Sectores'}`}
                  />
                }
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} className={classes.rootGrow}>
            <ListItem>
              <ListItemText
                className={classes.topIndicatorSecondary}
                primary={`${(supplyResume.zones || 0) === 1 ? 'Ubicada en:' : 'Ubicadas en:'}`}
                secondary={
                  <CountUp
                    duration={2}
                    end={supplyResume.zones || 0}
                    suffix={` ${(supplyResume.zones || 0) === 1 ? 'Zona' : 'Zonas'}`}
                  />
                }
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} className={classes.rootGrow}>
            <ListItem>
              <ListItemText
                className={classes.topIndicatorSecondary}
                primary="De:"
                secondary={
                  <CountUp
                    duration={2}
                    end={supplyResume.cities || 0}
                    suffix={` ${(supplyResume.cities || 0) === 1 ? 'Ciudad' : 'Ciudades'}`}
                  />
                }
              />
            </ListItem>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <FilterDrawer
        showProjects={showProjects}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <main
        onCopy={() => {
          return false;
        }}
        onCut={() => {
          return false;
        }}
        className={classes.content}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <ChevronRightOutlined color="primary" />
        </IconButton>
        <FilterPaper />
        {renderIndicators}
        {renderCharts()}
      </main>
      <CustomFab {...props} />
    </div>
  );
};

TrivoSmartOffer.propTypes = {
  history: PropTypes.any,
};

export default withStyles(styles)(TrivoSmartOffer);
