import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {filterIdentificationTypes, createIdentificationType, updateIdentificationType} from '../../../actions/identificationTypes';
import {getProfile} from '../../../actions/profiles';
import EnhancedTable from '../../common/enhancedTable';
import {identificationTypesCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import IdentificationTypeDetail from './detail';

export class IdentificationTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identificationType: null,
      isNew: false,
      title: 'Tipo de identificación'
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleUpdateIdentificationType = this.handleUpdateIdentificationType.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.filterIdentificationTypes(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  onAdd() {
    this.setState({isNew: true, identificationType: {}});
  }

  onDetail(id) {
    const identificationType = this.props.identificationTypes.filter(obj => obj.id === id)[0];
    this.setState({identificationType, isNew: false});
  }

  onOrder(order, orderBy) {
    this.props.filterIdentificationTypes(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText);
  }

  onSearch(searchText) {
    this.props.filterIdentificationTypes(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_identification_types) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  };

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_profiles) {
        return true;
      }
    }
    return false;
  }

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_profiles) {
        return true;
      }
    }
    return false;
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterIdentificationTypes(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleChangePage(page) {
    this.props.filterIdentificationTypes(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleGoBack() {
    this.props.filterIdentificationTypes(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
    this.setState({identificationType: null, isNew: false});
  }

  handleUpdateIdentificationType(identificationTypeEdited) {
    const {identificationType} = this.state;
    this.setState({identificationType: identificationTypeEdited});
    const edited = {};
    if (identificationType.status !== identificationTypeEdited.status) {
      edited.status = identificationTypeEdited.status;
    }
    if (identificationType.name !== identificationTypeEdited.name) {
      edited.name = identificationTypeEdited.name;
    }
    this.props.updateIdentificationType(identificationTypeEdited.id, edited);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, isLoadingProfile, identificationTypes, dataLength} = this.props;
    const {title, identificationType, isNew} = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const data = {
      rows: identificationTypes,
      cols: identificationTypesCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };
    if (isLoading === true || isLoadingProfile === true) {
      return (
        <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {
          identificationType === null ?
            <EnhancedTable
              title={title}
              data={data}
              handlers={handlers}
              config={config}
            />
            :
            <IdentificationTypeDetail
              identificationType={identificationType}
              handleGoBack={this.handleGoBack}
              handleCreateIdentificationType={this.props.createIdentificationType}
              handleUpdateIdentificationType={this.handleUpdateIdentificationType}
              isLoading={isLoading}
              isNew={isNew}
            />
        }
      </div>
    );
  }
}

IdentificationTypes.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  identificationTypes: PropTypes.array.isRequired,
  isLoadingProfile: PropTypes.bool.isRequired,
  filterIdentificationTypes: PropTypes.func.isRequired,
  createIdentificationType: PropTypes.func.isRequired,
  updateIdentificationType: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {identificationTypes} = state;
  return {
    isLoading: identificationTypes.sending,
    identificationTypes: identificationTypes.identificationTypes,
    dataLength: identificationTypes.dataLength,
    error: identificationTypes.error,
    message: identificationTypes.message,
    searchText: identificationTypes.searchText,
    order: identificationTypes.order,
    orderBy: identificationTypes.orderBy,
    page: identificationTypes.page,
    rowsPerPage: identificationTypes.rowsPerPage,
    filters: identificationTypes.filters
  };
};

const mapDispatchToProps = {
  filterIdentificationTypes,
  createIdentificationType,
  updateIdentificationType,
  getProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentificationTypes);
