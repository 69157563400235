import * as profileActions from '../actions/types/profiles';

const initialState = {
  profiles: [],
  permissions: {},
  profile: null,
  message: '',
  dataLength: 0,
  sending: false,
  sendingPermissions: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {profiles, profile, permissions, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case profileActions.FILTER_PROFILES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case profileActions.FILTER_PROFILES_SUCCESS:
    return {...state, sending: false, profiles, error: null, message: 'Perfiles obtenidos satisfactoriamente', dataLength};
  case profileActions.FILTER_PROFILES_FAILED:
    return {...state, sending: false, profiles: [], error, message, dataLength};
  case profileActions.GET_PROFILES_SENDING:
    return {...state, sending: true};
  case profileActions.GET_PROFILES_SUCCESS:
    return {...state, sending: false, profiles, error: null, message: 'Perfiles obtenidos satisfactoriamente', dataLength};
  case profileActions.GET_PROFILES_FAILED:
    return {...state, sending: false, profiles: [], error, message, dataLength};
  case profileActions.GET_PROFILE_SENDING:
    return {...state, sending: true};
  case profileActions.GET_PROFILE_SUCCESS:
    return {...state, sending: false, profile, error: null, message: 'Perfil obtenido satisfactoriamente'};
  case profileActions.GET_PROFILE_FAILED:
    return {...state, sending: false, profiles: [], error, message};
  case profileActions.GET_PERMISSIONS_SENDING:
    return {...state, sendingPermissions: true};
  case profileActions.GET_PERMISSIONS_SUCCESS:
    return {...state, sendingPermissions: false, permissions, error: null, message: 'Permisos obtenidos satisfactoriamente'};
  case profileActions.GET_PERMISSIONS_FAILED:
    return {...state, sendingPermissions: false, permissions: {}, error, message, dataLength};
  case profileActions.CREATE_PROFILE_SENDING:
    return {...state, sending: true};
  case profileActions.CREATE_PROFILE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Perfil creado satisfactoriamente'};
  case profileActions.CREATE_PROFILE_FAILED:
    return {...state, sending: false, error, message};
  case profileActions.UPDATE_PROFILE_SENDING:
    return {...state, sending: true};
  case profileActions.UPDATE_PROFILE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Perfil actualizado satisfactoriamente'};
  case profileActions.UPDATE_PROFILE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
