import React from 'react';
import { Tabs, Tab, withStyles } from '@material-ui/core';
import { styles } from './styles';
import TaskList from '../taskList';

const TasksTabBar = (props) => {
  const { classes, brokers, lead } = props;

  const [selectedTab, setSelectedTab] = React.useState(1);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Tabs value={selectedTab} onChange={handleChange} className={classes.tabContainer}>
        <Tab label="Vencidas" />
        <Tab label="Hoy" />
        <Tab label="Futuras" />
      </Tabs>

      {selectedTab === 0 && (
        <div className={classes.pageContainer}>
          <TaskList brokers={brokers} lead={lead} typeFilter={'EXPIRED'} />
        </div>
      )}
      {selectedTab === 1 && (
        <div className={classes.pageContainer}>
          <TaskList brokers={brokers} lead={lead} typeFilter={'TODAY'} />
        </div>
      )}
      {selectedTab === 2 && (
        <div className={classes.pageContainer}>
          <TaskList brokers={brokers} lead={lead} typeFilter={'FUTURE'} />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(TasksTabBar);
