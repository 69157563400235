/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as leadStateActions from './types/leadStates';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

const getLeadStages = (leadStates) => {
  const leadStages = [];
  if (leadStates.length > 0) {
    leadStates.forEach((leadState) => {
      if (leadState.stages.length > 0) {
        leadState.stages.forEach((stage) => {
          const newStage = stage;
          if ('_id' in stage) {
            newStage.id = stage._id;
          }
          leadStages.push(stage);
        });
      }
    });
  }
  return leadStages;
};

export const getStagesByLead = (leadState) => {
  const leadStages = [];
  if (leadState.stages.length > 0) {
    leadState.stages.forEach((stage) => {
      const newStage = stage;
      if ('_id' in stage) {
        newStage.id = stage._id;
      }
      leadStages.push(stage);
    });
  }
  return leadStages;
};

export const filterLeadStates = (
  page,
  rowsPerPage,
  order,
  orderBy,
  searchText
) => async (dispatch) => {
  if (
    order !== store.getState().leadStates.order ||
    orderBy !== store.getState().leadStates.orderBy
  ) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: leadStateActions.FILTER_LEAD_STATES_SENDING,
    payload: {
      leadStates: [],
      leadStages: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    let filter = {};
    if (
      page !== null &&
      rowsPerPage !== null &&
      order !== null &&
      orderBy !== null &&
      searchText !== null
    ) {
      filter = {
        filters: {
          name: searchText
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order
        },
        company: company && company._id
      };
    }
    const response = await axios(axiosMaker('POST', 'leadStatus/filter', filter, true));
    const leadStages = getLeadStages(response.data.results);
    return dispatch({
      type: leadStateActions.FILTER_LEAD_STATES_SUCCESS,
      payload: {
        leadStates: response.data.results,
        leadStages,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadStateActions.FILTER_LEAD_STATES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getLeadStates = () => async (dispatch) => {
  dispatch({
    type: leadStateActions.GET_LEAD_STATES_SENDING,
    payload: {
      leadStates: [],
      dataLength: 0
    }
  });
  try {
    const sort = {
      order: 'asc'
    };
    const response = await axios(axiosMaker('POST', 'leadStatus/filter', {sort, filters: {}, company: company && company._id}, true));
    const leadStages = getLeadStages(response.data.results);
    return dispatch({
      type: leadStateActions.GET_LEAD_STATES_SUCCESS,
      payload: {
        leadStates: response.data.results,
        leadStages,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadStateActions.GET_LEAD_STATES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createLeadState = data => async (dispatch) => {
  dispatch({
    type: leadStateActions.CREATE_LEAD_STATE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'leadStatus', data, true));
    return dispatch({
      type: leadStateActions.CREATE_LEAD_STATE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: leadStateActions.CREATE_LEAD_STATE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateLeadState = (id, leadStates) => async (dispatch) => {
  dispatch({
    type: leadStateActions.UPDATE_LEAD_STATE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `leadStatus/${id}`, leadStates, true));
    return dispatch({
      type: leadStateActions.UPDATE_LEAD_STATE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadStateActions.UPDATE_LEAD_STATE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const swapStatuses = (oldIndex, newIndex) => async (dispatch) => {
  dispatch({
    type: leadStateActions.SWAP_LEAD_STATE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker(
      'PUT',
      'swapStatuses',
      {a_id: oldIndex, b_id: newIndex},
      true
    ));
    return dispatch({
      type: leadStateActions.SWAP_LEAD_STATE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: leadStateActions.SWAP_LEAD_STATE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
