export const userCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'email', right: false, disablePadding: false, label: 'Email', type: 'text'},
  {id: 'phone', right: false, disablePadding: false, label: 'Teléfono', type: 'text'},
  {id: 'profileName', right: false, disablePadding: false, label: 'Perfil', type: 'text'},
  {id: 'companyName', right: false, disablePadding: false, label: 'Empresa', type: 'text'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'is_asesor', right: false, disablePadding: true, label: 'Asesor', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const parametersCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'options', right: false, disablePadding: false, label: 'Tipo', type: 'parameter_type'},
  {id: 'title', right: false, disablePadding: false, label: 'Título', type: 'text'},
  {id: 'icon', right: false, disablePadding: false, label: 'Ícono', type: 'icon'},
  {id: 'enable_wizard', right: false, disablePadding: true, label: 'Visible cliente', type: 'bool'},
  {id: 'enable_web', right: false, disablePadding: true, label: 'Visible web', type: 'bool'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'enabled', right: false, disablePadding: true, label: 'Habilitado', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const wizardCols = [
  {id: 'order', right: false, disablePadding: false, label: 'Orden', type: 'text'},
  {id: 'category', right: false, disablePadding: false, label: 'Categoría', type: 'category'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'options', right: false, disablePadding: false, label: 'Tipo', type: 'parameter_type'},
  {id: 'title', right: false, disablePadding: false, label: 'Título', type: 'text'},
  {id: 'icon', right: false, disablePadding: false, label: 'Ícono', type: 'icon'}
];

export const paramsOrderCols = [
  {id: 'globalOrder', right: false, disablePadding: false, label: 'Orden', type: 'text'},
  {id: 'category', right: false, disablePadding: false, label: 'Categoría', type: 'category'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'options', right: false, disablePadding: false, label: 'Tipo', type: 'parameter_type'},
  {id: 'title', right: false, disablePadding: false, label: 'Título', type: 'text'},
  {id: 'icon', right: false, disablePadding: false, label: 'Ícono', type: 'icon'}
];


export const editProjectsCols = [
  {id: 'builderId', right: false, disablePadding: false, label: 'Constructora', type: 'editableText', autoCompleteWith: 'builder'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'editableText'},
  {id: 'code', right: false, disablePadding: false, label: 'Código', type: 'editableText'},
  {id: 'cityId', right: false, disablePadding: false, label: 'Ciudad', type: 'editableText', autoCompleteWith: 'city', isNoClearable: true},
  {id: 'zoneId', right: false, disablePadding: false, label: 'Sector', type: 'editableText', autoCompleteWith: 'zone'},
  {id: 'revenue_percentage', right: false, disablePadding: false, label: 'Comisión(%)', type: 'editablePercentage'},
  {id: 'leads', right: false, disablePadding: false, label: 'Leads', type: 'noSortableNumber'},
  {id: 'sold', right: false, disablePadding: false, label: 'Vendidos', type: 'noSortableNumber'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'toggle'},
  {id: 'id', right: false, disablePadding: false, label: 'Editar', type: 'modify'}
];

export const newProjectsCols = [
  {id: 'builderId', right: false, disablePadding: false, label: 'Constructora', type: 'editableText', autoCompleteWith: 'builder'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'editableText'},
  {id: 'code', right: false, disablePadding: false, label: 'Código', type: 'editableText'},
  {id: 'cityId', right: false, disablePadding: false, label: 'Ciudad', type: 'editableText', autoCompleteWith: 'city', isNoClearable: true},
  {id: 'zoneId', right: false, disablePadding: false, label: 'Sector', type: 'editableText', autoCompleteWith: 'zone'},
  {id: 'revenue_percentage', right: false, disablePadding: false, label: 'Comisión(%)', type: 'editablePercentage'},
  {id: 'id', right: false, disablePadding: false, label: 'Guardar', type: 'save'}
];

export const projectsCols = [
  {id: 'builderName', right: false, disablePadding: false, label: 'Constructora', type: 'text'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'code', right: false, disablePadding: false, label: 'Código', type: 'text'},
  {id: 'cityName', right: false, disablePadding: false, label: 'Ciudad', type: 'text'},
  {id: 'zoneName', right: false, disablePadding: false, label: 'Sector', type: 'text'},
  {id: 'revenue_percentage', right: false, disablePadding: false, label: 'Comisión(%)', type: 'percentage'},
  {id: 'amenities', right: false, disablePadding: false, label: 'Amenidades', type: 'text'},
  {id: 'leads', right: false, disablePadding: false, label: 'Leads', type: 'noSortableNumber'},
  {id: 'sold', right: false, disablePadding: false, label: 'Vendidos', type: 'noSortableNumber'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},  
  {id: 'companyName', right: false, disablePadding: false, label: 'Empresa', type: 'text'},
  {id: 'geolocation', right: false, disablePadding: false, label: 'Ubicación', type: 'geolocation'},
  {id: 'atributes', right: false, disablePadding: false, label: 'Atributos', type: 'attributes'},
  {id: 'attachments', right: false, disablePadding: false, label: 'Adjuntos', type: 'attach'},
  {id: 'id', right: false, disablePadding: false, label: 'Editar', type: 'edit'},
  {id: 'proforma', right: false, disablePadding: false, label: 'Proforma', type: 'proforma'},
  {id: 'clone', right: false, disablePadding: false, label: 'Clonar Proyecto', type: 'cloneProject'},
  {id: 'uploadUnits', right: false, disablePadding: false, label: 'Importar Unidades', type: 'uploadUnits'},
  {id: 'configIntegrations', right: false, disablePadding: false, label: 'Configurar integraciones', type: 'configIntegrations'},
];

export const unitsColsAdmin = [
  {id: 'projectName', right: false, disablePadding: false, label: 'Proyecto', type: 'text'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'typeName', right: false, disablePadding: false, label: 'Tipo', type: 'text'},
  {id: 'stageName', right: false, disablePadding: false, label: 'Etapa', type: 'text'},
  {id: 'area', right: false, disablePadding: false, label: 'Área (m2)', type: 'area'},
  {id: 'price', right: false, disablePadding: false, label: 'Precio', type: 'price'},
  {id: 'active', right: false, disablePadding: true, label: 'Activo', type: 'bool'},
  {id: 'builderName', right: false, disablePadding: false, label: 'Constructora', type: 'text'},
  {id: 'companyName', right: false, disablePadding: false, label: 'Empresa', type: 'text'},
  {id: 'zoneName', right: false, disablePadding: false, label: 'Sector', type: 'text'},
  {id: 'rooms', right: false, disablePadding: false, label: 'Dormitorios', type: 'number'},
  {id: 'availability', right: false, disablePadding: true, label: 'Disponible', type: 'availability'},
  {id: 'atributes', right: false, disablePadding: false, label: 'Atributos', type: 'attributes'},
  {id: 'attachments', right: false, disablePadding: false, label: 'Adjuntos', type: 'attach'},
  {id: 'id', right: false, disablePadding: false, label: 'Editar', type: 'edit'},
  {id: 'duplicate', right: false, disablePadding: false, label: 'Duplicar', type: 'duplicate'},
  {id: 'sold', right: true, disablePadding: true, label: 'Perfil de Venta', type: 'sold'}
];

export const unitsColsAsesor = [
  {id: 'projectName', right: false, disablePadding: false, label: 'Proyecto', type: 'text'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'typeName', right: false, disablePadding: false, label: 'Tipo', type: 'text'},
  {id: 'stageName', right: false, disablePadding: false, label: 'Etapa', type: 'text'},
  {id: 'area', right: false, disablePadding: false, label: 'Área (m2)', type: 'area'},
  {id: 'price', right: false, disablePadding: false, label: 'Precio', type: 'price'},
  {id: 'active', right: false, disablePadding: true, label: 'Activo', type: 'bool'},
  {id: 'builderName', right: false, disablePadding: false, label: 'Constructora', type: 'text'},
  {id: 'zoneName', right: false, disablePadding: false, label: 'Sector', type: 'text'},
  {id: 'rooms', right: false, disablePadding: false, label: 'Dormitorios', type: 'number'},
  {id: 'availability', right: false, disablePadding: true, label: 'Disponible', type: 'availability'},
  {id: 'atributes', right: false, disablePadding: false, label: 'Atributos', type: 'attributes'},
  {id: 'attachments', right: false, disablePadding: false, label: 'Adjuntos', type: 'attach'},
  {id: 'id', right: false, disablePadding: false, label: 'Editar', type: 'edit'},
  {id: 'duplicate', right: false, disablePadding: false, label: 'Duplicar', type: 'duplicate'},
  {id: 'addToLead', right: false, disablePadding: false, label: 'Agregar a lead', type: 'addToLead'},
  {id: 'sold', right: false, disablePadding: true, label: 'Perfil de Venta', type: 'sold'}
];

export const editUnitsCols = [
  {id: 'projectId', right: false, disablePadding: false, label: 'Proyecto', type: 'editableText', autoCompleteWith: 'project'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'editableText'},
  {id: 'typeId', right: false, disablePadding: false, label: 'Tipo', type: 'editableText', autoCompleteWith: 'type'},
  {id: 'stageId', right: false, disablePadding: false, label: 'Etapa', type: 'editableText', autoCompleteWith: 'stage'},
  {id: 'area', right: false, disablePadding: false, label: 'Área (m2)', type: 'editableNumber'},
  {id: 'price', right: false, disablePadding: false, label: 'Precio', type: 'editableNumber'},
  {id: 'active', right: false, disablePadding: true, label: 'Activo', type: 'toggle'},
  {id: 'id', right: false, disablePadding: false, label: 'Editar', type: 'modify'}
];

export const newUnitsCols = [
  {id: 'projectId', right: false, disablePadding: false, label: 'Proyecto', type: 'editableText', autoCompleteWith: 'project'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'editableText'},
  {id: 'typeId', right: false, disablePadding: false, label: 'Tipo', type: 'editableText', autoCompleteWith: 'type'},
  {id: 'stageId', right: false, disablePadding: false, label: 'Etapa', type: 'editableText', autoCompleteWith: 'stage'},
  {id: 'area', right: false, disablePadding: false, label: 'Área (m2)', type: 'editableNumber'},
  {id: 'price', right: false, disablePadding: false, label: 'Precio', type: 'editableNumber'},
  {id: 'id', right: false, disablePadding: false, label: 'Editar', type: 'save'}
];

export const propertiesCols = [
  {id: 'icon', right: false, disablePadding: false, label: 'Imagen', type: 'image'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'code', right: false, disablePadding: false, label: 'Código', type: 'text'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const zonesCols = [
  {id: 'icon', right: false, disablePadding: false, label: 'Imagen', type: 'image'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'father', right: false, disablePadding: true, label: 'Padre', type: 'object'},
  {id: 'code', right: false, disablePadding: false, label: 'Código', type: 'text'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const leadsCols = [
  {id: 'id', right: false, disablePadding: false, label: 'Detalle', type: 'edit'},
  // {id: 'calendar', right: false, disablePadding: false, label: 'Agendar', type: 'calendar'},
  {id: 'complete_name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'originProjectName', right: false, disablePadding: false, label: 'Desarrollo Origen', type: 'text'},
  {id: 'origin', right: false, disablePadding: false, label: 'Origen', type: 'text'},
  {id: 'temperature', right: false, disablePadding: false, label: 'Temperatura', type: 'temperature'},
  {id: 'zones', right: false, disablePadding: false, label: 'Sector', type: 'array'},
  {id: 'brokers', right: false, disablePadding: true, label: 'Asesor', type: 'array'},
  {id: 'status', right: false, disablePadding: true, label: 'Estado', type: 'text'},
  {id: 'stage', right: false, disablePadding: true, label: 'Etapa', type: 'text'},
  {id: 'next_deadline', right: false, disablePadding: true, label: 'Tareas', type: 'date'},
  {id: 'companyName', right: false, disablePadding: true, label: 'Empresa', type: 'text'},
  {id: 'date_created', right: false, disablePadding: true, label: 'Fecha creación lead', type: 'date'},
  {id: 'date_updated', right: false, disablePadding: true, label: 'Fecha últ. contacto', type: 'date'},
  {id: 'lastProforma', right: false, disablePadding: true, label: 'Proforma', type: 'dateLink'},
];

export const stagesCols = [
  {id: 'icon', right: false, disablePadding: false, label: 'Imagen', type: 'image'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'code', right: false, disablePadding: false, label: 'Código', type: 'text'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const leadStatesCols = [
  {id: 'order', right: false, disablePadding: false, label: 'Orden', type: 'number'},
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'stages', right: false, disablePadding: true, label: 'Etapas', type: 'objectArray'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const projectAttachmentsCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'type', right: false, disablePadding: true, label: 'Tipo', type: 'text'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const builderCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'address', right: false, disablePadding: false, label: 'Dirección', type: 'text'},
  {id: 'revenue_percentage', right: false, disablePadding: false, label: 'Comisión(%)', type: 'percentage'},
  {id: 'company', right: false, disablePadding: false, label: 'Empresa', type: 'company'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const whatsappTemplatesCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'template', right: false, disablePadding: false, label: 'Plantilla', type: 'text'},
  {id: 'companyName', right: false, disablePadding: false, label: 'Empresa', type: 'text'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const emailTemplatesCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'subject', right: false, disablePadding: false, label: 'Asunto', type: 'text'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const rulesCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'status', right: false, disablePadding: false, label: 'Estado', type: 'text', autoCompleteWith: 'leadStates'},
  {id: 'stage', right: false, disablePadding: false, label: 'Etapa', type: 'text', autoCompleteWith: 'leadStages'},
  {id: 'days', right: false, disablePadding: false, label: 'Último contacto (días) ', type: 'number'},
  {id: 'action', right: false, disablePadding: false, label: 'Acción', type: 'text', autoCompleteWith: 'actions'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const profilesCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'company', right: false, disablePadding: false, label: 'Empresa', type: 'company'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'editWithPermissions'}
];

export const commisionsCollectCols = [
  {id: 'type', right: false, disablePadding: false, label: 'Estado', type: 'bool'},
  {id: 'lead', right: false, disablePadding: false, label: 'Lead', type: 'text'},
  {id: 'builder', right: false, disablePadding: false, label: 'Constructora', type: 'text'},
  {id: 'project', right: false, disablePadding: true, label: 'Proyecto', type: 'text'},
  {id: 'unit', right: false, disablePadding: true, label: 'Unidad', type: 'text'},
  {id: 'beneficiary', right: false, disablePadding: true, label: 'Asesor', type: 'text'},
  {id: 'date_created', right: false, disablePadding: true, label: 'Fecha creación comisión', type: 'text'},
  {id: 'total', right: false, disablePadding: true, label: 'Valor', type: 'number'}
];

export const commisionsPayCols = [
  {id: 'type', right: false, disablePadding: false, label: 'Estado', type: 'bool'},
  {id: 'lead', right: false, disablePadding: false, label: 'Lead', type: 'text'},
  {id: 'beneficiary', right: false, disablePadding: true, label: 'Beneficiario', type: 'text'},
  {id: 'referrer', right: false, disablePadding: true, label: 'Referidor', type: 'text'},
  {id: 'builder', right: false, disablePadding: false, label: 'Constructora', type: 'text'},
  {id: 'project', right: false, disablePadding: true, label: 'Proyecto', type: 'text'},
  {id: 'unit', right: false, disablePadding: true, label: 'Unidad', type: 'text'},
  {id: 'date_created', right: false, disablePadding: true, label: 'Fecha creación comisión', type: 'text'},
  {id: 'total', right: false, disablePadding: true, label: 'Valor', type: 'number'}
];

export const commentsCols = [
  {id: 'builder', right: false, disablePadding: false, label: 'Constructora', type: 'text'},
  {id: 'project', right: false, disablePadding: true, label: 'Proyecto', type: 'text'},
  {id: 'unit', right: false, disablePadding: false, label: 'Unidad', type: 'text'},
  {id: 'lead', right: false, disablePadding: true, label: 'Lead', type: 'text'},
  {id: 'pointsInFavor', right: false, disablePadding: true, label: 'Puntos a favor', type: 'rating'},
  {id: 'commentInFavor', right: false, disablePadding: true, label: 'Comentario a favor', type: 'text'},
  {id: 'pointsAgainst', right: false, disablePadding: true, label: 'Puntos en contra', type: 'rating'},
  {id: 'commentAgainst', right: false, disablePadding: true, label: 'Comentario en contra', type: 'text'}
];

export const observationCols = [
  {id: 'broker', right: false, disablePadding: false, label: 'Asesor', type: 'text'},
  {id: 'lead', right: false, disablePadding: false, label: 'Lead', type: 'text'},
  {id: 'date', right: false, disablePadding: true, label: 'Fecha y hora', type: 'datetime'},
  {id: 'type', right: false, disablePadding: true, label: 'Tipo', type: 'text'},
  {id: 'observation', right: false, disablePadding: false, label: 'Observación', type: 'text'}
];

export const advanceCols = [
  {id: 'status', right: false, disablePadding: false, label: 'Estado', type: 'text'},
  {id: 'stage', right: false, disablePadding: false, label: 'Etapa', type: 'text'},
  {id: 'planned', right: false, disablePadding: false, label: 'Planificado', type: 'number'},
  {id: 'executed', right: false, disablePadding: false, label: 'Ejecutado', type: 'number'},
  {id: 'percentage', right: false, disablePadding: false, label: '%', type: 'percentage'},
  {id: 'id', right: false, disablePadding: true, label: 'Editar', type: 'edit'}
];

export const advanceMultipleCols = [
  {id: 'status', right: false, disablePadding: false, label: 'Estado', type: 'text'},
  {id: 'stage', right: false, disablePadding: false, label: 'Etapa', type: 'text'},
  {id: 'planned', right: false, disablePadding: false, label: 'Planificado', type: 'number'},
  {id: 'executed', right: false, disablePadding: false, label: 'Ejecutado', type: 'number'},
  {id: 'percentage', right: false, disablePadding: false, label: '%', type: 'percentage'}
];

export const editAdvanceCols = [
  {id: 'status', right: false, disablePadding: false, label: 'Estado', type: 'text'},
  {id: 'stage', right: false, disablePadding: false, label: 'Etapa', type: 'text'},
  {id: 'planned', right: false, disablePadding: false, label: 'Planificado', type: 'editableNumber'},
  {id: 'executed', right: false, disablePadding: false, label: 'Ejecutado', type: 'number'},
  {id: 'percentage', right: false, disablePadding: false, label: '%', type: 'percentage'},
  {id: 'id', right: false, disablePadding: false, label: 'Editar', type: 'modify'}
];

export const originsCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'company', right: false, disablePadding: false, label: 'Empresa', type: 'company'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const identificationTypesCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'status', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const companiesCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'businessReason', right: false, disablePadding: false, label: 'Razón social', type: 'text'},
  {id: 'identificationType.name', right: false, disablePadding: false, label: 'Tipo de identificación', type: 'text'},
  {id: 'identification', right: false, disablePadding: false, label: 'Identificación', type: 'text'},
  {id: 'status', right: false, disablePadding: false, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: false, label: 'Detalle', type: 'edit'},
  {id: 'oauthclients', right: false, disablePadding: true, label: 'Fecha de expiración tokens', type: 'text'},
  {id: 'expiration', right: true, disablePadding: true, label: 'Integración', type: 'oauthIntegration'},
  {id: 'user', right: false, disablePadding: false, label: 'Agregar usuario', type: 'addUser'}
];

export const countriesCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'active', right: false, disablePadding: false, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: false, label: 'Detalle', type: 'edit'}
];

export const provinceCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'countryName', right: false, disablePadding: false, label: 'País', type: 'text'},
  {id: 'active', right: false, disablePadding: false, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: false, label: 'Detalle', type: 'edit'}
];

export const cityCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'provinceName', right: false, disablePadding: false, label: 'Provincia', type: 'text'},
  {id: 'code', right: false, disablePadding: false, label: 'Código', type: 'text'},
  {id: 'active', right: false, disablePadding: false, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: false, label: 'Detalle', type: 'edit'}
];

export const groupCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'usersNames', right: false, disablePadding: false, label: 'Asesores', type: 'text'},
  {id: 'projectsNames', right: false, disablePadding: false, label: 'Proyectos', type: 'text'},
  {id: 'active', right: false, disablePadding: false, label: 'Activo', type: 'bool'},
  {id: 'id', right: false, disablePadding: false, label: 'Detalle', type: 'edit'}
];

export const leadStagesCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'type', right: false, disablePadding: false, label: 'Tipo', type: 'text'},
  {id: 'companyName', right: false, disablePadding: false, label: 'Empresa', type: 'text'},
  {id: 'leadStatusName', right: false, disablePadding: false, label: 'Estado de Lead', type: 'text'},
  {id: 'order', right: false, disablePadding: false, label: 'Orden', type: 'number'},
  {id: 'active', right: false, disablePadding: false, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: false, label: 'Detalle', type: 'edit'}
];

export const standCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const plansCols = [
  {id: 'name', right: false, disablePadding: false, label: 'Nombre', type: 'text'},
  {id: 'graphNames', right: false, disablePadding: false, label: 'Gráficos', type: 'text'},
  {id: 'active', right: false, disablePadding: true, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const newsCols = [
  {id: 'title', right: false, disablePadding: false, label: 'Título', type: 'text'},
  {id: 'active', right: false, disablePadding: false, label: 'Disponible', type: 'bool'},
  {id: 'id', right: false, disablePadding: false, label: 'Detalle', type: 'edit'}
];

export const calendarCols = [
  {id: 'lead', right: false, disablePadding: true, label: 'Historial', type: 'history_events'},
  {id: 'lead', right: false, disablePadding: true, label: 'Lead', type: 'link'},
  {id: 'date', right: false, disablePadding: true, label: 'Fecha', type: 'datetime'},
  {id: 'projects', right: false, disablePadding: true, label: 'Proyectos', type: 'text'},
  {id: 'hasDate', right: false, disablePadding: false, label: 'Cita confirmada', type: 'bool2'},
  {id: 'hasVisit', right: false, disablePadding: false, label: 'Cita realizada', type: 'bool2'},
  {id: 'isCanceled', right: false, disablePadding: false, label: 'Cita cancelada', type: 'boolx'},
  {id: 'rescheduled', right: false, disablePadding: true, label: 'Reagendar', type: 'number'},
 // {id: 'countHasVisit', right: false, disablePadding: true, label: 'Visitas', type: 'number'},
  {id: 'visitCount', right: false, disablePadding: true, label: 'Total visitas', type: 'number'},
];

export const eventListCols = [
  {id: 'title', right: false, disablePadding: false, label: 'Nombre de evento', type: 'text'},
  {id: 'date', right: false, disablePadding: true, label: 'Fecha', type: 'datetime'},
  {id: 'projects', right: false, disablePadding: true, label: 'Proyectos', type: 'text'},
  {id: 'hasDate', right: false, disablePadding: false, label: 'Cita confirmada', type: 'bool2'},
  {id: 'hasVisit', right: false, disablePadding: false, label: 'Cita realizada', type: 'bool2'},
  {id: 'isCanceled', right: false, disablePadding: false, label: 'Cita cancelada', type: 'boolx'},
  {id: 'rescheduled', right: false, disablePadding: true, label: 'Reagendar', type: 'number'},
  {id: 'canEdit', right: false, disablePadding: true, label: 'Detalle', type: 'edit'},
  {id: 'dropdown', right: false, disablePadding: true, label: 'Acciones', type: 'dropdown'},
];

export const calendarColsAdmin = [
  {id: 'title', right: false, disablePadding: false, label: 'Nombre de evento', type: 'text'},
  {id: 'lead', right: false, disablePadding: true, label: 'Lead',type: 'text'},
  {id: 'date', right: false, disablePadding: true, label: 'Fecha', type: 'datetime'},
  {id: 'projects', right: false, disablePadding: true, label: 'Proyectos', type: 'text'},
  {id: 'hasDate', right: false, disablePadding: false, label: 'Cita confirmada', type: 'bool2'},
  {id: 'hasVisit', right: false, disablePadding: false, label: 'Cita realizada', type: 'bool2'},
  {id: 'broker', right: false, disablePadding: true, label: 'Asesor',type: 'text'},
  {id: 'id', right: false, disablePadding: true, label: 'Detalle', type: 'edit'}
];

export const eventsHistoryCols = [
  {id: 'title', right: false, disablePadding: false, label: 'Título del evento', type: 'text'},
  {id: 'date', right: false, disablePadding: true, label: 'Fecha', type: 'text'},
  {id: 'hasDate', right: false, disablePadding: false, label: 'Cita confirmada', type: 'bool2'},
  {id: 'hasVisit', right: false, disablePadding: false, label: 'Cita realizada', type: 'bool2'},
  {id: 'isCanceled', right: false, disablePadding: false, label: 'Cita cancelada', type: 'boolx'},
  {id: 'dropdown', right: false, disablePadding: false, label: 'Acciones', type: 'component'},
]
