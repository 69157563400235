import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {withStyles} from '@material-ui/core';
import {styles} from './styles';

class BrokerList extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderItems = this.renderItems.bind(this);
  }

  handleChange = (event) => {
    this.props.handleChange(event.target.value);
  }

  renderItems = (options) => {
    if (options) {
      return options.map((item, index) => {
        return (
          <MenuItem key={index} value={item.value}>
            {item.title}
          </MenuItem>
        );
      });
    } 
    return null;
  }

  render() {
    const {classes, options, value} = this.props;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-simple">Selecciona Asesor</InputLabel>
        <Select
          value={value}
          onChange={this.handleChange}
          inputProps={{
            name: 'broker-assign',
            id: 'broker-assign'
          }}
        >
          {this.renderItems(options)}
        </Select>
      </FormControl>
    );
  }
}

BrokerList.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func
};

export default ((withStyles)(styles)(BrokerList));
