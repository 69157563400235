/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { ADMIN_ID, ASESOR_ID, ASESOR_JR_ID, axiosMaker, COMERCIAL_MANAGER, copyObject, errorMessage, getProfileIsAdmin } from '../utils/utils';
import * as calendarActions from './types/calendar';
import * as taskActions from './types/tasks';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));
const profileId = localStorage.getItem('profileId');
const user = localStorage.getItem('user');

export const getCalendar = () => async (dispatch) => {
  dispatch({
    type: calendarActions.GET_CALENDAR_SENDING,
    payload: {
      calendar: [],
    },
  });
  try {
    const response = await axios(axiosMaker('GET', 'calendar', null, true));
    return dispatch({
      type: calendarActions.GET_CALENDAR_SUCCESS,
      payload: {
        calendar: response.data,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: calendarActions.GET_FILTER_CALENDAR_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getTasks = (page, size = 10, typeFilter, brokers, lead) => async (dispatch) => {
  dispatch({
    type: taskActions.GET_TASKS_SENDING,
    payload: { loading: true },
  });
  try {
    console.log("user on brokerList",user);
    let brokerList = brokers;
    if(!lead && (profileId === ASESOR_ID || profileId === ASESOR_JR_ID)){
      brokerList = [user];
    }
    const response = await axios(
      axiosMaker(
        'POST',
        `calendar/tasks?page=${page}&size=${size}&type=${typeFilter}`,
        { brokers:brokerList, lead },
        true,
      ),
    );

    page === 1 &&
      dispatch({
        type: taskActions.FIRSTLOAD_TASKS,
        payload: {},
      }); // dispara primera carga
    return dispatch({
      type: taskActions.GET_TASKS_SUCCESS,
      payload: {
        tasks: response.data.data,
        size: response.data.size,
        page: response.data.page,
        loading: false,
        hasMore: response.data.hasMore,
      },
    });
  } catch (e) {
    // const error = e.response;
    // page === 1 && dispatch(firstLoad()); // dispara primera carga
    return dispatch({
      type: taskActions.GET_TASKS_FAILED,
      payload: {
        loading: false,
      },
    });
  }
};

export const getTasksHistory = (page = 1, size = 30, brokers, lead) => async (dispatch) => {
  try {
    page === 1 &&
      dispatch({
        type: taskActions.GET_HISTORY_TASKS_FIRST_LOAD,
        payload: { sending: true, tasks: [], hasMore: true },
      });

    dispatch({
      type: taskActions.GET_HISTORY_TASKS_SENDING,
      payload: { sending: true, hasMore: true },
    });

    const response = await axios(
      axiosMaker(
        'POST',
        `calendar/tasks?page=${page}&size=${size}&sort=-1&history=true`,
        { brokers, lead },
        true,
      ),
    );

    return dispatch({
      type: taskActions.GET_HISTORY_TASKS_SUCCESS,
      payload: {
        page: response.data.page,
        sending: false,
        hasMore: response.data.hasMore,
        tasks: response.data.data,
        dataLength: response.data.total,
      },
    });
  } catch (error) {
    return dispatch({
      type: taskActions.GET_HISTORY_TASKS_FAILED,
      payload: {
        hasMore: false,
        sending: false,
        error: 'Error al obtener historial de tareas',
      },
    });
  }
};

export const getEvents = (page, size = 20, brokers, lead) => async (dispatch) => {
  dispatch({
    type: taskActions.GET_TASKS_SENDING,
    payload: { loading: true },
  });
  try {
    const response = await axios(
      axiosMaker(
        'POST',
        `calendar/events?page=${page}&size=${size}`,
        { brokers: [user], lead },
        true,
      ),
    );

    page === 1 &&
      dispatch({
        type: taskActions.FIRSTLOAD_TASKS,
        payload: {},
      }); // dispara primera carga
    return dispatch({
      type: taskActions.GET_TASKS_SUCCESS,
      payload: {
        tasks: response.data.data,
        size: response.data.size,
        page: response.data.page,
        loading: false,
        hasMore: response.data.hasMore,
      },
    });
  } catch (e) {
    // const error = e.response;
    // page === 1 && dispatch(firstLoad()); // dispara primera carga
    return dispatch({
      type: taskActions.GET_TASKS_FAILED,
      payload: {
        loading: false,
      },
    });
  }
};

export const filterCalendar = (
  page = 1,
  rowsPerPage,
  customFilter = {},
  sort,
  canceled = false,
) => async (dispatch) => {
  const isAdmin = getProfileIsAdmin();

  dispatch({
    type: calendarActions.GET_HISTORY_EVENTS_SENDING,
    payload: { sending: true },
  });

  page === 1 &&
    dispatch({
      type: calendarActions.GET_HISTORY_EVENTS_FIRST_LOAD,
      payload: { sending: true },
    });

  try {
    let filter = {
      filters: {
        ...customFilter,
        brokers: isAdmin === false ? [user] : undefined,
        type: 'EVENT',
      },
      operator: 'or',
      page: page - 1,
      limit: rowsPerPage,
      canceled,
      sort,
    };

    const response = await axios(axiosMaker('POST', 'calendar/filter', filter, true));

    const canceledEvents = response.data.results.filter((e) => e.status === 'CANCELED');
    const activeEvents = response.data.results.filter((e) => e.status !== 'CANCELED');

    return dispatch({
      type: calendarActions.GET_HISTORY_EVENTS_SUCCESS,
      payload: {
        events: [
          ...activeEvents.sort((a, b) => a.date - b.date),
          ...canceledEvents.sort((a, b) => a.date - b.date),
        ],
        size: rowsPerPage,
        page,
        dataLength: response.data.total,
        sending: false,
      },
    });
  } catch (e) {
    const error = 'Error al obtener historial de citas';
    return dispatch({
      type: calendarActions.GET_HISTORY_EVENTS_FAILED,
      payload: {
        message: error,
        error,
        dataLength: 0,
        sending: false,
      },
    });
  }
};

export const firstLoad = () => ({
  type: taskActions.FIRSTLOAD_TASKS,
});

export const groupEvents = (
  page,
  rowsPerPage,
  order,
  orderBy,
  searchText,
  filtersToApply,
  type = 'EVENT',
) => async (dispatch) => {
  if (
    (searchText !== '' && store.getState().calendar.searchText !== searchText) ||
    (Object.keys(filtersToApply).length && filtersToApply !== store.getState().calendar.filters)
  ) {
    page = 0;
  }
  if (order !== store.getState().calendar.order || orderBy !== store.getState().calendar.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
    filtersToApply = {};
  }

  dispatch({
    type: calendarActions.GET_CALENDAR_SENDING,
    payload: {
      calendar: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
      filters: filtersToApply,
    },
  });
  try {
    let filter = {};
    let filters = {};
    if (company && company._id) {
      filtersToApply.company = [company._id];
    }
    if (profileId !== ADMIN_ID && !filtersToApply.brokers) {
      filtersToApply.brokers = [user] ;
    }
   // if(profileId)
    filters = copyObject(filtersToApply);
    if (searchText !== '') {
      filters.name = searchText;
    }
    filters.type = type;
    if (
      page !== null &&
      rowsPerPage !== null &&
      order !== null &&
      orderBy !== null &&
      searchText !== null
    ) {
      filter = {
        filters,
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order,
        },
      };
    }
    const response = await axios(axiosMaker('POST', 'calendar/events/group', filter, true));

    return dispatch({
      type: calendarActions.GET_CALENDAR_SUCCESS,
      payload: {
        calendar: response.data.results,
        dataLength: response.data.total,
        filters:filter.filters
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: calendarActions.GET_CALENDAR_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updateEvent = (id, event) => async (dispatch) => {
  dispatch({
    type: calendarActions.UPDATE_EVENT_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PATCH', `calendar/${id}`, event, true));
    return dispatch({
      type: calendarActions.UPDATE_EVENT_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: calendarActions.UPDATE_EVENT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const createEvent = (data) => async (dispatch) => {
  dispatch({
    type: calendarActions.CREATE_EVENT_SENDING,
    payload: {},
  });
  try {
    if (company && company._id) {
      data.company = company._id;
    }
    await axios(axiosMaker('POST', 'calendar', data, true));
    return dispatch({
      type: calendarActions.CREATE_EVENT_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: calendarActions.CREATE_EVENT_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const finishTask = (taskId) => async (dispatch) => {
  dispatch({
    type: taskActions.FINISH_TASKS_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PUT', `calendar/tasks/finish?taskId=${taskId}`, null, true));
    return dispatch({
      type: taskActions.FINISH_TASKS_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: taskActions.FINISH_TASKS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const cancelTask = (taskId) => async (dispatch) => {
  dispatch({
    type: taskActions.CANCEL_TASKS_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PUT', `calendar/tasks/cancel?taskId=${taskId}`, null, true));
    return dispatch({
      type: taskActions.CANCEL_TASKS_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: taskActions.CANCEL_TASKS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const cancelEvent = (eventId) => async (dispatch) => {
  try {
    dispatch({ type: calendarActions.CANCEL_EVENT_SENDING, payload: {} });

    await axios(axiosMaker('PUT', `calendar/events/cancel?eventId=${eventId}`, null, true));

    return dispatch({ type: calendarActions.CANCEL_EVENT_SUCCESS, payload: {} });
  } catch (e) {
    const error = e ? e.response : {};
    return dispatch({
      type: calendarActions.CANCEL_EVENT_FAILED,
      payload: {
        eventCancel: {
          message: errorMessage(error),
          error: errorMessage(error),
        },
      },
    });
  }
};

export const rescheduleEvent = (eventId) => async (dispatch) => {
  try {
    dispatch({
      type: calendarActions.RESCHEDULE_EVENT_SENDING,
      payload: { error: null, message: '' },
    });
    await axios(axiosMaker('PATCH', 'calendar/reschedule', { eventId }, true));

    return dispatch({
      type: calendarActions.RESCHEDULE_EVENT_SENDING,
      payload: { error: null, message: 'Evento reagendado satisfactoriamente' },
    });
  } catch (error) {
    const message = 'El evento que quiere reagendar ya se encuentra reagendado o no está activo';

    return dispatch({
      type: calendarActions.RESCHEDULE_EVENT_SENDING,
      payload: {
        error: message,
        message,
      },
    });
  }
};

export const getActiveEvents = (filters = {}) => async (dispatch) => {
  try {
    const safeFilter = { filters: {} };
    if (filters.leads && filters.leads.length) safeFilter.filters.leads = filters.leads;
    if (filters.brokers && filters.brokers.length) safeFilter.filters.brokers = filters.brokers;

    if (!Object.keys(safeFilter.filters).length)
      throw new Error('provide a leads or brokers in body');

    dispatch({ type: calendarActions.GET_ACTIVE_EVENTS_SENDING, payload: { sending: true } });

    const reponse = await axios(axiosMaker('POST', 'calendar/events/active', safeFilter, true));
    const { results, total } = reponse.data;

    return dispatch({
      type: calendarActions.GET_ACTIVE_EVENTS_SUCCESS,
      payload: {
        events: results,
        filters: safeFilter,
        dataLength: total,
      },
    });
  } catch (e) {
    const error = e ? e.response : {};

    return dispatch({
      type: calendarActions.GET_ACTIVE_EVENTS_FAILED,
      payload: {
        error: errorMessage(error),
        message: errorMessage(error),
      },
    });
  }
};

export const getEventsStatistics = (leadId, filters = {}) => async (dispatch) => {
  try {
    dispatch({
      type: calendarActions.GET_EVENTS_STATISTICS_SENDING,
      payload: {
        sending: true,
        message: '',
        error: null,
        leadId,
        statistics: [],
      },
    });

    const safeFilter = { filters: {} };
    if (filters.leads && filters.leads.length) safeFilter.filters.leads = filters.leads;
    if (filters.brokers && filters.brokers.length) safeFilter.filters.brokers = filters.brokers;

    const { data } = await axios(
      axiosMaker('POST', 'calendar/events/statistics', safeFilter, true),
    );
    const { results = [] } = data || {};

    return dispatch({
      type: calendarActions.GET_EVENTS_STATISTICS_SUCCESS,
      payload: {
        sending: false,
        leadId,
        statistics: results,
      },
    });
  } catch (error) {
    const message = 'Hubo un error al recoger metricas de eventos';
    return dispatch({
      type: calendarActions.GET_EVENTS_STATISTICS_FAILED,
      payload: {
        sending: false,
        leadId,
        statistics: [],
        error: message,
        message,
      },
    });
  }
};

export const getEventsReport = (dateFrom, dateTo) => async (dispatch) => {
  dispatch({
    type: calendarActions.GET_EVENTS_REPORT_SENDING,
    payload: {  },
  });
  try {
    let broker = null;
    if(profileId=== ASESOR_ID || profileId=== ASESOR_JR_ID){
      broker=user;
    }
    const response = await axios(
      axiosMaker('POST', `calendar/eventsReport`, { company: company ? company._id : null, dateFrom, dateTo, broker }, true),
    );
    console.log('response:', response);

    return dispatch({
      type: calendarActions.GET_EVENTS_REPORT_SUCCESS,
      payload: {
        report: response.data.report,
        // loading: false,
      },
    });
  } catch (e) {
    return dispatch({
      type: calendarActions.GET_EVENTS_REPORT_FAILED,
      payload: {
        loading: false,
      },
    });
  }
};

export const getEventsById = (leadId) => async (dispatch) => {
  dispatch({
    type: calendarActions.GET_CALENDAR_EVENTS_SENDING,
    payload: {
      eventsByLead: [],
    },
  });
  try {
    const response = await axios(axiosMaker('GET', `eventcalendars/${leadId}`, null, true));
    console.log("response", response);
    return dispatch({
      type: calendarActions.GET_CALENDAR_EVENTS_SUCCESS,
      payload: {
        eventsByLead: response.data,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: calendarActions.GET_CALENDAR_EVENTS_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const cleanEventsByLead = () => async (dispatch) => {
  dispatch({
    type: calendarActions.CLEAN_CALENDAR_EVENTS_FAILED,
    payload: {
      leadsById: [],
    },
  });
};
