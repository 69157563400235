/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as advanceActions from './types/advance';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterAdvances = (page, rowsPerPage, searchText, filters) => async (dispatch) => {
  const monthsArray = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  const newFilters = {};

  if (searchText !== '' && store.getState().advances.searchText !== searchText) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  if (filters && filters.userId) {
    newFilters.userId = filters.userId;
  }
  if (filters && filters.monthFrom) {
    for (let i = 0; i < monthsArray.length; i += 1) {
      if (monthsArray[i] === filters.monthFrom) {
        newFilters.monthFrom = i;
        break;    
      }
    }
  }
  if (filters && filters.monthTo) {
    for (let i = 0; i < monthsArray.length; i += 1) {
      if (monthsArray[i] === filters.monthTo) {
        newFilters.monthTo = i;
        break;    
      }
    }
  }
  if (filters && filters.yearFrom) {
    newFilters.yearFrom = filters.yearFrom;
  }
  if (filters && filters.yearTo) {
    newFilters.yearTo = filters.yearTo;
  }
  if (newFilters.yearFrom < newFilters.yearTo) {
    newFilters.monthTo += 12;
  }
  if (filters && filters.status) {
    newFilters.status = filters.status[0];
  }
  
  if (filters && filters.stage) {
    newFilters.stage = filters.stage[0];
  }

  dispatch({
    type: advanceActions.FILTER_ADVANCES_SENDING,
    payload: {
      advances: [],
      dataLength: 0,
      page,
      rowsPerPage,
      searchText,
      filters
    }
  });
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && searchText !== null) {
      filter = {
        filters: newFilters
      };
    }
    const request = axiosMaker('POST', 'advances/filter', filter, true);
    const response = await axios(request);
    console.log('response: ', response);
    return dispatch({
      type: advanceActions.FILTER_ADVANCES_SUCCESS,
      payload: {
        advances: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: advanceActions.FILTER_ADVANCES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateAdvance = (id, advances) => async (dispatch) => {
  dispatch({
    type: advanceActions.UPDATE_ADVANCE_SENDING,
    payload: {}
  });
  try {
    if (company && company._id) { advances.company = company._id; }
    await axios(axiosMaker('PATCH', `advances/${id}`, advances, true));
    return dispatch({
      type: advanceActions.UPDATE_ADVANCE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: advanceActions.UPDATE_ADVANCE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
