export const styles = (theme) => ({
  item: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    marginTop: 10,
    marginBottom: 10,
  },
  itemTask: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginRight: '50px',
  },
  itemTaskContent: {
    flex: '1',
  },
  taskTitle: {
    fontSize: '1rem',
    color: 'black',
    fontWeight: '600',
    marginRight: '50px',
  },
  taskSubtitle: {
    fontSize: '0.8rem',
    color: theme.palette.primary.main,
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  date: {
    fontSize: '0.7rem',
    color: '#606060',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  projects: {
    fontSize: '0.8rem',
    color: 'black',
    marginRight: '50px',
    overflowWrap: 'break-word',
  },
  center: {
    margin: '20px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
});
