import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {ChromePicker} from 'react-color';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import {styles} from './styles';

export class ColorPickerButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: '#000',
      anchorEl: null
    };
    this.handleClose = this.handleClose.bind(this);
    this.onChangeColorPicker = this.onChangeColorPicker.bind(this);
  }

  componentWillMount() {
    this.setState({color: this.props.color});
  }

  onChangeColorPicker = color => this.setState({color: color.hex});;

  handleClose = () => {
    this.setState({anchorEl: null});
    this.props.onChangeColor(this.state.color);
  };

  render() {
    const {classes, name} = this.props;
    const {color, anchorEl} = this.state;
    const open = Boolean(anchorEl);
    const id = open ? name : undefined;
    return (
      <div>
        <Button
          aria-describedby={id}
          className={classes.button}
          variant="contained"
          style={{background: color, color: '#fff'}}
          onClick={event => this.setState({anchorEl: event.currentTarget})}
        >
          {color}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <ChromePicker
            onChange={this.onChangeColorPicker}
            color={color}
          />
        </Popover>
      </div>
    );
  }
}
ColorPickerButton.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  onChangeColor: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ColorPickerButton);
