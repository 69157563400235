import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {filterStages, createStage, updateStage} from '../../../actions/stages';
import {getProfile} from '../../../actions/profiles';
import EnhancedTable from '../../common/enhancedTable';
import {stagesCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import StageDetail from './detail';

export class Stages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {           
      stage: null,
      isNew: false,
      title: 'Etapas'
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleUpdateStage = this.handleUpdateStage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.filterStages(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  onAdd() {
    this.setState({isNew: true, stage: {}});
  }

  onDetail(id) {
    const stage = this.props.stages.filter(obj => obj.id === id)[0];
    this.setState({stage, isNew: false});
  }

  onOrder(order, orderBy) {
    this.props.filterStages(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText);
  }

  onSearch(searchText) {
    this.props.filterStages(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_stages) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_stages) {
        return true;
      }
    }
    return false;
  }

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_stages) {
        return true;
      }
    }
    return false;
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterStages(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleChangePage(page) {
    this.props.filterStages(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleGoBack() {
    this.props.filterStages(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
    this.setState({stage: null, isNew: false});
  }

  handleUpdateStage(stageEdited) {
    const {stage} = this.state;
    this.setState({stage: stageEdited});
    const edited = {};
    if (stage.active !== stageEdited.active) {
      edited.active = stageEdited.active;
    }
    if (stage.name !== stageEdited.name) {
      edited.name = stageEdited.name;
    }
    if (stage.icon !== stageEdited.icon) {
      edited.icon = stageEdited.icon;
    }
    if (stage.code !== stageEdited.code) {
      edited.code = stageEdited.code;
    }
    return this.props.updateStage(stageEdited.id, edited);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, isLoadingProfile, stages, dataLength} = this.props;
    const {title, stage, isNew} = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const data = {
      rows: stages,
      cols: stagesCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };
    if (isLoading === true || isLoadingProfile === true) {
      return (
        <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {
          stage === null ?
            <EnhancedTable 
              title={title}
              data={data} 
              handlers={handlers}
              config={config}
            />  
            :
            <StageDetail 
              stage={stage}
              handleGoBack={this.handleGoBack}
              handleCreateStage={this.props.createStage}
              handleUpdateStage={this.handleUpdateStage}
              isLoading={isLoading}
              isNew={isNew}
            />
        }
      </div>
    );
  }
}

Stages.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  stages: PropTypes.array.isRequired,
  isLoadingProfile: PropTypes.bool.isRequired,
  filterStages: PropTypes.func.isRequired,
  createStage: PropTypes.func.isRequired,
  updateStage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {stages} = state;
  return { 
    isLoading: stages.sending, 
    stages: stages.stages,
    dataLength: stages.dataLength, 
    error: stages.error,
    message: stages.message,
    searchText: stages.searchText,
    order: stages.order,
    orderBy: stages.orderBy,
    page: stages.page,
    rowsPerPage: stages.rowsPerPage,
    filters: stages.filters
  };
};
  
const mapDispatchToProps = {
  filterStages,
  createStage,
  updateStage,
  getProfile
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Stages);
