import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { CircularProgress, withStyles } from '@material-ui/core';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { getTasks } from '../../../actions/calendar';
import { styles } from './styles';
import ActionsTask from './actionsTask';
import { dateFormatExtended } from '../../../utils/utils';
import CustomSnackbar from '../../common/customSnackbar';

let scrolling = true;
const TaskList = (props) => {
  const { classes, typeFilter, brokers, lead } = props;
  const dispatch = useDispatch();
  const [loadingPage, setloadingPage] = useState(true);
  const { page, size, tasks, sending, hasMore, firstLoad, messageAction, error } = useSelector(
    (state) => state.tasks,
  );

  useEffect(() => {
    dispatch(getTasks(1, size, typeFilter, brokers, lead));
  }, [lead]);

  const reloadData = async () => {
    await dispatch(getTasks(1, size, typeFilter, brokers, lead));
  };

  const getTasksData = async () => {
    if (scrolling && hasMore) {
      scrolling = false;
      const newPage = page + 1;
      await dispatch(getTasks(newPage, size, typeFilter, brokers, lead));
      scrolling = true;
    }
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: sending,
    hasNextPage: hasMore,
    onLoadMore: getTasksData,
    disabled: sending,
    rootMargin: '0px 0px 400px 0px',
  });

  const renderSnackBar = () => {
    if (messageAction && messageAction != '') {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={messageAction || 'texto'}
        />
      );
    }
    return null;
  };

  const renderDate = (date) => {
    if (typeFilter === 'TODAY') {
      return `Hora: ${moment(date).format('HH:mm')}`;
    }
    return `Fecha: ${moment(date).format('LLLL')}`;
  };

  const handlePressLead = (leadId) => {
    console.log('leadId:', leadId);
    return props.history.push(`/leads?id=${leadId}`);
  };

  return (
    <div ref={rootRef} className={classes.root}>
      <List className={classes.root2}>
        {tasks.map((task, index) => (
          <ListItem key={`list-task-${index}`} role={undefined} dense button>
            <div className={classes.itemTask}>
              <div className={classes.itemTaskContent}>
                <div className={classes.taskTitle}> {task.title}</div>
                <div
                  className={classes.taskSubtitle}
                  onClick={() => {
                    handlePressLead(task.leadId);
                  }}
                >
                  {task.lead}
                </div>
                <div className={classes.date}>{renderDate(task.date)}</div>
              </div>
              <div className={classes.temperature}>
                <div className={task.temperature}></div>
              </div>
            </div>
            <ListItemSecondaryAction>
              <ActionsTask task={task} reloadData={reloadData} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}

        {!sending && hasMore && (
          <div ref={sentryRef}>
            <CircularProgress />
          </div>
        )}
        {renderSnackBar()}
      </List>
    </div>
  );
};

TaskList.propTypes = {
  typeFilter: PropTypes.string,
  brokers: PropTypes.array,
};

export default withStyles(styles)(withRouter(TaskList));
