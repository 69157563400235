import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import DropDown from '../dropdown';
import AppointmentListTable from './appointmentListTable';
import { cancelEvent, getEventsById } from '../../../actions/calendar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppointmentList = (props) => {
  const { open, closeModal, editAppointment, lead } = props;
  const { eventsByLead } = useSelector((state) => state.calendar);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventsById(lead));
  }, []);

  const handlerEditAppointment = (eventId) => {
    editAppointment(eventId);
  };

  const handlerCancelEvent = (eventId) => {
    dispatch(cancelEvent(eventId));
  };

  const handlerClose = (forceUpdate = true) => {
    forceUpdate && closeModal();
  };

  const handlerDropDownClick = (clickId, val) => {
    if (clickId === 'cancel-event') {
      const eventId = val;
      handlerCancelEvent(eventId);
    }

    return handlerClose();
  };

  const getDropDownItems = (item) => [
    {
      id: 'cancel-event',
      val: item.id,
      disabled: item.status === 'CANCELED',
      label: 'Cancelar',
      onClick: handlerDropDownClick,
    },
  ];

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xl"
      onClose={handlerClose}
      TransitionComponent={Transition}
    >
      <DialogContent style={{ padding: 0 }}>
        {eventsByLead.length > 0 && (
          <AppointmentListTable
            events={eventsByLead}
            renderActionEvents={(event, index) => (
              <DropDown key={index} options={getDropDownItems(event)} />
            )}
            handlerOnEdit={handlerEditAppointment}
            handlerCancelEvent={handlerCancelEvent}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handlerClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AppointmentList.propTypes = {
  events: PropTypes.array,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  editAppointment: PropTypes.func,
  lead: PropTypes.string,
};

export default AppointmentList;
