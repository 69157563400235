import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  MenuItem,
} from '@material-ui/core';
import { styles } from './styles';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { InlineDatePicker } from 'material-ui-pickers';
import { parseNumber } from '../../../utils/utils';

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const QuotationTable = (props) => {
  const {
    formValues,
    classes,
    handleInputChange,
    setQuotationInvalid,
    currentVersion,
    setSimpleDialog,
  } = props;

  const [activeRow, setActiveRow] = useState();
  const [rowValue, setRowValue] = useState();
  const [rowDate, setRowDate] = useState();
  const [newRow, setNewRow] = useState();
  const [deleteRow, setDeleteRow] = useState();
  const [recalculateOption, setRecalculateOption] = useState();
  const [quotationNumber, setQuotationNumber] = useState();
  const [fixValue, setFixValue] = useState();

  const {
    coveredAreaPrice,
    gardenOrTerraceAreaPrice,
    parkingSpacesPrice,
    warehouseValue,
    builderFinancingPercentage,
    content,
    totalQuotes,
    interestPercentage,
    formHash,
    discountValue,
  } = formValues;

  const formatNumber = (value) => {
    return Math.floor(1000 * value) / 1000;
  };

  const currentPrice =
    parseNumber(coveredAreaPrice) +
    parseNumber(gardenOrTerraceAreaPrice) +
    parseNumber(parkingSpacesPrice) +
    parseNumber(warehouseValue) -
    parseNumber(discountValue);

  const builderFinancingValue =
    (currentPrice * parseNumber(builderFinancingPercentage)) / 100;
  const fractionInterest = (number) => {
    return (
      (builderFinancingValue * parseNumber(interestPercentage)) /
      100 /
      parseNumber(totalQuotes)
    );
  };

  const fixedPay =
    parseNumber(builderFinancingValue) / parseNumber(totalQuotes);

  const realValue = [...new Array(parseNumber(totalQuotes))].reduce(
    (a) => a + parseNumber(fixedPay + fractionInterest()),
    0,
  );

  const currentValue = content.reduce((a, b) => a + parseNumber(b.value), 0);

  useEffect(() => {
    handleInputChange(
      { target: { name: 'totalQuotesValue', value: currentValue.toFixed(2) } },
      false,
    );
  }, [currentValue]);

  const valid =
    formatNumber(currentValue).toFixed(2) ===
    formatNumber(realValue).toFixed(2);

  setQuotationInvalid(!valid);

  useEffect(() => {
    if (content.length === 0) {
      let array = [...new Array(parseNumber(totalQuotes))].map((_, index) => ({
        number: index + 1,
        value: Number((fixedPay + fractionInterest()).toFixed(2)),
        date: moment(new Date()).add(index + 1, 'months'),
      }));

      const totalRowValues = array.reduce(
        (a, b) => a + Number(b.value.toFixed(2)),
        0,
      );

      const difference = Math.round(
        (Number(realValue.toFixed(2)) - totalRowValues) * 100,
      );

      array = array.map((item, index) => {
        const touched = array.length - index <= Math.abs(difference);
        if (difference > 0 && touched) item.value += 0.01;
        else if (difference < 0 && touched) item.value -= 0.01;
        return item;
      });

      handleInputChange({ target: { name: 'content', value: array } }, false);
    }
  }, [formHash]);

  useEffect(() => {
    if (newRow) {
      const array = [];
      for (const item of content) {
        const { number, value, date } = item;

        if (number <= newRow) array.push(item);
        else array.push({ ...item, number: number + 1 });

        if (number === newRow)
          array.push({ number: number + 1, value: 0, date });
      }

      setActiveRow(newRow + 1);
      setNewRow(null);
      handleInputChange({ target: { name: 'content', value: array } }, false);
    } else if (deleteRow) {
      const array = [];
      for (const item of content) {
        if (item.number < deleteRow) array.push(item);
        else if (item.number > deleteRow)
          array.push({ ...item, number: item.number - 1 });
      }
      setDeleteRow(null);
      handleInputChange({ target: { name: 'content', value: array } }, false);
    }
  }, [totalQuotes]);

  useEffect(() => {
    setFixValue(null);
    setQuotationNumber(null);
  }, [recalculateOption]);

  const onDiscardRow = () => {
    setActiveRow(null);
    setRowValue(null);
    setRowDate(null);
  };

  const onEditRow = () => {
    const array = [];
    for (const item of content) {
      const { number, value, date } = item;
      if (number === activeRow)
        array.push({
          number,
          value: Number(rowValue) < 0 ? 0 : rowValue || value,
          date: rowDate ? moment(rowDate) : date,
        });
      else array.push(item);
    }

    handleInputChange({ target: { name: 'content', value: array } }, false);
    onDiscardRow();
  };

  const onAddRow = (row) => {
    setNewRow(row);
    handleInputChange(
      { target: { name: 'totalQuotes', value: Number(totalQuotes) + 1 } },
      false,
    );
  };

  const onDeleteRow = (row) => {
    setSimpleDialog({
      text: `Está seguro de que desea eliminar la fila número ${row}`,
      actions: (
        <>
          <Button
            variant="contained"
            style={{ margin: 8 }}
            onClick={() => setSimpleDialog(null)}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            style={{ margin: 8 }}
            onClick={() => {
              setDeleteRow(row);
              handleInputChange(
                {
                  target: {
                    name: 'totalQuotes',
                    value: Number(totalQuotes) - 1,
                  },
                },
                false,
              );
              setSimpleDialog(null);
            }}
            color="primary"
          >
            Confirmar
          </Button>
        </>
      ),
    });
  };

  const handleRecalculate = (number) => {
    const numberItem = number - 1;
    const array = [...content];

    const currentSum = array.reduce(
      (a, b) =>
        a +
        (parseNumber(b.number) <= parseNumber(numberItem)
          ? parseNumber(b.value) - fractionInterest()
          : 0),
      0,
    );

    const newFixedPay =
      (parseNumber(builderFinancingValue) - currentSum) /
      (parseNumber(totalQuotes) - parseNumber(numberItem));

    for (const item of array) {
      if (item.number > numberItem) {
        const value = newFixedPay + fractionInterest();
        item.value = parseNumber(value) > 0 ? value.toFixed(2) : 0;
      }
    }

    const fitValues = (arr) => {
      const contentSum = arr.reduce((a, b) => a + parseNumber(b.value), 0);
      const difference = contentSum - builderFinancingValue;
      const lastValue = arr[arr.length - 1].value - difference;
      arr[arr.length - 1].value = lastValue > 0 ? lastValue : 0;
      return arr;
    };

    return fitValues(array);
  };

  const onRecalculate = () => {
    if (['all', 'specific', 'fix'].includes(recalculateOption)) {
      let array;

      if (recalculateOption === 'all') {
        array = handleRecalculate(1);
      } else if (recalculateOption === 'specific') {
        array = handleRecalculate(quotationNumber);
      } else {
        array = content.map((item) => ({ ...item, value: Number(fixValue) }));
      }

      handleInputChange({ target: { name: 'content', value: array } }, false);
      setRecalculateOption(null);
      setFixValue(null);
      setQuotationNumber(null);
    }
  };

  return (
    <Paper className={classes.root}>
      <Typography
        variant="h5"
        color="primary"
        className={classes.headerProformas}
      >
        Cuotas mensuales
      </Typography>
      {!!!currentVersion && (
        <Grid
          container
          spacing={24}
          justify="flex-end"
          style={{ paddingBottom: 20 }}
        >
          <Grid item className={classes.gridItem} lg={8} sm={6} xs={0} />
          <Grid
            item
            className={classes.gridItem}
            lg={4}
            sm={6}
            xs={12}
            style={{ paddingBottom: 0 }}
          >
            <FormControl variant="outlined" className={classes.textFieldSelect}>
              <InputLabel
                classes={{ shrink: classes.inputShrink }}
                className={classes.labelForm}
                htmlFor="recalculate-options"
              >
                Recalcular tabla
              </InputLabel>
              <Select
                value={recalculateOption || ''}
                onChange={({ target: { value } }) =>
                  setRecalculateOption(value)
                }
                input={
                  <OutlinedInput
                    className={classes.selectInputForm}
                    labelWidth={0}
                    name="recalculate-options"
                  />
                }
              >
                <MenuItem value="all">Todas las cuotas</MenuItem>
                <MenuItem value="specific">Desde Cuota específica</MenuItem>
                <MenuItem value="fix">Valor fijo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {recalculateOption === 'specific' && (
            <Grid item className={classes.gridItem} lg={2} sm={3} xs={6}>
              <FormControl
                variant="outlined"
                className={classes.textFieldSelect}
              >
                <InputLabel
                  classes={{ shrink: classes.inputShrink }}
                  className={classes.labelForm}
                  htmlFor="quotes-number"
                >
                  No. Cuota
                </InputLabel>
                <Select
                  value={quotationNumber || ''}
                  onChange={({ target: { value } }) =>
                    setQuotationNumber(value)
                  }
                  input={
                    <OutlinedInput
                      className={classes.selectInputForm}
                      labelWidth={0}
                      name="quotes-number"
                    />
                  }
                >
                  {content.map(({ number }) => (
                    <MenuItem key={`quote-${number}`} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {recalculateOption === 'fix' && (
            <Grid
              item
              className={classes.gridItem}
              lg={2}
              sm={3}
              xs={6}
              style={{ paddingTop: 6 }}
            >
              <TextField
                type="number"
                label="Valor"
                value={fixValue}
                onChange={({ target: { value } }) => setFixValue(value)}
                margin="normal"
                variant="outlined"
                className={classes.textField}
                InputProps={{ className: classes.inputForm }}
                InputLabelProps={{ className: classes.labelForm }}
              />
            </Grid>
          )}
          {recalculateOption && (
            <Grid
              item
              className={classes.gridItem}
              lg={2}
              sm={3}
              xs={6}
              style={{ padding: '20px 12px' }}
            >
              <Button
                onClick={onRecalculate}
                style={{ textTransform: 'none', width: '100%' }}
                variant="contained"
                color="primary"
              >
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', color: 'white' }}
                >
                  Recalcular
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell
              className={classes.tableCell}
              align="center"
              style={{ borderTopLeftRadius: 8 }}
            >
              Número
            </CustomTableCell>
            <CustomTableCell className={classes.tableCell} align="center">
              Valor
            </CustomTableCell>
            <CustomTableCell
              className={classes.tableCell}
              style={!currentVersion ? {} : { borderTopRightRadius: 8 }}
              align="center"
            >
              Fecha
            </CustomTableCell>
            {!currentVersion && (
              <>
                <CustomTableCell className={classes.tableCell} align="center">
                  {activeRow ? 'Guardar' : 'Editar'}
                </CustomTableCell>
                {!activeRow && (
                  <CustomTableCell className={classes.tableCell} align="center">
                    Agregar
                  </CustomTableCell>
                )}
                <CustomTableCell
                  className={classes.tableCell}
                  align="center"
                  style={{ borderTopRightRadius: 8 }}
                >
                  {activeRow ? 'Descartar' : 'Eliminar'}
                </CustomTableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {content.map(({ number, value, date }) => (
            <TableRow className={classes.row} key={`quotation_${number}`}>
              <CustomTableCell className={classes.tableCell} align="center">
                {number}
              </CustomTableCell>
              <CustomTableCell className={classes.tableCell} align="center">
                {activeRow === number ? (
                  <TextField
                    type="number"
                    placeholder="0"
                    value={
                      Number(rowValue) < 0
                        ? ''
                        : rowValue || formatNumber(value).toFixed(2)
                    }
                    onChange={({ target }) => setRowValue(target.value || -1)}
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    InputProps={{ className: classes.inputForm }}
                  />
                ) : (
                  `$ ${parseNumber(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                )}
              </CustomTableCell>
              <CustomTableCell className={classes.tableCell} align="center">
                {activeRow === number ? (
                  <FormControl style={{ width: '100%' }}>
                    <InlineDatePicker
                      variant="outlined"
                      format="YYYY-MM-DD"
                      value={rowDate || date}
                      onChange={(selected) =>
                        setRowDate(selected.format('YYYY-MM-DD'))
                      }
                      className={classes.pickerContainer}
                      InputProps={{ className: classes.inputForm }}
                      {...(activeRow === 1
                        ? { maxDate: content[1].date }
                        : activeRow === content.length
                        ? { minDate: content[activeRow - 2].date }
                        : {
                            minDate: content[activeRow - 2].date,
                            maxDate: content[activeRow].date,
                          })}
                    />
                  </FormControl>
                ) : (
                  moment(date).format('DD-MMM-YYYY')
                )}
              </CustomTableCell>
              {!currentVersion && (
                <>
                  <CustomTableCell className={classes.tableCell} align="center">
                    {(activeRow === number || !activeRow) && (
                      <IconButton
                        className={classes.iconButton}
                        onClick={() =>
                          activeRow ? onEditRow() : setActiveRow(number)
                        }
                      >
                        {activeRow === number ? <SaveIcon /> : <EditIcon />}
                      </IconButton>
                    )}
                  </CustomTableCell>
                  <CustomTableCell className={classes.tableCell} align="center">
                    {(activeRow === number || !activeRow) && (
                      <IconButton
                        className={classes.iconButton}
                        onClick={() =>
                          activeRow ? onDiscardRow() : onAddRow(number)
                        }
                      >
                        {activeRow === number ? <CloseIcon /> : <AddIcon />}
                      </IconButton>
                    )}
                  </CustomTableCell>
                  {!activeRow && (
                    <CustomTableCell
                      className={classes.tableCell}
                      align="center"
                    >
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => onDeleteRow(number)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CustomTableCell>
                  )}
                </>
              )}
            </TableRow>
          ))}
          <TableRow className={`${classes.rowTotal} ${!valid && 'error'}`}>
            <CustomTableCell
              className={classes.tableCell}
              align="center"
              style={{ borderBottomLeftRadius: 8 }}
            >
              Valor total de Cuotas
            </CustomTableCell>
            <CustomTableCell
              className={classes.tableCell}
              align="center"
            >{`$ ${currentValue.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}</CustomTableCell>
            {!currentVersion && (
              <>
                <CustomTableCell className={classes.tableCell} align="center" />
                <CustomTableCell className={classes.tableCell} align="center" />
                {!activeRow && (
                  <CustomTableCell
                    className={classes.tableCell}
                    align="center"
                  />
                )}
              </>
            )}
            <CustomTableCell
              className={classes.tableCell}
              align="center"
              style={{ borderBottomRightRadius: 8 }}
            />
          </TableRow>
        </TableBody>
      </Table>
      {!valid && (
        <Typography className={classes.textError} variant="body2">
          {`* La suma de las cuotas debe sumar un total de $ ${formatNumber(
            realValue,
          ).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}, por favor cámbiela manualmente`}
          <br />
          {`* Saldo a cuadrar (USD): ${(
            Number(currentValue) - Number(realValue)
          ).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
        </Typography>
      )}
    </Paper>
  );
};

QuotationTable.propTypes = {
  classes: PropTypes.object.isRequired,
  lead: PropTypes.object,
  formValues: PropTypes.object,
  handleInputChange: PropTypes.func,
  setSimpleDialog: PropTypes.func,
};

export default withStyles(styles)(QuotationTable);
