export const styles = (theme) => ({
  loadingWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0px',
  },
  paperRoot: {
    width: '95%',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 3,
  },
});

export default styles;
