/* eslint-disable react/prop-types */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import {styles} from './styles';

const NoOptionsMessage = (props) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const inputComponent = ({inputRef, ...props}) => {
  return <div ref={inputRef} {...props} />;
};

const Control = (props) => {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = (props) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder = (props) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const SingleValue = (props) => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = (props) => {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
};


const Menu = (props) => {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null
    };
  }

  componentWillMount() {
    const {value, suggestions, multiple} = this.props;
    if (multiple) {
      this.setState({value});
    } else if (value && value !== null) {
      const newValue = suggestions.filter(obj => obj.value === value.value)[0];
      this.setState({value: newValue});
    }
  }

  handleChangeCell = (value) => {
    const {multiple} = this.props;
    if (multiple) {
      this.setState({value});
      this.props.handleChangeCell(value);
    } else {
      this.setState({value});
      this.props.handleChangeCell(value !== null ? value.value.toString() : null);
    }
  };

  render() {
    const {classes, suggestions, multiple, isNoClearable} = this.props;
    return (
      <Select
        classes={classes}
        options={suggestions}
        placeholder="Escoja una opción"
        components={components}
        value={this.state.value}
        onChange={this.handleChangeCell}
        isClearable={!isNoClearable}
        isMulti={multiple}
      />
    );
  }
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeCell: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  multiple: PropTypes.bool.isRequired,
  isNoClearable: PropTypes.bool
};

export default withStyles(styles, {withTheme: true})(Autocomplete);
