import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import Phone from '@material-ui/icons/Phone';
import Mail from '@material-ui/icons/Mail';
import {styles} from './styles';
import {copyObject} from '../../../../utils/utils';

export class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lead: null
    };
    this.renderLeadUserPhone = this.renderLeadUserPhone.bind(this);
    this.renderLeadUserMail = this.renderLeadUserMail.bind(this);
    this.renderLeadUserName = this.renderLeadUserName.bind(this);
    this.renderLoadingLead = this.renderLoadingLead.bind(this);
  }

  componentWillMount() {
    const {lead} = this.props;
    if (lead) {
      this.setState({lead: copyObject(lead)});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.lead !== nextProps.lead) {
      this.setState({lead: copyObject(nextProps.lead)});
    }
  }

  renderLeadUserName() {
    const {lead} = this.state;
    if (lead.user) {
      if (lead.user.name) {
        return `${lead.user.name} ${lead.user.lastname}`;
      }
    }
    return null;
  }

  renderLeadUserPhone() {
    const {lead} = this.state;
    if (lead.user) {
      if (lead.user.phone) {
        return lead.user.phone;
      }
    }
    return null;
  }

  renderLeadUserMail() {
    const {lead} = this.state;
    if (lead.user) {
      if (lead.user.email) {
        return lead.user.email;
      }
    }
    return null;
  }

  renderLoadingLead() {
    const {isLoading} = this.props;
    const {lead} = this.state;
    if (isLoading === true || lead === null) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)'
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto'
            }}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const {classes, isNew, lead} = this.props;
    let stage = '';
    if (lead.stage !== '') {
      if (lead.status) {
        if (lead.status.stages.length > 0) {
          const stageExists = lead.status.stages.filter(obj => obj._id === lead.stage);
          if (stageExists.length > 0) {
            stage = stageExists[0].name;
          }
        }
      }
    }
    return (
      <div>
        {this.renderLoadingLead()}
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{minWidth: 400}}>
                  <div className={classes.submitContainer}>
                    <Toolbar className={classes.smallBar}>
                      <Typography variant="h4">
                        {this.renderLeadUserName()}
                      </Typography>
                    </Toolbar>
                    <Toolbar className={classes.smallBar}>
                      <Phone />
                      <a href={`tel::${this.renderLeadUserPhone()}`}>
                        <Typography variant="subtitle1">
                          &nbsp; {this.renderLeadUserPhone()}
                        </Typography>
                      </a>
                    </Toolbar>
                    <Toolbar className={classes.smallBar}>
                      <Mail />
                      <a href={`mailto:${this.renderLeadUserMail()}`}>
                        <Typography variant="subtitle1">
                          &nbsp; {this.renderLeadUserMail()}
                        </Typography>
                      </a>
                    </Toolbar>
                  </div>
                </TableCell>
                {!isNew && (
                  <TableCell>
                    <div className={classes.submitContainer}>
                      {lead.brokers_arr && (
                        <div>
                          {lead.brokers_arr.length > 0 && (
                            <Typography variant="body1">
                              <b>Asignado a: </b>
                              {lead.brokers_arr.join(', ')}
                            </Typography>
                          )}
                        </div>
                      )}
                      <Typography variant="body1">
                        <b>Estado: </b>
                        {lead.status.name}
                      </Typography>
                      {stage !== '' && (
                        <Typography variant="body1">
                          <b>Etapa: </b>
                          {stage}
                        </Typography>
                      )}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

User.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  lead: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const {leads} = state;
  return {
    lead: leads.lead,
    isLoading: leads.sendingLead
  };
};

export default connect(mapStateToProps)(withStyles(styles)(User));
