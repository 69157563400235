import { useState } from 'react';
import { randomString } from '../utils/utils';

export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const changeManualState = (newSatate) => {
    setValues({ ...values, ...newSatate, formHash: randomString(8) });
  };

  const handleInputChange = ({ target }, refresh = true) => {
    const object = {
      ...values,
      [target.name]: target.value,
    };

    // Special Considerations
    if (refresh) object.formHash = randomString(8);
    if (
      [
        'totalQuotes',
        'builderFinancingPercentage',
        'coveredAreaPrice',
        'gardenOrTerraceAreaPrice',
        'parkingSpacesPrice',
        'warehouseValue',
        'discountValue'
      ].includes(target.name) &&
      refresh
    )
      object.content = [];

    setValues(object);
  };

  return [values, handleInputChange, changeManualState];
};
