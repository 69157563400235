import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { styles } from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import { Chip, MenuItem, Select } from '@material-ui/core';

import { updatePlan } from '../../../../actions/plans';

const PlanDetails = (props) => {
  const [planDetail, setPlan] = useState({
    active: true,
    name: '',
  });

  const [selected, setSelected] = useState([]);

  const { classes, graphs } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    const { isNew, plan } = props;
    if (plan) {
      setPlan(plan);
      setSelected(plan.graphs || []);
    }
  }, []);

  const onEditButtonClick = async () => {
    const { plan } = props;
    const { name, active } = planDetail;
    await dispatch(updatePlan(plan.id, { name, active, graphs:selected }));
    props.handleGoBack();
  };
  

  const handleChangePlan = (e) => {
    const name = e.target.value;
    setPlan((prevState) => {
      return { ...prevState, name };
    });
  };

  const isInvalid = () => {
    const { name } = planDetail;
    if (name !== '') {
      return false;
    }
    return true;
  };

  const renderButton = () => {
    const { isLoading, isNew, classes } = props;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} este plan?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} Plan`}
          variant="contained"
          isDisabled={isInvalid()}
          confirmAction={onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  };

  const renderSelectValue = () => {
    const graphsFiltered = graphs.filter((graph) => selected.includes(graph.id));
    return graphsFiltered.map((graph, index) => (
      <div key={index}>
        <Chip label={graph.title.toString()} className={classes.chip} />
      </div>
    ));
  };

  
  const handleChangeGrahps = (event) => {
    setSelected(event.target.value);
  };

  const { isLoading, isNew, handleGoBack } = props;
  return isLoading === true ? (
    <CircularProgress
      style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }}
    />
  ) : (
    <Paper elevation={2} className={classes.paperRoot}>
      <Toolbar>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">{!isNew ? 'Detalle de ' : 'Nuevo '} plan</Typography>
      </Toolbar>
      <div style={{ width: 'auto', overflowX: 'scroll' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Nombre</b>
              </TableCell>
              <TableCell>
                <TextField
                  autoFocus
                  value={planDetail.name}
                  onChange={handleChangePlan}
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}>
                <b>Gráficos</b>
              </TableCell>
              <TableCell>
                <Select
                  multiple
                  value={selected}
                  onChange={handleChangeGrahps}
                  renderValue={() => renderSelectValue()}
                  fullWidth
                  className={classes.brokerSelect}
                >
                  {graphs.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={classes.submitContainer}>{renderButton()}</div>
    </Paper>
  );
};

PlanDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  plan: PropTypes.object.isRequired,
  graphs: PropTypes.array.isRequired,
  handleGoBack: PropTypes.func.isRequired,
};

export default withStyles(styles)(PlanDetails);
