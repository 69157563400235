import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {
  filterBuilders,
  createBuilder,
  updateBuilder
} from '../../../actions/builders';
import {getProfile} from '../../../actions/profiles';
import EnhancedTable from '../../common/enhancedTable';
import {builderCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import BuilderDetail from './detail';
import {getCompanies} from '../../../actions/companies';
import {SUPER_ADMIN_ROL_ID} from '../../../utils/utils';

export class Builders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      builder: null,
      isNew: false,
      title: 'Constructoras'
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleUpdateBuilder = this.handleUpdateBuilder.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
    this.formatColsFromUser = this.formatColsFromUser.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.getCompanies();
    this.props.filterBuilders(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  onAdd() {
    this.setState({isNew: true, builder: {}});
  }

  onSearch(searchText) {
    this.props.filterBuilders(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      searchText
    );
  }

  onDetail(id) {
    const builder = this.props.builders.filter(obj => obj.id === id)[0];
    this.setState({builder, isNew: false});
  }

  onOrder(order, orderBy) {
    this.props.filterBuilders(
      this.props.page,
      this.props.rowsPerPage,
      order,
      orderBy,
      this.props.searchText
    );
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_builders) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  };

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_builders) {
        return true;
      }
    }
    return false;
  };

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_builders) {
        return true;
      }
    }
    return false;
  };

  formatColsFromUser = (cols) => {
    const profileId = localStorage.getItem('profileId');
    let newCols = cols;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      newCols = cols.filter(col => col.id !== 'company');
    }
    return newCols;
  }

  handleChangePage(page) {
    this.props.filterBuilders(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  handleGoBack() {
    this.props.filterBuilders(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
    this.setState({builder: null, isNew: false});
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterBuilders(
      this.props.page,
      rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
  }

  handleUpdateBuilder(editedBuilder) {
    const {builder} = this.state;
    this.setState({builder: {...editedBuilder, company: {id: editedBuilder.company}}});
    const edited = {};
    if (editedBuilder.active !== builder.active) {
      edited.active = editedBuilder.active;
    }
    if (editedBuilder.name !== builder.name) {
      edited.name = editedBuilder.name;
    }
    if (editedBuilder.address !== builder.address) {
      edited.address = editedBuilder.address;
    }
    if (editedBuilder.icon !== builder.icon) {
      edited.icon = editedBuilder.icon;
    }
    if (editedBuilder.revenue_percentage !== builder.revenue_percentage) {
      edited.revenue_percentage = editedBuilder.revenue_percentage;
    }
    if (editedBuilder.representative !== builder.representative) {
      edited.representative = editedBuilder.representative;
    }
    if (editedBuilder.company === -1) {
      edited.company = null;
    } else if (editedBuilder.company !== builder.company) {
      edited.company = editedBuilder.company;
    }
    this.props.updateBuilder(editedBuilder.id, edited);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {
      isLoading,
      isLoadingProfile,
      builders,
      dataLength,
      companies
    } = this.props;
    const {title, builder, isNew} = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const newBuildersCols = this.formatColsFromUser(builderCols);
    const data = {
      rows: builders,
      cols: newBuildersCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };

    if (isLoading === true || isLoadingProfile === true) {
      return (
        <CircularProgress
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto'
          }}
        />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {builder === null ? (
          <EnhancedTable
            title={title}
            data={data}
            handlers={handlers}
            config={config}
          />
        ) : (
          <BuilderDetail
            builder={builder}
            handleGoBack={this.handleGoBack}
            handleCreateBuilder={this.props.createBuilder}
            handleUpdateBuilder={this.handleUpdateBuilder}
            isLoading={isLoading}
            isNew={isNew}
            companies={companies}
          />
        )}
      </div>
    );
  }
}

Builders.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingProfile: PropTypes.bool.isRequired,
  builders: PropTypes.array.isRequired,
  filterBuilders: PropTypes.func.isRequired,
  updateBuilder: PropTypes.func.isRequired,
  createBuilder: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  getCompanies: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
  const {builders, companies} = state;
  return {
    isLoading: builders.sending,
    builders: builders.builders,
    dataLength: builders.dataLength,
    error: builders.error,
    message: builders.message,
    searchText: builders.searchText,
    order: builders.order,
    orderBy: builders.orderBy,
    page: builders.page,
    rowsPerPage: builders.rowsPerPage,
    filters: builders.filters,
    companies: companies.companies
  };
};

const mapDispatchToProps = {
  filterBuilders,
  createBuilder,
  getProfile,
  updateBuilder,
  getCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(Builders);
