/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';
import Chip from '@material-ui/core/Chip';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import DragIcon from '@material-ui/icons/DragHandle';
import Delete from '@material-ui/icons/Delete';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Place from '@material-ui/icons/Place';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import FileCopy from '@material-ui/icons/FileCopy';
import Receipt from '@material-ui/icons/Receipt';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Refresh from '@material-ui/icons/Refresh';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import CommentIcon from '@material-ui/icons/Comment';
import CloseIcon from '@material-ui/icons/Close';
import { getUnitImages } from '../../../../actions/units';
import { getEventsStatistics } from '../../../../actions/calendar';
import { styles } from './styles';
import placeholderImage from '../../../../assets/placeholder.png';
import {
  PROJECTS_URL,
  moveArray,
  dateRegion,
  copyObject,
  sortOptions,
  UNIT_TYPE,
  BUDGET,
  ZONE,
  sortObjectByProperty,
} from '../../../../utils/utils';
import { GET_UNIT_IMAGES_SUCCESS } from '../../../../actions/types/units';
import { UPDATE_LEAD_MATCHES_SUCCESS } from '../../../../actions/types/leads';
import CONSTANTS from '../../.././../utils/constants';
import DialogMap from '../../../common/simpleDialogMap';
import { InputAdornment } from '@material-ui/core';
import Proformas from '../../../proformas';
import AppointmentModal from '../../../common/appointmentModal';
import EventList from '../../../common/eventList';

const DragHandle = sortableHandle(() => <DragIcon />);

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const formatUnit = (unit) => {
  let unitFormated = '-';

  if (unit) {
    const excludedType = CONSTANTS.UNITS_TYPES_WITHOUT_ROOMS.indexOf(unit.id);
    const price = Number(unit.price).toFixed(2);
    const match = Number(unit.match).toFixed(0);
    if (excludedType >= 0) {
      unitFormated = `${unit.name} - ${match}% ($${price})`;
    } else {
      const roms = unit.rooms ? `${unit.rooms}dorm -` : '';
      unitFormated = `${unit.name} - ${match}% (${roms} $${price})`;
    }
  }

  return unitFormated;
};

const SortableItem = SortableElement(
  ({
    project,
    index,
    auxIndex,
    classes,
    onChangeComent,
    handleConfirmDeleteMatch,
    onChangeUnit,
    propertyTypes,
    handleAddComment,
    renderPointsInFavor,
    renderPointsAgainst,
    leadStages,
    onChangeProjectStage,
    handleCheckMatch,
    checkedMatches,
    handleBlockProject,
    handleUnBlockProject,
    handleOpenMap,
    handleDuplicateProject,
  }) => {
    const unitToShow = project.units[0];
    if (unitToShow) {
      const imagesToShow = unitToShow.images || [];
      const comment = unitToShow.comments
        ? unitToShow.comments[unitToShow.comments.length - 1]
          ? unitToShow.comments[unitToShow.comments.length - 1].comment
          : ''
        : '';
      return (
        <div key={index} className={classes.matchContainer}>
          <div className={classes.row}>
            <Tooltip title="Mover proyecto" className={classes.cursor}>
              <IconButton className={classes.matchButton} className={classes.cursor}>
                <DragHandle className={classes.cursor} />
              </IconButton>
            </Tooltip>

            {project.blocked ? (
              <Tooltip title="Desbloquear proyecto" className={classes.unblock}>
                <IconButton
                  className={classes.matchButton}
                  onClick={() => handleUnBlockProject(auxIndex)}
                >
                  <Lock />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Bloquear proyecto" className={classes.block}>
                <IconButton
                  className={classes.matchButton}
                  onClick={() => handleBlockProject(auxIndex)}
                >
                  <LockOpen />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Duplicar proyecto" className={classes.cursor}>
              <IconButton
                className={classes.matchButton}
                onClick={() => handleDuplicateProject(auxIndex)}
              >
                <FileCopy />
              </IconButton>
            </Tooltip>
            {localStorage.getItem('isBroker') && <Proformas project={project} />}
            <Tooltip title="Seleccionar proyecto" className={classes.delete}>
              <Checkbox
                checked={checkedMatches.includes(auxIndex)}
                onChange={() => handleCheckMatch(auxIndex)}
                value={`${auxIndex}`}
              />
            </Tooltip>
            <Tooltip title="Eliminar proyecto" className={classes.delete}>
              <IconButton
                className={classes.matchButton}
                onClick={() => handleConfirmDeleteMatch(auxIndex)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
          {imagesToShow.length > 0 ? (
            <a href={imagesToShow[0].url} target="_blank" rel="noopener noreferrer">
              <img src={imagesToShow[0].url} alt="match unit" className={classes.matchImage} />
            </a>
          ) : (
            <img src={placeholderImage} alt="match unit" className={classes.matchImage} />
          )}
          <Button
            fullWidth
            onClick={() =>
              project.code ? window.open(`${PROJECTS_URL}propiedades/${project.code}`, '_blank') :  window.open(`${PROJECTS_URL}`, '_blank')
            }
            style={{ alignItems: 'flex-start' }}
          >
            <Typography variant="body1" className={classes.projectTitle}>
              {project.name}
            </Typography>
          </Button>
          <br />
          <Typography variant="body1" className={classes.fontSize}>
            <b>{propertyTypes.filter((obj) => obj.id === unitToShow.type)[0].name}</b>
          </Typography>
          <Typography variant="body1" className={classes.fontSize}>
            <b>Sector: </b>
            {project.zone}
            {project.location && project.location.coordinates.length > 0 && (
              <Place
                onClick={() => handleOpenMap(project)}
                className={classes.pointer}
                color="primary"
              />
            )}
          </Typography>
          <Typography variant="body1" className={classes.fontSize}>
            <b>Precio: </b>$ {unitToShow.price.toLocaleString('de-DE')}
          </Typography>
          <Typography variant="body1" className={classes.fontSize}>
            <b>Área total: </b>
            {unitToShow.area} m2
          </Typography>
          {unitToShow.rooms && unitToShow.rooms !== '' && (
            <Typography variant="body1" className={classes.fontSize}>
              <b>Número de dormitorios: </b>
              {unitToShow.rooms || ''}
            </Typography>
          )}
          {unitToShow.parkCount && unitToShow.parkCount !== '' && (
            <Typography variant="body1" className={classes.fontSize}>
              <b>Parqueos: </b>
              {unitToShow.parkCount || ''}
            </Typography>
          )}
          {!!unitToShow.externalArea && (
            <Typography variant="body1" className={classes.fontSize}>
              <b>Área Terraza/jardín: </b>
              {unitToShow.externalArea || ''} m2
            </Typography>
          )}
          {!!unitToShow.balconArea && (
            <Typography variant="body1" className={classes.fontSize}>
              <b>Área Balcón: </b>
              {unitToShow.balconArea || ''} m2
            </Typography>
          )}
          {project.finishDate && project.finishDate !== '' && (
            <Typography variant="body1" className={classes.fontSize}>
              <b>Fecha de entrega: </b>
              {project.finishMonth || ''} {project.finishDate || ''}
            </Typography>
          )}
          {unitToShow.stage && unitToShow.stage !== '' && (
            <Typography variant="body1" className={classes.fontSize}>
              <b>Etapa: </b>
              {unitToShow.stage || ''}
            </Typography>
          )}
          <br />
          <Select
            value={unitToShow._id}
            native
            onChange={(e) => onChangeUnit(e, auxIndex)}
            fullWidth
          >
            {project.units.map((unit, unitIndex) => {
              return (
                <option key={unitIndex} value={unit._id}>
                  {formatUnit(unit)}
                </option>
              );
            })}
          </Select>
          <br />
          <br />
          <Typography variant="body1" className={classes.match}>
            {`${Number(unitToShow.totalMatch).toFixed(2)}% match`}
          </Typography>
          <Divider />
          <br />
          <FormControl fullWidth>
            <Select
              value={project.projectStage}
              onChange={(e) => onChangeProjectStage(e, auxIndex)}
              fullWidth
              name="Etapa"
              displayEmpty
            >
              <MenuItem value="" disabled>
                Ninguno
              </MenuItem>
              {sortObjectByProperty(leadStages, 'order')
                .filter((obj) => obj.type === 'unit')
                .map((stage, stageIndex) => {
                  return (
                    <MenuItem key={stageIndex} value={stage.id}>
                      {stage.name}
                    </MenuItem>
                  );
                })}
            </Select>
            <FormHelperText>Etapa</FormHelperText>
          </FormControl>
          <br />
          <br />
          <Divider />
          <br />
          {renderPointsInFavor(auxIndex, unitToShow)}
          {renderPointsAgainst(auxIndex, unitToShow)}
          <br />
          <Divider />
          <br />
          <br />
          <TextField
            variant="outlined"
            value={comment}
            placeholder="Comentarios"
            multiline
            fullWidth
            onChange={(e) => onChangeComent(e, auxIndex)}
            InputProps={{
              endAdornment: comment && comment !== '' && (
                <InputAdornment style={{ marginLeft: 0 }} position="end">
                  <IconButton onClick={() => onChangeComent({ target: { value: '' } }, auxIndex)}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <Button
            fullWidth
            onClick={() => handleAddComment(project, auxIndex)}
            style={{ fontSize: 11 }}
          >
            Actualizar comentario
          </Button>
        </div>
      );
    }
    return null;
  },
);
const SortGrid = SortableContainer(
  ({
    items,
    classes,
    projectIndex,
    handleConfirmDeleteMatch,
    handleClickOpen,
    handleClickClose,
    showModal,
    onChangeComent,
    onChangeUnit,
    handleAddComment,
    propertyTypes,
    renderPointsInFavor,
    renderPointsAgainst,
    leadStages,
    onChangeProjectStage,
    handleCheckMatch,
    checkedMatches,
    handleBlockProject,
    handleUnBlockProject,
    handleOpenMap,
    handleDuplicateProject,
  }) => {
    const sortProjects = items;
    const grid = sortProjects
      .filter((obj) => obj.units.length > 0)
      .map((project, index) => {
        return (
          <SortableItem
            key={index}
            index={index}
            item={project}
            project={project}
            classes={classes}
            auxIndex={index}
            projectIndex={projectIndex}
            handleConfirmDeleteMatch={handleConfirmDeleteMatch}
            handleClickOpen={handleClickOpen}
            onChangeComent={onChangeComent}
            propertyTypes={propertyTypes}
            onChangeUnit={onChangeUnit}
            handleAddComment={handleAddComment}
            handleClickClose={handleClickClose}
            renderPointsInFavor={renderPointsInFavor}
            renderPointsAgainst={renderPointsAgainst}
            showModal={showModal}
            leadStages={leadStages}
            onChangeProjectStage={onChangeProjectStage}
            handleCheckMatch={handleCheckMatch}
            checkedMatches={checkedMatches}
            handleBlockProject={handleBlockProject}
            handleUnBlockProject={handleUnBlockProject}
            handleOpenMap={handleOpenMap}
            handleDuplicateProject={handleDuplicateProject}
          />
        );
      });
    return <div>{grid}</div>;
  },
);

export class BestOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingImages: true,
      showModal: false,
      projectIndex: -1,
      lead: null,
      openDeleteModal: false,
      openDeleteMatchesModal: false,
      openUpdateFixModal: false,
      openBadFixModal: false,
      openParametersFixModal: false,
      auxIndex: -1,
      index: 0,
      modal: '',
      open: false,
      comment: '',
      unitToShow: {},
      checkedMatches: [],
      sortBy: 'auto',
      modalMapOpen: false,
      projectMap: { lat: null, long: null },
      fixParams: [], // identifiers para sector, tipo de propiedad y presupuesto
      openAppointmentModal: null,
      openFinishAppointmentModal: null,
    };

    this.renderMatches = this.renderMatches.bind(this);
    this.handleUpdateMatches = this.handleUpdateMatches.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleDeleteMatch = this.handleDeleteMatch.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleConfirmDeleteMatch = this.handleConfirmDeleteMatch.bind(this);
    this.handleAddComment = this.handleAddComment.bind(this);
    this.handleOpenDelete = this.handleOpenDelete.bind(this);
    this.handleCloseDelete = this.handleCloseDelete.bind(this);
    this.handleOpenDeleteMatches = this.handleOpenDeleteMatches.bind(this);
    this.handleCloseDeleteMatches = this.handleCloseDeleteMatches.bind(this);
    this.handleOpenUpdate = this.handleOpenUpdate.bind(this);
    this.handleCloseUpdate = this.handleCloseUpdate.bind(this);
    this.handleCloseBadFix = this.handleCloseBadFix.bind(this);
    this.handleSuccessDelete = this.handleSuccessDelete.bind(this);
    this.handleSuccessDeleteMatches = this.handleSuccessDeleteMatches.bind(this);
    this.handleSuccessUpdate = this.handleSuccessUpdate.bind(this);
    this.handleGetImages = this.handleGetImages.bind(this);
    this.handleGetImage = this.handleGetImage.bind(this);
    this.handleUpdateComment = this.handleUpdateComment.bind(this);
    this.handleChangePointsInFavor = this.handleChangePointsInFavor.bind(this);
    this.handleChangePointsAgainst = this.handleChangePointsAgainst.bind(this);
    this.handleCloseParametersFixModal = this.handleCloseParametersFixModal.bind(this);
    this.handleOpenParametersFixModal = this.handleOpenParametersFixModal.bind(this);
    this.handleSuccessParametersFixModal = this.handleSuccessParametersFixModal.bind(this);
    this.onChangeComent = this.onChangeComent.bind(this);
    this.onChangeSort = this.onChangeSort.bind(this);
    this.onChangeUnit = this.onChangeUnit.bind(this);
    this.onChangeFixParams = this.onChangeFixParams.bind(this);
    this.onChangeProjectStage = this.onChangeProjectStage.bind(this);
    this.fetchEventsStatistics = this.fetchEventsStatistics.bind(this);
    this.renderPDFButton = this.renderPDFButton.bind(this);
    this.renderLoadingLead = this.renderLoadingLead.bind(this);
    this.renderPointsAgainst = this.renderPointsAgainst.bind(this);
    this.renderPointsInFavor = this.renderPointsInFavor.bind(this);
    this.renderSelectValue = this.renderSelectValue.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.handleCheckMatch = this.handleCheckMatch.bind(this);
    this.handleDeleteMatches = this.handleDeleteMatches.bind(this);
    this.renderDeleteMatchesButton = this.renderDeleteMatchesButton.bind(this);
    this.renderDeleteMatchDialog = this.renderDeleteMatchDialog.bind(this);
    this.renderDeleteMatchesDialog = this.renderDeleteMatchesDialog.bind(this);
    this.renderGenerateFixDialog = this.renderGenerateFixDialog.bind(this);
    this.renderBadFixDialog = this.renderBadFixDialog.bind(this);
    this.handleBlockProject = this.handleBlockProject.bind(this);
    this.handleUnBlockProject = this.handleUnBlockProject.bind(this);
    this.handleOpenMap = this.handleOpenMap.bind(this);
    this.handleCloseMap = this.handleCloseMap.bind(this);
    this.handleDuplicateProject = this.handleDuplicateProject.bind(this);
  }

  componentWillMount() {
    const { lead } = this.props;
    if (lead) {
      this.handleGetImages(lead);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lead !== this.props.lead) {
      if (this.state.lead) {
        if (nextProps.lead.matches !== this.state.lead.matches) {
          this.handleGetImages(nextProps.lead);
        }
      }
    }
  }

  onChangeSort(e) {
    const { lead } = this.state;
    this.props.onReplaceMatches(lead._id, e.target.value);
  }

  onChangeFixParams(e) {
    this.setState({ fixParams: e.target.value });
  }

  onChangeUnit(e, auxIndex) {
    const { lead } = this.state;
    const unitId = e.target.value;
    const unitIndex = lead.matches[auxIndex].units
      .map((obj, index) => {
        if (obj._id === unitId) {
          return index;
        }
        return null;
      })
      .filter((obj) => obj !== null);
    if (unitIndex) {
      const oldIndex = unitIndex[0];
      const newIndex = 0;
      const { matches } = lead;
      let { units } = matches[auxIndex];
      units = moveArray(units, oldIndex, newIndex);
      matches[auxIndex].units = units;
      lead.matches = matches;
      this.props.onSwapMatchUnits(lead._id, auxIndex, oldIndex, newIndex);
      this.handleGetImage(auxIndex, unitId);
      this.setState({ lead });
    }
  }

  fetchEventsStatistics() {
    const broker = localStorage.getItem('user');

    return this.props.getEventsStatistics(this.props.lead._id, {
      leads: [this.props.lead._id],
      brokers: [broker],
    });
  }

  async onChangeProjectStage({ target: { value } }, auxIndex) {
    const {
      leadStages,
      lead,
      eventsStatistics: { statistics = {} },
    } = this.props;

    try {
      await this.fetchEventsStatistics();
    } catch (error) {}

    const { activeEventsNotRealized = 0 } = statistics[lead._id]  ? (statistics[lead._id][0] ? statistics[lead._id][0] : {}) : {};

    const selectedStage =
      leadStages && leadStages.length && leadStages.find((stage) => stage.id === value);

    console.log('selectedStage:', selectedStage);
    if (selectedStage.isConfirmedAppointment)
      this.setState({ openAppointmentModal: { value, auxIndex } });
    else if (selectedStage.isCompletedAppointment)
      this.setState({ openFinishAppointmentModal: { value, auxIndex } });
    else {
      activeEventsNotRealized > 0 &&
        this.setState({ openFinishAppointmentModal: { value, auxIndex } });
    }
  }

  confirmChangeProjectStage({ value, auxIndex }) {
    const { lead } = this.state;
    const projectStage = value;
    lead.matches[auxIndex].projectStage = projectStage;
    this.props.onChangeProjectMatchStage(lead._id, auxIndex, projectStage);
    this.setState({ lead });
  }

  onChangeComent(e, auxIndex) {
    const { lead } = this.state;
    const comment = {
      date_created: moment.tz(moment(), dateRegion),
      comment: e.target.value,
    };
    if (lead.matches) {
      const unit = lead.matches[auxIndex].units[0];
      if (unit.comments) {
        if (unit.comments.length === 0) {
          unit.comments.push(comment);
        } else {
          unit.comments[unit.comments.length - 1] = comment;
        }
      } else {
        unit.comments = [];
        unit.comments.push(comment);
      }
      this.setState({ lead });
    }
  }

  onSortEnd(data) {
    const { lead } = this.state;
    let { matches } = lead;
    if (matches) {
      matches = moveArray(matches, data.oldIndex, data.newIndex);
      lead.matches = matches;
      this.props.onSwapMatches(lead._id, data.oldIndex, data.newIndex);
      this.setState({ lead });
    }
  }

  handleGetImages = async (lead) => {
    this.setState({ isLoadingImages: true });
    if (lead.matches) {
      if (lead.matches.length > 0) {
        for (let i = 0; i < lead.matches.length; i += 1) {
          const match = lead.matches[i];
          if (match.units) {
            if (match.units.length > 0) {
              // eslint-disable-next-line no-await-in-loop
              await this.props.getUnitImages(match.units[0]._id).then((result) => {
                if (result.type === GET_UNIT_IMAGES_SUCCESS) {
                  const { images } = result.payload;
                  // eslint-disable-next-line no-param-reassign
                  match.units[0].images = images;
                }
              });
            }
          }
          // eslint-disable-next-line no-param-reassign
          lead.matches[i] = match;
        }
        this.setState({ isLoadingImages: false, lead });
      } else {
        this.setState({ isLoadingImages: false, lead });
      }
    } else {
      this.setState({ isLoadingImages: false, lead });
    }
  };

  handleGetImage = async (projectIndex, unitId) => {
    const { lead } = this.state;
    await this.props.getUnitImages(unitId).then((result) => {
      if (result.type === GET_UNIT_IMAGES_SUCCESS) {
        const { images } = result.payload;
        // eslint-disable-next-line no-param-reassign
        lead.matches[projectIndex].units[0].images = images;
      }
    });
    this.setState({ lead });
  };

  handleAddComment(item, auxIndex) {
    const { lead } = this.props;
    if (item) {
      const { matches } = lead;
      if (matches[auxIndex]) {
        const unit = lead.matches[auxIndex].units[0];
        if (unit.comments) {
          const comment = {
            date_created: moment.tz(moment(), dateRegion),
            comment: unit.comments[unit.comments.length - 1].comment,
          };
          unit.comments.push(comment);
          matches[auxIndex].units[0] = unit;
          lead.matches = matches;
          this.props.onAddUnitMatchComment(
            lead._id,
            auxIndex,
            lead.matches[auxIndex].units[0]._id,
            comment.comment,
          );
          this.setState({ lead });
        }
      }
    }
  }

  handleBlockProject(auxIndex) {
    const { lead } = this.state;
    const { matches } = lead;
    if (matches[auxIndex]) {
      matches[auxIndex].blocked = true;
      this.props.onBlockProject(lead._id, auxIndex);
      this.setState({ lead });
    }
  }

  handleUnBlockProject(auxIndex) {
    const { lead } = this.state;
    const { matches } = lead;
    if (matches[auxIndex]) {
      matches[auxIndex].blocked = false;
      this.props.onUnBlockProject(lead._id, auxIndex);
      this.setState({ lead });
    }
  }

  handleDuplicateProject(auxIndex) {
    const { lead } = this.state;
    const { matches } = lead;
    if (matches[auxIndex]) {
      const project = copyObject(matches[auxIndex]);
      project.blocked = false;
      matches.splice(auxIndex + 1, 0, project);
      this.props.handleDuplicateProject(lead._id, auxIndex);
      this.setState({ lead });
    }
  }

  handleDeleteMatch() {
    const { lead, auxIndex } = this.state;
    const { matches } = lead;
    if (matches[auxIndex]) {
      matches.splice(auxIndex, 1);
      lead.matches = matches;
      this.props.onRemoveMatch(lead._id, auxIndex);
      this.setState({ lead });
    }
    this.handleCloseDelete();
  }

  handleDeleteMatches() {
    const { lead } = this.state;
    let { checkedMatches } = this.state;
    const idsToDelete = [];
    checkedMatches = checkedMatches.sort((a, b) => a - b);
    checkedMatches.forEach((index) => idsToDelete.push(lead.matches[index]._id));
    const { matches } = lead;
    const newMatches = copyObject(matches).filter((obj) => {
      for (let i = 0; i < idsToDelete.length; i += 1) {
        if (obj._id === idsToDelete[i]) {
          return false;
        }
      }
      return true;
    });
    lead.matches = newMatches;
    this.setState({ lead });
    this.props.onRemoveMatches(lead._id, checkedMatches);
    this.handleCloseDeleteMatches();
  }

  handleOpenDelete(auxIndex) {
    this.setState({ openDeleteModal: true, auxIndex });
  }

  handleCloseDelete() {
    this.setState({ openDeleteModal: false, auxIndex: -1 });
  }

  handleOpenDeleteMatches() {
    this.setState({ openDeleteMatchesModal: true });
  }

  handleCloseDeleteMatches() {
    this.setState({ openDeleteMatchesModal: false, checkedMatches: [] });
  }

  handleOpenUpdate() {
    this.setState({ openUpdateFixModal: true });
  }

  handleCloseUpdate() {
    this.setState({ openUpdateFixModal: false });
  }

  handleCloseBadFix() {
    this.setState({ openBadFixModal: false });
  }

  handleSuccessUpdate() {
    this.handleCloseUpdate();
    this.handleOpenParametersFixModal();
  }

  handleOpenParametersFixModal() {
    this.setState({ openParametersFixModal: true });
  }

  handleCloseParametersFixModal() {
    this.setState({ openParametersFixModal: false });
  }

  handleSuccessParametersFixModal() {
    this.handleCloseParametersFixModal();
    this.handleUpdateMatches();
  }

  handleSuccessDelete() {
    this.handleDeleteMatch();
  }

  handleSuccessDeleteMatches() {
    this.handleDeleteMatches();
  }

  handleConfirmDeleteMatch(auxIndex) {
    const { lead } = this.props;
    if (lead.matches.length > 0) {
      this.handleOpenDelete(auxIndex);
    } else {
      this.handleSuccessUpdate();
    }
  }

  handleOpenModal(index, modal, commentToShow) {
    const unitToShow = this.props.lead.matches[index].units[0];
    this.setState({
      index,
      open: true,
      modal,
      unitToShow,
      comment: commentToShow,
    });
    if (modal === 'favor') {
      if (unitToShow.pros && unitToShow.pros.length >= 1) {
        this.setState({
          comment: unitToShow.pros[unitToShow.pros.length - 1].comment,
        });
      } else {
        this.setState({ comment: '' });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (unitToShow.cons && unitToShow.cons.length >= 1) {
        this.setState({
          comment: unitToShow.cons[unitToShow.cons.length - 1].comment,
        });
      } else {
        this.setState({ comment: '' });
      }
    }
  }

  handleClickOpen(projectIndex) {
    this.setState({ showModal: true, projectIndex });
  }

  handleClickClose() {
    this.setState({ showModal: false, projectIndex: -1 });
  }

  handleUpdateMatches() {
    const { lead, fixParams } = this.state;
    const { allParameters } = this.props;

    this.setState({ sortBy: null });
    if (lead !== null) {
      const checkRequiredAttrs = lead.preferences
        .filter((item) => item.answerId.length > 0)
        .map((item) => item.attributeId);

      if (
        checkRequiredAttrs.includes(ZONE) &&
        checkRequiredAttrs.includes(BUDGET) &&
        checkRequiredAttrs.includes(UNIT_TYPE)
      ) {
        const parameters = {
          unit: [],
          project: [],
        };
        fixParams.forEach((param) => {
          allParameters.forEach((originalParam) => {
            if (originalParam.identifier === param) {
              if (originalParam.category === 'unit') {
                parameters.unit.push(param);
              } else if (originalParam.category === 'project') {
                parameters.project.push(param);
              }
            }
          });
        });

        this.props.onUpdateLeadMatches(parameters).then((response) => {
          if (response.type === UPDATE_LEAD_MATCHES_SUCCESS) {
            const { matches } = response.payload;
            const newLead = response.payload.lead;
            newLead.matches = matches;
            this.handleGetImages(newLead);
          }
        });
      } else {
        this.setState({ openBadFixModal: true });
      }
    }
  }

  handleUpdateComment(index, unitToShow) {
    const { lead } = this.props;
    const { comment } = this.state;
    const projectId = lead.matches[index]._id;
    const unitId = unitToShow._id;
    let value;
    if (this.state.modal === 'favor') {
      if (unitToShow.pros && unitToShow.pros.length >= 1) {
        // eslint-disable-next-line prefer-destructuring
        value = unitToShow.pros[unitToShow.pros.length - 1].value;
      } else {
        value = 0;
      }
      const data = {
        unitId,
        projectId,
        value,
        comment,
      };
      this.props.handleChangePointsInFavor(data, index);
    } else {
      if (unitToShow.cons && unitToShow.cons.length >= 1) {
        // eslint-disable-next-line prefer-destructuring
        value = unitToShow.cons[unitToShow.cons.length - 1].value;
      } else {
        value = 0;
      }
      const data = {
        unitId,
        projectId,
        value,
        comment,
      };
      this.props.handleChangePointsAgainst(data, index);
    }
    this.handleCloseModal();
  }

  handleCloseModal() {
    this.setState({ open: false, comment: '' });
  }

  handleOpenMap(projectMap) {
    if (projectMap.location.coordinates) {
      const lat = projectMap.location.coordinates[1];
      const long = projectMap.location.coordinates[0];
      this.setState({ modalMapOpen: true, projectMap: { lat, long } });
    }
  }

  handleCloseMap() {
    this.setState({ modalMapOpen: false });
  }

  handleChangePointsInFavor(index, unitToShow, value) {
    const { lead } = this.props;
    const projectId = lead.matches[index]._id;
    let comment = ' ';

    if (unitToShow.pros && unitToShow.pros.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      comment = unitToShow.pros[unitToShow.pros.length - 1].comment;
    }

    const data = {
      unitId: unitToShow._id,
      projectId,
      value,
      comment,
    };
    this.props.handleChangePointsInFavor(data, index);
  }

  handleChangePointsAgainst(index, unitToShow, value) {
    const { lead } = this.props;
    const projectId = lead.matches[index]._id;
    let comment = ' ';

    if (unitToShow.cons && unitToShow.cons.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      comment = unitToShow.cons[unitToShow.cons.length - 1].comment;
    }

    const data = {
      unitId: unitToShow._id,
      projectId,
      value,
      comment,
    };
    this.props.handleChangePointsAgainst(data, index);
  }

  handleCheckMatch(auxIndex) {
    const { checkedMatches } = this.state;
    const indexOfMatch = checkedMatches.indexOf(auxIndex);
    if (indexOfMatch > -1) {
      checkedMatches.splice(indexOfMatch, 1);
    } else {
      checkedMatches.push(auxIndex);
    }
    this.setState({ checkedMatches });
  }

  renderMatches() {
    const { classes, zones, propertyTypes, leadStages, isLoadingUnits } = this.props;
    const { projectIndex, showModal, lead, checkedMatches, isLoadingImages } = this.state;
    if (lead) {
      const { matches } = lead;
      if (zones && matches) {
        const matchesToShow = matches.map((item) => {
          const element = item;
          const zone = zones.filter((obj) => obj.id === item.zone)[0];
          if (zone) {
            element.zone = zone.name;
            return element;
          }
          return null;
        });
        if (matchesToShow.length > 0) {
          return (
            <SortGrid
              items={matches}
              onSortEnd={this.onSortEnd}
              onChangeUnit={this.onChangeUnit}
              handleClickOpen={this.handleClickOpen}
              handleClickClose={this.handleClickClose}
              handleAddComment={this.handleAddComment}
              handleConfirmDeleteMatch={this.handleConfirmDeleteMatch}
              onChangeComent={this.onChangeComent}
              onChangeProjectStage={this.onChangeProjectStage}
              onUnitsSortEnd={this.onUnitsSortEnd}
              showModal={showModal}
              projectIndex={projectIndex}
              propertyTypes={propertyTypes}
              leadStages={leadStages}
              renderPointsInFavor={this.renderPointsInFavor}
              renderPointsAgainst={this.renderPointsAgainst}
              handleDeleteMatch={this.handleDeleteMatch}
              useDragHandle
              lockAxis="xy"
              axis="xy"
              classes={classes}
              pressDelay={80}
              handleCheckMatch={this.handleCheckMatch}
              checkedMatches={checkedMatches}
              handleBlockProject={this.handleBlockProject}
              handleUnBlockProject={this.handleUnBlockProject}
              handleOpenMap={this.handleOpenMap}
              handleDuplicateProject={this.handleDuplicateProject}
            />
          );
        }
      }
    }
    if (isLoadingUnits || isLoadingImages) {
      return (
        <div style={{ padding: 10, margin: '0 auto', display: 'table' }}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <Typography variant="subtitle1" style={{ marginLeft: 20 }}>
        No se encontraron resultados
      </Typography>
    );
  }

  renderLoadingLead() {
    const { isLoading, lead } = this.props;
    const { isLoadingImages } = this.state;
    if (isLoading || lead === null || isLoadingImages) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }
    return null;
  }

  renderPointsInFavor(index, unitToShow) {
    let points = 0;
    let commentToShow = '';
    if (unitToShow.pros && unitToShow.pros.length >= 1) {
      points = unitToShow.pros[unitToShow.pros.length - 1].value;
      commentToShow = unitToShow.pros[unitToShow.pros.length - 1].comment;
    }
    return (
      <div>
        <Rating
          emptySymbol={
            <IconButton style={{ padding: 0 }}>
              {' '}
              <ThumbUp style={{ margin: 2, color: '#757575' }} />
            </IconButton>
          }
          fullSymbol={
            <IconButton style={{ padding: 0 }}>
              <ThumbUp style={{ margin: 2, color: '#00AF9A' }} />
            </IconButton>
          }
          onClick={(value) => {
            this.handleChangePointsInFavor(index, unitToShow, value);
          }}
          initialRating={points}
          style={{ marginRight: 10 }}
        />
        {points > 0 && (
          <Tooltip
            title="Eliminar calificación"
            classes={{
              tooltip: 'tooltipText',
            }}
          >
            <IconButton onClick={() => this.handleChangePointsInFavor(index, unitToShow, 0)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title="Agregar comentario"
          classes={{
            tooltip: 'tooltipText',
          }}
        >
          <IconButton onClick={() => this.handleOpenModal(index, 'favor', commentToShow)}>
            <CommentIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="body1" style={{ fontSize: 10 }}>
          {commentToShow}
        </Typography>
      </div>
    );
  }

  renderPointsAgainst(index, unitToShow) {
    let points = 0;
    let commentToShow = '';
    if (unitToShow.cons && unitToShow.cons.length >= 1) {
      points = unitToShow.cons[unitToShow.cons.length - 1].value;
      commentToShow = unitToShow.cons[unitToShow.cons.length - 1].comment;
    }
    return (
      <div>
        <Rating
          emptySymbol={
            <IconButton style={{ padding: 0 }}>
              {' '}
              <ThumbDown style={{ margin: 2, color: '#757575' }} />
            </IconButton>
          }
          fullSymbol={
            <IconButton style={{ padding: 0 }}>
              <ThumbDown style={{ margin: 2, color: '#FF8C00' }} />
            </IconButton>
          }
          onClick={(value) => {
            this.handleChangePointsAgainst(index, unitToShow, value);
          }}
          initialRating={points}
          style={{ marginRight: 10 }}
        />
        {points > 0 && (
          <Tooltip
            title="Eliminar calificación"
            classes={{
              tooltip: 'tooltipText',
            }}
          >
            <IconButton onClick={() => this.handleChangePointsAgainst(index, unitToShow, 0)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title="Agregar comentario"
          classes={{
            tooltip: 'tooltipText',
          }}
        >
          <IconButton onClick={() => this.handleOpenModal(index, 'against', commentToShow)}>
            <CommentIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="body1" style={{ fontSize: 10 }}>
          {commentToShow}
        </Typography>
      </div>
    );
  }

  renderPDFButton() {
    const { lead } = this.state;
    const { classes } = this.props;
    if (lead) {
      if (lead.matches) {
        if (lead.matches.length > 0) {
          return (
            <Tooltip title="Generar PDF">
              <Link to={`/pdf/${lead._id}`} className={classes.link} href="null" target="_blank">
                <IconButton>
                  <PictureAsPdf />
                </IconButton>
              </Link>
            </Tooltip>
          );
        }
      }
    }
    return null;
  }

  renderDeleteMatchesButton() {
    const { lead, checkedMatches } = this.state;
    if (lead) {
      if (lead.matches) {
        if (lead.matches.length > 0) {
          if (checkedMatches.length > 0) {
            return (
              <Tooltip title="Eliminar desarrollos seleccionados">
                <IconButton onClick={this.handleOpenDeleteMatches}>
                  <Delete />
                </IconButton>
              </Tooltip>
            );
          }
        }
      }
    }
    return null;
  }

  renderModal() {
    const { index, modal, unitToShow, comment, open } = this.state;
    return (
      <Dialog open={open} onClose={this.handleCloseModal} keepMounted>
        <DialogTitle>{modal === 'favor' ? 'Puntos a favor ' : 'Puntos en contra '}</DialogTitle>
        <DialogContent>
          <DialogContentText>Ingrese su comentario</DialogContentText>
          <TextField
            variant="outlined"
            autoFocus
            value={comment}
            placeholder="Comentario"
            multiline
            fullWidth
            onChange={(e) => this.setState({ comment: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => this.handleUpdateComment(index, unitToShow)} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderDeleteMatchDialog() {
    return (
      <Dialog
        open={this.state.openDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseDelete}
      >
        <DialogTitle>Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Está seguro que desea eliminar este proyecto?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.handleSuccessDelete} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderDeleteMatchesDialog() {
    return (
      <Dialog
        open={this.state.openDeleteMatchesModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseDeleteMatches}
      >
        <DialogTitle>Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Está seguro que desea eliminar estos desarrollos?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDeleteMatches} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.handleSuccessDeleteMatches} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderGenerateFixDialog() {
    return (
      <Dialog
        open={this.state.openUpdateFixModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseUpdate}
      >
        <DialogTitle>Atención</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se regenerará el fix y se perderán los cambios realizados excepto los desarrollos
            bloqueados. ¿Está seguro que desea regenerar el fix?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseUpdate} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.handleSuccessUpdate} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderBadFixDialog() {
    return (
      <Dialog
        open={this.state.openBadFixModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseBadFix}
      >
        <DialogTitle>Atención</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Debe especificar Sector, Tipo de Unidad y Presupuesto para poder generar el fix
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseBadFix} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderSelectValue(values) {
    const { classes, allParameters } = this.props;
    const clearValuesIds = values.filter((obj) => obj !== '');
    const valuesNames = clearValuesIds.map(
      (valId) => allParameters.filter((obj) => valId === obj.identifier)[0].name,
    );
    return valuesNames.map((valueName, index) => (
      <div key={index}>
        <Chip label={valueName.toString()} className={classes.chip} />
      </div>
    ));
  }

  renderParametersForFixDialog() {
    const { fixParams } = this.state;
    const { allParameters, lead } = this.props;
    if (allParameters) {
      if (allParameters.length > 0) {
        return (
          <Dialog
            open={this.state.openParametersFixModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleCloseParametersFixModal}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="lg"
          >
            <DialogTitle>Confirmación</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Escoja qué parámetros obligatorios además de Sector, Tipo y Precio desea considerar
                en el fix
              </DialogContentText>
              <FormControl fullWidth>
                <Select
                  value={fixParams}
                  onChange={this.onChangeFixParams}
                  fullWidth
                  multiple
                  renderValue={this.renderSelectValue}
                >
                  {allParameters
                    .filter((obj) => {
                      if (obj.category === 'personal') {
                        return false;
                      }
                      if (obj.identifier === ZONE) {
                        return false;
                      }
                      if (obj.identifier === UNIT_TYPE) {
                        return false;
                      }
                      if (obj.identifier === BUDGET) {
                        return false;
                      }
                      const preference = lead.preferences.filter(
                        (ob) => ob.attributeId === obj.identifier && ob.answerId.length > 0,
                      )[0];
                      if (preference) {
                        return true;
                      }
                      return false;
                    })
                    .sort((a, b) =>
                      a.category > b.category ? 1 : b.category > a.category ? -1 : 0,
                    )
                    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                    .map((value, index) => {
                      const type = value.category === 'unit' ? 'Unidad' : 'Proyecto';
                      return (
                        <MenuItem key={index} value={value.identifier}>
                          {`${value.name} - ${type}`}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>Parámetros</FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseParametersFixModal} color="primary">
                Cancelar
              </Button>
              <Button onClick={this.handleSuccessParametersFixModal} color="primary">
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        );
      }
    }
    return null;
  }

  renderSortSelect() {
    const { sortBy, lead } = this.state;
    if (lead) {
      const { matches } = lead;
      if (matches) {
        if (matches.length > 0) {
          return (
            <FormControl fullWidth>
              <Select value={sortBy} onChange={this.onChangeSort} fullWidth>
                <MenuItem value="" disabled>
                  Ninguno
                </MenuItem>
                {sortOptions.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.value}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Ordenar fix por</FormHelperText>
            </FormControl>
          );
        }
      }
    }
    return null;
  }

  render() {
    const { classes, lead } = this.props;
    const {
      modalMapOpen,
      projectMap,
      openAppointmentModal,
      openFinishAppointmentModal,
    } = this.state;
    return (
      <Paper elevation={2} className={classes.paperRoot}>
        {openFinishAppointmentModal && (
          <EventList
            open={openFinishAppointmentModal ? true : false}
            closeModal={() => {
              this.confirmChangeProjectStage(openFinishAppointmentModal);
              this.setState({ openFinishAppointmentModal: false });
            }}
            lead={lead.id}
          />
        )}
        {this.renderLoadingLead()}
        {this.renderDeleteMatchDialog()}
        {this.renderDeleteMatchesDialog()}
        {this.renderGenerateFixDialog()}
        {this.renderBadFixDialog()}
        {this.renderParametersForFixDialog()}
        <DialogMap
          closeText="Cerrar"
          lat={projectMap.lat}
          long={projectMap.long}
          closeDialog={this.handleCloseMap}
          isOpen={modalMapOpen}
        />
        <Toolbar>
          <Typography variant="h6" style={{ width: 400 }}>
            Mejores opciones
          </Typography>
        </Toolbar>
        <Toolbar>
          {this.renderSortSelect()}
          <Link to="/unidades" href="null">
            <Button variant="text" style={{ width: 150 }}>
              Ver todas
            </Button>
          </Link>
          <Tooltip title="Actualizar mejores opciones">
            <IconButton onClick={this.handleOpenUpdate}>
              <Refresh />
            </IconButton>
          </Tooltip>
          {this.renderDeleteMatchesButton()}
          {this.renderPDFButton()}
        </Toolbar>
        {this.renderMatches()}
        {this.renderModal()}
        {!!openAppointmentModal && (
          <AppointmentModal
            open={!!openAppointmentModal}
            close={() => {
              this.confirmChangeProjectStage(openAppointmentModal);
              this.setState({ openAppointmentModal: null });
            }}
            lead={lead._id}
            isEventFlag
          />
        )}
      </Paper>
    );
  }
}
BestOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingUnits: PropTypes.bool.isRequired,
  lead: PropTypes.object.isRequired,
  zones: PropTypes.array.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  leadStages: PropTypes.array.isRequired,
  eventsStatistics: PropTypes.shape({
    statistics: PropTypes.object.isRequired,
    sending: PropTypes.bool.isRequired,
  }).isRequired,
  allParameters: PropTypes.array.isRequired,
  onChangeProjectMatchStage: PropTypes.func.isRequired,
  onRemoveMatch: PropTypes.func.isRequired,
  onRemoveMatches: PropTypes.func.isRequired,
  onBlockProject: PropTypes.func.isRequired,
  onUnBlockProject: PropTypes.func.isRequired,
  onUpdateLeadMatches: PropTypes.func.isRequired,
  onSwapMatchUnits: PropTypes.func.isRequired,
  onSwapMatches: PropTypes.func.isRequired,
  getUnitImages: PropTypes.func.isRequired,
  getEventsStatistics: PropTypes.func.isRequired,
  onAddUnitMatchComment: PropTypes.func.isRequired,
  onReplaceMatches: PropTypes.func.isRequired,
  handleChangePointsInFavor: PropTypes.func.isRequired,
  handleChangePointsAgainst: PropTypes.func.isRequired,
  handleDuplicateProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { leads, units, parameters, eventsStatistics } = state;
  return {
    lead: leads.lead,
    eventsStatistics,
    allParameters: parameters.allParameters,
    isLoadingUnits: units.sending,
    isLoading: leads.sendingLead,
  };
};

const mapDispatchToProps = {
  getEventsStatistics,
  getUnitImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BestOptions));
