import React, {useState, useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector, useDispatch} from 'react-redux';
import {filterCountries} from '../../../actions/countries';
import EnhancedTable from '../../common/enhancedTable';
import {countriesCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import CountryDetail from './detail';

const Countries = (props) => {
  const title = 'Países';
  const {page, rowsPerPage, order, orderBy, countries, searchText, sending, dataLength, error, message} = useSelector(state => state.countries);
  const [country, setCountry] = useState(null);
  const [isNew, setIsNew] = useState(false);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(filterCountries(page, rowsPerPage, order, orderBy, searchText));
  }, []);

  const onSearch = (searchedText) => {
    dispatch(filterCountries(page, rowsPerPage, order, orderBy, searchedText));
  };

  const onAdd = () => {
    setCountry({});
    setIsNew(true);
  };

  const onDetail = (id) => {
    const countryLocal = countries.filter(obj => obj.id === id)[0];
    setCountry(countryLocal);
    setIsNew(false);
  };

  const handleChangeRowsPerPage = (rowsPerPageLocal) => {
    dispatch(filterCountries(page, rowsPerPageLocal, order, orderBy, searchText));
  };

  const handleChangePage = (pageLocal) => {
    dispatch(filterCountries(pageLocal, rowsPerPage, order, orderBy, searchText));
  };

  const onOrder = (orderLocal, orderByLocal) => {
    dispatch(filterCountries(page, rowsPerPage, orderLocal, orderByLocal, searchText));
  };

  const getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_profiles) {
        return true;
      }
    }
    return false;
  };

  const getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_profiles) {
        return true;
      }
    }
    return false;
  };

  const handleGoBack = () => {
    dispatch(filterCountries(page, rowsPerPage, order, orderBy, searchText));
    setCountry(null);
    setIsNew(false);
  };

  const renderSnackBar = () => {
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  };

  const isLoading = sending;
  const hasEditPermission = getEditPermissions();
  const hasCreatePermission = getCreatePermission();
  const data = {
    rows: countries,
    cols: countriesCols
  };
  const handlers = {
    onDetail,
    onAdd,
    onSearch,
    onOrder,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage
  };
  const config = {
    isDragable: false,
    isSelectable: false,
    hasEditPermission,
    hasCreatePermission,
    isFiterable: false,
    searchText: searchText || '',
    order: {
      by: orderBy,
      direction: order
    },
    pagination: {
      current: page,
      rowsPerPage,
      totalItems: dataLength
    },
    filters: {
      is: false
    }
  };
  return (
    isLoading === true ?
      <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} />
      :
      <div>
        {renderSnackBar()}
        {  
          !country ?
            <EnhancedTable
              title={title}
              data={data}
              handlers={handlers}
              config={config}
            />
            :
            <CountryDetail
              country={country}
              handleGoBack={handleGoBack}
              isLoading={isLoading}
              isNew={isNew}
            />
        }
      </div>
  );
};
  
export default Countries;
