/* eslint-disable no-param-reassign */
import axios from 'axios';
import { axiosMaker, errorMessage } from '../utils/utils';
import * as zoneActions from './types/zones';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterZones = (
  page,
  rowsPerPage,
  order,
  orderBy,
  searchText,
) => async (dispatch) => {
  if (searchText !== '' && store.getState().zones.searchText !== searchText) {
    page = 0;
  }
  if (
    order !== store.getState().zones.order ||
    orderBy !== store.getState().zones.orderBy
  ) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: zoneActions.FILTER_ZONES_SENDING,
    payload: {
      zones: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
    },
  });
  try {
    let filter = {};
    if (
      page !== null &&
      rowsPerPage !== null &&
      order !== null &&
      orderBy !== null &&
      searchText !== null
    ) {
      filter = {
        filters: {
          name: searchText,
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order,
        },
      };
    }
    const response = await axios(
      axiosMaker('POST', 'zones/filter', filter, true),
    );
    return dispatch({
      type: zoneActions.FILTER_ZONES_SUCCESS,
      payload: {
        zones: response.data.results,
        dataLength: response.data.total,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: zoneActions.FILTER_ZONES_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getZones = () => async (dispatch) => {
  dispatch({
    type: zoneActions.GET_ZONES_SENDING,
    payload: {
      zones: [],
      dataLength: 0,
    },
  });
  try {
    const data = {
      sort: {
        name: 'asc',
      },
      filters: {},
      onlySons: true,
    };
    const response = await axios(
      axiosMaker('POST', 'zones/filter', data, true),
    );
    return dispatch({
      type: zoneActions.GET_ZONES_SUCCESS,
      payload: {
        zones: response.data.results,
        dataLength: response.data.total,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: zoneActions.GET_ZONES_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getAllZones = () => async (dispatch) => {
  dispatch({
    type: zoneActions.GET_ALL_ZONES_SENDING,
    payload: {
      allZones: [],
    },
  });
  try {
    const response = await axios(axiosMaker('GET', 'zones', null, true));
    return dispatch({
      type: zoneActions.GET_ALL_ZONES_SUCCESS,
      payload: {
        allZones: response.data.results,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: zoneActions.GET_ALL_ZONES_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const createZone = (data) => async (dispatch) => {
  dispatch({
    type: zoneActions.CREATE_ZONE_SENDING,
    payload: {},
  });
  try {
    const request = axiosMaker('POST', 'zones', data, true);
    await axios(request);
    return dispatch({
      type: zoneActions.CREATE_ZONE_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: zoneActions.CREATE_ZONE_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const updateZone = (id, zones) => async (dispatch) => {
  dispatch({
    type: zoneActions.UPDATE_ZONE_SENDING,
    payload: {},
  });
  try {
    await axios(axiosMaker('PATCH', `zones/${id}`, zones, true));
    return dispatch({
      type: zoneActions.UPDATE_ZONE_SUCCESS,
      payload: {},
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: zoneActions.UPDATE_ZONE_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getFormattedZones = (level, filterBy, company) => async (
  dispatch,
) => {
  dispatch({
    type: zoneActions.GET_FORMATTED_ZONES_SENDING,
    payload: {
      formattedZones: [],
      dataLength: 0,
    },
  });
  try {
    const response = await axios(
      axiosMaker(
        'POST',
        'zones/formattedZones',
        { level, filterBy, company },
        true,
      ),
    );
    return dispatch({
      type: zoneActions.GET_FORMATTED_ZONES_SUCCESS,
      payload: {
        formattedZones: response.data,
      },
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: zoneActions.GET_FORMATTED_ZONES_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};
