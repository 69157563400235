/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as emailTemplateActions from './types/emailTemplates';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterEmailTemplates = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().emailTemplates.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().emailTemplates.order || orderBy !== store.getState().emailTemplates.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: emailTemplateActions.FILTER_EMAIL_TEMPLATES_SENDING,
    payload: {
      emailTemplates: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    const data = {
      operator: 'or',
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order
      },
      company: company && company._id
    };
    if (searchText !== '') {
      data.filters = {
        name: searchText,
        template: searchText
      };
    }
    const response = await axios(axiosMaker('POST', 'emailTemplates/filter', data, true));
    return dispatch({
      type: emailTemplateActions.FILTER_EMAIL_TEMPLATES_SUCCESS,
      payload: {
        emailTemplates: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: emailTemplateActions.FILTER_EMAIL_TEMPLATES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getEmailTemplates = () => async (dispatch) => {
  dispatch({
    type: emailTemplateActions.GET_EMAIL_TEMPLATES_SENDING,
    payload: {
      emailTemplates: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      operator: 'and',
      sort: {
        name: 'asc'
      },
      filters: company && company._id ? {company: [company._id], active:true} : {}
    };
    const response = await axios(axiosMaker('POST', 'emailTemplates/filter', data, true));
    return dispatch({
      type: emailTemplateActions.GET_EMAIL_TEMPLATES_SUCCESS,
      payload: {
        emailTemplates: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: emailTemplateActions.GET_EMAIL_TEMPLATES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createEmailTemplate = data => async (dispatch) => {
  dispatch({
    type: emailTemplateActions.CREATE_EMAIL_TEMPLATE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'emailTemplates', data, true));
    return dispatch({
      type: emailTemplateActions.CREATE_EMAIL_TEMPLATE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: emailTemplateActions.CREATE_EMAIL_TEMPLATE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateEmailTemplate = (id, emailTemplates) => async (dispatch) => {
  dispatch({
    type: emailTemplateActions.UPDATE_EMAIL_TEMPLATE_SENDING,
    payload: {}
  });
  try {
    const request = axiosMaker('PATCH', `emailTemplates/${id}`, emailTemplates, true);
    const response = await axios(request);
    console.log('response: ', response);
    return dispatch({
      type: emailTemplateActions.UPDATE_EMAIL_TEMPLATE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: emailTemplateActions.UPDATE_EMAIL_TEMPLATE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
