/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage, copyObject, maxLimit, logOutFunction} from '../utils/utils';
import * as usersActions from './types/users';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterUsers = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().users.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().users.order || orderBy !== store.getState().users.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: usersActions.FILTER_USERS_SENDING,
    payload: {
      users: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    const filter = {
      filters: {
        email: searchText,
        phone: searchText,
        name: searchText
      },
      operator: 'or',
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order
      },
      forAdmin: true,
      company: company && company._id
    };
    const response = await axios(axiosMaker('POST', 'users/filter', filter, true));
    
    return dispatch({
      type: usersActions.FILTER_USERS_SUCCESS,
      payload: {
        users: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    const { data } = e.response;
    if (data) {
      const { message } = data;
      if (message === 'jwt expired') {
        logOutFunction();
      }
    }
    return dispatch({
      type: usersActions.FILTER_USERS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getAsesors = () => async (dispatch) => {
  dispatch({
    type: usersActions.FILTER_USERS_SENDING,
    payload: {}
  });
  try {
    const filter = {
      filters: {is_asesor: [true], active: [true]},
      operator: 'or',
      page: 0,
      limit: 9999999999999,
      sort: {
        // eslint-disable-next-line no-useless-computed-key
        ['name']: 'asc'
      },
      forAdmin: true,
      company: company && company._id
    };
    const response = await axios(axiosMaker('POST', 'users/filter', filter, true));
    
    return dispatch({
      type: usersActions.FILTER_USERS_SUCCESS,
      payload: {
        users: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: usersActions.FILTER_USERS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getOnlyAsessors = () => async (dispatch) => {
  dispatch({
    type: usersActions.FILTER_USERS_ASESSORS_SENDING,
    payload: {}
  });
  try {
    const filter = {
      filters: {is_asesor: [true], active: [true]},
      operator: 'or',
      page: 0,
      limit: 9999999999999,
      sort: {
        // eslint-disable-next-line no-useless-computed-key
        ['name']: 'asc'
      },
      forAdmin: true,
      company: company && company._id
    };
    const response = await axios(axiosMaker('POST', 'users/filter', filter, true));
    
    return dispatch({
      type: usersActions.FILTER_USERS_ASESSORS_SUCCESS,
      payload: {
        asessors: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: usersActions.FILTER_USERS_ASESSORS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getUsers = () => async (dispatch) => {
  dispatch({
    type: usersActions.GET_USERS_SENDING,
    payload: {
      users: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {},
      limit: maxLimit,
      company: company && company._id
    };
    
    const response = await axios(axiosMaker('POST', 'users/filter', data, true));
    
    return dispatch({
      type: usersActions.GET_USERS_SUCCESS,
      payload: {
        users: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: usersActions.GET_USERS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getUser = userId => async (dispatch) => {
  dispatch({
    type: usersActions.GET_USER_SENDING,
    payload: {
      users: [],
      dataLength: 0
    }
  });
  try {
    const response = await axios(axiosMaker('GET', `users/${userId}`, null, true));
    return dispatch({
      type: usersActions.GET_USER_SUCCESS,
      payload: {
        user: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: usersActions.GET_USER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createUser = user => async (dispatch) => {
  dispatch({
    type: usersActions.CREATE_USER_SENDING,
    payload: {}
  });
  try {
    const data = copyObject(user);
    data.profile_img = user.profileImg;
    data.is_asesor = user.isBroker;
    data.is_builder = user.isBuilder;
    delete data.profileImg;
    delete data.isBroker;
    delete data.isBuilder;

    const request = axiosMaker('POST', 'users', data, true);
    const response = await axios(request);
    
    if (response.data.exists) {
      return dispatch({
        type: usersActions.USER_ALREADY_EXISTS,
        payload: {
          userExists: true,
          userName: response.data.name
        }
      });
    }
    return dispatch({
      type: usersActions.CREATE_USER_SUCCESS,
      payload: {
        user: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: usersActions.CREATE_USER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const editUser = (id, user, companyId) => async (dispatch) => {
  dispatch({
    type: usersActions.UPDATE_USER_SENDING,
    payload: {}
  });
  try {
    if (user._id) {
      delete user._id;
    } 
    if (user.contact_history) {
      delete user.contact_history;
    }
    
    const request = axiosMaker('PATCH', `users/${id}`, {...user, company: companyId}, true);
    
    const response = await axios(request);
    
    return dispatch({
      type: usersActions.UPDATE_USER_SUCCESS,
      payload: {
        response: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: usersActions.UPDATE_USER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const resetPassword = data => async (dispatch) => {  
  dispatch({
    type: usersActions.RESETPASSWORD_SENDING,
    payload: {
      data
    }
  });
  try {
    const response = await axios(axiosMaker('POST', 'resetPassword', data, true));
    return dispatch({
      type: usersActions.RESETPASSWORD_SUCCESS,
      payload: {
        response: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: usersActions.RESETPASSWORD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const userHasTrivoSmart = async () => {
  try {
    const response = await axios(axiosMaker('GET', 'users/hasTrivoSmart', null, true));
    return response.data;
  } catch (e) {
    const { data } = e.response || {};
    if (data) {
      const { message } = data;
      if (message === 'jwt expired') {
        logOutFunction();
      }
    }
    return false;
  }
};

export const validateDocument = async (data) => {
  try {
    const response = await axios(axiosMaker('POST', 'users/validateDocument', data, true));
    return response.data;
  } catch (e) {
    console.error('error', e);
    return false;
  }
};
