export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';

export const GET_COUNTRIES_SENDING = 'GET_COUNTRIES_SENDING';

export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

export const FILTER_COUNTRIES_SUCCESS = 'FILTER_COUNTRIES_SUCCESS';

export const FILTER_COUNTRIES_SENDING = 'FILTER_COUNTRIES_SENDING';

export const FILTER_COUNTRIES_FAILED = 'FILTER_COUNTRIES_FAILED';

export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';

export const CREATE_COUNTRY_SENDING = 'CREATE_COUNTRY_SENDING';

export const CREATE_COUNTRY_FAILED = 'CREATE_COUNTRY_FAILED';

export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';

export const UPDATE_COUNTRY_SENDING = 'UPDATE_COUNTRY_SENDING';

export const UPDATE_COUNTRY_FAILED = 'UPDATE_COUNTRY_FAILED';
