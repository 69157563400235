import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {styles} from './styles';
import ConfirmationDialog from '../../common/confirmationDialog';
import {getEmailTemplates} from '../../../actions/emailTemplates';
import {getLeadStates} from '../../../actions/leadStates';


export class RulesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      name: '',
      status: '',
      stage: '',
      data: {
        statusId: '',
        stageId: '',
        templateId: '',
        content: ''
      },
      days: '0'
    };
    this.renderButton = this.renderButton.bind(this);
    this.renderLeadStates = this.renderLeadStates.bind(this);
    this.renderLeadStages = this.renderLeadStages.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderContentTitle = this.renderContentTitle.bind(this);
    this.renderContentOption = this.renderContentOption.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.renderEmailTemplates = this.renderEmailTemplates.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.onChangeStatusId = this.onChangeStatusId.bind(this);
    this.onChangeStageId = this.onChangeStageId.bind(this);
    this.onChangeTemplateId = this.onChangeTemplateId.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  componentWillMount() {
    const {isNew, rule} = this.props;
    this.props.getEmailTemplates();
    this.props.getLeadStates();
    if (isNew === false) {
      this.setState({
        rule,
        name: rule.name ? rule.name : '',
        status: rule.status ? rule.status : '',
        stage: rule.stage ? rule.stage : '',
        days: rule.days ? rule.days : '',
        action: rule.action ? rule.action : '',
        data: rule.data ? rule.data : {
          statusId: '',
          stageId: '',
          templateId: '',
          content: ''
        },
        active: rule.active
      });
    }
  }

  onAddButtonClick() {
    const {name, data, status, stage, days, action} = this.state;
    this.props.handleCreateRule({name, data, status, stage, days, action});
  }

  onEditButtonClick() {
    const {rule, active, name, data, status, stage, days, action} = this.state;
    const ruleEdited = {
      id: rule.id,
      active,
      name,
      data,
      status,
      stage,
      days,
      action
    };
    this.props.handleUpdateRule(ruleEdited);
  }

  onChangeContent(e) {
    const {data} = this.state;
    data.content = e.target.value;
    this.setState({data});
  }

  onChangeStatusId(e) {
    const {data} = this.state;
    data.statusId = e.target.value;
    this.setState({data});
  }

  onChangeStageId(e) {
    const {data} = this.state;
    data.stageId = e.target.value;
    this.setState({data});
  }

  onChangeTemplateId(e) {
    const {data} = this.state;
    data.templateId = e.target.value;
    this.setState({data});
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  isInvalid() {
    const {name, status, action, data} = this.state;
    if (
      name !== '' && 
      status !== '' && 
      action !== '' && 
      data !== {
        statusId: '',
        stageId: '',
        templateId: '',
        content: ''
      }
    ) {
      return false;
    }
    return true;
  }

  renderButton() {
    const {isLoading, isNew, classes} = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} esta regla?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} regla`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderLeadStates() {
    const {leadStates} = this.props;
    if (leadStates.length > 0) {
      return leadStates.map((leadState, index) => {
        return (
          <MenuItem key={index} value={leadState.id}>{leadState.name}</MenuItem>
        );
      });
    }
    return null;
  }

  renderLeadStages(lastStatus = false) {
    const {leadStates} = this.props;
    const {data, status} = this.state;
    let state = data.statusId;
    if (lastStatus) {
      state = status;
    }
    if (state !== '') {
      const leadState = leadStates.filter(obj => obj.id === state)[0];
      if (leadState) {
        return leadState.stages.map((leadStage, index) => {
          return (
            <MenuItem key={index} value={leadStage.id}>{leadStage.name}</MenuItem>
          );
        });
      }
    }
    return null;
  }

  renderActions() {
    const {actions} = this.props;
    if (actions.length > 0) {
      return actions.map((action, index) => {
        return (
          <MenuItem key={index} value={action.id}>{action.name}</MenuItem>
        );
      });
    }
    return null;
  }

  renderDays = () => {
    const daysArr = [];
    const daysMax = 30;
    for (let i = 0; i <= daysMax; i += 1) {
      daysArr.push(`${i}`);
    }
    return daysArr.map((day, index) => {
      return (
        <MenuItem key={index} value={day}>{day}</MenuItem>
      );
    });
  }

  renderEmailTemplates() {
    const {emailTemplates} = this.props;
    if (emailTemplates.length > 0) {
      return emailTemplates.map((emailTemplate, index) => {
        return <MenuItem value={emailTemplate.id} key={index}>{emailTemplate.name}</MenuItem>;
      });
    }
    return null;
  }

  renderContentOption() {
    const {action} = this.state;
    switch (action) {
    case 'send_mail':
      return (
        <FormControl>
          <Select
            value={this.state.data.templateId}
            onChange={this.onChangeTemplateId}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Ninguno
            </MenuItem>
            {this.renderEmailTemplates()}
          </Select>
          <FormHelperText>Escoja el template de correo</FormHelperText>
        </FormControl>
      );
    case 'add_action':
      return (
        <TextField 
          value={this.state.data.content}
          onChange={this.onChangeContent}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
        />
      );
    case 'change_status':
      return (
        <div>
          <FormControl>
            <Select
              value={this.state.data.statusId}
              onChange={this.onChangeStatusId}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                Ninguno
              </MenuItem>
              {this.renderLeadStates()}
            </Select>
            <FormHelperText>Escoja el nuevo estado del lead</FormHelperText>
          </FormControl>
          <br />
          <FormControl>
            <Select
              value={this.state.data.stageId}
              onChange={this.onChangeStageId}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                Ninguno
              </MenuItem>
              {this.renderLeadStages(false)}
            </Select>
            <FormHelperText>Escoja la nueva etapa del lead</FormHelperText>
          </FormControl>
        </div>
      );
    default:
      return null;
    }
  }
  
  renderContentTitle() {
    const {action} = this.state;
    switch (action) {
    case 'send_mail':
      return 'Template de correo: ';
    case 'add_action':
      return 'Contenido de la acción: ';
    case 'change_status':
      return 'Nuevo Estado: ';
    case 'change_stage':
      return 'Nueva Etapa: ';
    default:
      return null;
    }
  }

  renderContent() {
    const {classes} = this.props;
    return (
      <TableRow className={classes.tableRowTitle}>
        <TableCell><b>{this.renderContentTitle()}</b></TableCell>
        <TableCell>
          {this.renderContentOption()}
        </TableCell>
      </TableRow>      
    );
  }
    
  render() {
    const {classes, isLoading, isNew} = this.props;
    return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de ' : 'Nueva '} regla
          </Typography>
        </Toolbar>
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              {
                !isNew &&
                <TableRow>
                  <TableCell className={classes.tableRowTitle}><b>Disponible</b></TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.active}
                          onChange={e => this.setState({active: e.target.checked})}
                          color="primary"
                        />
                      }
                      label={this.state.active === true ? 'Disponible' : 'No disponible'}
                    />
                  </TableCell>
                </TableRow>
              }
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Nombre</b></TableCell>
                <TableCell>
                  <TextField 
                    autoFocus
                    value={this.state.name}
                    onChange={e => this.setState({name: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow> 
              <TableRow className={classes.tableRowTitle}>
                <TableCell><b>Cuando el lead está en estado: </b></TableCell>
                <TableCell>
                  <FormControl>
                    <Select
                      value={this.state.status}
                      onChange={e => this.setState({status: e.target.value})}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Ninguno
                      </MenuItem>
                      {this.renderLeadStates()}
                    </Select>
                    <FormHelperText>Estado del lead</FormHelperText>
                  </FormControl>
                </TableCell>
              </TableRow>                     
              <TableRow className={classes.tableRowTitle}>
                <TableCell><b>y el lead está en etapa: </b></TableCell>
                <TableCell>
                  <FormControl>
                    <Select
                      value={this.state.stage}
                      fullWidth
                      onChange={e => this.setState({stage: e.target.value})}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Ninguno
                      </MenuItem>
                      {this.renderLeadStages(true)}
                    </Select>
                    <FormHelperText>Etapa del lead</FormHelperText>
                  </FormControl>
                </TableCell>
              </TableRow>                     
              <TableRow className={classes.tableRowTitle}>
                <TableCell><b>y el último contacto: </b></TableCell>
                <TableCell>
                  <FormControl>
                    <Select
                      value={this.state.days}
                      fullWidth
                      onChange={e => this.setState({days: e.target.value})}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Ninguno
                      </MenuItem>
                      {this.renderDays()}
                    </Select>
                    <FormHelperText>Días que han pasado desde el último contacto</FormHelperText>
                  </FormControl>
                </TableCell>
              </TableRow>                     
              <TableRow className={classes.tableRowTitle}>
                <TableCell><b>Acción: </b></TableCell>
                <TableCell>
                  <FormControl>
                    <Select
                      value={this.state.action}
                      onChange={e => this.setState({action: e.target.value})}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Ninguno
                      </MenuItem>
                      {this.renderActions()}
                    </Select>
                    <FormHelperText>Escoja la acción</FormHelperText>
                  </FormControl>
                </TableCell>
              </TableRow>                     
              {this.renderContent()}
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>
          {this.renderButton()}
        </div>
      </Paper>
    );
  }
}

RulesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  rule: PropTypes.object.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  leadStates: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleCreateRule: PropTypes.func.isRequired,
  handleUpdateRule: PropTypes.func.isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  getLeadStates: PropTypes.func.isRequired  
};

const mapStateToProps = (state) => {
  const {emailTemplates, leadStates} = state;
  return { 
    isLoadingEmailTemplates: emailTemplates.sending, 
    emailTemplates: emailTemplates.emailTemplates,
    isLoadingLeadStates: leadStates.sending, 
    leadStates: leadStates.leadStates,
    leadStages: leadStates.leadStages
  };
};
  
const mapDispatchToProps = {
  getEmailTemplates,
  getLeadStates
};
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RulesDetails));

