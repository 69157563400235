import axios from 'axios';
import * as uploaderActions from './types/uploader';
import {axiosFormData, errorMessage} from '../utils/utils';

export const upload = (dirName, form) => async (dispatch) => {
  dispatch({
    type: uploaderActions.UPLOAD_SENDING,
    payload: {
      dirName,
      form
    }
  });
  try {
    const request = axiosFormData('POST', `uploadFile/${dirName}`, form, true);
    const response = await axios(request);
    return dispatch({
      type: uploaderActions.UPLOAD_SUCCESS,
      payload: {
        response: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: uploaderActions.UPLOAD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export default upload;
