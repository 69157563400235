export const styles = theme => ({
  root: {
    display: 'flex'
  },
  br: {
    width: '100%',
    height: 25
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    height: '100%',
    backgroundColor: '#F5F5F6',
    width: '100%'
  },
  paperRoot: {
    width: '95%',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 3
  },
  submitContainer: {
    height: '100%',
    display: 'table',
    margin: '0 auto',
    verticalAlign: 'middle'
  },
  tableRowTitle: {
    width: 100
  },
  textInput: {
    marginBottom: theme.spacing.unit * 2
  },
  fontAwesomeIcon: {
    textAlign: 'center',
    margin: '0 auto',
    display: 'table'
  },
  spacer: {
    flex: '1 1 100%'
  },
  smallBar: {
    minHeight: 30,
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0
  },
  preferenceCell: {
    width: 200,
    padding: 10,
    verticalAlign: 'top',
    display: 'inline-block',
    marginBottom: 20
  },
  preferenceTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  preferenceSubtitle: {
    textAlign: 'center'
  },
  preferenceContainer: {
    maxWidth: '100%',
    textAlign: 'center'
  }
});

export default styles;
