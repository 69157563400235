export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';

export const GET_NEWS_SENDING = 'GET_NEWS_SENDING';

export const GET_NEWS_FAILED = 'GET_NEWS_FAILED';

export const FILTER_NEWS_SUCCESS = 'FILTER_NEWS_SUCCESS';

export const FILTER_NEWS_SENDING = 'FILTER_NEWS_SENDING';

export const FILTER_NEWS_FAILED = 'FILTER_NEWS_FAILED';

export const CREATE_NEWS_ITEM_SUCCESS = 'CREATE_NEWS_ITEM_SUCCESS';

export const CREATE_NEWS_ITEM_SENDING = 'CREATE_NEWS_ITEM_SENDING';

export const CREATE_NEWS_ITEM_FAILED = 'CREATE_NEWS_ITEM_FAILED';

export const UPDATE_NEWS_ITEM_SUCCESS = 'UPDATE_NEWS_ITEM_SUCCESS';

export const UPDATE_NEWS_ITEM_SENDING = 'UPDATE_NEWS_ITEM_SENDING';

export const UPDATE_NEWS_ITEM_FAILED = 'UPDATE_NEWS_ITEM_FAILED';

export const GET_NEWS_TO_SHOW = 'GET_NEWS_TO_SHOW';

export const FAIL_NEWS_TO_SHOW = 'FAIL_NEWS_TO_SHOW';
