export const styles = (theme) => ({
  tabsHeader: {
    paddingTop: '0px',
  },
  tabContainer: {
    margin: '0 0px',
    justifyContent: 'center',
    borderBottom: '1.5px solid #eeeeee',
    display: 'grid',
    paddingTop: '0px',
    // [theme.breakpoints.up('sm')]: {
    //   margin: '0 30px',
    // },
  },
  pageContainer: {
    padding: '0px',
    // [theme.breakpoints.down('xs')]: {
    //   padding: '80px 0px',
    // },
  },
  creditsContainer: {
    display: 'flex',
    position: 'absolute',
    top: '130px',
    right: '18px',
    backgroundColor: 'white',
    padding: '13px 35px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
  },
  creditsDescription: {
    display: 'inline-flex',
    color: '#3590F3',
    fontSize: '18px',
    '& span': {
      marginLeft: '5px',
    },
  },
});

export default styles;
