import * as commissionsActions from '../actions/types/commissions';

const initialState = {
  commissions: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 50,
  filters: {status: ['pending']},
  order: 'asc',
  orderBy: 'date_created',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {commissions, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText, filters} = action.payload;
  switch (action.type) {
  case commissionsActions.FILTER_COMMISSIONS_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, dataLength, searchText, filters};
  case commissionsActions.FILTER_COMMISSIONS_SUCCESS:
    return {...state, sending: false, commissions, error: null, message: 'Comisiones obtenidas satisfactoriamente', dataLength};
  case commissionsActions.FILTER_COMMISSIONS_FAILED:
    return {...state, sending: false, commissions: [], error, message, dataLength};
  case commissionsActions.UPDATE_COMMISSION_SENDING:
    return {...state, sending: true};
  case commissionsActions.UPDATE_COMMISSION_SUCCESS:
    return {...state, sending: false, error: null, message: 'Comisión actualizada satisfactoriamente'};
  case commissionsActions.UPDATE_COMMISSION_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
