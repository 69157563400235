export const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 4,
    padding: theme.spacing.unit * 3,
  },
  headerProformas: {
    paddingBottom: theme.spacing.unit * 4,
    fontWeight: 'bold',
  },
  inputForm: {
    '& input': {
      padding: '6px 14px',
    },
  },
  labelForm: {
    transform: 'translate(14px, 6px) scale(1)',
  },
  textField: {
    width: '100%',
    marginTop: '10px',
    '& input': {
      padding: '6px 14px',
    },
  },
  gridItem: {
    paddingBottom: '0px !important',
    paddingTop: '0px !important',
  },
  listText: {
    textAlign: 'center',
    '& *': {
      fontWeight: 'bold',
      color: 'grey',
    },
    '& span': {
      fontSize: 14,
    },
    '& p': {
      fontSize: 22,
      marginBottom: 0,
    },
    '& h6': {
      fontSize: 11,
      fontWeight: '400',
    },
  },
  listHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'grey',
    position: 'relative',
  },
});

export default styles;
