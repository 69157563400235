export const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  br: {
    width: '100%',
    height: 25,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    height: '100%',
    backgroundColor: '#F5F5F6',
    width: '100%',
  },
  paperRoot: {
    width: '95%',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
  },
  submitContainer: {
    display: 'table',
    margin: '0 auto',
  },
  tableRowTitle: {
    width: 180,
  },
  textInput: {
    marginBottom: theme.spacing.unit * 2,
  },
  highlightColor: {
    color: theme.palette.primary.main,
  },
  chip: {
    margin: 2,
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing.unit * 0.5,
    margin: 0,
  },
  noOutline: {
    '&:focus': {
      outline: 'none',
    },
  },
  inputError: {
    paddingTop: 4,
    fontSize: '0.75rem',
    color: '#f44336',
  },
  brokerSelect: {
    '& > div': {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      '& > div': {
        margin: 0,
        display: 'flex',
        padding: 8,
        flexWrap: 'wrap',
        listStyle: 'none',
        justifyContent: 'start',
      },
    },
  },
});

export default styles;
