/* eslint-disable prefer-destructuring */
import axios from 'axios';
import * as securityActions from './types/security';
import {axiosMaker, errorMessage} from '../utils/utils';

export const login = (username, password, companyId) => async (dispatch) => {
  dispatch({
    type: securityActions.LOGIN_SENDING,
    payload: {
      username,
      password
    }
  });
  try {
    const data = {
      email: username,
      password,
      companyId
    };
    const response = await axios(axiosMaker('POST', 'login', data, false));
    const user = response.data.user;
    const token = response.data.token;
    const profile = response.data.profile;
    return dispatch({
      type: securityActions.LOGIN_SUCCESS,
      payload: {
        user,
        token,
        profile
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: securityActions.LOGIN_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getUser = id => async (dispatch) => {
  dispatch({
    type: securityActions.GET_USER_SENDING,
    payload: {}
  });
  try {
    if (id) {
      const response = await axios(axiosMaker('GET', `users/${id}`, null, true));
      return dispatch({
        type: securityActions.GET_USER_SUCCESS,
        payload: {
          user: response.data.results
        }
      });
    } 
    return dispatch({
      type: securityActions.GET_USER_FAILED,
      payload: {
        error: true,
        message: 'No existe el id de usuario'
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: securityActions.GET_USER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export default login;
