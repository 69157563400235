import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Select,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  IconButton,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import ConfirmationDialog from '../../common/confirmationDialog';
import AttachmentsList from '../../common/attachmentsList';
import { getEmailTemplates } from '../../../actions/emailTemplates';
import { editProject } from '../../../actions/projects';
import * as projectsActions from '../../../actions/types/projects';
import { parseNumber } from '../../../utils/utils';
import { InlineDatePicker } from 'material-ui-pickers';
import Uploader from './../../common/uploader/index';

const LeadsAssignment = (props) => {
  const { classes, project, onGoBack, company } = props;

  const { emailTemplates } = useSelector((state) => state.emailTemplates);
  const { sending } = useSelector((state) => state.projects);

  const [details, setDetails] = useState({
    builderFinancingPercentage: 0,
    totalQuotes: 1,
    defaultEmailTemplate: '',
    reservePercentage: '',
    entryPercentage: '',
    interestPercentage: '',
    defaultAttachments: false,
    attachments: [],
    logo: '',
    showUnitData: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const { proformaConfiguration } = project;
    if (proformaConfiguration) setDetails(proformaConfiguration);
    dispatch(getEmailTemplates());
  }, []);

  const onEditButtonClick = async () => {
    const { type } = await dispatch(
      editProject(project.id, { proformaConfiguration: details }),
    );
    if (type === projectsActions.EDIT_PROJECT_SUCCESS) onGoBack();
  };

  const onAddAttachment = (attachment) =>
    setDetails({
      ...details,
      attachments: [attachment, ...details.attachments],
    });

  const onDeleteAttachment = (url) =>
    setDetails({
      ...details,
      attachments: details.attachments.filter((item) => item.url !== url),
    });

  const onEditAttachment = (attachment, target) => {
    const attachments = details.attachments.map((item) =>
      item.url === target ? attachment : item,
    );
    setDetails({ ...details, attachments });
  };

  const onReorderAttachment = (attachments) => {
    setDetails({ ...details, attachments });
  };

  const percentageValidation = (value, isStrict = true) =>
    isStrict ? value <= 0 || value > 100 : value < 0 || value > 100;

  const isNumber = (n) => /^[0-9.]+$/.test(String(n));

  const isDisabled =
    percentageValidation(details.reservePercentage, false) ||
    percentageValidation(details.entryPercentage) ||
    percentageValidation(details.interestPercentage, false) ||
    !isNumber(details.reservePercentage + details.entryPercentage) ||
    parseNumber(details.reservePercentage) >
      parseNumber(details.entryPercentage);

  const renderButton = (
    <ConfirmationDialog
      confirmationText="¿Está seguro que desea modificar la configuración de proformas?"
      buttonText="Actualizar"
      variant="contained"
      isDisabled={isDisabled}
      confirmAction={onEditButtonClick}
    />
  );

  const renderBuilderFinancingPercentage = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Porcentaje de financiamiento de la constructora</b>
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          value={details.builderFinancingPercentage}
          fullWidth
          placeholder="0"
          onChange={(e) =>
            setDetails({
              ...details,
              builderFinancingPercentage: e.target.value,
            })
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderTotalQuotes = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Número de cuotas</b>
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          value={details.totalQuotes}
          fullWidth
          placeholder="1"
          onChange={(e) =>
            setDetails({
              ...details,
              totalQuotes: e.target.value,
            })
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderReservePercentage = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>{'Reserva (%)'}</b>
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          autoFocus
          value={details.reservePercentage || ''}
          onChange={(e) =>
            setDetails({
              ...details,
              reservePercentage: e.target.value,
            })
          }
          fullWidth
          error={
            percentageValidation(details.reservePercentage, false) ||
            parseNumber(details.reservePercentage) >
              parseNumber(details.entryPercentage)
          }
          helperText={
            parseNumber(details.reservePercentage) >
            parseNumber(details.entryPercentage)
              ? 'El porcentaje de reserva debe menor que el de entrada'
              : percentageValidation(details.reservePercentage, false)
              ? 'Porcentaje no válido'
              : ''
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderEntryPercentage = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>{'Entrada (%)'}</b>
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          autoFocus
          value={details.entryPercentage || ''}
          onChange={(e) =>
            setDetails({
              ...details,
              entryPercentage: e.target.value,
            })
          }
          fullWidth
          error={percentageValidation(details.entryPercentage)}
          helperText={
            percentageValidation(details.entryPercentage) &&
            'Porcentaje no válido'
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderInterestPercentage = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>{'Tasa (%)'}</b>
      </TableCell>
      <TableCell>
        <TextField
          disabled
          type="number"
          autoFocus
          value={details.interestPercentage || ''}
          placeholder="0 %"
          onChange={(e) =>
            setDetails({
              ...details,
              interestPercentage: e.target.value,
            })
          }
          fullWidth
          error={percentageValidation(details.interestPercentage, false)}
          helperText={
            percentageValidation(details.interestPercentage, false) &&
            'Porcentaje no válido'
          }
        />
      </TableCell>
    </TableRow>
  );

  const renderDeadline = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>{'Fecha de entrega'}</b>
      </TableCell>
      <TableCell>
        <FormControl style={{ width: '100%', paddingTop: 8 }}>
          <InlineDatePicker
            format="YYYY-MM-DD"
            value={details.deadline}
            onChange={(selected) =>
              setDetails({
                ...details,
                deadline: selected,
              })
            }
            className={classes.pickerContainer}
            InputProps={{ className: classes.inputForm }}
          />
        </FormControl>
      </TableCell>
    </TableRow>
  );

  const renderEmailTemplate = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Plantilla por defecto</b>
      </TableCell>
      <TableCell>
        <Select
          value={details.defaultEmailTemplate || ''}
          onChange={(e) =>
            setDetails({
              ...details,
              defaultEmailTemplate: e.target.value,
            })
          }
          fullWidth
        >
          {emailTemplates
            .filter((template) => template.company === company.id)
            .map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
        </Select>
      </TableCell>
    </TableRow>
  );

  const renderDefaultAttachments = (
    <TableRow>
      <TableCell className={`${classes.tableRowTitle} topTitle`}>
        <b>Adjuntos por defecto</b>
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={details.defaultAttachments}
              onChange={(e) =>
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    defaultAttachments: e.target.checked,
                  };
                })
              }
              color="primary"
            />
          }
          label={
            details.defaultAttachments === true ? 'Habilitado' : 'No habilitado'
          }
        />
        {details.defaultAttachments && (
          <AttachmentsList
            attachments={details.attachments}
            onAddAttachment={onAddAttachment}
            onDeleteAttachment={onDeleteAttachment}
            onEditAttachment={onEditAttachment}
            onReorderAttachment={onReorderAttachment}
            legacyAttachments={project.attachments.map((attch) => ({
              ...attch,
              type: 'legacy',
            }))}
          />
        )}
      </TableCell>
    </TableRow>
  );

  const renderLoadImage = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Imagen del logo</b>
      </TableCell>
      <TableCell id="imageLogo">
        {details.logo && (
          <a href={details.logo} rel="noopener noreferrer" target="_blank">
            <img
              src={details.logo}
              alt="Foto de perfil"
              style={{ width: 100 }}
            />
          </a>
        )}
        <Uploader
          buttonText={details.logo ? 'Actualizar logo' : 'Subir logo'}
          photo
          dirName="Proforma"
          urlUploaded={(logo) =>
            setDetails({
              ...details,
              logo: logo ? logo : '',
            })
          }
          uploaderId="project-proforma-logo"
        />
      </TableCell>
    </TableRow>
  );

  const renderImageUrlRedirect = (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>Url de redirección de la imagen principal</b>
      </TableCell>
      <TableCell>
        <TextField
          value={details.imageUrlRedirect}
          fullWidth
          placeholder="www.ejemplo.com"
          onChange={(e) =>
            setDetails({
              ...details,
              imageUrlRedirect: e.target.value,
            })
          }
        />
      </TableCell>
    </TableRow>
  );

  return sending ? (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    />
  ) : (
    <div>
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={onGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">{`Configuracion de proformas de ${project.name}`}</Typography>
        </Toolbar>
        <div style={{ width: 'auto', overflowX: 'scroll' }}>
          <Table>
            <TableBody>
              {renderBuilderFinancingPercentage}
              {renderTotalQuotes}
              {renderReservePercentage}
              {renderEntryPercentage}
              {renderInterestPercentage}
              {renderDeadline}
              {renderEmailTemplate}
              {renderDefaultAttachments}
              {renderLoadImage}
              {renderImageUrlRedirect}
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>{renderButton}</div>
      </Paper>
    </div>
  );
};

LeadsAssignment.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  onGoBack: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadsAssignment);
