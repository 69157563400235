import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {createGroup, updateGroup} from '../../../../actions/groups';
import SearchSelect from '../../../common/enhancedTable/enhancedTableToolbar/searchSelect';


const GroupDetails = (props) => {
  const {group, projects, isNew, asessors} = props;
  const [selectedProjectsIds, setSelectedProjectsIds] = useState(isNew ? [] : group.projects.map(project=> { return {value:project._id, label:project.name}}, []) );
  const [selectedAsessorIds, setSelectedAsessorIds] = useState(isNew ? [] : group.users.map(user=> { return {value:user._id, label:user.name}}, []) );

  console.log("selectedProjectsIds", selectedProjectsIds)

  const [groupDetail, setGroup] = useState({
    active: isNew ? group.active : true,
    name: isNew ? group.name : '',
    projects:isNew ? group.projects : [],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNew) {
      setGroup(group);
    }
  }, []);

  const onAddButtonClick = async () => {
    const {name, projects, status} = groupDetail;
    await dispatch(createGroup({name, projects:selectedProjectsIds,users: selectedAsessorIds}));
    props.handleGoBack();
  };

  const onEditButtonClick = async () => {
    const {group} = props;
    const {name, status, projects} = groupDetail;
    console.log("on ADD selectedProjectsIds", selectedProjectsIds)
    const projectIds = selectedProjectsIds.map(projectItem => projectItem.value ? projectItem.value:projectItem );
    const userIds = selectedAsessorIds.map(usertItem => usertItem.value ? usertItem.value:usertItem );
    console.log("projectIds", projectIds);
    console.log("userIds", userIds);
    await dispatch(updateGroup(group.id, {name, status,projects:  projectIds, users: userIds}));
    props.handleGoBack();
  };

  const handleChangeProject = ({ target: { value } }) => {
    console.log(selectedProjectsIds)
    setSelectedProjectsIds(value);
  };

  const handleChangeAsessors =
  ({ target: { value } }) => {
    setSelectedAsessorIds(value);
  };

  const handleChangeGroup = (e) => {
    const {value, name} = e.target;
    setGroup((prevState) => {
      return {...prevState, [name]: value};
    });
  };


  const isInvalid = () => {
    const {name, province} = groupDetail;
    if (name !== '' && province !== '') {
      return false;
    }
    return true;
  };

  const renderButton = () => {
    const {isLoading, isNew, classes} = props;
    if (!isLoading) {
      return (
        <ConfirmationDialog 
          confirmationText={`¿Está seguro que desea ${isNew ? 'crear' : 'modificar'} este grupo?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} grupo`}
          variant="contained"
          isDisabled={isInvalid()}
          confirmAction={isNew ? onAddButtonClick : onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  };


  const {classes, isLoading, handleGoBack} = props;
  return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
    <Paper elevation={2} className={classes.paperRoot}>
      <Toolbar>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">
          {!isNew ? 'Detalle de ' : 'Nuevo '} Grupo
        </Typography>
      </Toolbar>
      <div style={{width: 'auto',}}>
        <Table>
          <TableBody>
            {
              !isNew &&
              <TableRow>
                <TableCell className={classes.tableRowTitle}><b>Disponible</b></TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={groupDetail.active}
                        onChange={e => setGroup((prevState) => {
                          return {...prevState, active: e.target.checked};
                        })}
                        color="primary"
                      />
                    }
                    label={groupDetail.active === true ? 'Disponible' : 'No disponible'}
                  />
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell className={classes.tableRowTitle}><b>Nombre</b></TableCell>
              <TableCell>
                <TextField 
                  autoFocus
                  name="name"
                  value={groupDetail.name}
                  onChange={handleChangeGroup}
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}><b>Proyectos</b></TableCell>
              <TableCell>
              <SearchSelect simple={true}  onChange={handleChangeProject} options = {{types:projects}} label = {""} selectedOptions={selectedProjectsIds}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableRowTitle}><b>Asesores</b></TableCell>
              <TableCell>
              <SearchSelect simple={true}  onChange={handleChangeAsessors} options = {{types:asessors}} label = {""} selectedOptions={selectedAsessorIds}/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={classes.submitContainer}>
        {renderButton()}
      </div>
    </Paper>
  );
};

GroupDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  asessors:PropTypes.array
};

export default (withStyles(styles)(GroupDetails));
