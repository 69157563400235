import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export class RadioList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerId: ''
    };
    this.renderAnswers = this.renderAnswers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {currentAnswer} = this.props;
    if (currentAnswer) {
      const {answerId} = currentAnswer;
      if (answerId.length > 0) {
        this.setState({answerId: answerId[0]});
      }
    }
  }

  handleChange(event) {
    const {identifier} = this.props;
    const answerId = event.target.value;
    this.setState({answerId});
    const arr = [];
    arr.push(answerId);
    this.props.updateAnswer(identifier, arr, '');
  }

  renderAnswers() {
    const {permissions} = this.props;
    return this.props.answers.map((options, index) => {
      return (
        <FormControlLabel
          key={index}
          control={<Radio />}
          label={options.value}
          value={options.answerId}
          disabled={permissions === false}
        />
      );
    });
  }

  render() {
    return (
      <RadioGroup
        id="radioInput"
        value={this.state.answerId}
        onChange={this.handleChange}
      >
        {this.renderAnswers()}
      </RadioGroup>
    );
  }
}

RadioList.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    answerId: PropTypes.string
  })).isRequired,
  updateAnswer: PropTypes.func.isRequired,
  currentAnswer: PropTypes.object,
  permissions: PropTypes.bool,
  identifier: PropTypes.string.isRequired
};

export default RadioList;
