import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  Tooltip,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';
import { styles } from './styles';
import Uploader from '../../common/uploader';

const AttachmentsList = (props) => {
  const {
    classes,
    attachments,
    onAddAttachment,
    onDeleteAttachment,
    onEditAttachment,
    onReorderAttachment,
    legacyAttachments,
  } = props;

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [editUrl, setEditUrl] = useState(null);

  const handleAddAttachment = () => {
    onAddAttachment({ name, url, type: 'added' });
    setUrl('');
    setName('');
  };

  const handleEditAttachment = () => {
    const previousItem = attachments.find((item) => item.url === editUrl);
    const attachment = {
      name: name === '' ? previousItem.name : name,
      url: url === '' ? previousItem.url : url,
      type: 'added',
    };
    onEditAttachment(attachment, editUrl);
    setEditUrl(null);
    setUrl('');
    setName('');
  };

  const handleReorder = ({ removedIndex, addedIndex }) => {
    const newArray = arrayMove([...attachments], removedIndex, addedIndex);
    onReorderAttachment(newArray);
  };

  let isDisabled = () => {
    if (name === '') return true;
    if (editUrl) {
      const attatchment = attachments.find((item) => item.url === editUrl);
      return !(
        name !== attatchment.name ||
        (url !== '' && url !== attatchment.url)
      );
    }
    return url === '';
  };

  const renderEditAttachment = (
    <Fragment>
      <Grid item xs={7} className={classes.inputPadding}>
        <TextField
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          placeholder="Nombre del Adjunto"
        />
      </Grid>
      <Grid item>
        <Uploader
          buttonText="Subir Archivo"
          iconStyle={true}
          photo={false}
          dirName="Proforma"
          urlUploaded={(url) => setUrl(url || '')}
          iconColor={!!(url && url !== '')}
          uploaderId="atta-file"
        />
      </Grid>
      <Grid item>
        <Tooltip title={`${editUrl ? 'Editar' : 'Añadir'} Adjunto`}>
          <div>
            <IconButton
              color="primary"
              className={classes.iconButton}
              disabled={isDisabled()}
              onClick={editUrl ? handleEditAttachment : handleAddAttachment}
            >
              {editUrl ? <SaveIcon /> : <AddCircleIcon />}
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </Fragment>
  );

  const renderAddedAttachment = (attch) => (
    <Fragment>
      <Tooltip title="Ver Adjunto">
        <IconButton
          className={classes.iconButton}
          onClick={() => window.open(attch.url)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Editar Adjunto">
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            setEditUrl(attch.url);
            setName(attch.name);
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Eliminar Adjunto">
        <IconButton
          className={classes.iconButton}
          onClick={() => onDeleteAttachment(attch.url)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );

  const renderEditableAttachment = (
    <Fragment>
      <Tooltip title="Deshacer">
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            setEditUrl(null);
            setName('');
            setUrl('');
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );

  const renderLegacyAttachment = (attch, isLegacyList = false) => {
    return (
      <Fragment>
        <Tooltip
          title="Ver Adjunto"
          className={isLegacyList ? classes.legacy : ''}
        >
          <IconButton
            className={classes.iconButton}
            onClick={() => window.open(attch.url)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={`${isLegacyList ? 'Agregar' : 'Remover'} Adjunto`}
          className={isLegacyList ? classes.legacy : ''}
        >
          <IconButton
            className={classes.iconButton}
            onClick={() =>
              isLegacyList
                ? onAddAttachment(attch)
                : onDeleteAttachment(attch.url)
            }
          >
            {isLegacyList ? <PublishIcon /> : <GetAppIcon />}
          </IconButton>
        </Tooltip>
      </Fragment>
    );
  };

  const attachmentType = (attch, isLegacyList) => {
    if (attch.type === 'added')
      return editUrl !== attch.url
        ? renderAddedAttachment(attch)
        : renderEditableAttachment;
    else if (attch.type === 'legacy')
      return renderLegacyAttachment(attch, isLegacyList);

    return null;
  };

  const renderAttachmentsList = (data, isLegacyList = false) => (
    <Grid item xs={8}>
      <List>
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={handleReorder}
        >
          {data &&
            data.map((attch) => (
              <Draggable key={attch.url}>
                <ListItem className={isLegacyList ? classes.legacy : ''}>
                  {!editUrl && !isLegacyList && (
                    <Tooltip title="Reordenar Adjunto" className="drag-handle">
                      <IconButton className={classes.iconButton}>
                        <DragHandleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <ListItemText primary={attch.name} />
                  <ListItemSecondaryAction>
                    {attachmentType(attch, isLegacyList)}
                  </ListItemSecondaryAction>
                </ListItem>
              </Draggable>
            ))}
        </Container>
      </List>
    </Grid>
  );

  const legacyAttachmentsData =
    legacyAttachments &&
    legacyAttachments.filter(
      (item) => !attachments.map((attch) => attch.url).includes(item.url),
    );

  return (
    <Grid
      container
      direction="row"
      alignItems="baseline"
      className={classes.rowGridAttachment}
    >
      {renderEditAttachment}
      {renderAttachmentsList(attachments)}
      {legacyAttachmentsData && legacyAttachmentsData.length > 0 && (
        <Divider className={classes.divider} />
      )}
      {renderAttachmentsList(legacyAttachmentsData, true)}
    </Grid>
  );
};

AttachmentsList.propTypes = {
  classes: PropTypes.object.isRequired,
  attachments: PropTypes.array,
  onAddAttachment: PropTypes.func.isRequired,
  onDeleteAttachment: PropTypes.func.isRequired,
  onEditAttachment: PropTypes.func.isRequired,
  onReorderAttachment: PropTypes.func.isRequired,
  legacyAttachments: PropTypes.array,
};

export default withStyles(styles)(AttachmentsList);
