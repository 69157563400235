export const formatDynamicPie = (
  title,
  categories = [],
  names = [],
  types = [],
  arrayData = [],
) => {
  const seriesDrillDown = [];
  const seriesData = [];

  arrayData.map((item, index) => {
    seriesData[index] = {
      name: names[index],
      y: item.reduce((a, b) => a + b, 0),
      drilldown: names[index],
    };

    seriesDrillDown[index] = {
      name: names[index],
      id: names[index],
      data: item.map((itemDrillDown, indexD) => [categories[indexD], itemDrillDown]),
    };
  });

  const chart = {
    chart: {
      type: 'pie',
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true
    },
    title: {
      text: title,
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '',
      },
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          // format: '{point.name}: {point.y:.1f}',
        },
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b> en total<br/>',
    },

    series: [
      {
        name: 'Proyectos',
        colorByPoint: true,
        data: seriesData,
      },
    ],
    drilldown: {
      series: seriesDrillDown,
    },
  };
  return chart;
};

export const formatDynamicPieReverse = (
  title,
  subtitle,
  categories = [],
  names = [],
  types = [],
  arrayData = [],
) => {
  const seriesDrillDown = [];
  const seriesData = [];

  categories.map((item, index) => {
    seriesData[index] = {
      name: item,
      y: arrayData.reduce((a, b) => a + b[index], 0),
      drilldown: item,
    };

    const dataDrillItem = names.map((name, indexName) => {
      return [name, arrayData[indexName][index]];
    }, []);

    seriesDrillDown[index] = {
      name: item,
      id: item,
      data: dataDrillItem,
    };
  });

  const chart = {
    chart: {
      type: 'pie',
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true
    },
    title: {
      text: title,
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '',
      },
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          // format: '{point.name}: {point.y:.1f}',
        },
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b> en total<br/>',
    },

    series: [
      {
        name: 'Proyectos',
        colorByPoint: true,
        data: seriesData,
      },
    ],
    drilldown: {
      series: seriesDrillDown,
    },
  };
  return chart;
};

export const columnLineFormater = (title, subtitle, categories, name1, name2, data1, data2) => {
  let dataSum = 0;
  // TODO MAP SI ES QUE SE NECESITA
  // const mapedData = data.map((item, index) => {
  //   dataSum += item.count;
  //   return { name: item.name, value: item.count || null, colorValue: index };
  // }, []);
  const chart = {
    chart: {
      zoomType: 'xy',
    },
    title: {
      text: title,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true
    },
    subtitle: {
      // text: 'Source: WorldClimate.com',
    },
    xAxis: [
      {
        categories,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        labels: {
          format: '{value}',
          style: {
            color: '#278a2e',
          },
        },
        title: {
          text: name1,
          style: {
            color: '#278a2e',
          },
        },
        opposite: true,
      },
      {
        title: {
          text: name2,
          style: {
            color: '#00000',
          },
        },
        labels: {
          format: '{value}',
          style: {
            color: '#00000',
          },
        },
      },
    ],
    tooltip: {
      shared: true,
    },
    // legend: {    // TODO DESCOMENTAR SI NECESITAN LAS LEYENDAS EN EL GRAFICO
    //   layout: 'vertical',
    //   align: 'left',
    //   x: 100,
    //   verticalAlign: 'top',
    //   y: 100,
    //   floating: true,
    //   backgroundColor: 'rgba(255,255,255,0.25)',
    // },
    series: [
      {
        colors: [
          '#006064',
          '#23dce4',
          '#4ebb3a',
          '#2D6D22',
          '#58C445',
          '#204E18',
          '#3F51B5',
          '#497f81',
          '#66d7d1',
          '#3fbdc5',
          '#17a2b8',
          '#159aae',
          '#1292a3',
          '#0c818e',
        ],
        colorByPoint: true,
        name: name1,
        type: 'column',
        yAxis: 1,
        data: data1,
      },
      {
        marker: {
          lineWidth: 2,
          lineColor: '#006064',
          fillColor: 'white',
        },
        name: name2,
        type: 'spline',
        data: data2,
      },
    ],
  };
  return chart;
};

export const columnAndLineReportFormater = (
  title,
  subtitle,
  categories = [],
  name1 = '',
  name2 = '',
  data1 = [],
  data2 = [],
) => {
  let dataSum = 0;
  // const mapedData = data.map((item, index) => {
  //   dataSum += item.count;
  //   return { name: item.name, value: item.count || null, colorValue: index };
  // }, []);
  const chart = {
    title: {
      text: title,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true
    },
    xAxis: {
      categories,
    },
    labels: {
      items: [],
    },
    series: [
      {
        colors: [
          '#006064',
          '#23dce4',
          '#4ebb3a',
          '#2D6D22',
          '#58C445',
          '#204E18',
          '#3F51B5',
          '#497f81',
          '#66d7d1',
          '#3fbdc5',
          '#17a2b8',
          '#159aae',
          '#1292a3',
          '#0c818e',
        ],
        colorByPoint: true,
        type: 'column',
        name: name1,
        data: data1,
      },
      {
        type: 'spline',
        name: name2,
        data: data2,
        marker: {
          lineWidth: 2,
          lineColor: '#006064',
          fillColor: 'white',
        },
      },
    ],
  };
  return chart;
};

export const dynamicColumnAndLineReportFormater = (
  title,
  subtitle,
  categories = [],
  names = [],
  types = [],
  arrayData = [],
) => {
  const chart = {
    title: {
      text: title,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true
    },
    xAxis: {
      categories,
    },
    labels: {
      items: [],
    },
    series: dynamicSeriesFormater(names, types, arrayData),
  };
  return chart;
};

export const dynamicSeriesFormater = (names = [], types = [], arrayData = [], yAxis = false) => {
  const arraySeriesData = [];
  arrayData.map((item, index) => {
    if (types[index] == 'spline') {
      arraySeriesData.push({
        type: 'spline',
        name: names[index],
        data: item,
        marker: {
          lineWidth: 2,
          lineColor: '#006064',
          fillColor: 'white',
        },
      });
    } else {
      if (yAxis) {
        arraySeriesData.push({
          type: 'column',
          yAxis: 1,
          name: names[index],
          data: item,
        });
      } else {
        arraySeriesData.push({
          type: 'column',
          name: names[index],
          data: item,
        });
      }
    }
  });

  return arraySeriesData;
};

export const dynamicColumnAndLineYAxissReportFormater = (
  title,
  subtitle,
  categories = [],
  names = [],
  yTitles = [],
  types = [],
  arrayData = [],
  yAxys = false,
) => {
  let newYaxis = [];
  if (yAxys) {
    newYaxis = [
      {
        labels: {
          format: '{value}',
          style: {
            color: '#278a2e',
          },
        },
        title: {
          text: yTitles[1],
          style: {
            color: '#278a2e',
          },
        },
        opposite: true,
      },
      {
        title: {
          text: yTitles[0],
          style: {
            color: '#00000',
          },
        },
        labels: {
          format: '{value}',
          style: {
            color: '#00000',
          },
        },
      },
    ];
  }

  const chart = {
    chart: {
      zoomType: 'xy',
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true
    },
    title: {
      text: title,
    },
    subtitle: {
      // text: 'Source: WorldClimate.com',
    },
    xAxis: [
      {
        categories,
        crosshair: true,
      },
    ],
    yAxis: newYaxis,
    tooltip: {
      shared: true,
    },
    // legend: {    // TODO DESCOMENTAR SI NECESITAN LAS LEYENDAS EN EL GRAFICO
    //   layout: 'vertical',
    //   align: 'left',
    //   x: 100,
    //   verticalAlign: 'top',
    //   y: 100,
    //   floating: true,
    //   backgroundColor: 'rgba(255,255,255,0.25)',
    // },
    series: dynamicSeriesFormater(names, types, arrayData, yAxys),
  };
  return chart;
};

export const stackColumnAndLineYAxissReportFormater = (
  title,
  subtitle,
  categories = [],
  names = [],
  yTitles = [],
  types = [],
  arrayData = [],
  yAxys = false,
) => {
  let newYaxis = [];
  if (yAxys) {
    newYaxis = [
      {
        labels: {
          format: '{value}',
          style: {
            color: '#278a2e',
          },
        },
        title: {
          text: yTitles[1],
          style: {
            color: '#278a2e',
          },
        },
        opposite: true,
      },
      {
        title: {
          text: yTitles[0],
          style: {
            color: '#00000',
          },
        },
        labels: {
          format: '{value}',
          style: {
            color: '#00000',
          },
        },
      },
    ];
  }

  const chart = {
    chart: {
      zoomType: 'xy',
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true
    },
    title: {
      text: title,
    },
    subtitle: {
      // text: 'Source: WorldClimate.com',
    },
    xAxis: [
      {
        categories,
        crosshair: true,
      },
    ],
    yAxis: newYaxis,
    tooltip: {
      shared: true,
    },
    plotOptions: {
      column: {
            stacking: 'normal'
        }
    },
    series: dynamicSeriesFormater(names, types, arrayData, yAxys),
  };
  return chart;
};


