export const styles = () => ({
  sortableRow: {
    cursor: 'move'
  },
  traffic_light_15: {
    background: 'honeydew',
  },
  traffic_light_30: {
    background: '#ffffe3',
  },
  traffic_light_45: {
    background: '#ffd6d6',
  }
});

export default styles;
