export const GET_ORIGINS_SUCCESS = 'GET_ORIGINS_SUCCESS';

export const GET_ORIGINS_SENDING = 'GET_ORIGINS_SENDING';

export const GET_ORIGINS_FAILED = 'GET_ORIGINS_FAILED';

export const FILTER_ORIGINS_SUCCESS = 'FILTER_ORIGINS_SUCCESS';

export const FILTER_ORIGINS_SENDING = 'FILTER_ORIGINS_SENDING';

export const FILTER_ORIGINS_FAILED = 'FILTER_ORIGINS_FAILED';

export const CREATE_ORIGIN_SUCCESS = 'CREATE_ORIGIN_SUCCESS';

export const CREATE_ORIGIN_SENDING = 'CREATE_ORIGIN_SENDING';

export const CREATE_ORIGIN_FAILED = 'CREATE_ORIGIN_FAILED';

export const UPDATE_ORIGIN_SUCCESS = 'UPDATE_ORIGIN_SUCCESS';

export const UPDATE_ORIGIN_SENDING = 'UPDATE_ORIGIN_SENDING';

export const UPDATE_ORIGIN_FAILED = 'UPDATE_ORIGIN_FAILED';
