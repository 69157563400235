export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';

export const GET_CITIES_SENDING = 'GET_CITIES_SENDING';

export const GET_CITIES_FAILED = 'GET_CITIES_FAILED';

export const FILTER_CITIES_SUCCESS = 'FILTER_CITIES_SUCCESS';

export const FILTER_CITIES_SENDING = 'FILTER_CITIES_SENDING';

export const FILTER_CITIES_FAILED = 'FILTER_CITIES_FAILED';

export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS';

export const CREATE_CITY_SENDING = 'CREATE_CITY_SENDING';

export const CREATE_CITY_FAILED = 'CREATE_CITY_FAILED';

export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS';

export const UPDATE_CITY_SENDING = 'UPDATE_CITY_SENDING';

export const UPDATE_CITY_FAILED = 'UPDATE_CITY_FAILED';
