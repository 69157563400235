/* eslint-disable no-alert */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloudUpload from '@material-ui/icons/CloudUpload';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {UPLOAD_SUCCESS} from '../../../actions/types/uploader';
import {upload} from '../../../actions/uploader';
import {styles} from './styles';
import CustomSnackbar from '../customSnackbar';
import CONSTANTS from '../../../utils/constants';

export class Uploader extends Component {
  constructor(props) {
    super(props); 
    this.state = {};
    this.handleUpload = this.handleUpload.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
  }

  handleUpload(e) {
    const file = e.target.files[0];
    console.log('file: ', file.size);
    if (file.size < 16000000) {
      const formData = new FormData();
      formData.append('file', file);
      this.props.upload(this.props.dirName, formData).then((value) => {
        if (value.type === UPLOAD_SUCCESS) {
          if (value.payload.response.Location) {
            const location = value.payload.response.Location.replace(CONSTANTS.BUCKET_S3_NAME,CONSTANTS.BUCKET_DOMAIN_NAME) ;
            if (typeof this.props.uploaderId !== 'undefined') {
              this.props.urlUploaded(location, this.props.uploaderId);
            } else {
              this.props.urlUploaded(location);
            }
          } 
        }
      });
    } else {
      // eslint-disable-next-line no-undef
      alert('El tamaño del archivo debe ser menor a 10 mb');
    }
  }

  uploaderIcon() {
    const {photo, classes} = this.props;
    if (photo === true) { return (<PhotoCameraIcon className={classes.icon} />); }
    return (<SaveIcon className={classes.icon} />);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  renderLabel() {
    const {uploaderId, classes, buttonText, iconStyle} = this.props;
    const htmlFor = uploaderId ? `contained-button-file${uploaderId}` : 'contained-button-file';
    return (
      // eslint-disable-next-line jsx-a11y/label-has-for
      <label htmlFor={htmlFor}>
        {
          iconStyle ? 
            <Tooltip title="Subir archivo">
              <IconButton component="span">
                <CloudUpload color={this.props.iconColor ? 'primary' : 'inherit'}/>
              </IconButton>
            </Tooltip>
            :
            <Button component="span" color="primary" className={classes.button}>
              {buttonText}
            </Button>
        }
      </label>
    );
  }

  render() {
    const {classes, uploaderId, isLoading, photo} = this.props;
    return isLoading === true ? <CircularProgress /> : (
      <div>
        {this.renderSnackBar()}
        <input
          accept={photo ? 'image/png' : '*'}
          className={classes.hide}
          multiple
          id={uploaderId ? `contained-button-file${uploaderId}` : 'contained-button-file'}
          type="file"
          onChange={this.handleUpload}
        />
        {this.renderLabel()}
      </div>
    );
  }
}
Uploader.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  photo: PropTypes.bool.isRequired,
  iconStyle: PropTypes.bool,
  dirName: PropTypes.string.isRequired,
  urlUploaded: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  uploaderId: PropTypes.string,
  message: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  iconColor: PropTypes.bool
};

const mapStateToProps = (state) => {
  const {uploader} = state;
  return {isLoading: uploader.sending, error: uploader.error, message: uploader.message};
};
  
const mapDispatchToProps = {
  upload
};
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Uploader));
