import * as drawerActions from '../actions/types/drawer';

const initialState = {
  sending: false,
  message: '',
  error: null,
  response: null,
  leads: null
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, message, leads} = action.payload;
  switch (action.type) {
  case drawerActions.DOWNLOAD_LEADS_SENDING:
    return {...state, sending: true};
  case drawerActions.DOWNLOAD_LEADS_SUCCESS:
    return {...state, sending: false, message: 'Descargando reporte', error: null, leads};
  case drawerActions.DOWNLOAD_LEADS_FAILED:
    return {...state, sending: false, message, error};
  default:
    return state;
  }
};
