export const GET_UNITS_SENDING = 'GET_UNITS_SENDING';

export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';

export const GET_UNITS_FAILED = 'GET_UNITS_FAILED';

export const GET_UNIT_SENDING = 'GET_UNIT_SENDING';

export const GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS';

export const GET_UNIT_FAILED = 'GET_UNIT_FAILED';

export const GET_UNIT_IMAGES_SENDING = 'GET_UNIT_IMAGES_SENDING';

export const GET_UNIT_IMAGES_SUCCESS = 'GET_UNIT_IMAGES_SUCCESS';

export const GET_UNIT_IMAGES_FAILED = 'GET_UNIT_IMAGES_FAILED';

export const ADD_UNIT_SENDING = 'ADD_UNIT_SENDING';

export const ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS';

export const ADD_UNIT_FAILED = 'ADD_UNIT_FAILED';

export const EDIT_UNIT_SENDING = 'EDIT_UNIT_SENDING';

export const EDIT_UNIT_SUCCESS = 'EDIT_UNIT_SUCCESS';

export const EDIT_UNIT_FAILED = 'EDIT_UNIT_FAILED';

export const EDIT_UNIT_ATTACHMENTS_SENDING = 'EDIT_UNIT_ATTACHMENTS_SENDING';

export const EDIT_UNIT_ATTACHMENTS_SUCCESS = 'EDIT_UNIT_ATTACHMENTS_SUCCESS';

export const EDIT_UNIT_ATTACHMENTS_FAILED = 'EDIT_UNIT_ATTACHMENTS_FAILED';

export const ADD_UNITS_SENDING = 'ADD_UNITS_SENDING';

export const ADD_UNITS_SUCCESS = 'ADD_UNITS_SUCCESS';

export const ADD_UNITS_FAILED = 'ADD_UNITS_FAILED';
