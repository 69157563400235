export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';

export const GET_COMPANIES_SENDING = 'GET_COMPANIES_SENDING';

export const GET_COMPANIES_FAILED = 'GET_COMPANIES_FAILED';

export const FILTER_COMPANIES_SUCCESS = 'FILTER_COMPANIES_SUCCESS';

export const FILTER_COMPANIES_SENDING = 'FILTER_COMPANIES_SENDING';

export const FILTER_COMPANIES_FAILED = 'FILTER_COMPANIES_FAILED';

export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';

export const CREATE_COMPANY_SENDING = 'CREATE_COMPANY_SENDING';

export const CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED';

export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';

export const UPDATE_COMPANY_SENDING = 'UPDATE_COMPANY_SENDING';

export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED';

export const VALIDATE_COMPANY_FAILED = 'VALIDATE_COMPANY_FAILED';