/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import {styles} from './styles';

library.add(fas, far, fab);

export class InfiniteGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderIcon = this.renderIcon.bind(this);
  }

  renderIcon(element) {
    const {icon} = this.props;
    if (icon === element) {
      return <CheckBoxIcon />;
    }
        
    return <CheckBoxOutlineBlankIcon />;
  }

  render() {
    const {classes, iconList, hasMoreItems} = this.props;
    const list = iconList.map((element, index) => {
      return (
        <div 
          key={index} 
          role="button"
          tabIndex={index} 
          className={classes.gridListTile} 
          onClick={() => this.props.clickIcon(element)}
        >
          <IconButton className={classes.icon} onClick={() => this.props.clickIcon(element)}>
            { this.renderIcon(element) }
          </IconButton>
          <div className={classes.separator} />
          <FontAwesomeIcon icon={element} className={classes.fontAwesomeIcon} color="#111" size="2x" />
          <Typography variant="caption" className={classes.center}>
            {element[1]}
          </Typography>
        </div>
      );
    });
    return (
      <div className={classes.root} >
        <InfiniteScroll
          dataLength={iconList.length}
          next={this.props.loadItems}
          hasMore={hasMoreItems}
          loader={<div className={classes.loaderContainer}><CircularProgress key="loader" className={classes.loader} /></div>}
          height={400}
        >
          {list}
        </InfiniteScroll>
      </div>
    );       
  }
}

InfiniteGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  iconList: PropTypes.array.isRequired,
  icon: PropTypes.array,
  hasMoreItems: PropTypes.bool.isRequired,
  loadItems: PropTypes.func.isRequired,
  clickIcon: PropTypes.func.isRequired
};

export default withStyles(styles)(InfiniteGrid);
