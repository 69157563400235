export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';

export const GET_TASKS_SENDING = 'GET_TASKS_SENDING';

export const GET_TASKS_FAILED = 'GET_TASKS_FAILED';

export const FINISH_TASKS_SUCCESS = 'FINISH_TASKS_SUCCESS';

export const FINISH_TASKS_SENDING = 'FINISH_TASKS_SENDING';

export const FINISH_TASKS_FAILED = 'FINISH_TASKS_FAILED';

export const CANCEL_TASKS_SUCCESS = 'CANCEL_TASKS_SUCCESS';

export const CANCEL_TASKS_SENDING = 'CANCEL_TASKS_SENDING';

export const CANCEL_TASKS_FAILED = 'CANCEL_TASKS_FAILED';

export const FIRSTLOAD_TASKS = 'FIRSTLOAD_TASKS';

export const GET_HISTORY_TASKS_SUCCESS = 'GET_HISTORY_TASKS_SUCCESS';

export const GET_HISTORY_TASKS_FIRST_LOAD = 'GET_HISTORY_TASKS_FIRST_LOAD';

export const GET_HISTORY_TASKS_SENDING = 'GET_HISTORY_TASKS_SENDING';

export const GET_HISTORY_TASKS_FAILED = 'GET_HISTORY_TASKS_FAILED';
