import * as standActions from '../actions/types/stands';

const initialState = {
  stands: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {stands, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case standActions.FILTER_STANDS_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case standActions.FILTER_STANDS_SUCCESS:
    return {...state, sending: false, stands, error: null, message: 'Stands obtenidos satisfactoriamente', dataLength};
  case standActions.FILTER_STANDS_FAILED:
    return {...state, sending: false, stands: [], error, message, dataLength};
  case standActions.GET_STANDS_SENDING:
    return {...state, sending: true};
  case standActions.GET_STANDS_SUCCESS:
    return {...state, sending: false, stands, error: null, message: 'Stands obtenidos satisfactoriamente', dataLength};
  case standActions.GET_STANDS_FAILED:
    return {...state, sending: false, stands: [], error, message, dataLength};
  case standActions.CREATE_STAND_SENDING:
    return {...state, sending: true};
  case standActions.CREATE_STAND_SUCCESS:
    return {...state, sending: false, error: null, message: 'Stand creado satisfactoriamente'};
  case standActions.CREATE_STAND_FAILED:
    return {...state, sending: false, error, message};
  case standActions.UPDATE_STAND_SENDING:
    return {...state, sending: true};
  case standActions.UPDATE_STAND_SUCCESS:
    return {...state, sending: false, error: null, message: 'Stand actualizado satisfactoriamente'};
  case standActions.UPDATE_STAND_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
