/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddBulkIcon from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';
import SearchIcon from '@material-ui/icons/Search';
import PollIcon from '@material-ui/icons/Poll';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import TextField from '@material-ui/core/TextField';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import { searchLeads } from '../../../../actions/leads';
import Filters from './filters';
import { styles } from './styles';
import {
  dateRegion,
  isPhone,
  isEmail,
  isInternationalPhone,
  axiosMaker,
  SUPER_ADMIN_ROL_ID,
  ADMIN_ID,
  ASESOR_ID,
  ASESOR_JR_ID,
  COMERCIAL_MANAGER
} from '../../../../utils/utils'; 
import BrokerList from './brokerList';
import DuplicateLeads from './duplicateLeadsModal';
import ManualAssign from './manualAssignModal';
import AutomaticAssign from './automaticAssignModal';
import RankingModal from '../../rankingModal';

const currentCompany = JSON.parse(localStorage.getItem('company'));
const profileId = localStorage.getItem('profileId');

export class EnhancedTableToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadsToAdd: [],
      openOriginModal: false,
      openDuplicatesModal: false,
      openManualAssignModal: false,
      openAutomaticAssignModal: false,
      origin: '',
      searching: false,
      searchText: '',
      inProcess: false,
      company: null,
      isAdmin: false,
      notificationLeads: [],
    };
    this.onAdd = this.onAdd.bind(this);
    this.onBulkAdd = this.onBulkAdd.bind(this);
    this.handleCancelSearch = this.handleCancelSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onOriginChange = this.onOriginChange.bind(this);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.renderAddButton = this.renderAddButton.bind(this);
    this.renderCloseButton = this.renderCloseButton.bind(this);
    this.renderBulkAddButton = this.renderBulkAddButton.bind(this);
    this.renderDownloadButton = this.renderDownloadButton.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handlePressEnterSearch = this.handlePressEnterSearch.bind(this);
    this.renderChips = this.renderChips.bind(this);
    this.renderChipTitle = this.renderChipTitle.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderSearchBar = this.renderSearchBar.bind(this);
    this.renderSearchBarButtons = this.renderSearchBarButtons.bind(this);
    this.renderOrigins = this.renderOrigins.bind(this);
    this.renderCompanies = this.renderCompanies.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDuplicatesChange = this.onDuplicatesChange.bind(this);
    this.onLeadAssignChange = this.onLeadAssignChange.bind(this);
    this.renderUploadTrivoSmartOffers = this.renderUploadTrivoSmartOffers.bind(this);
  }

  componentWillMount() {
    const { searchText } = this.props;
    

    if (searchText) {
      if (searchText !== '') {
        this.setState({ searching: true, searchText });
      }
    }
    if (SUPER_ADMIN_ROL_ID !== profileId && currentCompany)
      this.setState({ company: currentCompany._id });

      if (ADMIN_ID === profileId)
      this.setState({ isAdmin:true });



  }

  onFilter(items) {
    const filterChips = items.filter((obj) => obj.value !== 'all' && obj.value !== '');
    const mainFilter = {};
    filterChips.forEach((element) => {
      mainFilter[element.id] = {
        value: element.value,
        type: element.type,
        id: element.id,
      };
    });
    this.props.onFilter(mainFilter, filterChips);
  }

  onSearch() {
    const { searchText } = this.state;
    if (searchText === '') {
      this.props.onSearch(null);
    } else {
      this.props.onSearch(searchText);
    }
  }

  onAdd() {
    this.props.onAdd();
  }

  onBulkAdd(addLeads, ignoredBrokers, notificationLeads) {
    const { company, origin } = this.state;
    const leads = addLeads.map((lead) => {
      const newLead = lead;
      newLead.origin = origin.id;
      newLead.company = company;
      return newLead;
    });
    const notifications = notificationLeads || this.state.notificationLeads;

    if (notifications && notifications.length > 0) {
      axios(axiosMaker('POST', 'leads/bulkNotifications', notifications, true));
    }

    this.props.onBulkAdd(leads, ignoredBrokers);
    this.setState({ leadsToAdd: [] });
  }

  onOriginChange(origin) {
    this.setState({ origin, openOriginModal: false });
    this.setState({ openDuplicatesModal: true });
  }

  onDuplicatesChange(addLeads, notificationLeads) {
    const { company } = this.state;
    const { companies } = this.props;

    const checkCompany = companies && companies.find((x) => x.id === company);

    this.setState({ leadsToAdd: addLeads, openDuplicatesModal: false, notificationLeads });

    const isManual =
      checkCompany &&
      checkCompany.assigmentConfiguration &&
      checkCompany.assigmentConfiguration.type === 'Manual';

    if (addLeads.length === 0) this.onBulkAdd(addLeads, [], notificationLeads);
    else if (isManual) this.setState({ openManualAssignModal: true });
    else this.setState({ openAutomaticAssignModal: true });
  }

  onLeadAssignChange(addLeads, ignoredBrokers = []) {
    this.setState({ openAutomaticAssignModal: false, openManualAssignModal: false });
    this.onBulkAdd(addLeads || this.state.leadsToAdd, ignoredBrokers);
  }

  onCompanyChange(company) {
    this.setState({ company: company.id });
  }

  handleUpload(e) {
    const { origin } = this.state;
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onload = () => {
      const file = reader.result;
      const fileByLines = file.split('\n');
      const data = fileByLines.map((semicolon) => {
        const value = semicolon.split(';');
        return value;
      });
      const csvFile = data.filter(
        (obj) => obj.length === 5 || obj.length === 7 || obj.length === 8,
      );
      if (csvFile.length > 1) {
        csvFile.shift();
        const leadList = csvFile.filter((obj, indx) => {
          if (isEmail(obj[3]) && (isPhone(obj[2]) || isInternationalPhone(obj[2]))) {
            return true;
          }
          // eslint-disable-next-line no-undef
          alert(`Error, el formato del archivo es inválido, (línea ${indx + 2})`);
          return false;
        });
        if (leadList.length === csvFile.length) {
          const leadsToAdd = leadList.map((lead) => {
            const newLead = {
              user: {
                name: lead[0] || '',
                lastname: lead[1] || '',
                phone: lead[2] || '',
                email: lead[3] || '',
              },
              origin,
              observations: [
                {
                  description: lead[4] || '',
                  date_created: moment.tz(moment(), dateRegion),
                },
              ],
              campaignId: lead[5],
              adId: lead[6],
              stand: lead[7],
            };
            return newLead;
          });

          this.setState({ leadsToAdd, openOriginModal: true });
        }
      } else {
        // eslint-disable-next-line no-undef
        alert('Error, el formato del archivo es inválido');
      }
    };
    reader.readAsBinaryString(e.target.files[0]);
  }

  handleDelete(e, filter) {
    const { filterChips, isSingle, avoidRemove } = this.props;
    if (avoidRemove) {
      return null;
    }
    let indexToDelete = 0;
    for (let i = 0; i < filterChips.length; i += 1) {
      const element = filterChips[i];
      if (element.id === filter.id) {
        indexToDelete = i;
        break;
      }
    }
    if (!isSingle) {
      filterChips.splice(indexToDelete, 1);
      this.onFilter(filterChips);
    }
    return null;
  }

  handlePressEnterSearch(ev) {
    if (ev.key === 'Enter') {
      this.onSearch(this.state.searchText);
      ev.preventDefault();
    }
  }

  handleCancelSearch() {
    this.setState({ searching: false, searchText: '' });
    this.props.onSearch(null);
  }

  handleSearchText = (e) => {
    const { inProcess } = this.state;
    this.setState({ searchText: e.target.value, inProcess: true });
    setTimeout(() => {
      if (inProcess) {
        return false;
      }
      this.setState({ inProcess: true });
      this.props.searchLeads(this.state.searchText).then(() => {
        this.setState({ inProcess: false });
      });
      return false;
    }, 1000);
  };

  handleClose() {
    this.setState({ openOriginModal: false, leadsToAdd: [] });
  }

  renderAddButton() {
    const { editingIndex, hasCreatePermission } = this.props;
    if (hasCreatePermission) {
      if (editingIndex !== null) {
        return (
          <Tooltip title="Salir del modo de edición">
            <IconButton onClick={this.props.stopAdd}>
              <CloseIcon style={{ color: 'red' }} fontSize="large" />
            </IconButton>
          </Tooltip>
        );
      }
      return (
        <Tooltip title="Agregar">
          <IconButton onClick={this.onAdd}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  }

  renderCloseButton() {
    const { editingIndex, hasCreatePermission } = this.props;
    if (editingIndex !== null && !hasCreatePermission) {
      return (
        <Tooltip title="Salir del modo de edición">
          <IconButton onClick={this.props.stopAdd}>
            <CloseIcon style={{ color: 'red' }} fontSize="large" />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  }

  renderBulkAddButton() {
    const { hasCreatePermission, isBulkable, classes } = this.props;
    if (isBulkable) {
      if (hasCreatePermission) {
        return (
          <div style={{ marginTop: 8 }}>
            <input
              accept=".csv"
              className={classes.inputHidden}
              id="icon-button-file"
              type="file"
              onChange={this.handleUpload}
            />
            <label htmlFor="icon-button-file">
              <Tooltip title="Agregar por archivo">
                <IconButton onClick={() => {}} component="span">
                  <AddBulkIcon />
                </IconButton>
              </Tooltip>
            </label>
          </div>
        );
      }
    }
    return null;
  }

  renderUploadTrivoSmartOffers() {
    const { onUploadTrivoSmartOffers, classes } = this.props;
    return (
      <div style={{ marginTop: 8 }}>
        <input
          accept=".xlsx"
          className={classes.inputHidden}
          id="icon-button-file-excel"
          type="file"
          onChange={onUploadTrivoSmartOffers}
        />
        <label htmlFor="icon-button-file-excel">
          <Tooltip title="Subir información de Ofertas Trivo Smart">
            <IconButton onClick={() => {}} component="span">
              <AddBulkIcon />
            </IconButton>
          </Tooltip>
        </label>
      </div>
    );
  }

  renderDownloadButton() {
    const { isDownloadable, onDownload } = this.props;
    if (isDownloadable) {
      return (
        <Tooltip title="Descargar leads">
          <IconButton onClick={() => onDownload()} component="span">
            <CloudDownload />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  }

  renderAssignBulkSelect() {
    const { isDownloadable, onDownload } = this.props;
    if (isDownloadable) {
      return (
        <Tooltip title="Descargar leads">
          <IconButton onClick={() => onDownload()} component="span">
            <CloudDownload />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  }

  renderFilters() {
    const { isFilterable, isDragable, filterChips, filterOptions, isSingle } = this.props;
    if (!isDragable) {
      // if (!isDragable && !this.props.withoutFilter) {
      if (isFilterable) {
        return (
          <Filters
            options={filterOptions}
            chips={filterChips}
            onFilter={this.onFilter}
            isSingle={isSingle}
          />
        );
      }
    }
    return null;
  }

  renderChipTitle = (data) => {
    if (data.type === 'numberRange') {
      return `${data.label}: Desde: ${data.value.$gt} - Hasta: ${data.value.$lt}`;
    } else if (data.type === 'dateRange') {
      return `${data.label}: Desde: ${data.value.start} - Hasta: ${data.value.end}`;
    }
    return `${data.label}: ${data.title}`;
  };

  renderChips() {
    const { classes, filterChips, avoidRemove } = this.props;
    if (filterChips) {
      if (filterChips.length > 0) {
        return (
          <div className={classes.chipContainer}>
            {filterChips
              .filter(
                (obj) =>
                  obj.title !== '' &&
                  ((Array.isArray(obj.value) && obj.value.length > 0) || !Array.isArray(obj.value)),
              )
              .map((data, index) => {
                if (avoidRemove) {
                  return (
                    <Chip key={index} label={this.renderChipTitle(data)} className={classes.chip} />
                  );
                }
                return (
                  <Chip
                    key={index}
                    label={this.renderChipTitle(data)}
                    onDelete={(e) => this.handleDelete(e, data)}
                    className={classes.chip}
                  />
                );
              })}
          </div>
        );
      }
    }
    return null;
  }

  renderNames = () => {
    const { leadNames, classes, isSendingSearch } = this.props;
    if (isSendingSearch) {
      return (
        <div style={{ padding: 5 }}>
          <CircularProgress />
        </div>
      );
    }
    if (leadNames.length > 0) {
      const leadNamesList = leadNames.map((user, index) => {
        return (
          <div key={index}>
            <Button onClick={() => this.props.onDetail(user._id)}>
              {`${user.user.name} ${user.user.lastname}`}
            </Button>
          </div>
        );
      });
      return <Paper className={classes.popoverContainer}>{leadNamesList}</Paper>;
    }
    return null;
  };

  renderSearchBar() {
    const { isLeadSearch, classes } = this.props;
    if (this.state.searching === true) {
      if (isLeadSearch) {
        return (
          <div className={classes.search}>
            <TextField
              label="Buscar por nombre"
              fullWidth
              autoFocus
              value={this.state.searchText}
              onChange={this.handleSearchText}
              onKeyPress={this.handlePressEnterSearch}
            />
            {this.renderNames()}
          </div>
        );
      }
      return (
        <div className={classes.search}>
          <TextField
            label="Buscar por nombre"
            fullWidth
            autoFocus
            value={this.state.searchText}
            onChange={(e) => this.setState({ searchText: e.target.value })}
            onKeyPress={this.handlePressEnterSearch}
          />
        </div>
      );
    }
    return null;
  }

  renderSearchBarButtons() {
    const { isDragable, cantSearch } = this.props;
    if (!isDragable && !cantSearch) {
      if (this.state.searching === false) {
        return (
          <Tooltip title="Buscar por nombre">
            <IconButton onClick={() => this.setState({ searching: true })}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        );
      }
      return (
        <div style={{ width: 400 }}>
          <Tooltip title="Buscar por nombre" style={{ display: 'inline-block' }}>
            <IconButton onClick={this.onSearch}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancelar Búsqueda" style={{ display: 'inline-block' }}>
            <IconButton onClick={this.handleCancelSearch}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      );
    }
    return null;
  }

  renderOrigins() {
    const { classes, origins } = this.props;
    const { company } = this.state;

    return (
      origins && (
        <List>
          {origins
            .filter((origin) => origin.company._id === company)
            .map((origin, key) => (
              <ListItem button onClick={() => this.onOriginChange(origin)} key={key}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PollIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={origin.name} />
              </ListItem>
            ))}
        </List>
      )
    );
  }

  renderCompanies() {
    const { classes, companies } = this.props;
    return (
      companies && (
        <List>
          {companies.map((company, key) => (
            <ListItem button onClick={() => this.onCompanyChange(company)} key={key}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <BusinessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={company.name} />
            </ListItem>
          ))}
        </List>
      )
    );
  }

  render() {
    const {
      numSelected,
      classes,
      title,
      cantSearch,
      listBrokers,
      assignBroker,
      changeAssignBroker,
      onBulkAssign,
      selectedItems,
      hasBulkAssignPermission,
      companies,
      onUploadTrivoSmartOffers,
      hasRanking
    } = this.props;
    const {
      openOriginModal,
      leadsToAdd,
      openDuplicatesModal,
      openManualAssignModal,
      openAutomaticAssignModal,
      company,
      isAdmin
    } = this.state;

    const checkCompany = companies && companies.find((x) => x.id === company);

    const hasJuniorRol = () =>
      checkCompany &&
      checkCompany.assigmentConfiguration &&
      checkCompany.assigmentConfiguration.hasJuniorRol;

    return (
      <div>
        <Toolbar
          className={classNames(
            classes.root,
            { [classes.highlight]: numSelected > 0 },
            { [classes.responsiveBulkSelect]: numSelected > 0 },
          )}
        >
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} seleccionados
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle" style={{ width: '100%' }}>
              {title}
            </Typography>
          )}
          <div className={classes.spacer} />
          
          {hasRanking  && (isAdmin || profileId=== ASESOR_ID || profileId=== ASESOR_JR_ID  || profileId === COMERCIAL_MANAGER) && <RankingModal /> }
          {!cantSearch ? this.renderSearchBar() : ''}
          {numSelected > 0 && hasBulkAssignPermission ? (
            <Grow timeout={500} in={numSelected > 0}>
              <div className={classes.containerBrokerActions}>
                <BrokerList
                  handleChange={changeAssignBroker}
                  value={assignBroker}
                  options={listBrokers}
                />
                <Button
                  variant="contained"
                  size="small"
                  type="button"
                  onClick={() => {
                    onBulkAssign(selectedItems);
                  }}
                  color="primary"
                >
                  Asignar
                </Button>
              </div>
            </Grow>
          ) : (
            this.renderSearchBarButtons()
          )}

          {!numSelected && this.renderFilters()}
          {!numSelected && this.renderAddButton()}
          {!numSelected && this.renderCloseButton()}
          {!numSelected && !hasBulkAssignPermission && this.renderBulkAddButton()}
          {!numSelected && this.renderBulkAddButton()}
          {!numSelected && this.renderDownloadButton()}
          {onUploadTrivoSmartOffers && this.renderUploadTrivoSmartOffers()}
        </Toolbar>
        {this.renderChips()}
        {openDuplicatesModal && (
          <DuplicateLeads
            onDuplicatesChange={this.onDuplicatesChange}
            openDuplicatesModal={openDuplicatesModal}
            leadsToAdd={leadsToAdd}
            closeModal={() => this.setState({ openDuplicatesModal: false })}
            companyId={company}
          />
        )}
        {openManualAssignModal && (
          <ManualAssign
            openManualAssignModal={openManualAssignModal}
            leadsToAdd={leadsToAdd}
            hasJuniorRol={!!hasJuniorRol()}
            closeModal={() => this.setState({ openManualAssignModal: false })}
            onLeadAssignChange={this.onLeadAssignChange}
            companyId={company}
          />
        )}
        {openAutomaticAssignModal && (
          <AutomaticAssign
            openAutomaticAssignModal={openAutomaticAssignModal}
            hasJuniorRol={!!hasJuniorRol()}
            leadsCount={leadsToAdd.length}
            closeModal={() => this.setState({ openAutomaticAssignModal: false })}
            onLeadAssignChange={this.onLeadAssignChange}
            companyId={company}
            origin={this.state.origin.id}
          />
        )}
        <Dialog open={openOriginModal} onClose={() => this.handleClose()}>
          <DialogTitle>
            Escoja {this.state.company ? 'el origen' : 'la empresa'} de los leads
          </DialogTitle>
          {this.state.company ? this.renderOrigins() : this.renderCompanies()}
        </Dialog>
      </div>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  searchLeads: PropTypes.func.isRequired,
  onBulkAdd: PropTypes.func,
  onDetail: PropTypes.func,
  stopAdd: PropTypes.func,
  onDownload: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onBulkAssign: PropTypes.func,
  isFilterable: PropTypes.bool,
  avoidRemove: PropTypes.bool,
  isBulkable: PropTypes.bool,
  isSingle: PropTypes.bool,
  cantSearch: PropTypes.bool,
  isDownloadable: PropTypes.bool,
  isSendingSearch: PropTypes.bool,
  isLeadSearch: PropTypes.bool,
  isDragable: PropTypes.bool.isRequired,
  hasCreatePermission: PropTypes.bool.isRequired,
  hasRanking: PropTypes.bool,
  hasBulkAssignPermission: PropTypes.bool,
  editingIndex: PropTypes.number,
  filterChips: PropTypes.array,
  selectedItems: PropTypes.array,
  leadNames: PropTypes.array,
  filterOptions: PropTypes.array,
  onFilter: PropTypes.func,
  listBrokers: PropTypes.array,
  assignBroker: PropTypes.string,
  changeAssignBroker: PropTypes.func,
  origins: PropTypes.array,
  companies: PropTypes.array,
  withoutFilter: PropTypes.object,
  onUploadTrivoSmartOffers: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { leads } = state;
  return {
    isSendingSearch: leads.sendingSearch,
    leadNames: leads.leadNames,
    error: leads.error,
    message: leads.message,
  };
};

const mapDispatchToProps = {
  searchLeads,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(EnhancedTableToolbar));
