import * as originActions from '../actions/types/origins';

const initialState = {
  origins: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {origins, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case originActions.FILTER_ORIGINS_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case originActions.FILTER_ORIGINS_SUCCESS:
    return {...state, sending: false, origins, error: null, message: 'Etapas obtenidas satisfactoriamente', dataLength};
  case originActions.FILTER_ORIGINS_FAILED:
    return {...state, sending: false, origins: [], error, message, dataLength};
  case originActions.GET_ORIGINS_SENDING:
    return {...state, sending: true};
  case originActions.GET_ORIGINS_SUCCESS:
    return {...state, sending: false, origins, error: null, message: 'Etapas obtenidas satisfactoriamente', dataLength};
  case originActions.GET_ORIGINS_FAILED:
    return {...state, sending: false, origins: [], error, message, dataLength};
  case originActions.CREATE_ORIGIN_SENDING:
    return {...state, sending: true};
  case originActions.CREATE_ORIGIN_SUCCESS:
    return {...state, sending: false, error: null, message: 'Etapa creada satisfactoriamente'};
  case originActions.CREATE_ORIGIN_FAILED:
    return {...state, sending: false, error, message};
  case originActions.UPDATE_ORIGIN_SENDING:
    return {...state, sending: true};
  case originActions.UPDATE_ORIGIN_SUCCESS:
    return {...state, sending: false, error: null, message: 'Etapa actualizada satisfactoriamente'};
  case originActions.UPDATE_ORIGIN_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
