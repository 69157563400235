export const GET_USERS_SENDING = 'GET_USERS_SENDING';

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

export const GET_USERS_FAILED = 'GET_USERS_FAILED';


export const GET_USER_SENDING = 'GET_USER_SENDING';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const GET_USER_FAILED = 'GET_USER_FAILED';


export const FILTER_USERS_SENDING = 'FILTER_USERS_SENDING';

export const FILTER_USERS_SUCCESS = 'FILTER_USERS_SUCCESS';

export const FILTER_USERS_FAILED = 'FILTER_USERS_FAILED';


export const CREATE_USER_SENDING = 'CREATE_USER_SENDING';

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

export const USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS';

export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';


export const RESETPASSWORD_SENDING = 'RESETPASSWORD_SENDING';

export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS';

export const RESETPASSWORD_FAILED = 'RESETPASSWORD_FAILED';


export const UPDATE_USER_SENDING = 'UPDATE_USER_SENDING';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';


export const FILTER_USERS_ASESSORS_SENDING = 'FILTER_USERS_ASESSORS_SENDING';

export const FILTER_USERS_ASESSORS_SUCCESS = 'FILTER_USERS_ASESSORS_SUCCESS';

export const FILTER_USERS_ASESSORS_FAILED = 'FILTER_USERS_ASESSORS_FAILED';

