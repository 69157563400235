export const styles = (theme) => ({
  filterItems: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#fff',
    padding: '5px',
    marginBottom: 0,
    listStyle: 'none',
    // display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  chip: {
    margin: 3,
  },
  filterContent: {
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#fff',
    marginBottom: '20px',
    borderRadius: 12,
  },
  toolbarContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarButton: {
    textTransform: 'none',
    color: 'white',
    '&:focus': {
      outline: 'none',
    },
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default styles;
