import * as calendarActions from '../actions/types/calendar';

const initialState = {
  rescheduleEvent: { error: null, message: '' },
  events: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  filters: {},
};

export default (state = initialState, action = {}) => {
  const { events = [], message = '', error = null, dataLength = 0, sending = false, filters = {} } =
    action.payload || {};

  switch (action.type) {
    case calendarActions.RESCHEDULE_EVENT_FAILED:
    case calendarActions.RESCHEDULE_EVENT_SUCCESS:
    case calendarActions.RESCHEDULE_EVENT_SENDING:
      return { ...state, rescheduleEvent: { message, error } };
    case calendarActions.GET_ACTIVE_EVENTS_FAILED:
      return { ...state, error, events, message, dataLength, sending, filters };
    case calendarActions.GET_ACTIVE_EVENTS_SENDING:
      return { ...state, sending, events, message, dataLength, filters, error };
    case calendarActions.GET_ACTIVE_EVENTS_SUCCESS:
      return {
        ...state,
        events,
        error,
        sending,
        message: 'Eventos activos obtenidos satisfactoriamente',
        filters,
        dataLength,
      };
    default:
      return state;
  }
};
