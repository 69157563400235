import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  withStyles,
} from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';
import { styles } from './styles';
import FilterModal from './filterModal';
import getDashboard from '../../../actions/dashboard';

const FilterChips = (props) => {
  const { classes, filters, users } = props;

  const chipData = [
    { type: 'startDate', label: `Desde el ${moment(filters.startDate).format('LL')}` },
    { type: 'endDate', label: `Hasta el ${moment(filters.endDate).format('LL')}` },
    ...filters.brokers.map((id) => {
      const user = users.find((user) => user._id === id);
      return {
        type: 'brokers',
        label: user && user.name,
      };
    }),
  ];

  const chipColor = (filterType) => {
    return [
      { type: 'startDate', color: '#c6f1d6' },
      { type: 'endDate', color: '#ffba92' },
      { type: 'brokers', color: '#e0f5b9' },
    ].find((item) => item.type === filterType).color;
  };

  if (users.length === 0) chipData.pop();

  return (
    <div>
      {chipData.map((data, index) => {
        return (
          <span key={`chip-filter-${index}`}>
            <Chip
              label={data.label}
              className={classes.chip}
              style={{ backgroundColor: chipColor(data.type) }}
            />
          </span>
        );
      })}
    </div>
  );
};

const FiltersDashboard = (props) => {
  const { filters, users } = props;

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const dispatch = useDispatch();

  const { classes } = props;
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title} variant="h5" color="textSecondary" gutterBottom>
            Filtros
          </Typography>
          <div>
            <Tooltip title="Filtrar">
              <IconButton onClick={() => setOpenFilterModal(true)}>
                <FilterList />
              </IconButton>
            </Tooltip>
          </div>
        </CardContent>
        {filters && (
          <CardActions>
            <FilterChips filters={filters} users={users} classes={classes} />
          </CardActions>
        )}
      </Card>
      {openFilterModal && (
        <FilterModal
          open={openFilterModal}
          close={() => setOpenFilterModal(false)}
          filters={filters}
        />
      )}
    </>
  );
};

export default withStyles(styles)(FiltersDashboard);
