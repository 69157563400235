import { BorderTop } from "@material-ui/icons";

const hexToRGB = (hex, opacity) => {
  const newHex = hex.replace('#', '');
  hex = '0x' + newHex;
  let r = (hex >> 16) & 0xff;
  let g = (hex >> 8) & 0xff;
  let b = hex & 0xff;
  return `rgb(${r}, ${g}, ${b}, ${opacity})`;
};
export const styles = (theme) => ({
  container: {
    width: 1200,
    maxWidth: 1400,
    minWidth: 1400,
    backgroundColor: '#fff',
    '-webkit-print-color-adjust': 'exact',
    paddingTop: 20,
    paddingBottom: 20,
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  leftSide: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
  },
  titleContainer: {
    display: 'inline-block',
    paddingLeft: 20,
  },
  titleBrokerContainer: {
    paddingLeft: 20,
  },
  headphonesContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: 70,
    marginTop: -40,
  },
  rightSide: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: 430,
  },
  fontSize: {
    fontSize: 12,
    textAlign: 'justify',
    color: '#8A8A8A',
    '-webkit-print-color-adjust': 'exact',
  },
  projectDescription: {
    fontSize: 13,
    textAlign: 'justify',
    marginBottom: 5,
    color: '#8A8A8A',
    '-webkit-print-color-adjust': 'exact',
  },

  flexItemCenter: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  title: {
    textAlign: 'center',
  },
  titleBroker: {
    textAlign: 'left',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 14,
    color: '#999999',
    '-webkit-print-color-adjust': 'exact',
    maxWidth: 800,
  },
  footer: {
    width: '80%',
    textAlign: 'center',
    fontSize: 16,
    color: 'black',
    '-webkit-print-color-adjust': 'exact',
    marginLeft: 10,
  },
  subtitleBroker: {
    textAlign: 'left',
    fontSize: 14,
    color: '#999999',
    '-webkit-print-color-adjust': 'exact',
  },
  imagesContainer: {
    display: 'block',
    marginLeft: 5,
    height: 480,
  },
  projectTitle: {
    position: 'absolute',
    marginTop: 100,
    backgroundColor: 'black',
    color: '#ffffff',
    '-webkit-print-color-adjust': 'exact',
    fontSize: 18,
    fontWeight: 800,
    paddingLeft: 10,
    paddingRight: 10,
  },
  price: {
    fontSize: 18,
    textAlign: 'justify',
    marginRight: 10,
  },
  priceValue: {
    fontSize: 24,
    textAlign: 'justify',
    '-webkit-print-color-adjust': 'exact',
    color: '#969696',
  },
  amenitiesContainer: {
    display: 'inline-block',
    width: '100%',
  },
  matchImage: {
    width: 500,
    height: 460,
  },
  leadIcon: {
    width: 204,
    height: 178,
  },
  profileImg: {
    width: 130,
    height: 130,
    marginTop: 20,
  },
  profileBrokerImg: {
    width: 85,
    height: 85,
    borderRadius: 15,
  },
  profileNewBrokerImg: {
    width: 110,
    borderRadius: 15,
    marginTop:"20px"
  },
  imageProfileContainer: {
    width: '100%',
    paddingRight: '50px',
  },
  matchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 25,
    paddingLeft: 22,
    paddingRight: 22,
    '-webkit-print-color-adjust': 'exact',
    height: 480,
    overflowY: 'hidden',
  },
  description: {
    marginLeft: 20,
    maxHeight: 500,
    paddingTop: 5,
    verticalAlign: 'top',
    height: 480,
  },
  infoImage: {
    width: 270,
    height: 230,
    marginBottom: 2,
    marginRight: 5,
  },
  header: {
    display: 'inline-flex',
    margin: '0 auto',
    marginTop: 20,
    marginLeft: 40,
  },
  headerBrokerContainer: {
    display: 'flex',
    backgroundColor: '#f8f8f8',
    '-webkit-print-color-adjust': 'exact',
    padding: 20,
    marginTop: 20,
  },
  headerBroker: {
    display: 'inline-flex',
    margin: '0 auto',
  },
  logo: {
    display: 'inline-block',
    width: 80,
    height: 90,
    marginBottom: 20,
    objectFit: 'contain',
  },
  newLogo: {
    display: 'inline-block',
    width: '180px',
    //height: 90,
    marginBottom: 20,
    objectFit: 'contain',
  },
  logoBroker: {
    display: 'inline-block',
    width: 80,
    height: 90,
    marginBottom: 20,
  },
  slogan: {
    display: 'inline-block',
    width: 160,
    height: 65,
    marginBottom: 20,
    objectFit: 'contain',
  },
  logoContainer: {
    display: 'inline-block',
    width: 270,
  },
  logoBrokerContainer: {
    display: 'inline-block',
  },
  divider: {
    marginTop: 5,
    marginBottom: 10,
    width: '10%',
    height: 3,
    backgroundColor: '#35B7A4',
    '-webkit-print-color-adjust': 'exact',
  },
  fullWidthDivider: {
    color: 'red',
    marginTop: 5,
    marginBottom: 10,
    width: '60%',
    height: 3,
    backgroundColor: theme.palette.primary.main,
    '-webkit-print-color-adjust': 'exact',
  },
  wrapDivider: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
  },
  projectCounterText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  amenitiesPropertiesContainer: {
    width: '100%',
    display: 'inline-flex',
  },
  amenitiesText: {
    width: '100%',
    fontSize: 13,
    marginTop: 8,
    '-webkit-print-color-adjust': 'exact',
    color: '#8A8A8A',
  },
  tipImage: {
    width: '80px',
    position: 'absolute',
    right: 40,
  },
  customFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
    background: 'white',
    marginTop: 40,
    border: `solid ${theme.palette.primary.main}`,
  },
  button: {
    margin: theme.spacing.unit,
    '& *:hover, &:hover': {
      color: 'white',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  gridRoot: {
    flexGrow: 1,
  },
  gridText: {
    '& span': {
      fontWeight: 'bold',
    },
  },
  gridTextPrice: {
    '& span': {
      fontWeight: 'bold',
    },
    '& p': {
      fontSize: '18px !important',
      color: theme.palette.primary.main,
      fontWeight: '600',
    },
  },
  commentText: {
    fontSize: 11,
    textAlign: 'justify',
    color: '#8A8A8A',
    '-webkit-print-color-adjust': 'exact',
    '& span': {
      fontWeight: 'bold',
      color: 'black',
    },
  },
  newLogo: {
    display: 'inline-block',
    width: '150px',
    //height: 90,
    marginBottom: 20,
    objectFit: 'contain',
  },
  flexContainer: { display: 'flex' },
  fullContainer: { display: 'flex' },
  flexItems: { flex: 1 },
  flexItemsContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divContainer: { display: 'flex', marginLeft: '20px', marginRight: '20px' },
  div1: { width: '250px', textAlign: 'left' },
  div2: { width: '900px', textAlign: 'center' },
  div3: { width: '250px', textAlign: 'right' },
  itemBordered: {
    border: '2px solid #6e6e6e',
    color: "#6e6e6e",
    borderRadius: '16px',
    padding: '5px 20px',
    margin: '0px 5px',
    minWidth: '200px',
    display: 'inline-block',
    fontSize: '18px',
    minWidth: '350px',
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 10,
  },
  itemBordered2: {
    border: '2px solid #6e6e6e',
    color: "#6e6e6e",
    borderRadius: '16px',
    padding: '5px 20px',
    margin: '0px 5px',
    minWidth: '200px',
    display: 'inline-block',
    fontSize: '18px',
    minWidth: '350px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  flexItem: {
    display: 'flex',
    marginBottom: '15px',
    fontSize: '18px',
    fontWeight: 'bold',
    minWidth: '200px',
    flex: 1,
  },
  featuredProjectItem: {
    width: '820px',
    display: 'inline-block',
  },
  featuredProjectSecondary: {
    width: '545px',
    display: 'inline-block',
  },
  rowContainer: {
    marginLeft: '20px',
    marginRight: '20px',
    display: 'flex',
  },
  simpleFlex: {
    display: 'flex',
  },
  div4: {
    width: '250px',
    textAlign: 'justify',
    background: `linear-gradient(90deg, ${hexToRGB(theme.palette.primary.main, 1)} 0%, ${hexToRGB(
      theme.palette.primary.main,
      1,
    )} 70%, ${hexToRGB(theme.palette.primary.main, 0.75)} 100%)`,
    display: 'inline-block',
    borderBottomLeftRadius: '20px',
    borderTopLeftRadius: '20px',
    padding: '20px',
    color: 'white',
    fontWeight: '500',
    fontSize: '15px',
    position: 'relative',
  },
  boldItem: {
    fontWeight: '900',
  },
  image1: {
    width: '545px',
    textAlign: 'left',
    backgroundColor: 'white',
    display: 'inline-block',
    borderBottomRightRadius: '20px',
    borderTopRightRadius: '20px',
  },
  image2: {
    width: '545px',
    textAlign: 'left',
    backgroundColor: 'white',
    display: 'inline-block',
    borderBottomRightRadius: '20px',
    borderTopRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderTopLeftRadius: '20px',
  },
  tinyText: {
    fontSize: '10px',
    position: 'absolute',
    bottom: 0,
    paddingRight: '20px',
    textAlign: 'left',
  },
  sectionDivider: {
    marginTop: 20,
  },
  featuredProjectItem2: {
    width: '870px',
    display: 'inline-block',
  },
  div5: {
    width: '830px',
    textAlign: 'justify',
    background: theme.palette.primary.main,
    display: 'inline-block',
    borderRadius: '20px',
    padding: '20px',
    color: 'white',
    fontWeight: '500',
    fontSize: '14px',
    position: 'relative',
    display: 'flex',
  },
  div6: {
    display: 'inline-block',
    width: '390px',
    padding: '30px',
    fontSize: "16px",
    fontWeight: "500",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  div7: {
    display: 'inline-block',
    width: '390px',
  },
  featuredProjectSecondary2: {
    width: '520px',
    display: 'inline-block',
    backgroundColor: theme.palette.primary.main,
    borderRadius: "20px"
  },
  image_3: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'white',
    display: 'inline-block',
    borderRadius: "20px"
  },
  titleUnitType: {
    fontWeight: '900',
    fontSize:"22px"
  },
  simpleP:{
    marginBottom:"0.5rem"
  },
  simpleDivisor:{
    borderTop: "1px solid rgb(159 159 159 / 50%)"
  },
  headerCard:{
    backgroundColor: theme.palette.secondary.main,
    padding: "25px",
    fontSize: "32px",
    fontWeight: "900",
    textAlign: "center",
    color: "white",
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
  },
  cardContent:{
    textAlign: "center",
    margin: "30px",
    fontSize: "20px",
    color: "white"
  },
  fullColorRwContainer: {
    marginLeft: '20px',
    marginRight: '20px',
    display: 'flex',
    borderRadius: "20px",
    backgroundColor:  theme.palette.primary.main,
    minHeight:"200px"
  },
  colorTitle:{
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "20px",
    fontSize: "32px",
    fontWeight: "900",
    color: "white",

  },
  amenitiesContent:{
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    color: "white",
    width:"960px",
    fontSize: "24px",
    textAlign: "center"
  },
  newMatchImage: {
    width: 900,
  },
  imageUnitContainer:{
    width:"100%",
    textAlign: "center"
  },
  spaceItem:{
    width:"100%",
    height: "100px"
  },
  rowContainer2: {
    marginLeft: '20px',
    marginRight: '20px',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  amenitiesContent2:{
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    color: "white",
    flex: 1,
    fontSize: "18px",
    textAlign: "center"
  },
  fullColorRwContainer2: {
    marginLeft: '20px',
    marginRight: '20px',
    display: 'flex',
    borderRadius: "20px",
    backgroundColor:  theme.palette.primary.main,
  },
});

export default styles;