import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch, useSelector } from 'react-redux';

import { styles } from './styles';
import {
  createProforma,
  getProformas,
  getUnitsCompanyProformaConfig,
  sendEmail,
} from '../../../actions/proformas';
import Proforma from '../proforma';
import { useForm } from '../../../hooks/useForm';
import { downloadPdf, parseNumber } from '../../../utils/utils';
import { CREATE_PROFORMA_SUCCESS } from '../../../actions/types/proformas';
import { getLead } from '../../../actions/leads';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const unitFormDefault = {
  id: '',
  name: '',
  coveredArea: '',
  coveredAreaPrice: '',
  gardenOrTerraceArea: '',
  gardenOrTerraceAreaPrice: '',
  totalArea: '',
  totalPrice: 0,
  warehouseValue: '',
  rooms: '',
  parkingSpaces: '',
  parkingSpacesPrice: '',
  observations: '',
  order: '',
  reserveValue: '',
  entryValue: '',
  discountValue: '',
  content: [],
  cashPrice: 0,
  showUnitData: false,
};

const customerFormDefault = {
  name: '',
  lastname: '',
  document: '',
  civilStatus: '',
  address: '',
  phone: '',
  date: '',
  sector: '',
  nationality: 'Ecuador',
  workSector: '',
  cellPhone: '',
};

const DialogProformas = (props) => {
  const { open, unitsLocal, classes, closeDialog, project } = props;

  const dispatch = useDispatch();
  const { units, defaultConfigurations, sending, proformas } = useSelector(
    (state) => state.proformas,
  );

  const {
    entryPercentage,
    interestPercentage,
    reservePercentage,
    builderFinancingPercentage,
    totalQuotes,
    quotationObservations,
    purchaseIntention,
    deadline,
  } = defaultConfigurations;

  const { lead } = useSelector((state) => state.leads);

  const [formValues, handleInputChange, changeManualState] = useForm(
    customerFormDefault,
  );
  const [unitValues, handleUnitChange, changeUnitManualState] = useForm();

  const [actualUnit, setActualUnit] = useState();
  const [proformedUnits, setProformedUnits] = useState([]);
  const [versionProformedUnits, setVersionProformedUnits] = useState([]);
  const [quotationInvalid, setQuotationInvalid] = useState(false);
  const [payFormInvalid, setPayFormInvalid] = useState(false);
  const [documentInvalid, setDocumentInvalid] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [currentVersion, setCurrentVersion] = useState();
  const [versionMenu, setVersionMenu] = useState(null);

  useEffect(() => {
    if (currentVersion) {
      changeManualState(
        proformas.find((item) => item.version === currentVersion).customer,
      );
    } else {
      if (project) {
        dispatch(getUnitsCompanyProformaConfig(project._id, unitsLocal));
        dispatch(
          getProformas(project._id, lead.id, localStorage.getItem('user')),
        );
        changeManualState({
          name: lead.user.name,
          lastname: lead.user.lastname,
          email: lead.user.email,
          phone: lead.user.phone,
          document: lead.user.document || '',
          civilStatus: lead.user.civilStatus || '',
          address: lead.user.address || '',
          date: lead.user.date || '',
          sector: lead.user.sector || '',
          nationality: lead.user.nationality || 'Ecuador',
          workSector: lead.user.workSector || '',
          cellPhone: lead.user.cellPhone || '',
          date: moment(new Date()),
        });
      }
    }
  }, [currentVersion]);

  useEffect(() => {
    if (units.length > 0) {
      const unitId = currentVersion
        ? proformas.find((item) => item.version === currentVersion).units[0].id
        : units[0].id;
      setActualUnit(unitId);
    }
  }, [units, currentVersion]);

  useEffect(() => {
    if (!currentVersion) setVersionProformedUnits([]);
    else {
      const unitsArray = proformas
        .find((item) => item.version === currentVersion)
        .units.map((unit) => ({
          id: unit.id || '',
          name: unit.name || '',
          coveredArea: unit.coveredArea || '',
          coveredAreaPrice: unit.coveredAreaPrice || '',
          gardenOrTerraceArea: unit.gardenOrTerraceArea || '',
          gardenOrTerraceAreaPrice: unit.gardenOrTerraceAreaPrice || '',
          totalArea: unit.totalArea || '',
          totalPrice: unit.totalPrice || 0,
          warehouseValue: unit.warehouseValue || '',
          rooms: unit.rooms || '',
          parkingSpaces: unit.parkingSpaces || '',
          parkingSpacesPrice: unit.parkingSpacesPrice || '',
          observations: unit.observations || '',
          order: unit.order || 0,
          builderFinancingPercentage:
            unit.quotation.payInformation.builderFinancingPercentage || '',
          builderFinancingValue:
            unit.quotation.payInformation.builderFinancingValue || '',
          entryPercentage: unit.quotation.payInformation.entryPercentage || '',
          entryValue: unit.quotation.payInformation.entryValue || '',
          entryDate:
            unit.quotation.payInformation.entryDate || moment(new Date()),
          interestPercentage:
            unit.quotation.payInformation.interestPercentage || '',
          reservePercentage:
            unit.quotation.payInformation.reservePercentage || '',
          reserveValue: unit.quotation.payInformation.reserveValue || '',
          reserveDate:
            unit.quotation.payInformation.reserveDate || moment(new Date()),
          discountPercentage:
            unit.quotation.payInformation.discountPercentage || '',
          discountValue: unit.quotation.payInformation.discountValue || '',
          totalQuotes: unit.quotation.data.totals.totalQuotes,
          creditBalancePercentage:
            unit.quotation.data.totals.creditBalancePercentage,
          creditBalanceValue: unit.quotation.data.totals.creditBalanceValue,
          content: unit.quotation.data.content,
          quotationObservations: unit.quotationObservations || '',
          purchaseIntention: unit.purchaseIntention || '',
          deadline: unit.deadline || deadline,
          showUnitData: unit.showUnitData || false,
        }));
      setVersionProformedUnits(unitsArray);
    }
  }, [currentVersion]);

  useEffect(() => {
    if (actualUnit) {
      const proformedUnit =
        versionProformedUnits.length > 0
          ? versionProformedUnits.find((x) => x.id === actualUnit)
          : proformedUnits.find((x) => x && x.id === actualUnit);

      if (proformedUnit) {
        changeUnitManualState(proformedUnit);
      } else {
        const currentUnit = units.find((x) => x.id === actualUnit);
        const unit = {
          ...unitFormDefault,
          ...currentUnit,
          coveredAreaPrice:
            currentUnit.totalPrice -
            parseNumber(currentUnit.warehouseValue) -
            parseNumber(currentUnit.parkingSpacesPrice),
          entryValue: (
            (currentUnit.totalPrice * entryPercentage) /
            100
          ).toFixed(2),
          reserveValue: (
            (currentUnit.totalPrice * reservePercentage) /
            100
          ).toFixed(2),
          entryDate: moment(new Date()).format('YYYY-MM-DD'),
          reserveDate: moment(new Date()).format('YYYY-MM-DD'),
          interestPercentage,
          builderFinancingPercentage,
          totalQuotes,
          quotationObservations,
          purchaseIntention,
          deadline,
        };
        changeUnitManualState(unit);
      }
    }
  }, [actualUnit, versionProformedUnits]);

  const onNext = () => {
    const array = currentVersion
      ? [...versionProformedUnits]
      : [...proformedUnits];
    array[unitValues.order] = unitValues;
    if (!!!currentVersion) setProformedUnits(array);
    setActualUnit(
      currentVersion
        ? versionProformedUnits.find((x) => x.order === unitValues.order + 1).id
        : units.find((x) => x.order === unitValues.order + 1).id,
    );
  };

  const formatProformaUnit = (unit) => {
    const {
      reserveValue,
      entryValue,
      interestPercentage,
      content,
      builderFinancingPercentage,
      totalQuotes,
      parkingSpaces,
      warehouses,
      totalQuotesValue,
      cashPrice,
      reserveDate,
      entryDate,
      discountValue,
      ...rest
    } = unit;

    const currentPrice =
      parseNumber(rest.coveredAreaPrice) +
      parseNumber(rest.gardenOrTerraceAreaPrice) +
      parseNumber(rest.parkingSpacesPrice) +
      parseNumber(rest.warehouseValue) -
      parseNumber(discountValue);

    return {
      ...rest,
      totalPrice: currentPrice + parseNumber(discountValue),
      warehouses: warehouses || parseNumber(rest.warehouseValue) > 0 ? 1 : 0,
      parkingSpaces: parseNumber(parkingSpaces),
      quotation: {
        payInformation: {
          reserveValue,
          reservePercentage: (parseNumber(reserveValue) * 100) / currentPrice,
          reserveDate: moment(reserveDate).format('YYYY-MM-DD'),
          entryValue,
          entryPercentage: (parseNumber(entryValue) * 100) / currentPrice,
          entryDate: moment(entryDate).format('YYYY-MM-DD'),
          interestPercentage,
          builderFinancingValue:
            (currentPrice * parseNumber(builderFinancingPercentage)) / 100,
          builderFinancingPercentage,
          discountValue,
          discountPercentage: (parseNumber(discountValue) * 100) / (currentPrice + parseNumber(discountValue))
        },
        data: {
          content: content.map((item) => ({
            ...item,
            date: item.date.format('YYYY-MM-DD'),
          })),
          totals: {
            totalQuotes,
            creditBalanceValue:
              currentPrice -
              (currentPrice * parseNumber(builderFinancingPercentage)) / 100 -
              parseNumber(entryValue),
            creditBalancePercentage:
              100 -
              parseNumber(builderFinancingPercentage) -
              (parseNumber(entryValue) * 100) / currentPrice,
            totalQuotesValue: parseNumber(totalQuotesValue),
            cashPrice: parseNumber(cashPrice),
          },
        },
      },
    };
  };

  const onSave = async () => {
    let units = [...proformedUnits];
    units[unitValues.order] = unitValues;

    units = units.map((unit) => formatProformaUnit(unit));

    const { date, ...customer } = formValues;
    const { attachments } = defaultConfigurations;

    const proforma = {
      lead: lead.id,
      broker: localStorage.getItem('user'),
      project: project._id,
      projectName: project.name,
      date: moment(date).format('YYYY-MM-DD'),
      customer,
      units,
      company: lead.company,
      attachments: [...attachments.project, ...attachments.company],
    };

    const { type, payload } = await dispatch(createProforma(proforma));

    if (type === CREATE_PROFORMA_SUCCESS) {
      setSimpleDialog({
        text:
          'La proforma ha sido creada con éxito, elija una de las siguientes acciones para continuar',
        actions: (
          <Fragment>
            <Button
              variant="contained"
              style={{ margin: 8 }}
              onClick={() => {
                sendEmail(payload._id, lead.user.email);
                dispatch(getLead(lead.id));
                closeDialog(false);
              }}
              color="primary"
            >
              Enviar Email
            </Button>
            <Button
              variant="contained"
              style={{ margin: 8 }}
              onClick={() => {
                dispatch(getLead(lead.id));
                downloadPdf(payload.pdf);
                closeDialog(false);
              }}
              color="primary"
            >
              Descargar PDF
            </Button>

            <Button
              variant="contained"
              style={{ margin: 8 }}
              onClick={() => {
                dispatch(getLead(lead.id));
                closeDialog(false);
              }}
              color="primary"
            >
              No hacer nada
            </Button>
          </Fragment>
        ),
      });
    } else {
      setSimpleDialog({
        text: 'Ha habido un error al crear la proforma, inténtelo nuevamente',
        actions: (
          <Button
            variant="contained"
            onClick={() => setSimpleDialog(null)}
            color="primary"
          >
            Entendido
          </Button>
        ),
      });
    }
  };

  const versionMenuAction = (index) => {
    const proforma = proformas.find(
      (proforma) => proforma.version === currentVersion,
    );

    const sendEmailDialog = (email, preview) => ({
      text: (
        <Fragment>
          Se ha enviado la {preview ? 'vista previa' : 'proforma'} a{' '}
          <span style={{ color: 'teal', fontWeight: 'bold' }}>{email}</span>{' '}
          satisfactoriamente
        </Fragment>
      ),
      actions: (
        <Button
          variant="contained"
          onClick={() => setSimpleDialog(null)}
          color="primary"
        >
          Entendido
        </Button>
      ),
    });

    if (index === 0) {
      const clonedUnits = [];

      for (const unit of versionProformedUnits) {
        const oldUnit = units.find((item) => item.id === unit.id);
        if (oldUnit) {
          clonedUnits.push(unit.name);
          proformedUnits[oldUnit.order] = { ...unit, order: oldUnit.order };
        }
      }

      setCurrentVersion(null);
      setSimpleDialog({
        text:
          clonedUnits.length > 0
            ? `Se ${
                clonedUnits.length > 1 ? 'han' : 'ha'
              } clonado a la version actual ${
                clonedUnits.length > 1 ? 'las unidades' : 'la unidad'
              } ${clonedUnits.join(', ')} desde la version ${currentVersion}`
            : 'No hay unidades coincidentes para clonar',
        actions: (
          <Button
            variant="contained"
            onClick={() => setSimpleDialog(null)}
            color="primary"
          >
            Entendido
          </Button>
        ),
      });
    } else if (index === 1) {
      sendEmail(proforma._id, lead.user.email);
      setSimpleDialog(sendEmailDialog(lead.user.email), true);
    } else if (index === 2) {
      sendEmail(proforma._id, localStorage.getItem('userEmail'));
      setSimpleDialog(sendEmailDialog(localStorage.getItem('userEmail')));
    } else if (index === 3) {
      downloadPdf(proforma.pdf);
    }
  };

  const closeDialogProformas = () => {
    closeDialog(false);
  };

  const renderTabs = () => {
    return (currentVersion ? versionProformedUnits : units).map((unit) => (
      <Tab
        className={
          !currentVersion &&
          !proformedUnits.some((item) => item && item.id === unit.id)
            ? 'disabled'
            : ''
        }
        value={unit.id}
        key={unit.id}
        label={`Unidad ${unit.name}`}
      />
    ));
  };

  const renderTabContent = () => {
    let tabContent = '';
    (currentVersion ? versionProformedUnits : units).filter((unit) => {
      if (unit.id == actualUnit) {
        tabContent = (
          <div key={unit.id}>
            <Proforma
              formValues={formValues}
              handleInputChange={handleInputChange}
              unit={unit}
              project={project}
              setQuotationInvalid={setQuotationInvalid}
              setPayFormInvalid={setPayFormInvalid}
              setDocumentInvalid={setDocumentInvalid}
              unitValues={unitValues}
              handleUnitChange={handleUnitChange}
              currentVersion={currentVersion}
              setSimpleDialog={setSimpleDialog}
              changeUnitManualState={changeUnitManualState}
            />
          </div>
        );
      }
    });
    return tabContent;
  };

  const [simpleDialog, setSimpleDialog] = useState();

  const renderSimpleDialog = (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={!!simpleDialog}
      onClose={() => setSimpleDialog(null)}
    >
      <DialogTitle>Confirmación</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {simpleDialog && simpleDialog.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{simpleDialog && simpleDialog.actions}</DialogActions>
    </Dialog>
  );

  const isLastTab = () =>
    currentVersion && versionProformedUnits.length > 0
      ? versionProformedUnits[versionProformedUnits.length - 1].id ===
        actualUnit
      : units[units.length - 1].id === actualUnit;

  const isReadyToSave = () => {
    const lengthDiff = units.length - proformedUnits.filter((x) => x).length;
    const response =
      lengthDiff <= 1 &&
      (lengthDiff === 0 ||
        !proformedUnits
          .filter((x) => x)
          .map((x) => x.id)
          .includes(units[units.length - 1].id));

    return response;
  };

  const invalidProforma = quotationInvalid || payFormInvalid || documentInvalid;

  return units && units.length > 0 ? (
    <Fragment>
      <Dialog
        fullScreen
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={closeDialogProformas}
        TransitionComponent={Transition}
        className={classes.muiDialog}
      >
        <DialogTitle className={classes.titleProformas}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>{`Proformas - ${project.name}`}</Grid>
            <Grid item>
              <Button
                onClick={() => setDrawer(true)}
                className={classes.iconButton}
                variant="contained"
                color="primary"
              >
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', color: 'white' }}
                >
                  {!currentVersion ? 'version actual' : `v${currentVersion}`}
                </Typography>
              </Button>
              {currentVersion && (
                <Fragment>
                  <IconButton
                    className={classes.iconButton}
                    style={{ borderRadius: '50%' }}
                    aria-owns={open ? 'version-menu' : undefined}
                    onClick={(event) => setVersionMenu(event.currentTarget)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="version-menu"
                    open={!!versionMenu}
                    anchorEl={versionMenu}
                    onClose={() => setVersionMenu(null)}
                  >
                    {[
                      'Clonar',
                      'Enviar proforma',
                      'Vista Previa',
                      'Descargar PDF',
                    ].map((option, index) => (
                      <MenuItem
                        key={option}
                        onClick={() => {
                          versionMenuAction(index);
                          setVersionMenu(null);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Fragment>
              )}
            </Grid>
            <Grid item xs={12}>
              <Tabs
                className={`${classes.tabs} ${invalidProforma && 'error'}`}
                value={actualUnit}
                onChange={(_, id) =>
                  !invalidProforma &&
                  (currentVersion ||
                    proformedUnits.some(
                      (item) =>
                        item &&
                        item.order >= units.find((u) => u.id === id).order,
                    )) &&
                  setActualUnit(id)
                }
                variant="scrollable"
                scrollButtons="auto"
              >
                {renderTabs()}
              </Tabs>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>{renderTabContent()}</DialogContent>
        <DialogActions className={classes.footerActions}>
          <Button onClick={closeDialogProformas} color="primary">
            Cancelar
          </Button>
          {!(isLastTab() && currentVersion) && (
            <Button
              onClick={() => (isLastTab() ? onSave() : onNext())}
              variant="contained"
              color="primary"
              disabled={invalidProforma || (isLastTab() && !isReadyToSave())}
            >
              {isLastTab() ? 'Guardar' : 'Siguiente'}
            </Button>
          )}
        </DialogActions>
        <Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => setDrawer(false)}
            onKeyDown={() => setDrawer(false)}
          >
            <div className={classes.list}>
              <List>
                <ListItem button onClick={() => setCurrentVersion(null)}>
                  <ListItemText
                    primary="actual"
                    className={!currentVersion ? classes.currentVersion : ''}
                  />
                </ListItem>
                {[...proformas].reverse().map(({ version, createdAt }) => (
                  <ListItem
                    button
                    onClick={() => setCurrentVersion(version)}
                    key={`version_${version}`}
                  >
                    <ListItemText
                      primary={`v${version}`}
                      secondary={moment(createdAt).format(
                        'DD-MMM-YYYY  h:mm a',
                      )}
                      className={
                        version === currentVersion ? classes.currentVersion : ''
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </Drawer>
        {renderSimpleDialog}
        {sending && (
          <div className={classes.loaderBackdrop}>
            <CircularProgress
              style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto',
              }}
            />
          </div>
        )}
      </Dialog>
    </Fragment>
  ) : (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    />
  );
};

DialogProformas.propTypes = {
  classes: PropTypes.object.isRequired,
  unitsLocal: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  lead: PropTypes.object,
};

export default withStyles(styles)(DialogProformas);
