export const SET_FILTERS = 'SET_FILTERS';

export const SET_CHIP_DATA = 'SET_CHIP_DATA';

export const SET_CITIES = 'SET_CITIES';

export const SET_ROOMS = 'SET_ROOMS';

export const SET_UNIT_TYPES = 'SET_UNIT_TYPES';

export const SET_PROJECT_STATUS = 'SET_PROJECT_STATUS';

export const SET_SECTORS = 'SET_SECTORS';

export const SET_ZONES = 'SET_ZONES';

export const SET_PROJECTS = 'SET_PROJECTS';

export const SET_CHANGE_FILTERS = 'SET_CHANGE_FILTERS';

export const SET_DISABLE_FILTER = 'SET_DISABLE_FILTER';
