import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getTasksHistory } from '../../../../actions/calendar';
import PropTypes from 'prop-types';
import { dateFormat, ADMIN } from '../../../../utils/utils';
import EnhancedTable from '../../../common/enhancedTable';
import { styles } from './styles';

const profile = JSON.parse(localStorage.getItem('profile'));

const getProfileIsAdmin = () => {
  return profile.name === ADMIN;
};

const getBrokerId = () => {
  return localStorage.getItem('user');
};

const taskStatusParser = (status = '') => {
  switch (status) {
    case 'FINALIZED':
      return { color: '#2dad37', label: 'Finalizado' };
    case 'CANCELED':
      return { color: '#f54343', label: 'Cancelado' };
    default:
      return { color: '#898989', label: 'No definido' };
  }
};

const rowsPerPage = 5;

const TasksHistory = ({ classes = {}, leadId }) => {
  const isAdmin = getProfileIsAdmin();
  const dispatch = useDispatch();

  const { tasks, sending, page, dataLength: totalItems } = useSelector(
    (state) => state.tasksHistory,
  );

  const parseTask = (task) => {
    const currentDate = moment(task.date);
    const timeAgo = currentDate.fromNow();
    const fullDate = `${currentDate.format(`${dateFormat} HH:mm`)} ${timeAgo && `(${timeAgo})`}`;
    const status = taskStatusParser(task.status);
    const statusComponent = <Typography style={{ color: status.color }}>{status.label}</Typography>;

    return { ...task, status: statusComponent, fullDate };
  };

  const fetchHistoryTasks = (page = 1) =>
    dispatch(getTasksHistory(page, rowsPerPage, [], leadId));

  const getData = () => {
    const cols = [
      { id: 'status', right: false, disablePadding: false, label: 'Estado', type: 'component' },
      {
        id: 'title',
        right: false,
        disablePadding: false,
        label: 'Título del evento',
        type: 'text',
      },
      { id: 'fullDate', right: false, disablePadding: false, label: 'Fecha', type: 'text' },
    ];

    const brokerCol = {
      id: 'broker',
      right: false,
      disablePadding: false,
      label: 'Usuario',
      type: 'text',
    };

    return {
      cols: isAdmin ? [ ...cols] : cols,
      rows: tasks.map((task) => parseTask(task)),
    };
  };

  const getConfig = () => ({
    cantSearch: true,
    disableRowsOptions: true,
    isDragable: false,
    isSortable: false,
    isSelectable: false,
    isEditableInline: false,
    hasEditPermission: false,
    hasCreatePermission: false,
    pagination: { current: page - 1, rowsPerPage, totalItems },
    filters: { by: {}, chips: [], options: [], is: true },
    searchText: '',
  });

  const handleChangePage = (newPage) => {
    fetchHistoryTasks(newPage + 1);
  };

  const handleChangeRowsPerPage = (newRowsPerPage = rowsPerPage) => {
    console.log('handleChangeRowsPerPage', { newRowsPerPage });
  };

  const handlerOnSearch = () => {};

  const handlers = {
    onSearch: handlerOnSearch,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
  };

  useEffect(() => {
    fetchHistoryTasks(1);
  }, [leadId]);

  if (sending) {
    return (
      <div className={classes.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <EnhancedTable
        title="Historial de tareas"
        data={getData()}
        config={getConfig()}
        handlers={handlers}
      />
    </div>
  );
};

TasksHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  leadId: PropTypes.string.isRequired,
};

export default withStyles(styles)(TasksHistory);
