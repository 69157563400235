import React from 'react';
import PropTypes from 'prop-types';
import { arrayMove } from 'react-sortable-hoc';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import EnhancedSortableTable from './enhancedSortableTable';
import EnhancedTableToolbar from './enhancedTableToolbar';
import EnhancedTableHead from './enhancedTableHead';
import { styles } from './styles';
import EnhancedTableRow from './enhancedTableRow';

import { isNumber, dateFormat, SUPER_ADMIN_ROL_ID, ASESOR_ID } from '../../../utils/utils';

const zone = 'zoneId';
const builder = 'builderId';
const propertyType = 'typeId';
const city = 'cityId';

export class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      rows: [],
      editingIndex: null,
      isAddingNew: false,
    };
    this.renderBody = this.renderBody.bind(this);
    this.renderToolBar = this.renderToolBar.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.handleSaveRow = this.handleSaveRow.bind(this);
    this.handleUpdateRow = this.handleUpdateRow.bind(this);
    this.handleViewEventsHistory = this.handleViewEventsHistory.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.displayedRows = this.displayedRows.bind(this);
    this.addRow = this.addRow.bind(this);
    this.stopAdd = this.stopAdd.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.handleSortEnd = this.handleSortEnd.bind(this);
    this.handleChangeCell = this.handleChangeCell.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleClickRow = this.handleClickRow.bind(this);
    this.handleAttach = this.handleAttach.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleAttributes = this.handleAttributes.bind(this);
    this.openMapModal = this.openMapModal.bind(this);
    this.onProformas = this.onProformas.bind(this);
    this.onProformaAdmin = this.onProformaAdmin.bind(this);
  }

  componentWillMount() {
    this.setState({ rows: this.props.data.rows });
  }

  onSearch(searchText) {
    if (searchText !== null) {
      // eslint-disable-next-line no-param-reassign
      searchText = searchText.toLowerCase();
    }
    this.props.handlers.onSearch(searchText);
  }

  handleRequestSort(event, property) {
    const { isSortable } = this.props.config;
    if (isSortable !== false) {
      const { by, direction } = this.props.config.order;
      const orderBy = property;
      let orderDirection = 'desc';
      if (by === property && direction === 'desc') {
        orderDirection = 'asc';
      }
      this.props.handlers.onOrder(orderDirection, orderBy);
    }
  }

  handleChangeCell(text, prop, rowIndex) {
    const { rows } = this.state;
    if (prop.id === zone || prop.id === builder || prop.id === propertyType) {
      rows[rowIndex][prop.id] = text;
      if (prop.id === builder && text !== null) {
        const builders = this.props.data.autoFillers.builder;
        const builderItem = builders.filter((obj) => text === obj.id)[0].revenue_percentage;
        rows[rowIndex].revenue_percentage = builderItem;
      }
    } else if (prop.id === city) {
      let selectedCity;

      if (text) selectedCity = text;
      else {
        const cities = this.props.data.autoFillers.city;
        selectedCity = cities && cities.length > 0 ? cities[0].id : null;
      }

      rows[rowIndex][city] = selectedCity;
      this.props.handlers.onChangeCity(text);
    } else {
      rows[rowIndex][prop.id] = text;
    }
    this.setState({ rows });
  }

  handleSelectAllClick(event) {
    if (event.target.checked) {
      this.setState((state) => ({ selected: state.rows.map((n) => n.id) }));
      return;
    }

    this.setState({ selected: [] });
  }

  handleAttributes(row) {
    this.props.handlers.onAttributes(row);
  }

  openMapModal(row) {
    this.props.handlers.openMapModal(row);
  }

  onProformas(row) {
    this.props.handlers.onProformas(row);
  }

  onProformaAdmin(row) {
    this.props.handlers.onProformaAdmin(row);
  }

  displayedRows = (info) => {
    return <div>{`${info.from} - ${info.to} de ${info.count}`} </div>;
  };

  handleSaveRow(row) {
    this.props.handlers.onAdd(row);
    this.setState({ isAddingNew: false, editingIndex: null });
  }

  handleViewEventsHistory(row) {
    this.props.handlers.onViewHistory(row);
  }

  handleUpdateRow(row) {
    this.props.handlers.onUpdate(row);
    this.setState({ isAddingNew: false, editingIndex: null });
  }

  handleClickRow(id) {
    if (this.props.config.isSelectable) {
      const { selected } = this.state;
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      this.setState({ selected: newSelected });
    }
  }

  handleChangePage(event, page) {
    this.props.handlers.onChangePage(page);
  }

  handleChangeRowsPerPage(event) {
    this.props.handlers.onChangeRowsPerPage(event.target.value);
  }

  handleDetail(row, rowIndex) {
    const { config, handlers } = this.props;
    const { rows, isAddingNew } = this.state;
    const { isEditableInline } = config;
    const { onDetail } = handlers;
    if (row.cityId) this.props.handlers.onChangeCity(row.cityId);
    if (isEditableInline) {
      if (isAddingNew) {
        row.shift();
        this.setState({ rows, editingIndex: rowIndex, isAddingNew: false });
      } else {
        this.setState({ editingIndex: rowIndex });
      }
    } else {
      const { id, _id } = rows[rowIndex];
      if (id) {
        onDetail(id, rowIndex);
      } else if (_id) {
        onDetail(_id, rowIndex);
      }
    }
  }

  handleEvent(row) {
    const { handlers } = this.props;
    const { onEvent } = handlers;
    onEvent(row._id);
  }

  handleDuplicate(row, rowIndex) {
    this.addRow(rowIndex, row);
  }

  handleAttach(id) {
    this.props.handlers.onAttach(id);
  }

  handleSortEnd({ oldIndex, newIndex }) {
    const { dragIndexOnly } = this.props.config;
    const newData = arrayMove(this.state.rows, oldIndex, newIndex);
    const rows = newData.map((item, index) => {
      const element = item;
      element.direction = index + 1;
      return element;
    });
    this.setState({ rows });
    if (dragIndexOnly) {
      this.props.handlers.onDrag({ oldIndex, newIndex });
    } else {
      this.props.handlers.onDrag(rows);
    }
  }

  isSelected(id) {
    return this.state.selected.indexOf(id) !== -1;
  }

  addRow(editingIndex, row) {
    const { config, handlers } = this.props;
    const { rows } = this.state;
    const { isEditableInline } = config;
    const { onAdd } = handlers;
    if (isEditableInline) {
      rows.splice(editingIndex, 0, row);
      this.setState({ rows, editingIndex, isAddingNew: true });
    } else {
      onAdd();
    }
  }

  stopAdd() {
    const { config } = this.props;
    const { rows, isAddingNew } = this.state;
    const { isEditableInline } = config;
    if (isEditableInline) {
      if (isAddingNew) {
        rows.shift();
        this.setState({ rows, editingIndex: null, isAddingNew: false });
      } else {
        this.setState({ editingIndex: null });
      }
    }
  }

  handleDownload() {
    const { handlers } = this.props;
    const { onDownload } = handlers;
    onDownload();
  }

  renderToolBar() {
    const { config, handlers, title, withoutFilter } = this.props;
    const { selected, editingIndex } = this.state;
    const {
      searchText,
      filters,
      isDragable,
      hasCreatePermission,
      isLeadSearch,
      isBulkable,
      isDownloadable,
      isSingle,
      cantSearch,
      assignBroker,
      listBrokers,
      hasBulkAssignPermission,
      hasRanking,
    } = config;
    const {
      onFilter,
      onBulkAdd,
      handleChangeAssignBroker,
      onBulkAssign,
      onDetail,
      onUploadTrivoSmartOffers,
    } = handlers;
    return (
      <EnhancedTableToolbar
        numSelected={selected.length}
        selectedItems={selected}
        title={title}
        hasRanking={hasRanking}
        isBulkable={isBulkable}
        onAdd={() => this.addRow(0, {})}
        onBulkAdd={onBulkAdd}
        stopAdd={this.stopAdd}
        stopBulkAdd={this.stopAdd}
        editingIndex={editingIndex}
        onSearch={this.onSearch}
        onDownload={this.handleDownload}
        onDetail={onDetail}
        searchText={searchText}
        filterBy={filters.by}
        filterChips={filters.chips}
        isFilterable={filters.is}
        isDownloadable={isDownloadable}
        isSingle={isSingle}
        isLeadSearch={isLeadSearch}
        cantSearch={cantSearch}
        filterOptions={filters.options}
        onFilter={onFilter}
        isDragable={isDragable}
        hasCreatePermission={hasCreatePermission}
        hasBulkAssignPermission={hasBulkAssignPermission}
        assignBroker={assignBroker}
        onBulkAssign={onBulkAssign}
        changeAssignBroker={handleChangeAssignBroker}
        listBrokers={listBrokers}
        origins={config.origins}
        companies={config.companies}
        avoidRemove={filters.avoidRemove}
        withoutFilter={withoutFilter}
        onUploadTrivoSmartOffers={onUploadTrivoSmartOffers}
      />
    );
  }

  renderPagination() {
    const { config } = this.props;
    const { isDragable, disableRowsOptions } = config;
    const { rowsPerPage, totalItems, current } = config.pagination;
    if (!isDragable) {
      return (
        <TablePagination
          rowsPerPageOptions={disableRowsOptions ? [5] : [5, 10, 20, 30, 40, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Mostrar:"
          labelDisplayedRows={this.displayedRows}
          page={current}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      );
    }
    return null;
  }

  renderHeader() {
    const { config, data } = this.props;
    const { isSelectable, hasEditPermission } = config;
    const { totalItems } = config.pagination;
    const { selected, rows } = this.state; // rows para validar la selección

    const { cols } = data;
    let by = '';
    let direction = '';
    if (config.order) {
      // eslint-disable-next-line prefer-destructuring
      by = config.order.by;
      // eslint-disable-next-line prefer-destructuring
      direction = config.order.direction;
    }
    return (
      <EnhancedTableHead
        numSelected={selected.length}
        direction={direction}
        by={by}
        onSelectAllClick={this.handleSelectAllClick}
        onRequestSort={this.handleRequestSort}
        rowCount={totalItems}
        cols={cols}
        isSelectable={isSelectable}
        hasEditPermission={hasEditPermission}
        renderCheckGLobal={this.validRenderCheck()}
      />
    );
  }

  validRenderCheck() {
    const { selected, rows } = this.state; // rows para validar la selección
    let renderCheckGLobal = true; // check para validar qeu el asesor senior no pueda hacer acciones no permitidas en este caso reasignar un lead que no es suyo
    const profileId = localStorage.getItem('profileId');
    const userIdStorage = localStorage.getItem('user');
    if (
      (window.location.pathname == '/' || window.location.pathname == '/leads') &&
      profileId == ASESOR_ID
    ) {
      rows.forEach((element) => {
        if (element.brokerId !== userIdStorage) {
          renderCheckGLobal = false;
          return;
        }
      });
    }
    return renderCheckGLobal;
  }

  renderTable() {
    const { classes, config, data } = this.props;
    const { isDragable, hasEditPermission } = config;
    const { rowsPerPage, totalItems, current } = config.pagination;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalItems - current * rowsPerPage);
    const { cols } = data;
    if (isDragable) {
      return (
        <div className={classes.tableWrapper}>
          <EnhancedSortableTable
            items={this.state.rows}
            onSortEnd={this.handleSortEnd}
            cols={cols}
            hasEditPermission={hasEditPermission}
            onRequestSort={this.handleRequestSort}
            onDetail={this.handleDetail}
          />
        </div>
      );
    }
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          {this.renderHeader()}
          <TableBody>
            {this.renderBody()}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }

  renderBody() {
    const { rows, editingIndex, isAddingNew } = this.state;
    const { config, data, handlers } = this.props;
    const { hasEditPermission, isSelectable, allowAddToLead, hasRanking } = config;
    const { cols, editCols, newCols } = data;
    const autoFillers = data.autoFillers ? data.autoFillers : {};
    return rows.map((row, rowIndex) => {
      let colsItems = [];
      if (editingIndex !== null) {
        if (editingIndex === rowIndex) {
          if (isAddingNew === true) {
            colsItems = newCols;
          } else {
            colsItems = editCols;
          }
        } else {
          colsItems = cols;
        }
      } else {
        colsItems = cols;
      }
      return (
        <EnhancedTableRow
          key={rowIndex}
          isSelectable={isSelectable}
          isAddingNew={isAddingNew}
          hasAutoFillers={autoFillers !== {}}
          autoFillers={autoFillers}
          isSelected={this.isSelected(row.id)}
          row={row}
          rowIndex={rowIndex}
          cols={colsItems}
          onDetail={this.handleDetail}
          onEvent={this.handleEvent}
          onGetAllLeadsOfUser={handlers.onGetAllLeadsOfUser}
          onDuplicate={this.handleDuplicate}
          onAttach={this.handleAttach}
          onAttributes={this.handleAttributes}
          onSave={this.handleSaveRow}
          onUpdate={this.handleUpdateRow}
          onViewHistory={this.handleViewEventsHistory}
          onClickRow={this.handleClickRow}
          onAddToLead={handlers.onAddToLead}
          allowAddToLead={allowAddToLead}
          hasEditPermission={hasEditPermission}
          onChangeCell={(text, props) => this.handleChangeCell(text, props, rowIndex)}
          openMapModal={this.openMapModal}
          onAddUser={handlers.onAddUser}
          onGenerateCredentials={handlers.onGenerateCredentials}
          renderMockup={handlers.renderMockup}
          onCloneProject={handlers.onCloneProject}
          onProformas={this.onProformas}
          onProformaAdmin={this.onProformaAdmin}
          onAddUnits={handlers.onAddUnits}
        />
      );
    });
  }

  render() {
    const { classes, elevation } = this.props;
    return (
      <Paper className={classes.paper} elevation={elevation}>
        {this.renderToolBar()}
        {this.renderTable()}
        {this.renderPagination()}
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  withoutFilter: PropTypes.object,
  elevation: PropTypes.number,
  data: PropTypes.shape({
    rows: PropTypes.array.isRequired,
    cols: PropTypes.array.isRequired,
    editCols: PropTypes.array,
    newCols: PropTypes.array,
    autoFillers: PropTypes.object,
  }),
  handlers: PropTypes.shape({
    onDetail: PropTypes.func,
    onAttach: PropTypes.func,
    onDownload: PropTypes.func,
    onAttributes: PropTypes.func,
    onAdd: PropTypes.func,
    onAddToLead: PropTypes.func,
    onBulkAdd: PropTypes.func,
    onUpdate: PropTypes.func,
    onViewHistory: PropTypes.func,
    onSearch: PropTypes.func,
    onFilter: PropTypes.func,
    onOrder: PropTypes.func,
    onGetAllLeadsOfUser: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    onDrag: PropTypes.func,
    openMapModal: PropTypes.func,
    onAddUser: PropTypes.func,
    onAddonCloneProjectUser: PropTypes.func,
    onGenerateCredentials: PropTypes.func,
    onChangeCity: PropTypes.func,
    onProformas: PropTypes.func,
    onProformaAdmin: PropTypes.func,
    onAddUnits: PropTypes.func,
    onUploadTrivoSmartOffers: PropTypes.func,
  }),
  config: PropTypes.shape({
    isDragable: PropTypes.bool,
    disableRowsOptions: PropTypes.bool,
    isLeadSearch: PropTypes.bool,
    isBulkable: PropTypes.bool,
    isSortable: PropTypes.bool,
    isDownloadable: PropTypes.bool,
    isSingle: PropTypes.bool,
    cantSearch: PropTypes.bool,
    allowAddToLeads: PropTypes.bool,
    hasCreatePermission: PropTypes.bool,
    hasEditPermission: PropTypes.bool,
    hasBulkAssignPermission: PropTypes.bool,
    dragIndexOnly: PropTypes.bool,
    isSelectable: PropTypes.bool.isRequired,
    isEditableInline: PropTypes.bool,
    searchText: PropTypes.string.isRequired,
    order: PropTypes.shape({
      by: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }),
    pagination: PropTypes.shape({
      current: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired,
    }),
    filters: PropTypes.shape({
      is: PropTypes.bool.isRequired,
      avoidRemove: PropTypes.bool,
      options: PropTypes.array,
      chips: PropTypes.array,
      by: PropTypes.object,
    }),
  }),
};

export default withStyles(styles)(EnhancedTable);
