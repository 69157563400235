import * as taskActions from '../actions/types/tasks';

const initialState = {
  tasks: [],
  dataLength: 0,
  page: 1,
  sending: false,
  message: '',
  error: null,
  hasMore: true,
};

const messages = { onSuccessFetching: 'Historial de tareas obtenidas satisfactoriamente' };

export default (state = initialState, action = {}) => {
  const { tasks = [], error = null, dataLength = 0, sending = false, page = 1, hasMore = true } =
    action.payload || {};

  switch (action.type) {
    case taskActions.GET_HISTORY_TASKS_FIRST_LOAD:
      return { ...state, page, tasks, sending, hasMore };
    case taskActions.GET_HISTORY_TASKS_FAILED:
      return { ...state, error, page, tasks: [], sending, message: '', hasMore };
    case taskActions.GET_HISTORY_TASKS_SENDING:
      return {
        ...state,
        error: null,
        sending,
        page,
        message: '',
        dataLength,
        hasMore,
      };
    case taskActions.GET_HISTORY_TASKS_SUCCESS:
      return {
        ...state,
        error: null,
        sending,
        message: messages.onSuccessFetching,
        page,
        tasks,
        dataLength,
        hasMore,
      };
    default:
      return state;
  }
};
