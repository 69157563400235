import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { styles } from './styles';

const OtherAttributes = (props) => {
  const { formValues, classes, handleInputChange, currentVersion } = props;

  const { quotationObservations, purchaseIntention } = formValues;

  return (
    <Paper className={classes.root}>
      <Typography
        variant="h5"
        color="primary"
        className={classes.headerProformas}
      >
        Información Adicional
      </Typography>
      <Grid container spacing={24}>
        <Grid item className={classes.gridItem} xs={12} md={6}>
          <TextField
            disabled={!!currentVersion}
            label="Observaciones"
            name="quotationObservations"
            value={quotationObservations || ''}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
            multiline
            rows={5}
          />
        </Grid>
        <Grid item className={classes.gridItem} xs={12} md={6}>
          <TextField
            disabled={!!currentVersion}
            label="Texto de intención de compra"
            name="purchaseIntention"
            value={purchaseIntention || ''}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            InputProps={{ className: classes.inputForm }}
            InputLabelProps={{ className: classes.labelForm }}
            multiline
            rows={5}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

OtherAttributes.propTypes = {
  classes: PropTypes.object.isRequired,
  lead: PropTypes.object,
  formValues: PropTypes.object,
  handleInputChange: PropTypes.func,
};

export default withStyles(styles)(OtherAttributes);
