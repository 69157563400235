import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import {styles} from './styles';
import Attributes from './attributes';
import {switchEditingPreferences} from '../../../../actions/leads';

library.add(fas, far, fab);

export class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderAllPreferences = this.renderAllPreferences.bind(this);
    this.handleEditPreferences = this.handleEditPreferences.bind(this);
    this.handleEditAttributes = this.handleEditAttributes.bind(this);
    this.renderLoadingLead = this.renderLoadingLead.bind(this);
  }

  handleEditPreferences() {
    this.props.switchEditingPreferences();
  }

  handleEditAttributes() {
    this.props.onEditAttributes();
  }

  renderAllPreferences() {
    const {classes, lead, allParameters} = this.props;
    if (lead) {
      const {preferences} = lead;
      if (preferences) {
        if (preferences.length > 0) {
          const preferencesSorted = [];
          allParameters.forEach((parameter) => {
            const attribute = preferences.filter(obj => obj.attributeId === parameter.identifier)[0];            
            if (attribute) {
              if (parameter && parameter.options && parameter.options.content 
                && parameter.options.content.length > 0 && attribute.answerValues && attribute.answerValues.length > 0) {
                const validValues = parameter.options.content.filter(x => x.active).map(x => x.value);
                attribute.answerValues = [...new Set(attribute.answerValues)].filter(x => new Set(validValues).has(x));
              }

              if (attribute.answer !== '') {
                preferencesSorted.push(attribute);
              } else if (attribute.answerValues) {
                if (attribute.answerValues.length > 0) {
                  preferencesSorted.push(attribute);
                }
              }
            }
          });
          const tableBody = preferencesSorted.map((preference, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  { preference.icon && <FontAwesomeIcon icon={preference.icon} className={classes.fontAwesomeIcon} color="#111" size="2x" />}
                </TableCell>
                <TableCell>
                  {preference.name || ''}
                </TableCell>
                <TableCell>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    preference.answer !== '' ?
                      preference.answer :
                      preference.answerValues ? 
                        preference.answerValues.join(', ') :
                        ''
                  }
                </TableCell>
              </TableRow>
            );
          });
          return (
            <div className={classes.preferenceContainer}>
              <div style={{width: 'auto', overflowX: 'scroll'}}>
                <Table>
                  <TableBody>
                    {tableBody}
                  </TableBody>
                </Table>
              </div>
            </div>
          );
        }
      }
    }
    return null;
  }

  renderLoadingLead() {
    const {isLoading, lead} = this.props;
    if (
      isLoading === true || 
      lead === null
    ) {
      return (
        <div style={{zIndex: 100, position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)'}}>
          <CircularProgress 
            style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} 
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const {classes, allParameters, isEditingPreferences} = this.props;
    const parameters = allParameters.filter(obj => obj.active === true);
    return (
      <Paper elevation={2} className={classes.paperRoot}>
        {this.renderLoadingLead()}
        <Toolbar>
          <Typography variant="h6">
            Preferencias
          </Typography>
          <div className={classes.spacer} />
          <Tooltip title={isEditingPreferences ? 'Salir de edición' : 'Editar'}>
            <IconButton onClick={this.handleEditPreferences}>
              {
                isEditingPreferences ?
                  <Close />
                  :
                  <Edit />
              }  
                
            </IconButton>
          </Tooltip>
        </Toolbar>
        {
          isEditingPreferences ?
            <Attributes 
              onUpdateAnswer={this.props.onUpdateAnswer}
              allParameters={parameters}
              onEditAttributes={this.handleEditAttributes}
            />
            : this.renderAllPreferences()
        }
      </Paper>
    );
  }
}

Preferences.propTypes = {
  classes: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEditingPreferences: PropTypes.bool,
  allParameters: PropTypes.array.isRequired,
  onUpdateAnswer: PropTypes.func.isRequired,
  switchEditingPreferences: PropTypes.func.isRequired,
  onEditAttributes: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {leads} = state;
  return { 
    lead: leads.lead,
    isEditingPreferences: leads.isEditingPreferences,
    isLoading: leads.sendingLead
  };
};
  
const mapDispatchToProps = {
  switchEditingPreferences
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Preferences));
