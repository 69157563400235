import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Receipt from '@material-ui/icons/Receipt';

import { styles } from './styles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import DialogProformas from './dialogProformas';
import { validCompanyProformaConfig } from '../../actions/proformas';

const Proformas = (props) => {
  const {
    currentProjectValidProforma,
    validProforma,
    validUnits,
  } = useSelector((state) => state.proformas);
  const [openListUnits, setOpenListUnits] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openModalQuotation, setOpenModalQuotation] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const { project } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (project) {
      const { units } = project;
      const unitArray = units.filter((unit) => validUnits.includes(unit._id));
      setChecked(
        unitArray.length > 0 && unitArray[0]._id === units[0]._id
          ? [unitArray[0]._id]
          : [],
      );
    }
  }, [validUnits]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const openModalUnits = async () => {
    const { units } = project;
    await dispatch(
      validCompanyProformaConfig(
        project._id,
        units.map((unit) => unit._id),
      ),
    ).then((result) => {
      if (result.payload.error) {
        showError();
      } else {
        if (units.length > 1) {
          setOpenListUnits(!openListUnits);
        } else {
          setOpenModalQuotation(!openModalQuotation);
        }
      }
    });
  };

  const showError = () => {
    enqueueSnackbar('Error el proyecto no está configurado!', {
      autoHideDuration: 1200,
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const openQuotation = () => {
    if (checked.length > 0) {
      setOpenModalQuotation(true);
      setOpenListUnits(false);
    }
  };

  const renderListUnits = () => {
    const { units } = project;
    if (project._id == currentProjectValidProforma && validProforma) {
      const unitArray = units.filter((unit) => validUnits.includes(unit._id));
      return (
        <Dialog
          onClose={openModalUnits}
          aria-labelledby="simple-dialog-title"
          open={openListUnits}
        >
          <DialogTitle id="simple-dialog-title">
            {unitArray.length > 0
              ? 'Selecciona unidades a cotizar'
              : 'Ninguna unidad está disponible para cotizar'}
          </DialogTitle>
          <List className={classes.root}>
            {unitArray.map((value) => {
              const labelId = `checkbox-list-label-${value._id}`;
              return (
                <ListItem
                  key={value._id}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(value._id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value._id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`Unidad ${value.name}`} />
                </ListItem>
              );
            })}
          </List>
          <div className={classes.footerContained}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                unitArray.length > 0 ? openQuotation() : setOpenListUnits(false)
              }
            >
              {unitArray.length > 0 ? 'Continuar' : 'Entendido'}
            </Button>
          </div>
        </Dialog>
      );
    }
  };

  const { classes } = props;
  return (
    <div className={classes.inlineItem}>
      <Tooltip title="Proforma" className={classes.cursor}>
        <IconButton className={classes.matchButton} onClick={openModalUnits}>
          <Receipt />
        </IconButton>
      </Tooltip>
      {project && renderListUnits()}
      {openModalQuotation && (
        <DialogProformas
          open={openModalQuotation}
          unitsLocal={checked}
          project={project}
          closeDialog={setOpenModalQuotation}
        />
      )}
    </div>
  );
};

Proformas.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  lead: PropTypes.object,
};

export default withStyles(styles)(Proformas);
