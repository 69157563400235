export const styles = theme => ({  
  root: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 1
  },
  title: {
    paddingRight: '16%'
  },
  radioGroup: {
    paddingTop: 8
  },
  radioButton: {
    marginRight: 64,
    '& > .MuiTypography-root-69': {
      fontWeight: 'bold !important;'
    }
  }
});

export default styles;
