import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import {TableHead} from '@material-ui/core';
import EnhancedTableToolbar from './enhancedTableToolbar';
import {styles} from './styles';
import {dateFormat} from '../../../utils/utils';

export class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.renderToolBar = this.renderToolBar.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }

  componentWillMount() {
    let value = 0;
    this.props.data.rows.forEach((element) => {
      value += element.total;
    });
    this.setState({value});
  }

  createSortHandler = property => (event) => {
    this.handleRequestSort(event, property);
  };

  displayedRows = (info) => {
    return (
      <div>{`${info.from} - ${info.to} de ${info.count}`}  </div>
    );
  }

  handleRequestSort(event, property) {
    const {by, direction} = this.props.config.order;
    const orderBy = property;
    let orderDirection = 'desc';
    if (by === property && direction === 'desc') {
      orderDirection = 'asc';
    }
    this.props.handlers.onOrder(orderDirection, orderBy);
  }

  handleChangePage(event, page) {
    this.props.handlers.onChangePage(page);
  }

  handleChangeRowsPerPage(event) {
    this.props.handlers.onChangeRowsPerPage(event.target.value);
  }

  handleChangeStatus(id, e) {
    let status = 'pending';
    if (e.target.checked) {
      status = 'paid';
    }
    this.props.handlers.onChangeStatus(id, status);
  }

  renderToolBar() {
    const {config, handlers, title} = this.props;
    const {value} = this.state;
    const {filters} = config;
    const {onFilter, onSearch} = handlers;
    return (
      <EnhancedTableToolbar 
        title={title} 
        value={value} 
        searchText={config.searchText} 
        filterBy={filters.by}
        filterChips={filters.chips}
        isFilterable={filters.is}
        filterOptions={filters.options}
        onFilter={onFilter}
        onSearch={onSearch}
      />
    );
  }

  renderHeader() {
    const {config, data} = this.props;
    const {by, direction} = config.order;
    const {cols} = data;
    return (
      <TableHead>
        <TableRow>
          {cols.map(col => (
            col.type !== 'bool' ?
              <TableCell
                key={col.id}
                align={col.right ? 'right' : 'left'}
                sortDirection={by === col.id ? direction : false}
              >
                <Tooltip
                  title="Ordenar"
                  placement={col.right ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={by === col.id}
                    direction={direction}
                    onClick={this.createSortHandler(col.id)}
                  >
                    {col.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              :
              <TableCell
                key={col.id}
                align={col.right ? 'right' : 'left'}
                sortDirection={by === col.id ? direction : false}
                style={{paddingRight: 0, width: '5%'}}
              >
                <Tooltip
                  title="Ordenar"
                  placement={col.right ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={by === col.id}
                    direction={direction}
                    onClick={this.createSortHandler(col.id)}
                  >
                    {col.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  renderTable() {
    const {classes, config, data} = this.props;
    const {rows} = data;
    const {rowsPerPage, totalItems, current} = config.pagination;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalItems - (current * rowsPerPage));
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          {this.renderHeader()}
          <TableBody>
            { rows.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell style={{paddingRight: 0, width: '5%'}} >
                    <Checkbox checked={row.status === 'paid'} onChange={(e) => { this.handleChangeStatus(row.id, e); }} />
                  </TableCell>
                  <TableCell style={{paddingRight: 0}}>{row.lead}</TableCell>
                  <TableCell style={{paddingRight: 0}}>{row.beneficiaryType === 'broker' && `${row.beneficiary.name} ${row.beneficiary.lastname}`}</TableCell>
                  <TableCell style={{paddingRight: 0}}>{row.beneficiaryType === 'referrer' && `${row.beneficiary.name} ${row.beneficiary.lastname}`}</TableCell>
                  <TableCell style={{paddingRight: 0}}>{row.builder.name}</TableCell>
                  <TableCell style={{paddingRight: 0}}>{row.project.name}</TableCell>
                  <TableCell style={{paddingRight: 0}}>Unidad {row.unit.name}</TableCell>
                  <TableCell style={{paddingRight: 0}}>{ moment(row.date_created).format(dateFormat)}</TableCell>
                  <TableCell style={{paddingRight: 0}}>
                    {row.total ? Number(row.total).toLocaleString('de-DE', {style: 'currency', currency: 'USD'}) : '$ 0'}
                  </TableCell>
                </TableRow>
              );  
            })
            }
            {emptyRows > 0 && (
              <TableRow style={{height: 49 * emptyRows}}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 100, 200]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Mostrar:"
          labelDisplayedRows={this.displayedRows}
          page={current}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.paper}>
        {this.renderToolBar()}
        {this.renderTable()}
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    rows: PropTypes.array.isRequired,
    cols: PropTypes.array.isRequired
  }),
  handlers: PropTypes.shape({
    onFilter: PropTypes.func,
    onOrder: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    onChangeStatus: PropTypes.func.isRequired
  }),
  config: PropTypes.shape({
    searchText: PropTypes.string.isRequired,
    order: PropTypes.shape({
      by: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired
    }),
    pagination: PropTypes.shape({
      current: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired
    }),
    filters: PropTypes.shape({
      is: PropTypes.bool.isRequired,
      options: PropTypes.array,
      chips: PropTypes.array,
      by: PropTypes.object
    })
  })
};

export default ((withStyles)(styles)(EnhancedTable));
