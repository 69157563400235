export const styles = (theme) => ({
  paperRoot: {
    width: '95%',
    margin: '0 auto',
    marginTop: theme.spacing.unit * 3,
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  title: {
    paddingBottom: theme.spacing.unit * 3,
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableRowTitle: {
    width: 100,
  },
});

export default styles;
