import {lighten} from '@material-ui/core/styles/colorManipulator';
import blue from '@material-ui/core/colors/blue';

export const styles = theme => ({
  paper: {
    width: '100%',
    maxWidth: '100%',
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 1020
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  root: {
    paddingRight: theme.spacing.unit,
    width: '100%',
    maxWidth: '100%',
    marginBottom: '5px'
  },
  chipContainer: {
    paddingRight: 10,
    paddingLeft: 10,
    width: '100%',
    maxWidth: '100%'
  },
  sortableRow: {
    cursor: 'move'
  },
  inputHidden: {
    display: 'none'
  },
  chip: {
    marginBottom: 10
  },
  row: {

  },
  highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 100%'
  },
  search: {
    marginRight: theme.spacing.unit * 3,
    width: theme.spacing.unit * 80
  },
  containerBrokerActions: {
    display: 'flex'
  },
  responsiveBulkSelect: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  popoverContainer: {
    position: 'absolute',
    height: 300,
    width: 300,
    overflowX: 'hidden',
    overflowY: 'scroll',
    background: '#fff',
    zIndex: 100
  }
  // : {
  //   display: 'flex'
  // }
});

export default styles;
