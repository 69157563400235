/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  Grid,
  Button,
  ListItem,
  Avatar,
  ListItemText,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import { getUnitImages } from '../../actions/units';
import { getProjectImages } from '../../actions/projects';
import { getLeadPdf } from '../../actions/leads';
import { getZones } from '../../actions/zones';
import { getUser } from '../../actions/security';
import placeholderImage from '../../assets/placeholder.png';
import { styles } from './styles';
import logo from '../../assets/logotrivo.png';
import slogan from '../../assets/slogan.png';
import help from '../../assets/help.png';
import {
  ME_DA_IGUAL,
  PROJECTS_URL,
  validTrivoCompany,
} from '../../utils/utils';
import { GET_PROJECT_IMAGES_SUCCESS } from '../../actions/types/projects';
import { GET_UNIT_IMAGES_SUCCESS } from '../../actions/types/units';
import CONSTANTS from '../../utils/constants';

export class LeadsPdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lead: null,
      isLoadingCurrent: true,
    };
    this.getZone = this.getZone.bind(this);
    this.renderAmenities = this.renderAmenities.bind(this);
    this.renderMatches = this.renderMatches.bind(this);
    this.renderBrokerPhoto = this.renderBrokerPhoto.bind(this);
    this.handleGetProjectImages = this.handleGetProjectImages.bind(this);
    this.print = this.print.bind(this);
  }

  async componentWillMount() {
    const { match } = this.props;
    if (match) {
      if (match.params) {
        if (match.params.id) {
          await this.props.getZones();
          await this.props.getLeadPdf(match.params.id);
          await this.props.getUser(localStorage.getItem('user'));
          await this.handleGetProjectImages();
        }
      }
    }
  }

  // get ZONE
  getZone(id) {
    const { zones } = this.props;
    if (id) {
      return zones.filter((obj) => obj.id === id)[0].name;
    }
    return null;
  }

  print = () => {
    setTimeout(() => {
      window.print();
    }, 10000);
    return null;
  };

  async handleGetProjectImages() {
    const { lead } = this.props;
    if (lead) {
      if (lead.matches) {
        if (lead.matches.length > 0) {
          for (let i = 0; i < lead.matches.length; i += 1) {
            const match = lead.matches[i];
            await this.props.getProjectImages(match._id).then((result) => {
              if (result.type === GET_PROJECT_IMAGES_SUCCESS) {
                const { images } = result.payload;
                match.images = images;
              }
            });
            if (match.units) {
              if (match.units.length > 0) {
                await this.props
                  .getUnitImages(match.units[0]._id)
                  .then((result) => {
                    if (result.type === GET_UNIT_IMAGES_SUCCESS) {
                      const { images } = result.payload;
                      // eslint-disable-next-line no-param-reassign
                      match.units[0].images = images;
                    }
                  });
              }
            }
            // eslint-disable-next-line no-param-reassign
            lead.matches[i] = match;
          }
          this.setState({ lead, isLoadingCurrent: false });
        }
      }
    }
  }

  renderBrokerPhoto() {
    const { classes, user } = this.props;
    if (user) {
      if (user.profile_img) {
        return (
          <img
            src={user.profile_img}
            alt="trivo"
            className={classes.profileBrokerImg}
          />
        );
      }
    }
    return (
      <img
        src={placeholderImage}
        alt="trivo"
        className={classes.profileBrokerImg}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderAmenities(amenitiesArray) {
    const newAmenitiesArray = [];
    amenitiesArray.forEach((amenitie) => {
      if (amenitie !== ME_DA_IGUAL) {
        newAmenitiesArray.push(amenitie);
      }
    });
    return newAmenitiesArray.length > 0 ? newAmenitiesArray.join(', ') : '-';
  }

  renderMatches() {
    const { classes, user } = this.props;
    const { lead } = this.state;
    if (lead && user) {
      if (lead.matches) {
        console.log("lead.matches",lead.matches);
        if (lead.matches.length > 0) {
          return lead.matches.map((project, index) => {
            const unitToShow = project.units[0];
            const projectImagesToShow = project.images || [];
            const imagesToShow = unitToShow.images || [];
            const pdf = project.brochure ? project.brochure.url : null;
            const fixMargin = (cardIndex) => {
              if (cardIndex < 4 && cardIndex !== 3) return 0;
              if (cardIndex === 3) return 100;
              return 220;
            };
            const dynamicHeigth = index === lead.matches.length - 1 ? 550 : 500;
            return (
              <div
                style={{ height: dynamicHeigth, marginTop: fixMargin(index) }}
                key={index}
              >
                <div
                  style={{
                    marginBottom: 20,
                    marginTop: 40,
                    paddingTop: 30,
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      width: '46%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Divider className={classes.fullWidthDivider} />
                  </div>
                  <div
                    style={{
                      width: '8%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={classes.projectCounterText}
                    >
                      {index + 1}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: '46%',
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <Divider className={classes.fullWidthDivider} />
                  </div>
                </div>
                <div key={index} className={classes.matchContainer}>
                  <div className={classes.leftSide}>
                    <div style={{ height: 480 }}>
                      {imagesToShow.length > 0 ? (
                        <img
                          src={imagesToShow[0].url}
                          alt="match unit"
                          className={classes.matchImage}
                        />
                      ) : (
                        <img
                          src={placeholderImage}
                          alt="match unit"
                          className={classes.matchImage}
                        />
                      )}
                      {validTrivoCompany() && (
                        <Typography className={classes.fontSize}>
                          Unidad: {`TRIVO-${unitToShow.identifier}` || ''}
                        </Typography>
                      )}
                    </div>
                    <div className={classes.imagesContainer}>
                      <img
                        src={
                          projectImagesToShow[0]
                            ? projectImagesToShow[0].url
                            : placeholderImage
                        }
                        alt="match unit"
                        className={classes.infoImage}
                      />
                      <img
                        src={
                          projectImagesToShow[1]
                            ? projectImagesToShow[1].url
                            : placeholderImage
                        }
                        alt="match unit"
                        className={classes.infoImage}
                      />
                    </div>
                    <div className={classes.description}>
                      <Typography className={classes.projectDescription}>
                        {project.description}
                      </Typography>
                      <div className={classes.gridRoot}>
                        <Grid container spacing={0}>
                          {!validTrivoCompany() && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Proyecto"
                                  secondary={project.name}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          {!validTrivoCompany() && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Unidad"
                                  secondary={unitToShow.name}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Tipo"
                                secondary={unitToShow.typeName}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Localidad"
                                secondary={this.getZone(project.zone)}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Área total"
                                secondary={`${unitToShow.area} m2` || '-'}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Fecha de entrega"
                                secondary={project.finish_date || '-'}
                              />
                            </ListItem>
                          </Grid>
                          {unitToShow.rooms && unitToShow.rooms !== '' && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Recámaras"
                                  secondary={unitToShow.rooms || '-'}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          {unitToShow.parkCount && unitToShow.parkCount !== '' && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Estacionamientos"
                                  secondary={unitToShow.parkCount || '-'}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          {!!unitToShow.externalArea && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Terraza/jardín"
                                  secondary={`${unitToShow.externalArea} m2`}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          {!!unitToShow.balconArea && (
                            <Grid item xs={4}>
                              <ListItem dense>
                                <ListItemText
                                  className={classes.gridText}
                                  primary="Balcón"
                                  secondary={`${unitToShow.balconArea} m2`}
                                />
                              </ListItem>
                            </Grid>
                          )}
                          <Grid item xs={4}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridTextPrice}
                                primary="Precio"
                                secondary={`$ ${unitToShow.price.toLocaleString(
                                  'de-DE',
                                )}`}
                              />
                            </ListItem>
                          </Grid>
                          <Grid item xs={12}>
                            <ListItem dense>
                              <ListItemText
                                className={classes.gridText}
                                primary="Amenidades"
                                secondary={this.renderAmenities(
                                  project.amenidades,
                                )}
                              />
                            </ListItem>
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                      {unitToShow.comments &&
                        unitToShow.comments.length > 0 &&
                        unitToShow.comments[unitToShow.comments.length - 1]
                          .comment &&
                        unitToShow.comments[unitToShow.comments.length - 1]
                          .comment !== '' && (
                          <Typography className={classes.commentText}>
                            <span>Comentario: </span>
                            {
                              unitToShow.comments[
                                unitToShow.comments.length - 1
                              ].comment
                            }
                          </Typography>
                        )}
                      {validTrivoCompany() && (
                        <Button
                          component={Link}
                        //  href={`${PROJECTS_URL}${project._id}?unitId=${unitToShow._id}`}
                          href = { project.code ?`${PROJECTS_URL}propiedades/${project.code}` :  `${PROJECTS_URL}`}
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          underline="none"
                        >
                          <InfoIcon className={classes.buttonIcon} />
                          Ver más información
                        </Button>
                      )}
                      {pdf && (
                        <Button
                          component={Link}
                          href={pdf}
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          underline="none"
                        >
                          <InsertDriveFileIcon className={classes.buttonIcon} />
                          Descargar Brochure
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        }
      }
    }
    return null;
  }

  render() {
    const {
      classes,
      user,
      isLoading,
      isLoadingProjects,
      isLoadingUser,
      isLoadingZones,
      isLoadingUnit,
    } = this.props;
    const { lead, isLoadingCurrent } = this.state;
    const company = JSON.parse(localStorage.getItem('company'));
    if (
      lead === null ||
      user === null ||
      isLoading === true ||
      isLoadingCurrent === true ||
      isLoadingProjects === true ||
      isLoadingUser === true ||
      isLoadingZones === true ||
      isLoadingUnit === true
    ) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }

    const firstTextFooter =
      'Indícale a tu asesor tu criterio sobre las opciones para ir a conocer las que más te convencen!';
    const secondTextFooter =
      'Recuerda que trabajar con trivo no tiene costo y te garantizamos las mejores condiciones de compra con cada constructor.';

    return (
      <div>
        {this.print()}
        <div className={classes.container}>
          <div>
            <Grid container spacing={0}>
              <Grid item xs={3} style={{ paddingLeft: 20 }}>
                <div className={classes.logoContainer}>
                  <img
                    src={
                      company && company.layout.logo
                        ? company.layout.logo
                        : logo
                    }
                    alt="trivo"
                    className={classes.logo}
                  />
                  {company && company.layout.slogan && (
                    <img
                      src={company.layout.slogan}
                      alt="trivo"
                      className={classes.slogan}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4" className={classes.title}>
                  ¡Hola {lead.user.name}!
                </Typography>
                <Typography variant="body1" className={classes.subtitle}>
                  {company && company.layout.brochureText}
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.headerBrokerContainer}>
              <div className={classes.headerBroker}>
                {this.renderBrokerPhoto()}
                <div className={classes.titleBrokerContainer}>
                  <Typography variant="body1" className={classes.titleBroker}>
                    {`${user.name} ${user.lastname}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.subtitleBroker}
                  >
                    {user.email}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.subtitleBroker}
                  >
                    {user.phone}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {this.renderMatches()}
        </div>
        <div className={classes.customFooter}>
          <Typography variant="body1" className={classes.footer}>
            {company && company._id === CONSTANTS.TRIVO_COMPANY_ID
              ? `${firstTextFooter} ${secondTextFooter}`
              : firstTextFooter}
          </Typography>
          <img className={classes.tipImage} src={help} alt="trivo" />
        </div>
      </div>
    );
  }
}

LeadsPdf.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingUnit: PropTypes.bool.isRequired,
  isLoadingProjects: PropTypes.bool.isRequired,
  isLoadingUser: PropTypes.bool.isRequired,
  isLoadingZones: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  getLeadPdf: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getZones: PropTypes.func.isRequired,
  getProjectImages: PropTypes.func.isRequired,
  getUnitImages: PropTypes.func.isRequired,
  zones: PropTypes.array.isRequired,
  match: PropTypes.object,
  lead: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { leads, zones, units, user, projects } = state;
  return {
    isLoading: leads.sending,
    isLoadingUser: user.sending,
    isLoadingUnit: units.sending,
    isLoadingZones: zones.sending,
    isLoadingProjects: projects.sending,
    user: user.user,
    lead: leads.lead,
    zones: zones.zones,
  };
};

const mapDispatchToProps = {
  getLeadPdf,
  getUser,
  getZones,
  getUnitImages,
  getProjectImages,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(LeadsPdf));
