/* eslint-disable no-param-reassign */
import axios from 'axios';
import * as unitsActions from './types/units';
import {axiosMaker, errorMessage, copyObject} from '../utils/utils';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const getUnits = (
  page,
  rowsPerPage,
  order,
  orderBy,
  searchText,
  filtersToApply
) => async (dispatch) => {
  if (
    (searchText !== '' && store.getState().units.searchText !== searchText) ||
    (Object.keys(filtersToApply).length &&
      filtersToApply !== store.getState().units.filters)
  ) {
    page = 0;
  }
  if (
    order !== store.getState().units.order ||
    orderBy !== store.getState().units.orderBy
  ) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
    filtersToApply = {};
  }
  dispatch({
    type: unitsActions.GET_UNITS_SENDING,
    payload: {
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText,
      filters: filtersToApply
    }
  });
  try {
    if (company && company._id) {
      filtersToApply.company = [company._id];
    }
    let filters = {};
    filters = copyObject(filtersToApply);
    if (searchText !== '') {
      filters.name = searchText;
    }
    const data = {
      filters,
      operator: Object.keys(filtersToApply).length === 0 ? 'or' : 'and',
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order
      },
      forAdmin: true
    };
    const request = axiosMaker('POST', 'units/filter', data, true);
    const response = await axios(request);
    return dispatch({
      type: unitsActions.GET_UNITS_SUCCESS,
      payload: {
        units: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: unitsActions.GET_UNITS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createUnit = unit => async (dispatch) => {
  dispatch({
    type: unitsActions.ADD_UNIT_SENDING,
    payload: {}
  });
  try {
    const request = axiosMaker('POST', 'units', unit, true);
    await axios(request);
    return dispatch({
      type: unitsActions.ADD_UNIT_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: unitsActions.ADD_UNIT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getUnit = id => async (dispatch) => {
  dispatch({
    type: unitsActions.GET_UNIT_SENDING,
    payload: {}
  });
  try {
    const response = await axios(axiosMaker('GET', `units/${id}`, null, true));
    return dispatch({
      type: unitsActions.GET_UNIT_SUCCESS,
      payload: {
        unit: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: unitsActions.GET_UNIT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getUnitImages = id => async (dispatch) => {
  dispatch({
    type: unitsActions.GET_UNIT_IMAGES_SENDING,
    payload: {}
  });
  try {
    const response = await axios(axiosMaker('GET', `units/${id}/images`, null, true));
    return dispatch({
      type: unitsActions.GET_UNIT_IMAGES_SUCCESS,
      payload: {
        images: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: unitsActions.GET_UNIT_IMAGES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const editUnit = (id, unit) => async (dispatch) => {
  dispatch({
    type: unitsActions.EDIT_UNIT_SENDING,
    payload: {}
  });
  try {
    const options = axiosMaker('PATCH', `units/${id}`, unit, true);
    const response = await axios(options);
    return dispatch({
      type: unitsActions.EDIT_UNIT_SUCCESS,
      payload: {
        unit: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: unitsActions.EDIT_UNIT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const editUnitAttachments = (id, attachments) => async (dispatch) => {
  dispatch({
    type: unitsActions.EDIT_UNIT_ATTACHMENTS_SENDING,
    payload: {}
  });
  try {
    const request = axiosMaker(
      'PATCH',
      `units/${id}/attachments`,
      {attachments},
      true
    );
    await axios(request);

    return dispatch({
      type: unitsActions.EDIT_UNIT_ATTACHMENTS_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: unitsActions.EDIT_UNIT_ATTACHMENTS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const setSold = (id, date) => async (dispatch) => {
  dispatch({
    type: unitsActions.EDIT_UNIT_SENDING,
    payload: {}
  });
  try {
    const options = axiosMaker('PUT', `units/${id}/setSold`, {date}, true);
    await axios(options);
    return dispatch({
      type: unitsActions.EDIT_UNIT_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: unitsActions.EDIT_UNIT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const setAvailable = id => async (dispatch) => {
  dispatch({
    type: unitsActions.EDIT_UNIT_SENDING,
    payload: {}
  });
  try {
    const options = axiosMaker('PUT', `units/${id}/setAvailable`, null, true);
    await axios(options);
    return dispatch({
      type: unitsActions.EDIT_UNIT_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: unitsActions.EDIT_UNIT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const areDisposable = async (id) => {
  try {
    const response = await axios(axiosMaker('POST', 'units/areDisposable', {units: [id]}, true));    
    return response.data.result;
  } catch (e) {
    return false;
  }
};

export const addUnits = (projectId, units) => async (dispatch) => {
  dispatch({
    type: unitsActions.ADD_UNITS_SENDING,
    payload: {}
  });
  try {
    const data = {
      projectId,
      companyId: company && company._id,
      units
    };
    const request = axiosMaker('POST', 'units/addUnits', data, true);
    await axios(request);
    return dispatch({
      type: unitsActions.ADD_UNITS_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: unitsActions.ADD_UNITS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
