import * as buildersActions from '../actions/types/builders';

const initialState = {
  builders: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {builders, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case buildersActions.FILTER_BUILDER_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case buildersActions.FILTER_BUILDER_SUCCESS:
    return {...state, sending: false, builders, error: null, message: 'Constructoras obtenidas satisfactoriamente', dataLength};
  case buildersActions.FILTER_BUILDER_FAILED:
    return {...state, sending: false, builders: [], error, message, dataLength};
  case buildersActions.GET_BUILDER_SENDING:
    return {...state, sending: true};
  case buildersActions.GET_BUILDER_SUCCESS:
    return {...state, sending: false, builders, error: null, message: 'Constructoras obtenidas satisfactoriamente', dataLength};
  case buildersActions.GET_BUILDER_FAILED:
    return {...state, sending: false, builders: [], error, message, dataLength};
  case buildersActions.CREATE_BUILDER_SENDING:
    return {...state, sending: true};
  case buildersActions.CREATE_BUILDER_SUCCESS:
    return {...state, sending: false, error: null, message: 'Constructora creada satisfactoriamente'};
  case buildersActions.CREATE_BUILDER_FAILED:
    return {...state, sending: false, error, message};
  case buildersActions.UPDATE_BUILDER_SENDING:
    return {...state, sending: true};
  case buildersActions.UPDATE_BUILDER_SUCCESS:
    return {...state, sending: false, error: null, message: 'Constructoras actualizada satisfactoriamente'};
  case buildersActions.UPDATE_BUILDER_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
