export const styles = theme => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    height: '100%',
    backgroundColor: '#F5F5F6',
    width: '100%'
  },
  form: {
    width: '100%'
  },
  paperRoot: {
    width: '95%',
    margin: '0 auto'
  },
  submitContainer: {
    display: 'table',
    margin: '0 auto',
    marginTop: 30,
    marginBottom: 20
  },
  tableRowTitle: {
    width: 100
  },
  textInput: {
    marginBottom: theme.spacing.unit * 2
  },
  separator: {
    width: '100%',
    height: 30
  },
  titleType: {
    marginTop: 20,
    marginLeft: 20
  },
  fontAwesomeIcon: {
    marginTop: 20,
    marginLeft: 20
  },
  br: {
    width: '100%',
    height: 20
  }
});

export default styles;
