import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { styles } from './styles';
import CustomerForm from '../customerForm';
import UnitForm from '../unitForm';
import PayForm from '../payForm';
import QuotationTable from '../quotationTable';
import OtherAttributes from '../OtherAttributes';

const Proforma = (props) => {
  const {
    setQuotationInvalid,
    setPayFormInvalid,
    setDocumentInvalid,
    currentVersion,
    formValues,
    handleInputChange,
    unitValues,
    handleUnitChange,
    setSimpleDialog,
    changeUnitManualState
  } = props;

  const { unit } = props;

  return unit && unit.id ? (
    <div>
      <CustomerForm
        formValues={formValues}
        handleInputChange={handleInputChange}
        currentVersion={currentVersion}
        setDocumentInvalid={setDocumentInvalid}
      />
      <UnitForm
        formValues={unitValues}
        handleInputChange={handleUnitChange}
        currentVersion={currentVersion}
        changeUnitManualState={changeUnitManualState}
      />
      <PayForm
        formValues={unitValues}
        handleInputChange={handleUnitChange}
        setPayFormInvalid={setPayFormInvalid}
        currentVersion={currentVersion}
        changeUnitManualState={changeUnitManualState}
      />
      {Object.keys(unitValues).length > 0 && (
        <QuotationTable
          formValues={unitValues}
          handleInputChange={handleUnitChange}
          setQuotationInvalid={setQuotationInvalid}
          currentVersion={currentVersion}
          setSimpleDialog={setSimpleDialog}
        />
      )}
      <OtherAttributes
        formValues={unitValues}
        handleInputChange={handleUnitChange}
        currentVersion={currentVersion}
      />
    </div>
  ) : (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    />
  );
};

Proforma.propTypes = {
  classes: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  lead: PropTypes.object,
  configuration: PropTypes.object,
  setSimpleDialog: PropTypes.func,
};

export default withStyles(styles)(Proforma);
