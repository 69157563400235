
export const styles = () => ({
  datePicker: {
    marginBottom: 20,
    width: '100%',
    maxWidth: 300
  }
});

export default styles;
