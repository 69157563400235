import * as leadStateActions from '../actions/types/leadStates';

const initialState = {
  leadStates: [],
  leadStages: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'order',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {leadStates, leadStages, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case leadStateActions.FILTER_LEAD_STATES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case leadStateActions.FILTER_LEAD_STATES_SUCCESS:
    return {...state, sending: false, leadStates, leadStages, error: null, message: 'Estados de leads obtenidos satisfactoriamente', dataLength};
  case leadStateActions.FILTER_LEAD_STATES_FAILED:
    return {...state, sending: false, leadStates: [], leadStages: [], error, message, dataLength};
  case leadStateActions.GET_LEAD_STATES_SENDING:
    return {...state, sending: true};
  case leadStateActions.GET_LEAD_STATES_SUCCESS:
    return {...state, sending: false, leadStates, leadStages, error: null, message: 'Estados de lead obtenidos satisfactoriamente', dataLength};
  case leadStateActions.GET_LEAD_STATES_FAILED:
    return {...state, sending: false, leadStates: [], leadStages: [], error, message, dataLength};
  case leadStateActions.CREATE_LEAD_STATE_SENDING:
    return {...state, sending: true};
  case leadStateActions.CREATE_LEAD_STATE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Estado de lead creado satisfactoriamente'};
  case leadStateActions.CREATE_LEAD_STATE_FAILED:
    return {...state, sending: false, error, message};
  case leadStateActions.UPDATE_LEAD_STATE_SENDING:
    return {...state, sending: true};
  case leadStateActions.UPDATE_LEAD_STATE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Estado de lead actualizado satisfactoriamente'};
  case leadStateActions.UPDATE_LEAD_STATE_FAILED:
    return {...state, sending: false, error, message};
  case leadStateActions.SWAP_LEAD_STATE_SENDING:
    return {...state, sending: true};
  case leadStateActions.SWAP_LEAD_STATE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Estados de lead ordenados satisfactoriamente'};
  case leadStateActions.SWAP_LEAD_STATE_FAILED:
    return {...state, sending: false, error, message};
  case leadStateActions.SWAP_LEAD_STAGE_SENDING:
    return {...state, sending: true};
  case leadStateActions.SWAP_LEAD_STAGE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Etapas de lead ordenados satisfactoriamente'};
  case leadStateActions.SWAP_LEAD_STAGE_FAILED:
    return {...state, sending: false, error, message};
  case leadStateActions.CREATE_LEAD_STAGE_SENDING:
    return {...state, sending: true};
  case leadStateActions.CREATE_LEAD_STAGE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Etapa de lead creada satisfactoriamente'};
  case leadStateActions.CREATE_LEAD_STAGE_FAILED:
    return {...state, sending: false, error, message};
  case leadStateActions.UPDATE_LEAD_STAGE_SENDING:
    return {...state, sending: true};
  case leadStateActions.UPDATE_LEAD_STAGE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Etapa de lead actualizada satisfactoriamente'};
  case leadStateActions.UPDATE_LEAD_STAGE_FAILED:
    return {...state, sending: false, error, message};
  case leadStateActions.DELETE_LEAD_STAGE_SENDING:
    return {...state, sending: true};
  case leadStateActions.DELETE_LEAD_STAGE_SUCCESS:
    return {...state, sending: false, error: null, message: 'Etapa de lead eliminada satisfactoriamente'};
  case leadStateActions.DELETE_LEAD_STAGE_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
