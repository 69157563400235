import axios from 'axios';
import * as drawerActions from './types/drawer';
import {axiosMakerCSV, errorMessage} from '../utils/utils';

const company = JSON.parse(localStorage.getItem('company'));

export const downloadLeads = () => async (dispatch) => {
  dispatch({
    type: drawerActions.DOWNLOAD_LEADS_SENDING,
    payload: {}
  });
  try {
    const data = {};
    if (company && company._id) data.company = company._id;
    const response = await axios(axiosMakerCSV('POST', 'leads/report', data, true));
    return dispatch({
      type: drawerActions.DOWNLOAD_LEADS_SUCCESS,
      payload: {
        leads: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: drawerActions.DOWNLOAD_LEADS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export default downloadLeads;
