import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { filterCalendar, cancelEvent, updateEvent } from '../../../../actions/calendar';
import { dateFormat, ADMIN } from '../../../../utils/utils';
import { eventsHistoryCols } from '../../../../utils/colTypes';
import CustomSnackbar from '../../../common/customSnackbar';
import EnhancedTable from '../../../common/enhancedTable';
import DropDown from '../../../common/dropdown';
import PropTypes from 'prop-types';
import { styles } from './styles';

const profile = JSON.parse(localStorage.getItem('profile'));

const getProfileIsAdmin = () => {
  return profile.name === ADMIN;
};

const getBrokerId = () => {
  return localStorage.getItem('user');
};

const rowsPerPage = 5;

const EventsHistory = ({ classes = {}, leadId }) => {
  const isAdmin = getProfileIsAdmin();
  const dispatch = useDispatch();

  const { events, page, sending, dataLength: totalItems, error, message } = useSelector(
    (state) => state.eventsHistory,
  );

  const fetchHistoryEvents = (page = 1) =>
    dispatch(filterCalendar(page, rowsPerPage, { lead: leadId }, { status: 1 }, true));

  const handleChangePage = (newPage) => {
    fetchHistoryEvents(newPage + 1);
  };

  const handlerCancelEvent = (eventId) => {
    dispatch(cancelEvent(eventId));
  };

  const handlerEventToHasVisit = (eventId) => {
    dispatch(updateEvent(eventId, { hasVisit: true }));
  };

  const handleChangeRowsPerPage = (newRowsPerPage = rowsPerPage) => {
    console.log('handleChangeRowsPerPage', { newRowsPerPage });
  };

  const handlerOnSearch = () => {};

  const handlers = {
    onSearch: handlerOnSearch,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
  };

  const renderSnackBar = () => {
    if (message) {
      return <CustomSnackbar variant={error !== null ? 'error' : 'success'} message={message} />;
    }

    return null;
  };

  const handlerDropDownClick = (clickId, val) => {
    if (clickId === 'cancel-event') {
      const eventId = val;
      handlerCancelEvent(eventId);
    } else if (clickId === 'event-has-visit') {
      const eventId = val;
      handlerEventToHasVisit(eventId);
    }

    return fetchHistoryEvents(page);
  };

  const getDropDownItems = (event) => [
    {
      id: 'event-has-visit',
      val: event.id,
      disabled: event.status === 'CANCELED' || event.hasVisit,
      label: 'Cita Realizada',
      onClick: handlerDropDownClick,
    },
    {
      id: 'cancel-event',
      val: event.id,
      disabled: event.status === 'CANCELED',
      label: 'Cita Cancelada',
      onClick: handlerDropDownClick,
    },
  ];

  const parseEvent = (event) => {
    const currentDate = moment(event.date);
    const timeAgo = currentDate.fromNow();
    const date = `${currentDate.format(`${dateFormat} HH:mm`)} ${timeAgo && `(${timeAgo})`}`;
    const isCanceled = event.status === 'CANCELED';

    const dropdown = <DropDown options={getDropDownItems(event)} />;

    return { ...event, date, isCanceled, dropdown };
  };

  const getData = () => {
    const brokerCol = {
      id: 'broker',
      right: false,
      disablePadding: false,
      label: 'Usuario',
      type: 'text',
    };

    return {
      cols: isAdmin ? [brokerCol, ...eventsHistoryCols] : eventsHistoryCols,
      rows: events.map((event) => parseEvent(event)),
    };
  };

  const getConfig = () => ({
    cantSearch: true,
    disableRowsOptions: true,
    isDragable: false,
    isSortable: false,
    isSelectable: false,
    isEditableInline: false,
    hasEditPermission: false,
    hasCreatePermission: false,
    pagination: { current: page - 1, rowsPerPage, totalItems },
    filters: { by: {}, chips: [], options: [], is: true },
    searchText: '',
  });

  useEffect(() => {
    fetchHistoryEvents(page);
  }, [leadId]);

  if (sending) {
    return (
      <div className={classes.loadingWrapper}>
        {renderSnackBar()}
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {renderSnackBar()}
      <EnhancedTable
        title="Historial de Citas"
        data={getData()}
        config={getConfig()}
        handlers={handlers}
      />
    </div>
  );
};

EventsHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  leadId: PropTypes.string.isRequired,
};

export default withStyles(styles)(EventsHistory);
