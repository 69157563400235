export const GET_PROVINCES_SUCCESS = 'GET_PROVINCES_SUCCESS';

export const GET_PROVINCES_SENDING = 'GET_PROVINCES_SENDING';

export const GET_PROVINCES_FAILED = 'GET_PROVINCES_FAILED';

export const FILTER_PROVINCES_SUCCESS = 'FILTER_PROVINCES_SUCCESS';

export const FILTER_PROVINCES_SENDING = 'FILTER_PROVINCES_SENDING';

export const FILTER_PROVINCES_FAILED = 'FILTER_PROVINCES_FAILED';

export const CREATE_PROVINCE_SUCCESS = 'CREATE_PROVINCE_SUCCESS';

export const CREATE_PROVINCE_SENDING = 'CREATE_PROVINCE_SENDING';

export const CREATE_PROVINCE_FAILED = 'CREATE_PROVINCE_FAILED';

export const UPDATE_PROVINCE_SUCCESS = 'UPDATE_PROVINCE_SUCCESS';

export const UPDATE_PROVINCE_SENDING = 'UPDATE_PROVINCE_SENDING';

export const UPDATE_PROVINCE_FAILED = 'UPDATE_PROVINCE_FAILED';
