export const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  header: {
    paddingLeft: 0,
    '& .MuiTypography-body1-78': {
      fontWeight: '100', 
      fontSize: 14
    }
  }
});

export default styles;
