export const drawerWidth = 300;

export const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    display: 'block'
  },
  drawerPaperCloseCustom: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: 0
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    '&:focus': {
      textDecoration: 'none'
    }, 
    '&:hover': {
      textDecoration: 'none'
    }, 
    '&:visited': {
      textDecoration: 'none'
    }, 
    '&:link': {
      textDecoration: 'none'
    }, 
    '&:active': {
      textDecoration: 'none'
    }
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
});

export default styles;
