export const GET_LEAD_STATES_SUCCESS = 'GET_LEAD_STATE_SUCCESS';

export const GET_LEAD_STATES_SENDING = 'GET_LEAD_STATE_SENDING';

export const GET_LEAD_STATES_FAILED = 'GET_LEAD_STATE_FAILED';

export const FILTER_LEAD_STATES_SUCCESS = 'FILTER_LEAD_STATES_SUCCESS';

export const FILTER_LEAD_STATES_SENDING = 'FILTER_LEAD_STATE_SENDING';

export const FILTER_LEAD_STATES_FAILED = 'FILTER_LEAD_STATE_FAILED';

export const CREATE_LEAD_STATE_SUCCESS = 'CREATE_LEAD_STATE_SUCCESS';

export const CREATE_LEAD_STATE_SENDING = 'CREATE_LEAD_STATE_SENDING';

export const CREATE_LEAD_STATE_FAILED = 'CREATE_LEAD_STATE_FAILED';

export const UPDATE_LEAD_STATE_SUCCESS = 'UPDATE_LEAD_STATE_SUCCESS';

export const UPDATE_LEAD_STATE_SENDING = 'UPDATE_LEAD_STATE_SENDING';

export const UPDATE_LEAD_STATE_FAILED = 'UPDATE_LEAD_STATE_FAILED';

export const SWAP_LEAD_STATE_SUCCESS = 'SWAP_LEAD_STATE_SUCCESS';

export const SWAP_LEAD_STATE_SENDING = 'SWAP_LEAD_STATE_SENDING';

export const SWAP_LEAD_STATE_FAILED = 'SWAP_LEAD_STATE_FAILED';

export const SWAP_LEAD_STAGE_SUCCESS = 'SWAP_LEAD_STAGE_SUCCESS';

export const SWAP_LEAD_STAGE_SENDING = 'SWAP_LEAD_STAGE_SENDING';

export const SWAP_LEAD_STAGE_FAILED = 'SWAP_LEAD_STAGE_FAILED';

export const CREATE_LEAD_STAGE_SUCCESS = 'CREATE_LEAD_STAGE_SUCCESS';

export const CREATE_LEAD_STAGE_SENDING = 'CREATE_LEAD_STAGE_SENDING';

export const CREATE_LEAD_STAGE_FAILED = 'CREATE_LEAD_STAGE_FAILED';

export const UPDATE_LEAD_STAGE_SUCCESS = 'UPDATE_LEAD_STAGE_SUCCESS';

export const UPDATE_LEAD_STAGE_SENDING = 'UPDATE_LEAD_STAGE_SENDING';

export const UPDATE_LEAD_STAGE_FAILED = 'UPDATE_LEAD_STAGE_FAILED';

export const DELETE_LEAD_STAGE_SUCCESS = 'DELETE_LEAD_STAGE_SUCCESS';

export const DELETE_LEAD_STAGE_SENDING = 'DELETE_LEAD_STAGE_SENDING';

export const DELETE_LEAD_STAGE_FAILED = 'DELETE_LEAD_STAGE_FAILED';
