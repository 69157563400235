/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as propertyTypesActions from './types/propertyTypes';
import store from '../store';

export const filterPropertyTypes = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().propertyTypes.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().propertyTypes.order || orderBy !== store.getState().propertyTypes.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: propertyTypesActions.FILTER_PROPERTY_TYPES_SENDING,
    payload: {
      propertyTypes: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    const filter = {
      filters: {
        name: searchText
      },
      operator: 'or',
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order
      }
    };
    const response = await axios(axiosMaker('POST', 'unitTypes/filter', filter, true));
    return dispatch({
      type: propertyTypesActions.FILTER_PROPERTY_TYPES_SUCCESS,
      payload: {
        propertyTypes: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: propertyTypesActions.FILTER_PROPERTY_TYPES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getPropertyTypes = () => async (dispatch) => {
  dispatch({
    type: propertyTypesActions.GET_PROPERTY_TYPES_SENDING,
    payload: {
      propertyTypes: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {}
    };
    const response = await axios(axiosMaker('POST', 'unitTypes/filter', data, true));
    return dispatch({
      type: propertyTypesActions.GET_PROPERTY_TYPES_SUCCESS,
      payload: {
        propertyTypes: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: propertyTypesActions.GET_PROPERTY_TYPES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createPropertyType = data => async (dispatch) => {
  dispatch({
    type: propertyTypesActions.CREATE_PROPERTY_TYPES_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'unitTypes', data, true));
    return dispatch({
      type: propertyTypesActions.CREATE_PROPERTY_TYPES_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: propertyTypesActions.CREATE_PROPERTY_TYPES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updatePropertyType = (id, propertyType) => async (dispatch) => {
  dispatch({
    type: propertyTypesActions.UPDATE_PROPERTY_TYPES_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `unitTypes/${id}`, propertyType, true));
    return dispatch({
      type: propertyTypesActions.UPDATE_PROPERTY_TYPES_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: propertyTypesActions.UPDATE_PROPERTY_TYPES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
