
export const styles = () => ({
  colorPickerPalette: {
    position: 'absolute',
    zIndex: 100
  },
  colorPickerCover: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  button: {
    width: 100,
    '&:focus': {
      outline: 'none'
    }
  }
});

export default styles;
