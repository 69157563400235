export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';

export const GET_GROUPS_SENDING = 'GET_GROUPS_SENDING';

export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED';

export const FILTER_GROUPS_SUCCESS = 'FILTER_GROUPS_SUCCESS';

export const FILTER_GROUPS_SENDING = 'FILTER_GROUPS_SENDING';

export const FILTER_GROUPS_FAILED = 'FILTER_GROUPS_FAILED';

export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';

export const CREATE_GROUP_SENDING = 'CREATE_GROUP_SENDING';

export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';

export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';

export const UPDATE_GROUP_SENDING = 'UPDATE_GROUP_SENDING';

export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';
