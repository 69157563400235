/* eslint-disable no-alert */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import {
  filterCompanies,
  createCompany,
  updateCompany,
  validateCompany,
} from '../../../actions/companies';
import { bulkBuilders } from '../../../actions/builders';
import { getProfile, getProfiles } from '../../../actions/profiles';
import EnhancedTable from '../../common/enhancedTable';
import { companiesCols } from '../../../utils/colTypes';
import {
  flattenArray,
  getIdentifier,
  haveSameContents,
} from '../../../utils/utils';
import CustomSnackbar from '../../common/customSnackbar';
import CompanyDetail from './detail';
import { getIdentificationTypes } from '../../../actions/identificationTypes';
import AddUser from './addUser';
import OauthCredentials from './oauthCredentials';
import { createUser } from '../../../actions/users';
import {
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  USER_ALREADY_EXISTS,
} from '../../../actions/types/users';
import { createClient, updateClient } from './../../../actions/oauth';
import { getPlans } from './../../../actions/plans';
import { getOrigins } from './../../../actions/origins';

export class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: null,
      isNew: false,
      title: 'Empresas',
      open: false,
      openIntegration: false,
      companyId: null,
      userSnackbar: {
        show: false,
        type: '',
        message: '',
      },
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleUpdateCompany = this.handleUpdateCompany.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
    this.onAddUser = this.onAddUser.bind(this);
    this.onGenerateCredentials = this.onGenerateCredentials.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.createUser = this.createUser.bind(this);
    this.generateOauthCredentials = this.generateOauthCredentials.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.filterCompanies(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
    );
    this.props.getIdentificationTypes();
    this.props.getProfiles();
    this.props.getPlans();
    this.props.getOrigins();
  }

  onAdd() {
    this.setState({ isNew: true, company: {} });
  }

  onDetail(id) {
    const company = this.props.companies.filter((obj) => obj.id === id)[0];
    this.setState({ company, isNew: false });
  }

  onAddUser(id) {
    this.setState({ open: true });
    this.setState({ companyId: id });
  }

  onGenerateCredentials(id) {
    this.setState({ openIntegration: true });
    this.setState({ companyId: id });
  }

  onOrder(order, orderBy) {
    this.props.filterCompanies(
      this.props.page,
      this.props.rowsPerPage,
      order,
      orderBy,
      this.props.searchText,
    );
  }

  onSearch(searchText) {
    this.props.filterCompanies(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      searchText,
    );
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_companies) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  };

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_profiles) {
        return true;
      }
    }
    return false;
  };

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_profiles) {
        return true;
      }
    }
    return false;
  };

  closeDialog() {
    this.setState({ open: false, openIntegration: false });
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterCompanies(
      this.props.page,
      rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
    );
  }

  handleChangePage(page) {
    this.props.filterCompanies(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
    );
  }

  handleGoBack() {
    this.props.filterCompanies(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
    );
    this.setState({ company: null, isNew: false });
  }

  prepareObject = (company, companyEdited) => {
    const edited = {};

    edited.subscribedEmails = companyEdited.subscribedEmails;
    edited.plusvaliaCode = companyEdited.plusvaliaCode;
    edited.properatiCode = companyEdited.properatiCode;
    edited.avalMessages = companyEdited.avalMessages;

    if (companyEdited.name !== company.name) {
      edited.name = companyEdited.name;
      edited.identifier = getIdentifier(companyEdited.name);
    }
    if (companyEdited.status !== company.status) {
      edited.status = companyEdited.status;
    }
    if (companyEdited.businessReason !== company.businessReason) {
      edited.businessReason = companyEdited.businessReason;
    }
    if (companyEdited.identification !== company.identification) {
      edited.identification = companyEdited.identification;
    }
    if (companyEdited.identificationType.id !== company.identificationType.id) {
      edited.identificationType = companyEdited.identificationType.id;
    }
    if (companyEdited.hasTrivoSmart !== company.hasTrivoSmart) {
      edited.hasTrivoSmart = companyEdited.hasTrivoSmart;
    }
    if (companyEdited.plan !== company.plan) {
      edited.plan = companyEdited.plan;
    }
    if (
      companyEdited.configuration.smtp.domain !==
        company.configuration.smtp.domain ||
      companyEdited.configuration.smtp.status !==
        company.configuration.smtp.status
    ) {
      edited.configuration = companyEdited.configuration;
    }
    if (
      JSON.stringify(companyEdited.layout.colors) !==
        JSON.stringify(company.layout.colors) ||
      companyEdited.layout.logo !== company.layout.logo ||
      companyEdited.layout.slogan !== company.layout.slogan ||
      companyEdited.layout.brochureText !== company.layout.brochureText
    ) {
      edited.layout = companyEdited.layout;
    }
    if (
      !haveSameContents(
        companyEdited.configuredSectors,
        company.configuredSectors,
      )
    ) {
      edited.configuredSectors = companyEdited.configuredSectors;
    }

    return edited;
  };

  handleUpdateCompany(companyEdited) {
    const { company } = this.state;
    const edited = this.prepareObject(company, { ...companyEdited });
    const now = new Date();
    const updatedAt = now.toISOString();
    const updatedBy = { email: localStorage.getItem('userEmail') };
    this.setState({
      company: {
        ...this.state.company,
        ...companyEdited,
        updatedAt,
        updatedBy,
      },
    });

    const { updated, setNulls } = companyEdited.buildersChanges;
    if (updated.length + setNulls.length > 0) {
      this.props.bulkBuilders(updated, setNulls, companyEdited.id);
    }
    this.props.updateCompany(companyEdited.id, edited);
  }

  async createUser(user) {
    const newUser = { ...user, company: this.state.companyId };
    const { type } = await this.props.createUser(newUser);

    if (type === CREATE_USER_SUCCESS) {
      this.setState({
        userSnackbar: {
          show: true,
          type: 'success',
          message: 'Usuario creado satisfactoriamente',
        },
      });
      this.closeDialog();
      this.setState({ companyId: null });
    }
    if (type === USER_ALREADY_EXISTS) {
      this.setState({
        userSnackbar: {
          show: true,
          type: 'error',
          message: 'El correo electrónico ingresado ya existe',
        },
      });
    }
    if (type === CREATE_USER_FAILED) {
      this.setState({
        userSnackbar: {
          show: true,
          type: 'success',
          message: 'Error al crear el usuario',
        },
      });
    }
    this.setState({ userSnackbar: { show: false, type: '', message: '' } });
  }

  generateOauthCredentials(data, isNew) {
    const request = {
      facebookIntegrations: data,
      company: this.state.companyId,
    };

    if (isNew) return createClient(request);
    else return updateClient(request);
  }

  renderSnackBar() {
    const { error, message } = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {
      isLoading,
      isLoadingProfile,
      companies,
      dataLength,
      profiles,
      plans,
    } = this.props;
    const {
      title,
      company,
      isNew,
      open,
      openIntegration,
      userSnackbar,
    } = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();

    const data = {
      rows: companies.length > 0 ? flattenArray(companies) : [],
      cols: companiesCols,
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      onAddUser: this.onAddUser,
      onGenerateCredentials: this.onGenerateCredentials,
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order,
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength,
      },
      filters: {
        is: false,
      },
    };
    if (isLoading === true || isLoadingProfile === true) {
      return (
        <CircularProgress
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
          }}
        />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {userSnackbar.show && (
          <CustomSnackbar
            variant={userSnackbar.type}
            message={userSnackbar.message}
          />
        )}
        {company === null ? (
          <EnhancedTable
            title={title}
            data={data}
            handlers={handlers}
            config={config}
          />
        ) : (
          <CompanyDetail
            company={company}
            identificationTypes={this.props.identificationTypes}
            handleGoBack={this.handleGoBack}
            handleCreateCompany={this.props.createCompany}
            handleUpdateCompany={this.handleUpdateCompany}
            handleValidateCompany={this.props.validateCompany}
            isLoading={isLoading}
            isNew={isNew}
            plans={plans}
          />
        )}
        {open && (
          <AddUser
            closeDialog={this.closeDialog}
            isLoading={isLoading}
            profiles={profiles}
            createUser={this.createUser}
          />
        )}
        {openIntegration && (
          <OauthCredentials
            closeDialog={this.closeDialog}
            openIntegration={openIntegration}
            generateOauthCredentials={this.generateOauthCredentials}
            companyId={this.state.companyId}
            companyName={
              companies.find((item) => item.id === this.state.companyId).name
            }
            origins={this.props.origins}
          />
        )}
      </div>
    );
  }
}

Companies.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  isLoadingProfile: PropTypes.bool,
  filterCompanies: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  bulkBuilders: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number.isRequired,
  error: PropTypes.object,
  getIdentificationTypes: PropTypes.func.isRequired,
  identificationTypes: PropTypes.array.isRequired,
  createUser: PropTypes.func.isRequired,
  getProfiles: PropTypes.func.isRequired,
  profiles: PropTypes.array.isRequired,
  validateCompany: PropTypes.func.isRequired,
  getPlans: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired,
  getOrigins: PropTypes.func.isRequired,
  origins: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { companies, identificationTypes, profiles, plans, origins } = state;
  return {
    isLoading: companies.sending,
    companies: companies.companies,
    dataLength: companies.dataLength,
    error: companies.error,
    message: companies.message,
    searchText: companies.searchText,
    order: companies.order,
    orderBy: companies.orderBy,
    page: companies.page,
    rowsPerPage: companies.rowsPerPage,
    filters: companies.filters,
    identificationTypes: identificationTypes.identificationTypes,
    isLoadingProfiles: profiles.sending,
    profiles: profiles.profiles,
    plans: plans.plans,
    origins: origins.origins
  };
};

const mapDispatchToProps = {
  filterCompanies,
  createCompany,
  updateCompany,
  getProfile,
  getIdentificationTypes,
  createUser,
  getProfiles,
  validateCompany,
  bulkBuilders,
  getPlans,
  getOrigins
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
