/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import FunnelGraph from 'funnel-graph-js';
import {dashboardStatus, colors} from '../../../utils/utils';
import './styles.css';

class Funnel extends React.Component {
  static propTypes = {
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: {}
    };
    this.funnelRef = React.createRef();
  }

  componentWillMount() {
    const {values, originsToFunnel} = this.props;
    if (originsToFunnel.length > 0) {
      const newOrigins = originsToFunnel.map(newOrigin => (newOrigin.name));
      const builderSession = localStorage.getItem('builder');
      // eslint-disable-next-line no-empty
      if (builderSession === 'false') { } else {
       import('./stylesBuilder.css');
      }
      const customValues = [];
      for (let i = 0; i < values.length; i += 1) {
        const value = values[i];
        if (value.filter(obj => obj > 0)[0]) {
          customValues.push(value);
        } else {
          break;
        }
      }
      const data = {
        labels: dashboardStatus.slice(0, customValues.length),
        subLabels: newOrigins,
        colors,
        values: customValues
      };
      this.setState({data, isLoading: false});
    }
  }

  componentDidMount() {
    const {height, width} = this.props;
    const {data, isLoading} = this.state;
    
    if (isLoading === false) {
      this.graph = new FunnelGraph({
        container: `.${this.funnelRef.current.className}`,
        direction: 'vertical',
        gradientDirection: 'vertical',
        displayPercent: true,
        height,
        width, 
        data,
        subLabelValue: 'percent'
      });

      this.graph.draw();
    }
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <div />
      );
    }
    return (
      <div>
        <div className="funnel" ref={this.funnelRef} />
      </div>
    );
  }
}

Funnel.propTypes = {
  values: PropTypes.array.isRequired,
  originsToFunnel: PropTypes.array.isRequired
};

export default Funnel;
