import React, { useEffect, useState } from 'react';
import { Button, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { styles } from './styles';

const SmartTips = (props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {}, []);

  const handleClose = () => {
    setOpen(false);
  };

  const { classes } = props;

  return (
    <div>
      <Button className={classes.tipsButton} variant="contained" onClick={() => setOpen(true)} color="primary">
        Smart Tips
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
          Smart Tips
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ul>
              <li>
                Si filtras uno o varios sectores y no se visualizan en el tablero, es porque no hay
                proyectos en el mercado que coincidan con el criterio de búsqueda seleccionado
              </li>
              <li>
                Recuerda para realizar un análisis más profundo del mercado inmobiliario,
                seleccionar los Sectores Complementarios al sector de tu Proyecto
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

SmartTips.propTypes = {};

export default withStyles(styles)(SmartTips);
