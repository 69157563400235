/* eslint-disable no-param-reassign */
import axios from 'axios';
import { axiosMaker, errorMessage, haveSameContents } from '../utils/utils';
import * as dashboardActions from './types/dashboard';
import { getAsesors } from './users';

export const getDashboard = (filters, lastBrokers) => async (dispatch, getState) => {
  dispatch({
    type: dashboardActions.GET_DASHBOARD_SENDING,
    payload: {
      filters,
    },
  });
  try {
    console.log("filters, lastBrokers",filters.brokers, lastBrokers);
    const company = JSON.parse(localStorage.getItem('company'));
    let companyId = null;
    if (company) {
      companyId = company._id;
    }
// TODO VALID DATA
   const getPipelineData = !haveSameContents(lastBrokers, filters.brokers);
   //const getPipelineData = true;

    const request = axiosMaker(
      'POST',
      'dashboard',
      { ...filters, companyId, getPipelineData },
      true,
    );
    const response = await axios(request);

    dispatch({
      type: dashboardActions.CHANGE_FILTERS,
      payload: {
        filters,
      },
    });

    const {
      dashboard: { pipelineAnalysis, funnel },
    } = getState().dashboard;

    return dispatch({
      type: dashboardActions.GET_DASHBOARD_SUCCESS,
      payload: {
        dashboard: {
          ...response.data,
          pipelineAnalysis: getPipelineData && response.data.pipelineAnalysis || pipelineAnalysis,
          funnel: getPipelineData && response.data.funnel || funnel,
        },
      },
    });
  } catch (e) {
    console.log('e: ', e);
    const error = e.response;
    console.log('error: ', error);
    return dispatch({
      type: dashboardActions.GET_DASHBOARD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getDashboardAndUsers = (filters) => async (dispatch) => {
  dispatch({
    type: dashboardActions.GET_DASHBOARD_SENDING,
    payload: {
      filters,
    },
  });
  await dispatch(getAsesors);
  try {
    const company = JSON.parse(localStorage.getItem('company'));
    let companyId = null;
    if (company) {
      companyId = company._id;
    }
    const request = axiosMaker('POST', 'dashboard', { ...filters, companyId }, true);
    const response = await axios(request);
    console.log('response: ', response);

    dispatch({
      type: dashboardActions.CHANGE_FILTERS,
      payload: {
        filters,
      },
    });

    return dispatch({
      type: dashboardActions.GET_DASHBOARD_SUCCESS,
      payload: {
        dashboard: response.data,
      },
    });
  } catch (e) {
    console.log('e: ', e);
    const error = e.response;
    console.log('error: ', error);
    return dispatch({
      type: dashboardActions.GET_DASHBOARD_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export const getRanking = (filters) => async (dispatch) => {
  dispatch({
    type: dashboardActions.GET_RANKING_SENDING,
    payload: {
      rankingFilters: filters,
    },
  });
  const builderSession = localStorage.getItem('builder');
  const company = JSON.parse(localStorage.getItem('company'));
  let companyId = null;
  if (company) {
    companyId = company._id;
  }
  if (builderSession !== 'false') {
    delete filters.builder;
  }
  try {
    const request = axiosMaker('POST', 'historic/ranking', { filters, companyId }, true);
    const response = await axios(request);
    console.log('response: ', response);
    return dispatch({
      type: dashboardActions.GET_RANKING_SUCCESS,
      payload: {
        ranking: response.data.ranking,
      },
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: dashboardActions.GET_RANKING_FAILED,
      payload: {
        error,
        message: errorMessage(error),
      },
    });
  }
};

export default getDashboard;
