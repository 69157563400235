import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styles } from './styles';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const AppointmentSelectorModal = ({ rescheduleEvent, onClose, classes = {} }) => {
  const { events, sending } = useSelector((state) => state.leadActiveEvents);

  const handlerRescheduleEvent = (eventId) => {
    rescheduleEvent(eventId);
    return onClose();
  };

  const renderDate = (date) => {
    return `Fecha: ${moment(date).format('LLLL')}`;
  };

  const renderEventsList = () => (
    <List sx={{ pt: 0 }}>
      {events.map((event, index) => (
        <ListItem
          button
          key={`${event.id}-${index}`}
          className={classes.item}
          onClick={() => handlerRescheduleEvent(event.id)}
        >
          <div className={classes.itemTask}>
            <div className={classes.itemTaskContent}>
              <div className={classes.taskTitle}> {event.title}</div>
              <div className={classes.taskSubtitle}>{event.lead}</div>
              <div className={classes.date}>{renderDate(event.date)}</div>
              <div className={classes.projects}>
                <strong>Desarrolos: </strong>
                {event.projects}
              </div>
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  );

  const renderCircularProgress = () => (
    <div className={classes.center}>
      <CircularProgress />
    </div>
  );

  return (
    <Dialog open TransitionComponent={Transition} keepMounted onClose={onClose}>
      <DialogTitle>Escoge el evento que quieres reagendar</DialogTitle>
      <DialogContent>{sending ? renderCircularProgress() : renderEventsList()}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AppointmentSelectorModal.propTypes = {
  classes: PropTypes.object.isRequired,
  rescheduleEvent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(AppointmentSelectorModal);
