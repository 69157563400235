export const styles = theme => ({
  filterSelect: {
    width: '95%',
    margin: '0 auto',
    display: 'table',
    marginBottom: theme.spacing.unit * 2
  },
  datePicker: {
    width: '100%'
  }
});

export default styles;
