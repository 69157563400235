import * as plansActions from '../actions/types/plans';

const initialState = {
  plans: [],
  graphs: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: '',
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {
    message,
    error,
    plans,
    dataLength,
    page,
    rowsPerPage,
    order,
    orderBy,
    searchText,
    graphs
  } = action.payload;
  switch (action.type) {
    case plansActions.GET_PLANS_SENDING:
      return { ...state, sending: true };
    case plansActions.GET_PLANS_SUCCESS:
      return {
        ...state,
        sending: false,
        plans,
        error: null,
        message: 'Planes obtenidos satisfactoriamente',
      };
    case plansActions.GET_PLANS_FAILED:
      return { ...state, sending: false, plans: [], error, message };
    case plansActions.GET_GRAPHS_SENDING:
      return { ...state };
    case plansActions.GET_GRAPHS_SUCCESS:
      return {
        ...state,
        graphs,
        error: null,
        message: null
      };
    case plansActions.GET_GRAPHS_FAILED:
      return { ...state, graphs: [], error, message };
    case plansActions.GET_FILTER_PLANS_SENDING:
      return { ...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength };
    case plansActions.GET_FILTER_PLANS_SUCCESS:
      return {
        ...state,
        sending: false,
        plans,
        error: null,
        message: 'Planes obtenidos satisfactoriamente',
        dataLength,
      };
    case plansActions.GET_FILTER_PLANS_FAILED:
      return { ...state, sending: false, plans: [], error, message, dataLength };
    default:
      return state;
  }
};
