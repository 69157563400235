import React, {Component} from 'react';
import {TextField} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {DatePicker} from 'material-ui-pickers';
import Uploader from '../../../../../common/uploader';
import {dateFormat, isPhone} from '../../../../../../utils/utils';

export class TypeOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderOptions = this.renderOptions.bind(this);
  }

  getDatePicker(contentIndex, key, keyIndex, keyValue, value) {
    return (
      <DatePicker 
        value={value !== '' ? value : moment().format(dateFormat)} 
        key={keyIndex}
        fullWidth
        onChange={e => this.props.handleChangeTypeValue(e, true, contentIndex, key)} 
        format={dateFormat}
        label={keyValue}
      />
    );    
  }

  getImagePicker(contentIndex, keyIndex, value) {
    return (
      <div key={keyIndex}>
        {
          value !== '' &&
          <a href={value} rel="noopener noreferrer" target="_blank"><img src={value} alt="Imagen" style={{width: 100}} /></a>
        }
        <Uploader 
          buttonText={value !== '' ? 'Modificar imagen' : 'Subir imagen'} 
          dirName="Parameters" 
          photo
          uploaderId={contentIndex.toString()} 
          urlUploaded={this.props.handleUrlUploaded}
        />
      </div>
    );
  }

  getTextField(contentIndex, key, keyIndex, keyValue, value, isRange, order = null) {
    return (
      <TextField
        fullWidth
        label={order === null ? keyValue : order}
        key={keyIndex}
        value={value}
        multiline
        onChange={
          e => (isRange ?
            isPhone(e.target.value) &&
                                this.props.handleChangeTypeValue(e, false, contentIndex, key)
            :
            this.props.handleChangeTypeValue(e, false, contentIndex, key))
        }
      />
    );
  }

  renderOptions(contentIndex, key, keyIndex, keyValue, value, answerId) {
    switch (key) {
    case 'min_date':
      return this.getDatePicker(contentIndex, key, keyIndex, keyValue, value);
    case 'max_date':
      return this.getDatePicker(contentIndex, key, keyIndex, keyValue, value);
    case 'min_val':
      return this.getTextField(contentIndex, key, keyIndex, keyValue, value, true, answerId);
    case 'max_val':
      return this.getTextField(contentIndex, key, keyIndex, keyValue, value, true, answerId);
    case 'url':
      return this.getImagePicker(contentIndex, keyIndex, value);
    case 'value':
      return this.getTextField(contentIndex, key, keyIndex, keyValue, value, false, answerId);
    case 'placeholder':
      return this.getTextField(contentIndex, key, keyIndex, keyValue, value, false);
    default:
      return null;
    }
  }

  render() {
    const {properties, content, contentIndex} = this.props;
    return properties.map((property) => {
      const keys = Object.keys(property);
      const values = Object.values(property);
      return keys.map((key, keyIndex) => {
        const keyValue = values[keyIndex];
        const value = content[contentIndex][key];
        let {order} = content[contentIndex];
        if (order) order = `orden ${order}`;
        return this.renderOptions(contentIndex, key, keyIndex, keyValue, value, order);
      });
    });
  }
}

TypeOptions.propTypes = {
  contentIndex: PropTypes.number.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChangeTypeValue: PropTypes.func.isRequired,
  handleUrlUploaded: PropTypes.func.isRequired
};

export default TypeOptions;
