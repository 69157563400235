import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { styles } from './styles';
import ConfirmationDialog from '../../common/confirmationDialog';
import {
  isEmail,
  isPhone,
  ASESOR_ID,
  ADMIN_ID,
  ASESOR_JR_ID,
  BUILDER_ID,
  MANDATO_ID,
  STRATEGIC_PARTNER_ID,
  isNumber,
  SUPER_ADMIN_ROL_ID,
  IDENTIFICATION_CARD_ID,
  COMERCIAL_MANAGER
} from '../../../utils/utils';
import Uploader from '../../common/uploader';
import CollapsableZones from '../../common/collapsableZones';
import verifyIdentification from '../../../utils/validations/validateIdentification';

export class UsersDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      isBroker: false,
      isBuilder: false,
      builder: '',
      email: '',
      name: '',
      lastname: '',
      phone: '',
      commissionPercentage: '',
      profileImg: '',
      profile: '',
      userAsessors: [],
      zones: [],
      isStrategicPartnerProfile: false,
      strategicPartner: '',
      company: '',
      trivoSmartPermission: false,
      document: ''
    };
    this.renderButton = this.renderButton.bind(this);
    this.renderZones = this.renderZones.bind(this);
    this.renderBuilder = this.renderBuilder.bind(this);
    this.renderCommissionPercentage = this.renderCommissionPercentage.bind(
      this,
    );
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
    this.renderResetPasswordButton = this.renderResetPasswordButton.bind(this);
    this.onResetPasswordClick = this.onResetPasswordClick.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeLastName = this.handleChangeLastName.bind(this);
    this.handleChangeCommissionPercentage = this.handleChangeCommissionPercentage.bind(
      this,
    );
    this.handleChangeIsBroker = this.handleChangeIsBroker.bind(this);
    this.handleChangeIsBuilder = this.handleChangeIsBuilder.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleChangeZone = this.handleChangeZone.bind(this);
    this.handleChangeBuilder = this.handleChangeBuilder.bind(this);
    this.handleChangeProfile = this.handleChangeProfile.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChangeAsessors = this.handleChangeAsessors.bind(this);
   // this.renderSelectValue = this.renderSelectValue.bind(this);
    this.renderAssessors = this.renderAssessors.bind(this);
    this.handleChangeStrategicPartner = this.handleChangeStrategicPartner.bind(
      this,
    );
    this.handleChangeDocument = this.handleChangeDocument.bind(this);
  }

  componentWillMount() {
    const { isNew, user } = this.props;

    const profileId = localStorage.getItem('profileId');
    let { company } = user;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      const companyStored = JSON.parse(localStorage.getItem('company'));
      company = companyStored ? companyStored._id : '';
      this.setState({ company });
    }

    if (isNew === false) {
      this.setState({
        user,
        name: user.name,
        lastname: user.lastname,
        profile: user.profile,
        zones: user.zones ? user.zones : [],
        commissionPercentage: user.commissionPercentage
          ? user.commissionPercentage
          : '',
        email: user.email,
        phone: user.phone,
        profileImg: user.profile_img,
        active: user.active,
        isBroker: user.is_asesor,
        isBuilder: user.is_builder,
        builder: user.builder,
        isStrategicPartnerProfile: user.profile === STRATEGIC_PARTNER_ID,
        strategicPartner: user.strategicPartner,
        company,
        trivoSmartPermission: user.trivoSmartPermission || false,
        document: user.document || '',
        userAsessors: user.advisers ? user.advisers : []
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { isNew, user } = nextProps;
      if (isNew === false) {
        this.setState({
          user,
          name: user.name,
          lastname: user.lastname,
          profile: user.profile,
          zones: user.zones ? user.zones : [],
          commissionPercentage: user.commissionPercentage
            ? user.commissionPercentage
            : '',
          email: user.email,
          phone: user.phone,
          profileImg: user.profile_img,
          active: user.active,
          isBroker: user.is_asesor,
          isBuilder: user.is_builder,
          document: user.document || '',
          userAsessors:user.advisers
        });
      }
    }
  }

  onResetPasswordClick() {
    const { user } = this.props;
    this.props.resetPassword({ id: user.id, email: user.email });
  }

  onAddButtonClick() {
    const {
      email,
      name,
      lastname,
      phone,
      profileImg,
      profile,
      zones,
      isBroker,
      commissionPercentage,
      isBuilder,
      builder,
      strategicPartner,
      company,
      trivoSmartPermission,
      document, 
      userAsessors
    } = this.state;
    this.props.createUser({
      email,
      name,
      lastname,
      phone,
      profile,
      zones,
      profileImg,
      commissionPercentage,
      isBroker,
      isBuilder,
      builder,
      strategicPartner,
      company,
      trivoSmartPermission,
      document,
      advisers:userAsessors
    });
  }

  onEditButtonClick() {
    const {
      user,
      active,
      name,
      lastname,
      email,
      profileImg,
      phone,
      profile,
      zones,
      isBroker,
      commissionPercentage,
      isBuilder,
      builder,
      strategicPartner,
      company,
      trivoSmartPermission,
      document,
      userAsessors
    } = this.state;
    const userEdited = {
      id: user.id,
      active,
      name,
      lastname,
      email,
      profile,
      zones,
      profile_img: profileImg,
      commissionPercentage,
      phone,
      is_asesor: isBroker,
      is_builder: isBuilder,
      builder,
      strategicPartner,
      company,
      trivoSmartPermission,
      document,
      advisers: userAsessors
    };
    this.props.editUser(userEdited);
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  handleChangeZone(zones) {
    this.setState({ zones });
  }

  handleChangeAsessors(e) {
    this.setState({ userAsessors: e.target.value });
  }

  renderSelectValue() {
    const { classes, asessors } = this.props;
    const values = this.state.userAsessors;
    console.log("assesors here", asessors
    );
    console.log("values here", values );
    const asessorsFiltered = asessors.filter((asessor) =>
    values.includes(asessor.id),
    );
    console.log("asessorsFiltered",asessorsFiltered);
    return asessorsFiltered.map((asessor, index) => (
      <div key={index}>
        <Chip label={asessor.name.toString()} className={classes.chip} />
      </div>
    ));
  }


  handleChangeBuilder(e) {
    this.setState({ builder: e.target.value });
  }

  handleChangeCommissionPercentage(e) {
    if (isNumber(e.target.value)) {
      this.setState({ commissionPercentage: e.target.value });
    }
  }

  handleUrlUploaded(profileImg) {
    this.setState({ profileImg: profileImg === false ? '' : profileImg });
  }

  handleChangeName(e) {
    const name = e.target.value;
    this.setState({ name });
  }

  handleChangeLastName(e) {
    const lastname = e.target.value;
    this.setState({ lastname });
  }

  handleChangeProfile(e) {
    const profile = e.target.value;
    this.setState({
      isStrategicPartnerProfile: profile === STRATEGIC_PARTNER_ID,
    });
    if (
      profile === ASESOR_ID ||
      profile === ASESOR_JR_ID ||
      profile === MANDATO_ID
    ) {
      this.setState({ profile, isBroker: true, isBuilder: false });
    } else if (profile === BUILDER_ID) {
      this.setState({ profile, isBroker: false, isBuilder: true });
    } else {
      this.setState({ profile, isBroker: false, isBuilder: false });
    }
  }

  handleChangeCompany(e) {
    const company = e.target.value;
    this.setState({ company });
  }

  handleChangeStrategicPartner(e) {
    const strategicPartner = e.target.value;
    this.setState({ strategicPartner, isBroker: false, isBuilder: false });
  }

  handleChangeEmail(e) {
    const email = e.target.value;
    this.setState({ email });
  }

  handleChangePhone(e) {
    const phone = e.target.value;
    if (isPhone(phone)) {
      this.setState({ phone });
    }
  }

  handleChangeDocument({target: {['value']: document}}) {
    this.setState({ document });
  }

  handleChangeActive(e) {
    const active = e.target.checked;
    this.setState({ active });
  }

  handleChangeIsBroker(e) {
    const isBroker = e.target.checked;
    if (isBroker === true) {
      this.setState({ isBroker, profile: ASESOR_ID, isBuilder: false });
    } else {
      this.setState({ isBroker, profile: ADMIN_ID, isBuilder: false });
    }
  }

  handleChangeIsBuilder(e) {
    const isBuilder = e.target.checked;
    if (isBuilder === true) {
      this.setState({ isBuilder, isBroker: false, profile: BUILDER_ID });
    } else {
      this.setState({ isBuilder, isBroker: false, profile: ADMIN_ID });
    }
  }

  isInvalid() {
    const {
      name,
      lastname,
      email,
      phone,
      profile,
      zones,
      commissionPercentage,
      isBroker,
      isBuilder,
      builder,
      document
    } = this.state;
    if (
      name !== '' &&
      lastname !== '' &&
      email !== '' &&
      phone !== '' &&
      isEmail(email) &&
      profile !== '' &&
      !(document !== '' && !verifyIdentification(document, IDENTIFICATION_CARD_ID))
    ) {
      if (isBroker === true) {
        if (zones.length > 0) {
          return false;
        } else if (commissionPercentage.length > 0) {
          return false;
        }
        return true;
      }
      if (isBuilder === true) {
        if (builder !== '') {
          return false;
        }
      }
      return false;
    }
    return true;
  }

  renderProfiles() {
    const { profiles } = this.props;
    return profiles.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>
          {item.name}
        </MenuItem>
      );
    });
  }

  renderAssessors() {
    const { asessors } = this.props;
    return asessors.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>
          {item.name}
        </MenuItem>
      );
    });
  }

  renderCompanies() {
    const { companies } = this.props;
    return companies.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>
          {item.name}
        </MenuItem>
      );
    });
  }

  renderRowCompany(classes) {
    const profileId = localStorage.getItem('profileId');
    return (
      SUPER_ADMIN_ROL_ID === profileId && (
        <TableRow>
          <TableCell className={classes.tableRowTitle}>
            <b>Empresa</b>
          </TableCell>
          <TableCell>
            <Select
              value={this.state.company}
              fullWidth
              onChange={this.handleChangeCompany}
            >
              {this.renderCompanies()}
            </Select>
          </TableCell>
        </TableRow>
      )
    );
  }

  renderStrategicPartner() {
    const { strategicPartner } = this.props;
    return strategicPartner.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>
          {item.name}
        </MenuItem>
      );
    });
  }

  renderZones() {
    const { classes } = this.props;
    const { isBroker } = this.state;
    if (isBroker === true) {
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}>
            <b>Sectores</b>
          </TableCell>
          <TableCell>
            <CollapsableZones
              level="cities"
              filterBy="configuration"
              checkedSectors={this.state.zones}
              onCheckSector={this.handleChangeZone}
            />
          </TableCell>
        </TableRow>
      );
    }
    return null;
  }

  renderBuilder() {
    const { classes, builders } = this.props;
    const { isBuilder } = this.state;
    if (isBuilder === true) {
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}>
            <b>Constructora</b>
          </TableCell>
          <TableCell>
            <FormControl>
              <InputLabel>Constructora</InputLabel>
              <Select
                value={this.state.builder}
                onChange={this.handleChangeBuilder}
              >
                {builders.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  }

  renderCommissionPercentage() {
    const { classes } = this.props;
    const { isBroker } = this.state;
    if (isBroker === true) {
      return (
        <TableRow>
          <TableCell className={classes.tableRowTitle}>
            <b>Porcentaje de comisión (%)</b>
          </TableCell>
          <TableCell>
            <TextField
              value={this.state.commissionPercentage}
              onChange={this.handleChangeCommissionPercentage}
              fullWidth
              id="commissionPercentage"
            />
          </TableCell>
        </TableRow>
      );
    }
    return null;
  }

  renderResetPasswordButton() {
    const { isNew, isLoading, classes } = this.props;
    if (!isNew) {
      if (!isLoading) {
        return (
          <ConfirmationDialog
            confirmationText="¿Está seguro que desea restablecer la contraseña de este usuario?"
            buttonText="Restablecer Contraseña"
            confirmAction={this.onResetPasswordClick}
            variant="text"
            isDisabled={false}
          />
        );
      }
      return <CircularProgress className={classes.progress} />;
    }
    return null;
  }

  renderButton() {
    const { isLoading, isNew, classes } = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText={`¿Está seguro que desea ${
            isNew ? 'crear' : 'modificar'
          } este usuario?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} Usuario`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  render() {
    const { classes, isLoading, isNew, user, companies, asessors } = this.props;
    const profileId = localStorage.getItem('profileId');

    let hasTrivoSmart = false;

    const userCompany = companies.find((x) => x.id === this.state.company);
    if (userCompany) hasTrivoSmart = !!userCompany.hasTrivoSmart;

    return isLoading === true ? (
      <div
        style={{
          zIndex: 100,
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.5)',
        }}
      >
        <CircularProgress
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
          }}
        />
      </div>
    ) : (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de ' : 'Nuevo '} usuario
          </Typography>
        </Toolbar>
        <div style={{ width: 'auto', overflowX: 'scroll' }}>
          <Table>
            <TableBody>
              {!isNew && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Disponible</b>
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.active}
                          onChange={this.handleChangeActive}
                          id="activeSwitch"
                          color="primary"
                        />
                      }
                      label={
                        this.state.active === true
                          ? 'Disponible'
                          : 'No disponible'
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Nombres</b>
                </TableCell>
                <TableCell>
                  <TextField
                    autoFocus
                    value={this.state.name}
                    onChange={this.handleChangeName}
                    fullWidth
                    id="nameText"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Apellidos</b>
                </TableCell>
                <TableCell>
                  <TextField
                    autoFocus
                    value={this.state.lastname}
                    onChange={this.handleChangeLastName}
                    fullWidth
                    id="lastNameText"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Email</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    fullWidth
                    disabled={!isNew}
                    error={
                      this.state.email !== '' && !isEmail(this.state.email)
                    }
                    id="emailText"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Tel&eacute;fono</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.phone}
                    onChange={this.handleChangePhone}
                    fullWidth
                    error={
                      this.state.phone !== '' && this.state.phone.length < 7
                    }
                    inputProps={{
                      maxLength: 12,
                    }}
                    id="phoneText"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>¿Es asesor?</b>
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.isBroker}
                        onChange={this.handleChangeIsBroker}
                        id="brokerSwitch"
                        color="primary"
                      />
                    }
                    label={
                      this.state.isBroker === true
                        ? 'Es asesor'
                        : 'No es asesor'
                    }
                  />
                </TableCell>
              </TableRow>
              {this.renderRowCompany(classes)}
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Perfil</b>
                </TableCell>
                <TableCell>
                  <Select
                    value={this.state.profile}
                    fullWidth
                    onChange={this.handleChangeProfile}
                  >
                    {this.renderProfiles()}
                  </Select>
                </TableCell>
              </TableRow>
              {this.state.profile=== COMERCIAL_MANAGER  && (
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Seleccione asesores</b>
                </TableCell>
                <TableCell>
                  <Select
                    multiple
                    value={this.state.userAsessors}
                    fullWidth
                    onChange={this.handleChangeAsessors}
                    renderValue={() => this.renderSelectValue()}
                    input={<Input id="select-multiple" />}
                  >
                    {this.renderAssessors()}
                  </Select>
                </TableCell>
              </TableRow>)
              }
              {this.state.isStrategicPartnerProfile && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Empresa Aliada</b>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={this.state.strategicPartner || ''}
                      fullWidth
                      onChange={this.handleChangeStrategicPartner}
                    >
                      {this.renderStrategicPartner()}
                    </Select>
                  </TableCell>
                </TableRow>
              )}
              {this.renderBuilder()}
              {this.state.isBroker && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Identificación</b>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={this.state.document}
                      onChange={this.handleChangeDocument}
                      error={
                        this.state.document !== '' && !verifyIdentification(this.state.document, IDENTIFICATION_CARD_ID)
                      }
                      fullWidth
                      id="documentText"
                    />
                  </TableCell>
                </TableRow>
              )}
              {this.renderZones()}
              {this.renderCommissionPercentage()}
              <TableRow className={classes.tableRowTitle}>
                <TableCell>
                  <b>Foto perfil</b>
                </TableCell>
                <TableCell id="imageInput">
                  {this.state.profileImg && (
                    <a
                      href={this.state.profileImg}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src={this.state.profileImg}
                        alt="Foto de perfil"
                        style={{ width: 100 }}
                      />
                    </a>
                  )}
                  <Uploader
                    buttonText={
                      this.state.profileImg ? 'Actualizar foto' : 'Subir foto'
                    }
                    photo
                    dirName="Users"
                    urlUploaded={this.handleUrlUploaded}
                  />
                </TableCell>
              </TableRow>
              {profileId === SUPER_ADMIN_ROL_ID && hasTrivoSmart && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Trivo Smart</b>
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.trivoSmartPermission}
                          onChange={({ target }) =>
                            this.setState({
                              trivoSmartPermission: target.checked,
                            })
                          }
                          color="primary"
                        />
                      }
                      label={
                        this.state.trivoSmartPermission
                          ? 'Habilitado'
                          : 'No habilitado'
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
              {isNew === false && (
                <TableRow className={classes.tableRowTitle}>
                  <TableCell>
                    <b>Restablecer contrase&ntilde;a</b>
                  </TableCell>
                  <TableCell>{this.renderResetPasswordButton()}</TableCell>
                </TableRow>
              )}
              {user.date_created && (
                <TableRow className={classes.tableRowTitle}>
                  <TableCell>
                    <b>Fecha de creaci&oacute;n</b>
                  </TableCell>
                  <TableCell>{user.date_created.substring(0, 10)}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>{this.renderButton()}</div>
      </Paper>
    );
  }
}

UsersDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  builders: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  profiles: PropTypes.array.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  strategicPartner: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
};

export default withStyles(styles)(UsersDetail);
