export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';

export const GET_PLANS_SENDING = 'GET_PLANS_SENDING';

export const GET_PLANS_FAILED = 'GET_PLANS_FAILED';

export const GET_FILTER_PLANS_SUCCESS = 'GET_FILTER_PLANS_SUCCESS';

export const GET_FILTER_PLANS_SENDING = 'GET_FILTER_PLANS_SENDING';

export const GET_FILTER_PLANS_FAILED = 'GET_FILTER_PLANS_FAILED';

export const GET_GRAPHS_SUCCESS = 'GET_GRAPHS_SUCCESS';

export const GET_GRAPHS_SENDING = 'GET_GRAPHS_SENDING';

export const GET_GRAPHS_FAILED = 'GET_GRAPHS_FAILED';

export const UPDATE_PLAN_SENDING = 'UPDATE_PLAN_SENDING';

export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';

export const UPDATE_PLAN_FAILED = 'UPDATE_PLAN_FAILED';
