import * as securityActions from '../actions/types/security';

const initialState = {
  user: null,
  sending: false,
  token: null,
  error: null,
  message: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, token, user, profile, message} = action.payload;
  switch (action.type) {
  case securityActions.LOGIN_SENDING:
    return {...state, sending: true};
  case securityActions.LOGIN_SUCCESS:
    return {...state, sending: false, user, token, profile, message: 'Inicio de sesión satisfactorio'};
  case securityActions.LOGIN_FAILED:
    return {...state, sending: false, error, message, token: null, user: null, profile: null};
  case securityActions.GET_USER_SENDING:
    return {...state, sending: true};
  case securityActions.GET_USER_SUCCESS:
    return {...state, sending: false, user, message: 'Usuario obtenido satisfactoriamente'};
  case securityActions.GET_USER_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
