/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';
import {getUser} from '../../../actions/users';
import {styles} from './styles';
import User from './user';
import Observations from './observations';
import {findIndexFromObjectsArray} from '../../../utils/utils';
import {GET_USER_SUCCESS} from '../../../actions/types/users';
import {getLead} from '../../../actions/leads';

export class LeadsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextActions: false,
      referrer: null
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleNextActions = this.handleNextActions.bind(this);
    this.renderLoadingLead = this.renderLoadingLead.bind(this);
    this.renderReferrer = this.renderReferrer.bind(this);
    this.handleNextLead = this.handleNextLead.bind(this);
    this.handleGoBackLead = this.handleGoBackLead.bind(this);
  }

  componentWillMount() {
    const {lead} = this.props;
    if (lead.referrer) {
      this.props.getUser(lead.referrer).then((result) => {
        if (result.type === GET_USER_SUCCESS) {
          this.setState({referrer: result.payload.user});
        }
      });
    }
  }

  handleNextActions() {
    this.setState({nextActions: true});
  }

  handleGoBack() {
    const {nextActions} = this.state;
    if (nextActions) {
      this.setState({nextActions: false});
    } else {
      this.props.handleGoBack();
      window.history.replaceState('', '', '/readonly');
    }
  }

  async handleNextLead() {
    const {leads, lead, page, rowsPerPage, dataLength} = this.props;
    const limitPages = Math.ceil(dataLength / rowsPerPage);
    let position = 0;
    const validateLeadPosition = findIndexFromObjectsArray(
      leads,
      '_id',
      lead._id
    );
    position = validateLeadPosition + 1;
    if (
      validateLeadPosition >= 0 &&
      validateLeadPosition < rowsPerPage &&
      position < leads.length
    ) {
      this.props.getLead(leads[position]._id);
    } else if (limitPages > page + 1 && position === leads.length) {
      const result = await this.props.handleChangePage();
      if (result.type === 'FILTER_LEADS_SUCCESS') {
        const newLeads = result.payload.leads[0];
        await this.props.getLead(newLeads._id);
      }
    } else {
      // eslint-disable-next-line no-undef
      alert('Llegaste al final de los leads');
    }
  }

  async handleGoBackLead() {
    const {leads, lead, page, rowsPerPage, handleBackPage} = this.props;
    let position = 0;
    const validateLeadPosition = findIndexFromObjectsArray(
      leads,
      '_id',
      lead._id
    );
    position = validateLeadPosition - 1;
    if (
      validateLeadPosition >= 0 &&
      validateLeadPosition < rowsPerPage &&
      position >= 0
    ) {
      this.props.getLead(leads[position]._id);
    } else if (page > 0 && position < 0) {
      const result = await handleBackPage();
      if (result.type === 'FILTER_LEADS_SUCCESS') {
        const newLeads = result.payload.leads[19];
        await this.props.getLead(newLeads._id);
      }
    } else {
      // eslint-disable-next-line no-undef
      alert('Llegaste al final de los leads');
    }
  }

  renderLoadingLead() {
    const {isLoading, lead} = this.props;
    if (isLoading === true || lead === null) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)'
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto'
            }}
          />
        </div>
      );
    }
    return null;
  }

  renderReferrer() {
    const {classes} = this.props;
    const {referrer} = this.state;
    if (referrer) {
      return (
        <div className={classes.paperRootPaddingCustom}>
          <Typography variant="h6">Referido por:</Typography>
          <br />
          <Typography variant="subtitle1">
            {`${referrer.name} ${referrer.lastname}`}
          </Typography>
          <a href={`tel:${referrer.phone}`}>
            <Typography variant="body1">{referrer.phone}</Typography>
          </a>
          <a href={`mailto:${referrer.email}`}>
            <Typography variant="body1">{referrer.email}</Typography>
          </a>
        </div>
      );
    }
    return null;
  }

  render() {
    const {classes, isNew, origins} = this.props;
    return (
      <div>
        {this.renderLoadingLead()}
        <div>
          <Paper elevation={2} className={classes.paperRoot}>
            <Toolbar>
              <IconButton onClick={this.handleGoBack}>
                <ArrowBackIosIcon />
              </IconButton>
              <Typography variant="h6" className={classes.flexToolbar}>
                {!isNew ? 'Detalle de ' : 'Nuevo '} Lead
              </Typography>
              <div>
                <Button
                  size="small"
                  className={classes.margin}
                  onClick={this.handleGoBackLead}
                >
                  <ArrowBackIosIcon />
                </Button>
                <Button
                  size="small"
                  className={classes.margin}
                  onClick={this.handleNextLead}
                >
                  <ArrowForwardIos />
                </Button>
              </div>
            </Toolbar>
            <User
              onClickNextActions={this.handleNextActions}
              isNew={isNew}
              origins={origins}
            />
          </Paper>
          {!isNew && (
            <div>
              <Paper elevation={2} className={classes.paperRoot}>
                <Toolbar>
                  <Typography variant="h6">Observaciones</Typography>
                </Toolbar>
                <Observations />
              </Paper>
            </div>
          )}
        </div>
      </div>
    );
  }
}

LeadsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  lead: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleBackPage: PropTypes.func.isRequired,
  getLead: PropTypes.func,
  leads: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  dataLength: PropTypes.number.isRequired,
  origins: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
  const {leads} = state;
  return {
    leads: leads.leads,
    lead: leads.lead,
    page: leads.page,
    rowsPerPage: leads.rowsPerPage,
    dataLength: leads.dataLength,
    isLoading: leads.sendingLead
  };
};

const mapDispatchToProps = {
  getUser,
  getLead
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LeadsDetails));
