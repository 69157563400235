/* eslint-disable no-alert */
/* eslint-disable prefer-destructuring */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { getAllParameters } from '../../actions/parameters';
import {
  filterLeads,
  createLead,
  updateLead,
  onBulkAddLeads,
  onAddUnitMatchComment,
  onUpdateLeadMatches,
  onRemoveMatch,
  onRemoveMatches,
  addNewAction,
  onSendLeadEmail,
  onUpdateLeadUser,
  onReassignLead,
  updateLeadState,
  onSwapMatchUnits,
  onUpdateTemperature,
  onUpdatePrequalification,
  onUpdateClosingPrice,
  onUpdateEstimatedClosingTime,
  onSwapMatches,
  addObservation,
  assignBrokerToLead,
  setLead,
  updatePointsAgainst,
  updatePointsInFavor,
  getLead,
  onChangeProjectMatchStage,
  onWinUnit,
  bulkLeadAssign,
  onBlockProject,
  onReplaceMatches,
  onUnBlockProject,
  onDuplicateProject,
} from '../../actions/leads';
import { getPropertyTypes } from '../../actions/propertyTypes';
import { getZones, getFormattedZones } from '../../actions/zones';
import { getOrigins } from '../../actions/origins';
import { downloadLeads } from '../../actions/drawer';
import { getUsers, getUser, editUser } from '../../actions/users';
import { getCompanies } from '../../actions/companies';
import { setAvailable } from '../../actions/units';
import { getLeadStates } from '../../actions/leadStates';
import { getLeadStages } from '../../actions/leadStages';
import EnhancedTable from '../common/enhancedTable';
import { leadsCols } from '../../utils/colTypes';
import CustomSnackbar from '../common/customSnackbar';
import LeadDetail from './detail';
import { leadsOptions, defaultOrderLeads } from '../../utils/filterTypes';
import {
  copyObject,
  sortObjectByProperty,
  ADMIN,
  ADVICE,
  OBSERVATION,
  ZONE,
  NEW_LEAD,
  dateFormat,
  ADMIN_ID,
  SUPER_ADMIN,
  ASESOR_ID,
  LEAD_STATE_GANADO,
  SUPER_ADMIN_ROL_ID,
  FERIA_ONLINE_QUITO_ORIGIN,
} from '../../utils/utils';
import {
  CREATE_LEAD_SUCCESS,
  CREATE_LEADS_BULK_SUCCESS,
  REMOVE_MATCH_FAILED,
  SWAP_MATCH_FAILED,
  SWAP_UNIT_MATCH_FAILED,
  ADD_UNIT_MATCH_COMMENT_FAILED,
  UPDATE_LEAD_FAILED,
  ADD_LEAD_ACTION_FAILED,
  REASSIGN_LEAD_FAILED,
  CHANGE_PROJECT_MATCH_STAGE_FAILED,
  BLOCK_MATCH_FAILED,
  UNBLOCK_MATCH_FAILED,
  DUPLICATE_MATCH_FAILED,
} from '../../actions/types/leads';
import { DOWNLOAD_LEADS_SUCCESS } from '../../actions/types/drawer';
import { UPDATE_USER_FAILED } from '../../actions/types/users';
import CONSTANTS from '../../utils/constants';
import { getStands } from '../../actions/stands';
import ProformasAdmin from './proformasAdmin';
import { getProjects } from '../../actions/projects';
import AppointmentModal from '../common/appointmentModal';
import EventList from '../common/eventList';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const company = JSON.parse(localStorage.getItem('company'));

export class Leads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      title: 'Leads',
      filterChips: [],
      filterOptions: copyObject(leadsOptions),
      openExistingUserModal: false,
      existingUserId: null,
      existingUserMail: null,
      existingUserName: null,
      existingUserLastname: null,
      assignBroker: '',
      openAssigConfirmation: false,
      assigConfirmationData: null,
      showNotificationCsv: false,
      proformaLead: null,
      openAppointmentModal: false,
      leadToEvent : null
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onBulkAdd = this.onBulkAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.onEvent = this.onEvent.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.onBulkAssign = this.onBulkAssign.bind(this);
    this.handleAddObservation = this.handleAddObservation.bind(this);
    this.handleObservationsChange = this.handleObservationsChange.bind(this);
    this.handleAddNewAction = this.handleAddNewAction.bind(this);
    this.handleOriginChange = this.handleOriginChange.bind(this);
    this.handleOriginUpdate = this.handleOriginUpdate.bind(this);
    this.handleOriginProjectUpdate = this.handleOriginProjectUpdate.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangePageDetail = this.handleChangePageDetail.bind(this);
    this.handleBackPage = this.handleBackPage.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.handleUpdateAnswer = this.handleUpdateAnswer.bind(this);
    this.handleEditAttributes = this.handleEditAttributes.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.onGetAllLeadsOfUser = this.onGetAllLeadsOfUser.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderFilterOptions = this.renderFilterOptions.bind(this);
    this.renderConfirmUpdateExistingUser = this.renderConfirmUpdateExistingUser.bind(
      this,
    );
    this.renderLoadingLead = this.renderLoadingLead.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.filtersAttributes = this.filtersAttributes.bind(this);
    this.handleUpdateTemperature = this.handleUpdateTemperature.bind(this);
    this.handleUpdatePrequalification = this.handleUpdatePrequalification.bind(this);
    this.handleUpdateEstimatedClosingTime = this.handleUpdateEstimatedClosingTime.bind(
      this,
    );
    this.handleUpdateClosingPrice = this.handleUpdateClosingPrice.bind(this);
    this.handleUpdateLeadMatches = this.handleUpdateLeadMatches.bind(this);
    this.handleRemoveMatch = this.handleRemoveMatch.bind(this);
    this.handleRemoveMatches = this.handleRemoveMatches.bind(this);
    this.handleSwapMatchUnits = this.handleSwapMatchUnits.bind(this);
    this.handleSwapMatches = this.handleSwapMatches.bind(this);
    this.handleAddUnitMatchComment = this.handleAddUnitMatchComment.bind(this);
    this.handleUpdateLeadUser = this.handleUpdateLeadUser.bind(this);
    this.handleWinUnit = this.handleWinUnit.bind(this);
    this.handleUpdateLeadState = this.handleUpdateLeadState.bind(this);
    this.handleRefreshLead = this.handleRefreshLead.bind(this);
    this.handleChangeUnitComment = this.handleChangeUnitComment.bind(this);
    this.handleChangeProjectMatchStage = this.handleChangeProjectMatchStage.bind(
      this,
    );
    this.handleCreateLead = this.handleCreateLead.bind(this);
    this.handleReassignLead = this.handleReassignLead.bind(this);
    this.handleSendLeadEmail = this.handleSendLeadEmail.bind(this);
    this.handleCloseExistingUserModal = this.handleCloseExistingUserModal.bind(
      this,
    );
    this.handleCancelExistingUserModal = this.handleCancelExistingUserModal.bind(
      this,
    );
    this.handleSuccessExistingUserModal = this.handleSuccessExistingUserModal.bind(
      this,
    );
    this.handleSeeCommentInFavor = this.handleSeeCommentInFavor.bind(this);
    this.handleSeeCommentAgainst = this.handleSeeCommentAgainst.bind(this);
    this.handleChangePointsInFavor = this.handleChangePointsInFavor.bind(this);
    this.handleChangePointsAgainst = this.handleChangePointsAgainst.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.hasAdminPermissions = this.hasAdminPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
    this.getDownloadPermissions = this.getDownloadPermissions.bind(this);
    this.getBulkAssignPermissions = this.getBulkAssignPermissions.bind(this);
    this.getAdvisers = this.getAdvisers.bind(this);
    this.handleChangeAssignBroker = this.handleChangeAssignBroker.bind(this);
    this.handleCloseResponseBulkModal = this.handleCloseResponseBulkModal.bind(
      this,
    );
    this.handleBlockProject = this.handleBlockProject.bind(this);
    this.handleUnBlockProject = this.handleUnBlockProject.bind(this);
    this.onReplaceMatches = this.onReplaceMatches.bind(this);
    this.handleDuplicateProject = this.handleDuplicateProject.bind(this);
    this.handleCloseCsvNotification = this.handleCloseCsvNotification.bind(
      this,
    );
    this.handleStandsUpdate = this.handleStandsUpdate.bind(this);
    this.onProformaAdmin = this.onProformaAdmin.bind(this);
  }

  componentWillMount() {
    this.props.getPropertyTypes();
    this.props.getUsers();
    this.props.getZones();
    this.props.getProjects();
    this.props.getFormattedZones(
      'cities',
      {},
      company && company._id ? company._id : null,
    );
    this.props.getLeadStates();
    this.props.getLeadStages();
    this.props.getAllParameters(true);
    this.props.getOrigins({ active: true });
    this.props.getCompanies();
    this.props.getStands();
    const profileId = localStorage.getItem('profileId');
    let defaultFilters = this.props.filters;
    if (CONSTANTS.ROLES_DEFAULT_FILTER_LEADS.indexOf(profileId) >= 0) {
      defaultFilters = {
        status: ['5cbdf596ba0b4000172a4857', '5cbdf569ba0b4000172a4851'],
      };
      this.setState({ filterChips: CONSTANTS.DEFAULT_CHIPS_FILTER_LEADS });
    }
    this.props.filterLeads(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      defaultFilters,
    );
    
    const params = this.props.match ? this.props.match.params || {} : {};
    const query = new URLSearchParams(this.props.location ? this.props.location.search : '');
    
    const leadId = params.id || query.get("id");
        
    if (!!leadId) {
      this.handleRefreshLead(leadId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.renderFilters();
    }
  }

  onAdd() {
    const newLead = copyObject(NEW_LEAD);
    this.props.setLead(newLead, null);
    this.setState({ isNew: true });
  }

  onBulkAdd(leadsToAdd, ignoredBrokers) {
    const { stands } = this.props;
    let observationOrigin = ADVICE;
    const userCreatorId = localStorage.getItem('user');
    if (JSON.parse(localStorage.getItem('isBroker')) === true) {
      observationOrigin = OBSERVATION;
    }

    const profileId = localStorage.getItem('profileId');
    const standIds = stands.map((x) => x.id);

    const newLeads = leadsToAdd
      .map((newLead) => {
        const lead = newLead;
        lead.observations[0].origin = observationOrigin;
        lead.observations[0].userId = userCreatorId;
        return lead;
      })
      .filter(
        (lead) =>
          lead.origin !== FERIA_ONLINE_QUITO_ORIGIN ||
          (lead.origin === FERIA_ONLINE_QUITO_ORIGIN &&
            profileId === ADMIN_ID &&
            standIds.includes(lead.stand.toString().trim())),
      )
      .map((lead) => {
        let stand = {};
        if (lead.stand) stand = lead.stand.toString().trim();
        return { ...lead, stand };
      });

    this.props.onBulkAddLeads(newLeads, ignoredBrokers).then((result) => {
      if (result.type === CREATE_LEADS_BULK_SUCCESS) {
        this.props.filterLeads(
          this.props.page,
          this.props.rowsPerPage,
          this.props.order,
          this.props.orderBy,
          this.props.searchText,
          this.props.filters,
        );
        // eslint-disable-next-line no-alert
        // eslint-disable-next-line no-undef
        alert(`Se han agregado ${newLeads.length} leads exitosamente`);
      }
    });
  }

  async onBulkAssign(leadsToAsign) {
    const { assignBroker } = this.state;
    if (assignBroker !== '' && assignBroker !== null) {
      const result = await this.props.bulkLeadAssign(
        assignBroker,
        leadsToAsign,
      );
      this.setState({
        openAssigConfirmation: true,
        assigConfirmationData: result,
      });
      if (result.type === 'BULK_LEAD_ASSIGN_SUCCESS') {
        this.props.filterLeads(
          this.props.page,
          this.props.rowsPerPage,
          this.props.order,
          this.props.orderBy,
          this.props.searchText,
          this.props.filters,
        );
      }
      this.setState({ assignBroker: '' });
    } else {
      // eslint-disable-next-line no-alert
      // eslint-disable-next-line no-undef
      alert('Seleccione un  Asesor');
    }
  }

  onDetail(_id) {
    this.props.getLead(_id);
    this.setState({ isNew: false });
  }

  onEvent(_id) {
    this.setState({ leadToEvent: _id,openAppointmentModal:true });
  }

  onProformaAdmin(row) {
    this.setState({ proformaLead: row.id });
  }

  onOrder(order, orderBy) {
    this.props.filterLeads(
      this.props.page,
      this.props.rowsPerPage,
      order,
      orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  onSearch(searchText) {
    this.setState({ filterChips: [] });
    this.props.filterLeads(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      searchText,
      {},
    );
  }

  onGetAllLeadsOfUser(userId, searchText, document, phone) {
    this.props.filterLeads(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      searchText,
      { user: userId, document, phone },
    );
  }

  onReplaceMatches(id, sortBy) {
    this.props.onReplaceMatches(id, sortBy).then((result) => {
      if (result.type === SWAP_MATCH_FAILED) {
        this.handleRefreshLead(id);
      }
    });
  }

  onDownload() {
    this.props.downloadLeads().then((value) => {
      if (DOWNLOAD_LEADS_SUCCESS) {
        const data = value.payload.leads;
        if (data && data.timeValidation) {
          this.setState({ showNotificationCsv: true });
        } else if (data) {
          const fileName = 'leads-report.csv';
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          // eslint-disable-next-line no-undef
          const blob = new Blob([data], { type: 'octet/stream' });
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
    });
  }

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_leads) {
        return true;
      }
    }
    return false;
  };

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_leads) {
        return true;
      }
    }
    return false;
  };

  getBulkAssignPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_leads) {
        return true;
      }
    }
    return false;
  };

  getDownloadPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    return profile && (profile.name === ADMIN || profile.name === SUPER_ADMIN);
  };

  getBulkAssignPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.assign_bulk_leads) {
        return true;
      }
    }
    return false;
  };

  getAdvisers = () => {
    const { users } = this.props;
    const types = [];
    if (users) {
      const asesors = users.filter((obj) => {
        if (obj.profile) {
          if (obj.profile._id) {
            if (obj.is_asesor === true) {
              return true;
            }
          }
        }
        return false;
      });
      asesors.forEach((leadStage) => {
        const newType = {
          title: leadStage.name,
          value: leadStage.id,
        };
        types.push(newType);
      });
    }
    return types;
  };

  defaulOrderFilters = (filtersToOrder) => {
    const orderedFilters = [];
    defaultOrderLeads.reduce((result, defaultItem) => {
      filtersToOrder.filter((filterItem) => {
        if (filterItem.id === defaultItem) {
          return orderedFilters.push(filterItem);
        }
        return false;
      });
      return orderedFilters;
    }, []);
    return orderedFilters;
  };

  handleBlockProject(id, index) {
    this.props.onBlockProject(id, index).then((result) => {
      if (result.type === BLOCK_MATCH_FAILED) {
        this.handleRefreshLead(id);
      }
    });
  }

  handleUnBlockProject(id, index) {
    this.props.onUnBlockProject(id, index).then((result) => {
      if (result.type === UNBLOCK_MATCH_FAILED) {
        this.handleRefreshLead(id);
      }
    });
  }

  handleDuplicateProject(id, index) {
    this.props.onDuplicateProject(id, index).then((result) => {
      if (result.type === DUPLICATE_MATCH_FAILED) {
        this.handleRefreshLead(id);
      }
    });
  }

  handleChangePointsInFavor(data, index) {
    const { lead } = this.props;
    const { matches } = lead;
    if (matches[index]) {
      matches[index].units[0].pros.push({
        value: data.value,
        comment: data.comment,
      });
      lead.matches = matches;
      this.props.updatePointsInFavor(lead._id, data).then((result) => {
        if (result.type === UPDATE_LEAD_FAILED) {
          this.handleRefreshLead(lead._id);
        } else {
          this.props.setLead(lead);
        }
      });
    }
  }

  handleChangePointsAgainst(data, index) {
    const { lead } = this.props;
    const { matches } = lead;
    if (matches[index]) {
      matches[index].units[0].cons.push({
        value: data.value,
        comment: data.comment,
      });
      this.props.updatePointsAgainst(lead._id, data).then((result) => {
        if (result.type === UPDATE_LEAD_FAILED) {
          this.handleRefreshLead(lead._id);
        } else {
          this.props.setLead(lead);
        }
      });
    }
  }

  handleSeeCommentAgainst(index) {
    const { lead } = this.props;
    const { matches } = lead;
    if (matches[index]) {
      matches[index].units[0].cons[
        matches[index].units[0].cons.length - 1
      ].seeComment = !matches[index].units[0].cons[
        matches[index].units[0].cons.length - 1
      ].seeComment;
      lead.matches = matches;
      this.props.setLead(lead);
    }
  }

  handleSeeCommentInFavor(index) {
    const { lead } = this.props;
    const { matches } = lead;
    if (matches[index]) {
      matches[index].units[0].pros[
        matches[index].units[0].pros.length - 1
      ].seeComment = !matches[index].units[0].pros[
        matches[index].units[0].pros.length - 1
      ].seeComment;
      lead.matches = matches;
      this.props.setLead(lead);
    }
  }

  handleChangeAssignBroker(assignBrokerValue) {
    this.setState({ assignBroker: assignBrokerValue });
  }

  filtersAttributes = (allFilters) => {
    const items = allFilters;
    const attributes = [];
    for (let i = 0; i < Object.entries(items).length; i += 1) {
      const filter = Object.entries(items)[i];
      if (
        filter[0] !== 'status' &&
        filter[0] !== 'originProject' &&
        filter[0] !== 'stage' &&
        filter[0] !== 'origin' &&
        filter[0] !== 'stand' &&
        filter[0] !== 'brokers' &&
        filter[0] !== 'date_created' &&
        filter[0] !== 'date_updated' &&
        filter[0] !== 'next_deadline' &&
        filter[0] !== 'last_contact_days' &&
        filter[0] !== 'estimated_closing_time' &&
        filter[0] !== 'temperature' &&
        filter[0] !== 'company' &&
        filter[0] !== 'zone' &&
        filter[0] !== 'proformas_date_created' &&
        filter[1] !== ''
      ) {
        let query = '';
        if (filter[1].type === 'select') {
          query = 'preferences.answerId';
          const $in = {
            $in: filter[1].value,
          };
          attributes.push({
            [query]: $in,
          });
        } else {
          query = 'preferences.answer';
          attributes.push({
            [query]: filter[1].value,
          });
        }
        delete items[filter[0]];
      }
    }
    if (attributes.length > 0) {
      attributes.forEach((attribute) => {
        const attributeObject = Object.entries(attribute);
        const key = attributeObject[0][0];
        const value = attributeObject[0][1];
        if (value !== '') {
          items[key] = value;
        } else {
          delete items[key];
        }
      });
    }
    const preFilters = Object.entries(items).filter((obj) => obj[1] !== '');
    const filters = {};
    preFilters.forEach((item) => {
      const key = item[0];
      if (typeof item[1] === 'string' || item[1] instanceof String) {
        filters[key] = item[1];
      } else if (Array.isArray(item[1].value)) {
        if (item[1].value.length > 0) {
          filters[key] = item[1].value;
        }
      } else if (item[1].value) {
        filters[key] = item[1].value;
      } else {
        filters[key] = item[1];
      }
    });

    return filters;
  };

  handleRefreshLead(_id) {
    this.props.getLead(_id);
  }

  hasAdminPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.name === ADMIN || profile.name === SUPER_ADMIN) {
        return true;
      }
    }
    return false;
  };

  handleUpdateLeadState(
    id,
    nextStateObject,
    nextStage,
    oldStatusIsWon,
    project,
    unit,
    beneficiary,
    builder,
    closingPrice,
  ) {
    const { lead } = this.props;
    const oldStatus = lead.status._id;
    const leadUnitWon = lead.unitSold;
    lead.status = nextStateObject;
    lead.stage = nextStage;
    const projectToSend = project ? project._id || '' : '';
    lead.projectSold = project;
    lead.unitSold = unit;
    const winData = {
      project: projectToSend,
      unit,
      closing_price: closingPrice,
      builder,
      beneficiary,
    };
    this.props
      .updateLeadState(
        id,
        nextStateObject.id,
        nextStage,
        oldStatusIsWon,
        projectToSend,
        unit,
        winData,
      )
      .then((result) => {
        if (result.type === UPDATE_LEAD_FAILED) {
          this.handleRefreshLead(id);
        } else {
          // if (oldStatus === LEAD_STATE_GANADO && leadUnitWon !== '') {
          if (
            oldStatus === LEAD_STATE_GANADO &&
            nextStateObject.id !== LEAD_STATE_GANADO &&
            leadUnitWon !== ''
          ) {
            this.props.setAvailable(leadUnitWon);
          }
          this.props.setLead(lead);
        }
      });
  }

  handleReassignLead(id, currentBrokerId, newBrokerId, zoneId) {
    const { users, lead } = this.props;
    if (users.length > 0) {
      const newBroker = users.filter((obj) => obj.id === newBrokerId)[0];
      if (newBroker) {
        lead.brokers_arr = [`${newBroker.name} ${newBroker.lastname}`];
        this.props
          .onReassignLead(id, currentBrokerId, newBrokerId, zoneId)
          .then((result) => {
            if (result.type === REASSIGN_LEAD_FAILED) {
              this.handleRefreshLead(id);
            } else {
              this.props.setLead(lead);
            }
          });
      }
    }
  }

  handleUpdateTemperature(id, temperature) {
    const { lead } = this.props;
    this.props.onUpdateTemperature(id, temperature);
    lead.temperature = temperature;
    this.props.setLead(lead);
  }

  handleUpdatePrequalification(id, prequalification) {
    const { lead } = this.props;
    this.props.onUpdatePrequalification(id, prequalification);
    lead.prequalification = prequalification;
    this.props.setLead(lead);
  }

  handleUpdateEstimatedClosingTime(id, estimatedClosingTime) {
    const { lead } = this.props;
    this.props.onUpdateEstimatedClosingTime(id, estimatedClosingTime);
    lead.estimated_closing_time = estimatedClosingTime;
    this.props.setLead(lead);
  }

  handleUpdateClosingPrice(id, closingPrice) {
    this.props.onUpdateClosingPrice(id, closingPrice).then(() => {
      this.props.getLead(id);
    });
  }

  handleWinUnit(id, project, unit, closingPrice, builder, beneficiary) {
    const data = {
      project,
      unit,
      closing_price: closingPrice,
      builder,
      beneficiary,
    };
    this.props.onWinUnit(id, data).then(() => {
      this.props.getLead(id);
    });
  }

  handleSendLeadEmail(
    id,
    templateId,
    to,
    attachment,
    fileType,
    replaceWith,
    from,
  ) {
    this.props.onSendLeadEmail(
      id,
      templateId,
      to,
      attachment,
      fileType,
      replaceWith,
      from,
    );
  }

  handleAddNewAction(id, action, date) {
    this.props.addNewAction(id, action, date).then((result) => {
      if (result.type === ADD_LEAD_ACTION_FAILED) {
        this.handleRefreshLead(id);
      } else {
        const newAction = {
          date_created: moment().format(`${dateFormat} HH:mm`),
          deadline: moment(date).format(`${dateFormat} HH:mm`),
          description: action,
          active: true,
        };
        const { lead } = this.props;
        const leadToUpdate = copyObject(lead);
        const leadActions = lead.actions;
        leadActions.push(newAction);
        leadToUpdate.actions = leadActions;
        this.props.setLead(leadToUpdate);
      }
    });
  }

  async handleCreateLead(data) {
    await this.props.createLead(data);
    this.handleGoBack(true);
  }

  handleUpdateLeadUser(id, user) {
    const { lead } = this.props;
    if (lead) {
      const userId = lead.user._id;
      this.props.editUser(userId, user, lead.company).then((result) => {
        if (result.type === UPDATE_USER_FAILED) {
          this.handleRefreshLead(lead._id);
        } else if (result.payload.response) {
          if (result.payload.response.exists) {
            const existingUserId = result.payload.response.id;
            const existingUserMail = result.payload.response.email;
            const existingUserName = result.payload.response.name;
            const existingUserLastname = result.payload.response.lastname;
            this.setState({
              existingUserId,
              existingUserMail,
              existingUserName,
              existingUserLastname,
              openExistingUserModal: true,
            });
          } else {
            this.props.setLead(lead);
          }
        }
      });
    }
  }

  handleUpdateLeadMatches(parameters) {
    const { lead } = this.props;
    if (lead) {
      return this.props.onUpdateLeadMatches(lead, parameters);
    }
    return null;
  }

  handleAddUnitMatchComment(id, index, unitId, comment) {
    this.props
      .onAddUnitMatchComment(id, index, unitId, comment)
      .then((result) => {
        if (result.type === ADD_UNIT_MATCH_COMMENT_FAILED) {
          this.handleRefreshLead(id);
        }
      });
  }

  handleChangeProjectMatchStage(id, matchIndex, stage) {
    const { lead, leadStates } = this.props;
    const state = leadStates.filter((obj) => {
      const stages = obj.stages.map((ob) => ob.id.toString());
      if (stages.includes(stage.toString())) {
        return true;
      }
      return false;
    });
    lead.status = state[0];
    this.props
      .onChangeProjectMatchStage(id, matchIndex, stage)
      .then((result) => {
        if (result.type === CHANGE_PROJECT_MATCH_STAGE_FAILED) {
          this.handleRefreshLead(id);
        } else {
          this.props.setLead(lead);
        }
      });
  }

  handleSwapMatchUnits(id, projectIndex, oldIndex, newIndex) {
    if (oldIndex !== newIndex) {
      this.props
        .onSwapMatchUnits(id, projectIndex, oldIndex, newIndex)
        .then((result) => {
          if (result.type === SWAP_UNIT_MATCH_FAILED) {
            this.handleRefreshLead(id);
          }
        });
    }
  }

  handleSwapMatches(id, oldIndex, newIndex) {
    if (oldIndex !== newIndex) {
      this.props.onSwapMatches(id, oldIndex, newIndex).then((result) => {
        if (result.type === SWAP_MATCH_FAILED) {
          this.handleRefreshLead(id);
        }
      });
    }
  }

  handleRemoveMatch(id, index) {
    this.props.onRemoveMatch(id, index).then((result) => {
      if (result.type === REMOVE_MATCH_FAILED) {
        this.handleRefreshLead(id);
      }
    });
  }

  handleRemoveMatches(id, indexes) {
    this.props.onRemoveMatches(id, indexes).then((result) => {
      if (result.type === REMOVE_MATCH_FAILED) {
        this.handleRefreshLead(id);
      }
    });
  }

  handleChangeUnitComment(projectIndex, comment) {
    const { lead } = this.props;
    if (lead.matches) {
      lead.matches[projectIndex].units[0].comments = comment;
      this.props.setLead(lead);
    }
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterLeads(
      this.props.page,
      rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  handleFilter(filters, filterChips) {
    const filtersToApply = this.filtersAttributes(filters);
    this.setState({ filterChips });
    this.props.filterLeads(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      '',
      filtersToApply,
    );
  }

  handleUpdateAnswer(preference) {
    const { lead } = this.props;
    if (lead) {
      const { identifier } = preference;
      const leadPreference = {
        answerId: preference.answerId || [],
        answer: preference.answer || '',
        attributeId: preference.identifier || '',
        name: preference.name || '',
        identifier: preference.identifier || '',
        title: preference.title || '',
        icon: preference.icon || null,
      };
      if (leadPreference.answerId.length > 0) {
        const answerValues = leadPreference.answerId.map((answerId) => {
          let value = '';
          if (preference.options) {
            const { content } = preference.options;
            if (content) {
              content.forEach((item) => {
                if (item.answerId === answerId) {
                  value = item.value;
                }
              });
            }
          }
          return value;
        });
        leadPreference.answerValues = answerValues;
      }
      const preferenceExists = lead.preferences.filter(
        (obj) => obj.attributeId === identifier,
      )[0];
      if (preferenceExists) {
        const index = lead.preferences.indexOf(preferenceExists);
        lead.preferences[index] = leadPreference;
      } else {
        lead.preferences.push(leadPreference);
      }
      this.props.setLead(lead);
    }
  }

  handleEditAttributes() {
    const { lead } = this.props;
    if (lead) {
      const { preferences } = lead;
      const clearPreferences = preferences
        .map((preference) => {
          return {
            answer: preference.answer,
            answerId: preference.answerId,
            attributeId: preference.identifier || preference.attributeId,
          };
        })
        .reduce((acc, val) => {
          if (!acc.map((x) => x.attributeId).includes(val.attributeId))
            acc.push(val);
          return acc;
        }, []);
      this.props
        .updateLead(lead._id, { preferences: clearPreferences })
        .then((result) => {
          if (result.type === UPDATE_LEAD_FAILED) {
            this.handleRefreshLead(lead._id);
          }
        });
    }
  }

  handleChangePage(page) {
    this.props.filterLeads(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
  }

  async handleChangePageDetail() {
    const page = this.props.page + 1;
    const result = await this.props.filterLeads(
      page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText,
      this.props.filters,
    );
    return result;
  }

  async handleBackPage() {
    const page = this.props.page + -1;
    if (page >= 0) {
      const result = await this.props.filterLeads(
        page,
        this.props.rowsPerPage,
        this.props.order,
        this.props.orderBy,
        this.props.searchText,
        this.props.filters,
      );
      return result;
    }
    return null;
  }

  handleGoBack(refresh = true) {
    this.props.setLead(null);
    this.setState({ isNew: false });
    if (refresh === true) {
      this.props.filterLeads(
        this.props.page,
        this.props.rowsPerPage,
        this.props.order,
        this.props.orderBy,
        this.props.searchText,
        this.props.filters,
      );
    }
  }

  handleUpdateUser(user) {
    const { lead } = this.props;
    if (lead !== null) {
      lead.user = user;
      this.props.setLead(lead);
    }
  }

  handleOriginChange(origin) {
    const { lead } = this.props;
    if (lead !== null) {
      lead.origin = origin;
      this.props.setLead(lead);
    }
  }

  handleOriginUpdate(origin) {
    const { lead } = this.props;
    if (lead !== null) {
      lead.origin = origin;
      this.props.setLead(lead);
      this.props.updateLead(lead._id, { origin });
    }
  }

  handleOriginProjectUpdate(originProject) {
    const { lead } = this.props;
    if (lead !== null) {
      lead.originProject = originProject;
      this.props.setLead(lead);
      this.props.updateLead(lead._id, { originProject });
    }
  }

  handleStandsUpdate(stands) {
    const { lead } = this.props;
    if (lead !== null) {
      lead.stands = stands;
      this.props.setLead(lead);
      this.props.updateLead(lead._id, { stands });
    }
  }

  handleObservationsChange(observations) {
    const { lead } = this.props;
    if (lead !== null) {
      lead.observations = observations;
      this.props.setLead(lead);
    }
  }

  handleAddObservation(lead) {
    if (lead !== null) {
      const observation = lead.observations[lead.observations.length - 1];
      if (observation) {
        this.props
          .addObservation(
            lead._id,
            observation.description,
            observation.origin,
            observation.userId,
            observation.type,
          )
          .then((result) => {
            if (result.type === UPDATE_LEAD_FAILED) {
              this.handleRefreshLead(lead._id);
            } else {
              this.props.setLead(lead);
            }
          });
      }
    }
  }

  handleCloseExistingUserModal() {
    this.setState({
      openExistingUserModal: false,
      existingUserId: null,
      existingUserMail: null,
      existingUserName: null,
      existingUserLastname: null,
    });
  }

  handleCloseResponseBulkModal() {
    this.setState({ openAssigConfirmation: false });
  }

  handleCloseCsvNotification() {
    this.setState({ showNotificationCsv: false });
  }

  handleCancelExistingUserModal() {
    this.setState({
      openExistingUserModal: false,
      existingUserId: null,
      existingUserMail: null,
      existingUserName: null,
      existingUserLastname: null,
    });
    const { lead } = this.props;
    this.handleRefreshLead(lead._id);
  }

  handleSuccessExistingUserModal() {
    const { lead } = this.props;
    const { existingUserId } = this.state;
    this.props.onUpdateLeadUser(lead._id, existingUserId).then(() => {
      this.props.setLead(lead);
    });
    this.handleCloseExistingUserModal();
  }

  renderSnackBar() {
    const { error, message } = this.props;
    let variant = 'success';
    if (error !== null) {
      variant = 'error';
    }
    if (message) {
      return <CustomSnackbar variant={variant} message={message} />;
    }
    return null;
  }

  renderFilterOptions = () => {
    let cols = copyObject(leadsOptions);
    const profileId = localStorage.getItem('profileId');
    
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      cols = cols.filter((col) => !['company'].includes(col.id));
    }
    if (!(localStorage.getItem('isBroker') || profileId === ADMIN_ID)) {
      cols = cols.filter((col) => !['proformas_date_created'].includes(col.id));
    }
    if (JSON.parse(localStorage.getItem('isBroker')) === true) {
      cols.splice(0, 1);
      return cols;
    }
    return cols;
  };

  renderFilters() {
    const {
      allParameters,
      leadStates,
      leadStages,
      users,
      origins,
      companies,
      stands,
      projects
    } = this.props;
    let defFilters = this.renderFilterOptions();
    let filtersToSet = [];
    if (
      leadStates.length > 0 &&
      leadStages.length > 0 &&
      users.length > 0 &&
      companies.length > 0
    ) {
      defFilters = defFilters.map((item) => {
        const filter = item;
        if (filter.id === 'status') {
          const types = [];
          leadStates.forEach((leadState) => {
            const newType = {
              title: leadState.name,
              value: leadState.id,
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'stage') {
          const types = [{ title: 'Todos', value: 'all' }];
          leadStates.forEach((leadState) => {
            if (leadState.stages) {
              sortObjectByProperty(leadState.stages, 'order').forEach(
                (stage) => {
                  const newType = {
                    title: stage.name,
                    value: stage.id,
                    leadState: leadState.id,
                    leadStateName: leadState.name,
                  };
                  types.push(newType);
                },
              );
            }
          });
          filter.types = types;
        } else if (filter.id === 'brokers') {
          const types = [{ title: 'Todos', value: 'all' }];
          if (users) {
            const asesors = users.filter((obj) => {
              if (obj.profile) {
                if (obj.profile._id) {
                  if (obj.is_asesor === true) {
                    return true;
                  }
                }
              }
              return false;
            });
            asesors.forEach((leadStage) => {
              const newType = {
                title: leadStage.name,
                value: leadStage.id,
              };
              types.push(newType);
            });
            filter.types = types;
          }
        } else if (filter.type === 'date') {
          filter.types = [{ title: 'Todos', value: '' }];
        } else if (filter.id === 'origin') {
          const types = [{ title: 'Todos', value: 'all' }];
          if (origins) {
            origins.forEach((origin) => {
              const newType = {
                title: origin.name,
                value: origin.id,
              };
              types.push(newType);
            });
            filter.types = types;
          }
        } else if (filter.id === 'originProject') {
          const types = [{ title: 'Todos', value: 'all' }];
          if (projects) {
            projects.forEach((project) => {
              const newType = {
                title: project.name,
                value: project.id,
              };
              types.push(newType);
            });
            const [all, ...rest] = types;
            filter.types = [all, ...sortObjectByProperty(rest, 'title')];
          }
        } else if (filter.id === 'stand') {
          const types = [{ title: 'Todos', value: 'all' }];
          if (stands) {
            stands.forEach((stand) => {
              const newType = {
                title: stand.name,
                value: stand.id,
              };
              types.push(newType);
            });
            filter.types = types;
          }
        } else if (filter.id === 'company') {
          const types = [{ title: 'Todos', value: 'all' }];
          companies.forEach((company) => {
            const newType = {
              title: company.name,
              value: company.id,
            };
            types.push(newType);
          });
          filter.types = types;
        }
        return filter;
      });
    }
    if (allParameters.length > 0) {
      const clearParams = allParameters
        .filter((obj) => {
          if (
            obj.is_catalog === true &&
            obj.active === true &&
            obj.options.is_multiple === true
          ) {
            if (obj.identifier === ZONE) {
              return obj;
            }
          }
          return null;
        })
        .filter((obj) => obj !== null);
      const parameterFilters = clearParams
        .map((parameter) => {
          let parameterTypes = [];
          if (parameter.options.content.length > 0) {
            const parameterOptions = parameter.options.content;
            const sortParameterOptions = sortObjectByProperty(
              parameterOptions,
              'value',
            );
            parameterTypes = sortParameterOptions.map((item) => {
              return {
                title: item.value,
                value: item.answerId,
              };
            });
            const filter = {
              id: parameter.identifier,
              label: parameter.name,
              types: [{ title: 'Todos', value: 'all' }],
            };

            if (parameterTypes.length > 0) {
              filter.types = filter.types.concat(parameterTypes);
            }
            return filter;
          }
          return null;
        })
        .filter((obj) => obj !== null);
        
      filtersToSet.push(defFilters[0]);
      filtersToSet.push(defFilters[1]);
      filtersToSet = filtersToSet.concat(parameterFilters);
      defFilters.splice(0, 2);
      filtersToSet = filtersToSet.concat(defFilters);
    }
    let orderFilters = this.defaulOrderFilters(filtersToSet);

    if (orderFilters.length > 0 && stands.length === 0) {
      orderFilters = orderFilters.filter((x) => x.id !== 'stand');
    }
    
    this.setState({ filterOptions: orderFilters });
  }

  renderConfirmUpdateExistingUser() {
    const {
      existingUserMail,
      existingUserName,
      existingUserLastname,
    } = this.state;
    return (
      <Dialog
        open={this.state.openExistingUserModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseExistingUserModal}
      >
        <DialogTitle>Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            El correo electrónico <code>{existingUserMail}</code> ya existe y
            está asignado a{' '}
            <code>{`${existingUserName} ${existingUserLastname}`}</code>.
            <br />
            Al actualizar el mail asignará el lead a esa persona.
            <br />
            ¿Está seguro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancelExistingUserModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.handleSuccessExistingUserModal} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderAlertCsv() {
    const { showNotificationCsv } = this.state;
    return (
      <Dialog
        open={showNotificationCsv}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseCsvNotification}
      >
        <DialogTitle>Notificación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            No puede descargar estos registros en horarios activos
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseCsvNotification} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderBulkResponseDialog() {
    const { assigConfirmationData } = this.state;
    return (
      assigConfirmationData && (
        <Dialog
          open={this.state.openAssigConfirmation}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseExistingUserModal}
        >
          <DialogTitle>Detalle de Asignación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <br />
              Se asignaron{' '}
              {assigConfirmationData.payload.mappAssignedLeads.assigned}/
              {assigConfirmationData.payload.mappAssignedLeads.count} leads
              exitosamente.
              <br />
              <br />
              {assigConfirmationData.payload.mappAssignedLeads.detailsNotAssigned.map(
                (item, key) => {
                  return (
                    <span key={key}>
                      {' '}
                      {`${item.name}`} <br />{' '}
                    </span>
                  );
                },
              )}
              {assigConfirmationData.payload.mappAssignedLeads
                .detailsNotAssigned.length > 0 &&
                'no pueden ser asignados porque no cuentan con sectores atendidos por el asesor seleccionado.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseResponseBulkModal} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  }

  renderLoadingLead() {
    const { isLoadingLead, isLoadingDrawer } = this.props;
    if (isLoadingLead === true || isLoadingDrawer === true) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }
    return null;
  }

  renderCols = () => {
    const isBroker = JSON.parse(localStorage.getItem('isBroker'));
    const profileId = localStorage.getItem('profileId');
    let cols = copyObject(leadsCols);
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      cols = cols.filter((col) => !['companyName'].includes(col.id));
    }
    if (!(isBroker || profileId === ADMIN_ID)) {
      cols = cols.filter((col) => !['lastProforma'].includes(col.id));
    }
    if (isBroker === true && ASESOR_ID !== profileId) {
      cols = cols.filter((col) => !['brokers'].includes(col.id));
    }

    if (
      isBroker === true &&
      ASESOR_ID == profileId &&
      this.props.searchText == ''
    ) {
      if (this.props.searchText == '') {
        cols = cols.filter((col) => !['brokers'].includes(col.id));
      }
    }
    return cols;
  };

  render() {
    const {
      leads,
      isLoading,
      dataLength,
      zones,
      lead,
      users,
      allParameters,
      propertyTypes,
      leadStates,
      leadStages,
      origins,
      companies,
      stands,
      projects
    } = this.props;
    const {
      title,
      isNew,
      filterOptions,
      filterChips,
      assignBroker,
      openAppointmentModal,
      leadToEvent
    } = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasAdminPermissions = this.hasAdminPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const hasBulkAssignPermission = this.getBulkAssignPermissions();
    const newListBrokers = this.getAdvisers();
    const profileId = localStorage.getItem('profileId');
    const isSuperAdmin = SUPER_ADMIN_ROL_ID === profileId;
    const data = {
      rows: leads,
      autoFillers: { users },
      cols: this.renderCols(),
    };
    const handlers = {
      onDetail: this.onDetail,
      onEvent: this.onEvent,
      onAdd: this.onAdd,
      onBulkAdd: this.onBulkAdd,
      onDownload: this.onDownload,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onFilter: this.handleFilter,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage,
      onGetAllLeadsOfUser: this.onGetAllLeadsOfUser,
      onBulkAssign: this.onBulkAssign,
      handleChangeAssignBroker: this.handleChangeAssignBroker,
      onProformaAdmin: this.onProformaAdmin,
    };
    const config = {
      isDragable: false,
      isLeadSearch: false,
      isBulkable:
        localStorage.getItem('profileId') === ADMIN_ID ||
        localStorage.getItem('profileId') === SUPER_ADMIN_ROL_ID,
      isDownloadable: this.getDownloadPermissions(),
      isSelectable: true,
      hasEditPermission,
      hasCreatePermission,
      hasBulkAssignPermission,
      listBrokers: newListBrokers,
      assignBroker,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order,
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength,
      },
      filters: {
        is: true,
        options: filterOptions,
        chips: filterChips,
        by: this.props.filters,
      },
      origins,
      companies,
    };

    if (isLoading === true) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {this.renderLoadingLead()}
        {this.renderConfirmUpdateExistingUser()}
        {this.renderBulkResponseDialog()}
        {this.renderAlertCsv()}
        {openAppointmentModal && (
          <AppointmentModal
            open={openAppointmentModal}
            close={() => this.setState({ openAppointmentModal: false })}
            lead={leadToEvent}
          />
        )}
        {lead === null ? (
          <Fragment>
            <EnhancedTable
              title={title}
              data={data}
              handlers={handlers}
              config={config}
            />
            {!!this.state.proformaLead && (
              <ProformasAdmin
                lead={this.state.proformaLead}
                onClose={() => this.setState({ proformaLead: null })}
              />
            )}
          </Fragment>
        ) : (
          <LeadDetail
            handleGoBack={this.handleGoBack}
            zones={zones}
            users={users}
            allParameters={allParameters}
            hasAdminPermissions={hasAdminPermissions}
            leadStates={leadStates}
            leadStages={leadStages}
            onOriginChange={this.handleOriginChange}
            onSendLeadEmail={this.handleSendLeadEmail}
            onUpdateLeadMatches={this.handleUpdateLeadMatches}
            onOriginUpdate={this.handleOriginUpdate}
            onOriginProjectUpdate={this.handleOriginProjectUpdate}
            onStandsUpdate={this.handleStandsUpdate}
            onRemoveMatch={this.handleRemoveMatch}
            onRemoveMatches={this.handleRemoveMatches}
            onSwapMatchUnits={this.handleSwapMatchUnits}
            onSwapMatches={this.handleSwapMatches}
            onAddUnitMatchComment={this.handleAddUnitMatchComment}
            onAddNewAction={this.handleAddNewAction}
            onUpdateLeadUser={this.handleUpdateLeadUser}
            onUpdateLeadState={this.handleUpdateLeadState}
            onObservationsChange={this.handleObservationsChange}
            onChangeUnitComment={this.handleChangeUnitComment}
            onUpdateTemperature={this.handleUpdateTemperature}
            onUpdatePrequalification={this.handleUpdatePrequalification}
            onUpdateEstimatedClosingTime={this.handleUpdateEstimatedClosingTime}
            onUpdateClosingPrice={this.handleUpdateClosingPrice}
            onReassignLead={this.handleReassignLead}
            handleAddObservation={this.handleAddObservation}
            onUpdateUser={this.handleUpdateUser}
            onUpdateAnswer={this.handleUpdateAnswer}
            onCreateLead={this.handleCreateLead}
            onEditAttributes={this.handleEditAttributes}
            handleChangePointsInFavor={this.handleChangePointsInFavor}
            handleChangePointsAgainst={this.handleChangePointsAgainst}
            handleSeeCommentInFavor={this.handleSeeCommentInFavor}
            handleSeeCommentAgainst={this.handleSeeCommentAgainst}
            onChangeProjectMatchStage={this.handleChangeProjectMatchStage}
            isNew={isNew}
            propertyTypes={propertyTypes}
            handleChangePage={this.handleChangePageDetail}
            handleBackPage={this.handleBackPage}
            onBlockProject={this.handleBlockProject}
            onUnBlockProject={this.handleUnBlockProject}
            handleDuplicateProject={this.handleDuplicateProject}
            onReplaceMatches={this.onReplaceMatches}
            origins={origins}
            companies={companies}
            stands={stands}
            searchText={this.props.searchText}
            projects={projects}
          />
        )}
      </div>
    );
  }
}

Leads.propTypes = {
  updateLead: PropTypes.func.isRequired,
  createLead: PropTypes.func.isRequired,
  onUpdateLeadMatches: PropTypes.func.isRequired,
  onAddUnitMatchComment: PropTypes.func.isRequired,
  onSendLeadEmail: PropTypes.func.isRequired,
  addNewAction: PropTypes.func.isRequired,
  onBulkAddLeads: PropTypes.func.isRequired,
  onSwapMatchUnits: PropTypes.func.isRequired,
  downloadLeads: PropTypes.func.isRequired,
  onSwapMatches: PropTypes.func.isRequired,
  onRemoveMatch: PropTypes.func.isRequired,
  onRemoveMatches: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingDrawer: PropTypes.bool.isRequired,
  isLoadingLead: PropTypes.bool.isRequired,
  leads: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  lead: PropTypes.object,
  zones: PropTypes.array.isRequired,
  leadStates: PropTypes.array.isRequired,
  leadStages: PropTypes.array.isRequired,
  filterLeads: PropTypes.func.isRequired,
  onUpdateLeadUser: PropTypes.func.isRequired,
  onUpdateTemperature: PropTypes.func.isRequired,
  getZones: PropTypes.func.isRequired,
  getOrigins: PropTypes.func.isRequired,
  getLead: PropTypes.func.isRequired,
  getPropertyTypes: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  onChangeProjectMatchStage: PropTypes.func.isRequired,
  addObservation: PropTypes.func.isRequired,
  setLead: PropTypes.func.isRequired,
  updatePointsInFavor: PropTypes.func.isRequired,
  updatePointsAgainst: PropTypes.func.isRequired,
  updateLeadState: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  allParameters: PropTypes.array.isRequired,
  setAvailable: PropTypes.func.isRequired,
  getAllParameters: PropTypes.func.isRequired,
  onReassignLead: PropTypes.func.isRequired,
  onBlockProject: PropTypes.func.isRequired,
  onUnBlockProject: PropTypes.func.isRequired,
  onUpdateEstimatedClosingTime: PropTypes.func.isRequired,
  onUpdateClosingPrice: PropTypes.func.isRequired,
  onWinUnit: PropTypes.func.isRequired,
  getLeadStates: PropTypes.func.isRequired,
  getLeadStages: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  onReplaceMatches: PropTypes.func.isRequired,
  assignBrokerToLead: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
  dataLength: PropTypes.number.isRequired,
  bulkLeadAssign: PropTypes.func,
  onDuplicateProject: PropTypes.func.isRequired,
  origins: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  getStands: PropTypes.func,
  stands: PropTypes.array,
  getProjects: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const {
    leads,
    parameters,
    zones,
    leadStates,
    leadStages,
    users,
    drawer,
    propertyTypes,
    origins,
    companies,
    stands,
    projects
  } = state;
  return {
    isLoadingDrawer: drawer.sending,
    isLoading: leads.sending,
    isLoadingLead: leads.sendingLead,
    isLoadingParameters: parameters.sending,
    isLoadingZones: zones.sending,
    isLoadingLeadStates: leadStates.sending,
    isLoadingUsers: users.sending,
    isLoadingPropertyTypes: propertyTypes.sending,
    isLoadingCompanies: companies.sending,
    propertyTypes: propertyTypes.propertyTypes,
    leads: leads.leads,
    users: users.users,
    lead: leads.lead,
    leadStates: leadStates.leadStates,
    leadStages: leadStages.leadStages,
    zones: zones.zones,
    companies: companies.companies,
    dataLength: leads.dataLength,
    error: leads.error,
    message: leads.message,
    searchText: leads.searchText,
    allParameters: parameters.allParameters,
    order: leads.order,
    orderBy: leads.orderBy,
    page: leads.page,
    rowsPerPage: leads.rowsPerPage,
    filters: leads.filters,
    origins: origins.origins,
    stands: stands.stands,
    projects: projects.projects,
  };
};

const mapDispatchToProps = {
  filterLeads,
  createLead,
  updateLead,
  getUsers,
  getUser,
  onBulkAddLeads,
  assignBrokerToLead,
  onAddUnitMatchComment,
  editUser,
  onUpdateTemperature,
  onUpdatePrequalification,
  onSwapMatchUnits,
  onUpdateEstimatedClosingTime,
  onUpdateClosingPrice,
  onSendLeadEmail,
  onSwapMatches,
  addNewAction,
  onUpdateLeadMatches,
  onUpdateLeadUser,
  onRemoveMatch,
  onRemoveMatches,
  addObservation,
  getLead,
  setLead,
  onReassignLead,
  getZones,
  getFormattedZones,
  getOrigins,
  getLeadStates,
  getPropertyTypes,
  updateLeadState,
  getAllParameters,
  downloadLeads,
  updatePointsAgainst,
  updatePointsInFavor,
  onChangeProjectMatchStage,
  onWinUnit,
  setAvailable,
  bulkLeadAssign,
  onBlockProject,
  onUnBlockProject,
  onReplaceMatches,
  onDuplicateProject,
  getCompanies,
  getLeadStages,
  getStands,
  getProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
