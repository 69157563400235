import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Filters from './filters';
import {styles} from './styles';

export class EnhancedTableToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searching: false,
      searchText: ''
    };
    this.onFilter = this.onFilter.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancelSearch = this.handleCancelSearch.bind(this);
    this.handlePressEnterSearch = this.handlePressEnterSearch.bind(this);
    this.renderChips = this.renderChips.bind(this);
    this.renderSearchBarButtons = this.renderSearchBarButtons.bind(this);
    this.renderSearchBar = this.renderSearchBar.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
  }

  componentWillMount() {
    const {searchText} = this.props;
    if (searchText) {
      if (searchText !== '') {
        this.setState({searching: true, searchText});
      }
    }
  }

  onFilter(items) {
    const filterChips = items.filter(obj => obj.value.length > 0 && obj.value !== 'all');
    const mainFilter = {};
    filterChips.forEach((element) => {
      if (element.value !== 'all' && element.value !== '') {
        mainFilter[element.id] = {
          value: element.value,
          type: element.type
        };
      }
    });
    this.props.onFilter(mainFilter, filterChips);
  }

  onSearch() {
    const {searchText} = this.state;
    if (searchText === '') {
      this.props.onSearch(null);
    } else {
      this.props.onSearch(searchText);
    }
  }

  handlePressEnterSearch(ev) {
    if (ev.key === 'Enter') {
      this.onSearch(this.state.searchText);
      ev.preventDefault();
    }
  }

  handleCancelSearch() {
    this.setState({searching: false, searchText: ''});
    this.props.onSearch(null);
  }

  handleDelete(e, filter) {
    const {filterChips} = this.props;
    let indexToDelete = 0;
    for (let i = 0; i < filterChips.length; i += 1) {
      const element = filterChips[i];
      if (element.id === filter.id) {
        indexToDelete = i;
        break;
      }
    }
    filterChips.splice(indexToDelete, 1);
    this.onFilter(filterChips);
  }

  renderSearchBarButtons() {
    if (this.state.searching === false) {
      return (
        <Tooltip title="Buscar por nombre">
          <IconButton onClick={() => this.setState({searching: true})}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <div style={{width: 400}}>
        <Tooltip title="Buscar por nombre" style={{display: 'inline-block'}}>
          <IconButton onClick={this.onSearch}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancelar Búsqueda" style={{display: 'inline-block'}}>
          <IconButton onClick={this.handleCancelSearch}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  renderFilters() {
    const {isFilterable, filterChips, filterOptions} = this.props;
    if (isFilterable) {
      return (
        <Filters 
          options={filterOptions} 
          chips={filterChips} 
          onFilter={this.onFilter}
        />
      );
    }
    return null;
  }  

  renderSearchBar() {
    if (this.state.searching === true) {
      return (
        <div className={this.props.classes.search} >
          <TextField 
            label="Buscar por nombre" 
            fullWidth 
            autoFocus 
            value={this.state.searchText} 
            onChange={e => this.setState({searchText: e.target.value})}
            onKeyPress={this.handlePressEnterSearch}
          />
        </div>
      );
    }
    return null;
  }

  renderChips() {
    const {classes, filterChips} = this.props;
    if (filterChips) {
      if (filterChips.length > 0) {
        return (
          <Toolbar className={classNames(classes.root)}>
            {filterChips.filter(obj => obj.title !== '').map((data, index) => {
              return (
                <Chip
                  key={index}
                  label={data.title}
                  onDelete={e => this.handleDelete(e, data)}
                  className={classes.chip}
                />
              );
            })}
          </Toolbar>
        );
      }
    }
    return null;
  }

  render() {
    const {classes, title, value} = this.props;
    return (
      <div>
        <Toolbar className={classes.root}>
          <Typography variant="h6" id="tableTitle" style={{width: '100%'}}>
            {title}
          </Typography>
          <div className={classes.spacer} />
          <Typography variant="h6" id="tableTitle" style={{width: '100%'}}>
            {value ? `${Number(value).toLocaleString('de-DE', {style: 'currency', currency: 'USD'})}` : '$ 0'}
          </Typography>
          {this.renderSearchBar()}
          {this.renderSearchBarButtons()}
          {this.renderFilters()}
        </Toolbar>
        {this.renderChips()}
      </div>
    );
  }
}
  
EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  isFilterable: PropTypes.bool.isRequired,
  filterChips: PropTypes.array,
  filterOptions: PropTypes.array,
  onFilter: PropTypes.func
};

export default withStyles(styles)(EnhancedTableToolbar);
