import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { InlineDatePicker } from 'material-ui-pickers';
import { useSelector, useDispatch } from 'react-redux';
import getDashboard from '../../../../actions/dashboard';
import { ADMIN_ID, COMERCIAL_MANAGER } from '../../../../utils/utils';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const profileId = localStorage.getItem('profileId');

const FilterModal = (props) => {
  const { classes, open, close, filters } = props;

  const { users } = useSelector((state) => state.users);
  let filterUsers= users;

  const {
    user:localUser,
  } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  if(profileId === COMERCIAL_MANAGER){
    filterUsers =  users.filter((userItem) => localUser.advisers.includes(userItem.id))
  }

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [brokers, setBrokers] = useState([]);

  useEffect(() => {
    setBrokers(filters.brokers);
    setStartDate(new Date(filters.startDate));
    setEndDate(new Date(filters.endDate));
  }, [filters]);

  return (
    <>
      <Dialog
        maxWidth="md"
        scroll="body"
        open={open}
        onClose={close}
        TransitionComponent={Transition}
      >
        <DialogTitle>Filtros</DialogTitle>
        <DialogContent>
          <div className={classes.section}>
            <InlineDatePicker
              className={classes.picker}
              label="Desde"
              maxDate={endDate}
              minDate={new Date('2021-01-01T00:00:00.000-05:00')}
              value={startDate}
              format="YYYY-MM-DD"
              onChange={setStartDate}
            />
          </div>

          <div className={classes.section}>
            <InlineDatePicker
              className={classes.picker}
              label="Hasta"
              maxDate={new Date()}
              minDate={startDate}
              value={endDate}
              format="YYYY-MM-DD"
              onChange={setEndDate}
            />
          </div>

          { ((filterUsers.length > 1 && profileId === ADMIN_ID) || (filterUsers.length > 1 && profileId === COMERCIAL_MANAGER) )  && (
            <>
              <FormControl className={classes.textFields}>
                <InputLabel id="city-select">Asesores</InputLabel>
                <Select
                  value={brokers}
                  fullWidth
                  onChange={({ target: { value } }) => setBrokers(value)}
                  multiple
                >
                  {filterUsers.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(
                getDashboard(
                  {
                    brokers,
                    startDate: startDate,
                    endDate: endDate,
                  },
                  filters.brokers,
                ),
              );
              close();
            }}
            variant="contained"
            color="primary"
          >
            Filtrar
          </Button>
          <Button onClick={close} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

FilterModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilterModal);
