/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as buildersActions from './types/builders';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterBuilders = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().builders.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().builders.order || orderBy !== store.getState().builders.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: buildersActions.FILTER_BUILDER_SENDING,
    payload: {
      builders: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    const filter = {
      filters: {
        name: searchText
      },
      operator: 'or',
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order
      }
    };
    const response = await axios(axiosMaker('POST', 'builders/filter', filter, true));
    return dispatch({
      type: buildersActions.FILTER_BUILDER_SUCCESS,
      payload: {
        builders: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: buildersActions.FILTER_BUILDER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getBuilders = () => async (dispatch) => {
  dispatch({
    type: buildersActions.GET_BUILDER_SENDING,
    payload: {
      builders: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {}
    };
    if (company && company._id) {
      data.filters = {company: [company._id]};
      data.operator = 'and';
      data.page = 0;
      data.limit = 9999999999;
    }
    const response = await axios(axiosMaker('POST', 'builders/filter', data, true));
    return dispatch({
      type: buildersActions.GET_BUILDER_SUCCESS,
      payload: {
        builders: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: buildersActions.GET_BUILDER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createBuilder = (name, address, revenuePercentage, representativeName, representativeEmail, representativePhone, icon, newCompany) => async (dispatch) => {
  dispatch({
    type: buildersActions.CREATE_BUILDER_SENDING,
    payload: {}
  });
  try {
    const data = {
      representative: {
        name: representativeName,
        email: representativeEmail,
        phone: representativePhone
      },
      name,
      address,
      revenue_percentage: revenuePercentage,
      icon
    };
    if (company !== -1) {
      data.company = newCompany;
    }

    await axios(axiosMaker('POST', 'builders', data, true));
    return dispatch({
      type: buildersActions.CREATE_BUILDER_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: buildersActions.CREATE_BUILDER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateBuilder = (id, builders) => async (dispatch) => {
  dispatch({
    type: buildersActions.UPDATE_BUILDER_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `builders/${id}`, builders, true));
    return dispatch({
      type: buildersActions.UPDATE_BUILDER_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: buildersActions.UPDATE_BUILDER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const filterCompanyBuilders = (id, unallocated = true) => async (dispatch) => {
  dispatch({
    type: buildersActions.FILTER_BUILDER_SENDING,
    payload: {}
  });
  try {
    const options = unallocated ? [null, id] : [id];
    const filter = {
      filters: {company: {$in: options}},
      operator: 'and',
      page: 0,
      limit: 0,
      sort: {}
    };
    const response = await axios(axiosMaker('POST', 'builders/filter', filter, true));
    return dispatch({
      type: buildersActions.FILTER_BUILDER_SUCCESS,
      payload: {
        builders: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: buildersActions.FILTER_BUILDER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const bulkBuilders = (updated, setNulls, newCompany) => async (dispatch) => {  
  dispatch({
    type: buildersActions.UPDATE_BUILDER_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'builders/bulk', {updated, setNulls, company: newCompany}, true));
    return dispatch({
      type: buildersActions.UPDATE_BUILDER_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: buildersActions.UPDATE_BUILDER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const areDisposable = async (ids) => {
  try {
    const response = await axios(axiosMaker('POST', 'builders/areDisposable', {builders: ids}, true));    
    return response.data.result;
  } catch (e) {
    return false;
  }
};
