import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {MenuItem, Select} from '@material-ui/core';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {isNumber, SUPER_ADMIN_ROL_ID} from '../../../../utils/utils';
import Uploader from '../../../common/uploader';
import {areDisposable} from '../../../../actions/builders';
import CustomSnackbar from '../../../common/customSnackbar';

export class BuildersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      name: '',
      icon: '',
      address: '',
      revenuePercentage: '',
      representativeName: '',
      representativeEmail: '',
      representativePhone: '',
      company: '',
      isDisposable: true
    };
    this.renderButton = this.renderButton.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.renderDisposableSnackBar = this.renderDisposableSnackBar.bind(this);
  }

  componentWillMount() {
    const {isNew, builder} = this.props;
    if (isNew === false) {
      if (builder) {
        this.setState({
          builder,
          name: builder.name,
          address: builder.address,
          representativeName: builder.representative.name,
          representativeEmail: builder.representative.email,
          representativePhone: builder.representative.phone,
          active: builder.active,
          revenuePercentage: builder.revenue_percentage,
          icon: builder.icon,
          company: builder.company ? builder.company.id : -1
        });
      }
    }
  }

  onAddButtonClick() {
    const {
      name,
      address,
      revenuePercentage,
      representativeName,
      representativeEmail,
      representativePhone,
      icon,
      company
    } = this.state;
    this.props.handleCreateBuilder(
      name,
      address,
      revenuePercentage,
      representativeName,
      representativeEmail,
      representativePhone,
      icon,
      company && company !== -1 ? company : null
    );
  }

  // eslint-disable-next-line consistent-return
  async onEditButtonClick() { 
    const {
      builder,
      active,
      name,
      address,
      revenuePercentage,
      representativeName,
      representativeEmail,
      representativePhone,
      icon,
      company
    } = this.state;
    const representative = {
      name: representativeName,
      email: representativeEmail,
      phone: representativePhone
    };

    const oldCompany = this.props.builder.company;

    
    if (oldCompany && oldCompany.id !== company) {
      const isDisposable = await areDisposable([builder.id]);
      if (!isDisposable) {
        return this.setState({isDisposable: false, company: oldCompany.id});
      }
    }

    const editedBuilder = {
      id: builder.id,
      active,
      name,
      address,
      icon,
      revenue_percentage: Number(revenuePercentage),
      representative,
      company
    };
    this.props.handleUpdateBuilder(editedBuilder);
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  isInvalid() {
    const {name} = this.state;
    if (name !== '') {
      return false;
    }
    return true;
  }

  handleUrlUploaded(icon) {
    this.setState({icon: icon === false ? '' : icon});
  }

  handleChangeCompany(e) {
    const company = e.target.value;
    this.setState({company});
  }

  renderCompanies() {
    const {companies} = this.props;
    const unallocated = {id: -1, name: 'Sin asignar'};
    
    
    return [unallocated, ...companies].map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>
          {item.name}
        </MenuItem>
      );
    });
  }

  renderRowCompany(classes) {
    const profileId = localStorage.getItem('profileId');
    const {company} = this.state;
    return (
      SUPER_ADMIN_ROL_ID === profileId && (
        <TableRow>
          <TableCell className={classes.tableRowTitle}>
            <b>Empresa</b>
          </TableCell>
          <TableCell>
            <Select
              value={company}
              fullWidth
              onChange={this.handleChangeCompany}
            >
              {this.renderCompanies()}
            </Select>
          </TableCell>
        </TableRow>
      )
    );
  }

  renderButton() {
    const {isLoading, isNew, classes} = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText={`¿Está seguro que desea ${
            isNew ? 'crear' : 'modificar'
          } esta constructora?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} Constructora`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderDisposableSnackBar() {
    const {isDisposable} = this.state;
    if (!isDisposable) {
      this.setState({isDisposable: true});
      return (
        <CustomSnackbar
          variant="error"
          message="No se puede cambiar la compañía porque la constructora tiene leads dependientes"
        />
      );
    }
    return null;
  }

  render() {
    const {classes, isLoading, isNew} = this.props;
    return isLoading === true ? (
      <CircularProgress
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto'
        }}
      />
    ) : (
      <Paper elevation={2} className={classes.paperRoot}>
        {this.renderDisposableSnackBar()}
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de ' : 'Nueva '} constructora
          </Typography>
        </Toolbar>
        <div style={{width: 'auto', overflowX: 'scroll'}}>
          <Table>
            <TableBody>
              {!isNew && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Disponible</b>
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.active}
                          onChange={e =>
                            this.setState({active: e.target.checked})
                          }
                          color="primary"
                        />
                      }
                      label={
                        this.state.active === true
                          ? 'Disponible'
                          : 'No disponible'
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  <TextField
                    autoFocus
                    value={this.state.name}
                    onChange={e => this.setState({name: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Dirección</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.address}
                    onChange={e => this.setState({address: e.target.value})}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Nombre del representante</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.representativeName}
                    onChange={e =>
                      this.setState({representativeName: e.target.value})
                    }
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Email del representante</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.representativeEmail}
                    onChange={e =>
                      this.setState({representativeEmail: e.target.value})
                    }
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Teléfono del representante</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.representativePhone}
                    onChange={e =>
                      this.setState({representativePhone: e.target.value})
                    }
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              {this.renderRowCompany(classes)}
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Porcentaje de comisión</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.revenuePercentage}
                    onChange={e =>
                      isNumber(e.target.value) &&
                      this.setState({revenuePercentage: e.target.value})
                    }
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRowTitle}>
                <TableCell>
                  <b>Foto</b>
                </TableCell>
                <TableCell>
                  {this.state.icon && (
                    <img
                      src={this.state.icon}
                      alt="Foto"
                      style={{width: 100}}
                    />
                  )}
                  <Uploader
                    buttonText={
                      this.state.icon ? 'Actualizar foto' : 'Subir foto'
                    }
                    photo
                    dirName="Builders"
                    urlUploaded={this.handleUrlUploaded}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>{this.renderButton()}</div>
      </Paper>
    );
  }
}

BuildersDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  builder: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleCreateBuilder: PropTypes.func.isRequired,
  handleUpdateBuilder: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired
};

export default withStyles(styles)(BuildersDetails);
