import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles, Slide} from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import Place from '@material-ui/icons/Place';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {styles} from './styles';
 
const MarkerComponent = ({content}) => <div>{content}</div>;
MarkerComponent.propTypes = {
  content: PropTypes.object.isRequired
};

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

class SimpleDialogMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: -0.22985,
      long: -78.5249481
    };
    this.selectPosition = this.selectPosition.bind(this);
    this.successActionMap = this.successActionMap.bind(this);
  }

  selectPosition(e) {
    this.setState({lat: e.lat, long: e.lng});
  }

  successActionMap() {
    this.props.successAction({...this.state});
  }

  render() {
    const {classes, isOpen, closeDialog, title} = this.props;
    let {lat, long} = this.props;
    if ((!lat && !long)) {
      lat = this.state.lat;
      long = this.state.long;
    } 
    return isOpen && (
      <div>
        <Dialog 
          disableBackdropClick 
          disableEscapeKeyDown 
          open={isOpen}
          fullScreen
          onClose={this.closeDialog} 
          TransitionComponent={Transition} 
          className={classes.muiDialog}
        >
          <DialogTitle>{title || 'Ubicación'}
            <IconButton className={classes.closeButton} edge="start" color="inherit" onClick={closeDialog} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent >
            <div className={classes.mapContainer} > 
              <GoogleMapReact
                bootstrapURLKeys={{key: 'AIzaSyCRlpd9z_60R4K_R4QVBENJ6D3jZbLfU94'}}
                defaultCenter={{
                  lat,
                  lng: long 
                }}
                defaultZoom={12}
                options={{fullscreenControl: false}}
                onClick={this.selectPosition}
              >
                <MarkerComponent
                  lat={lat}
                  lng={long}
                  content={<Place className={classes.markerTrivo} color="secondary" fontSize="large" />}
                />
              </GoogleMapReact>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
SimpleDialogMap.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  successAction: PropTypes.func,
  title: PropTypes.string,
  lat: PropTypes.number,
  long: PropTypes.number
};

export default (withStyles(styles)(SimpleDialogMap));
