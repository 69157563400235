/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import { InlineDatePicker } from 'material-ui-pickers';
import Slide from '@material-ui/core/Slide';
import Lock from '@material-ui/icons/Lock';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { styles } from './styles';
import ConfirmationDialog from '../common/confirmationDialog';
import {
  getForm,
  createForm,
  getAnswers,
  updateForm,
} from '../../actions/smart';
import { copyObject } from '../../utils/utils';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';

const SaleProfile = (props) => {
  const { form, sending, answers } = useSelector((state) => state.smart);
  const [checked, setChecked] = useState([]);
  const [city, setCity] = useState('');

  const [dinamycForm, setDynamicData] = useState([]);
  const [errorForm, setErrorForm] = useState([]);
  const [validDinamyc, setValidDinamyc] = useState(false);
  const [filledAnswers, setFilledAnswers] = useState(true);
  const [citiesProfile, setCitiesProfile] = useState(null);

  const dispatch = useDispatch();

  const handleDateChange = (date, indexFormDinamyc) => {
    dinamycForm[indexFormDinamyc] = {
      ...dinamycForm[indexFormDinamyc],
      answer: date,
    };
    setDynamicData([...dinamycForm]);
  };

  useEffect(() => {
    const { unit } = props;
    dispatch(getForm());
    if (unit.isProfiled) {
      dispatch(getAnswers(unit.id));
    }
  }, []);

  useEffect(() => {
    const formatedData = [];
    if (form.length > 0) {
      form.map((section) => {
        section.attributes.map((question) => {
          formatedData.push({
            id: question.id,
            name: question.name,
            type: question.type,
            answer: question.type == 'datetime' ? null : '',
          });
        });
      });
      setDynamicData(formatedData);
    }
  }, [form]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleChange = (event, indexFormDinamyc) => {
    dinamycForm[indexFormDinamyc].answer = event.target.value;
    setDynamicData([...dinamycForm]);
  };

  const handleChangeBack = (event, indexFormDinamyc) => {
    dinamycForm[indexFormDinamyc].answer = event.target.value;
    setDynamicData(dinamycForm);
  };

  const saveForm = async () => {
    const { handleGoBack, unit, leadId } = props;
    const formToSave = copyObject(dinamycForm);
    if (unit.isProfiled) {
      await dispatch(updateForm(formToSave, unit.id, unit.projectId, leadId));
    } else {
      await dispatch(createForm(formToSave, unit.id, unit.projectId, leadId));
    }
    handleGoBack();
  };

  const validForm = () => {
    const validFormData = copyObject(dinamycForm);
    let valid = true;
    const errorFormData = validFormData.map((item) => {
      if (item.answer) {
        item.error = false;
      } else {
        item.error = true;
        valid = false;
      }
      return item;
    });
    setErrorForm(errorFormData);
    setValidDinamyc(true);
    return valid;
  };

  const renderButton = () => {
    return (
      <ConfirmationDialog
        confirmationText="¿Estás seguro de la información ingresada? Esta información no podra ser editada una vez guardado el fromulario."
        buttonText="Finalizar"
        variant="contained"
        isDisabled={false}
        confirmAction={saveForm}
        validOption
        validFunction={validForm}
      />
    );
  };

  const checkList = (items) => {
    const { classes } = props;
    return (
      <List className={classes.root}>
        {items.map((value) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem
              key={value}
              role={undefined}
              dense
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${value}` }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const radioGroup = (items, indexFormDinamyc) => {
    const { classes } = props;
    return (
      <div className={classes.root} style={{ paddingLeft: '20px' }}>
        <RadioGroup
          aria-label="Gender"
          name={`item-${indexFormDinamyc}`}
          className={classes.group}
          value={dinamycForm[indexFormDinamyc].answer}
          onChange={(e) => handleChange(e, indexFormDinamyc)}
        >
          {items.map((value) => {
            const labelId = `checkbox-list-label-${value.id}`;

            return (
              <FormControlLabel
                key={labelId}
                value={value.id}
                control={<Radio />}
                label={value.name}
              />
            );
          })}
        </RadioGroup>
        <div className={classes.errorDiv}>
          {validDinamyc && errorForm[indexFormDinamyc].error
            ? 'campo requerido'
            : ''}
        </div>
      </div>
    );
  };

  const renderZone = (items, indexFormDinamyc) => {
    const [
      {
        provinces: [
          {
            cities: [{ zones }],
          },
        ],
      },
    ] = items.options;
    const { classes } = props;
    return (
      zones && (
        <div className={classes.root} style={{ paddingLeft: '20px' }}>
          <RadioGroup
            aria-label="Zona"
            name="zone"
            className={classes.group}
            value={dinamycForm[indexFormDinamyc].answer}
            onChange={(e) => handleChange(e, indexFormDinamyc)}
          >
            {zones.map((zone) => {
              return zone.sectors.map((value) => {
                const labelId = `checkbox-list-label-${value.id}`;
                return (
                  <FormControlLabel
                    key={labelId}
                    value={value.id}
                    control={<Radio />}
                    label={value.name}
                  />
                );
              });
            })}
          </RadioGroup>
          <div className={classes.errorDiv}>
            {validDinamyc && errorForm[indexFormDinamyc].error
              ? 'campo requerido'
              : ''}
          </div>
        </div>
      )
    );
  };

  const renderZones = (items, indexFormDinamyc) => {
    const [
      {
        provinces: [
          {
            cities: [{ zones }],
          },
        ],
      },
    ] = items.options;
    const [{ provinces }] = items.options;
    let cities = provinces.reduce((item, i) => {
      return item.concat(i.cities);
    }, []);
    if (!cities || cities.length === 0) return [];
    if (city) {
      cities = cities.filter((cityItem) => cityItem.id === city);
    }
    let zonesArray = [];
    cities.map((city, cityIndex) => {
      const response = city.zones.reduce((acc, val, index) => {
        acc.push(
          <Typography
            className={classes.zoneLabel}
            key={`zone_${cityIndex}_${index}`}
          >
            {val.name}
          </Typography>,
        );
        val.sectors.map((item, i) =>
          acc.push(
            <MenuItem
              className={classes.zoneMenu}
              value={item.id}
              key={`sector_${cityIndex}_${index}_${i}`}
            >
              {item.name}
            </MenuItem>,
          ),
        );
        return acc;
      }, []);
      zonesArray = [...zonesArray, ...response];
    });
    return zonesArray;
  };

  const handleChangeCity = ({ target }) => {
    const { value } = target;
    setCity(value);
  };

  const renderCities = (cities) => {
    return cities.map((item, index) => (
      <MenuItem value={item.id} key={`cities_${index}`}>
        {item.name}
      </MenuItem>
    ));
  };

  const renderCity = (items) => {
    const [{ provinces }] = items.options;
    const cities = provinces.reduce((item, i) => {
      return item.concat(i.cities);
    }, []);
    if (!cities || cities.length === 0) return [];
    return (
      <FormControl style={{ width: '100%' }} className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Ciudad</InputLabel>
        <Select
          id="demo-simple-select"
          value={city}
          onChange={handleChangeCity}
        >
          {renderCities(cities)}
        </Select>
      </FormControl>
    );
  };

  const renderZoneSelect = (items, indexFormDinamyc) => {
    return (
      <div style={{ padding: '10px' }}>
        {renderCity(items)}
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="zone-select">
            Zona
          </InputLabel>
          <Select
            value={dinamycForm[indexFormDinamyc].answer}
            fullWidth
            onChange={(e) => {
              handleChange(e, indexFormDinamyc);
            }}
          >
            {renderZones(items, indexFormDinamyc)}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderDate = (indexFormDinamyc) => {
    const { classes } = props;
    return (
      <div style={{ marginBottom: '20px', marginTop: '20px' }}>
        <InlineDatePicker
          value={dinamycForm[indexFormDinamyc].answer}
          onChange={(date) => handleDateChange(date, indexFormDinamyc)}
          placeholder="Seleeccione fecha"
          format="YYYY-MM-DD"
          className={classes.datePicker}
        />
        <br />
        <div className={classes.errorDiv2}>
          {validDinamyc && errorForm[indexFormDinamyc].error
            ? 'campo requerido'
            : ''}
        </div>
      </div>
    );
  };

  const renderItem = (question, indexFormDinamyc) => {
    switch (question.type) {
      case 'radio_group':
        return radioGroup(question.options, indexFormDinamyc);
      case 'datetime':
        return renderDate(indexFormDinamyc);
      case 'zone':
        return renderZoneSelect(question, indexFormDinamyc);
      default:
        return '';
    }
  };

  const renderQuestions = () => {
    let indexFormDinamyc = 0;
    return form.map((category, index) => {
      return (
        <div key={index}>
          <br />
          <br />
          <Typography
            variant="h6"
            style={{
              marginBottom: '0px',
              color: 'rgb(0, 163, 146)',
              fontWeight: 'bold',
            }}
          >
            {category.title}
          </Typography>
          <div>
            {category.attributes.map((question, indexQ) => {
              return (
                <div key={indexQ}>
                  <Divider style={{ marginBottom: '20px' }} />
                  <div>
                    <div style={{ fontWeight: 'bold' }}>{question.name}</div>
                    <div>{renderItem(question, indexFormDinamyc++)}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  const fillAnswers = () => {
    dinamycForm.map((dynamicItem, indexData) => {
      answers.map((answerItem) => {
        if (answerItem.attribute == dynamicItem.id) {
          dinamycForm[indexData].answer = answerItem.answer;
        }
      });
      return dynamicItem;
    });

    setDynamicData([...dinamycForm]);
    setFilledAnswers(false);
  };

  const { classes } = props;
  return sending === true || dinamycForm.length === 0 ? (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    />
  ) : (
    <Slide direction="up" in={!sending} mountOnEnter unmountOnExit>
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar className={classes.headerContainer}>
          <Typography variant="h4">
            ¡Felicitaciones por esta nueva venta!
          </Typography>
        </Toolbar>
        <div>
          <div className={classes.container}>
            <div>
              <div className={classes.header}>
                <div className={classes.titleContainer}>
                  <br />
                  <div style={{ display: 'inline-flex' }}>
                    <Typography variant="body1" className={classes.subtitle}>
                      <Lock color="primary" style={{ fontSize: '30px' }} />{' '}
                      <span style={{ color: 'red', fontWeight: 'bold' }}>
                        {' '}
                        Politica de Privacidad:
                      </span>{' '}
                      !La información ingresada se convierte a data genérica
                      para alimentar la información de la industria en general.
                      No se identifica de que Constructor o Proyecto proviene.
                      Esta información permite a Quito ser la primera ciudad de
                      Latam con inteligencia inmobiliaria en tiempo real.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            {renderQuestions()}
            {filledAnswers ? fillAnswers() : ''}
          </div>

          <div className={classes.submitContainer}>{renderButton()}</div>
        </div>
      </Paper>
    </Slide>
  );
};

SaleProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func,
};

export default withStyles(styles)(SaleProfile);
