export const styles = (theme) => ({
  root: {
    width: '100%',
  },
  title: {
    color: 'dimgrey',
    fontWeight: '600',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  actionContainer: {
    justifyContent: 'center',
  },
  cardContainer: {
    color: 'white',
  },
  cardHeader: {
    padding: '0 16px',
    color: 'white',
    paddingTop: 8,
    '& span': {
      fontSize: 16,
      fontWeight: '500',
      color: 'grey',
    },
  },
  cardContent: {
    paddingBottom: '8px !important',
    paddingTop: 8,
    backgroundColor: '#272c34',
    borderRadius: 8,
    margin: '8px 16px',
    marginBottom: 16,
  },
  copyIcon: {
    '&:focus': {
      outline: 'none',
    },
  },
  reloadIcon: {
    '&:focus': {
      outline: 'none',
    },
    color: 'white',
  },
  selectableText: {
    color: 'white',
    textTransform: 'none',
    overflowWrap: 'anywhere',
    textAlign: 'justify',

    '&:focus': {
      outline: 'none',
    },

    '& span': {
      overflowWrap: 'anywhere',
      textAlign: 'justify',
    },
  },
  textHighlight: {
    color: '#2cbeac',
  },
  snack: {
    background: '#4caf50',
  },
  snackError: {
    background: 'red',
  },
  textFields: {
    width: '80%',
    marginLeft: '10%',
  },
  labelConfirmed: {
    color: '#4caf50',
  },
  labelUnconfirmed: {
    color: 'lightgrey',
  },
  loader: {
    width: '15px !important',
    height: '15px !important',
    color: 'white',
  },
  fbActionContainer: {
    flex: 1,
    textAlign: 'right',
  },
  bodyPanel: {
    display: 'inline-grid',
    width: '100%'
  },
  expandedContainer: {
    margin: '0px 40px',
  },
  itemListCredentials: {
    background: '#efefef',
    padding: '10px',
    marginBottom: '2px',
  },
  textToken: {
    overflowWrap: 'anywhere',
  },
  subtitleList: {
    fontWeight: 'bold',
  },
  chip: {
    marginLeft: 12,
    height: 'auto',
    padding: 4,
    backgroundColor: theme.palette.error.main,
    '& span': {
      color: 'white',
      fontWeight: 'bold',
    },
    '&.subscribed': {
      backgroundColor: 'rgb(76, 175, 80)',
    },
  },
  buttonContainer: {
    margin: '0 auto',
    paddingTop: 16,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
});

export default styles;
