import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Phone from '@material-ui/icons/Phone';
import Mail from '@material-ui/icons/Mail';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import { styles } from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {
  NEW_LEAD,
  copyObject,
  SUPER_ADMIN_ROL_ID,
  axiosMaker,
  ASESOR_JR_ID,
  ASESOR_ID,
  IDENTIFICATION_CARD_ID,
} from '../../../../utils/utils';
import DuplicateLead from './duplicateLeadModal';
import { Revisita } from '../revisita';
import verifyIdentification from './../../../../utils/validations/validateIdentification';
import { validateDocument } from './../../../../actions/users';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

export class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingUser: false,
      lead: null,
      openAppointmentModal: false,
      openDuplicatesModal: false,
      openValidationDialog: false,
      leadsWithSameDocument: [],
      leadsWithSamePhone:[],
      brokers: [],
    };
    this.isInvalid = this.isInvalid.bind(this);
    this.renderEditLeadTop = this.renderEditLeadTop.bind(this);
    this.renderLeadUserPhone = this.renderLeadUserPhone.bind(this);
    this.renderLeadUserMail = this.renderLeadUserMail.bind(this);
    this.renderLeadUserDocument = this.renderLeadUserDocument.bind(this);
    this.renderLeadUserName = this.renderLeadUserName.bind(this);
    this.renderLoadingLead = this.renderLoadingLead.bind(this);
    this.handleChangeLeadUserName = this.handleChangeLeadUserName.bind(this);
    this.handleChangeLeadUserLastname = this.handleChangeLeadUserLastname.bind(this);
    this.handleChangeLeadUserEmail = this.handleChangeLeadUserEmail.bind(this);
    this.handleChangeLeadUserPhone = this.handleChangeLeadUserPhone.bind(this);
    this.handleChangeLeadOrigin = this.handleChangeLeadOrigin.bind(this);
    this.handleChangeLeadCompany = this.handleChangeLeadCompany.bind(this);
    this.handleUpdateLeadUser = this.handleUpdateLeadUser.bind(this);
    this.handleCreateLead = this.handleCreateLead.bind(this);
    this.handleChangeLeadUserDocument = this.handleChangeLeadUserDocument.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.handleCloseValidationDialog = this.handleCloseValidationDialog.bind(this);
  }

  componentWillMount() {
    const { isNew, lead, users } = this.props;
    if (lead) {
      let company = JSON.parse(localStorage.getItem('company'));
      company = company ? { company: company._id } : {};

      const getBrokers = users.filter(
        (user) =>
          (user.profile && user.profile.id) === ASESOR_JR_ID ||
          (user.profile && user.profile.id) === ASESOR_ID,
      );

      this.setState({
        lead: { ...copyObject(lead), ...company },
        brokers: getBrokers,
      });
    }
    if (isNew) {
      this.setState({ isEditingUser: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.lead !== nextProps.lead) {
      this.setState({ lead: copyObject(nextProps.lead) });
    }
  }

  handleCreateLead(notificationLead) {
    const { lead, brokers } = this.state;
    this.setState({ lead: copyObject(NEW_LEAD) });

    const isBroker = localStorage.getItem('isBroker');

    if (notificationLead) {
      axios(axiosMaker('POST', 'leads/bulkNotifications', notificationLead, true));
    } else {
      if (!isBroker && lead.brokerId) {
        const broker = brokers.find((x) => x.id === lead.brokerId);
        const assignedBroker = [
          {
            userId: lead.brokerId,
            username: `${broker.name.trim()} ${broker.lastname.trim()}`,
            active: true,
            date_assigned: new Date().toISOString(),
          },
        ];

        lead.brokers = assignedBroker;
        delete lead.brokerId;
      }

      if (isBroker) {
        lead.brokers = {
          userId: localStorage.getItem('user'),
          username: localStorage.getItem('userName'),
          active: true,
          date_assigned: new Date().toISOString(),
        };
      }

      this.props.onCreateLead(lead);
    }
  }

  handleChangeLeadOrigin(e) {
    this.props.onUpdateOrigin(e.target.value);
    const { lead } = this.state;
    lead.origin = e.target.value;

    this.setState({ lead });
  }

  handleChangeLeadCompany(e) {
    const { lead } = this.state;
    lead.company = e.target.value;
    this.setState({ lead });
  }

  handleChangeLeadUserName(e) {
    const { lead } = this.state;
    lead.user.name = e.target.value;
    this.setState({ lead });
  }

  handleChangeLeadUserLastname(e) {
    const { lead } = this.state;
    lead.user.lastname = e.target.value;
    this.setState({ lead });
  }

  handleChangeLeadUserEmail(e) {
    const { lead } = this.state;
    lead.user.email = e.target.value;
    this.setState({ lead });
  }

  handleChangeLeadUserPhone(e) {
    const { lead } = this.state;
    lead.user.phone = e.target.value;
    this.setState({ lead });
  }

  handleChangeLeadUserDocument(e) {
    const { lead } = this.state;
    lead.user.document = e.target.value;
    this.setState({ lead });
  }

  handleUpdateLeadUser() {
    const { lead } = this.state;
    const originalUser = this.props.lead.user;
    const { user } = lead;
    const userToUpdate = {
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      phone: user.phone,
      document: user.document,
    };
    if (originalUser.email === userToUpdate.email) {
      delete userToUpdate.email;
    }
    this.props.onUpdateLeadUser(lead._id, userToUpdate);
    this.setState({ isEditingUser: false });
  }

  handleCloseValidationDialog() {
    this.setState({ openValidationDialog: false, leadsWithSameDocument: [], leadsWithSamePhone:[] });
  }

  isInvalid() {
    const { lead } = this.state;
    if (
      lead.origin &&
      lead.user.name !== '' &&
      lead.user.lastname !== '' &&
      lead.user.email !== '' &&
      lead.user.phone !== '' &&
      !(
        lead.user.document &&
        lead.user.document !== '' &&
        !verifyIdentification(lead.user.document, IDENTIFICATION_CARD_ID)
      )
    ) {
      return false;
    }
    return true;
  }

  async onValidate() {
    console.log(" this.state.lead",  this.state.lead);
    const {
      company,
      user: { document, email, phone },
    } = this.state.lead;

    if ((document && document.length > 0) || phone ) {
      const validation = await validateDocument({
        document,
        email,
        company,
        phone
      });
      const validationWithData = typeof validation === 'object' &&  !Array.isArray(validation) &&
      validation !== null;

      if (validation && validationWithData && validation.document && validation.phone && (validation.document.length >0 || validation.phone.length >0 ))
        this.setState({
          openValidationDialog: true,
          leadsWithSameDocument: validation.document,
          leadsWithSamePhone: validation.phone,
        });
      else this.setState({ openDuplicatesModal: true });
    } else {
      this.setState({ openDuplicatesModal: true });
    }
  }

  renderButton() {
    const { isLoading, classes, isNew } = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText={
            isNew
              ? '¿Está seguro que desea crear este Lead?'
              : '¿Está seguro que desea modificar este Lead?'
          }
          buttonText={isNew ? 'Crear Lead' : 'Actualizar Lead'}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={() => (isNew ? this.onValidate() : this.handleUpdateLeadUser())}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderLeadUserName() {
    const { lead } = this.state;
    if (lead.user) {
      if (lead.user.name) {
        return `${lead.user.name} ${lead.user.lastname}`;
      }
    }
    return null;
  }

  renderLeadUserPhone() {
    const { lead } = this.state;
    if (lead.user) {
      if (lead.user.phone) {
        return lead.user.phone;
      }
    }
    return null;
  }

  renderLeadUserMail() {
    const { lead } = this.state;
    if (lead.user) {
      if (lead.user.email) {
        return lead.user.email;
      }
    }
    return null;
  }

  renderLeadUserDocument() {
    const { lead } = this.state;
    if (lead.user) {
      if (lead.user.document) {
        return lead.user.document;
      }
    }
    return null;
  }

  renderEditLeadTop() {
    const { classes, isNew, origins, companies } = this.props;
    const { lead, brokers } = this.state;
    const { user, origin, company } = lead;
    const profileId = localStorage.getItem('profileId');
    const isSuperAdmin = SUPER_ADMIN_ROL_ID === profileId;

    const currentCompany = companies && companies.find((x) => x.id === lead.company);

    const showBrokers =
      isNew &&
      currentCompany &&
      currentCompany.assigmentConfiguration &&
      currentCompany.assigmentConfiguration.type === 'Manual' &&
      !localStorage.getItem('isBroker');

    const hasJuniorRol =
      currentCompany &&
      currentCompany.assigmentConfiguration &&
      currentCompany.assigmentConfiguration.hasJuniorRol;

    return (
      <div>
        <Toolbar>
          <Typography variant="subtitle2" style={{ width: 300 }}>
            {isNew ? 'Ingrese el ' : 'Editar '} usuario del lead
          </Typography>
          <div className={classes.spacer} />
          <Tooltip title="Salir del modo de edición">
            <IconButton onClick={() => this.setState({ isEditingUser: false })}>
              <Close />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <div style={{ width: 'auto', overflowX: 'scroll' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={user.name}
                    autoFocus
                    fullWidth
                    onChange={this.handleChangeLeadUserName}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Apellido</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={user.lastname}
                    fullWidth
                    onChange={this.handleChangeLeadUserLastname}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Telefono</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={user.phone}
                    fullWidth
                    onChange={this.handleChangeLeadUserPhone}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Email</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={user.email}
                    fullWidth
                    onChange={this.handleChangeLeadUserEmail}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Identificación</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={user.document || ''}
                    onChange={this.handleChangeLeadUserDocument}
                    error={
                      user.document &&
                      user.document !== '' &&
                      !verifyIdentification(user.document, IDENTIFICATION_CARD_ID)
                    }
                    fullWidth
                    id="documentText"
                  />
                </TableCell>
              </TableRow>
              {isNew && isSuperAdmin && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Empresa</b>
                  </TableCell>
                  <TableCell>
                    <Select value={company || ''} onChange={this.handleChangeLeadCompany} fullWidth>
                      {companies.map((item, companyIndex) => (
                        <MenuItem value={item.id} key={companyIndex}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              )}
              {isNew && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Origen</b>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={origin || ''}
                      onChange={this.handleChangeLeadOrigin}
                      fullWidth
                      disabled={!company && isSuperAdmin}
                    >
                      {origins
                        .filter((orign) => orign.company._id === company)
                        .map((orign, originIndex) => (
                          <MenuItem value={orign.id} key={originIndex}>
                            {orign.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                </TableRow>
              )}
              {showBrokers && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Asesor</b>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={lead.brokerId || ''}
                      onChange={(e) =>
                        this.setState({
                          lead: { ...lead, brokerId: e.target.value },
                        })
                      }
                      fullWidth
                    >
                      {brokers
                        .filter((broker) => {
                          const profile = hasJuniorRol ? ASESOR_JR_ID : ASESOR_ID;
                          return (
                            broker.company.id === lead.company && broker.profile.id === profile
                          );
                        })
                        .map((item, companyIndex) => (
                          <MenuItem value={item.id} key={companyIndex}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <br />
        <div className={classes.submitContainer}>{this.renderButton()}</div>
      </div>
    );
  }

  renderLoadingLead() {
    const { isLoading } = this.props;
    const { lead } = this.state;
    if (isLoading === true || lead === null) {
      return (
        <div
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </div>
      );
    }
    return null;
  }

  renderValidationDialog() {
    const {
      state: {
        openValidationDialog,
        leadsWithSameDocument,
        leadsWithSamePhone,
        lead: {
          user: { document, phone },
        },
      },
      handleCreateLead,
      handleCloseValidationDialog,
    } = this;

    return (
      <Dialog
        open={openValidationDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseValidationDialog}
      >
        <DialogTitle>Advertencia</DialogTitle>
        <DialogContent>
          { leadsWithSameDocument.length >= 1 && <DialogContentText>
            <Typography variant="subtitle1">{`La cédula ${document} está vinculada con ${
              leadsWithSameDocument.length > 1 ? 'los usuarios' : 'el usuario'
            }:`}</Typography>
            <br />
            <ul>
              {leadsWithSameDocument.map((item, index) => (
                <li>
                  <Typography style={{ color: 'grey' }} key={`leads-email-${index}`}>
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
            <br />
            
          </DialogContentText>}
          { leadsWithSamePhone.length >= 1 && <DialogContentText>
            <Typography variant="subtitle1">{`El teléfono ${phone} está vinculada con ${
              leadsWithSamePhone.length > 1 ? 'los usuarios' : 'el usuario'
            }:`}</Typography>
            <br />
            <ul>
              {leadsWithSamePhone.map((item, index) => (
                <li>
                  <Typography style={{ color: 'grey' }} key={`leads-email-${index}`}>
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
            <br />
          </DialogContentText>
          }
          <Typography variant="subtitle1">Desea continuar con la creación del lead</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseValidationDialog} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleCreateLead();
              handleCloseValidationDialog();
            }}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, isNew, lead } = this.props;
    const { isEditingUser, openDuplicatesModal, openValidationDialog } = this.state;
    let stage = '';
    if (lead.stage !== '') {
      if (lead.status) {
        if (lead.status.stages.length > 0) {
          const stageExists = lead.status.stages.filter((obj) => obj.id === lead.stage);
          if (stageExists.length > 0) {
            stage = stageExists[0].name;
          }
        }
      }
    }

    return (
      <div>
        {this.renderLoadingLead()}
        {!isEditingUser ? (
          <div style={{ width: 'auto', overflowX: 'scroll' }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ minWidth: 400 }}>
                    <div className={classes.submitContainer}>
                      <Toolbar className={classes.smallBar}>
                        <Typography variant="h4">{this.renderLeadUserName()}</Typography>
                        <Tooltip title="Editar">
                          <IconButton onClick={() => this.setState({ isEditingUser: true })}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Toolbar>
                      <Toolbar className={classes.smallBar}>
                        <Phone />
                        <a href={`tel::${this.renderLeadUserPhone()}`}>
                          <Typography variant="subtitle1">
                            &nbsp; {this.renderLeadUserPhone()}
                          </Typography>
                        </a>
                      </Toolbar>
                      <Toolbar className={classes.smallBar}>
                        <Mail />
                        <a href={`mailto:${this.renderLeadUserMail()}`}>
                          <Typography variant="subtitle1">
                            &nbsp; {this.renderLeadUserMail()}
                          </Typography>
                        </a>
                      </Toolbar>
                      {this.renderLeadUserDocument() && (
                        <Toolbar className={classes.smallBar}>
                          <AccountBoxIcon />
                          <Typography variant="subtitle1">
                            &nbsp; {this.renderLeadUserDocument()}
                          </Typography>
                        </Toolbar>
                      )}
                    </div>
                  </TableCell>
                  {!isNew && (
                    <TableCell>
                      <div className={classes.submitContainer}>
                        {lead.brokers_arr && (
                          <div>
                            {lead.brokers_arr.length > 0 && (
                              <Typography variant="body1">
                                <b>Asignado a: </b>
                                {lead.brokers_arr.join(', ')}
                              </Typography>
                            )}
                          </div>
                        )}
                        <Typography variant="body1">
                          <b>Estado: </b>
                          {lead.status.name}
                        </Typography>
                        {stage !== '' && (
                          <Typography variant="body1">
                            <b>Etapa: </b>
                            {stage}
                          </Typography>
                        )}
                        <br />
                        <Button
                          type="button"
                          variant="contained"
                          className={classes.submit}
                          color="primary"
                          onClick={this.props.onClickNextActions}
                        >
                          Siguientes acciones
                        </Button>
                        <Revisita
                          lead={lead}
                          classes={classes}
                          toggleAppoinmentModal={(state) =>
                            this.setState({ openAppointmentModal: state })
                          }
                          isOpenAppointmentModal={this.state.openAppointmentModal}
                        />
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          this.renderEditLeadTop()
        )}
        {openDuplicatesModal && (
          <DuplicateLead
            handleCreateLead={this.handleCreateLead}
            openDuplicatesModal={openDuplicatesModal}
            newLead={this.state.lead}
            closeModal={() => this.setState({ openDuplicatesModal: false })}
          />
        )}
        {openValidationDialog && this.renderValidationDialog()}
      </div>
    );
  }
}

User.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  lead: PropTypes.object.isRequired,
  onCreateLead: PropTypes.func.isRequired,
  onUpdateOrigin: PropTypes.func.isRequired,
  onUpdateLeadUser: PropTypes.func.isRequired,
  onClickNextActions: PropTypes.func.isRequired,
  origins: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { leads, users } = state;
  return {
    lead: leads.lead,
    isLoading: leads.sendingLead,
    users: users.users,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(User));
