import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {filterProfiles, createProfile, updateProfile, getProfile} from '../../actions/profiles';
import EnhancedTable from '../common/enhancedTable';
import {profilesCols} from '../../utils/colTypes';
import CustomSnackbar from '../common/customSnackbar';
import ProfileDetail from './detail';
import {getCompanies} from '../../actions/companies';
import {SUPER_ADMIN_ROL_ID} from '../../utils/utils';

export class Profiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {           
      profile: null,
      isNew: false,
      title: 'Perfiles'
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handleCreateProfile = this.handleCreateProfile.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
    this.formatColsFromUser = this.formatColsFromUser.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    const profileId = localStorage.getItem('profileId');
    this.props.getCompanies();
    if (profileId) {
      this.props.getProfile(profileId).then(() => {
        this.props.filterProfiles(
          this.props.page,
          this.props.rowsPerPage,
          this.props.order,
          this.props.orderBy,
          this.props.searchText
        );
      });
    } else {
      window.location.href = '/login';
    }
  }

  onAdd() {
    this.setState({isNew: true, profile: {}});
  }

  onDetail(id) {
    const profile = this.props.profiles.filter(obj => obj.id === id)[0];
    this.setState({profile, isNew: false});
  }

  onSearch(searchText) {
    this.props.filterProfiles(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText);
  }

  onOrder(order, orderBy) {
    this.props.filterProfiles(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_profiles) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_profiles) {
        return true;
      }
    }
    return false;
  }

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_profiles) {
        return true;
      }
    }
    return false;
  }

  handleCreateProfile(newProfile) {
    this.props.createProfile(newProfile).then(() => {
      this.handleGoBack();
    });
  }

  handleUpdateProfile(profileEdited) {
    const {profile} = this.state;
    this.setState({profile: profileEdited});
    const edited = {};
    if (profile.active !== profileEdited.active) {
      edited.active = profileEdited.active;
    }
    if (profile.name !== profileEdited.name) {
      edited.name = profileEdited.name;
    }
    if (profile.company !== profileEdited.company) {
      edited.company = profileEdited.company;
    }
    edited.permissions = profileEdited.permissions;
    this.props.updateProfile(profileEdited.id, edited).then(() => {
      this.handleGoBack();
    });
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterProfiles(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  
  handleChangePage(page) {
    this.props.filterProfiles(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleGoBack() {
    this.props.filterProfiles(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
    this.setState({profile: null, isNew: false});
  }

  formatColsFromUser = (cols) => {
    const profileId = localStorage.getItem('profileId');
    let newCols = cols;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      newCols = cols.filter(col => col.id !== 'company');
    }
    return newCols;
  }
  
  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, profiles, dataLength, companies} = this.props;
    const {title, profile, isNew} = this.state;
    const hasEditPermission = this.getEditPermissions();
    console.log('hasEditPermission:', hasEditPermission)
    const hasCreatePermission = this.getCreatePermission();
    console.log('hasCreatePermission:', hasCreatePermission)
    const newProfilesCols = this.formatColsFromUser(profilesCols);
    const data = {
      rows: profiles,
      cols: newProfilesCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };
    return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <div>
        {this.renderSnackBar()}
        {
          profile === null ?
            <EnhancedTable 
              title={title}
              data={data} 
              handlers={handlers}
              config={config}
            />  
            :
            <ProfileDetail 
              profile={profile}
              handleGoBack={this.handleGoBack}
              handleCreateProfile={this.handleCreateProfile}
              handleUpdateProfile={this.handleUpdateProfile}
              isLoading={isLoading}
              isNew={isNew}
              companies={companies}
            />
        }
      </div>
    );
  }
}

Profiles.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  profiles: PropTypes.array.isRequired,
  filterProfiles: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getCompanies: PropTypes.func,
  createProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  dataLength: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  companies: PropTypes.array
};

const mapStateToProps = (state) => {
  const {profiles, companies} = state;
  return { 
    isLoading: profiles.sending, 
    profiles: profiles.profiles,
    profile: profiles.profile,
    dataLength: profiles.dataLength, 
    error: profiles.error,
    message: profiles.message,
    searchText: profiles.searchText,
    order: profiles.order,
    orderBy: profiles.orderBy,
    page: profiles.page,
    rowsPerPage: profiles.rowsPerPage,
    filters: profiles.filters,
    companies: companies.companies
  };
};
  
const mapDispatchToProps = {
  filterProfiles,
  getProfile,
  createProfile,
  updateProfile,
  getCompanies
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Profiles);
