import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Checkbox,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { InlineDateTimePicker } from 'material-ui-pickers';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { sortObjectByProperty } from '../../../utils/utils';
import { getProjectUnits } from '../../../actions/projects';
import { createEvent, updateEvent } from '../../../actions/calendar';
import { CREATE_EVENT_SUCCESS, UPDATE_EVENT_SUCCESS } from '../../../actions/types/calendar';
import SearchSelect from '../enhancedTable/enhancedTableToolbar/searchSelect';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const AppointmentModal = (props) => {
  const {
    classes,
    open,
    close,
    lead,
    eventObject,
    isEventFlag,
    onUpdate,
    allowLastDatesSelector,
    isRevisit
  } = props;

  const { projectUnits } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [openedProjects, setOpenedProjects] = useState([]);
  const [checkedUnits, setCheckUnits] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [title, setTitle] = useState('');
  const [observations, setObservations] = useState('');
  const [selectedProjectsIds, setSelectedProjectsIds] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isEvent, setIsEvent] = useState(true);
  const [hasDate, setHasDate] = useState(false);
  const [hasVisit, setHasVisit] = useState(false);
  // const [selectedIndex, setSelectedIndex] = React.useState(1);

  useEffect(() => {
    if (eventObject && projectUnits.length) {
      const { unitsIds, projectsIds } = eventObject;
      setSelectedProjectsIds(projectsIds);
      setSelectedProjects(projectUnits.filter(({ _id }) => projectsIds.includes(_id)));
      setCheckUnits(unitsIds);
      setHasDate(eventObject.hasDate);
      setHasVisit(eventObject.hasVisit);
      setTitle(eventObject.title);
      setSelectedDate(moment(eventObject.date));
      setObservations(eventObject.observations);
    }
    setIsEvent(isEventFlag);
  }, [projectUnits]);

  useEffect(() => {
    if (isEvent && projectUnits.length === 0) dispatch(getProjectUnits());
  }, [dispatch, isEvent]);

  const sortName = (arr) => sortObjectByProperty(arr, 'name');

  const handleCheckUnit = (unit) => {
    const action = !checkedUnits.some(({ _id }) => unit._id === _id);
    if (action) setCheckUnits([...checkedUnits, unit]);
    else setCheckUnits(checkedUnits.filter((item) => item._id !== unit._id));
  };

  const onCollapseProjects = (project) => {
    if (openedProjects.includes(project))
      setOpenedProjects(openedProjects.filter((item) => item !== project));
    else setOpenedProjects([...openedProjects, project]);
  };

  const handleChangeProject = ({ target: { value } }) => {
    setSelectedProjectsIds(value);
    setSelectedProjects(projectUnits.filter(({ _id }) => value.includes(_id)));
    setCheckUnits(checkedUnits.filter(({ projectId }) => value.includes(projectId)));
  };

  const clearState = () => {
    setOpenedProjects([]);
    setCheckUnits([]);
    setSelectedDate(null);
    setTitle('');
    setObservations('');
    setSelectedProjectsIds([]);
    setSelectedProjects([]);
    setOpenConfirmationModal(false);
    setIsEvent(false);
  };

  const onAddAppointment = async () => {
    const broker = localStorage.getItem('user');
    let status;
    if (eventObject) {
      status = await dispatch(
        updateEvent(eventObject.id, {
          title,
          type: (isEvent && 'EVENT') || 'TASK',
          lead,
          broker,
          observations,
          hasDate,
          hasVisit,
          date: (selectedDate || new Date()).toISOString(),
          projects: (isEvent && selectedProjectsIds) || undefined,
          units: (isEvent && checkedUnits.map(({ _id }) => _id)) || undefined,
        }),
      );
      onUpdate();
    } else {
      status = await dispatch(
        createEvent({
          title,
          type: (isEvent && 'EVENT') || 'TASK',
          lead,
          broker,
          observations,
          hasDate,
          hasVisit,
          date: (selectedDate || new Date()).toISOString(),
          projects: (isEvent && selectedProjectsIds) || undefined,
          units: (isEvent && checkedUnits.map(({ _id }) => _id)) || undefined,
          revisit:isRevisit
        }),
      );
    }
    const messageResponse =
      status.type === CREATE_EVENT_SUCCESS
        ? 'Evento creado satisfactoriamente'
        : status.type === UPDATE_EVENT_SUCCESS
        ? 'Evento actualizado satisfactoriamente'
        : 'Error al crear el evento';
    const varianMessage =
      status.type === CREATE_EVENT_SUCCESS || status.type === UPDATE_EVENT_SUCCESS
        ? 'success'
        : 'error';

    enqueueSnackbar(messageResponse, {
      autoHideDuration: 1200,
      variant: varianMessage,
      preventDuplicate: true,
    });

    clearState();
    close();
  };

  const checkIsFilledForm = () => {
    const haveTitle = !!title.length;
    if (isEvent) {
      const hasSwitched = !(!hasDate && !hasVisit);
      const haveProjects = !!selectedProjectsIds.length;
      return haveProjects && haveTitle && hasSwitched;
    }
    return haveTitle;
  };

  const isValid = checkIsFilledForm();

  const renderDeleteMatchDialog = (
    <Dialog
      open={openConfirmationModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpenConfirmationModal(false)}
    >
      <DialogTitle>Confirmación</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Está seguro que desea agendar la {isEvent ? 'cita' : 'tarea'}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenConfirmationModal(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={onAddAppointment} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderCollapsableProjects = (project) => (
    <div key={project._id}>
      <ListItem button onClick={() => onCollapseProjects(project._id)}>
        {checkedUnits.some((unit) => unit.projectId === project._id) && (
          <ListItemIcon>
            <CheckRoundedIcon style={{ color: 'green' }} />
          </ListItemIcon>
        )}
        <ListItemText className={classes.collapsable} inset primary={project.name} />
        {openedProjects.includes(project._id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={openedProjects.includes(project._id)} timeout="auto" unmountOnExit>
        <List component="div">
          {sortName(project.units).map((unit) => {
            return (
              <ListItem
                button
                className={classes.sectionPadding}
                key={unit._id}
                button
                onClick={() => handleCheckUnit({ ...unit, projectId: project._id })}
              >
                <Checkbox
                  checked={checkedUnits.some(({ _id }) => unit._id === _id)}
                  color="secondary"
                />
                <ListItemText inset primary={unit.name} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </div>
  );

  const renderList = () => {
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button selected={isEvent} onClick={(event) => setIsEvent(true)}>
            <ListItemText primary="Cita" />
          </ListItem>
          <ListItem button selected={!isEvent} onClick={(event) => setIsEvent(false)}>
            <ListItemText primary="Tarea" />
          </ListItem>
        </List>
      </div>
    );
  };

  console.log("projectUnits", projectUnits)

  return (
    <>
      <Dialog
        maxWidth="md"
        scroll="body"
        open={open}
        onClose={close}
        TransitionComponent={Transition}
      >
        <DialogTitle>Agendar</DialogTitle>
        <DialogContent>
          {/* <FormControlLabel
            className={classes.textFields}
            control={
              <Switch
                name="isEvent"
                checked={isEvent}
                onChange={(e) => setIsEvent(e.target.checked)}
                color="primary"
              />
            }
            label={(isEvent && 'Evento') || 'Tarea'}
          /> */}
          {/* <div className={classes.section}>{renderList()}</div> */}
          {isEvent && (
            <div>
              <FormControlLabel
                className={classes.textFields}
                control={
                  <Switch
                    name="hasDate"
                    checked={hasDate}
                    onChange={(e) => setHasDate(e.target.checked)}
                    color="primary"
                  />
                }
                label={'Cita agendada'}
              />
              <FormControlLabel
                className={classes.textFields}
                control={
                  <Switch
                    name="hasVisit"
                    checked={hasVisit}
                    onChange={(e) => setHasVisit(e.target.checked)}
                    color="primary"
                  />
                }
                label={'Cita realizada'}
              />
              <FormControl className={classes.textFields}>
              {/*  <InputLabel id="city-select">Proyectos</InputLabel> */}
                <SearchSelect simple={true}  onChange={handleChangeProject} options = {{types:projectUnits}} label = {"Proyectos"} selectedOptions={selectedProjectsIds}/>
                {/* <Select
                  value={selectedProjectsIds}
                  fullWidth
                  onChange={handleChangeProject}
                  multiple
                >
                  {projectUnits.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>
            </div>
          )}

          <TextField
            className={classes.textFields}
            label="Título"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <TextField
            className={classes.textFields}
            label="Observaciones"
            fullWidth
            value={observations}
            onChange={(e) => setObservations(e.target.value)}
          />

          <div className={classes.section}>
            <InlineDateTimePicker
              minDate={allowLastDatesSelector ? undefined : moment().startOf('day')}
              minDateMessage="La fecha no debe ser anterior a la fecha de hoy"
              format="DD/MM/YYYY HH:mm"
              className={classes.picker}
              label="Fecha"
              value={selectedDate}
              onChange={setSelectedDate}
            />
          </div>

          {isEvent && (
            <>
               {/* <div className={classes.section}>
                {selectedProjects.map(renderCollapsableProjects)}
              </div> */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmationModal(true)}
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Agendar
          </Button>
          <Button onClick={close} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {renderDeleteMatchDialog}
    </>
  );
};

AppointmentModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  allowLastDatesSelector: PropTypes.bool,
  close: PropTypes.func.isRequired,
  lead: PropTypes.string.isRequired,
  eventObject: PropTypes.object,
  isEventFlag: PropTypes.bool,
  isRevisit: PropTypes.bool,
  onUpdate: PropTypes.func,
};

export default withStyles(styles)(AppointmentModal);
