export const LOGIN_SENDING = 'LOGIN_SENDING';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGIN_FAILED = 'LOGIN_FAILED';


export const GET_USER_SENDING = 'GET_USER_SENDING';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const GET_USER_FAILED = 'GET_USER_FAILED';
