import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { styles } from './styles';
import ConfirmationDialog from '../../common/confirmationDialog';
import { getPermissions } from '../../../actions/profiles';
import { SUPER_ADMIN_ROL_ID } from '../../../utils/utils';

export class ProfilesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      profile: null,
      name: '',
      permissions: {},
      company: '',
    };
    this.renderButton = this.renderButton.bind(this);
    this.renderPermissions = this.renderPermissions.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleChangePermission = this.handleChangePermission.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
  }

  componentWillMount() {
    const { profile } = this.props;
    const profileId = localStorage.getItem('profileId');
    let { company } = profile;
    if (SUPER_ADMIN_ROL_ID !== profileId) {
      const companyStored = JSON.parse(localStorage.getItem('company'));
      company = companyStored ? companyStored._id : '';
    } else {
      company = company && company._id ? company._id : '';
    }

    this.props.getPermissions().then((result) => {
      const permissions = {};
      Object.values(result.payload.permissions).map((category) => {
        return category.map((permission) => {
          if (profile.permissions) {
            if (profile.permissions[permission]) {
              permissions[permission] = profile.permissions[permission];
            } else {
              permissions[permission] = false;
            }
          } else {
            permissions[permission] = false;
          }
          return permissions;
        });
      });
      this.setState({
        profile,
        permissions,
        name: profile.name ? profile.name : '',
        active: profile.active ? profile.active : true,
        company,
      });
    });
  }

  onAddButtonClick() {
    const { name, permissions, company } = this.state;
    this.props.handleCreateProfile({ name, permissions, company });
  }

  onEditButtonClick() {
    const { profile, active, permissions, name, company } = this.state;
    const profileEdited = {
      id: profile.id,
      active,
      name,
      permissions,
      company,
    };
    this.props.handleUpdateProfile(profileEdited);
  }

  handleChangePermission(e, permission) {
    const { permissions } = this.state;
    permissions[permission] = e.target.checked;
    this.setState({ permissions });
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  handleChangeCompany(e) {
    const company = e.target.value;
    this.setState({ company });
  }

  isInvalid() {
    const { profile } = this.props;
    const { name, permissions, company } = this.state;
    if (name !== '' && permissions !== {} && (company || profile.isBase)) {
      return false;
    }
    return true;
  }

  renderCompanies() {
    const { companies } = this.props;
    return companies.map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>
          {item.name}
        </MenuItem>
      );
    });
  }

  renderRowCompany(classes) {
    const profileId = localStorage.getItem('profileId');
    return (
      SUPER_ADMIN_ROL_ID === profileId && (
        <TableRow>
          <TableCell className={classes.tableRowTitle}>
            <b>Empresa</b>
          </TableCell>
          <TableCell>
            <Select
              value={this.state.company}
              fullWidth
              onChange={this.handleChangeCompany}
            >
              {this.renderCompanies()}
            </Select>
          </TableCell>
        </TableRow>
      )
    );
  }

  renderButton() {
    const { isLoading, isNew, classes } = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText={`¿Está seguro que desea ${
            isNew ? 'crear' : 'modificar'
          } este perfil?`}
          buttonText={`${isNew ? 'Agregar' : 'Actualizar'} perfil`}
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={isNew ? this.onAddButtonClick : this.onEditButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderPermissions() {
    const { permissions } = this.state;
    if (permissions) {
      return Object.entries(permissions).map((permission, index) => {
        return (
          <TableRow key={index}>
            <TableCell colSpan={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={permission[1]}
                    onChange={(e) =>
                      this.handleChangePermission(e, permission[0])
                    }
                    color="primary"
                  />
                }
                label={permission[0]}
              />
            </TableCell>
          </TableRow>
        );
      });
    }
    return null;
  }

  render() {
    const { classes, isLoading, isNew } = this.props;
    return isLoading === true ? (
      <CircularProgress
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
        }}
      />
    ) : (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.handleGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {!isNew ? 'Detalle de ' : 'Nuevo '} perfil
          </Typography>
        </Toolbar>
        <div style={{ width: 'auto', overflowX: 'scroll' }}>
          <Table>
            <TableBody>
              {!isNew && (
                <TableRow>
                  <TableCell className={classes.tableRowTitle}>
                    <b>Disponible</b>
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.active}
                          onChange={(e) =>
                            this.setState({ active: e.target.checked })
                          }
                          color="primary"
                        />
                      }
                      label={
                        this.state.active === true
                          ? 'Disponible'
                          : 'No disponible'
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  <TextField
                    autoFocus
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              {this.renderRowCompany(classes)}
              <TableRow>
                <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                  <b>Permisos</b>
                </TableCell>
              </TableRow>
              {this.renderPermissions()}
            </TableBody>
          </Table>
        </div>
        <div className={classes.submitContainer}>{this.renderButton()}</div>
      </Paper>
    );
  }
}

ProfilesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleCreateProfile: PropTypes.func.isRequired,
  getPermissions: PropTypes.func.isRequired,
  handleUpdateProfile: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { profiles } = state;
  return {
    isLoading: profiles.sending,
    permissions: profiles.permissions,
  };
};

const mapDispatchToProps = {
  getPermissions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ProfilesDetails));
