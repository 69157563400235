import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {filterZones, getAllZones, createZone, updateZone} from '../../../actions/zones';
import EnhancedTable from '../../common/enhancedTable';
import {zonesCols} from '../../../utils/colTypes';
import CustomSnackbar from '../../common/customSnackbar';
import ZoneDetail from './detail';

export class Zones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {           
      zone: null,
      isNew: false,
      title: 'Sectores'
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.handleUpdateZone = this.handleUpdateZone.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.getEditPermissions = this.getEditPermissions.bind(this);
    this.getCreatePermission = this.getCreatePermission.bind(this);
  }

  componentWillMount() {
    this.getViewPermission();
    this.props.filterZones(
      this.props.page,
      this.props.rowsPerPage,
      this.props.order,
      this.props.orderBy,
      this.props.searchText
    );
    this.props.getAllZones();
  }

  onAdd() {
    this.setState({isNew: true, zone: {}});
  }

  onDetail(id) {
    const zone = this.props.zones.filter(obj => obj.id === id)[0];
    this.setState({zone, isNew: false});
  }

  onSearch(searchText) {
    this.props.filterZones(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, searchText);
  }

  onOrder(order, orderBy) {
    this.props.filterZones(this.props.page, this.props.rowsPerPage, order, orderBy, this.props.searchText);
  }

  getViewPermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.view_zones) {
        return true;
      }
    }
    window.location.href = '/';
    return false;
  }

  getEditPermissions = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.update_zones) {
        return true;
      }
    }
    return false;
  }

  getCreatePermission = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      if (profile.permissions.create_zones) {
        return true;
      }
    }
    return false;
  }

  handleUpdateZone(zoneEdited) {
    const {zone} = this.state;
    this.setState({zone: zoneEdited});
    const edited = {};
    if (zone.active !== zoneEdited.active) {
      edited.active = zoneEdited.active;
    }
    if (zone.name !== zoneEdited.name) {
      edited.name = zoneEdited.name;
    }
    if (zone.father !== zoneEdited.father) {
      edited.father = zoneEdited.father;
    }
    if (zone.icon !== zoneEdited.icon) {
      edited.icon = zoneEdited.icon;
    }
    if (zone.city !== zoneEdited.city) {
      edited.city = zoneEdited.city;
    }
    if (zone.code !== zoneEdited.code) {
      edited.code = zoneEdited.code;
    }
    this.props.updateZone(zoneEdited.id, edited);
  }

  handleChangeRowsPerPage(rowsPerPage) {
    this.props.filterZones(this.props.page, rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  
  handleChangePage(page) {
    this.props.filterZones(page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
  }

  handleGoBack() {
    this.props.filterZones(this.props.page, this.props.rowsPerPage, this.props.order, this.props.orderBy, this.props.searchText);
    this.setState({zone: null, isNew: false});
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  }

  render() {
    const {isLoading, zones, dataLength} = this.props;
    const {title, zone, isNew} = this.state;
    const hasEditPermission = this.getEditPermissions();
    const hasCreatePermission = this.getCreatePermission();
    const data = {
      rows: zones,
      cols: zonesCols
    };
    const handlers = {
      onDetail: this.onDetail,
      onAdd: this.onAdd,
      onSearch: this.onSearch,
      onOrder: this.onOrder,
      onChangePage: this.handleChangePage,
      onChangeRowsPerPage: this.handleChangeRowsPerPage
    };
    const config = {
      isDragable: false,
      hasEditPermission,
      hasCreatePermission,
      isSelectable: false,
      isFiterable: false,
      searchText: this.props.searchText,
      order: {
        by: this.props.orderBy,
        direction: this.props.order
      },
      pagination: {
        current: this.props.page,
        rowsPerPage: this.props.rowsPerPage,
        totalItems: dataLength
      },
      filters: {
        is: false
      }
    };
    if (isLoading === true) {
      return (
        <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} />
      );
    }
    return (
      <div>
        {this.renderSnackBar()}
        {
          zone === null ?
            <EnhancedTable 
              title={title}
              data={data} 
              handlers={handlers}
              config={config}
            />  
            :
            <ZoneDetail 
              zone={zone}
              handleGoBack={this.handleGoBack}
              handleCreateZone={this.props.createZone}
              handleUpdateZone={this.handleUpdateZone}
              isLoading={isLoading}
              isNew={isNew}
            />
        }
      </div>
    );
  }
}

Zones.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  zones: PropTypes.array.isRequired,
  filterZones: PropTypes.func.isRequired,
  getAllZones: PropTypes.func.isRequired,
  createZone: PropTypes.func.isRequired,
  updateZone: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  dataLength: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {zones} = state;
  return { 
    isLoading: zones.sending, 
    zones: zones.zones,
    allZones: zones.allZones,
    dataLength: zones.dataLength, 
    error: zones.error,
    message: zones.message,
    searchText: zones.searchText,
    order: zones.order,
    orderBy: zones.orderBy,
    page: zones.page,
    rowsPerPage: zones.rowsPerPage,
    filters: zones.filters
  };
};
  
const mapDispatchToProps = {
  filterZones,
  createZone,
  updateZone,
  getAllZones
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Zones);
