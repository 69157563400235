export const formatFunnel = (title, data) => {
  const mapedData = data.map((item) => {
    return [item.name, item.count || null];
  }, []);
  const chart = {
    chart: {
      type: 'funnel',
      // options3d: {
      //   enabled: true,
      //   alpha: 10,
      //   depth: 50,
      //   viewDistance: 50,
      // },
    },
    title: {
      text: title,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b> ({point.y:,.0f})',
          softConnector: true,
        },
        center: ['40%', '50%'],
        neckWidth: '30%',
        neckHeight: '25%',
        width: '80%',
      },
    },
    legend: {
      enabled: false,
    },
    minSize:800,
    series: [
      {
        // height:400,
        minSize:800,
        colors: ['#17a2b8', '#006064', '#23dce4', '#4ebb3a', '#3F51B5', '#497f81'],
        name: '#',
        data: mapedData,
      },
    ],
  };
  return chart;
};
