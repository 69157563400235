import * as groupActions from '../actions/types/groups';

const initialState = {
  groups: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {groups, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case groupActions.FILTER_GROUPS_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case groupActions.FILTER_GROUPS_SUCCESS:
    return {...state, sending: false, groups, error: null, message: 'Grupos obtenidos satisfactoriamente', dataLength};
  case groupActions.FILTER_GROUPS_FAILED:
    return {...state, sending: false, groups: [], error, message, dataLength};
  case groupActions.GET_GROUPS_SENDING:
    return {...state, sending: true};
  case groupActions.GET_GROUPS_SUCCESS:
    return {...state, sending: false, groups, error: null, message: 'Grupos obtenidas satisfactoriamente', dataLength};
  case groupActions.GET_GROUPS_FAILED:
    return {...state, sending: false, groups: [], error, message, dataLength};
  case groupActions.CREATE_GROUP_SENDING:
    return {...state, sending: true};
  case groupActions.CREATE_GROUP_SUCCESS:
    return {...state, sending: false, error: null, message: 'Grupo creada satisfactoriamente'};
  case groupActions.CREATE_GROUP_FAILED:
    return {...state, sending: false, error, message};
  case groupActions.UPDATE_GROUP_SENDING:
    return {...state, sending: true};
  case groupActions.UPDATE_GROUP_SUCCESS:
    return {...state, sending: false, error: null, message: 'Grupo actualizada satisfactoriamente'};
  case groupActions.UPDATE_GROUP_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
