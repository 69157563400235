/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage, logOutFunction} from '../utils/utils';
import * as profileActions from './types/profiles';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterProfiles = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().profiles.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().profiles.order || orderBy !== store.getState().profiles.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: profileActions.FILTER_PROFILES_SENDING,
    payload: {
      profiles: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && order !== null && orderBy !== null && searchText !== null) {
      filter = {
        filters: {
          name: searchText
        },
        operator: 'and',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order
        },
        company: company && company._id
      };
    }
    const response = await axios(axiosMaker('POST', 'profiles/filter', filter, true));
    return dispatch({
      type: profileActions.FILTER_PROFILES_SUCCESS,
      payload: {
        profiles: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: profileActions.FILTER_PROFILES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getProfiles = () => async (dispatch) => {
  dispatch({
    type: profileActions.GET_PROFILES_SENDING,
    payload: {
      profiles: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {},
      company: company && company._id
    };
    const response = await axios(axiosMaker('POST', 'profiles/filter', data, true));
    return dispatch({
      type: profileActions.GET_PROFILES_SUCCESS,
      payload: {
        profiles: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: profileActions.GET_PROFILES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getProfile = id => async (dispatch) => {
  dispatch({
    type: profileActions.GET_PROFILE_SENDING,
    payload: {
    }
  });
  try {
    const response = await axios(axiosMaker('GET', `profiles/${id}`, null, true));
    return dispatch({
      type: profileActions.GET_PROFILE_SUCCESS,
      payload: {
        profile: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    const { data } = e.response;
    if (data) {
      const { message } = data;
      if (message === 'jwt expired') {
        logOutFunction();
      }
    }
    return dispatch({
      type: profileActions.GET_PROFILE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getPermissions = () => async (dispatch) => {
  dispatch({
    type: profileActions.GET_PERMISSIONS_SENDING,
    payload: {
      permissions: null,
      dataLength: 0
    }
  });
  try {
    const response = await axios(axiosMaker('GET', 'profiles/getPermissions', null, true));
    return dispatch({
      type: profileActions.GET_PERMISSIONS_SUCCESS,
      payload: {
        permissions: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: profileActions.GET_PERMISSIONS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createProfile = data => async (dispatch) => {
  dispatch({
    type: profileActions.CREATE_PROFILE_SENDING,
    payload: {}
  });
  const dataTosend = data;
  if (company && company._id) {
    dataTosend.company = company._id;
  }
  try {
    await axios(axiosMaker('POST', 'profiles', dataTosend, true));
    return dispatch({
      type: profileActions.CREATE_PROFILE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: profileActions.CREATE_PROFILE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateProfile = (id, profiles) => async (dispatch) => {
  dispatch({
    type: profileActions.UPDATE_PROFILE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `profiles/${id}`, profiles, true));
    return dispatch({
      type: profileActions.UPDATE_PROFILE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: profileActions.UPDATE_PROFILE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
