/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as companyActions from './types/companies';
import store from '../store';

export const filterCompanies = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().companies.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().companies.order || orderBy !== store.getState().companies.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: companyActions.FILTER_COMPANIES_SENDING,
    payload: {
      companies: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    let filter = {};
    if (page !== null && rowsPerPage !== null && order !== null && orderBy !== null && searchText !== null) {
      filter = {
        filters: {
          name: searchText,
          businessReason: searchText,
          identification: searchText
        },
        operator: 'or',
        page,
        limit: rowsPerPage,
        sort: {
          [orderBy]: order
        }
      };
    }
    const response = await axios(axiosMaker('POST', 'companies/filter', filter, true));
    return dispatch({
      type: companyActions.FILTER_COMPANIES_SUCCESS,
      payload: {
        companies: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: companyActions.FILTER_COMPANIES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getCompanies = () => async (dispatch) => {
  dispatch({
    type: companyActions.GET_COMPANIES_SENDING,
    payload: {
      companies: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      sort: {
        name: 'asc'
      },
      filters: {}
    };
    const response = await axios(axiosMaker('POST', 'companies/filter', data, true));
    return dispatch({
      type: companyActions.GET_COMPANIES_SUCCESS,
      payload: {
        companies: response.data.results,
        dataLength: response.data.results.length
      }
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: companyActions.GET_COMPANIES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createCompany = data => async (dispatch) => {
  dispatch({
    type: companyActions.CREATE_COMPANY_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('POST', 'companies', data, true));
    return dispatch({
      type: companyActions.CREATE_COMPANY_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: companyActions.CREATE_COMPANY_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateCompany = (id, company) => async (dispatch) => {
  dispatch({
    type: companyActions.UPDATE_COMPANY_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `companies/${id}`, company, true));
    return dispatch({
      type: companyActions.UPDATE_COMPANY_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;

    return dispatch({
      type: companyActions.UPDATE_COMPANY_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const validateCompany = (id, identifier) => async (dispatch) => {
  try {
    const response = await axios(axiosMaker('POST', 'companies/validate', {id, identifier}, true));
    return response.data.results;
  } catch (e) {
    const error = e.response;    
    return dispatch({
      type: companyActions.VALIDATE_COMPANY_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
