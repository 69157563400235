import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import {
  Divider,
  Drawer,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SmartTips from '../smartTips';
import {
  setFilters,
  setZones,
  setCities,
  setRooms,
  setUnitTypes,
  setProjectStatus,
  setSectors,
  setProjects,
  orderChips,
  setDisabledFilter,
} from '../../../actions/smartSupplyFilters';
import { getSupplyGraphs } from '../../../actions/smart';

const FilterDrawer = (props) => {
  const { window, classes, showProjects, mobileOpen, handleDrawerToggle } = props;

  const dispatch = useDispatch();

  const [
    { supplyFilters },
    { filters, chipData, sectors, cities, zones, unitTypes, rooms, projects, status, filtersObj },
  ] = useSelector((state) => [state.smart, state.smartSupplyFilters]);

  useEffect(() => {
    dispatch(getSupplyGraphs(filtersObj, false));
  }, [filtersObj]);

  useEffect(() => {
    dispatch(
      setFilters(supplyFilters || { types: [], rooms: [], projectsByLocation: [], status: [] }),
    );
  }, [supplyFilters]);

  useEffect(() => {
    dispatch(setDisabledFilter());
  }, [chipData, sectors, cities, zones, unitTypes, rooms, projects, status]);

  const handleChangeSector = ({ target }) => {
    const value = target.value.filter((item) => item);
    dispatch(setSectors(value));

    const chipsWithoutSectors = chipData.filter((chip) => chip.type !== 'sector');

    const newSectors = value.map((item, index) => ({
      key: chipsWithoutSectors.length + index,
      type: 'sector',
      label: item,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutSectors, ...newSectors]));
  };

  const handleChangeZone = ({ target }) => {
    const value = target.value.filter((item) => item);
    dispatch(setZones(value));

    const chipsWithoutZones = chipData.filter((chip) => chip.type !== 'zone');

    const newZones = value.map((item, index) => ({
      key: chipsWithoutZones.length + index,
      type: 'zone',
      label: item,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutZones, ...newZones]));
  };

  const handleChangeCity = ({ target }) => {
    const value = target.value.filter((item) => item);
    dispatch(setCities(value));

    const chipsWithoutCities = chipData.filter((chip) => chip.type !== 'city');

    const newCities = value.map((item, index) => ({
      key: chipsWithoutCities.length + index,
      type: 'city',
      label: item,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutCities, ...newCities]));
  };

  const handleChangeRoom = ({ target }) => {
    const { value } = target;
    dispatch(setRooms(value));

    const chipsWithoutRooms = chipData.filter((chip) => chip.type !== 'room');

    const newRooms = value.map((item, index) => ({
      key: chipsWithoutRooms.length + index,
      type: 'room',
      label: `${item} ${item === 1 ? 'dormitorio' : 'dormitorios'}`,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutRooms, ...newRooms]));
  };

  const handleChangeUnitType = ({ target }) => {
    console.log(target.value,"unitchange");
    const { value } = target;
    dispatch(setUnitTypes(value));

    const chipsWithoutTypes = chipData.filter((chip) => chip.type !== 'unitType');

    const newTypes = value.map((item, index) => ({
      key: chipsWithoutTypes.length + index,
      type: 'unitType',
      label: item,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutTypes, ...newTypes]));
  };

  const handleChangePojectStatus = ({ target }) => {
    const { value } = target;
    dispatch(setProjectStatus(value));

    const chipsWithoutTypes = chipData.filter((chip) => chip.type !== 'status');

    const newTypes = value.map((item, index) => ({
      key: chipsWithoutTypes.length + index,
      type: 'status',
      label: item,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutTypes, ...newTypes]));
  };

  const handleChangeProject = ({ target }) => {
    const { value } = target;
    dispatch(setProjects(value));

    const chipsWithoutTypes = chipData.filter((chip) => chip.type !== 'project');

    const newTypes = value.map((item, index) => ({
      key: chipsWithoutTypes.length + index,
      type: 'project',
      label: item,
      id: item,
    }));

    dispatch(orderChips([...chipsWithoutTypes, ...newTypes]));
  };

  const renderSectors = () => {
    const data = filters.projectsByLocation
      .filter(
        ({ city, zone }) =>
          (!cities.length || cities.includes(city)) && (!zones.length || zones.includes(zone)),
      )
      .map(({ sector }) => sector)
      .sort();

    return [...new Set(data)].map((item, index) => (
      <MenuItem value={item} key={`sector_${index}`}>
        {item}
      </MenuItem>
    ));
  };

  const renderZones = () => {
    const data = filters.projectsByLocation
      .filter(({ city }) => !cities.length || cities.includes(city))
      .map(({ zone }) => zone)
      .sort();

    return [...new Set(data)].map((item, index) => (
      <MenuItem value={item} key={`zone_${index}`}>
        {item}
      </MenuItem>
    ));
  };

  const renderCities = () => {
    return [...new Set(filters.projectsByLocation.map(({ city }) => city).sort())].map(
      (item, index) => (
        <MenuItem value={item} key={`cities_${index}`}>
          {item}
        </MenuItem>
      ),
    );
  };

  const renderRooms = () => {
    return filters.rooms.sort().map((item, index) => (
      <MenuItem value={item} key={`room_${index}`}>
        {item}
      </MenuItem>
    ));
  };

  const renderUnitTypes = () => {
    return filters.types.sort().map((item, index) => (
      <MenuItem value={item} key={`unitType_${index}`}>
        {item}
      </MenuItem>
    ));
  };

  const renderProjectStatus = () => {
    return filters.status.map((item, index) => (
      <MenuItem value={item} key={`status_${index}`}>
        {item}
      </MenuItem>
    ));
  };

  const renderProjects = () => {
    const data = filters.projectsByLocation
      .filter(
        ({ city, zone, sector }) =>
          (!cities.length || cities.includes(city)) &&
          (!zones.length || zones.includes(zone)) &&
          (!sectors.length || sectors.includes(sector)),
      )
      .map(({ projects: array }) => array)
      .flat()
      .sort();

    return [...new Set(data)].map((item, index) => (
      <MenuItem value={item} key={`project_${index}`}>
        {item}
      </MenuItem>
    ));
  };

  const renderUnitType = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="room-select">
            Tipo de propiedad
          </InputLabel>
          <Select value={unitTypes} fullWidth onChange={handleChangeUnitType} multiple>
            {renderUnitTypes()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderRoom = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="room-select">
            Habitaciones
          </InputLabel>
          <Select value={rooms} fullWidth onChange={handleChangeRoom} multiple>
            {renderRooms()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderCity = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="city-select">
            Ciudad
          </InputLabel>
          <Select value={cities} fullWidth onChange={handleChangeCity} multiple>
            {renderCities()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderZone = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="zone-select">
            Zona
          </InputLabel>
          <Select value={zones} fullWidth onChange={handleChangeZone} multiple>
            {renderZones()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderSector = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="sector-select">
            Sector
          </InputLabel>
          <Select value={sectors} fullWidth onChange={handleChangeSector} multiple>
            {renderSectors()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderStatus = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="room-select">
            Estado de proyectos
          </InputLabel>
          <Select value={status} fullWidth onChange={handleChangePojectStatus} multiple>
            {renderProjectStatus()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderProject = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel className={classes.filterTitle} id="project-select">
            Proyecto
          </InputLabel>
          <Select value={projects} fullWidth onChange={handleChangeProject} multiple>
            {renderProjects()}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderTips = () => {
    return (
      <div style={{ padding: '10px' }}>
        <FormControl style={{ width: '100%' }}>
          <SmartTips />
        </FormControl>
      </div>
    );
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      {renderUnitType()}
      {renderRoom()}
      <Divider />
      {renderCity()}
      {renderZone()}
      {renderSector()}
      {renderStatus()}
      {showProjects && renderProject()}
      {renderTips()}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

FilterDrawer.propTypes = {
  window: PropTypes.func,
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  showProjects: PropTypes.bool,
  mobileOpen: PropTypes.bool,
};

export default withStyles(styles)(FilterDrawer);
