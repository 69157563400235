import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Select,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import ConfirmationDialog from '../../common/confirmationDialog';
import {
  getLeadsAssignment,
  updateLeadAssignment,
} from '../../../actions/leadAssignment';
import CustomSnackbar from '../../common/customSnackbar';
import { SUPER_ADMIN_ROL_ID } from '../../../utils/utils';
import OriginAssignment from './originAssignment';

const ASSIGNMENT_TYPE = {
  Manual: 'Manual',
  Automatic: 'Balanceada',
  Equitable: 'Equitativa',
};

const company = JSON.parse(localStorage.getItem('company'));
const profile = JSON.parse(localStorage.getItem('profile'));

const LeadsAssignment = (props) => {
  const { leadsAssignment, sending, error, message } = useSelector(
    (state) => state.leadsAssignment,
  );

  const [details, setDetails] = useState({
    type: Object.keys(ASSIGNMENT_TYPE)[0],
    hasJuniorRol: false,
    hasProjectAssignment: false,
    hasOriginAssignment: false,
    originAssignment: [],
  });

  const [origins, setOrigins] = useState([]);
  const [asesors, setAsesors] = useState([]);

  const [companyId, setCompanyId] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!profile || !profile.permissions.view_leads_assignments)
      window.location.href = '/';

    dispatch(getLeadsAssignment());
    const val = company && company._id ? company._id : '';
    setCompanyId(val);
  }, []);

  useEffect(() => {
    if (leadsAssignment.length > 0) {
      const object = leadsAssignment.find((item) => item.id === companyId);
      if (object) {
        setDetails(object.assigmentConfiguration);
        setOrigins(object.origins);
        setAsesors(object.asesors);
      }
    }
  }, [leadsAssignment]);

  const onChangeCompany = (e) => {
    setCompanyId(e.target.value);
    const object = leadsAssignment.find((item) => item.id === e.target.value);
    setDetails(object.assigmentConfiguration);
    setOrigins(object.origins);
    setAsesors(object.asesors);
  };

  const onEditButtonClick = async () => {
    const originAssignment = details.originAssignment.filter(
      ({ origins }) => origins.length,
    );

    await dispatch(
      updateLeadAssignment(companyId, { ...details, originAssignment }),
    );
    dispatch(getLeadsAssignment());
  };

  const { classes } = props;

  const renderCompany = () => {
    return (
      <TableRow>
        <TableCell className={classes.tableRowTitle}>
          <b>Empresa:</b>
        </TableCell>
        <TableCell>
          <Select value={companyId} onChange={onChangeCompany} fullWidth>
            {leadsAssignment.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
      </TableRow>
    );
  };

  const renderType = () => {
    return (
      <TableRow>
        <TableCell className={classes.tableRowTitle}>
          <b>Tipo de Asignación:</b>
        </TableCell>
        <TableCell>
          <Select
            value={details.type}
            onChange={(e) =>
              setDetails({
                ...details,
                type: e.target.value,
              })
            }
            fullWidth
          >
            {Object.keys(ASSIGNMENT_TYPE).map((type, index) => (
              <MenuItem value={type} key={index}>
                {ASSIGNMENT_TYPE[type]}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
      </TableRow>
    );
  };

  const checksData = {
    hasJuniorRol: 'Habilitar gestión de Asesores Junior',
    hasProjectAssignment: 'Habilitar asignación por proyectos',
    hasOriginAssignment: 'Habilitar asignación por orígenes',
    hasGroupAssignment: 'Habilitar asignación por grupos',
  };

  const checkElement = (attribute) => (
    <TableRow>
      <TableCell className={classes.tableRowTitle}>
        <b>{checksData[attribute]}</b>
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={details[attribute]}
              onChange={(e) =>
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    [attribute]: e.target.checked,
                  };
                })
              }
              color="primary"
            />
          }
          label={details[attribute] === true ? 'Habilitado' : 'No habilitado'}
        />
      </TableCell>
    </TableRow>
  );

  const renderSnackBar = () => {
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          message={message}
        />
      );
    }
    return null;
  };

  const renderButton = () => {
    return (
      <ConfirmationDialog
        confirmationText="¿Está seguro que desea modificar la asignación de leads?"
        buttonText="Actualizar"
        variant="contained"
        isDisabled={companyId === ''}
        confirmAction={onEditButtonClick}
      />
    );
  };

  return sending ? (
    <CircularProgress
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    />
  ) : (
    <div>
      {renderSnackBar()}
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <Typography variant="h6">Asignación de leads</Typography>
        </Toolbar>
        <div style={{ width: 'auto', overflowX: 'auto' }}>
          <Table>
            <TableBody>
              {profile._id === SUPER_ADMIN_ROL_ID && renderCompany()}
              {companyId !== '' && checkElement('hasJuniorRol')}
              {companyId !== '' && renderType()}
              {companyId !== '' &&
                details.type !== 'Manual' &&
                checkElement('hasProjectAssignment')}
              {companyId !== '' &&
                details.type !== 'Manual' &&
                checkElement('hasOriginAssignment')}
                {companyId !== '' &&
                details.type !== 'Manual' &&
                checkElement('hasGroupAssignment')}
            </TableBody>
          </Table>
          {companyId !== '' &&
            details.type !== 'Manual' &&
            details.hasOriginAssignment &&
            asesors.length > 0 && (
              <OriginAssignment
                origins={origins}
                asesors={asesors}
                details={details}
                setDetails={setDetails}
              />
            )}
        </div>
        <div className={classes.submitContainer}>{renderButton()}</div>
      </Paper>
    </div>
  );
};

LeadsAssignment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadsAssignment);
