export const styles = theme => ({
  gridList: {
    marginTop: 30,
    width: 500,
    height: 450
  },
  icon: {
    color: theme.palette.primary.main,
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 5,
    marginRight: 5,
    float: 'right'
  },
  button: {
    marginTop: 10,
    marginBottom: 10
  },
  titleBar: {
    backgroundColor: 'transparent'
  },
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  },
  fontAwesomeIcon: {
    display: 'table', 
    margin: '0 auto'
  },
  center: {
    textAlign: 'center'
  },
  paperGrid: {
    height: '100%',
    margin: 5
  },
  gridListTile: {
    float: 'left',
    width: 100,
    height: 100,
    margin: 10,
    cursor: 'pointer' 
  },
  textSearch: {
    width: '95%',
    marginTop: 20,
    marginBottom: 20
  },
  separator: {
    width: '100%',
    height: 30
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  loader: {
    margin: 10
  }
    
});

export default styles;
