import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {withStyles} from '@material-ui/core';
import {styles} from './styles';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

export class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleValidOpen = this.handleValidOpen.bind(this);
  }

  handleOpen() {
    this.setState({open: true});
  }

  handleValidOpen() {
    const {validFunction} = this.props;
    if (validFunction()) {
      this.setState({open: true});
    }
  }


  handleClose() {
    this.setState({open: false});
  }

  handleSuccess() {
    this.props.confirmAction();
    this.setState({open: false});
  }

  render() {
    const {buttonText, confirmationText, variant, classes, isDisabled, validOption} = this.props;
    return (
      <div>
        <Button
          type="button"
          variant={variant}
          className={classes.submit}
          color="primary"
          disabled={isDisabled}
          onClick={validOption ? this.handleValidOpen : this.handleOpen}
        >
          {buttonText}
        </Button>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
        >
          <DialogTitle>
            {'Confirmación'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleSuccess} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  confirmAction: PropTypes.func.isRequired,
  confirmationText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  validOption: PropTypes.bool,
  validFunction: PropTypes.func
};

export default ((withStyles)(styles)(ConfirmationDialog));
