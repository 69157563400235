export const GET_BUILDER_SUCCESS = 'GET_BUILDER_SUCCESS';

export const GET_BUILDER_SENDING = 'GET_BUILDER_SENDING';

export const GET_BUILDER_FAILED = 'GET_BUILDER_FAILED';

export const FILTER_BUILDER_SUCCESS = 'FILTER_BUILDER_SUCCESS';

export const FILTER_BUILDER_SENDING = 'FILTER_BUILDER_SENDING';

export const FILTER_BUILDER_FAILED = 'FILTER_BUILDER_FAILED';

export const CREATE_BUILDER_SUCCESS = 'CREATE_BUILDER_SUCCESS';

export const CREATE_BUILDER_SENDING = 'CREATE_BUILDER_SENDING';

export const CREATE_BUILDER_FAILED = 'CREATE_BUILDER_FAILED';

export const UPDATE_BUILDER_SUCCESS = 'UPDATE_BUILDER_SUCCESS';

export const UPDATE_BUILDER_SENDING = 'UPDATE_BUILDER_SENDING';

export const UPDATE_BUILDER_FAILED = 'UPDATE_BUILDER_FAILED';
