import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { cleanEventsByLead, groupEvents } from '../../actions/calendar';
import EnhancedTable from '../common/enhancedTable';
import {
  calendarCols,
  getProfileIsAdmin,
  ADMIN_ID,
  copyObject,
  sortObjectByProperty,
  calendarOptions,
  COMERCIAL_MANAGER,
} from '../../utils';
import AppointmentModal from '../common/appointmentModal';
import AppointmentList from '../common/appointmentList';
import CustomSnackbar from '../common/customSnackbar';
import { getProjects } from '../../actions/projects';
import { getBuilders } from '../../actions/builders';
import { getUsers } from '../../actions/users';
import { Button } from '@material-ui/core';

const profile = JSON.parse(localStorage.getItem('profile'));
const profileId = localStorage.getItem('profileId');

const getEventsStatistics = (events = []) => {
  const statistics = {
    countHasVisit: 0,
  };

  events.forEach((event) => {
    if (event.hasVisit) {
      statistics.countHasVisit++;
    }
  });

  return statistics;
};

const getViewPermission = () => {
  if (profile.permissions.view_calendar) {
    return true;
  }
  window.location.href = '/';
  return false;
};

const Calendar = (props) => {
  const title = 'Citas';
  const {
    page,
    rowsPerPage,
    order,
    orderBy,
    calendar,
    searchText,
    sending,
    dataLength,
    error,
    message,
    filters,
    eventCancel = {},
  } = useSelector((state) => state.calendar);
  const isAdmin = getProfileIsAdmin();
  const { projects } = useSelector((state) => state.projects);
  const { users } = useSelector((state) => state.users);
  const { builders } = useSelector((state) => state.builders);
  const [event, setEvent] = useState(null);
  const [activeListEvents, setActiveListEvents] = useState(null);
  const [filterOptions, setFilterOptions] = useState(calendarOptions);
  const [filterChips, setFilterChips] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [actualEvent, setActualEvent] = useState(null);
  
  const dispatch = useDispatch();
  console.log("activeListEvents", activeListEvents);

  useEffect(() => {
    getViewPermission();
    dispatch(getBuilders());
    dispatch(getUsers());
    dispatch(getProjects()); 
  }, []);

  useEffect(() => {
   
    if(users.length >0){
      let loadFilters = filters;
      if (profileId === COMERCIAL_MANAGER) {
        loadFilters= {...filters, brokers:users.map(userItem=> userItem.id)};
      }
      

      dispatch(groupEvents(page, rowsPerPage, order, orderBy, searchText, loadFilters));
    }
   
  }, [users])
  

  useEffect(() => {
    if (projects.length) {
      renderFilters();
    }
  }, [projects, users]);

  const getFullEvents = () => {
    let fullEvents = [];

    Object.keys(calendar).forEach((leadId) => {
      const events = calendar[leadId] || [];
      fullEvents = fullEvents.concat(fullEvents, ...events);
    });

    console.log('fullEvents:', fullEvents);
    return fullEvents;
  };

  const onSearch = (searchedText) => {
    console.log("filter on search");
    dispatch(groupEvents(page, rowsPerPage, order, orderBy, searchedText, filters));
  };
  const renderFilters = () => {
    let defaultFilters = [];
    let parameterFilters = [];
    let filterOptions = [];

    if (projects.length > 0) {
      let options = copyObject(calendarOptions);
      if (ADMIN_ID !== profileId && profileId !== COMERCIAL_MANAGER) {
        options = options.filter((option) => option.id !== 'brokers');
      }
      defaultFilters = options.map((item) => {
        const filter = item;
        if (filter.id === 'project') {
          const types = [{ title: 'Todos', value: 'all' }];
          const sortProjects = sortObjectByProperty(projects, 'name');
          sortProjects.forEach((project) => {
            const newType = {
              title: project.name,
              value: project.id,
            };
            types.push(newType);
          });
          filter.types = types;
        } else if (filter.id === 'brokers') {
          const types = [{ title: 'Todos', value: 'all' }];
          if (users) {
            const asesors = users.filter((obj) => {
              if (obj.profile) {
                if (obj.profile._id) {
                  if (obj.is_asesor === true) {
                    return true;
                  }
                }
              }
              return false;
            });
            asesors.forEach((leadStage) => {
              const newType = {
                title: leadStage.name,
                value: leadStage.id,
              };
              types.push(newType);
            });
            filter.types = types;
          }
        } else if (filter.id === 'builder') {
          const types = [{ title: 'Todos', value: 'all' }];
          builders.forEach((builder) => {
            const newType = {
              title: builder.name,
              value: builder.id,
            };
            types.push(newType);
          });
          filter.types = types;
        }
        return filter;
      });
    }

    setFilterOptions(defaultFilters);
  };

  const filtersAttributes = (allFilters) => {
    const attributes = [];
    for (let i = 0; i < Object.entries(allFilters).length; i += 1) {
      const filter = Object.entries(allFilters)[i];
      if (
        filter[0] !== 'project' &&
        filter[0] !== 'type' &&
        filter[0] !== 'date' &&
        filter[0] !== 'builder' &&
        filter[0] !== 'brokers' &&
        filter[1] !== ''
      ) {
        let query = '';
        if (filter[1].type === 'select') {
          if (filter[1].value.length > 0) {
            query = `attributes.${filter[0]}.answerId`;
            const $in = {
              $in: filter[1].value,
            };
            attributes.push({
              [query]: $in,
            });
          }
        } else {
          query = `attributes.${filter[0]}.answer`;
          attributes.push({
            [query]: filter[1].value,
          });
        }
      } else {
        const defaultFilter = {
          [filter[0]]: filter[1].value,
        };
        attributes.push(defaultFilter);
      }
    }
    const filterToApply = {};
    if (attributes.length > 0) {
      for (let i = 0; i < attributes.length; i += 1) {
        const attribute = attributes[i];
        const attributeObject = Object.entries(attribute);
        const key = attributeObject[0][0];
        const value = attributeObject[0][1];
        if (value !== '') {
          filterToApply[key] = value;
        }
      }
    }
    const preFilters = Object.entries(filterToApply).filter((obj) => obj[1] !== '');
    const filters = {};
    for (let i = 0; i < preFilters.length; i += 1) {
      const preFilter = preFilters[i];
      if (preFilter[1].value) {
        if (Array.isArray(preFilter[1].value)) {
          if (preFilter[1].value.length > 0) {
            filters[preFilter[0]] = preFilter[1].value;
          }
        } else {
          filters[preFilter[0]] = preFilter[1].value;
        }
      } else if (Array.isArray(preFilter[1])) {
        if (preFilter[1].length > 0) {
          filters[preFilter[0]] = preFilter[1];
        }
      } else {
        filters[preFilter[0]] = preFilter[1];
      }
    }
    return filters;
  };

  const parseCalendarRows = (leadId) => {
    const events = calendar[leadId] || [];

    const firstEvent = events[0];
    const { countHasVisit } = getEventsStatistics(events);
    const isCanceled = firstEvent.status === 'CANCELED';

    const userId = firstEvent.leadId;

    const rescheduled = events.reduce((prev, current) => {
      if (current.rescheduled !== 0) {
        return prev + 1;
      } else {
        return prev;
      }
    }, 0);

    return { ...firstEvent, rescheduled, userId, countHasVisit, isCanceled };
  };

  const handleFilter = (filters, filterChipsReceived) => {
    const filtersToApply = filtersAttributes(filters);
    console.log("profileId === COMERCIAL_MANAGER", profileId === COMERCIAL_MANAGER);
    if(profileId === COMERCIAL_MANAGER && (!filtersToApply.brokers ||  filtersToApply.brokers.length === 0)){
      filtersToApply.brokers= users.map(userItem=> userItem.id)
    }
    dispatch(groupEvents(page, rowsPerPage, order, orderBy, searchText, filtersToApply));
    setFilterChips(filterChipsReceived);
  };

  const refereshCalendar = () => {
    dispatch(groupEvents(page, rowsPerPage, order, orderBy, searchText, filters));
  };

  const onAdd = () => {
    setEvent({});
    setIsNew(true);
  };

  const handleChangeRowsPerPage = (rowsPerPageLocal) => {
    console.log(filtersToApply, "filtersToApply");
    const filtersToApply = filtersAttributes(filters);
    dispatch(groupEvents(page, rowsPerPageLocal, order, orderBy, searchText, filtersToApply));
  };

  const handleChangePage = (pageLocal) => {
    dispatch(groupEvents(pageLocal, rowsPerPage, order, orderBy, searchText, filters));
  };

  const handlePressLead = (leadId) => {
    return props.history.push(`/leads?id=${leadId}`);
  };

  const onOrder = (orderLocal, orderByLocal) => {
    dispatch(groupEvents(page, rowsPerPage, orderLocal, orderByLocal, searchText, filters));
  };

  const getEditPermissions = () => {
    if (profile) {
      if (profile.permissions.view_calendar) {
        return true;
      }
    }
    return false;
  };

  const getCreatePermission = () => {
    if (profile) {
      if (profile.permissions.create_event) {
        return true;
      }
    }
    return false;
  };

  const renderSnackBar = () => {
    if (eventCancel.message) {
      return (
        <CustomSnackbar
          variant={eventCancel.error !== null ? 'error' : 'success'}
          message={eventCancel.message}
        />
      );
    }

    if (message) {
      return <CustomSnackbar variant={error !== null ? 'error' : 'success'} message={message} />;
    }
    return null;
  };

  const onViewHistory = (event) => {
    setActiveListEvents(event.leadId);
  };

  const onDetail = (id) => {
    const fullEvents = getFullEvents();
    const eventLocal = fullEvents.filter((obj) => obj.id === id)[0];

    setActualEvent(eventLocal);
    setOpenAppointmentModal(true);
  };

  const cancelUpdate = () => {
    setActualEvent(null);
    setOpenAppointmentModal(false);
  };

  const getTableData = () => {
    const colBroker = {
      id: 'broker',
      right: false,
      disablePadding: false,
      label: 'Asesor',
      type: 'text',
    };

    const rows = Object.keys(calendar).map((leadId) => parseCalendarRows(leadId));

    const cols = (isAdmin || profileId === COMERCIAL_MANAGER)
      ? [...calendarCols.slice(0, 1), colBroker, ...calendarCols.slice(1)]
      : calendarCols;

    const data = {
      rows,
      cols,
    };

    return data;
  };

  const isLoading = sending;
  const hasEditPermission = getEditPermissions();
  const hasCreatePermission = false;
  const handlers = {
    onDetail: onDetail,
    onViewHistory,
    onAdd,
    onSearch,
    onOrder,
    onGetAllLeadsOfUser: handlePressLead,
    onChangePage: handleChangePage,
    onFilter: handleFilter,
    onChangeRowsPerPage: handleChangeRowsPerPage,
  };

  const myConfig = () => {
    return {
      isDragable: false,
      hasRanking: true,
      isSelectable: false,
      hasEditPermission,
      hasCreatePermission,
      searchText: searchText || '',
      order: {
        by: orderBy,
        direction: order,
      },
      pagination: {
        current: page,
        rowsPerPage,
        totalItems: dataLength,
      },
      filters: {
        is: true,
        options: filterOptions,
        chips: filterChips,
        by: filters,
      },
    };
  };

  return isLoading === true ? (
    <CircularProgress
      style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }}
    />
  ) : (
    <div>
      {renderSnackBar()}
      <EnhancedTable
        title={'Agendamiento de citas'}
        data={getTableData()}
        handlers={handlers}
        config={myConfig()}
        />
      {openAppointmentModal && actualEvent && (
        <AppointmentModal
          open={openAppointmentModal}
          close={cancelUpdate}
          lead={actualEvent.leadId}
          isEventFlag
          eventObject={actualEvent}
          onUpdate={refereshCalendar}
        />
      )}
      {activeListEvents && (
        <AppointmentList
          events={calendar[activeListEvents]}
          open={Boolean(activeListEvents)}
          lead={activeListEvents}
          editAppointment={(eventId) => {
            setActiveListEvents(null);
            onDetail(eventId);
          }}
          closeModal={() => {
            setActiveListEvents(null);
            dispatch(cleanEventsByLead())
            //refereshCalendar();
          }}
        />
      )}
    </div>
  );
};

Calendar.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Calendar);
