import * as companyActions from '../actions/types/companies';

const initialState = {
  companies: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {companies, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case companyActions.FILTER_COMPANIES_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case companyActions.FILTER_COMPANIES_SUCCESS:
    return {...state, sending: false, companies, error: null, message: 'Empresas obtenidas satisfactoriamente', dataLength};
  case companyActions.FILTER_COMPANIES_FAILED:
    return {...state, sending: false, companies: [], error, message, dataLength};
  case companyActions.GET_COMPANIES_SENDING:
    return {...state, sending: true};
  case companyActions.GET_COMPANIES_SUCCESS:
    return {...state, sending: false, companies, error: null, message: 'Empresas obtenidas satisfactoriamente', dataLength};
  case companyActions.GET_COMPANIES_FAILED:
    return {...state, sending: false, companies: [], error, message, dataLength};
  case companyActions.CREATE_COMPANY_SENDING:
    return {...state, sending: true};
  case companyActions.CREATE_COMPANY_SUCCESS:
    return {...state, sending: false, error: null, message: 'Empresa creada satisfactoriamente'};
  case companyActions.CREATE_COMPANY_FAILED:
    return {...state, sending: false, error, message};
  case companyActions.UPDATE_COMPANY_SENDING:
    return {...state, sending: true};
  case companyActions.UPDATE_COMPANY_SUCCESS:
    return {...state, sending: false, error: null, message: 'Empresa actualizada satisfactoriamente'};
  case companyActions.UPDATE_COMPANY_FAILED:
    return {...state, sending: false, error, message};
  case companyActions.VALIDATE_COMPANY_FAILED:
    return {...state, error, message};
  default:
    return state;
  }
};
