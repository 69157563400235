import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import {styles} from './styles';
import DatePicker from '../../common/datePicker';
import CheckList from '../../common/checkList';
import TextBox from '../../common/textBox';
import RadioList from '../../common/radioGroup';
import YesNo from '../../common/yesNo';
import ConfirmationDialog from '../../common/confirmationDialog';
import Range from '../../common/range';
import DateRange from '../../common/dateRange';
import ImagePicker from '../../common/imagePicker';
import ImagePickerMultiple from '../../common/imagePickerMultiple';

export class Attributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderAttributes = this.renderAttributes.bind(this);
    this.renderAnswer = this.renderAnswer.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleEditAttributes = this.handleEditAttributes.bind(this);
  }

  handleGoBack() {
    window.scrollTo(0, 0);
    this.props.onGoBack();
  }

  dateAnswer(identifier, currentAnswer, isBirthdate, content, permissions) {
    return (
      <DatePicker 
        isBirthdate={isBirthdate} 
        updateAnswer={this.props.onUpdateAnswer} 
        content={content}
        currentAnswer={currentAnswer}
        identifier={identifier}
        permissions={permissions}
      />
    );
  }

  textAnswer(identifier, currentAnswer, permissions) {
    return (
      <TextBox 
        updateAnswer={this.props.onUpdateAnswer} 
        placeholder="Ingrese..."
        currentAnswer={currentAnswer}
        identifier={identifier}
        permissions={permissions}
      />
    );
  }

  yesNoAnswer(identifier, currentAnswer, permissions) {
    return (
      <YesNo 
        identifier={identifier}
        currentAnswer={currentAnswer}
        permissions={permissions}
        updateAnswer={this.props.onUpdateAnswer} 
      />
    );
  }

  imagePickerUnique(identifier, currentAnswer, answers, permissions) {
    return (
      <ImagePicker 
        identifier={identifier}
        currentAnswer={currentAnswer}
        answers={answers} 
        updateAnswer={this.props.onUpdateAnswer} 
        permissions={permissions}
      />
    );
  }

  imagePickerMultiple(identifier, currentAnswer, answers, permissions) {
    return (
      <ImagePickerMultiple 
        identifier={identifier}
        currentAnswer={currentAnswer}
        answers={answers} 
        updateAnswer={this.props.onUpdateAnswer} 
        permissions={permissions}
      />
    );
  }

  radioGroup(identifier, currentAnswer, answers, permissions) {
    return (
      <RadioList 
        answers={answers} 
        currentAnswer={currentAnswer}
        identifier={identifier}
        permissions={permissions}
        updateAnswer={this.props.onUpdateAnswer} 
      />
    );
  }

  checkList(identifier, currentAnswer, answers, permissions) {
    return (
      <CheckList 
        answers={answers}
        currentAnswer={currentAnswer}
        identifier={identifier} 
        updateAnswer={this.props.onUpdateAnswer}
        permissions={permissions}
      />
    );
  }

  range(identifier, currentAnswer, answers, permissions) {
    return (
      <Range 
        identifier={identifier}
        answers={answers} 
        currentAnswer={currentAnswer}
        updateAnswer={this.props.onUpdateAnswer} 
        permissions={permissions}
      />
    );
  }

  dateRange(identifier, currentAnswer, permissions) {
    return (
      <DateRange
        identifier={identifier} 
        currentAnswer={currentAnswer}
        permissions={permissions}
        updateAnswer={this.props.onUpdateAnswer} 
      />
    );
  }

  handleEditAttributes() {
    window.scrollTo(0, 0);
    this.props.onEditAttributes();
  }

  renderButton() {
    const {isLoading, classes, permissions} = this.props;
    if (permissions) {
      if (!isLoading) {
        return (
          <ConfirmationDialog 
            confirmationText="¿Está seguro que desea actualizar este proyecto?"
            buttonText="Modificar Proyecto"
            variant="contained"
            isDisabled={false}
            confirmAction={this.handleEditAttributes}
          />
        );
      }
      return <CircularProgress className={classes.progress} />;
    }
    return null;
  }


  renderAnswer(attribute) {
    const {project, permissions} = this.props;
    const {identifier} = attribute;
    const currentAnswer = project.attributes[identifier];
    switch (attribute.input_type) {
    case 'date':
      return this.dateAnswer(identifier, currentAnswer, false, permissions);
    case 'birth_date':
      return this.dateAnswer(identifier, currentAnswer, true, permissions);
    case 'text_box':
      return this.textAnswer(identifier, currentAnswer, permissions);
    case 'yes_no':
      return this.yesNoAnswer(identifier, currentAnswer, permissions);
    case 'image_unique':
      return this.imagePickerUnique(identifier, currentAnswer, attribute.options.content, permissions);
    case 'image_multiple':
      return this.imagePickerMultiple(identifier, currentAnswer, attribute.options.content, permissions);
    case 'radio_group':
      return this.radioGroup(identifier, currentAnswer, attribute.options.content, permissions);
    case 'check_list':
      return this.checkList(identifier, currentAnswer, attribute.options.content, permissions);
    case 'range':
      return this.range(identifier, currentAnswer, attribute.options.content, permissions);
    case 'date_range':
      return this.dateRange(identifier, currentAnswer, permissions);
    default:
      return null;
    }
  }

  renderAttributes() {
    const {attributes, classes} = this.props;
    return attributes.map((attribute, index) => {
      return (
        <div key={index}>
          <div className={classes.questionContainer}>
            <Typography variant="subtitle1">
              {attribute.name}
            </Typography>
            {this.renderAnswer(attribute)}
          </div>
          <Divider />
        </div>
      );
    });
  }


  render() {
    const {classes, isLoading, project, permissions} = this.props;
    const name = project.name ? project.name : '';
    return isLoading === true ? <CircularProgress style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'}} /> : (
      <Paper elevation={2} className={classes.paperRoot}>
        <Toolbar>
          <IconButton onClick={this.props.onGoBack}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">
            {`Atributos del desarrollo ${name}`}
          </Typography>
        </Toolbar>
        <div className={classes.attributesContainer}>
          <div className={classes.questionContainer}>
            <Typography variant="subtitle1">
              Descripción del desarrollo: 
            </Typography>
            <TextField 
              fullWidth
              autoFocus
              placeholder="Ingrese descripción"
              variant="outlined"
              multiline
              disabled={permissions === false}
              value={project.description}
              onChange={this.props.onEditDescription}
            />
          </div>
          <Divider />
          {this.renderAttributes()}
        </div>
        <div className={classes.submitContainer}>
          {this.renderButton()}
        </div>
      </Paper>
    );
  }
}

Attributes.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  attributes: PropTypes.array.isRequired,
  permissions: PropTypes.bool.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onUpdateAnswer: PropTypes.func.isRequired,
  onEditAttributes: PropTypes.func.isRequired,
  onEditDescription: PropTypes.func.isRequired
};
  
export default (withStyles(styles)(Attributes));
