import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {styles} from './styles';
import ConfirmationDialog from '../../../common/confirmationDialog';
import {
  isEmail,
  isPhone,
  STRATEGIC_PARTNER_ID,
  CLIENT_ID
} from '../../../../utils/utils';
import Uploader from '../../../common/uploader';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

export class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      lastname: '',
      phone: '',
      profileImg: '',
      profile: ''
    };
    this.renderButton = this.renderButton.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeLastName = this.handleChangeLastName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeProfile = this.handleChangeProfile.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  onAddButtonClick() {
    const {
      email,
      name,
      lastname,
      phone,
      profileImg,
      profile
    } = this.state;
    this.props.createUser({
      email,
      name,
      lastname,
      phone,
      profile,
      profileImg
    });
  }

  handleUrlUploaded(profileImg) {
    this.setState({profileImg: profileImg === false ? '' : profileImg});
  }

  handleChangeName(e) {
    const name = e.target.value;
    this.setState({name});
  }

  handleChangeLastName(e) {
    const lastname = e.target.value;
    this.setState({lastname});
  }

  handleChangeProfile(e) {
    const profile = e.target.value;
    this.setState({profile});
  }

  handleChangeEmail(e) {
    const email = e.target.value;
    this.setState({email});
  }

  handleChangePhone(e) {
    const phone = e.target.value;
    if (isPhone(phone)) {
      this.setState({phone});
    }
  }

  isInvalid() {
    const {
      name,
      lastname,
      email,
      phone,
      profile
    } = this.state;
    if (
      name !== '' &&
      lastname !== '' &&
      email !== '' &&
      phone !== '' &&
      isEmail(email) &&
      profile !== ''
    ) {
      return false;
    }
    return true;
  }

  renderProfiles() {
    const {profiles} = this.props;
    return profiles
    .filter(item => item.id !== CLIENT_ID && item.id !== STRATEGIC_PARTNER_ID)
    .map((item, index) => {
      return (
        <MenuItem value={item.id} key={index}>
          {item.name}
        </MenuItem>
      );
    });
  }

  renderButton() {
    const {isLoading, classes} = this.props;
    if (!isLoading) {
      return (
        <ConfirmationDialog
          confirmationText="¿Está seguro que desea crear este usuario? "
          buttonText="Agregar Usuario"
          variant="contained"
          isDisabled={this.isInvalid()}
          confirmAction={this.onAddButtonClick}
        />
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderBody() {
    const {classes, isLoading} = this.props;
    return isLoading === true ? (
      <div
        style={{
          zIndex: 100,
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.5)'
        }}
      >
        <CircularProgress
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto'
          }}
        />
      </div>
    ) : (
      <Paper elevation={0} className={classes.paperRoot}>
        <Toolbar>
          <Typography variant="h6">Nuevo usuario</Typography>
        </Toolbar>
        <div style={{width: '100%'}}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Nombres</b>
                </TableCell>
                <TableCell>
                  <TextField
                    autoFocus
                    value={this.state.name}
                    onChange={this.handleChangeName}
                    fullWidth
                    id="nameText"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Apellidos</b>
                </TableCell>
                <TableCell>
                  <TextField
                    autoFocus
                    value={this.state.lastname}
                    onChange={this.handleChangeLastName}
                    fullWidth
                    id="lastNameText"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Email</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    fullWidth
                    error={
                      this.state.email !== '' && !isEmail(this.state.email)
                    }
                    id="emailText"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Tel&eacute;fono</b>
                </TableCell>
                <TableCell>
                  <TextField
                    value={this.state.phone}
                    onChange={this.handleChangePhone}
                    fullWidth
                    error={
                      this.state.phone !== '' && this.state.phone.length < 7
                    }
                    inputProps={{
                      maxLength: 12
                    }}
                    id="phoneText"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRowTitle}>
                  <b>Perfil</b>
                </TableCell>
                <TableCell>
                  <Select
                    value={this.state.profile}
                    fullWidth
                    onChange={this.handleChangeProfile}
                  >
                    {this.renderProfiles()}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRowTitle}>
                <TableCell>
                  <b>Foto perfil</b>
                </TableCell>
                <TableCell id="imageInput">
                  {this.state.profileImg && (
                    <a
                      href={this.state.profileImg}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src={this.state.profileImg}
                        alt="Foto de perfil"
                        style={{width: 100}}
                      />
                    </a>
                  )}
                  <Uploader
                    buttonText={
                      this.state.profileImg ? 'Actualizar foto' : 'Subir foto'
                    }
                    photo
                    dirName="Users"
                    urlUploaded={this.handleUrlUploaded}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="lg"
        fullWidth
        open
        onClose={this.props.closeDialog}
        TransitionComponent={Transition}
      >
        <DialogContent style={{padding: '20px 50px'}}>
          {this.renderBody()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeDialog} color="primary">
            Cancelar
          </Button>
          <div className={classes.submitContainer}>{this.renderButton()}</div>
        </DialogActions>
      </Dialog>
    );
  }
}

AddUser.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  profiles: PropTypes.array.isRequired,
  createUser: PropTypes.func.isRequired
};

export default withStyles(styles)(AddUser);
