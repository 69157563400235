export const styles = theme => ({
  container: {
    width: 1200,
    maxWidth: 1400,
    minWidth: 1400,
    backgroundColor: '#fff',
    '-webkit-print-color-adjust': 'exact',
    paddingTop: 20,
    paddingBottom: 20,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  leftSide: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%'
  },
  titleContainer: {
    display: 'inline-block',
    paddingLeft: 20
  },
  titleBrokerContainer: {
    paddingLeft: 20
  },
  headphonesContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: 70,
    marginTop: -40
  },
  rightSide: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: 430
  },
  fontSize: {
    fontSize: 12,
    textAlign: 'justify',
    color: '#8A8A8A',
    '-webkit-print-color-adjust': 'exact'
  },
  projectDescription: {
    fontSize: 13,
    textAlign: 'justify',
    marginBottom: 5,
    color: '#8A8A8A',
    '-webkit-print-color-adjust': 'exact'
  },
  title: {
    textAlign: 'center'
  },
  titleBroker: {
    textAlign: 'left'
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 14,
    color: '#999999',
    '-webkit-print-color-adjust': 'exact',
    maxWidth: 800
  },
  footer: {
    width: '80%',
    textAlign: 'center',
    fontSize: 16,
    color: 'black',
    '-webkit-print-color-adjust': 'exact',
    marginLeft: 10
  },
  subtitleBroker: {
    textAlign: 'left',
    fontSize: 14,
    color: '#999999',
    '-webkit-print-color-adjust': 'exact'
  },
  imagesContainer: {
    display: 'block',
    marginLeft: 5,
    height: 480
  },
  projectTitle: {
    position: 'absolute',
    marginTop: 100,
    backgroundColor: 'black',
    color: '#ffffff',
    '-webkit-print-color-adjust': 'exact',
    fontSize: 18,
    fontWeight: 800,
    paddingLeft: 10,
    paddingRight: 10
  },
  price: {
    fontSize: 18,
    textAlign: 'justify',
    marginRight: 10
  },
  priceValue: {
    fontSize: 24,
    textAlign: 'justify',
    '-webkit-print-color-adjust': 'exact',
    color: '#969696'
  },
  amenitiesContainer: {
    display: 'inline-block',
    width: '100%'
  },
  matchImage: {
    width: 500,
    height: 460
  },
  leadIcon: {
    width: 204,
    height: 178
  },
  profileImg: {
    width: 130,
    height: 130,
    marginTop: 20
  },
  profileBrokerImg: {
    width: 85,
    height: 85,
    borderRadius: 50
  },
  matchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 25,
    paddingLeft: 22,
    paddingRight: 22,
    '-webkit-print-color-adjust': 'exact',
    height: 480,
    overflowY: 'hidden'
  },
  description: {
    marginLeft: 20,
    maxHeight: 500,
    paddingTop: 5,
    verticalAlign: 'top',
    height: 480
  },
  infoImage: {
    width: 270,
    height: 230,
    marginBottom: 2,
    marginRight: 5
  },
  header: {
    display: 'inline-flex',
    margin: '0 auto',
    marginTop: 20,
    marginLeft: 40
  },
  headerBrokerContainer: {
    display: 'flex',
    backgroundColor: '#f8f8f8',
    '-webkit-print-color-adjust': 'exact',
    padding: 20,
    marginTop: 20
  },
  headerBroker: {
    display: 'inline-flex',
    margin: '0 auto'
  },
  logo: {
    display: 'inline-block',
    width: 80,
    height: 90,
    marginBottom: 20,
    objectFit: 'contain'
  },
  logoBroker: {
    display: 'inline-block',
    width: 80,
    height: 90,
    marginBottom: 20
  },
  slogan: {
    display: 'inline-block',
    width: 160,
    height: 65,
    marginBottom: 20,
    objectFit: 'contain'
  },
  logoContainer: {
    display: 'inline-block',
    width: 270
  },
  logoBrokerContainer: {
    display: 'inline-block'
  },
  divider: {
    marginTop: 5,
    marginBottom: 10,
    width: '10%',
    height: 3,
    backgroundColor: '#35B7A4',
    '-webkit-print-color-adjust': 'exact'
  },
  fullWidthDivider: {
    color: 'red',
    marginTop: 5,
    marginBottom: 10,
    width: '60%',
    height: 3,
    backgroundColor: theme.palette.primary.main,
    '-webkit-print-color-adjust': 'exact'
  },
  wrapDivider: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0
  },
  projectCounterText: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  amenitiesPropertiesContainer: {
    width: '100%',
    display: 'inline-flex'
  },
  amenitiesText: {
    width: '100%',
    fontSize: 13,
    marginTop: 8,
    '-webkit-print-color-adjust': 'exact',
    color: '#8A8A8A'
  },
  tipImage: {
    width: '80px',
    position: 'absolute',
    right: 40
  },
  customFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
    background: 'white',
    marginTop: 40,
    border: `solid ${theme.palette.primary.main}`
  },
  button: {
    margin: theme.spacing.unit,
    '& *:hover, &:hover': {
      color: 'white'
    }
  },
  buttonIcon: {
    marginRight: theme.spacing.unit
  },
  gridRoot: {
    flexGrow: 1
  },
  gridText: {
    '& span': {
      fontWeight: 'bold'
    }
  },
  gridTextPrice: {
    '& span': {
      fontWeight: 'bold'
    },
    '& p': {
      fontSize: '18px !important',
      color: theme.palette.primary.main,
      fontWeight: '600'
    }
  },
  commentText: {
    fontSize: 11,
    textAlign: 'justify',
    color: '#8A8A8A',
    '-webkit-print-color-adjust': 'exact',
    '& span': {
      fontWeight: 'bold',
      color: 'black',
    }
  }
});

export default styles;
