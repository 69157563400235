import * as newsActions from '../actions/types/news';

const initialState = {
  news: [],
  newsToShow: [],
  message: '',
  dataLength: 0,
  sending: false,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {news, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText, newsToShow} = action.payload;
  switch (action.type) {
  case newsActions.FILTER_NEWS_SENDING:
    return {...state, sending: true, page, rowsPerPage, order, orderBy, searchText, dataLength};
  case newsActions.FILTER_NEWS_SUCCESS:
    return {...state, sending: false, news, error: null, message: 'Noticias obtenidos satisfactoriamente', dataLength};
  case newsActions.FILTER_NEWS_FAILED:
    return {...state, sending: false, news: [], error, message, dataLength};
  case newsActions.GET_NEWS_SENDING:
    return {...state, sending: true};
  case newsActions.GET_NEWS_SUCCESS:
    return {...state, sending: false, news, error: null, message: 'Noticias obtenidos satisfactoriamente', dataLength};
  case newsActions.GET_NEWS_FAILED:
    return {...state, sending: false, news: [], error, message, dataLength};
  case newsActions.CREATE_NEWS_ITEM_SENDING:
    return {...state, sending: true};
  case newsActions.CREATE_NEWS_ITEM_SUCCESS:
    return {...state, sending: false, error: null, message: 'Noticias creada satisfactoriamente'};
  case newsActions.CREATE_NEWS_ITEM_FAILED:
    return {...state, sending: false, error, message};
  case newsActions.UPDATE_NEWS_ITEM_SENDING:
    return {...state, sending: true};
  case newsActions.UPDATE_NEWS_ITEM_SUCCESS:
    return {...state, sending: false, error: null, message: 'Noticia actualizada satisfactoriamente'};
  case newsActions.UPDATE_NEWS_ITEM_FAILED:
    return {...state, sending: false, error, message};
  case newsActions.GET_NEWS_TO_SHOW:
    return {...state, newsToShow};
  case newsActions.FAIL_NEWS_TO_SHOW:
    return {...state, newsToShow: []};
  default:
    return state;
  }
};
