export const styles = (theme) => ({
  filterSelect: {
    width: '95%',
    margin: '0 auto',
    display: 'table',
    marginBottom: theme.spacing.unit * 2,
  },
  datePicker: {
    width: '100%',
  },
  datePickerRange: {
    width: '50%',
  },
  menuItemSpan: {
    fontSize: 10,
    color: '#949494',
    marginLeft: 20,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  dialogContent: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
  },
  // filterTitle: {
  //   fontSize: 16,
  //   fontWeight: 'bold',
  //   color: 'grey',
  // },
  zoneLabel: {
    padding: theme.spacing.unit * 2,
    fontSize: 18,
    fontWeight: 'bold',
    color: 'grey',

    '&:focus': {
      outline: 'none',
    },
  },
  cityChip: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    margin: '0 16px',
    fontSize: 10,
    fontWeight: '400',
    height: 16,
    borderRadius: 4,
    '& span': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
});

export default styles;
