import * as smartSupplyFilters from '../actions/types/smartSupplyFilters';

const initialState = {
  sectors: [],
  chipData: [],
  cities: [],
  zones: [],
  unitTypes: [],
  rooms: [],
  projects: [],
  status: [],
  filters: {
    types: [],
    rooms: [],
    projectsByLocation: [],
    status: [],
  },
  filtersObj: {},
  disabledFilter: true,
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {
    filters,
    chipData,
    sectors,
    cities,
    zones,
    unitTypes,
    rooms,
    projects,
    status,
    filtersObj,
  } = action.payload;
  switch (action.type) {
    case smartSupplyFilters.SET_FILTERS:
      return { ...state, filters };
    case smartSupplyFilters.SET_CHIP_DATA:
      return { ...state, chipData };
    case smartSupplyFilters.SET_SECTORS:
      return { ...state, sectors };
    case smartSupplyFilters.SET_CITIES:
      return { ...state, cities };
    case smartSupplyFilters.SET_ZONES:
      return { ...state, zones };
    case smartSupplyFilters.SET_UNIT_TYPES:
      return { ...state, unitTypes };
    case smartSupplyFilters.SET_ROOMS:
      return { ...state, rooms };
    case smartSupplyFilters.SET_PROJECTS:
      return { ...state, projects };
    case smartSupplyFilters.SET_PROJECT_STATUS:
      return { ...state, status };
    case smartSupplyFilters.SET_CHANGE_FILTERS:
      return { ...state, filtersObj, disabledFilter: true };
    case smartSupplyFilters.SET_DISABLE_FILTER:
      return { ...state, disabledFilter: false };
    default:
      return state;
  }
};
