export const SORT_PARAMETERS_SENDING = 'SORT_PARAMETERS_SENDING';

export const SORT_PARAMETERS_SUCCESS = 'SORT_PARAMETERS_SUCCESS';

export const SORT_PARAMETERS_FAILED = 'SORT_PARAMETERS_FAILED';

export const GET_ALL_PARAMETERS_SENDING = 'GET_ALL_PARAMETERS_SENDING';

export const GET_ALL_PARAMETERS_SUCCESS = 'GET_ALL_PARAMETERS_SUCCESS';

export const GET_ALL_PARAMETERS_FAILED = 'GET_ALL_PARAMETERS_FAILED';

export const GET_PARAMETERS_SENDING = 'GET_PARAMETERS_SENDING';

export const GET_PARAMETERS_SUCCESS = 'GET_PARAMETERS_SUCCESS';

export const GET_PARAMETERS_FAILED = 'GET_PARAMETERS_FAILED';

export const FILTER_PARAMETERS_SENDING = 'FILTER_PARAMETERS_SENDING';

export const FILTER_PARAMETERS_SUCCESS = 'FILTER_PARAMETERS_SUCCESS';

export const FILTER_PARAMETERS_FAILED = 'FILTER_PARAMETERS_FAILED';

export const ADD_PARAMETER_SENDING = 'ADD_PARAMETER_SENDING';

export const ADD_PARAMETER_SUCCESS = 'ADD_PARAMETER_SUCCESS';

export const ADD_PARAMETER_FAILED = 'ADD_PARAMETER_FAILED';

export const EDIT_PARAMETER_SENDING = 'EDIT_PARAMETER_SENDING';

export const EDIT_PARAMETER_SUCCESS = 'EDIT_PARAMETER_SUCCESS';

export const EDIT_PARAMETER_FAILED = 'EDIT_PARAMETER_FAILED';
