/* eslint-disable no-param-reassign */
import axios from 'axios';
import {axiosMaker, errorMessage} from '../utils/utils';
import * as stageActions from './types/whatsappTemplates';
import store from '../store';

const company = JSON.parse(localStorage.getItem('company'));

export const filterWhatsappTemplates = (page, rowsPerPage, order, orderBy, searchText) => async (dispatch) => {
  if (searchText !== '' && store.getState().whatsappTemplates.searchText !== searchText) {
    page = 0;
  }
  if (order !== store.getState().whatsappTemplates.order || orderBy !== store.getState().whatsappTemplates.orderBy) {
    page = 0;
  }
  if (searchText === null) {
    searchText = '';
  }
  dispatch({
    type: stageActions.FILTER_WHATSAPP_TEMPLATES_SENDING,
    payload: {
      whatsappTemplates: [],
      dataLength: 0,
      page,
      rowsPerPage,
      order,
      orderBy,
      searchText
    }
  });
  try {
    const data = {
      operator: 'and',
      page,
      limit: rowsPerPage,
      sort: {
        [orderBy]: order
      },
      filters: {}
    };
    if (company && company._id) {
      data.filters = {
        company: [company._id]
      };
    }
    if (searchText !== '') {
      data.filters = {
        ...data.filters,
        name: searchText,
        template: searchText
      };
    }
    const response = await axios(axiosMaker('POST', 'whatsappTemplates/filter', data, true));
    return dispatch({
      type: stageActions.FILTER_WHATSAPP_TEMPLATES_SUCCESS,
      payload: {
        whatsappTemplates: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: stageActions.FILTER_WHATSAPP_TEMPLATES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getWhatsappTemplates = () => async (dispatch) => {
  dispatch({
    type: stageActions.GET_WHATSAPP_TEMPLATES_SENDING,
    payload: {
      whatsappTemplates: [],
      dataLength: 0
    }
  });
  try {
    const data = {
      operator: 'and',
      sort: {
        name: 'asc'
      }, 
      filters: company && company._id ? {company: [company._id]} : {}
    };
    const response = await axios(axiosMaker('POST', 'whatsappTemplates/filter', data, true));
    return dispatch({
      type: stageActions.GET_WHATSAPP_TEMPLATES_SUCCESS,
      payload: {
        whatsappTemplates: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: stageActions.GET_WHATSAPP_TEMPLATES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createWhatsappTemplate = data => async (dispatch) => {
  dispatch({
    type: stageActions.CREATE_WHATSAPP_TEMPLATE_SENDING,
    payload: {}
  });
  try {
    if (!data.company && company && company._id) { data.company = company._id; } 
    
    await axios(axiosMaker('POST', 'whatsappTemplates', data, true));
    return dispatch({
      type: stageActions.CREATE_WHATSAPP_TEMPLATE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: stageActions.CREATE_WHATSAPP_TEMPLATE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const updateWhatsappTemplate = (id, whatsappTemplates) => async (dispatch) => {
  dispatch({
    type: stageActions.UPDATE_WHATSAPP_TEMPLATE_SENDING,
    payload: {}
  });
  try {
    await axios(axiosMaker('PATCH', `whatsappTemplates/${id}`, whatsappTemplates, true));
    return dispatch({
      type: stageActions.UPDATE_WHATSAPP_TEMPLATE_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    
    return dispatch({
      type: stageActions.UPDATE_WHATSAPP_TEMPLATE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
