import * as usersActions from '../actions/types/users';

const initialState = {
  users: [],
  asessors:[],
  message: '',
  dataLength: 0,
  sending: false,
  user: null,
  error: null,
  page: 0,
  rowsPerPage: 20,
  order: 'asc',
  orderBy: 'name',
  searchText: '',
  userExists: false,
  userName: ''
};
  
export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {userExists, userName, user, users, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText, asessors} = action.payload;
  switch (action.type) {
  case usersActions.FILTER_USERS_SENDING:
    return {...state, sending: true, dataLength, page, rowsPerPage, order, orderBy, searchText};
  case usersActions.FILTER_USERS_SUCCESS:
    return {...state, sending: false, users, error: null, message: 'Usuarios obtenidos satisfactoriamente', dataLength};
  case usersActions.FILTER_USERS_FAILED:
    return {...state, sending: false, users: [], error, message};
  case usersActions.FILTER_USERS_ASESSORS_SENDING:
    return {...state, sending: true};
  case usersActions.FILTER_USERS_ASESSORS_SUCCESS:
    return {...state,asessors};
  case usersActions.FILTER_USERS_ASESSORS_FAILED:
    return {...state, sending: false, asesors: [], error, message};
  case usersActions.GET_USERS_SENDING:
    return {...state, sending: true, dataLength, users};
  case usersActions.GET_USERS_SUCCESS:
    return {...state, sending: false, users, error: null, message: 'Usuarios obtenidos satisfactoriamente', dataLength};
  case usersActions.GET_USERS_FAILED:
    return {...state, sending: false, users: [], error, message};
  case usersActions.GET_USER_SENDING:
    return {...state, sending: true, user};
  case usersActions.GET_USER_SUCCESS:
    return {...state, sending: false, user, error: null, message: 'Usuario obtenido satisfactoriamente'};
  case usersActions.GET_USER_FAILED:
    return {...state, sending: false, user: null, error, message};
  case usersActions.CREATE_USER_SENDING:
    return {...state, sending: true};
  case usersActions.CREATE_USER_SUCCESS:
    return {...state, sending: false, error: null, message: 'Usuario creado satisfactoriamente'};
  case usersActions.USER_ALREADY_EXISTS:
    return {...state, sending: false, error: null, message: 'El correo electrónico ingresado ya existe', userExists, userName};
  case usersActions.CREATE_USER_FAILED:
    return {...state, sending: false, error, message};
  case usersActions.UPDATE_USER_SENDING:
    return {...state, sending: true};
  case usersActions.UPDATE_USER_SUCCESS:
    return {...state, sending: false, error: null, message: 'Usuario modificado satisfactoriamente'};
  case usersActions.UPDATE_USER_FAILED:
    return {...state, sending: false, error, message};
  case usersActions.RESETPASSWORD_SENDING:
    return {...state, sending: true};
  case usersActions.RESETPASSWORD_SUCCESS:
    return {...state, sending: false, error: null, message: 'Se ha enviado un correo al usuario con la nueva contraseña'};
  case usersActions.RESETPASSWORD_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
